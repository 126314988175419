import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import appLabel from "../../../config/appLabel";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import { apis as api } from "../../../services/api.action";
import {
  handleSkeletonFac,
  AmntFormatFac,
} from "../../../components/layouts/Facility";

//dash v2
import { Box, Alert, AlertTitle, Typography } from "@mui/material";
import PieChart from "./PieChart";
import {
  Share,
  CheckCircleOutline,
  ThumbDownOffAltOutlined,
  WatchLaterOutlined,
  FormatQuote,
} from "@mui/icons-material";
import StatBox from "./StatBox";
import ProgressCircle from "./ProgressCircle";
import DashboardLayout from "./DashboardLayout";


const PODashboard = (props) => {
  const { userData, menuData, location, history } = props;
  const dispatch = useDispatch();
  const [poDashData, setPoDashData] = useState([]);
  const [totalFundedPO, setTotalFundedPO] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mockPieData, setMockPieData] = useState([]);

  useEffect(() => {
    getListByCriteria();
  }, []);

  function getListByCriteria() {
    setIsLoading(true);

    let body = {
      // profileId,
      // session,
    };

    BOM.FetchReqAction(body, api.GetGenericDashBoard, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.dashboardCount;
        let tempArr = [
          {
          name: appLabel.availableItem,
          value: objDetail.poShared,
          icon: <Share sx={{ color: "#333333", fontSize: "20px" }} />,
          color: "navy-bg",
          itemType: "PO",
          url: "shared-order",
          position: 1,
          gridColumn: "span 2",
        },
        {
          name: appLabel.quotedItem,
          value: objDetail.poQuoted,
          icon: (
            <FormatQuote sx={{ color: "#333333", fontSize: "20px" }} />
          ),
          color: "navy-bg",
          itemType: "PO",
          url: "financed-order",
          position: 2,
          gridColumn: "span 2",
        },
        {
          name: appLabel.fundedItem,
          value: objDetail.poFunded,
          icon: (
            <CheckCircleOutline
              sx={{ color: "#333333", fontSize: "20px" }}
            />
          ),
          color: "navy-bg",
          itemType: "PO",
          url: "funded-order",
          position: 3,
          gridColumn: "span 2",
        },
        {
          name: appLabel.rejectedItem,
          value: objDetail.poRejected,
          icon: (
            <ThumbDownOffAltOutlined
              sx={{ color: "#333333", fontSize: "20px" }}
            />
          ),
          color: "navy-bg",
          itemType: "PO",
          url: "manage-order",
          position: 4,
          gridColumn: "span 2",
        },
        {
          name: appLabel.awaitningItem,
          value: objDetail.awaitPoVerification,
          icon: (
            <WatchLaterOutlined
              sx={{ color: "#333333", fontSize: "20px" }}
            />
          ),
          color: "navy-bg",
          itemType: "PO",
          url: "order-to-verify",
          position: 5,
          gridColumn: "span 3",
        }]

        let tempChart = [{
          id: appLabel.availableItem,
          label: appLabel.availableItem,
          value: objDetail.poShared,
          color: "hsl(104, 70%, 50%)",
          position: 1,
        },
        {
          id: appLabel.quotedItem,
          label: appLabel.quotedItem,
          value: objDetail.poQuoted,
          color: "hsl(162, 70%, 50%)",
          position: 2,
        },
        {
          id: appLabel.fundedItem,
          label: appLabel.fundedItem,
          value: objDetail.poFunded,
          color: "hsl(291, 70%, 50%)",
          position: 3,
          gridColumn: "span 2",
        },
        {
          id: appLabel.rejectedItem,
          label: appLabel.rejectedItem,
          value: objDetail.poRejected,
          color: "hsl(229, 70%, 50%)",
          position: 4,
        },
        {
          id: appLabel.awaitningItem,
          label: appLabel.awaitningItem,
          value: objDetail.awaitPoVerification,
          color: "hsl(344, 70%, 50%)",
          position: 5,
        }];

        /** ORDER ITM S BY POSITION */
        const myData = []
          .concat(tempArr)
          .sort((a, b) => (a.position > b.position ? 1 : -1));

        const myDataChart = []
          .concat(tempChart)
          .sort((a, b) => (a.position > b.position ? 1 : -1));

        setPoDashData(myData);

        setIsLoading(false);

        // pie mock data
        setMockPieData(myDataChart);

        setTotalFundedPO(objDetail.poTotalFunded);
      }
    });
  }

  const onSelectTile = (objParam) => {
    const { url } = objParam;
    history.push(`/${url}`);
  };

  return (
    <>
    <DashboardLayout
      dashboardData={poDashData}
      isLoading={isLoading}
      mockPieData={mockPieData}
      totalFunded={totalFundedPO}
      isPo={true}
      onSelectTile={onSelectTile}
    />
    </>
  );
};

export default PODashboard;
