import React, { useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../../services/api.action";
import { connect, useDispatch } from "react-redux";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../../ui-setup/RecordStatus";
import appStatic from "../../../config/appStaticData";
import RightSide from "../../ui-setup/RightSide";
import {
  InputTextFacility,
  Col12BlockFacility,
  AmountFacility,
} from "../../layouts/Facility";

export const EditSurcharge = (props) => {
  const { location, userData, recordData, configData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId } = userData;
  const { isCsd } = configData;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const dispatch = useDispatch();
  const [currencyData, setCurrencyData] = useState([]);
  const [chargeDetail, setChargeDetail] = useState({});
  const [taxData, setTaxData] = useState([]);
  const [taxInputField, setTaxInputField] = useState({});
  const [isRequiredField, setIsRequiredField] = useState(true);
  const [amount, setAmount] = useState(undefined);
  const [isTaxSelected, setIsTaxSelected] = useState(false);
  const [companyTypeData, setCompanyTypeData] = useState([]);

  useEffect(() => {
    getCurrency();
    getTax();
    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);
    getCompanyType();

  }, []);

  function getCompanyType() {
    /** FETCH MENU */
    let body = {
      profileId,
      criteria: "A",
      companyTypeId: "",
      session,
    };
    BOM.FetchReqAction(body, api.GetCompanyType, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        // get all entity type except custodian
        let tempCompany = res.companyTypeListing.filter(
          (item) => item.COMPANY_TYPE_ID !== "CT2022012400000"
        );
        setCompanyTypeData(tempCompany);
      }
    });
  }
  const getDetailById = (params, taxDataParam) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      chargeId: params.SURCHARGE_ID,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetSurcharge, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let chargeDetail = res.surchargeDetail;

        setValue("chargeId", chargeDetail.SURCHARGE_ID);
        setValue("name", chargeDetail.CHARGE_NAME);
        setValue("maxBand", chargeDetail.CHARGE_LIMIT);
        setValue("rate", chargeDetail.CHARGE_PERCENTAGE);
        setValue("currency", chargeDetail.CHARGE_CCY);
        setValue("type", chargeDetail.CHARGE_CALCULATION_TYPE);
        setValue("taxId", chargeDetail.TAX_ID);
        setValue("companyTypeId", chargeDetail.COMPANY_TYPE_ID);
        // setAmount(parseFloat(chargeDetail.CHARGE_LIMIT));

        setChargeDetail(chargeDetail);
        onSelectTax(chargeDetail.TAX_ID, taxDataParam);
        dispatch({
          type: cnt.RECORD_STATUS,
          payload: chargeDetail,
        });
      }
    });
  };
  function getCurrency() {
    /** FETCH MENU */
    let body = {
      profileId,
      criteria: "A",
      currencyId: "",
      session,
    };
    BOM.FetchReqAction(body, api.GetCurrency, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setCurrencyData(res.currencyListing);
      }
    });
  }
  function getTax() {
    let body = {
      profileId,
      taxId: "",
      criteria: "A",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetTax, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.taxListing;
        setTaxData(objList);
        getDetailById(ggParam, objList);
      }
    });
  }
  const onSelectTax = (idParam, taxData) => {
    setIsTaxSelected(false);
    if (!idParam) return;
    const taxObj = taxData.find((item) => item.TAX_ID === idParam);

    if (!taxObj) {
      BOM.AlertMsg(
        cnt.WARNING,
        "There is no information about the selected tax."
      );
      return;
    }
    setTaxInputField(taxObj);
    setIsTaxSelected(true);
    // console.log(taxObj);
    // if (taxObj) {
    //   setTaxInputField([
    //     { name: appLabel.taxId, value: taxObj.TAX_ID },
    //     { name: appLabel.type, value: taxObj.TAX_TYPE },
    //     { name: appLabel.rate, value: taxObj.TAX_RATE },
    //     // { name: appLabel.amount, value: taxObj.TAX_AMOUNT },
    //   ]);
    // }
  };
  const formSubmit = (formValues) => {
    // console.log("data", formValues);

    formValues.maxBand = formValues.maxBand + ""; // send it as string as requested by the service layer.

    let body = {
      profileId,
      session,
      ...formValues,
    };

    // console.log(body);
    /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(body, api.UpdateSurcharge, "/manage-surcharge");
  };

  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <RightSide />
        <ContentTop pageTitle={appLabel.editSurcharge} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{appLabel.surchargeInfo}</h5>
                  <div className="ibox-tools">
                    <Link
                      to="/manage-surcharge"
                      className="btn btn-primary btn-xs"
                    >
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.generalInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.companyType} &nbsp;
                            <span className="asterisks"> *</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-md"
                            name="companyTypeId"
                            {...register("companyTypeId", {
                              required: "Field is required.",
                            })}
                          >
                            <option value="">{appLabel.choose}</option>
                            {companyTypeData.map((item, index) => (
                              <option value={item.COMPANY_TYPE_ID} key={index}>
                                {item.COMPANY_TYPE_NAME}
                              </option>
                            ))}
                          </select>
                          <span className="asterisks">
                            {errors?.companyTypeId?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.chargeName}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.chargeName}
                            {...register("name", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.name?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.currency}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm"
                            {...register("currency", {
                              required: "Field is required.",
                            })}
                          >
                            <option value="">{appLabel.choose}</option>
                            {currencyData.map((item, index) => (
                              <option value={item.CCY_CODE} key={index}>
                                {item.CCY_CODE}
                              </option>
                            ))}
                          </select>
                          <span className="asterisks">
                            {errors?.currency?.message}
                          </span>
                        </div>
                      </div>
                      {/* <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.chargeType}{" "}
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm"
                            {...register("type", {
                              required: "Field is required.",
                            })}
                          >
                            <option value="">{appLabel.choose}</option>
                            {appStatic.chargeType.map((item, index) => (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <span className="asterisks">
                            {errors?.type?.message}
                          </span>
                        </div>
                      </div> */}
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.maxBand}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="number"
                            className="form-control input-sm"
                            placeholder={appLabel.maxBand}
                            {...register("maxBand", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.maxBand?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.rate}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.rate}
                            {...register("rate", {
                              required: "Field is required.",
                              min: {
                                value: 0.01,
                                message: "Rate cannot be less than 0.01%",
                              },
                              max: {
                                value: 100,
                                message: "Rate cannot be greater than 100%",
                              },
                            })}
                          />
                          <span className="asterisks">
                            {errors?.rate?.message}
                          </span>
                        </div>
                      </div>
                    </div>
                    <Col12BlockFacility topTitle={appLabel.taxInformation}>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.category}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm"
                            {...register("taxId", {
                              required: "Field is required.",
                              onChange: (e) =>
                                onSelectTax(e.target.value, taxData),
                            })}
                          >
                            <option value="">{appLabel.choose}</option>
                            {taxData.map((item, index) => (
                              <option value={item.TAX_ID} key={index}>
                                {item.TAX_CATEGORY}
                              </option>
                            ))}
                          </select>
                          <span className="asterisks">
                            {errors?.taxId?.message}
                          </span>
                        </div>
                      </div>
                          <InputTextFacility
                            inputLabel={appLabel.taxId}
                            inputValue={taxInputField.TAX_ID}
                          />
                          <InputTextFacility
                            inputLabel={appLabel.type}
                            inputValue={taxInputField.TAX_TYPE}
                          />
                          <InputTextFacility
                            inputLabel={appLabel.rate}
                            inputValue={taxInputField.TAX_RATE}
                          />
                    </Col12BlockFacility>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <RecordStatus {...userDetail} /> */}
        </div>
      </form>
      <RecordStatus {...recordData} />
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditSurcharge);
