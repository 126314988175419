import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../../services/api.action";
import { connect, useDispatch } from "react-redux";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../../ui-setup/RecordStatus";
import NumberFormat from "react-number-format";
import {
  InputTextFacility,
  Col12BlockFacility,
  InputAmountFacility,
  NoDataFac,
} from "../../layouts/Facility";


export const SurchargeDetail = (props) => {
  const { location, userData, configData, recordData, menuData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId } = userData;
  const { isCsd } = configData;

  const dispatch = useDispatch();
  const [chargeDetail, setChargeDetail] = useState({});
  const [taxInputField, setTaxInputField] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [inputFldData, setInputFldData] = useState([]);

  useEffect(() => {
    // console.log(state.ggParam);
    getTax();
  }, []);

  const getDetailById = (params, taxDataParam) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      chargeId: params.SURCHARGE_ID,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetSurcharge, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let objDetail = res.surchargeDetail;

        setChargeDetail(objDetail);
        onSelectTax(objDetail.TAX_ID, taxDataParam);

        dispatch({
          type: cnt.RECORD_STATUS,
          payload: objDetail,
        });
        //handle right side
        BOM.IsApproveOrReject(
          objDetail.FLG_STATUS,
          menuData,
          "Fee Setup"
        );

        
        // mergedDataArr
        setInputFldData([
          {
            name: appLabel.companyType,
            value: objDetail.COMPANY_TYPE_NAME,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.chargeId,
            value: objDetail.SURCHARGE_ID,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.chargeName,
            value: objDetail.CHARGE_NAME,
            fldType: "text",
            section: "general",
          },

          {
            name: appLabel.maxSurcharge,
            value: objDetail.CHARGE_LIMIT,
            fldType: "amount",
            section: "general",
          },
          {
            name: appLabel.currency,
            value: objDetail.CHARGE_CCY,
            fldType: "text",
            section: "general",
          },{
            name: appLabel.chargeType,
            value: objDetail.CHARGE_CALCULATION_TYPE,
            fldType: "text",
            section: "general",
          },{
            name: appLabel.rate,
            value: objDetail.CHARGE_PERCENTAGE,
            fldType: "amount",
            section: "general",
          }
        
          ,{
            name: appLabel.taxId,
            value: objDetail.TAX_ID,
            fldType: "text",
            section: "tax",
          },{
            name: appLabel.category,
            value: objDetail.TAX_CATEGORY,
            fldType: "text",
            section: "tax",
          },{
            name: appLabel.type,
            value: objDetail.TAX_TYPE,
            fldType: "text",
            section: "tax",
          },{
            name: appLabel.rate,
            value: objDetail.TAX_RATE,
            fldType: "amount",
            section: "tax",
          }
        ]);
      }
    });
  };
  function getTax() {
    let body = {
      profileId,
      taxId: "",
      criteria: "A",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetTax, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.taxListing;
        setTaxData(objList);
        getDetailById(ggParam, objList);
        // console.log(objList);
      }
    });
  }
  const onSelectTax = (idParam, taxData) => {
 
    const taxObj = taxData.find((item) => (item.TAX_ID = idParam));

    if (taxObj) {
      setTaxInputField([
        { name: appLabel.taxId, value: taxObj.TAX_ID },
        { name: appLabel.category, value: taxObj.TAX_CATEGORY},
        { name: appLabel.type, value: taxObj.TAX_TYPE },
        { name: appLabel.rate, value: taxObj.TAX_RATE },
      ]);
    }
  };
  const onApprove = () => {
    let bodyApprove = {
      profileId,
      chargeId: state.ggParam.SURCHARGE_ID,
      criteria: "A",
      session,
    };

    // console.log(bodyApprove);
    BOM.GlobalApproveRejectAction(
      bodyApprove,
      api.ApproveRejectSurcharge,
      "manage-surcharge",
      "Fee Setup",
      "Approve "
    );
  };
  const onReject = () => {
    let bodyDisable = {
      profileId,
      chargeId: state.ggParam.SURCHARGE_ID,
      criteria: "R",
      session,
    };

    // console.log(bodyDisable);
    BOM.GlobalApproveRejectAction(
      bodyDisable,
      api.ApproveRejectSurcharge,
      "manage-surcharge",
      "Fee Setup",
      "Reject "
    );
  };

  return (
    <Main {...props} onApprove={onApprove} onReject={onReject}>
      <ContentTop pageTitle={appLabel.chargeDetail} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{appLabel.surchageInfo}</h5>
                <div className="ibox-tools">
                  <Link
                    to="/manage-surcharge"
                    className="btn btn-primary btn-xs"
                  >
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;
                    {appLabel.backBtn}
                  </Link>
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                <Col12BlockFacility topTitle={appLabel.generalInfo}>
                    {inputFldData.map((item, index) => (
                      <div key={index}>
                        {item.section === "general" &&
                          (item.fldType === "amount" ? (
                            <InputAmountFacility
                              inputLabel={item.name}
                              inputValue={item.value}
                            />
                          ) : (
                            <InputTextFacility
                              inputLabel={item.name}
                              inputValue={item.value}
                            />
                          ))}
                      </div>
                    ))}
                    <NoDataFac arrList={inputFldData} />
                  </Col12BlockFacility>
                  <Col12BlockFacility topTitle={appLabel.taxInformation}>
                  {inputFldData.map((item, index) => (
                      <div key={index}>
                        {item.section === "tax" &&
                          (item.fldType === "amount" ? (
                            <InputAmountFacility
                              inputLabel={item.name}
                              inputValue={item.value}
                            />
                          ) : (
                            <InputTextFacility
                              inputLabel={item.name}
                              inputValue={item.value}
                            />
                          ))}
                      </div>
                    ))}
                    <NoDataFac arrList={inputFldData} />
                  </Col12BlockFacility>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RecordStatus {...recordData} />
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SurchargeDetail);
