import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../../../services/api.action";
import { connect, useDispatch } from "react-redux";
import Main from "../../../layouts/Main";
import ContentTop from "../../../ui-setup/ContentTop";
import { cnt } from "../../../../services/constant.action";
import { BOM, RAL } from "../../../../services/defined.action";
import appLabel from "../../../../config/appLabel";
import {
  HandleSkeletonDetailFac,
  ColBlockFac,
} from "../../../layouts/Facility";

export const ManageUserNotification = (props) => {
  const { userData,  history } =
    props;
  const { profileId, session, companyTypeId } = userData;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const dispatch = useDispatch();
  const [userNotifData, setUserNotifData] = useState([]);
  const [isSetup, setIsSetup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const globalVar = window.globalData;

  useEffect(() => {
    getUserNotification();
    RAL.disableEnableRightAction([cnt.CLEAR_RIGHT_BTN]);

    globalVar.editLink = {
      path: "/create-user-notification",
      obj: {},
    };
  }, []);

  function getUserNotification() {
    /** FETCH MENU */
    let body = {
      profileId,
      entityTypeId:companyTypeId,
      session,
    };
    BOM.FetchReqAction(body, api.GetUserNotification, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let objList = res.notifListing;

        let temp = [];

        objList.forEach((element, index) => {
          temp.push({
            userId: element?.PROFILE_ID,
            username: element?.USERNAME,
            parentRoleName: element?.PARENT_ROLE_NAME,
            roleName: element?.SUB_ROLE_NAME,
            notificationData: element?.NOTIF_DATA.map((item) => {
              return {
                notifId: item.NOTIF_ID,
                notifName: item.NOTIF_NAME,
              };
            }),
          });
        });
        // console.log(temp);
        setUserNotifData(temp);

        //activate right btn action btn
        if (objList.length > 0) {
          RAL.disableEnableRightAction([cnt.RIGHT_EDIT_BTN]);
        } else {
          RAL.disableEnableRightAction([cnt.RIGHT_ADD_BTN]);
        }
      }
    });
  }

  const formSubmit = () => {
    // console.log("data", formValues);
    if (userNotifData.length === 0) {
      BOM.AlertMsg(cnt.WARNING, "Please configure user notification.");
      return;
    }
    let body = {
      profileId,
      userNotifData,
      session,
    };

    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(body, api.CreateUserNotification, "/manage-bank");
  };

  const deleteUserNotification = (objPram) => {
    let param = {
      apiUrl: api.DeleteUserNotification,
      action: "Remove ",
      label: objPram.username,
      isLoader: true,
    };

    param.body = {
      profileId,
      userId: objPram.userId,
      session,
    };
    BOM.ConfirmAction(param, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        BOM.AlertMsg(cnt.SUCCESS, res.message);
        getUserNotification();
      }
    });
  };

  return (
    <Main {...props} onSaveChange={formSubmit}>
      <ContentTop pageTitle={appLabel.userNotification} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <HandleSkeletonDetailFac
              classParam={`${isLoading ? "" : "hide"}`}
            />
            <div className={`${isLoading ? "hide" : "ibox"}`}>
              <div className="ibox-content content-card-v2 panel-primary">
                <div className="row">
                  {userNotifData.length === 0 && !isSetup ? (
                    <div
                      style={{ marginTop: "20px" }}
                      className="alert alert-success text-center"
                    >
                      {appLabel.noDataMsg}
                      <br />

                      <a
                        className="alert-link"
                        onClick={() =>
                          history.push("/create-user-notification")
                        }
                      >
                        <i className="fa fa-plus-circle fa-2x" />
                        <br />
                        {`Add New User Notification`}
                      </a>
                    </div>
                  ) : (
                    <>
                      <ColBlockFac topTitle={appLabel.listOfUserNotification}>
                        {userNotifData.map((item, index) => (
                          <div className="col-sm-3" key={index}>
                            <div className="ibox notification-card">
                              <div className="ibox-content">
                                {/* <h5>Profile {index + 1}</h5> */}
                                <h5
                                  className="text-center"
                                >
                                  <i className="fa fa-user text-navy of-icon m-r" />
                                  <i
                                    className="fa fa-times-circle text-danger of-icon"
                                    onClick={() => deleteUserNotification(item)}
                                    style={{ cursor: "pointer" }}
                                  />
                                </h5>
                                <h5>&nbsp;{item.username}</h5>
                                <h6 className="text-center">{`${item.roleName} ( ${item.parentRoleName} )`}</h6>
                                <table className="table table-stripped small m-t-md">
                                  <tbody>
                                    {BOM.GetUnique(
                                      item?.notificationData,
                                      "notifId"
                                    ).map((item2, sub) => (
                                      <tr key={sub}>
                                        <td>
                                          <i className="fa fa-bell text-navy" />
                                        </td>
                                        <td>{item2.notifName}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ))}
                      </ColBlockFac>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* { !isLoading && <RecordStatus {...recordData} />} */}
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageUserNotification);
