import axios from "axios";
import appConfig from "../config/appConfig.json";

/** DEFINED METHOD ONLY FOR THIS PAGE */
const IsProductionUrl = (key) => {

  switch (key) {
    case true:
      return appConfig.baseURL.prod;
    case false:
      return appConfig.baseURL.dev;
  }
};


/** LIVE SERVICE */
export default axios.create({
  baseURL: IsProductionUrl(appConfig.isProduction),
});

/** HEADER */
export const headerOptions = {
  headers: appConfig.apiSettings.headers
};

/** APIS CONSTANT */
export const apis = {
  /** AUTH */
  Authentication: "authentication",
  ChangePassword: "changePassword",
  ForgotPassword: "forgotPassword",
  ResetPassword: "resetPassword",

  /** SECURITY */
  //Role
  GetRole: "getRole",
  ApproveRejectRole: "approveRejectRole",
  GetSubRole: "getSubRole",
  CreateSubRole: "createSubRole",
  UpdateRole: "updateRole",
  GetRoleMenu: "getRoleMenu",
  GetEntitySubRole: "getEntitySubRole",
  GetRolesByEntity: "getRolesByEntity",
  GetUserNotification: "getUserNotification",
  CreateUserNotification: "createUserNotification",
  DeleteUserNotification: "deleteUserNotification",

  //Entity or Company
  GetEntityMenu: "getEntityMenu",
  GetCompanyType: "getCompanyType",
  AssignEntityMenu: "assignEntityMenu",

  //Menu
  GetMenu: "getMenu",
  AssignRoleMenu: "assignRoleMenu",

  //Remove menu
  DeleteRoleMenuId: "deleteRoleMenuId",
  DeleteEntityMenuId: "deleteEntityMenuId",

  // User
  GetUser: "getUser",
  CreateUser: "createUser",
  UpdateUser: "updateUser",
  ApproveRejectUser: "approveRejectUser",
  // GetUserAudit: "getUserAudit",

  /** COUNTRY */
  GetCountry: "getCountry",
  GetCity: "getCity",
  GetAllCountry: "getAllCountry",

  /** COMPANY */
  GetCompany: "getCompany",
  CreateCompany: "createCompany",
  KycVerification: "kycVerification",
  UpdateCompany: "updateCompany",
  ApproveRejectCompany: "approveRejectCompany",
  ProspectKycVerification: "prospectKycVerification",
  CreateProspectCompany: "createProspectCompany",

  //ProspectCompany
  GetProspectCompany: "getProspectCompany",
  CreateProspectCompany: "createProspectCompany",
  ApproveRejectProspectCompany: "approveRejectProspectCompany",
  UpdateProspectCompany: "updateProspectCompany",
  GetProspectBank:"getProspectBank",

  /** BANK */
  GetBank: "getBank",

  /** PURCHASE ORDER */
  GetPo: "getPo",
  CreatePo: "createPo",
  ApproveRejectPo: "approveRejectPo",
  VerifyPo: "verifyPo",
  GetVerifiedPo: "getVerifiedPo",
  InitiatePo: "initiatePo",
  PoShare: "poShare",
  FundPo: "fundPo",
  GetSharedPo: "getSharedPo",
  GetFundedPo: "getFundedPo",
  ApproveRejectFundedPo: "approveRejectFundedPo",
  GetPoToVerify: "getPoToVerify",
  ApproveRejectConsent: "approveRejectConsent",
  GeneratePoToken: "generatePoToken",
  ResendOtp: "resendOtp",
  OtpValidation: "otpValidation",
  GetConsentedPo: "getConsentedPo",
  CheckPoFees: "checkPoFees",
  ApproveRejectPoIntent: "approveRejectPoIntent",

  /** CURRENCY */
  GetCurrency: "getCurrency",
  GetConfCurrency: "getConfCurrency",

  /** MATERIAL */
  CreateMaterial: "createMaterial",

  /** BATCH UPLOAD */
  GetBatch: "getBatch",
  CreateBatch: "createBatch",
  ApproveRejectBatch: "approveRejectBatch",

  /** GET GLOBAL DOCUMENT */
  GetDocument: "getDocument",
  CreateGenericDocument: "createGenericDocument",
  CreateProspectGenericDocument: "createProspectGenericDocument",
  
  /** CONFIGURATION */
  //Tax
  GetTax: "getTax",
  CreateTax: "createTax",
  // UpdateTax:"updateTax",
  ApproveRejectTax: "approveRejectTax",

  //Currency
  GetCurrency: "getCurrency",
  CreateCurrency: "createCurrency",
  // UpdateCurrency:"updateCurrency",
  ApproveRejectCurrency: "approveRejectCurrency",
  GetCurrencyCode:"getCurrencyCode",


  //Surcharge
  GetSurcharge: "getSurcharge",
  CreateSurcharge: "createSurcharge",
  UpdateSurcharge: "updateSurcharge",
  ApproveRejectSurcharge: "approveRejectSurcharge",

  //Trust Account
  GetTrustAccount: "getTrustAccount",
  CreateTrustAccount: "createTrustAccount",
  // UpdateTrustAccount:"updateTrustAccount",
  ApproveRejectTrustAccount: "approveRejectTrustAccount",

  //Bank
  GetBank: "getBank",
  CreateBank: "createBank",
  // UpdateBank:"updateBank",
  ApproveRejectBank: "approveRejectBank",

  // Company bank
  GetCompanyBank: "getCompanyBank",
  CreateCompanyBank: "createCompanyBank",
  ApproveRejectCompanyBank: "approveRejectCompanyBank",

  // Ad-Hoc
  GetConfig: "getConfig",
  UpdateConfig: "updateConfig",
  CreateEntityNotification: "createEntityNotification",
  GetEntityNotification: "getEntityNotification",
  ApproveRejectEntityNotification: "approveRejectEntityNotification",
  
  // Report
  GetAudit: "getAudit",
  GetPoByStatusReport: "getPoByStatusReport",
  GetSharedPoReport: "getSharedPoReport",
  GetFinancedPoReport: "getFinancedPoReport",

  GetInvoiceReport: "getInvoiceReport",
  GetSharedInvoiceReport: "getSharedInvoiceReport",
  GetDiscountedInvoiceReport: "getDiscountedInvoiceReport",
  GetContractReport: "getContractReport",
  GetLoanPortfolioList:"getLoanPortfolioList",
  GetLoanPortfolioDetails:"getLoanPortfolioDetails",
  GetBiddingReport:"getBiddingReport",
  GetFunderSettlementReport:"getFunderSettlementReport",
  GetSupplierSettlementReport:"getSupplierSettlementReport",

  // Contract
  GetContract: "getContract",
  CreateContract: "createContract",
  ApproveRejectContract: "approveRejectContract",

  // Invoice
  GetInvoice: "getInvoice",
  CreateInvoice: "createInvoice",
  // UpdateInvoice:"updateInvoice",
  ShareInvoice: "shareInvoice",
  ApproveRejectInvoice: "approveRejectInvoice",
  GetSharedInvoice: "getSharedInvoice",
  InitiateInvoice: "initiateInvoice",
  GetInvoiceToVerify: "getInvoiceToVerify",
  VerifyInvoice: "verifyInvoice",
  FundInvoice: "fundInvoice",
  GetFundedInvoice: "getFundedInvoice",
  GetConsentedInvoice: "getConsentedInvoice",
  CheckInvoiceFees: "checkInvoiceFees",
  ApproveRejectInvIntent: "approveRejectInvIntent",

  //Discount not in use
  ApproveRejectDiscount: "approveRejectDiscount",

  // LIST DASHBOARD
  GetDashBoard: "getDashBoard",
  GetGenericDashBoard: "getGenericDashBoard",

  //SUPPLIER SHARE INTENT
  GetIntent: "getIntent",
  UpdatePoNoteInfo: "updatePoNoteInfo",
  UpdateInvNoteInfo: "updateInvNoteInfo",

  //BID
  // PO BID
  RejectBid: "rejectBid",
  PreApprovePoBid: "preApprovePoBid",
  ApproveRejectPODepositoryBid: "approveRejectPODepositoryBid",
  GetBid: "getBid",
  GetIntentFunders: "getIntentFunders",

  //INV BID
  PreApproveInvBid: "preApproveInvBid",
  ApproveRejectInvDepositoryBid: "approveRejectInvDepositoryBid",

  // FEES
  GetFees: "getFees",
  CreateFee: "createFee", 
  ApproveRejectFee: "approveRejectFee",
  UpdateFee: "updateFee",

  //Ts and Cs
  CreateTermsCondition: "createTermsCondition",

};