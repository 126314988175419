import React, { useEffect, useState } from "react";
import Main from "../layouts/Main";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { apis as api } from "../../services/api.action";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM, RAL } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import NumberFormat from "react-number-format";
import { TableFacility, HandleSkeletonTbleFac } from "../layouts/Facility";


const ManageFinancedOrder = (props) => {

  const { userData, menuData, location } = props;
  const { profileId, session, companyId } = userData;
  const { tableData } = appStatic;

  const globalVar = window.globalData;
  const dispatch = useDispatch();
  const [poData, setPoData] = useState([]);
  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [tiles, setTiles] = useState([
    {
      title: appLabel.authorized,
      otherTitle: appLabel.authorizedListing,
      color: "primary",
      icon: "tag",
      criteria: "A",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.unauthorized,
      otherTitle: appLabel.unauthorizedListing,
      color: "warning",
      icon: "exclamation-triangle",
      criteria: "U",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.disabled,
      otherTitle: appLabel.disabledListing,
      color: "danger",
      icon: "close",
      criteria: "R",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
  ]);

  useEffect(() => {
    // console.log(props);
    getListByCriteria(tiles[0]); // get position 0 object
  }, []);

  function getListByCriteria(params) {
    // console.log(params);
    setPoData([]);

    setTitleLeft(params.otherTitle);
    setTitleRight(params.title);
    setColor(params.color);
    setIcon(params.icon);

    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true)

    let body = {
      profileId,
      orderId: "",
      criteria: params.criteria,
      companyId,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetFundedPo, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setPoData(res.purchaseOrderListing);
        BOM.DatatableUsage();
        setIsLoading(false)
      }
    });
  }

  const onSelectPo = (objParam) => {
    /** CHECK FOR RIGHT BTN PRIVILEGES */
    let path = appStatic.pathData.find((item) => item.URL === location.pathname);
    if (path) {
      if (RAL.viewAction(menuData, path.DESCRIPTION)) {
        dispatch({
          type: cnt.RIGHT_DETAIL_BTN
        });
      }
    }

    globalVar.viewLink = {
      path: "/financed-order-detail",
      obj: objParam,
    };
  };

  /** RENDER JXS DATA */
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.financedOrder} />
      <div className="animated fadeInRightFAKE">
        <div className="row">
          <form className="m-t" autoComplete="off">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>{titleLeft}</h5>
                </div>

                <div className="ibox-content panel-primary contentCard">
                <HandleSkeletonTbleFac
                    classParam={`${isLoading ? "" : "hide"}`}
                  />
                  <div className={` ${isLoading ? "hide" : ""}`}>
                  {poData.length === 0 ? (
                    <div className="alert alert-warning text-center">
                      <a className="alert-link">{appLabel.noDataInTableMsg}</a>
                    </div>
                  ) : (
                      <TableFacility thData={tableData.poFundedList}>
                      {poData.map((item, index) => (
                        <tr className="gradeX" key={index}>
                          <td>
                            <label className="myradio-button">
                              <input
                                type="radio"
                                name="radio"
                                onClick={() => onSelectPo(item)}
                              />
                              <span className="label-visible">
                                <span className="myfake-radiobutton"></span>
                              </span>
                            </label>
                          </td>
                          <td>{item.PAYMENT_DATE}</td>
                          <td>{item.ORDERID}</td>
                          <td>{item.NOTE_CODE}</td>
                          <td>{item.FUNDER_RATE}</td>
                          <td>
                            <NumberFormat
                              value={item.PO_AMOUNT}
                              displayType="text"
                              thousandSeparator={true}
                              prefix={item.PO_CCY ? item.PO_CCY + " " : null}
                            />
                          </td>
                        </tr>
                      ))}
                    </TableFacility>
                  )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ManageFinancedOrder);
