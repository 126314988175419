import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../../services/api.action";
import { connect, useDispatch } from "react-redux";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../../ui-setup/RecordStatus";

export const CurrencyDetail = (props) => {
  const { location, userData, configData, recordData, menuData } = props;
  const { state } = location;
  const { profileId, session, companyId } = userData;
  const { isCsd } = configData;

  const dispatch = useDispatch();
  const [currencyDetail, setCurrencyDetail] = useState({});

  useEffect(() => {
    // console.log(state.ggParam);
    
    getDetailById(state.ggParam);
  }, []);

  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      currencyId: params.CCY_ID,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetCurrency, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let currencyDetail = res.currencyDetail;

        setCurrencyDetail(currencyDetail);
        dispatch({
          type: cnt.RECORD_STATUS,
          payload: currencyDetail,
        });
        //handle right side
        BOM.IsApproveOrReject(currencyDetail.FLG_STATUS, menuData, "Manage Currency");
      }
    });
  };

  const onApprove = () => {
    let bodyApprove = {
      profileId,
      currencyId: state.ggParam.CCY_ID,
      criteria: "A",
      session,
    };

    // console.log(bodyApprove);
    BOM.GlobalApproveRejectAction(
      bodyApprove,
      api.ApproveRejectCurrency,
      "manage-currency",
      "Currency",
      "Approve "
    );
  }
  const onReject = () => {
    let bodyDisable = {
      profileId,
      currencyId: state.ggParam.CCY_ID,
      criteria: "R",
      session,
    };

    // console.log(bodyDisable);
    BOM.GlobalApproveRejectAction(
      bodyDisable,
      api.ApproveRejectCurrency,
      "manage-currency",
      "Currency",
      "Reject "
    );
  }
  
  return (
   <Main {...props} onApprove={onApprove} onReject={onReject}>
      <ContentTop pageTitle={appLabel.currencyDetail} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{appLabel.currencyInfo}</h5>
                <div className="ibox-tools">
                  <Link to="/manage-currency" className="btn btn-primary btn-xs">
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;
                    {appLabel.backBtn}
                  </Link>
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.generalInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.description}&nbsp;
                            
                          </label>
                        </div>
                        <div className="col-md-7">
                        <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={currencyDetail.CCY_DESC}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.currency} 
                          </label>
                        </div>
                        <div className="col-md-7">
                        <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={currencyDetail.CCY_CODE}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.isoCode}{" "}
                            
                          </label>
                        </div>
                        <div className="col-md-7">
                        <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={currencyDetail.CCY_ISO_CODE}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.country}{" "}
                            
                          </label>
                        </div>
                        <div className="col-md-7">
                        <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={currencyDetail.CCY_COUNTRY}
                            disabled
                          />
                        </div>
                      </div>
                      
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RecordStatus {...recordData} />
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  configData: state.auth.configData,
  recordData: state.recordStatus
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyDetail);
