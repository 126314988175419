import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "../Report.css";
import Main from "../../../components/layouts/Main";
import { useForm } from "react-hook-form";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import NumberFormat from "react-number-format";
import { apis as api } from "../../../services/api.action";
import appStatic from "../../../config/appStaticData";
import appLabel from "../../../config/appLabel";
import { Tooltips,AmntFormatFac } from "../../../components/layouts/Facility";

export const POReport = (props) => {
  const { toolTip } = appStatic;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    setValue: setValue2,
  } = useForm();

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    setValue: setValue3,
  } = useForm();

  const { userData } = props;
  const { profileId, session, companyId, companyTypeId } = userData;

  const [isAuth, setIsAuth] = useState(false);
  const [isPoNo, setIsPoNo] = useState(false);
  const [isVerif, setIsVerif] = useState(false);
  const [isDateRange, setIsDateRange] = useState(false);
  const [isFunding, setIsFunding] = useState(false);
  const [poData, setPoData] = useState([]);
  const [sharePoData, setSharePoData] = useState([]);
  const [financedPoData, setFinancedPoData] = useState([]);
  const [labelData, setLabelData] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState({});
  const [isCustodian, setIsCustodian] = useState(false);

  useEffect(() => {
    setLabelData(appStatic.reporting.poLabelData);
    requiredJqueryDOM();

    switch (
      companyTypeId // not in use so far
    ) {
      case "CT2022012400000":
        setIsCustodian(true);
        break;

      default:
        setIsCustodian(false);
        break;
    }
  }, []);

  function requiredJqueryDOM() {
    let d = new Date();
    d.setMonth(d.getMonth() - 3);
    let lastThreeMonth = BOM.ReformatDate(d.toLocaleDateString());

    // console.log("lastThreeMonth ", lastThreeMonth);
    $(document).ready(function () {
      $("#startDate, .startDate, .startDate3").datepicker({
        todayBtn: "linked",
        keyboardNavigation: false,
        forceParse: false,
        calendarWeeks: true,
        autoclose: true,
        startDate: new Date(lastThreeMonth),
        endDate: new Date(BOM.CurrentDate()),
      });

      $("#endDate, .endDate, .endDate3").datepicker({
        todayBtn: "linked",
        keyboardNavigation: false,
        forceParse: false,
        calendarWeeks: true,
        autoclose: true,
        startDate: new Date(lastThreeMonth),
        endDate: new Date(BOM.CurrentDate()),
      });
    });
  }
  const onSelectType = (key) => {
    // console.log(key);
    // CLEAR ALL PREVIOUS TYPE
    setIsAuth(false);
    setIsPoNo(false);
    setIsVerif(false);
    setIsDateRange(false);
    setIsFunding(false);

    setValue("orderId", "");
    setValue2("orderId", "");
    setValue3("orderId", "");
    // unregister("")
    $(".clearInput").val("");

    switch (key.toLowerCase()) {
      case "auth":
        setIsAuth(true);
        break;
      case "verif":
        setIsVerif(true);
        break;
      case "ponumber":
        setIsPoNo(true);
        break;
      case "date":
        setIsDateRange(true);
        break;
      case "fundstatus":
        setIsFunding(true);
        break;
    }
  };

  const onSelectPOReport = (idParam) => {
    /** CLEAR PEVIOUS DATA */
    setPoData([]);
    setSharePoData([]);
    setIsAuth(false);
    setIsPoNo(false);
    setIsVerif(false);
    setIsDateRange(false);
    setIsFunding(false);
    /** SOME LOGIC ON THE SELECTED CARD */
    let newArray = labelData.map((item) =>
      item.id === idParam
        ? { ...item, isSelected: 1 }
        : { ...item, isSelected: 0 }
    );
    setLabelData(newArray);

    setSelectedLabel(newArray.find((item) => item.id === idParam));
  };
  const searchByStatus = (formValues) => {
    setPoData([]);
    let startDate = document.getElementById("startDate").value;
    let endDate = document.getElementById("endDate").value;

    if (!startDate && (isAuth || isVerif)) {
      BOM.AlertMsg(cnt.WARNING, "Start date is required.");
      return;
    }

    if (!endDate && (isAuth || isVerif)) {
      BOM.AlertMsg(cnt.WARNING, "End date is required.");
      return;
    }

    /** SEND PROPER CRITERIA */
    if (isAuth) {
      formValues.criteria = formValues.authCriteria;
    }
    if (isVerif) {
      formValues.criteria = formValues.verifCriteria;
    }

    formValues.startDate = startDate ? BOM.ReformatDate(startDate) : "";
    formValues.endDate = endDate ? BOM.ReformatDate(endDate) : "";
    let body = {
      profileId,
      session,
      ...{
        criteria: formValues.criteria,
        startDate: formValues.startDate,
        endDate: formValues.endDate,
        orderId: formValues.orderId,
        searchType: formValues.searchType,
      },
    };

    // console.log(body);
    /** FETCH ROLE */
    BOM.LoadAlert(cnt.LOAD, "Searching...");
    BOM.FetchReqAction(body, api.GetPoByStatusReport, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setPoData(res.purchaseOrderListing);
        BOM.DatatableUsage();
      }
    });
  };
  const searchSharedPO = (formValues) => {
    setSharePoData([]);
    let startDate = $(".startDate").val();
    let endDate = $(".endDate").val();

    if (!startDate && isDateRange) {
      BOM.AlertMsg(cnt.WARNING, "Start date is required.");
      return;
    }

    if (!endDate && isDateRange) {
      BOM.AlertMsg(cnt.WARNING, "End date is required.");
      return;
    }

    formValues.startDate = startDate ? BOM.ReformatDate(startDate) : "";
    formValues.endDate = endDate ? BOM.ReformatDate(endDate) : "";
    let body = {
      profileId,
      session,
      ...formValues,
    };

    // console.log(body);
    /** FETCH ROLE */
    BOM.LoadAlert(cnt.LOAD, "Searching...");
    BOM.FetchReqAction(body, api.GetSharedPoReport, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setSharePoData(res.purchaseOrderListing);
        BOM.DatatableWithNoPdfDownload();
      }
    });
  };
  const searchFinancedPO = (formValues) => {
    //clear previous data
    setFinancedPoData([]);

    let startDate = $(".startDate3").val();
    let endDate = $(".endDate3").val();

    if (!startDate && isFunding) {
      BOM.AlertMsg(cnt.WARNING, "Start date is required.");
      return;
    }

    if (!endDate && isFunding) {
      BOM.AlertMsg(cnt.WARNING, "End date is required.");
      return;
    }

    formValues.startDate = startDate ? BOM.ReformatDate(startDate) : "";
    formValues.endDate = endDate ? BOM.ReformatDate(endDate) : "";
    let body = {
      profileId,
      session,
      ...formValues,
    };

    // console.log(body);
    /** FETCH ROLE */
    BOM.LoadAlert(cnt.LOAD, "Searching...");
    BOM.FetchReqAction(body, api.GetFinancedPoReport, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setFinancedPoData(res.purchaseOrderListing);
        BOM.DatatableWithNoPdfDownload();
      }
    });
  };
  return (
    <Main {...props}>
      {/* <ContentTop pageTitle='Dashboard'/> */}
      <div className="row">
        {appStatic.reporting.type === "po" &&
          labelData.map((item, index) => (
            <div
              className="col-md-6 col-lg-3"
              key={index}
              onClick={() => onSelectPOReport(item.id)}
            >
              <div className="ibox">
                <div
                  className={`ibox-content ${
                    item.isSelected ? "reportCardSelected" : "reportCard"
                  }`}
                >
                  <h5>{appLabel.report}</h5>
                  <h3>
                    {" "}
                    <i className={`${item.icon} text-navy`} /> &nbsp;{" "}
                    {item.name}
                  </h3>
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className="animated fadeInRight">
        <div
          className={`row ${
            selectedLabel.isSelected === 1 && selectedLabel.id === "1"
              ? ""
              : "hide"
          }`}
        >
          <form
            className="m-t"
            onSubmit={handleSubmit(searchByStatus)}
            autoComplete="off"
          >
            <div className="col-lg-12">
              <div className="ibox-title">
                <h5>{selectedLabel.name}</h5>
              </div>
              <div className="ibox-content m-b-sm border-bottom contentCard panel-primary">
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-search text-navy"></i>&nbsp;{" "}
                      {appLabel.searchCriteria}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label className="control-label">
                          {appLabel.type}&nbsp;
                          <Tooltips title={toolTip.searchType} />{" "}
                          <span className="asterisks">*</span>
                        </label>
                        <select
                          className="form-control input-sm"
                          {...register("searchType", {
                            required: "Field is required.",
                            onChange: (e) => onSelectType(e.target.value),
                          })}
                        >
                          <option value="">{appLabel.choose}</option>
                          {appStatic.pOStatusCriteria.map((item, index) => (
                            <option value={item.value} key={index}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <span className="asterisks">
                          {errors?.searchType?.message}
                        </span>
                      </div>
                    </div>
                    <div className={`col-sm-3 ${isAuth ? "" : "hide"}`}>
                      <div className="form-group">
                        <label className="control-label">
                          {appLabel.criteria}&nbsp;
                          <Tooltips title={toolTip.searchCriteria} />
                          <span className="asterisks">*</span>
                        </label>
                        <select
                          className="form-control input-sm clearInput"
                          {...register("authCriteria", {
                            required: isAuth ? "Field is required." : false,
                          })}
                        >
                          <option value="">{appLabel.choose}</option>
                          {appStatic.flgStatusData.map((item, index) => (
                            <option value={item.value} key={index}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <span className="asterisks">
                          {errors?.authCriteria?.message}
                        </span>
                      </div>
                    </div>
                    <div className={`col-sm-2 ${isVerif ? "" : "hide"}`}>
                      <div className="form-group">
                        <label className="control-label">
                          {appLabel.criteria}&nbsp;
                          <Tooltips title={toolTip.searchCriteria} />
                          <span className="asterisks">*</span>
                        </label>
                        <select
                          className="form-control input-sm clearInput"
                          {...register("verifCriteria", {
                            required: isVerif ? "Field is required." : false,
                          })}
                        >
                          <option value="">{appLabel.choose}</option>
                          {appStatic.verifyStatusData.map((item, index) => (
                            <option value={item.value} key={index}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <span className="asterisks">
                          {errors?.verifCriteria?.message}
                        </span>
                      </div>
                    </div>
                    <div className={`col-sm-3 ${isPoNo ? "" : "hide"}`}>
                      <div className="form-group">
                        <label className="control-label">
                          {appLabel.poNo} <span className="asterisks">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control input-sm clearInput"
                          placeholder={appLabel.poNo}
                          {...register("orderId", {
                            required: isPoNo ? "Field is required." : false,
                          })}
                        />
                        <span className="asterisks">
                          {errors?.orderId?.message}
                        </span>
                      </div>
                    </div>
                    <div
                      className={`col-sm-2 ${isAuth || isVerif ? "" : "hide"}`}
                    >
                      <div className="form-group">
                        <label className="control-label" htmlFor="startDate">
                          {appLabel.startDate}&nbsp;
                          <Tooltips title={toolTip.startDate} />
                          <span className="asterisks">*</span>
                        </label>
                        <div className="input-group date">
                          <span className="input-group-addon">
                            <i className="fa fa-calendar" />
                          </span>
                          <input
                            id="startDate"
                            type="text"
                            className="form-control input-sm clearInput"
                          />

                          <span className="asterisks">
                            {errors?.startDate?.message}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`col-sm-2 ${isAuth || isVerif ? "" : "hide"}`}
                    >
                      <div className="form-group">
                        <label className="control-label" htmlFor="endDate">
                          {appLabel.endDate}&nbsp;
                          <Tooltips title={toolTip.endDate} />
                          <span className="asterisks">*</span>
                        </label>
                        <div className="input-group date">
                          <span className="input-group-addon">
                            <i className="fa fa-calendar" />
                          </span>
                          <input
                            id="endDate"
                            type="text"
                            className="form-control input-sm clearInput"
                          />
                          <span className="asterisks">
                            {errors?.endDate?.message}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`col-sm-2 ${
                        isAuth || isVerif || isPoNo ? "" : "hide"
                      }`}
                    >
                      <div className="form-group">
                        <label className="control-label">&nbsp;</label>
                        <div className="input-group date">
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm"
                          >
                            <i className="fa fa-search" />
                            &nbsp;
                            {appLabel.search}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-list text-navy"></i>&nbsp;{" "}
                      {appLabel.result}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="table-responsive">
                      {poData.length === 0 ? (
                        <div className="alert alert-warning text-center">
                          <a className="alert-link">
                            {appLabel.noDataInTableMsg}
                          </a>
                        </div>
                      ) : (
                        <div>
                          {" "}
                          <div className="table-responsive">
                            <table
                              id="myTable"
                              className="table table-striped table-bordered table-hover"
                            >
                              <thead>
                                <tr>
                                  <th>{appLabel.poIssueDate}</th>
                                  <th>{appLabel.csdNumber}</th>
                                  <th>{appLabel.noteName}</th>
                                  <th>{appLabel.noteCode}</th>
                                  <th>{appLabel.lpoNumber}</th>
                                  <th>{appLabel.maturityDate}</th>
                                  <th>{appLabel.registrar}</th>
                                  <th>{appLabel.poIssuer}</th>
                                  <th>{appLabel.vendor}</th>
                                  <th>{appLabel.country}</th>
                                  <th>{appLabel.currency}</th>
                                  <th>{appLabel.totalAmountPayable}</th>
                                  <th>{appLabel.bankName}</th>
                                  <th>{appLabel.accountName}</th>
                                  <th>{appLabel.accountNumber}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {poData.map((order, index) => (
                                  <tr className="gradeX" key={index}>
                                    <td>{order.PO_ISSUE_DATE}</td>
                                    <td>{order.CSD_NUMBER}</td>
                                    <td>{order.NOTE_NAME}</td>
                                    <td>{order.NOTE_CODE}</td>
                                    <td>{order.LPO_REFERENCE_ID}</td>
                                    <td>{order.MATURITY_DATE}</td>
                                    <td>{order.REGISTRAR}</td>
                                    <td>{order.PO_ISSUER}</td>
                                    <td>{order.PO_RECIPIENT}</td>
                                    <td>{order.COUNTRY}</td>
                                    <td>{order.PO_CURRENCY}</td>
                                    <td>
                                      <NumberFormat
                                        value={order.PO_AMOUNT}
                                        displayType="text"
                                        thousandSeparator={true}
                                        prefix={order.PO_CURRENCY + " "}
                                      />
                                    </td>

                                    <td>{order.BANK_NAME}</td>
                                    <td>{order.ACC_NAME}</td>
                                    <td>{order.ACC_NUM}</td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <th>{appLabel.poIssueDate}</th>
                                  <th>{appLabel.csdNumber}</th>
                                  <th>{appLabel.noteName}</th>
                                  <th>{appLabel.noteCode}</th>
                                  <th>{appLabel.lpoNumber}</th>
                                  <th>{appLabel.maturityDate}</th>
                                  <th>{appLabel.registrar}</th>
                                  <th>{appLabel.poIssuer}</th>
                                  <th>{appLabel.vendor}</th>
                                  <th>{appLabel.country}</th>
                                  <th>{appLabel.currency}</th>
                                  <th>{appLabel.totalAmountPayable}</th>
                                  <th>{appLabel.bankName}</th>
                                  <th>{appLabel.accountName}</th>
                                  <th>{appLabel.accountNumber}</th>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div
          className={`row ${
            selectedLabel.isSelected === 1 && selectedLabel.id === "2"
              ? ""
              : "hide"
          }`}
        >
          <form
            className="m-t"
            // onSubmit={handleSubmit(searchByStatus)}
            autoComplete="off"
          >
            <div className="col-lg-12">
              <div className="ibox-title">
                <h5>{selectedLabel.name}</h5>
              </div>
              <div className="ibox-content m-b-sm border-bottom contentCard panel-primary">
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-search text-navy"></i>&nbsp;{" "}
                      {appLabel.searchCriteria}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label className="control-label">
                          {appLabel.type}&nbsp;
                          <Tooltips title={toolTip.searchType} />
                          <span className="asterisks">*</span>
                        </label>
                        <select
                          className="form-control input-sm"
                          {...register2("searchType", {
                            required: "Field is required.",
                            onChange: (e) => onSelectType(e.target.value),
                          })}
                        >
                          <option value="">{appLabel.choose}</option>
                          {appStatic.pOSharedCriteria.map((item, index) => (
                            <option value={item.value} key={index}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <span className="asterisks">
                          {errors2?.searchType?.message}
                        </span>
                      </div>
                    </div>
                    <div className={`col-sm-3 ${isPoNo ? "" : "hide"}`}>
                      <div className="form-group">
                        <label className="control-label">
                          {appLabel.poNo} <span className="asterisks">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control input-sm clearInput"
                          placeholder={appLabel.poNo}
                          {...register2("orderId", {
                            required: isPoNo ? "Field is required." : false,
                          })}
                        />
                        <span className="asterisks">
                          {errors2?.orderId?.message}
                        </span>
                      </div>
                    </div>
                    <div className={`col-sm-2 ${isDateRange ? "" : "hide"}`}>
                      <div className="form-group">
                        <label className="control-label" htmlFor="startDate">
                          {appLabel.startDate}&nbsp;
                          <Tooltips title={toolTip.startDate} />
                          <span className="asterisks">*</span>
                        </label>
                        <div className="input-group date">
                          <span className="input-group-addon">
                            <i className="fa fa-calendar" />
                          </span>
                          <input
                            // id="startDate"
                            type="text"
                            className="form-control input-sm clearInput startDate"
                          />
                        </div>
                      </div>
                    </div>
                    <div className={`col-sm-2 ${isDateRange ? "" : "hide"}`}>
                      <div className="form-group">
                        <label className="control-label" htmlFor="endDate">
                          {appLabel.endDate}&nbsp;
                          <Tooltips title={toolTip.endDate} />
                          <span className="asterisks">*</span>
                        </label>
                        <div className="input-group date">
                          <span className="input-group-addon">
                            <i className="fa fa-calendar" />
                          </span>
                          <input
                            // id="endDate"
                            type="text"
                            className="form-control input-sm clearInput endDate"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={`col-sm-2 ${
                        isDateRange || isPoNo ? "" : "hide"
                      }`}
                    >
                      <div className="form-group">
                        <label className="control-label">&nbsp;</label>
                        <div className="input-group date">
                          <button
                            type="button"
                            onClick={handleSubmit2(searchSharedPO)}
                            className="btn btn-primary btn-sm"
                          >
                            <i className="fa fa-search" />
                            &nbsp;
                            {appLabel.search}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-list text-navy"></i>&nbsp;{" "}
                      {appLabel.result}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="table-responsive">
                      {sharePoData.length === 0 ? (
                        <div className="alert alert-warning text-center">
                          <a className="alert-link">
                            {appLabel.noDataInTableMsg}
                          </a>
                        </div>
                      ) : (
                        <div>
                          {" "}
                          <div className="table-responsive">
                            <table
                              id="myTable"
                              className="table table-striped table-bordered table-hover"
                            >
                              <thead>
                                <tr>
                                  <th>{appLabel.poNumber}</th>
                                  <th>{appLabel.poAmount}</th>
                                  <th>{appLabel.buyer}</th>
                                  <th>{appLabel.supplier}</th>
                                  <th>{appLabel.requestedAmount}</th>
                                  <th>{appLabel.indicativeInterestRate}</th>
                                  <th>{appLabel.tenor}</th>
                                  <th>{appLabel.noteName}</th>
                                  <th>{appLabel.noteNumber}</th>
                                  <th>{appLabel.noteCode}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {sharePoData.map((order, index) => (
                                  <tr className="gradeX" key={index}>
                                    <td>{order.PO_NUMBER}</td>
                                    <td>
                                      <AmntFormatFac
                                        inputValue={order.PO_AMOUNT}
                                      />
                                    </td>
                                    <td>{order.PO_ISSUER}</td>
                                    <td>{order.SUPPLIER}</td>
                                    <td>
                                      <AmntFormatFac
                                        inputValue={order.REQUESTED_AMOUNT}
                                      />
                                    </td>

                                    <td>{order.INDICATIVE_INTEREST_RATE}</td>
                                    <td>{order.TENOR}</td>
                                    <td>{order.NOTE_NAME}</td>
                                    <td>{order.NOTE_NUMBER}</td>
                                    <td>{order.NOTE_CODE}</td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <th>{appLabel.poNumber}</th>
                                  <th>{appLabel.poAmount}</th>
                                  <th>{appLabel.buyer}</th>
                                  <th>{appLabel.supplier}</th>
                                  <th>{appLabel.requestedAmount}</th>
                                  <th>{appLabel.indicativeInterestRate}</th>
                                  <th>{appLabel.tenor}</th>
                                  <th>{appLabel.noteName}</th>
                                  <th>{appLabel.noteNumber}</th>
                                  <th>{appLabel.noteCode}</th>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div
          className={`row ${
            selectedLabel.isSelected === 1 && selectedLabel.id === "3"
              ? ""
              : "hide"
          }`}
        >
          <form
            className="m-t"
            // onSubmit={handleSubmit(searchByStatus)}
            autoComplete="off"
          >
            <div className="col-lg-12">
              <div className="ibox-title">
                <h5>{selectedLabel.name}</h5>
              </div>
              <div className="ibox-content m-b-sm border-bottom contentCard panel-primary">
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-search text-navy"></i>&nbsp;{" "}
                      {appLabel.searchCriteria}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label className="control-label">
                          {appLabel.type}&nbsp;
                          <Tooltips title={toolTip.searchType} />
                          <span className="asterisks">*</span>
                        </label>
                        <select
                          className="form-control input-sm"
                          {...register3("searchType", {
                            required: "Field is required.",
                            onChange: (e) => onSelectType(e.target.value),
                          })}
                        >
                          <option value="">{appLabel.choose}</option>
                          {appStatic.pOFundCriteria.map((item, index) => (
                            <option value={item.value} key={index}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <span className="asterisks">
                          {errors3?.searchType?.message}
                        </span>
                      </div>
                    </div>
                    <div className={`col-sm-2 ${isFunding ? "" : "hide"}`}>
                      <div className="form-group">
                        <label className="control-label">
                          {appLabel.criteria}&nbsp;
                          <Tooltips title={toolTip.searchCriteria} />
                          <span className="asterisks">*</span>
                        </label>
                        <select
                          className="form-control input-sm clearInput"
                          {...register3("criteria", {
                            required: isFunding ? "Field is required." : false,
                          })}
                        >
                          <option value="">{appLabel.choose}</option>
                          {appStatic.fundingStatusData.map((item, index) => (
                            <option value={item.value} key={index}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <span className="asterisks">
                          {errors3?.criteria?.message}
                        </span>
                      </div>
                    </div>
                    <div className={`col-sm-3 ${isPoNo ? "" : "hide"}`}>
                      <div className="form-group">
                        <label className="control-label">
                          {appLabel.poNo} <span className="asterisks">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control input-sm clearInput"
                          placeholder={appLabel.poNo}
                          {...register3("orderId", {
                            required: isPoNo ? "Field is required." : false,
                          })}
                        />
                        <span className="asterisks">
                          {errors3?.orderId?.message}
                        </span>
                      </div>
                    </div>
                    <div className={`col-sm-2 ${isFunding ? "" : "hide"}`}>
                      <div className="form-group">
                        <label className="control-label" htmlFor="startDate">
                          {appLabel.startDate}&nbsp;
                          <Tooltips title={toolTip.startDate} />
                          <span className="asterisks">*</span>
                        </label>
                        <div className="input-group date">
                          <span className="input-group-addon">
                            <i className="fa fa-calendar" />
                          </span>
                          <input
                            // id="startDate"
                            type="text"
                            className="form-control input-sm clearInput startDate3"
                          />
                        </div>
                      </div>
                    </div>
                    <div className={`col-sm-2 ${isFunding ? "" : "hide"}`}>
                      <div className="form-group">
                        <label className="control-label" htmlFor="endDate">
                          {appLabel.endDate}&nbsp;
                          <Tooltips title={toolTip.endDate} />
                          <span className="asterisks">*</span>
                        </label>
                        <div className="input-group date">
                          <span className="input-group-addon">
                            <i className="fa fa-calendar" />
                          </span>
                          <input
                            // id="endDate"
                            type="text"
                            className="form-control input-sm clearInput endDate3"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={`col-sm-2 ${
                        isFunding || isPoNo ? "" : "hide"
                      }`}
                    >
                      <div className="form-group">
                        <label className="control-label">&nbsp;</label>
                        <div className="input-group date">
                          <button
                            type="button"
                            onClick={handleSubmit3(searchFinancedPO)}
                            className="btn btn-primary btn-sm"
                          >
                            <i className="fa fa-search" />
                            &nbsp;
                            {appLabel.search}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-list text-navy"></i>&nbsp;{" "}
                      {appLabel.result}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="table-responsive">
                      {financedPoData.length === 0 ? (
                        <div className="alert alert-warning text-center">
                          <a className="alert-link">
                            {appLabel.noDataInTableMsg}
                          </a>
                        </div>
                      ) : (
                        <div>
                          {" "}
                          <div className="table-responsive">
                            <table
                              id="myTable"
                              className="table table-striped table-bordered table-hover"
                            >
                              <thead>
                                <tr>
                                  <th>{appLabel.poNumber}</th>
                                  <th>{appLabel.transactionDate}</th>
                                  <th>{appLabel.buyer}</th>
                                  <th>{appLabel.supplier}</th>
                                  <th>{appLabel.poAmount}</th>
                                  <th>{appLabel.principalAmount}</th>
                                  <th>{appLabel._interestRate}</th>
                                  <th>{appLabel.tenor}</th>
                                  <th>{appLabel.valueDate}</th>
                                  <th>{appLabel.maturityDate}</th>
                                  <th>{appLabel.noteName}</th>
                                  <th>{appLabel.noteNumber}</th>
                                  <th>{appLabel.noteCode}</th>
                                  <th>{appLabel.supplierBankName}</th>
                                  <th>{appLabel.supplierAccountName}</th>
                                  <th>{appLabel.supplierAccountNumber}</th>
                                  <th>{appLabel.noOfFunder}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {financedPoData.map((order, index) => (
                                  <tr className="gradeX" key={index}>
                                    <td>{order.PO_NUMBER}</td>
                                    <td>{order.DATE_FUNDED}</td>
                                    <td>{order.PO_ISSUER}</td>
                                    <td>{order.PO_RECIPIENT}</td>
                                    <td>
                                      <NumberFormat
                                        value={order.PO_AMOUNT}
                                        displayType="text"
                                        thousandSeparator={true}
                                        prefix={order.PO_CURRENCY + " "}
                                      />
                                    </td>
                                    <td>
                                      <NumberFormat
                                        value={order.FUNDING_AMOUNT}
                                        displayType="text"
                                        thousandSeparator={true}
                                        prefix={order.PO_CURRENCY + " "}
                                      />
                                    </td>
                                    <td>{order.INTEREST_RATE}</td>
                                    <td>{order.TENOR}</td>
                                    <td>{order.VALUE_DATE}</td>
                                    <td>{order.MATURITY_DATE}</td>
                                    <td>{order.NOTE_NAME}</td>
                                    <td>{order.NOTE_NUMBER}</td>
                                    <td>{order.NOTE_CODE}</td>
                                    <td>{order.SUPPLIER_BANK_NAME}</td>
                                    <td>{order.SUPPLIER_ACCOUNT_NAME}</td>
                                    <td>{order.SUPPLIER_ACCOUNT_NUMBER}</td>
                                    <td>{order.NO_OF_FUNDER}</td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <th>{appLabel.poNumber}</th>
                                  <th>{appLabel.transactionDate}</th>
                                  <th>{appLabel.buyer}</th>
                                  <th>{appLabel.supplier}</th>
                                  <th>{appLabel.poAmount}</th>
                                  <th>{appLabel.principalAmount}</th>
                                  <th>{appLabel._interestRate}</th>
                                  <th>{appLabel.tenor}</th>
                                  <th>{appLabel.valueDate}</th>
                                  <th>{appLabel.maturityDate}</th>
                                  <th>{appLabel.noteName}</th>
                                  <th>{appLabel.noteNumber}</th>
                                  <th>{appLabel.noteCode}</th>
                                  <th>{appLabel.supplierBankName}</th>
                                  <th>{appLabel.supplierAccountName}</th>
                                  <th>{appLabel.supplierAccountNumber}</th>
                                  <th>{appLabel.noOfFunder}</th>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(POReport);
