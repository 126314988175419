import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { apis as api } from "../../../services/api.action";
import { cnt } from "../../../services/constant.action";
import { BOM, RAL } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import appStatic from "../../../config/appStaticData";
import { TableFacility, AmntFormatFac, HandleSkeletonTbleFac, } from "../../layouts/Facility";
import NumberFormat from "react-number-format";

const PublicBidList = (props) => {
  const { userData, menuData, location } = props;
  const { profileId, session, companyId } = userData;
  const globalVar = window.globalData;
  const { tableData } = appStatic;

  const dispatch = useDispatch();
  const [bidData, setBidData] = useState([]);
  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [tiles, setTiles] = useState([
    {
      title: appLabel.poBid,
      otherTitle: appLabel.poBidListing,
      color: "primary",
      icon: "list",
      itemType: "po",
      reqUrl: api.GetBid,
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.invoiceBid,
      otherTitle: appLabel.invoiceBidListing,
      color: "warning",
      icon: "list",
      itemType: "inv",
      reqUrl: api.GetBid,
      callback: function (params) {
        getListByCriteria(params);
      },
    },
  ]);

  useEffect(() => {
    getListByCriteria(tiles[0]);
  }, []);

  function getListByCriteria(params) {
    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true)

    setTitleLeft(params.otherTitle);
    setTitleRight(params.title);
    setColor(params.color);
    setIcon(params.icon);

    //clear previous data
    setBidData([]);
    let itemType = "";
    let itemId = "";

    switch (params.itemType.toLowerCase()) {
      case "po":
        itemType = params.itemType;
        break;
      case "inv":
        itemType = params.itemType;
        break;
    }

    let body = {
      profileId,
      itemType,
      itemId: "",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, params.reqUrl, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.bidListing;

        /** ORDER ITM S BY BID COUNT */
        const myData = []
          .concat(objList.public)
          .sort((a, b) => (parseInt(a.BID_COUNT) < parseInt(b.BID_COUNT) ? 1 : -1));
          setBidData([...myData]);

        BOM.DatatableUsage();
        setIsLoading(false)

      }
    });
  }

  const onSelectPo = (objParam) => {
    /** CHECK FOR RIGHT BTN PRIVILEGES */
    let path = appStatic.pathData.find(
      (item) => item.URL === location.pathname
    );
    if (path) {
      if (RAL.viewAction(menuData, path.DESCRIPTION)) {
        dispatch({
          type: cnt.RIGHT_DETAIL_BTN,
        });
      }
    }

    // console.log(objParam);

    const mergedJson = {shareType:'Public', ...objParam}
    // console.log(mergedJson);
    // return
    globalVar.viewLink = {
      path: "/manage-funder-bid-detail",
      obj: mergedJson,
    };
  };
  function hadleStatusIcon(key) {
    switch (key.toLowerCase()) {
      case "closed":
        return <i className="fa fa fa-play fa-rotate-90 text-danger" />;
      case "open":
        return <i className="fa fa-play fa-rotate-270 text-success" />;
    }
  }

  /** RENDER JXS DATA */
  return (
    <div className="animated fadeInRightFAKE">
      <div className="row">
        <form className="m-t" autoComplete="off">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>{titleLeft}</h5>
                <div className="ibox-tools">
                  <a className="dropdown-toggle" data-toggle="dropdown">
                    <span className={`badge badge-${color}`}>
                      <i className={`fa fa-${icon}`}></i> &nbsp; {titleRight}{" "}
                      &nbsp;
                      <i className="fa fa-chevron-down"></i>
                    </span>
                  </a>
                  <ul className="dropdown-menu dropdown-user">
                    {tiles.map((tile, tileIndex) => {
                      if (tile.title !== titleRight) {
                        return (
                          <li key={tileIndex}>
                            <a onClick={() => tile.callback(tile)}>
                              {" "}
                              {tile.title}
                            </a>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>

              <div className="ibox-content panel-primary contentCard">
              <HandleSkeletonTbleFac
                    classParam={`${isLoading ? "" : "hide"}`}
                  />
                  <div className={` ${isLoading ? "hide" : ""}`}>
                {bidData.length === 0 ? (
                  <div className="alert alert-warning text-center">
                    <a className="alert-link">{appLabel.noDataInTableMsg}</a>
                  </div>
                ) : (
                  <TableFacility thData={tableData.bidList}>
                    {bidData.map((item, index) => (
                      <tr className="gradeX" key={index}>
                        <td>
                          <label className="myradio-button">
                            <input
                              type="radio"
                              name="radio"
                              onClick={() => onSelectPo(item)}
                            />
                            <span className="label-visible">
                              <span className="myfake-radiobutton"></span>
                            </span>
                          </label>
                        </td>
                        <td>{item.INTENT_ID}</td>
                        <td>{item.ITEM_ID}</td>
                        <td>{item.BID_COUNT}</td>
                        <td>
                        <AmntFormatFac inputValue={item.REQUESTED_AMOUNT} />                       
                        </td>
                        <td>
                          {hadleStatusIcon(item.BIDDING_STATUS)}  {" "}
                          {item.BIDDING_STATUS}
                        </td>
                      </tr>
                    ))}
                  </TableFacility>
                )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default PublicBidList;
