import appLabel from "./appLabel";
import EndUserLicense from "../assets/documents/2023-11-End-User-License-Agreement.pdf";
import TermsAndConditions from "../assets/documents/2023-11-TERMS-AND-CONDITIONS-OF-USE-G.pdf";
import PrivacyPolicy from "../assets/documents/2023-11-Privacy-Policy-GH.pdf";
import Subscriber from "../assets/documents/2023-11-Information-Security-Policy.pdf";
import PlatformAgreement from "../assets/documents/FACTOReX-Platform-Onboarding-Agreement.pdf";
import OnboardingRequirements from "../assets/documents/FACTOREX-ONBOARDING-REQUIREMENTS.pdf";
import PricingGuide from "../assets/documents/Pricing-Guide.pdf";
import Addendum from "../assets/documents/Addendum.pdf";


const appStatic = {
  clientTitle: [
    { id: "1", value: "MS", name: "MS - Master/ Miss" },
    { id: "2", value: "MR", name: "MR - Mister" },
    { id: "3", value: "MRS", name: "MRS" },
    { id: "4", value: "DR", name: "DR - Doctor" },
    { id: "5", value: "PROF", name: "PROF - Professor" },
    { id: "6", value: "ADV", name: "ADV - Advocate" },
    { id: "7", value: "HON", name: "HON - Honorable" },
    { id: "8", value: "AMB", name: "AMB - Ambassador" },
    { id: "9", value: "REV", name: "REV - Reverend" },
    { id: "10", value: "N/A", name: "N/A - Not Applicable" },
  ],
  clientType: [
    { id: "Citizen", name: "Citizen" },
    { id: "Permanent Resident", name: "Permanent Resident" },
    { id: "Emigrant", name: "Emigrant" },
    { id: "Foreign Citizen", name: "Foreign Citizen" },
    { id: "Close Corporation", name: "Close Corporation" },
    { id: "Company", name: "Company" },
    { id: "Foreign Company", name: "Foreign Company" },
    { id: "Other Legal Person", name: "Other Legal Person" },
    { id: "Trust", name: "Trust" },
    { id: "Minor", name: "Minor" },
    { id: "Broker", name: "Broker" },
    { id: "Custodian Bank", name: "Custodian Bank" },
    { id: "Issuer", name: "Issuer" },
    { id: "Registry/Transfer Secretary", name: "Registry/Transfer Secretary" },
    { id: "Settlement Bank", name: "Settlement Bank" },
  ],
  investorType: [
    { id: "Business", name: "Business" },
    // { id: "Individual", name: "Individual" },
  ],
  companyType: [
    { id: "Buyer", name: "Buyer" },
    { id: "Supplier", name: "Supplier" },
    { id: "Funder", name: "Funder" },
    // { id: "Ultimate Supplier", name: "Ultimate Supplier" },
  ],
  companyTypeData: [
    { id: "CT2022012400001", name: "Buyer" },
    { id: "CT2022012400002", name: "Supplier" },
    { id: "CT2022012400003", name: "Funder" }
  ],
  registerItemType: [
    { id: appLabel.coRegNo, name: appLabel.coRegNo },
    { id: appLabel.companyTax, name: appLabel.companyTax },
  ],
  installementData: [
    { id: "1", name: "1" },
    { id: "2", name: "2" },
  ],
  otherFeeData: [
    { id: "Other Fee 1", name: "Other Fee 1" },
    { id: "Other Fee 2", name: "Other Fee 2" },
  ],
  paymentOptionData: [
    { id: "1", name: "Full" },
    { id: "2", name: "Instalment" },
  ],
  poCategory: [
    { id: "1", name: "Textile and Leather" },
    { id: "2", name: "Mechanical" },
    { id: "3", name: "Food and Agriculture" },
    { id: "4", name: "Chemical Technology" },
    { id: "5", name: "Electrotechnical" },
    { id: "6", name: "Services" },
  ],
  gender: [
    { id: "M", name: "Male" },
    { id: "F", name: "Female" },
  ],
  banks: [
    { id: "Access Bank Plc", name: "Access Bank Plc" },
    { id: "Citibank Nigeria Limited", name: "Citibank Nigeria Limited" },
    { id: "Ecobank Nigeria Plc", name: "Ecobank Nigeria Plc" },
    { id: "United Bank For Africa Plc", name: "United Bank For Africa Plc" },
  ],
  bankAccountType: [
    { id: "Current", name: "Current" },
    { id: "Saving", name: "Saving" },
  ],
  documntTypes: [
    { id: "Registration No", name: "Registration No" },
    { id: "Tax", name: "Tax" },
    { id: "Other", name: "Other" },
  ],
  documntTypesPo: [
    { id: "Signed Contract", name: "Signed Contract" },
    { id: "Purchase Order", name: "Purchase Order" },
    { id: "Award Letter", name: "Award Letter" },
    { id: "Other", name: "Other" },
  ],
  documntTypesPo: [
    { id: "Signed Contract", name: "Signed Contract" },
    { id: "Purchase Order", name: "Purchase Order" },
    { id: "Award Letter", name: "Award Letter" },
    { id: "Other", name: "Other" },
  ],

  documntTypesSharePo: [
    { id: "Bank Statement", name: "Bank Statement" },
    { id: "Supplier Quote", name: "Supplier Quote" },
    { id: "Other", name: "Other" },
  ],

  docTypesCreditSharePo: [
    { id: "Credit Score", name: "Credit Score" },
  ],

  businessType: [
    { value: "Public", name: "Public" },
    { value: "Private", name: "Private" },
  ],
  financeYears: [
    { value: "2020 / 2021", name: "2020 / 2021" },
    { value: "2021 / 2022", name: "2021 / 2022" },
  ],
  subActionList: [
    {
      value: "1",
      name: "create",
    },
    {
      value: "1",
      name: "view",
    },
    {
      value: "1",
      name: "edit",
    },
    {
      value: "1",
      name: "approve",
    },
    {
      value: "1",
      name: "reject",
    },
  ],
  menuData: [
    {
      DESCRIPTION: "Dashboard",
      FUNCTION_ID: "001",
      ICON: "th-large",
      POSITION: "1",
      URL: "/dashboard",
      SUB_MENU: [],
    },
    {
      DESCRIPTION: "Company",
      FUNCTION_ID: "002",
      ICON: "building-o",
      POSITION: "2",
      URL: "",
      SUB_MENU: [
        {
          DESCRIPTION: "Company",
          FUNCTION_ID: "0021",
          URL: "/manage-company",
        },
      ],
    },
    {
      DESCRIPTION: "Purchase Order",
      FUNCTION_ID: "003",
      ICON: "cart-plus",
      POSITION: "3",
      URL: "",
      SUB_MENU: [
        {
          DESCRIPTION: "Purchase Order",
          FUNCTION_ID: "0031",
          URL: "/purchase-order",
        },
      ],
    },
    {
      DESCRIPTION: "Invoice",
      FUNCTION_ID: "004",
      ICON: "money",
      POSITION: "4",
      URL: "",
      SUB_MENU: [
        {
          DESCRIPTION: "Invoice",
          FUNCTION_ID: "0041",
          URL: "/manage-invoice",
        },
      ],
    },
    {
      DESCRIPTION: "Contract",
      FUNCTION_ID: "005",
      ICON: "file",
      POSITION: "5",
      URL: "",
      SUB_MENU: [
        {
          DESCRIPTION: "Contract",
          FUNCTION_ID: "0051",
          URL: "/manage-contract",
        },
      ],
    },
    {
      DESCRIPTION: "Reporting",
      FUNCTION_ID: "006",
      ICON: "line-chart",
      POSITION: "6",
      URL: "",
      SUB_MENU: [
        {
          DESCRIPTION: "Reporting",
          FUNCTION_ID: "0061",
          URL: "/manage-report",
        },
        {
          DESCRIPTION: "Audit",
          FUNCTION_ID: "0062",
          URL: "/audit-trail",
        },
      ],
    },
    {
      DESCRIPTION: "Security",
      FUNCTION_ID: "007",
      ICON: "lock",
      POSITION: "7",
      URL: "/",
      SUB_MENU: [
        {
          DESCRIPTION: "User",
          FUNCTION_ID: "0071",
          URL: "/manage-user",
        },
        {
          DESCRIPTION: "Role",
          FUNCTION_ID: "0072",
          URL: "/manage-role",
        },
        {
          DESCRIPTION: "Role Menu",
          FUNCTION_ID: "0074",
          URL: "/manage-role-menu",
        },
        {
          DESCRIPTION: "Entity Menu",
          FUNCTION_ID: "0074",
          URL: "/manage-entity-menu",
        },
      ],
    },
  ],
  currencyCode: [
    { id: "NGN", name: "Nigerian naira" },
    { id: "GHS", name: "Ghanaian cedi" },
    { id: "GBP", name: "British pound" },
    { id: "EUR", name: "Euro" },
    { id: "USD", name: "US dollar" },
    { id: "ZAR", name: "South African" },
  ],
  isoCode: [
    { id: "NGA", name: "Nigeria" },
    { id: "ZAF", name: "South Africa" },
    { id: "ERI", name: "Eritrea" },
    { id: "KEN", name: "Kenya" },
  ],
  chargeType: [
    { id: "Flat", name: "Flat" },
    { id: "Percentage", name: "Percentage" },
  ],
  yesOrNoStatus: [
    { id: "Y", name: "Yes" },
    { id: "N", name: "No" },
  ],
  trueOrFalseStatus: [
    { id: "T", name: "True" },
    { id: "F", name: "False" },
  ],
  contractType: [
    { id: "po", name: "PO Financing" },
    { id: "invoice", name: "Invoice Financing" },
  ],
  obligationStatus: [
    { id: "Not yet Due", name: "Not yet Due" },
    { id: "Due", name: "Due" },
    { id: "Delivered", name: "Delivered" },
    { id: "Overdue", name: "Overdue" },
  ],
  invoiceType: [
    { id: "Invoice", name: "Invoice" },
    { id: "Delivery Note", name: "Delivery Note" },
    { id: "Others", name: "Others" },
  ],
  taxType: [
    { id: "Capital Gains Taxes (CGT)", name: "Capital Gains Taxes (CGT)" },
    { id: "Value Added Taxes (VAT)", name: "Value Added Taxes (VAT)" },
    { id: "Withholding Taxes (WHT)", name: "Withholding Taxes (WHT)" },
  ],
  otherFee1: [{ name: "Supplier Fee 1", value: "Supplier Fee 1" }],
  handleFlgStatus: (status) => {
    let statusName = "";
    switch (status.toLowerCase()) {
      case "f":
        return (statusName = "Funded");
      case "r":
        return (statusName = "Rejected");
      case "p":
        return (statusName = "Pending");
      case "pr":
        return (statusName = "Processing");
      case "pe":
        return (statusName = "Processed with errors");
      case "c":
        return (statusName = "Completed");
      case "n":
        return (statusName = "New");
      case "a":
        return (statusName = "Verified");
      case "u":
        return (statusName = "Unverified");
      default:
        return statusName;
    }
  },
  handleStatusIcon: (key) => {
    switch (key.toLowerCase()) {
      case "open":
        return <i className="fa fa-play fa-rotate-270 text-success" />;
      case "closed":
        return <i className="fa fa fa-play fa-rotate-90 text-danger" />;
      case "a":
        return <i className="fa fa-check text-navy" />;
      case "u":
      case "r":
        return <i className="fa fa-times text-danger" />;
    }
  },
  pathData: [
    {
      DESCRIPTION: "PO Upload",
      URL: "/order-upload",
    },
    { DESCRIPTION: "Invoice Upload", URL: "/invoice-upload" },
    { DESCRIPTION: "Manage Company", URL: "/manage-company" },
    { DESCRIPTION: "Manage Bank", URL: "/manage-bank" },
    { DESCRIPTION: "Manage Currency", URL: "/manage-currency" },
    { DESCRIPTION: "Manage Tax", URL: "/manage-tax" },
    // { DESCRIPTION: "Transactional Fee", URL: "/manage-surcharge" },
    { DESCRIPTION: "Fee Setup", URL: "/manage-surcharge" },
    { DESCRIPTION: "Manage Company Bank", URL: "/manage-company-bank" },
    { DESCRIPTION: "Ad-hoc", URL: "/manage-ad-hoc" },
    { DESCRIPTION: "Charge Setup", URL: "/manage-fee" },
    {
      DESCRIPTION: "Escrow Account",
      URL: "/manage-trust-account",
    },
    { DESCRIPTION: "Manage Contract", URL: "/manage-contract" },
    { DESCRIPTION: "Manage Invoice", URL: "/manage-invoice" },
    { DESCRIPTION: "Shared Invoice", URL: "/shared-invoice" },
    { DESCRIPTION: "Quoted Invoice", URL: "/financed-invoice" },
    { DESCRIPTION: "Funded Invoice", URL: "/funded-invoice" },
    { DESCRIPTION: "Manage PO", URL: "/manage-order" },
    { DESCRIPTION: "Shared PO", URL: "/shared-order" },
    { DESCRIPTION: "Quoted PO", URL: "/financed-order" },
    { DESCRIPTION: "Funded PO", URL: "/funded-order" },
    { DESCRIPTION: "Reporting", URL: "/manage-report" },
    { DESCRIPTION: "Audit", URL: "/audit-trail" },
    { DESCRIPTION: "User Setup", URL: "/manage-user" },
    { DESCRIPTION: "Manage Role", URL: "/manage-role" },
    { DESCRIPTION: "Role Config", URL: "/manage-role-menu" },
    { DESCRIPTION: "Entity Config", URL: "/manage-entity-menu" },
    { DESCRIPTION: "Manage Prospect", URL: "/manage-prospect-company" },
    { DESCRIPTION: "Verify PO", URL: "/order-to-verify" },
    { DESCRIPTION: "Verify Invoice", URL: "/invoice-to-verify" },
    { DESCRIPTION: "Loan Portfolio", URL: "/manage-loan-portfolio" },
    { DESCRIPTION: "Supplier Request", URL: "/manage-supplier-intent" },
    { DESCRIPTION: "Bid", URL: "/manage-funder-bid" },
    { DESCRIPTION: "Entity Notification", URL: "/manage-entity-notification" },
    // { DESCRIPTION: "User Notification", URL: "/manage-user-notification" },
  ],
  reporting: {
    type: "po",
    poLabelData: [
      {
        id: "1",
        name: "PO by Status",
        icon: "fa fa-line-chart",
        isSelected: 0,
      },
      {
        id: "2",
        name: "Shared PO",
        icon: "fa fa-line-chart",
        isSelected: 0,
      },
      {
        id: "3",
        name: "Financed PO",
        icon: "fa fa-line-chart",
        isSelected: 0,
      },
    ],
    invoiceLabelData: [
      {
        id: "1",
        name: "Invoice by Status",
        icon: "fa fa-line-chart",
        isSelected: 0,
      },
      {
        id: "2",
        name: "Shared Invoice",
        icon: "fa fa-line-chart",
        isSelected: 0,
      },
      {
        id: "3",
        name: "Funded Invoice",
        icon: "fa fa-line-chart",
        isSelected: 0,
      },
    ],
    contractLabelData: [
      {
        id: "1",
        name: "Contract by Status",
        icon: "fa fa-line-chart",
        isSelected: 0,
      },
    ],
    bidLabelData: [
      {
        id: "1",
        name: "PO",
        icon: "fa fa-line-chart",
        itemType: "PO",
        isSelected: 0,
      },
      {
        id: "2",
        name: "Invoice",
        icon: "fa fa-line-chart",
        itemType: "Invoice",
        isSelected: 0,
      },
    ],
    settlementLabelData: [
      {
        id: "1",
        name: appLabel.supplier,
        nameTwo: appLabel.settlementReport,
        icon: "fa fa-line-chart",
        isSelected: 0,
      },
      {
        id: "2",
        name: appLabel.funder,
        nameTwo: appLabel.settlementReport,
        icon: "fa fa-line-chart",
        isSelected: 0,
      },
    ],
  },
  flgStatusData: [
    { value: "A", name: "Authorised" },
    { value: "U", name: "Unauthorised" },
    { value: "R", name: "Disabled" },
  ],
  verifyStatusData: [
    { value: "A", name: "Verified" },
    { value: "U", name: "Unverified" },
  ],
  fundingStatusData: [
    { value: "D", name: "Funded" },
    { value: "U", name: "Pending" },
  ],
  pOStatusCriteria: [
    { value: "Auth", name: "Authorization" },
    { value: "Verif", name: "Verification" },
    { value: "ponumber", name: "PO Number" },
  ],
  pOSharedCriteria: [
    { value: "date", name: "Date Range" },
    { value: "ponumber", name: "PO Number" },
  ],
  pOFundCriteria: [
    { value: "fundstatus", name: "Fund" },
    { value: "ponumber", name: "PO Number" },
  ],

  invoiceStatusCriteria: [
    { value: "Auth", name: "Authorization" },
    { value: "invoiceid", name: "Invoice No" },
    { value: "ponumber", name: "PO Number" },
  ],

  invoiceSharedCriteria: [
    { value: "date", name: "Date Range" },
    { value: "invoiceid", name: "Invoice No" },
  ],

  discountedInvoiceCriteria: [
    { value: "date", name: "Date Range" },
    { value: "invoiceid", name: "Invoice No" },
  ],

  contractStatusCriteria: [
    { value: "auth", name: "Authorization" },
    { value: "contractId", name: "Contract Id" },
  ],

  contractTypeReport: [
    { value: "invoice", name: "Invoice No" },
    { value: "po", name: "PO Number" },
  ],
  requestFundingType: [
    { value: "public", name: "Public" },
    { value: "private", name: "Private" },
  ],
  itemType: [
    { value: "PO", name: "PO" },
    { value: "Invoice", name: "Invoice" },
  ],
  itemType2: [
    { value: "PO", name: "PO" },
    { value: "INV", name: "Invoice" },
  ],
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "Sptember",
    "October",
    "November",
    "December",
  ],
  toolTip: {
    // COMPANY
    toolCoy1:
      "This is the person that is in charge of the Project on the Company side. And has the responsibility to create and assign role rights to other Users",

    companyType:
      "Select whether your company represents a Supplier (Vendor), Buyer (PO Issuer), Funder or Ultimate Supplier (Manufacturer)",
    companyName: "The Unique name your company is called",
    companyRegNo:
      "The number used to identify your company and verify the fact that it is an entity registered with the Corporate Affairs Commission",
    vatId:
      "A Value-Added Tax identification number or VAT identification number (VATIN) is an identifier provided to the tax payer to use on invoices or when submitting VAT returns",
    tradingName:
      "A trade name is the official name under which an individual as a sole proprietor or a company chooses to do business other than the company name. A trade name is commonly known as a doing business as (DBA) name.",
    companyEmail:
      "The company's electronic means of receiving messages  (The email address of the company's contact person)",
    website:
      "The unique web pages located under your domain name, i.e. for your organization if any (Your company's web address if any.)",

    taxId:
      "A Tax Identification Number (TIN) is a number used by the IRS as a tracking number for tax purposes and is required information on all tax returns.",
    contactName:
      "The company's administrative  representative's name. And has the responsibility to create and assign role rights to other Users",
    contactEmail: "The company's administrative representative's email ",
    officePhone:
      "The company's administrative representative's official phone number",
    otherContactName:
      "The company's functional (secondary) representative's name  For the Buyer’s Entity, this is defaulted as the Verification Officer who will be in charge of verifying POs and Invoices.For the Funder’s Entity, this is defaulted as the Account Payable Officer who will have the access right to initiate funding.For Supplier’s Entity, this is the other User who has the right to create on the system.",
    otherContactEmail:
      "The company's functional (secondary) representative's email",
    otherOfficePhone:
      "The company's functional (secondary)  representative's official phone number",

    address1: "Address specifics such as plot number and street name",
    address2: "Landmark to make the address more accessible",
    country: "The country in which the company is based",
    province: "The state or province in which the company is based",
    city: "The city or town in which the company is located",
    postCode: "City post code",
    bankName: "The name of the bank with which the company has an account.",
    accountNumber:
      "Unique 10 digits numbers assigned to the company by the bank",
    accountName: "The name whereby the company opened a bank account.",
    branchCode:
      "The unique code of the branch where the company's account is operating from",
    accountType: "The type of account that the company operates",
    nuban: "Unique 10 digits numbers assigned to the company by the bank",
    companyRegDate:
      "The date the company was registered with the Corporate Affairs Commission",
    businessStartDate: "The date the company began doing business",
    financeYear:
      "A period of twelve months, used by government, business, and other organizations in order to calculate their budgets, profits, and losses",
    shippingAddress: "The address where the goods would be received",
    billingAddress: "The address where payment  would be made ",
    documentType:
      "Choose the document that the company is willing to use as a supporting document for onboarding.",
    documentUpload:
      "Upload the same kind of supporting documentation. (must be pdf and not more than 5mb)",
    // PO
    from: "A Buyer is the party purchasing goods from a Supplier and the issuer of a PO",
    poAmount: "The total value of the PO inclusive of VAT",
    to: "Your Company",
    pymtOption: "Method of Payment",
    bankName: "The name of the bank with which the company has an account.",
    frequency: "frequency",
    firstPayDue: "The date the Purchase Order was initiated.",
    currency: "The Currency in which the PO amount will be paid",
    lastPayDue: "The deadline for the fulfillment of a PO",
    noteName: "noteName",
    registrar: "registrar",
    csdNumber: "csdNumber",
    lpoRefId:
      "PO Number from Buyer Company",
    noteCode: "noteCode",
    materialInfo: "Details of item on the PO",
    materiaDesc: "Narration of the type of good",
    materialUnitPrice: "Price of the single item",
    materialQty: "Number of each good required",
    documentInfo: "Upload relevant PO documents",
    capitalRequired:
      "The amount needed by the Supplier/SME towards fulfilling an instrument (PO/Invoice)",
    logisticsOtherCosts: "Costs associated with logistics, including transport and warehousing costs and inventory carrying, administration, and order processing costs.",
    supplierQuote: "The term of the sale, warrantues and payment",
    interestRate: "Annual Interest rate on the capital",
    duration: "The period of time the money to be returned",
    fundsAvailable: "The Supplier's funds available for the transaction",
    trustAccount:
      "This is the trust account  meant for the custodian. (This is a trust account administered by the custodian  used to facilitate the funding process (Receive funds from Funders, Receive Repayments from Suppliers).)",
    interestAmount:
      "The interest rate by the Funder (The interest rate applied to a PO financing transaction by the Funder)",
    paymentDate:
      "It is the date at which funds or money's value becomes effective.",
    costOfFinance:
      "Cost of Finance is the total of Arrangement Fee + Other Fee + Interest Amount (This is the total of Arrangement Fee + Other Fee + Interest Amount)",
    arrangementFees:
      "The fee payable by the borrower to the arranger on signing the loan agreement, usually calculated as a percentage of the amount of the loan.",
    otherFees:
      "This is the miscellaneous fee (Any Additional fees to be incuured by the Suppier from a financing transaction)",
    totalRepayment:
      "Total Repayment Amount is the calculation of the Loan Amount + Arrangement Fee + Other Fee + Interest Amount (This is the summation of the Loan Amount and Cost of Finance)",
    investorInfo: "The funder's information ",
    //Invoice
    invPoNo: "Purchase Order Number to be linked to the invoice",
    fundingDate: "Date transaction is made available",
    taxCode: "Tax type to be deducted ",
    invPoAmount: "Purchase Order Amount",
    pymtOption: "Method of payment",
    frequency: "Rate of payment occurence",
    docType: "Type of document required to be uploaded",
    docUpload: "File Type - PDF, File Size - 5 MB",
    deposit: "Type of document required to be uploaded",
    trustAccount:
      "Escrow Account. Centralized bank account for disbursement",
    discountedAmount:
      "An amount equal to the sum of the offer discount and payable amount",
    netInvoiceAmount: "(The Receivable amount ) - (Tax)",
    processingFee:
      " It is charged by funders to cover their costs when processing your loan application.",
    investorName: "This is the funder's name",
    tenor: "The time period for capital to be refunded to the Funder ",

    //Bacth Upload
    batchNumber:
      "System generated identification number for a particular batch file",
    batchName: "Unique document name for a specific batch file to be uploaded",
    batchDescription:
      "Additional description for a specific batch file to be uploaded",
    documentUpload: "File Type - TSV (Tab Separated Value) File Size - 5 MB",
    documentUploadPdf: "File Type - PDF File Size - 5 MB",

    //report
    startDate: "The date the search is meant to start from",
    endDate: "The date the search is meant to stop at",
    searchCriteria: "Search criteria",
    searchType: "Search query",
    contractType:
      "serve as a guide for preparing meaningful, useful and credible evaluation reports.",

    //User
    firstName: "Given Name",
    lastName: "Surname",
    userName:
      "User credentials for logging in to the application. This must be in email format",
    userPassword: "Secret data for access into the system. ",
    userEmail: "User's valid email address",
    userPhone: "Valid mobile number",
    userCountry: "The country in which the individual is based",
    userProvince: "The state in which the individual is based",
    userCity: "The city in which the individual is based",
    userAddress: "The address in which the individual is based",
    parentRoleName: "The priviledge or access right a user has",
    roleName: "The priviledge or access right a user has",
    funderBid:"Bids from Potential Funders",

    //config
    //Tax
    category: "Tax Category defines how loan Products are to be taxed. ",
    type: "Tax type means a tax which is subject to remittance of payments.",
    rate: "The percentage of an amount or value that has to be paid in tax.",
    taxAmount:
      "The portion of  total amount or profits that is subject to tax.",
    effectiveDate: "Date the tax category will be in effect",
    waiverAllow: "If tax can be cancelled",

    configBankName:
      "This is a valid financial institute registered under the Act",
    configBankCode:
      "A bank code is the numerical code assigned to a specific bank in order to identify it during financial transaction.",
    configBankDesc:
      "A description is used to provide additional information regarding bank name.",

    //co_bank
    co_companyName: " A name by which a corperation is identified.",
    co_bankName: "A bank is a licensed financial institution. ",
    co_accountNumber:
      "An account number is a unique string of numbers, sometimes, letters or other characters that identifies the owner of the account.",
    co_accountName:
      "The full name of the person or business associated with the bank account.",
    co_branchCode:
      "A branch code is a unique identifying code for a given branch of a bank.",
    co_accountType: "The various types of  Accounts provided by the bank. ",
  },
  notif: {
    // notif1: "If you click Save, you will not be able to receive multiple Funding. To receive multiple Funding, kindly go back and select FMDQP as your Funder."
    notif1:
      "You will not be able to receive multiple Funding. To receive multiple Funding, kindly click cancel and select Public funding at the top.",
    notif2: "Approve or Reject",
  },
  tableData: {
    charge: [
      { name: appLabel.chargeId },
      { name: appLabel.chargeName },
      { name: appLabel.companyType,},
      { name: appLabel.currency,},
    ],
    adHoc: [
      { name: appLabel.configId, id: "1" },
      { name: appLabel.configName, id: "2" },
      { name: appLabel.configValue, id: "3" },
    ],
    feeList: [
      { name: appLabel.feeId },
      { name: appLabel.feeName },
      { name: appLabel.companyTypeName },
      { name: appLabel.currency },
      { name: appLabel.feeOption },
    ],
    downloadReport: [
      { name: appLabel.companyName },
      { name: appLabel.companyRegNo },
      { name: appLabel.businessType },
      { name: appLabel.companyTypeName },
      { name: appLabel.companyRegDate },
      { name: appLabel.primaryContact },
      { name: appLabel.phoneNumber },
      { name: appLabel.email },
      { name: appLabel.address },
      { name: appLabel.city },
      { name: appLabel.country },
    ],
    bidList: [
      { name: appLabel.intentId, id: "1" },
      { name: appLabel.itemId, id: "2" },
      { name: appLabel.bidCount, id: "3" },
      { name: appLabel.amountRequested, id: "4" },
      { name: appLabel.status, id: "5" },
    ],
    bidDetail: [
      { name: appLabel.funderName, id: "1" },
      { name: appLabel.funderRate, id: "2" },
      { name: appLabel.funderAmnt, id: "3" },
      { name: appLabel.interestAmount, id: "4" },
    ],
    bidByFunder: [
      { name: appLabel.funderName },
      { name: appLabel.funderRate },
      { name: appLabel.funderAmnt },
      { name: appLabel.status },
    ],
    poFundedList: [
      { name: appLabel.date, id: "1" },
      { name: appLabel.poNo, id: "2" },
      { name: appLabel.noteCode, id: "3" },
      { name: appLabel.rate, id: "4" },
      { name: appLabel.amount, id: "5" },
    ],
    invQuotedList: [
      { name: appLabel.date, id: "1" },
      { name: appLabel.invoiceId, id: "2" },
      { name: appLabel.noteCode, id: "3" },
      { name: appLabel.rate, id: "4" },
      { name: appLabel.amount, id: "5" },
    ],
    depositoryBidList: [
      { name: appLabel.intentId, id: "1" },
      { name: appLabel.itemId, id: "2" },
      { name: appLabel.depositoryName, id: "3" },
      { name: appLabel.rate, id: "4" },
      { name: appLabel.totalRepayment, id: "5" },
    ],
    historyBidList: [
      { name: appLabel.itemId, id: "1" },
      { name: appLabel.itemType, id: "2" },
      { name: appLabel.amountRequested, id: "3" },
      { name: appLabel.amountFunded, id: "4" },
    ],
    poCleanList: [
      { name: appLabel.date, id: "1" },
      { name: appLabel.poNo, id: "2" },
      { name: appLabel.from, id: "3" },
      { name: appLabel.to, id: "4" },
    ],
    invToverifyList: [
      { name: appLabel.date, id: "1" },
      { name: appLabel.invoiceId, id: "2" },
      { name: appLabel.amount, id: "3" },
      { name: appLabel.status, id: "4" },
    ],
    invIntentList: [
      { name: appLabel.intentId, id: "1" },
      { name: appLabel.itemType, id: "2" },
      { name: appLabel.itemId, id: "3" },
      { name: appLabel.to, id: "4" },
      { name: appLabel.amountRequested, id: "5" },
    ],
    invSharedList: [
      { name: appLabel.date, id: "1" },
      { name: appLabel.intentId, id: "2" },
      { name: appLabel.invoiceId, id: "3" },
      { name: appLabel.amountRequested, id: "5" },
    ],
    invFundedList: [
      { name: appLabel.date, id: "1" },
      { name: appLabel.fundingId, id: "2" },
      { name: appLabel.invoiceId, id: "3" },
      { name: appLabel.amount, id: "4" },
    ],
    bidDownloadList: [
      { name: appLabel.funderName },
      { name: appLabel.itemType },
      { name: appLabel.itemId },
      { name: appLabel.noteName },
      { name: appLabel.noteNumber },
      { name: appLabel.noteCode },
      { name: appLabel.principalAmount },
      { name: appLabel.bidRate },
      { name: appLabel.bidAmount },
      { name: appLabel.allotmentRate },
      { name: appLabel.allotedAmount },
    ],
    feeSlabList: [
      { name: appLabel.minAmount },
      { name: appLabel.maxAmount },
      { name: appLabel.rate },
    ],
    stlmtSupplierList: [
      { name: appLabel.itemId },
      { name: appLabel.noteName },
      { name: appLabel.noteNumber },
      { name: appLabel.noteCode },
      { name: appLabel.supplierName },
      { name: appLabel.principalAmount },
      { name: appLabel._interestRate },
      { name: appLabel.interestAmount },
      { name: appLabel.tenor },
      { name: appLabel.valueDate },
      { name: appLabel.maturityDate },
      { name: appLabel.arrangementFee },
      { name: appLabel.otherFee1 },
      { name: appLabel.otherFee2 },
      { name: appLabel.netSettlement },
      { name: appLabel.taxPayable },
      { name: appLabel.supplierBankName },
      { name: appLabel.supplierAccountName },
      { name: appLabel.supplierAccountNumber },
    ],
    stlmtFunderList: [
      { name: appLabel.itemId },
      { name: appLabel.noteName },
      { name: appLabel.noteNumber },
      { name: appLabel.noteCode },
      { name: appLabel.funderName },
      { name: appLabel.fundedAmount },
      { name: appLabel.allotedAmount },
       { name: appLabel._interestRate },
      { name: appLabel.interestAmount },
      { name: appLabel.tenor },
      { name: appLabel.valueDate },
      { name: appLabel.maturityDate },
      { name: appLabel.transactionFee },
      { name: appLabel.otherFee1 },
      { name: appLabel.otherFee2 },
      { name: appLabel.totalSettlementAmount },
      { name: appLabel.taxPayable },
      { name: appLabel.funderBankName },
      { name: appLabel.funderAccountName },
      { name: appLabel.funderAccountNumber },
    ],
  },
  tabData: {
    invDetailList: [
      {
        listName: appLabel.documentInfo,
        ref: "#documentInfo",
        icon: "fa fa-file-pdf-o text-navy",
        isActive: true,
      },
      {
        listName: appLabel.pOInfoShort,
        ref: "#pOInfo",
        icon: "fa fa-cart-plus text-navy",
        isActive: false,
      },
      {
        listName: appLabel.contractInfoShort,
        ref: "#contractInfo",
        icon: "fa fa-file-text text-navy",
        isActive: false,
      },
      {
        listName: appLabel.financeInfoShort,
        ref: "#financeInfo",
        icon: "fa fa-money text-navy",
        isActive: false,
      },
      {
        listName: appLabel.address,
        ref: "#addressInfo",
        icon: "fa fa-home text-navy",
        isActive: false,
      },
    ],
    invShareList: [
      {
        listName: appLabel.investorInfo,
        ref: "#investorInfo",
        icon: "fa fa-tags text-navy",
        isActive: true,
      },
      {
        listName: appLabel.address,
        ref: "#addressInfo",
        icon: "fa fa-home text-navy",
        isActive: false,
      },
    ],
  },
  privacyPolicyDocumentData:[
    { name: "End-user Licensing", link: EndUserLicense, icon:"tags", isProtected:false },
    { name: "Terms & Conditions", link: TermsAndConditions, icon:"tags", isProtected:false },
    { name: "Privacy Policy", link: PrivacyPolicy, icon:"tags", isProtected:false },
    // { name: "Subscriber Information", link: Subscriber , icon:"fa fa-lock text-navy"},
    { name: "Platform Agreement", link: PlatformAgreement , icon:"fa fa-lock text-navy", isProtected:true},
    { name: "Onboarding Requirements", link: OnboardingRequirements , icon:"fa fa-lock text-navy", isProtected:true},
    { name: "Pricing Guide", link: PricingGuide , icon:"fa fa-lock text-navy", isProtected:true},
    { name: "Addendum", link: Addendum , icon:"fa fa-lock text-navy", isProtected:true},
  ]
};

export default appStatic;
