import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { BOM } from "../../../services/defined.action";
import { apis as api } from "../../../services/api.action";
import { cnt } from "../../../services/constant.action";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import appLabel from "../../../config/appLabel";
import history from "../../../history";


export const ManageEntityMenu = (props) => {
  const { userData } = props;
  const { profileId, session, companyTypeId } = userData;
  const [roleName, setRoleName] = useState("");
  const [entityMenuData, setEntityMenuData] = useState([]);
  const [roleObj, setRoleObj] = useState({});

  useEffect(() => {
      getEntityMenu()
  }, []);

  const viewDetail = () => {
    history.push("/role-detail");
  };
  function getEntityMenu() {

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      // entityTypeId: companyTypeId,
      criteria: "A",
      functionId:"",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetEntityMenu, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setEntityMenuData(res.companyTypeListing);
      }
    });
  }


  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.entityMenu} />
      <div className="animated fadeInRight">
        <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>{appLabel.entityMenuListing}</h5>

                  <div className="ibox-tools">
                    {/* <Link
                      to="/new-entity-menu"
                      className="btn btn-primary btn-xs"
                      title="Add Menu"
                    >
                      <i
                        className="fa fa-plus-circle"
                      />
                      &nbsp;{appLabel.newMenu}
                    </Link> */}
                  </div>
                </div>

                <div className="ibox-content panel-primary content-card-v2">
                  <div className="table-responsive">
                    {entityMenuData.length === 0 ? (
                      <div className="alert alert-warning text-center">
                        <a className="alert-link">
                          {appLabel.noDataInTableMsg}
                        </a>
                      </div>
                    ) : (
                      <table
                        className="footable table table-stripped toggle-arrow-tiny"
                        data-page-size="10"
                        data-filter="#filter"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>{appLabel.entityTypeMenuId}</th>
                            <th>{appLabel.entityTypeMenuName}</th>
                            <th>{appLabel.entityTypeMenuDesc}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {entityMenuData.map((entity, index) => (
                            <tr className="gradeX" key={index}>
                              <td>{index + 1}</td>
                              <td>{entity.COMPANY_TYPE_ID}</td>
                              <td>{entity.COMPANY_TYPE_NAME}</td>
                              <td className="center">
                                {entity.COMPANY_TYPE_DESC}
                              </td>
                              <td className="project-actions">
                                <div>
                                  <Link to={{
                                      pathname: "/entity-menu-detail",
                                      state:{
                                        ggParam: entity,
                                      }
                                    }}
                                    className="btn btn-primary btn-xs"
                                    title="View More"
                                  >
                                    <i
                                      className="fa fa-folder-open"
                                    />
                                    &nbsp;{appLabel.viewMore}
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan="6">
                              <ul className="pagination pull-right"></ul>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          
        </div>
      </div>
    </Main>
  );
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ManageEntityMenu);
