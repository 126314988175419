import React, { useEffect, useState } from "react";
import Main from "../layouts/Main";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { apis as api } from "../../services/api.action";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM, RAL } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import NumberFormat from "react-number-format";
import { HandleSkeletonTbleFac } from "../layouts/Facility";

const ManageConsentedOrder = (props) => {
  const { userData, menuData, location } = props;
  const { profileId, session, companyId } = userData;
  const globalVar = window.globalData;
  const dispatch = useDispatch();
  const [poData, setPoData] = useState([]);
  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // console.log(props);
    getListByCriteria();
  }, []);

  function getListByCriteria() {
    // console.log(params);
    setPoData([]);

    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true)

    let body = {
      profileId,
      orderId: "",
      companyId,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetConsentedPo, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setPoData(res.purchaseOrderListing);
        BOM.DatatableUsage();
        setIsLoading(false)
      }
    });
  }
  const onSelectPo = (order) => {
    // ["RIGHT_DETAIL_BTN"].map((item) => {
    //   dispatch({
    //     type: item,
    //   });
    // });
    /** CHECK FOR RIGHT BTN PRIVILEGES */
    let path = appStatic.pathData.find(
      (item) => item.URL === location.pathname
    );
    if (path) {
      if (RAL.viewAction(menuData, path.DESCRIPTION)) {
        dispatch({
          type: cnt.RIGHT_DETAIL_BTN,
        });
      }
    }

    globalVar.viewLink = {
      path: "/funded-order-detail",
      obj: order,
    };
  };

  /** RENDER JXS DATA */
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.fundedPO} />
      <div className="animated fadeInRightFAKE">
        <div className="row">
          <form className="m-t" autoComplete="off">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>{appLabel.authorizedListing}</h5>
                </div>
                <div className="ibox-content panel-primary contentCard">
                <HandleSkeletonTbleFac
                    classParam={`${isLoading ? "" : "hide"}`}
                  />
                  <div className={` ${isLoading ? "hide" : ""}`}>
                  {poData.length === 0 ? (
                    <div className="alert alert-warning text-center">
                      <a className="alert-link">{appLabel.noDataInTableMsg}</a>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <table
                        id="myTable"
                        className="table table-striped table-bordered table-hover"
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th>{appLabel.date}</th>
                            <th>{appLabel.fundingId}</th>
                            <th>{appLabel.poNo}</th>
                            <th>{appLabel.poAmount}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {poData.map((order, index) => (
                            <tr className="gradeX" key={index}>
                              <td>
                                <label className="myradio-button">
                                  <input
                                    type="radio"
                                    name="radio"
                                    onClick={() => onSelectPo(order)}
                                  />
                                  <span className="label-visible">
                                    <span className="myfake-radiobutton"></span>
                                  </span>
                                </label>
                              </td>
                              <td>{order.PAYMENT_DATE}</td>
                              <td>{order.FUNDING_ID}</td>
                              <td>{order.ORDERID}</td>
                              <td>
                                <NumberFormat
                                  value={order.PO_AMOUNT}
                                  displayType="text"
                                  thousandSeparator={true}
                                  prefix={order.PO_CCY + " "}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th></th>
                            <th>{appLabel.date}</th>
                            <th>{appLabel.fundingId}</th>
                            <th>{appLabel.poNo}</th>
                            <th>{appLabel.poAmount}</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
});

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageConsentedOrder);
