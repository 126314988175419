import React, { useEffect, useState } from "react";
import Main from "../../layouts/Main";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { apis as api } from "../../../services/api.action";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM, RAL } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import appStatic from "../../../config/appStaticData";
import { TableFacility2, AmntFormatFac, HandleSkeletonTbleFac, } from "../../layouts/Facility";
import NumberFormat from "react-number-format";
import Notiflix from "notiflix";

const PrivateBidList = (props) => {
  const { userData, menuData, location } = props;
  const { state } = location;
  const { profileId, session, companyId } = userData;
  const globalVar = window.globalData;
  const { tableData, notif } = appStatic;

  const dispatch = useDispatch();
  const [bidData, setBidData] = useState([]);
  const [publicbidData, setPublicBidData] = useState([]);
  const [bidActivity, setBidActivity] = useState([]);
  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");

  const [isPrivate, setIsPrivate] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  const [isSelectedBid, setIsSelectedBid] = useState(false);
  const [apiUrl, setApiUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [tiles, setTiles] = useState([
    {
      title: appLabel.poBid,
      otherTitle: appLabel.poBidListing,
      color: "primary",
      icon: "list",
      itemType: "po",
      reqUrl: api.GetBid,
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.invoiceBid,
      otherTitle: appLabel.invoiceBidListing,
      color: "warning",
      icon: "list",
      itemType: "inv",
      reqUrl: api.GetBid,
      callback: function (params) {
        getListByCriteria(params);
      },
    },
  ]);

  useEffect(() => {
    getListByCriteria(tiles[0]);
  }, []);

  function getListByCriteria(params) {
    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true)

    setTitleLeft(params.otherTitle);
    setTitleRight(params.title);
    setColor(params.color);
    setIcon(params.icon);

    //clear previous data
    setBidData([]);
    setPublicBidData([]);
    setApiUrl(undefined);
    setIsSelectedBid(false);
    setBidActivity([]);
    let itemType = "";
    let itemId = "";

    switch (params.itemType.toLowerCase()) {
      case "po":
        itemType = params.itemType;
        setApiUrl(api.ApproveRejectPODepositoryBid);
        break;
      case "inv":
        itemType = params.itemType;
        setApiUrl(api.ApproveRejectInvDepositoryBid);
        break;
    }

    let body = {
      profileId,
      itemType,
      itemId: "",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, params.reqUrl, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.bidListing;
        // setBidData(objList.private);
        // setPublicBidData(objList.depositoryBid);

        /** ORDER ITM S BY FUNDER RATE */
        setBidData([...sortByRate(objList.private)]);
        setPublicBidData([...sortByRate(objList.depositoryBid)]);

        BOM.DatatableUsage2();
        setIsLoading(false)
      }
    });
  }

  const onSelectPo = (objParam) => {
    // console.log(objParam);

    /** CHECK FOR RIGHT BTN PRIVILEGES */
    let path = appStatic.pathData.find(
      (item) => item.URL === location.pathname
    );
    if (path) {
      if (RAL.viewAction(menuData, path.DESCRIPTION)) {
        dispatch({
          type: cnt.RIGHT_DETAIL_BTN,
        });
      }
    }

    const mergedJson = { shareType: "Private", ...objParam };
    // console.log(mergedJson);
    // return
    globalVar.viewLink = {
      path: "/manage-funder-bid-detail",
      obj: mergedJson,
    };
  };
  const onSelectSectorType = (key) => {
    setIsPrivate(false);
    setIsBlock(false);
    setIsSelectedBid(false);
    setBidActivity([]);

    RAL.disableEnableRightAction(["CLEAR_RIGHT_BTN"]);
    uncheckAll(bidData);
    uncheckAll(publicbidData);
    // console.log(key);
    switch (key.toLowerCase()) {
      case "private":
        setIsPrivate(true);
        setIsBlock(true);
        break;

      default:
        setIsPrivate(false);
        setIsBlock(true);
        break;
    }
  };
  const onSelectItem = (objParam) => {
    // console.log(objParam);
    // setIsSelectedBid(false);
    // setBidActivity([]);
    let tempObj = {};
    let duplicateItem = {};

    // Do Validate for duplicate
    duplicateItem = bidActivity.find(
      (item) => item.intentId === objParam.INTENT_ID
    );

    if (duplicateItem) {
      // if found
      setBidActivity(
        bidActivity.filter((item) => item.intentId !== objParam.INTENT_ID)
      );
    } else {
      // if Not found
      tempObj = {
        intentId: objParam.INTENT_ID,
        itemId: objParam.ITEM_ID,
        itemType: objParam.ITEM_TYPE,
        depositoryId: objParam.DEPOSITORY_ID,
        depositoryName: objParam.DEPOSITORY_NAME,
        rate: objParam.CUMULATIVE_RATE,
        transactionFee: <AmntFormatFac inputValue={objParam.ARRANGEMENT_FEE} />,
        amountPayable1: (
          <AmntFormatFac inputValue={objParam.AMOUNT_PAYABLE_1} />
        ),
        amountPayable2: (
          <AmntFormatFac inputValue={objParam.AMOUNT_PAYABLE_2} />
        ),
        cumulativeRate: <AmntFormatFac inputValue={objParam.CUMULATIVE_RATE} />,
        interestAmount: (
          <AmntFormatFac inputValue={objParam.TOTAL_INTEREST_AMOUNT} />
        ),
        amountReceivable: <AmntFormatFac inputValue={objParam.AMOUNT_RECEIVEABLE} />,
        totalRepayment: <AmntFormatFac inputValue={objParam.TOTAL_REPAYMENT} />,
      };

      setBidActivity([...bidActivity, tempObj]);
      setIsSelectedBid(true); //for row layout
    }
  };
  function uncheckAll(arrList) {
    // console.log(arrList);
    arrList.forEach((element) => {
      $("#idkey" + element.INTENT_ID).prop("checked", false);
    });
  }
  function handleStatusIcon(key) {
    switch (key.toLowerCase()) {
      case "closed":
        return <i className="fa fa fa-play fa-rotate-90 text-danger" />;
      case "open":
        return <i className="fa fa-play fa-rotate-270 text-success" />;
    }
  }
  const onApproveBid = () => {
    let depositorys = [];

    if (bidActivity.length === 0) {
      BOM.AlertMsg(cnt.WARNING, "Please choose at least one Depositor.");
      return;
    }

    bidActivity.forEach((element) => {
      depositorys.push({
        intentId: element.intentId,
        depositoryId: element.depositoryId,
        bidStatus: "A",
      });
    });
    const body = {
      profileId,
      depositorys,
      session,
    };

    // console.log(body);
    // return;

    BOM.ApproveRejectWithReload(body, apiUrl, "Bid ", appLabel.accept + " ");
  };
  const onRejectBid = () => {
    let depositorys = [];

    if (bidActivity.length === 0) {
      BOM.AlertMsg(cnt.WARNING, "Please choose at least one Depositor.");
      return;
    }

    bidActivity.forEach((element) => {
      depositorys.push({
        intentId: element.intentId,
        depositoryId: element.depositoryId,
        bidStatus: "R",
      });
    });
    const body = {
      profileId,
      depositorys,
      session,
    };

    BOM.ApproveRejectWithReload(body, apiUrl, "Bid ", "Reject ");
  };

  function sortByRate(objList) {
    /** ORDER ITM S BY FUNDER RATE */
    const myData = []
      .concat(objList)
      .sort((a, b) =>
        parseFloat(a.CUMULATIVE_RATE || a.FUNDER_RATE) >
        parseFloat(b.CUMULATIVE_RATE || b.FUNDER_RATE)
          ? 1
          : -1
      );

    return myData;
  }
  /** RENDER JXS DATA */
  return (
    <div className="animated fadeInRightFAKE">
      <div className="row">
        <div className="col-sm-12">
          <label className="al-heading">
            <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
            {appLabel.selectSector}
          </label>
          <div className="hr-line-dashed" style={{ marginTop: 0 }} />
          <div className="col-sm-6 m-b-xs">
            <div data-toggle="buttons" className="btn-group">
              {appStatic.requestFundingType.map((item) => (
                <label
                  onClick={() => onSelectSectorType(item.value)}
                  className="btn btn-sm btn-white"
                  key={item.value}
                >
                  {" "}
                  <input type="radio" />{" "}
                  <i
                    className={`fa fa-${
                      item.value === "private" ? "user" : "users"
                    }`}
                  />{" "}
                  &nbsp; {item.name}{" "}
                </label>
              ))}
            </div>
          </div>
          <div className={`col-sm-6 ${isBlock ? "" : "hide"}`}>
            <a className="btn btn-primary btn-rounded btn-block">
              <i
                className={`text-warning fa fa-${isPrivate ? "user" : "users"}`}
              />{" "}
              &nbsp; {isPrivate ? "Private " : "Public "} {appLabel.bidding}
            </a>
          </div>
        </div>
      </div>
      <div className={`row ${isBlock ? "" : "hide"}`}>
        <form className="m-t" autoComplete="off">
          <div className={`${isSelectedBid ? "col-sm-7" : "col-lg-12"}`}>
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>{titleLeft}</h5>
                <div className="ibox-tools">
                  <a className="dropdown-toggle" data-toggle="dropdown">
                    <span className={`badge badge-${color}`}>
                      <i className={`fa fa-${icon}`}></i> &nbsp; {titleRight}{" "}
                      &nbsp;
                      <i className="fa fa-chevron-down"></i>
                    </span>
                  </a>
                  <ul className="dropdown-menu dropdown-user">
                    {tiles.map((tile, tileIndex) => {
                      if (tile.title !== titleRight) {
                        return (
                          <li key={tileIndex}>
                            <a onClick={() => tile.callback(tile)}>
                              {" "}
                              {tile.title}
                            </a>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>

              <div className="ibox-content panel-primary contentCard">
              <HandleSkeletonTbleFac
                    classParam={`${isLoading ? "" : "hide"}`}
                  />
                  <div className={` ${isLoading ? "hide" : ""}`}>
                <div className={`row ${isPrivate ? "" : "hide"}`}>
                  {bidData.length === 0 ? (
                    <div className="alert alert-warning text-center">
                      <a className="alert-link">{appLabel.noDataInTableMsg}</a>
                    </div>
                  ) : (
                    <TableFacility2 thData={tableData.bidList}>
                      {bidData.map((item, index) => (
                        <tr className="gradeX" key={index}>
                          <td>
                            <label className="myradio-button">
                              <input
                                type="radio"
                                name="radio"
                                id={`idkey${item.INTENT_ID}`}
                                onClick={() => onSelectPo(item)}
                              />
                              <span className="label-visible">
                                <span className="myfake-radiobutton"></span>
                              </span>
                            </label>
                          </td>
                          <td>{item.INTENT_ID}</td>
                          <td>{item.ITEM_ID}</td>
                          <td>{item.BID_COUNT}</td>
                          <td>
                            <AmntFormatFac inputValue={item.REQUESTED_AMOUNT} />
                          </td>
                          <td>
                            {handleStatusIcon(item.BIDDING_STATUS)}{" "}
                            {item.BIDDING_STATUS}
                          </td>
                        </tr>
                      ))}
                    </TableFacility2>
                  )}
                </div>
                <div className={`row ${!isPrivate ? "" : "hide"}`}>
                  {publicbidData.length === 0 ? (
                    <div className="alert alert-warning text-center">
                      <a className="alert-link">{appLabel.noDataInTableMsg}</a>
                    </div>
                  ) : (
                    <TableFacility2 thData={tableData.depositoryBidList}>
                      {publicbidData.map((item, index) => (
                        <tr className="gradeX" key={index}>
                          <td>
                            {/* <label className="myradio-button">
                              <input
                                type="radio"
                                name="radio"
                                onClick={() => onSelectItem(item)}
                              />
                              <span className="label-visible">
                                <span className="myfake-radiobutton"></span>
                              </span>
                            </label> */}
                            <label>
                              {" "}
                              <input
                                type="checkbox"
                                id={`idkey${item.INTENT_ID}`}
                                onClick={() => onSelectItem(item)}
                                style={{ cursor: "pointer" }}
                              />
                            </label>
                          </td>
                          <td>{item.INTENT_ID}</td>
                          <td>{item.ITEM_ID}</td>
                          <td>{item.DEPOSITORY_NAME}</td>
                          <td>{item.CUMULATIVE_RATE}</td>
                          <td>
                            <AmntFormatFac inputValue={item.TOTAL_REPAYMENT} />
                          </td>
                        </tr>
                      ))}
                    </TableFacility2>
                  )}
                </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`${
              isSelectedBid ? "col-sm-5 animated fadeInRightFAKE" : "hide"
            }`}
          >
            <div className="ibox ">
              <div className="ibox-content contentCard">
                <div className="row m-b-lg">
                  <div className="col-xs-6 col-sm-6">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm btn-block"
                      onClick={() => onApproveBid()}
                    >
                      <i className="fa fa-thumbs-up" /> &nbsp;{" "}
                      {appLabel.accept}
                    </button>
                  </div>
                  <div className="col-xs-6 col-sm-6">
                    <button
                      type="button"
                      className="btn btn-danger btn-sm btn-block"
                      onClick={() => onRejectBid()}
                    >
                      <i className="fa fa-thumbs-down" /> &nbsp;{" "}
                      {appLabel.reject}
                    </button>
                  </div>
                </div>
                <div className="client-detail">
                  {bidActivity.length === 0
                    ? "Start bidding "
                    : appLabel.bidingActivity}

                  <div
                    id="vertical-timeline"
                    className="vertical-container dark-timeline"
                  >
                    {bidActivity.map((item, index) => (
                      <div key={index} className="vertical-timeline-block">
                        <div className="vertical-timeline-icon gray-bg">
                          <i className="fa fa-gavel text-success" />
                        </div>
                        <div className="vertical-timeline-content">
                          <div>
                            <span className="pull-right">{item.itemType}</span>
                            <i className="fa fa-dot-circle-o text-success" />
                            &nbsp;{appLabel.itemType}
                          </div>
                          <div>
                            <span className="pull-right">
                              {item.depositoryName.length > 5
                                ? item.depositoryName.substring(0, 5) + "..."
                                : item.depositoryName}
                            </span>
                            <i className="fa fa-dot-circle-o text-success" />
                            &nbsp;{appLabel.depoName}
                          </div>
                          {/* <div>
                            <span className="pull-right">{item.amount}</span>
                            <i className="fa fa-dot-circle-o text-success"/>&nbsp;{appLabel.amount}
                          </div> */}
                          <div>
                            <span className="pull-right">
                              {item.transactionFee}
                            </span>
                            <i className="fa fa-dot-circle-o text-success" />
                            &nbsp;{appLabel.transactionFee}
                          </div>
                          <div>
                            <span className="pull-right">
                              {item.amountPayable1}
                            </span>
                            <i className="fa fa-dot-circle-o text-success" />
                            &nbsp;{appLabel.amountPayable1}
                          </div>
                          <div>
                            <span className="pull-right">
                              {item.amountPayable2}
                            </span>
                            <i className="fa fa-dot-circle-o text-success" />
                            &nbsp;{appLabel.amountPayable2}
                          </div>

                          <div>
                            <span className="pull-right">
                              {item.cumulativeRate}
                            </span>
                            <i className="fa fa-dot-circle-o text-success" />
                            &nbsp;{appLabel.cumulativeRate}
                          </div>
                          <div>
                            <span className="pull-right">
                              {item.interestAmount}
                            </span>
                            <i className="fa fa-dot-circle-o text-success" />
                            &nbsp;{appLabel.interestAmount}
                          </div>
                          
                          <div>
                            <span className="pull-right">
                              {item.amountReceivable}
                            </span>
                            <i className="fa fa-dot-circle-o text-success" />
                            &nbsp;{appLabel.amountReceivable}
                          </div>
                          <div>
                            <span className="pull-right">
                              {item.totalRepayment}
                            </span>
                            <i className="fa fa-dot-circle-o text-success" />
                            &nbsp;{appLabel.totalRepayment}
                          </div>
                          <span className="vertical-date small text-muted m-t">
                            {item.dateTimeStr}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PrivateBidList;
