/**
 * @author Gedeon NdundeCode
 * @date 13/01/2024
 */
import React, { useState, useEffect } from "react";
import useGeolocation from "react-hook-geolocation";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { apis as api } from "../../services/api.action";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import { BgImgFac, HandleReqFldMsg } from "../../components/layouts/Facility";
import history from "../../history";
import AOS from "aos";
import Privacy from "../../other_files/terms/Privacy";
import appStatic from "../../config/appStaticData";

const ProtectedPrivacy = (props) => {
  /** INITIALIZE VARIABLES */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const { userData, isSignedIn, location } = props;
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [switchBlock, setSwitchBlock] = useState(isSignedIn);
  const [isUserLogin, setIsUserLogin] = useState(isSignedIn);
  const { privacyPolicyDocumentData } = appStatic;

  const onSwitchModule = (itemObj) => {
    if (itemObj?.isProtected && !isUserLogin) {
        BOM.AlertMsg(cnt.WARNING, "Please enter your login information to view the protected content");
      } else {
        setSwitchBlock(!switchBlock);
      }
  };

  useEffect(() => {
    AOS.init({
      easing: "ease-out-back",
      duration: 1000,
      // disable: 'mobile'
      disable: function () {
        var maxWidth = 1280;
        return window.innerWidth < maxWidth;
      },
    });
  }, []);

  window.onscroll = function () {
    // show or hide the back-top-top button
    var backToTo = document.querySelector(".fEx-scroll-top");
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 0
    ) {
      backToTo.style.display = "flex";
    } else {
      backToTo.style.display = "none";
    }
  };
  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  /** SUBMISSION */
  const onSignIn = (formData) => {
    setIsLoading(true);

    const body = {
      userName: formData.userName,
      userPass: formData.userPass,
      sourceIp: "197.184.164.52", //ip.IPv4,
      browser: BOM.GetBrowserName(),
      host: window.location.host,
      osName: "windows",
      longitude: "-25.757", //ip.longitude + "",
      latitude: "28.1443", //ip.latitude + "",
      channel: "self service",
    };

    BOM.SessionLessReqAction(body, api.Authentication, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
        setIsLoading(false);
      } else {
        //GET DYNAMIC MENU FOR CURRENT USER
        getRoleMenu(res.userData);

        dispatch({
          type: cnt.SIGN_IN,
          payload: res,
        });
        history.push("protected-privacy-document");
        setIsUserLogin(true)
        setSwitchBlock(true)
        setIsLoading(false);
      }
    });
  };
  async function getRoleMenu(user) {
    // BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId: user.profileId,
      roleId: user.userRoleId,
      session: user.session,
    };

    BOM.FetchReqAction(body, api.GetRoleMenu, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.roleData.FUNCTIONS;
        dispatch({
          type: cnt.MENU_DATA,
          payload: objList,
        });
        // $("#side-menu").metisMenu();
      }
    });
  }

  return (
    <>
      {switchBlock ? (
        <Privacy onSwitchModule={onSwitchModule} isUserLogin={isUserLogin} />
      ) : (
        <div className="fEx-innovative-platform">
          <div className="fEx-container">
            <div className="fEx-row">
              <div className="fEx-col-6">
                <div className="fEx-Innovative-img" data-aos="fade-up">
                  <form onSubmit={handleSubmit(onSignIn)} autoComplete="off">
                    <div className="fEx-fild-protected-row">
                      <label htmlFor="user">{appLabel.usernameOrEmail}</label>
                      <input
                        type="text"
                        className="fEx-from-input fEx-zero-input-focus"
                        placeholder={appLabel.usernameOrEmail}
                        {...register("userName", {
                          required: HandleReqFldMsg(appLabel.usernameOrEmail),
                          pattern: {
                            value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                            message: "Email must be a valid",
                          },
                        })}
                      />
                      <span className="asterisks-landing">
                        {errors?.userName?.message}
                      </span>
                    </div>
                    <div className="fEx-fild-protected-row">
                      <label htmlFor="password" className="protected">
                        {appLabel.password}
                      </label>
                      <input
                        type={passwordShown ? "text" : "password"}
                        className="fEx-from-input fEx-zero-input-focus"
                        placeholder={appLabel.password}
                        {...register("userPass", {
                          required: HandleReqFldMsg(appLabel.password),
                          minLength: {
                            value: 8,
                            message: "Minimum password length is 8 characters",
                          },
                        })}
                      />
                      <span
                        className="p-viewer"
                        onClick={() => togglePassword()}
                      >
                        <i
                          className={
                            passwordShown ? "fa fa-eye-slash" : "fa fa-eye"
                          }
                        ></i>
                      </span>
                      <span className="asterisks-landing">
                        {errors?.userPass?.message}
                      </span>
                    </div>
                    <div className="fEx-fild-row">
                      <label
                        className="fEx-check-box"
                        htmlFor="login-remember-me"
                      >
                        &nbsp;
                      </label>
                    </div>
                    <div className="fEx-fild-row">
                      <button
                        type="submit"
                        disabled={isLoading}
                        className={`${
                          isLoading ? "fEx-on-click-btn-login" : "fEx-btn-login"
                        }`}
                      >
                        {isLoading ? (
                          <span>
                            <i className="fa fa-spinner fa-spin" /> &nbsp;{" "}
                            {appLabel.processing}
                          </span>
                        ) : (
                          appLabel.btnLogin
                        )}
                      </button>
                    </div>
                    <div className="fEx-fild-link">
                      <a
                        className="fEx-password"
                        onClick={() => history.push("/sign-in")}
                      >
                        <i className="fa fa-long-arrow-left" /> {appLabel.goToLogin}
                      </a>
                    </div>
                  </form>
                </div>
              </div>
              <div className="fEx-col-6">
                <div className="fEx-Innovative-info" data-aos="fade-down">
                  <p>
                    Secure, Robust and Innovative Platform for Buyers,
                    Suppliers, and Funders to authenticate and manage supply
                    chain transactions
                  </p>
                  <span className="fEx-divider" />
                  <ul className="fEx-icon-list-items">
                  {privacyPolicyDocumentData.map((item, index) => (
                      <li key={index}>
                        <a onClick={() => onSwitchModule(item)}>
                          <i className={item.icon} />
                          &nbsp;{item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <p>Alternatively, please feel free to contact us</p>
                  {/* <div className="fEx-btn-action">
                    <a href="#">
                      <span>Contact Us</span>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <a
        href="#"
        className="fEx-scroll-top"
        aria-label="Back to top"
        alt="back-to-top"
      >
        <i className="fa fa-arrow-up" />
      </a>
    </>
  );
};

const mapStateToProps = (state) => ({
  isSignedIn: state.auth.isSignedIn,
  userData: state.auth.userData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedPrivacy);
