import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";

function InvoiceReceipt(props) {
  
  const [invoiceDetail, setInvoiceDetail] = useState({});

  useEffect(() => {
    console.log(props);
    setInvoiceDetail(props);
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div className="wrapper wrapper-content animated fadeInRight">
            <div className="ibox-content p-xl">
              <div className="row">
                <div className="col-sm-6">
                  <h5>From:</h5>
                  <address>
                    <strong>{invoiceDetail.SUPPLIER_COMPANY_NAME}.</strong>
                    <br />
                    address placeholder
                    <br />
                   address placeholder
                    <br />
                    <abbr title="Phone">P:</abbr> phone place holder
                  </address>
                </div>
                <div className="col-sm-6 text-right">
                  <h4>Invoice No.</h4>
                  <h4 className="text-navy">{invoiceDetail.INV_ID}</h4>
                  <span>To:</span>
                  <address>
                    <strong>{invoiceDetail.CUSTOMER_COMPANY_NAME}</strong>
                    <br />
                    address placeholder
                    <br />
                    address placeholder
                    <br />
                    <abbr title="Phone">P:</abbr> phone place holder
                  </address>
                  <p>
                    <span>
                      <strong>Invoice Date:</strong> {invoiceDetail.INV_DATE}
                    </span>
                    <br />
                    <span>
                      <strong>Due Date:</strong> {invoiceDetail.INVOICE_DUE_DATE}
                    </span>
                  </p>
                </div>
              </div>
              <div className="table-responsive m-t">
                <table className="table invoice-table">
                  <thead>
                    <tr>
                      <th>Item List</th>
                      <th>Quantity</th>
                      <th>Unit</th>
                      <th>Unit Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <strong>Placeholder</strong>
                        </div>
                        <small>
                          content placeholder
                        </small>
                      </td>
                      <td>1</td>
                      <td>1</td>
                      <td><NumberFormat
                        value={invoiceDetail.ORIGINAL_AMOUNT}
                        displayType="text"
                        thousandSeparator={true}
                        prefix={invoiceDetail.INV_CCY + " "}
                      /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* /table-responsive */}
              <table className="table invoice-total">
                <tbody>
                  <tr>
                    <td>
                      <strong>Sub Total :</strong>
                    </td>
                    <td>
                      <NumberFormat
                        value={invoiceDetail.ORIGINAL_AMOUNT}
                        displayType="text"
                        thousandSeparator={true}
                        prefix={invoiceDetail.INV_CCY + " "}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>TAX :</strong>
                    </td>
                    <td>
                      <NumberFormat
                        value={0}
                        displayType="text"
                        thousandSeparator={true}
                        prefix={invoiceDetail.INV_CCY + " "}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>TOTAL :</strong>
                    </td>
                    <td>
                      <NumberFormat
                        value={invoiceDetail.FINAL_AMOUNT}
                        displayType="text"
                        thousandSeparator={true}
                        prefix={invoiceDetail.INV_CCY + " "}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceReceipt;
