import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import NumberFormat from "react-number-format";
import InvoiceReceipt from "./InvoiceReceipt";

function PrintInvoiceReceipt(props) {
  const { recordData } = props;
  
  const [invoiceDetail, setInvoiceDetail] = useState({});

  useEffect(() => {
    // console.log(recordData);
    /** BUY TIME with setTimeout function to wait for all data to be loaded
     *  then RUN WINDON PRINT FUNCTION */
     setTimeout(() => {
      window.print();
    }, 500);
  }, []);
  return <InvoiceReceipt {...recordData} />;
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrintInvoiceReceipt);
