import React, { useEffect, useState } from "react";
import axios from "axios";
import Main from "../../layouts/Main";
import { connect, useDispatch } from "react-redux";
import { apis as api } from "../../../services/api.action";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM, RAL } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import NumberFormat from "react-number-format";
import appStatic from "../../../config/appStaticData";
import history from "../../../history";
import Notiflix from "notiflix";
import appConfig from "../../../config/appConfig.json";

const ManageLoanReport = (props) => {
  const { userData, menuData, location, authBuffer } = props;
  const { profileId, session, companyId, finsysClientId, companyTypeId } =
    userData;
  const globalVar = window.globalData;
  const dispatch = useDispatch();
  const [loanData, setLoanData] = useState([]);
  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [isSupplier, setIsSupplier] = useState(false);

  useEffect(() => {
    // console.log(props);
    getListOfLoan();
    // handleSwitchCondition();
  }, []);

  const handleSwitchCondition = () => {
    switch (companyTypeId) {
      case "CT2022012400002": // supplier
        setIsSupplier(true);
        break;
      default:
        BOM.AlertMsg(cnt.WARNING, "Loan Report is only Reserved for Supplier.");
        history.push("/dashboard");
        setTimeout(() => {
          window.location.href = "";
        }, 3000);
        break;
    }
  };

  const getListOfLoan = () => {
    if (!finsysClientId) {
      BOM.AlertMsg(cnt.WARNING, "Finsys ID is required.");
      return;
    }

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      finsysId:finsysClientId,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetLoanPortfolioList, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const loanData = res.loanData;
        setLoanData(loanData ? loanData : []);
        BOM.DatatableUsage();
      }
    });

  };

  const onSelectLoan = (objParam) => {
    // console.log(objParam);
    /** CHECK FOR RIGHT BTN PRIVILEGES */
    let path = appStatic.pathData.find(
      (item) => item.URL === location.pathname
    );
    if (path) {
      if (RAL.viewAction(menuData, path.DESCRIPTION)) {
        dispatch({
          type: cnt.RIGHT_DETAIL_BTN,
        });
      }
    }
    globalVar.viewLink = {
      path: "/loan-detail",
      obj: objParam,
    };
  };

  /** RENDER JXS DATA */
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.loanReport} />
      <div className="animated fadeInRight">
        <div className="row">
          <form className="m-t" autoComplete="off">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  {/* <h5>{titleLeft}</h5>
                  <div className="ibox-tools">
                    <a className="dropdown-toggle" data-toggle="dropdown">
                      <span className={`badge badge-${color}`}>
                        <i className={`fa fa-${icon}`}></i> &nbsp; {titleRight}{" "}
                        &nbsp;
                        <i className="fa fa-chevron-down"></i>
                      </span>
                    </a>
                    <ul className="dropdown-menu dropdown-user">
                      {tiles.map((tile, tileIndex) => {
                        if (tile.title !== titleRight) {
                          return (
                            <li key={tileIndex}>
                              <a onClick={() => tile.callback(tile)}>
                                {" "}
                                {tile.title}
                              </a>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div> */}
                </div>

                <div className="ibox-content panel-primary contentCard">
                  {loanData.length === 0 ? (
                    <div className="alert alert-warning text-center">
                      <a className="alert-link">{appLabel.noDataInTableMsg}</a>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <div className="table-responsive">
                        <table
                          id="myTable"
                          className="table table-striped table-bordered table-hover"
                        >
                          <thead>
                            <tr>
                              <th></th>
                              <th>{appLabel.loanId}</th>
                              <th>{appLabel.originalLoan}</th>
                              <th>{appLabel.loanBalance}</th>
                              <th>{appLabel.maturityDate}</th>
                              <th>{appLabel.status}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loanData.map((loan, index) => (
                              <tr className="gradeX" key={index}>
                                <td>
                                  <label className="myradio-button">
                                    <input
                                      type="radio"
                                      name="radio"
                                      onClick={() => onSelectLoan(loan)}
                                    />
                                    <span className="label-visible">
                                      <span className="myfake-radiobutton"></span>
                                    </span>
                                  </label>
                                </td>
                                <td>{loan.accountNo}</td>
                                <td>
                                  <NumberFormat
                                    value={loan.originalLoan}
                                    displayType="text"
                                    thousandSeparator={true}
                                    // prefix={"NGN" + " "}
                                  />
                                </td>
                                <td>
                                  <NumberFormat
                                    value={loan.loanBalance}
                                    displayType="text"
                                    thousandSeparator={true}
                                    // prefix={"NGN" + " "}
                                  />
                                </td>
                                <td>
                                  {loan.timeline.expectedMaturityDate[0] +
                                    "-" +
                                    loan.timeline.expectedMaturityDate[1] +
                                    "-" +
                                    loan.timeline.expectedMaturityDate[2]}
                                </td>
                                <td>{loan.status.value}</td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th></th>
                              <th>{appLabel.loanId}</th>
                              <th>{appLabel.originalLoan}</th>
                              <th>{appLabel.loanBalance}</th>
                              <th>{appLabel.maturityDate}</th>
                              <th>{appLabel.status}</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  authBuffer: state.auth.authBuffer,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ManageLoanReport);
