import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { BOM } from "../../../services/defined.action";
import { apis as api } from "../../../services/api.action";
import { cnt } from "../../../services/constant.action";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import appLabel from "../../../config/appLabel";
import history from "../../../history";

function ManageRoleMenu(props) {
  const { userData } = props;
  const { profileId, session, companyId } = userData;
  const [roleName, setRoleName] = useState("");
  const [roleData, setRoleData] = useState([]);
  const [subRoleData, setSubRoleData] = useState([]);
  const [roleObj, setRoleObj] = useState({});

  useEffect(() => {
    $(".footable").footable();
    getRole();
  }, []);

  function getRole() {

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      criteria: "A",
      roleId: "",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetRole, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setRoleData(res.roleListing);
        getSubRole(res.roleListing[1])
      }
    });
  }

  const getSubRole = (parentObj) => {
    BOM.LoadAlert(cnt.LOAD, "Processing");
    /** UPDATE TO ROLE HEADING */
    setRoleName(`${parentObj.ROLE_NAME} listing`);
    setRoleObj(parentObj);

    let getSubRoleBody = {
      profileId,
      entityId:companyId,
      parentId: parentObj.ROLE_ID,
      session,
    };

    /** fetch (reqBody, url, svc, callback) */
    BOM.FetchReqAction(getSubRoleBody, api.GetSubRole, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setSubRoleData(res.roleListing);
        // BOM.DatatableUsage();
      }
    });
  };
  

  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.roleMenu} />
      <div className="animated fadeInRight">
        <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>{roleName}</h5>

                  <div className="ibox-tools">
                    <a className="dropdown-toggle" data-toggle="dropdown">
                      <span
                        className={`${
                          !roleObj.ROLE_DESCRIPTION
                            ? "badge badge-warning"
                            : "badge badge-primary"
                        }`}
                      >
                        <i className={`fa fa-unlock`}></i> &nbsp;{" "}
                        {!roleObj.ROLE_DESCRIPTION
                          ? "Choose Role"
                          : roleObj.ROLE_DESCRIPTION}{" "}
                        &nbsp;
                        <i className="fa fa-chevron-down"></i>
                      </span>
                    </a>
                    <ul className="dropdown-menu dropdown-user">
                      {roleData.map((role, index) => {
                        return (
                          <li key={index}>
                            <a onClick={() => getSubRole(role)}>
                              {" "}
                              {role.ROLE_DESCRIPTION}
                            </a>
                          </li>
                        );
                      })}
                    </ul>

                    <Link
                      to={{
                        pathname: "/new-subrole",
                        state:{
                          ggParam: roleObj,
                        }
                      }}
                      className={`btn btn-primary btn-xs ${
                        roleName ? "" : "hide"
                      }`}
                      title="Add Role"
                    >
                      <i
                        className="fa fa-plus-circle"
                      />
                       &nbsp;{appLabel.newRole}
                    </Link>
                  </div>
                </div>

                <div className="ibox-content panel-primary content-card-v2">
                  <div className="table-responsive">
                    {subRoleData.length === 0 ? (
                      <div className="alert alert-warning text-center">
                        <a className="alert-link">
                          {appLabel.noDataInTableMsg}
                        </a>
                      </div>
                    ) : (
                     <div>
                        <table
                        className="footable table table-stripped toggle-arrow-tiny"
                        data-page-size="10"
                        data-filter="#filter"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>{appLabel.roleId}</th>
                            <th>{appLabel.roleName}</th>
                            <th>{appLabel.roleDescription}</th>
                            <th>{appLabel.flgStatusName}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {subRoleData.map((subRole, index) => (
                            <tr className="gradeX" key={index}>
                              <td>{index + 1}</td>
                              <td>{subRole.ROLE_ID}</td>
                              <td>{subRole.ROLE_NAME}</td>
                              <td className="center">
                                {subRole.ROLE_DESCRIPTION}
                              </td>
                              <td className="center">
                                <i
                                  className={
                                    subRole.FLG_STATUS.toLowerCase() === "a"
                                      ? "fa fa-check text-navy"
                                      : "fa fa-times text-danger"
                                  }
                                ></i>
                                &nbsp;{subRole.FLG_STATUS.toLowerCase() === "a"
                                  ? appLabel.authorized
                                  : appLabel.unauthorized}
                              </td>
                              <td className="project-actions">
                                <div>
                                  <Link to={{
                                      pathname: "/subrole-detail",
                                      state:{
                                        ggParam: subRole,
                                      }
                                    }}
                                    className="btn btn-primary btn-xs"
                                    title="View More"
                                  >
                                    <i
                                      className="fa fa-folder-open"
                                    />
                                    &nbsp; {appLabel.viewMore}
                                  </Link>
                                  &nbsp;
                                  <Link
                                    to={{
                                      pathname: "/edit-subrole",
                                      state:{
                                        ggParam: subRole,
                                      }
                                    }}
                                    className="btn btn-primary btn-xs"
                                    title="Edit Role"
                                  >
                                    <i
                                      className="fa fa-edit"
                                    />
                                    &nbsp;{appLabel.editRole}
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan="6">
                              <ul className="pagination pull-right"></ul>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                     </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </Main>
  );
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ManageRoleMenu);
