import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Main from "../../../layouts/Main";
import ContentTop from "../../../ui-setup/ContentTop";
import appLabel from "../../../../config/appLabel";
import { BOM } from "../../../../services/defined.action";
import RightSide from "../../../ui-setup/RightSide";
import RecordStatus from "../../../ui-setup/RecordStatus";
import { apis as api } from "../../../../services/api.action";
import { cnt } from "../../../../services/constant.action";
import appStatic from "../../../../config/appStaticData";
import Notiflix from "notiflix";

export const NewMenu = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { location, history, userData } = props;
  const { state } = location;
  const { profileId, session, companyId } = userData;

  const [functionData, setFunctionData] = useState([]);
  const [parentObj, setParentObj] = useState({});

  const [mainNameposition, setMainNameposition] = useState("");

  const [addAllResult, setAddAllResult] = useState(false);
  const [addAllText, setAddAllText] = useState("");

  const [newUserFunction, setNewUserFunction] = useState([]);
  const [readButton, setReadButton] = useState("");
  const [thirdLevel, setThirdLevel] = useState("");

  const [subMenu, setSubMenu] = useState([]);

  useEffect(() => {
    // console.log(props);

    if (!state.ggParam) {
      history.push("/manage-entity-menu");
      return;
    }
    getMenu();
    setAddAllResult(true);
    setAddAllText("Add All");
    setReadButton("Read More");

    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", formSubmit);
    /** fetch (reqBody, url, svc, callback) */
    BOM.LoadAlert(cnt.LOAD, "Processing");


    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);
  }, []);

  function getMenu() {

    BOM.LoadAlert(cnt.LOAD, "Processing");

    /** FETCH MENU */
    let body = {
      profileId,
      criteria: "A",
      functionId: "",
      session,
    };
    BOM.FetchReqAction(body, api.GetMenu, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        // console.log(res);
        var roleMenu = [];
        roleMenu = res.functionData;

        console.log(roleMenu);
        setFunctionData(roleMenu);

        if (roleMenu.length !== 0) {
          setMainNameposition(roleMenu[0].DESCRIPTION.toLowerCase());
          setParentObj(roleMenu[0]);
        }
      }
    });
  }

  const addAll = (param) => {
    // console.log(param);
    switch (param) {
      case true:
        /** STORE CURRENT USER DATA */
        let childArrMenu = [];
        let childInner = [];
        let outerArray = [];
        for (let index = 0; index < functionData.length; index++) {
          const element = functionData[index];

          for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
            const subElmt = element.SUB_MENU[sub];
            // if (element.FUNCTION_ID) {
            for (const key in subElmt.CHILD_SUB_MENU) {
              const childElmt = subElmt.CHILD_SUB_MENU[key];
              childInner.push({
                functionId: childElmt.FUNCTION_ID,
                description: childElmt.DESCRIPTION,
                action: {
                  // search: "1",
                  create: "1",
                  view: "1",
                  edit: "1",
                  approve: "1",
                  reject: "1",
                },
              });
            }

            childArrMenu.push({
              functionId: subElmt.FUNCTION_ID,
              description: subElmt.DESCRIPTION,
              childMenu: childInner,
              action: {
                // search: "1",
                create: "1",
                view: "1",
                edit: "1",
                approve: "1",
                reject: "1",
              },
            });

            childInner = [];
          }

          outerArray.push({
            functionId: element.FUNCTION_ID,
            description: element.DESCRIPTION,
            action: "1",
            subMenu: childArrMenu, // sub array.
          });

          childArrMenu = [];
        }
        outerArray = BOM.GetUnique(outerArray, "functionId");
        // console.log("Final Data: ", outerArray);
        setNewUserFunction([...outerArray]);

        /** end prepare */
        setAddAllResult(false);
        setAddAllText("Remove All");
        break;
      default:
        // console.log("addll: ", param, " is clicked");

        setAddAllResult(true);
        setAddAllText("Add All");
        setNewUserFunction([]);
        break;
    }
  };

  const selectedFunction = (parentData) => {
    setSubMenu(parentData.SUB_MENU);
    setParentObj(parentData);
  };

  const onAddBtn = (currentSubArray) => {
    let subTemp = [];

    currentSubArray.forEach((element) => {
      let create = $("#create" + element.FUNCTION_ID).prop("checked")
        ? "1"
        : "0";
      let view = $("#view" + element.FUNCTION_ID).prop("checked") ? "1" : "0";
      let edit = $("#edit" + element.FUNCTION_ID).prop("checked") ? "1" : "0";
      let approve = $("#approve" + element.FUNCTION_ID).prop("checked")
        ? "1"
        : "0";
      let reject = $("#reject" + element.FUNCTION_ID).prop("checked")
        ? "1"
        : "0";

      subTemp.push({
        functionId: element.FUNCTION_ID,
        description: element.DESCRIPTION,
        childMenu: [],
        action: {
          create,
          view,
          edit,
          approve,
          reject,
        },
      });
    });

    setNewUserFunction([
      ...newUserFunction,
      {
        functionId: parentObj.FUNCTION_ID,
        description: parentObj.DESCRIPTION,
        action: "1",
        subMenu: subTemp, //BOM.GetUnique(subTemp, "functionId") // sub array.
      },
    ]);

    Notiflix.Notify.success(parentObj.DESCRIPTION + " Menu was added!");
  };

  const formSubmit = (e) => {
    e.preventDefault();
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let tempFunc = [...newUserFunction];
    tempFunc = BOM.GetUnique(tempFunc, "functionId"); // sanitize the final JSON
    // console.log('tempFunc: ', tempFunc);
    // console.log('final obj: ', newUserFunction);

    if (tempFunc.length === 0) {
      BOM.AlertMsg(cnt.WARNING, "Please add at least one menu before saving !");
      return;
    }

    let newSubroleMenuBody = {
      profileId,
      roleId: state.ggParam.ROLE_ID,
      functions: tempFunc,
      session,
    };

    // console.log("new Menu data ", newSubroleMenuBody);
    /** SEND REQ */
    BOM.SendReqAction(
      newSubroleMenuBody,
      api.AssignRoleMenu,
      "manage-role-menu"
    );
  };

  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={(e) => formSubmit(e)}
      >
        <RightSide />
        <ContentTop pageTitle={appLabel.newMenu} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>
                    {state && state.ggParam.ROLE_NAME.length <= 25
                      ? state.ggParam.ROLE_NAME
                      : state.ggParam.ROLE_NAME + "..."}
                  </h5>
                  <div className="ibox-tools">
                    <Link
                      to="/manage-role-menu"
                      ui-sref="user-group-menu"
                      className="btn btn-primary btn-xs"
                    >
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>

                    {functionData.length !== 0 && (
                      <a onClick={() => addAll(addAllResult)}>
                        <span
                          className={`${
                            addAllResult
                              ? "btn btn-primary btn-xs"
                              : "btn btn-danger btn-xs"
                          }`}
                        >
                          <i className="fa fa-chevron-circle-right" /> &nbsp;
                          {addAllText}
                        </span>
                      </a>
                    )}
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  {functionData.length === 0 ? (
                    <div
                    style={{ marginTop: 35, fontWeight: 'bold'}}
                      className="alert alert-warning text-center"
                    >
                      {appLabel.yetToConfigure}
                    </div>
                  ) : (
                    <>
                      {addAllResult ? (
                        <>
                          <ul className="nav nav-tabs">
                            {functionData.map((item, index) => (
                              <li
                                className={`${
                                  item.DESCRIPTION.toLowerCase() ===
                                  mainNameposition
                                    ? "active"
                                    : ""
                                }`}
                                key={index}
                              >
                                <a
                                  data-toggle="tab"
                                  href="#tab-2"
                                  target="_self"
                                  onClick={() => selectedFunction(item)}
                                >
                                  <i
                                    className={`fa fa-${item.ICON} text-navy`}
                                  />
                                  &nbsp; {item.DESCRIPTION}
                                </a>
                              </li>
                            ))}
                          </ul>
                          <div className="tab-content">
                            <div id="tab-1" className="tab-pane active">
                              <div className="full-height-scroll">
                                <div className="table-responsive">
                                  {functionData.length === 0 ? (
                                    <div
                                    style={{ marginTop: 35, fontWeight: 'bold'}}
                                      className="alert alert-warning text-center"
                                    >
                                      {appLabel.this_menu_has_no_sub_menu}
                                    </div>
                                  ) : (
                                    <table className="table table-striped table-hover">
                                      <tbody>
                                        {functionData[0].SUB_MENU.map(
                                          (submenu, index) => (
                                            <tr key={index}>
                                              <td>
                                                <label
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  &nbsp; {submenu.DESCRIPTION}
                                                </label>
                                              </td>
                                              <td>
                                                {appStatic.subActionList.map(
                                                  (item, index) => (
                                                    <label
                                                      key={index}
                                                      className="al-subtitle"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      &nbsp; &nbsp;
                                                      <input
                                                        type="checkbox"
                                                        id={`${
                                                          item.name +
                                                          submenu.FUNCTION_ID
                                                        }`}
                                                      />
                                                      &nbsp;{" "}
                                                      {BOM.Uwfirst(item.name)}
                                                    </label>
                                                  )
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                        {
                                          <tr>
                                            <td>
                                              <a
                                                onClick={() =>
                                                  onAddBtn(
                                                    functionData[0].SUB_MENU
                                                  )
                                                }
                                              >
                                                <span className="btn btn-success">
                                                  <i className="fa fa-plus-circle" />
                                                  {/* Add */}
                                                </span>
                                              </a>
                                            </td>
                                          </tr>
                                        }
                                      </tbody>
                                    </table>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div id="tab-2" className="tab-pane">
                              <div className="full-height-scroll">
                                <div className="table-responsive">
                                  {subMenu.length === 0 ? (
                                    <div
                                    style={{ marginTop: 35, fontWeight: 'bold'}}
                                      className="alert alert-warning text-center"
                                    >
                                      {appLabel.this_menu_has_no_sub_menu}
                                    </div>
                                  ) : (
                                    <table className="table table-striped table-hover">
                                      <tbody>
                                        {subMenu.map((submenu, index) => (
                                          <tr key={index}>
                                            <td>
                                              {index + 1}{" "}
                                              <label
                                                style={{ cursor: "pointer" }}
                                              >
                                                &nbsp; {submenu.DESCRIPTION}
                                              </label>
                                            </td>
                                            {
                                              <td>
                                                {appStatic.subActionList.map(
                                                  (item, index) => (
                                                    <label
                                                      key={index}
                                                      className="al-subtitle"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      &nbsp; &nbsp;
                                                      <input
                                                        type="checkbox"
                                                        id={`${
                                                          item.name +
                                                          submenu.FUNCTION_ID
                                                        }`}
                                                      />
                                                      &nbsp;{" "}
                                                      {BOM.Uwfirst(item.name)}
                                                    </label>
                                                  )
                                                )}
                                              </td>
                                            }
                                          </tr>
                                        ))}
                                        {
                                          <tr>
                                            <td>
                                              <a
                                                onClick={() =>
                                                  onAddBtn(subMenu)
                                                }
                                              >
                                                <span className="btn btn-success">
                                                  <i className="fa fa-plus-circle" />
                                                  {/* Add */}
                                                </span>
                                              </a>
                                            </td>
                                          </tr>
                                        }
                                      </tbody>
                                    </table>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div
                        style={{ marginTop: 35, fontWeight: 'bold'}}
                          className="alert alert-warning text-center"
                        >
                          {appLabel.You_are_about_to_add_all_config}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <RecordStatus {...BOM.NewRecordStatusdata()} />
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(NewMenu);