import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import appLabel from "../../../config/appLabel";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import { apis as api } from "../../../services/api.action";
import {
  handleSkeletonFac,
  AmntFormatFac,
} from "../../../components/layouts/Facility";

//dash v2
import { Box, Alert, AlertTitle, Typography, Grid } from "@mui/material";
import PieChart from "./PieChart";
import {
  Share,
  CheckCircleOutline,
  ThumbDownOffAltOutlined,
  WatchLaterOutlined,
  FormatQuote,
} from "@mui/icons-material";
import StatBox from "./StatBox";
import ProgressCircle from "./ProgressCircle";
// import { GiMoneyStack } from "react-icons";
import { GiTakeMyMoney } from "react-icons/gi";

const DashboardLayout = (props) => {
  const {
    userData,
    menuData,
    location,
    history,

    dashboardData,
    totalFunded,
    isLoading,
    mockPieData,
    isPo,
    onSelectTile,
  } = props;

  useEffect(() => {}, []);

  return (
    <>
      {isLoading ? (
        handleSkeletonFac()
      ) : dashboardData.length === 0 ? (
        <Box>
          <Alert severity="info" variant="outlined" sx={{ fontSize: "20px" }}>
            {appLabel.noDataAvailableMsg} &nbsp; <i onClick={()=>BOM.ReloadComponent()} className="fa fa-refresh" style={{cursor:"pointer"}}/>
          </Alert>
        </Box>
      ) : (
        <Grid container spacing={1} p={3}>
          {dashboardData.map((item, index) => (
            <Grid item m={1} xs={12} md={item.gridColumn} key={index}>
              <Box
                backgroundColor={"#ffffff"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                borderRadius={"15px"}
                boxShadow="3"
                height={130}
                sx={{ cursor: "pointer", padding: "10px 0" }}
                onClick={() => onSelectTile(item)}
              >
                <StatBox
                  title={item.value}
                  subtitle={`${item.itemType} ${item.name}`}
                  progress={parseInt(item.value) / 100}
                  icon={item.icon}
                />
              </Box>
            </Grid>
          ))}

          {/* ROW 2 */}
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              backgroundColor:"#fff",
              boxShadow:"3",
              borderRadius:"15px",
              marginTop:"20px"
            }}
          >
            
            <Box height={"300px"} mt={"-20px"}>
              <PieChart isDashboard={true} dataChart={mockPieData} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={3.5}
            sx={{
              backgroundColor:"#fff",
              boxShadow:"3",
              borderRadius:"15px",
              margin:"20px 5px",
              overflow:"auto",
            }}
          >
            <Box
            // gridColumn={"span 4"}
            // backgroundColor={"#ffffff"}
            // gridRow={"span 2"}
            // borderRadius={"15px"}
            p={"20px"}
            // boxShadow="3"
            // overflow={"auto"}
          >
            
            <Typography variant="h5" fontWeight={"600"}>
              {`Total ${
                isPo ? appLabel.poShort : appLabel.invoice
              } Funded Amount`}
            </Typography>
            {totalFunded.map((item, index) => (
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                mt={"25px"}
                key={index}
              >
                <GiTakeMyMoney size={"3em"} className="text-navy" />
                <Typography variant="h5" color={"#4cceac"} sx={{ mt: "15px" }}>
                  {/* <AmntFormatFac
                    inputValue={item.TOTAL_FUNDED}
                    currency={item.CCY}
                  /> */}
                  {`${item.CCY} ${BOM.formatNumber(
                    parseFloat(item.TOTAL_FUNDED)
                  )}`}
                </Typography>
                <Typography color={"#333333"} fontWeight="bold">
                  {appLabel.fundsGenerated}
                </Typography>
              </Box>
            ))}

          </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DashboardLayout;
