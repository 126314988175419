import React, { useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../services/api.action";
import { connect } from "react-redux";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import history from "../../history";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../ui-setup/RecordStatus";
import appStatic from "../../config/appStaticData";
import RightSide from "../ui-setup/RightSide";
import AppModal from "../layouts/AppModal";
import NumberFormat from "react-number-format";
import { AmountFacility, Tooltips, ColBlockFac } from "../layouts/Facility";
import Select from "react-select";

export const NewOrder = (props) => {
  const { location, userData, configData } = props;
  const { state } = location;
  const { profileId, session, companyId } = userData;
  const { isCsd, depository } = configData;
  const { toolTip } = appStatic;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [companyData, setCompanyData] = useState([]);
  const [isFullPayment, setIsFullPayment] = useState(false);
  const [isCsdCheck, setIsCsdCheck] = useState(false);
  const [tempFile, setTempFile] = useState(false);
  const [docCategory, setDocCategory] = useState("");
  const [documents, setDocuments] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [buyerCo, setBuyerCo] = useState([]);
  const [supplierCo, setSupplierCo] = useState([]);
  const [show, setShow] = useState(false);
  const [materials, setMaterialData] = useState([]);
  const [manufacturer, setManufacturerData] = useState([]);
  const [shippingData, setShippingData] = useState({});
  const [billingData, setBillingData] = useState({});
  const [primaryData, setPrimaryData] = useState({});
  const [companyDetail, setCompanyDetail] = useState({});
  const [bankData, setBankData] = useState([]);
  const [bankDetail, setBankDetail] = useState({});
  const [isBankChose, setIsBankChose] = useState(false);

  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [currency, setCurrency] = useState("NGN");
  const [manCode, setManCode] = useState("");
  const [category, setCategory] = useState("");
  const [lomCode, setLomCode] = useState("");
  const [isPO, setIsPO] = useState(false);
  const [amount, setAmount] = useState(undefined);
  const [isRequiredField, setIsRequiredField] = useState(true);
  const [isUnitPriceReqField, setIsUnitPriceReqField] = useState(true);
  const currentDate = BOM.CurrentDate();

  useEffect(() => {
    if (isCsd.toLowerCase() === "y") {
      setIsCsdCheck(true);
    }
    setCompanyDetail({}); //used for shipping & billing address
    setBankDetail({});
    getCompany();
    getCurrency();
    setValue("registrar", depository);
    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);
  }, []);

  const onChoosePaymentOption = (type) => {
    // console.log(type);
    switch (type.toLowerCase()) {
      case "instalment":
        setIsFullPayment(true);
        break;
      default:
        setIsFullPayment(false);
        break;
    }
  };
  function getCompany() {
    let body = {
      profileId,
      companyId: "",
      criteria: "A",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setCompanyData(res.companyListing);

        /** GET COMPANIES BASED TYPE */
        let tempCo = res.companyListing.filter(
          (item) => item.COMPANY_TYPE_ID === "CT2022012400004"
        );
        let tempBuyerCo = res.companyListing.filter(
          (item) => item.COMPANY_TYPE_ID === "CT2022012400001"
        );
        let tempSupplieCo = res.companyListing.filter(
          (item) => item.COMPANY_TYPE_ID === "CT2022012400002"
        );
        setManufacturerData(tempCo);
        // setBuyerCo(tempBuyerCo);
        // setSupplierCo(tempSupplieCo);

        let tempBuyer = [];
        tempBuyerCo.forEach((element) => {
          tempBuyer.push({
            value: element.COMPANY_ID,
            label: element.COMPANY_NAME,
          });
        });
        setBuyerCo(tempBuyer);

        let tempSupplier = [];
        tempSupplieCo.forEach((element) => {
          tempSupplier.push({
            value: element.COMPANY_ID,
            label: element.COMPANY_NAME,
          });
        });
        setSupplierCo(tempSupplier);
      }
    });
  }
  function getCurrency() {
    /** FETCH MENU */
    let body = {
      profileId,
      // criteria: "A",
      // currencyId: "",
      session,
    };
    BOM.FetchReqAction(body, api.GetConfCurrency, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setCurrencyData(res.currencyListing);
      }
    });
  }
  const handleFileRead = async (event, setState_param) => {
    const file = event.target.files[0];
    if (!file) {
      // if file is empty
      setState_param("");
      return;
    }

    if (file.size > 5242880) {
      BOM.AlertMsg(cnt.WARNING, "File size cannot exceed 5 MB");
      $(".documentInput").val("");
      return;
    }

    switch (file.type.toLowerCase()) {
      case "application/pdf":
        const base64 = await BOM.convertBase64(file);
        let strB64 = base64.split("base64,")[1]; //.replace(/^data:image\/[a-z]+;base64,/, "");
        setState_param(strB64);
        break;

      default:
        BOM.AlertMsg(cnt.WARNING, "Document must be a PDF.");
        $(".documentInput").val("");
        break;
    }

    // console.log(file.type);
  };

  const uploadFile = () => {
    // console.log(tempFile);

    const itemId = documents.find(
      (item, index) => item.docCategory === docCategory
    );

    if (itemId) {
      BOM.AlertMsg(cnt.WARNING, "No duplicate file allowed!");
      return;
    }

    if (!tempFile || !docCategory) {
      BOM.AlertMsg(cnt.WARNING, "Fields with * are required");
      return;
    }

    setDocuments([
      ...documents,
      {
        base64: tempFile,
        docType: "purchaseorder",
        docCategory,
      },
    ]);
    $(".documentInput").val("");
  };
  const removeItem = (index, type) => {
    switch (type.toLowerCase()) {
      case "document":
        setDocuments([
          ...documents.slice(0, index),
          ...documents.slice(index + 1),
        ]);
        break;
      case "material":
        setMaterialData([
          ...materials.slice(0, index),
          ...materials.slice(index + 1),
        ]);
        break;
    }
  };
  const showModal = () => {
    console.log("set show");
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };
  const onAddMaterial = () => {
    if (!description || !unitPrice || !quantity || !currency) {
      BOM.AlertMsg(cnt.WARNING, "All material field with * is required.");
      return;
    }

    if (manCode) {
      let companyName = companyData.find(
        (item) => item.COMPANY_ID === manCode
      ).COMPANY_NAME;
    }
    setMaterialData([
      ...materials,
      {
        type: "",
        description,
        unitPrice,
        quantity,
        currency,
        manCode,
        category: "",
      },
    ]);

    $(".clearInput").val("");
    //Clear Input
    setManCode("");
    setType("");
    setDescription("");
    setUnitPrice("");
    setQuantity("");
    setCategory("");
  };
  const onChooseCompany = (companyId) => {
    // let specCo = companyData.find((item, index)=> item.COMPANY_ID === coId)
    if (!companyId) {
      setCompanyDetail({});
      setIsPO(false);
      return;
    }
    // console.log(companyId);
    // BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      companyId,
      session,
    };

    /** FETCH */
    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let companyDetail = res.companyDetail;
        setCompanyDetail(companyDetail);
        setPrimaryData(companyDetail.CONTACT_DATA);
        setShippingData(companyDetail.SHIP_DATA);
        setBillingData(companyDetail.BILL_DATA);
        setIsPO(true);
      }
    });
  };
  const onChooseSuppCo = (companyId) => {
    // let specCo = companyData.find((item, index)=> item.COMPANY_ID === coId)
    if (!companyId) {
      setIsBankChose(false);
      setCompanyDetail({});
      return;
    }
    // console.log(companyId);
    // BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      companyId,
      session,
    };

    /** FETCH */
    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let companyDetail = res.companyDetail;
        setBankData(companyDetail.BANK_DATA);
        setIsBankChose(true);
      }
    });
  };
  const onChooseBank = (bankId) => {
    if (!bankId) {
      setCompanyDetail({});
      return;
    }
    let specBank = bankData.find((item, index) => item.BANK_ID === bankId);
    setBankDetail(specBank);
  };
  const formSubmit = (formValues) => {
    if (!amount || !formValues.fromCompany || !formValues.toCompany) {
      BOM.AlertMsg(cnt.WARNING, "All fields with * are required.");
      return;
    }

    if (materials.length === 0) {
      BOM.AlertMsg(cnt.WARNING, "Material is required.");
      return;
    }

    // if (documents.length === 0) {
    //   BOM.AlertMsg(cnt.WARNING, "Document upload is required.");
    //   return;
    // }

    /** BUILD ORDER BODY */
    formValues.documents = documents;
    formValues.amount = amount + "";
    let body = {
      profileId,
      companyId,
      session,
      ...formValues,
    };

    /** BUILD MATERIAL BODY */
    let body2 = {
      profileId,
      // lomCode,
      materials,
    };

    // console.log(body);
    // console.log(body2);
    // return
    /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendPOReqAction(
      body,
      api.CreatePo,
      body2,
      api.CreateMaterial,
      "/manage-order"
    );
  };

  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <RightSide />
        <ContentTop pageTitle={appLabel.newPoOrder} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{appLabel.orderInfo}</h5>
                  <div className="ibox-tools">
                    <Link to="/manage-order" className="btn btn-primary btn-xs">
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <ColBlockFac colNo={12} topTitle={appLabel.generalInfo}>
                      <div className="col-sm-6">
                        <div className="row form-group">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.from} &nbsp;
                              <Tooltips title={toolTip.from} />
                              <span className="asterisks">*</span>
                            </label>
                          </div>
                          <div className="col-md-7">
                            <Select
                              className="basic-single "
                              classNamePrefix="select"
                              options={buyerCo}
                              onChange={(e) => {
                                onChooseCompany(e.value);
                                setValue("fromCompany", e.value);
                              }}
                            />
                          </div>
                        </div>

                        <div className="row form-group">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.to}&nbsp;
                              <Tooltips title={toolTip.to} />
                              <span className="asterisks">*</span>
                            </label>
                          </div>
                          <div className="col-md-7">
                            {/* <select
                              className="form-control input-sm"
                              {...register("toCompany", {
                                onChange: (e) => onChooseSuppCo(e.target.value),
                                required: "Field is required.",
                              })}
                            >
                              <option value="">{appLabel.choose}</option>
                              {supplierCo.map((item, index) => (
                                <option value={item.COMPANY_ID} key={index}>
                                  {item.COMPANY_NAME}
                                </option>
                              ))}
                            </select>
                            <span className="asterisks">
                              {errors?.toCompany?.message}
                            </span> */}
                            <Select
                              className="basic-single "
                              classNamePrefix="select"
                              options={supplierCo}
                              onChange={(e) => {
                                onChooseSuppCo(e.value);
                                setValue("toCompany", e.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="row form-group">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.pymtOption}&nbsp;
                              <Tooltips title={toolTip.pymtOption} />
                              <span className="asterisks">*</span>
                            </label>
                          </div>
                          <div className="col-md-7">
                            <select
                              className="form-control input-sm"
                              {...register("paymentOption", {
                                onChange: (e) =>
                                  onChoosePaymentOption(e.target.value),
                                required: "Field is required.",
                              })}
                            >
                              <option value="">{appLabel.choose}</option>
                              {appStatic.paymentOptionData.map(
                                (item, index) => (
                                  <option value={item.name} key={index}>
                                    {item.name}
                                  </option>
                                )
                              )}
                            </select>
                            <span className="asterisks">
                              {errors?.paymentOption?.message}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div
                          className={`row form-group ${
                            isBankChose ? "" : "hide"
                          }`}
                        >
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.bankName}&nbsp;
                              <Tooltips title={toolTip.bankName} />
                              <span className="asterisks">*</span>
                            </label>
                          </div>
                          <div className="col-md-7">
                            <select
                              className="form-control input-sm"
                              {...register("bankId", {
                                onChange: (e) => onChooseBank(e.target.value),
                                required: isBankChose
                                  ? "Field is required."
                                  : false,
                              })}
                            >
                              <option value="">{appLabel.choose}</option>
                              {bankData.map((item, index) => (
                                <option value={item.BANK_ID} key={index}>
                                  {item.BANK_NAME}
                                </option>
                              ))}
                            </select>
                            <span className="asterisks">
                              {errors?.bankId?.message}
                            </span>
                          </div>
                        </div>
                        <div
                          className={`row form-group ${
                            isFullPayment ? "" : "hide"
                          }`}
                        >
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.frequency}{" "}
                              <Tooltips title={toolTip.frequency} />
                              <span className="asterisks">*</span>
                            </label>
                          </div>
                          <div className="col-md-7">
                            <select
                              className="form-control input-sm"
                              {...register("installmentFrequency", {
                                required: isFullPayment
                                  ? "Field is required."
                                  : false,
                              })}
                            >
                              <option value="">{appLabel.choose}</option>
                              {appStatic.installementData.map((item, index) => (
                                <option value={item.name} key={index}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            <span className="asterisks">
                              {errors?.installmentFrequency?.message}
                            </span>
                          </div>
                        </div>
                        <div className="row form-group">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.currency}&nbsp;
                              <Tooltips title={toolTip.currency} />
                              <span className="asterisks">*</span>
                            </label>
                          </div>
                          <div className="col-md-7">
                            <select
                              className="form-control input-sm"
                              {...register("currency", {
                                onChange: (e) => setCurrency(e.target.value),
                                required: "Field is required.",
                              })}
                            >
                              <option value="">{appLabel.choose}</option>
                              {currencyData.map((item, index) => (
                                <option value={item.CCY_CODE} key={index}>
                                  {item.CCY_CODE}
                                </option>
                              ))}
                            </select>
                            <span className="asterisks">
                              {errors?.currency?.message}
                            </span>
                          </div>
                        </div>
                        
                      <div className="row form-group">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.poAmount}&nbsp;
                            <Tooltips title={toolTip.poAmount} />
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <AmountFacility
                            setIsRequiredField={setIsRequiredField}
                            setAmount={setAmount}
                            isRequiredField={isRequiredField}
                          />
                        </div>
                      </div>
                      </div>
                    </ColBlockFac>
                    <ColBlockFac>
                    <div className="col-sm-6">
                      <div className="row form-group">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.firstPayDue}{" "}
                            <Tooltips title={toolTip.firstPayDue} />
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="date"
                            max={`${currentDate}`}
                            className="form-control input-sm"
                            {...register("firstPayDue", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.firstPayDue?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.lastPayDue}{" "}
                            <Tooltips title={toolTip.lastPayDue} />
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="date"
                            className="form-control input-sm"
                            min={`${currentDate}`}
                            {...register("lastPayDue", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.lastPayDue?.message}
                          </span>
                        </div>
                      </div>
                      </div>
                    <div className="col-sm-6">

                      <div className="row form-group">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.registrar}&nbsp;
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.registrar}
                            {...register("registrar")}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.lpoRefId}&nbsp;
                            <Tooltips title={toolTip.lpoRefId} />
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.lpoRefId}
                            {...register("lpoRefId", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.lpoRefId?.message}
                          </span>
                        </div>
                      </div>
                      </div>
                    </ColBlockFac>
                  </div>

                  <div
                    className={`row ${isPO ? "" : "hide"}`}
                    style={{ marginTop: "15px" }}
                  >
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.addressInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      {Object.keys(companyDetail).length !== 0 && (
                        <div>
                          <div className="col-md-4">
                            <div className="well">
                              <address>
                                <h4>
                                  {" "}
                                  <i className="fa fa-building text-navy"></i>
                                  &nbsp; {appLabel.shippingAddress}
                                </h4>{" "}
                                <br />
                                <i className="fa fa-circle text-navy"></i>{" "}
                                &nbsp; {shippingData.SHIPPING_ADDRESS_1},{" "}
                                {shippingData.SHIPPING_CITY_NAME}
                                <br />
                                {shippingData.SHIPPING_PROVINCE_NAME},{" "}
                                {shippingData.SHIPPING_COUNTRY_NAME}{" "}
                                {shippingData.SHIPPING_POST_CODE}
                                <br />
                                <span title="Phone">
                                  <i className="fa fa-circle text-navy"></i>{" "}
                                  &nbsp; Phone :
                                </span>{" "}
                                <strong>{primaryData.OFFICE_PHONE}</strong>
                              </address>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="well">
                              <address>
                                <h4>
                                  {" "}
                                  <i className="fa fa-building text-navy"></i>
                                  &nbsp;{appLabel.billingAddress}
                                </h4>{" "}
                                <i className="fa fa-circle text-navy"></i>&nbsp;
                                {billingData.BILLING_ADDRESS_1},{" "}
                                {billingData.BILLING_CITY_NAME}
                                <br />
                                {billingData.BILLING_PROVINCE_NAME},{" "}
                                {billingData.BILLING_COUNTRY_NAME}{" "}
                                {billingData.BILLING_POST_CODE}
                                <br />
                                <span title="Phone">
                                  <i className="fa fa-circle text-navy"></i>{" "}
                                  &nbsp; Phone:
                                </span>{" "}
                                <strong>{primaryData.OFFICE_PHONE}</strong>
                              </address>
                            </div>
                          </div>
                        </div>
                      )}
                      {Object.keys(bankDetail).length !== 0 && (
                        <div className="col-md-4">
                          <div className="well">
                            <address>
                              <h4>
                                {" "}
                                <i className="fa fa-bank text-navy"></i>
                                &nbsp;{appLabel.bankInformation}
                              </h4>{" "}
                              <br />
                              <span title="Bank Name">
                                <i className="fa fa-circle text-navy"></i>{" "}
                                &nbsp; Bank Name :
                              </span>{" "}
                              <strong>{bankDetail.BANK_NAME}</strong> <br />
                              <span title="Account Name">
                                <i className="fa fa-circle text-navy"></i>{" "}
                                &nbsp; Account Name:{" "}
                              </span>{" "}
                              <strong>{bankDetail.ACC_NAME}</strong> <br />
                              <span title="Account Number">
                                <i className="fa fa-circle text-navy"></i>{" "}
                                &nbsp; Account Number:{" "}
                              </span>{" "}
                              <strong>{bankDetail.ACC_NUM}</strong>
                              <br />
                              <span title="Branch Code">
                                <i className="fa fa-circle text-navy"></i>{" "}
                                &nbsp; Branch Code:{" "}
                              </span>{" "}
                              <strong>{bankDetail.BRANCH_CODE}</strong>
                              <br />
                              <span title="NUBAN">
                                <i className="fa fa-circle text-navy"></i>{" "}
                                &nbsp; NUBAN:{" "}
                              </span>{" "}
                              <strong>{bankDetail.NUBAN}</strong>
                            </address>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.otherInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <ul className="nav nav-tabs">
                        <li className="active">
                          <a
                            data-toggle="tab"
                            href="#materialInfo"
                            target="_self"
                          >
                            <i className="fa fa-tag text-navy" />
                            &nbsp;{appLabel.materialInfo}&nbsp;
                            <Tooltips title={toolTip.materialInfo} />
                          </a>
                        </li>
                        <li>
                          <a
                            data-toggle="tab"
                            href="#documentInfo"
                            target="_self"
                          >
                            <i className="fa fa-file-pdf-o text-navy" />
                            &nbsp;{appLabel.documentInfo}&nbsp;
                            <Tooltips title={toolTip.documentInfo} />
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="materialInfo">
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-12">
                              <div className="row">
                                <AppModal
                                  show={show}
                                  title={"Add Multiple"}
                                  handleClose={() => hideModal()}
                                  handleSave={() => onAddMaterial()}
                                >
                                  <div className=" form-group col-md-6">
                                    <label className="al-subtitle">
                                      {appLabel.description}&nbsp;
                                      <Tooltips title={toolTip.materiaDesc} />
                                      <span className="asterisks">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control input-sm clearInput"
                                      placeholder={appLabel.description}
                                      onChange={(e) =>
                                        setDescription(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label className="al-subtitle">
                                      {appLabel.unitPrice}&nbsp;
                                      <Tooltips
                                        title={toolTip.materialUnitPrice}
                                      />
                                      <span className="asterisks">*</span>
                                    </label>
                                    <AmountFacility
                                      setIsRequiredField={
                                        setIsUnitPriceReqField
                                      }
                                      setAmount={setUnitPrice}
                                      isRequiredField={isUnitPriceReqField}
                                    />
                                  </div>
                                  <div className=" form-group col-md-6">
                                    <label className="al-subtitle">
                                      {appLabel.quantity}&nbsp;
                                      <Tooltips title={toolTip.materialQty} />
                                      <span className="asterisks">*</span>
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control input-sm clearInput"
                                      placeholder={appLabel.quantity}
                                      onChange={(e) =>
                                        setQuantity(e.target.value)
                                      }
                                    />
                                  </div>
                                  {/* <div className=" form-group col-md-6">
                                    <label className="al-subtitle">
                                      {appLabel.manufacturer}&nbsp;
                                    </label>
                                    <select
                                      className="form-control input-sm clearInput"
                                      onChange={(e) =>
                                        setManCode(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {manufacturer.map((item, index) => (
                                        <option
                                          value={item.COMPANY_ID}
                                          key={index}
                                        >
                                          {item.COMPANY_NAME}
                                        </option>
                                      ))}
                                    </select>
                                  </div> */}
                                </AppModal>

                                {!show && (
                                  <div className="col-sm-2">
                                    <div className="title-action">
                                      <button
                                        type="button"
                                        title="Add more Materials"
                                        className="btn btn-primary btn-sm"
                                        onClick={() => showModal()}
                                      >
                                        <i className="fa fa-plus" />
                                      </button>
                                      <div
                                        className="hr-line"
                                        style={{ marginBottom: "10px" }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="table-responsive">
                                {materials.length === 0 ? (
                                  <div className="alert alert-warning text-center">
                                    <a className="alert-link">
                                      {appLabel.noDataInTableMsg}
                                    </a>
                                  </div>
                                ) : (
                                  <div>
                                    <div className="col-sm-12">
                                      <label className="al-heading">
                                        <i className="fa fa-circle-o text-navy"></i>
                                        &nbsp; {appLabel.generalInfo}
                                      </label>
                                      <div
                                        className="hr-line-dashed"
                                        style={{ marginTop: 0 }}
                                      />
                                      {/* <div className="row form-group col-sm-10">
                                        <div className="col-md-4 text-left">
                                          <label className="al-subtitle">
                                            {appLabel.lomCode}{" "}
                                            <span className="asterisks">*</span>
                                          </label>
                                        </div>
                                        <div className="col-md-7">
                                          <input
                                            type="text"
                                            className="form-control input-sm"
                                            placeholder={appLabel.lomCode}
                                            onChange={(e) =>
                                              setLomCode(e.target.value)
                                            }
                                          />
                                        </div>
                                      </div> */}
                                    </div>

                                    <table
                                      className="footable table table-stripped toggle-arrow-tiny"
                                      data-page-size="10"
                                      data-filter="#filter"
                                    >
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>{appLabel.description}</th>
                                          <th>{appLabel.unitPrice}</th>
                                          <th>{appLabel.quantity}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {materials.map((item, index) => (
                                          <tr className="gradeX" key={index}>
                                            <td>
                                              {index + 1}&nbsp;{" "}
                                              <i
                                                style={{ cursor: "pointer" }}
                                                className="fa fa-times-circle text-danger fa-2x"
                                                onClick={() =>
                                                  removeItem(index, "material")
                                                }
                                              />
                                            </td>
                                            <td>{item.description}</td>
                                            <td>
                                              <NumberFormat
                                                value={item.unitPrice}
                                                displayType="text"
                                                thousandSeparator={true}
                                                prefix={item.currency + " "}
                                              />
                                            </td>
                                            <td>{item.quantity}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td colSpan="7">
                                            <ul className="pagination pull-right"></ul>
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="documentInfo">
                          <div className="row" style={{ marginTop: "30px" }}>
                            <div className="col-sm-12">
                              <p className="text-danger">
                                {appLabel.maxFileUploadMsg}
                              </p>
                            </div>
                            <div className="col-sm-12">
                              <div className="col-md-6">
                                <div className="row form-group has-errorr">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle control-label">
                                      {appLabel.documentType}{" "}
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <select
                                      className="form-control input-sm documentInput"
                                      onChange={(e) =>
                                        setDocCategory(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {appStatic.documntTypesPo.map(
                                        (item, index) => (
                                          <option value={item.id} key={index}>
                                            {item.name}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="row form-group">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle ">
                                      {appLabel.documentUpload}{" "}
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <input
                                      type="file"
                                      className="form-control input-sm documentInput"
                                      accept=".pdf"
                                      onChange={(e) =>
                                        handleFileRead(e, setTempFile)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row form-group">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle ">
                                      &nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <button
                                      type="button"
                                      onClick={() => uploadFile()}
                                      className="btn btn-primary btn-xs"
                                    >
                                      <i className="fa fa-upload" />
                                      &nbsp; Upload
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="col-sm-4 al-subtitle"></label>
                                  <div className="col-sm-8">
                                    {documents.map((item, index) => (
                                      <div className="i-checks" key={index}>
                                        <label className="control-label">
                                          <i
                                            style={{ cursor: "pointer" }}
                                            className="fa fa-times-circle text-danger fa-2x"
                                            onClick={() =>
                                              removeItem(index, "document")
                                            }
                                          />
                                          &nbsp; &nbsp;
                                          <i
                                            className="fa fa-file-pdf-o fa-2x"
                                            style={{ color: "#d50000" }}
                                          />
                                          &nbsp; {item.docCategory}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <RecordStatus {...userDetail} /> */}
        </div>
      </form>
      <RecordStatus {...BOM.NewRecordStatusdata()} />
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  configData: state.auth.configData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewOrder);
