import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import appLabel from "../../../config/appLabel";
import { BOM } from "../../../services/defined.action";
import RightSide from "../../ui-setup/RightSide";
import RecordStatus from "../../ui-setup/RecordStatus";
import { apis as api } from "../../../services/api.action";
import { cnt } from "../../../services/constant.action";
import Notiflix from "notiflix";

export const EntityMenuDetail = (props) => {
  const { location, history, userData} = props;
  const { state } = location
  const { profileId, session, companyTypeId } = userData;
  const [roleMenuData, setRoleMenuData] = useState([]);
  const [mainNameposition, setMainNameposition] = useState("");
  const [mainName, setMainName] = useState("");
  const [mainId, setMainId] = useState("");

  const [subMenu2, setSubMenu2] = useState([]);
  const [parentName, setParentName] = useState("");
  const [parentId, setParentId] = useState("");

  const [readButton, setReadButton] = useState("");
  const [thirdLevel, setThirdLevel] = useState("");

  useEffect(() => {
    // console.log(props);

    if (!state.ggParam) {
      history.push("/manage-entity-menu");
      return;
    }
    getEntityMenu(state.ggParam.COMPANY_TYPE_ID)
    setReadButton("Read More");
  }, []);

  function getEntityMenu(entityTypeId) {

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      entityTypeId,
      criteria: "A",
      functionId:"",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetEntityMenu, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        // console.log(res);
        var roleMenu = [];
        roleMenu = res.functionData;

        // console.log(roleMenu);
        setRoleMenuData(roleMenu);

        if (roleMenu.length !== 0) {
          setMainNameposition(roleMenu[0].DESCRIPTION.toLowerCase());
          setMainName(roleMenu[0].DESCRIPTION);
          setMainId(roleMenu[0].FUNCTION_ID);
        }

        // /** LOOP MENU AND DELETE ALL PARENT MENU WITH NO SUBMENU */
        roleMenu.forEach((element) => {
          if (element.SUB_MENU.length === 0) {
            // localDeleteParentMenu(element.FUNCTION_ID)
          }
        });

      }
    });
  }

  const selectedFunction = (parentData) => {
    setSubMenu2(parentData.SUB_MENU);
    setParentName(parentData.DESCRIPTION);
    setParentId(parentData.FUNCTION_ID);

    $(document).ready(function () {
      $(".footable").footable();
    });
  };

  const viewThirdLevel = (param1, param2) => {
    console.log(param1);
    console.log(param2);
    if (param2 === true) {
      setReadButton(param1);
      setThirdLevel(param1);
    } else {
      setReadButton("");
      setThirdLevel("");
    }
  };

  const RemoveLogic = (
    functionId,
    functionType,
    paramFuncName
  ) =>{

    // console.log("remove");
    // confirm
    Notiflix.Confirm.show(
      "Remove " + paramFuncName + " Menu",
      "Are you sure?",
      "Yes",
      "No",
      function () {
        // Yes
        BOM.LoadAlert(cnt.LOAD, "Processing");
        let bodyRemove = {
          profileId,
          entityTypeId: state.ggParam.COMPANY_TYPE_ID,
          functionId,
          functionType,
          session
        };
        // console.log("bodyRemove ", bodyRemove);

        /** SEND REQ */
        BOM.SendReqAction(
          bodyRemove,
          api.DeleteEntityMenuId,
          "manage-entity-menu",
        );
      },
      function () {
        // No button
      }
    );

  };

  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        // onSubmit={handleSubmit(formSubmit)}
      >
        <RightSide />

        <ContentTop pageTitle={appLabel.entityMenuDetail} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-sm-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>
                    {state.ggParam && state.ggParam.COMPANY_TYPE_NAME.length <= 25
                      ? state.ggParam.COMPANY_TYPE_NAME
                      : state.ggParam.COMPANY_TYPE_NAME + "..."}
                  </h5>
                  <div className="ibox-tools">
                    <Link to={`manage-entity-menu`}
                      className="btn btn-primary btn-xs"
                    >
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                    {roleMenuData.length !== 0 && (
                      <Link
                        to={{
                          pathname: "/add-to-entity-menu",
                          state:{
                            ggParam:state.ggParam
                          }
                        }}
                      >
                        <span className="btn btn-warning btn-xs">
                          <i className="fa fa-plus-circle" />
                          &nbsp;
                          {appLabel.addToMenu}
                        </span>
                      </Link>
                    )}

                    {roleMenuData.length === 0 && (
                      <Link
                        to={{
                          pathname: "/new-entity-menu",
                          ggParam: location.ggParam,
                        }}
                      >
                        <span className="btn btn-primary btn-xs">
                          <i className="fa fa-plus-circle" />
                          &nbsp;
                          {appLabel.newMenu}
                        </span>
                      </Link>
                    )}
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  {roleMenuData.length === 0 ? (
                    <div
                      style={{ marginTop: 35 }}
                      className="alert alert-warning text-center"
                    >
                      {appLabel.yetToConfigure}
                    </div>
                  ) : (
                    <>
                      <ul className="nav nav-tabs">
                        {roleMenuData.map((item, index) => (
                          <li
                            className={`${
                              item.DESCRIPTION.toLowerCase() ===
                              mainNameposition
                                ? "active"
                                : ""
                            }`}
                            key={index}
                          >
                            <a
                              data-toggle="tab"
                              href="#tab-2"
                              target="_self"
                              onClick={() => selectedFunction(item)}
                            >
                              <i className={`fa fa-${item.ICON} text-navy`} />
                              &nbsp; {item.DESCRIPTION}
                            </a>
                          </li>
                        ))}
                      </ul>
                      <div className="tab-content" >
                        <div id="tab-1" className="tab-pane active">
                          <div className="full-height-scroll">
                            <div className="table-responsive">
                              {roleMenuData.length === 0 ? (
                                <div
                                  style={{ marginTop: 35 }}
                                  className="alert alert-warning text-center"
                                >
                                  {appLabel.this_menu_has_no_sub_menu}
                                </div>
                              ) : (
                                <table className="table table-striped table-hover">
                                  <tbody>
                                    {roleMenuData[0].SUB_MENU && (
                                      <tr>
                                        <td>
                                        <a
                                            onClick={()=>RemoveLogic(mainId, 'parentKey', mainName)}
                                            title={`Remove ${mainName} on the list`}
                                          >
                                            <span className="btn btn-white btn-sm">
                                              <i className="fa fa-times-circle text-danger" />
                                              &nbsp; {mainName}
                                            </span>
                                          </a>
                                        </td>
                                      </tr>
                                    )}

                                    {roleMenuData[0].SUB_MENU.map(
                                      (submenu, index) => (
                                        <tr key={index}>
                                          <td>
                                          <a
                                              data-toggle="tab"
                                              className="client-link"
                                              title={`Remove ${submenu.DESCRIPTION} on the list`}
                                            >
                                              <i
                                                className="fa fa-times-circle text-danger"
                                                onClick={()=>RemoveLogic(submenu.FUNCTION_ID, 'submenuKey', submenu.DESCRIPTION)}
                                              />
                                              &nbsp; {submenu.DESCRIPTION}
                                            </a>
                                          </td>
                                          
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          </div>
                        </div>

                        <div id="tab-2" className="tab-pane">
                          <div className="full-height-scroll">
                            <div className="table-responsive">
                              {subMenu2.length === 0 ? (
                                <div
                                  style={{ marginTop: 35 }}
                                  className="alert alert-warning text-center"
                                >
                                  {appLabel.this_menu_has_no_sub_menu}
                                </div>
                              ) : (
                                <table className="table table-striped table-hover table-sm">
                                  <tbody>
                                    <tr>
                                      <td>
                                      <a
                                          onClick={()=>RemoveLogic(parentId, 'parentKey', parentName)}
                                            title={`Remove ${parentName} on the list`}
                                        >
                                          <span className="btn btn-white btn-sm">
                                            <i className="fa fa-times-circle text-danger" />
                                            &nbsp; {parentName.toUpperCase()}
                                          </span>
                                        </a>
                                      </td>
                                    </tr>

                                    {subMenu2.map((submenu, index) => (
                                      <tr key={index}>
                                        <td>
                                        <a
                                              data-toggle="tab"
                                              className="client-link"
                                              title={`Remove ${submenu.DESCRIPTION} on the list`}
                                            >
                                              <i
                                                className="fa fa-times-circle text-danger"
                                                onClick={()=>RemoveLogic(submenu.FUNCTION_ID, 'submenuKey', submenu.DESCRIPTION)}
                                              />
                                              &nbsp; {submenu.DESCRIPTION}
                                            </a>
                                        </td>
                                        
                                        {/* {submenu.CHILD_SUB_MENU.length !==
                                          0 && (
                                          <td className="client-status">
                                            <label>
                                              <input
                                                type="checkbox"
                                                onClick={(e) =>
                                                  viewThirdLevel(
                                                    submenu.FUNCTION_ID,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                              &nbsp;
                                              <a
                                                onClick={(e) =>
                                                  viewThirdLevel(
                                                    submenu.FUNCTION_ID,
                                                    e.target.value
                                                  )
                                                }
                                                className={`${
                                                  thirdLevel !==
                                                  submenu.FUNCTION_ID
                                                    ? "text-success"
                                                    : "text-warning"
                                                }`}
                                              >
                                                {readButton ===
                                                submenu.FUNCTION_ID
                                                  ? appLabel.readLess
                                                  : appLabel.viewMore}

                                                   {readButton}
                                              </a>
                                            </label>
                                          </td>
                                        )} */}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {/* <RecordStatus {...BOM.NewRecordStatusdata()} /> */}
    </Main>
  );
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(EntityMenuDetail);