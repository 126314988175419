import React, { useEffect, useState } from "react";
import { apis as api } from "../../services/api.action";
import { useForm } from "react-hook-form";
import { connect, useDispatch } from "react-redux";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM, RAL } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import { Link } from "react-router-dom";
import RecordStatus from "../ui-setup/RecordStatus";
import NumberFormat from "react-number-format";
import {
  TooltipsFAke,
  Col12BlockFacility,
  InputTextFacility,
  InputAmountFacility,
NoDataFac
} from "../layouts/Facility";
import RightSide from "../ui-setup/RightSide";
import GetFunderBids from "./bid/GetFunderBids";

export const SupplierIntentInvDetail = (props) => {
  const { location, userData, configData, recordData, menuData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId, companyTypeId } = userData;
  const { isCsd } = configData;
  const { toolTip } = appStatic;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const [isFullPayment, setIsFullPayment] = useState(false);
  const [isCsdCheck, setIsCsdCheck] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [orderDetail, setOrderDetail] = useState({});
  const [materials, setMaterialData] = useState([]);
  const [shippingData, setShippingData] = useState({});
  const [primaryData, setPrimaryData] = useState({});
  const [billingData, setBillingData] = useState({});

  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [contractDetail, setContractDetail] = useState({});
  const [financeDetail, setFinanceDetail] = useState({});
  const [financeHistoryData, setFinanceHistoryData] = useState([]);
  const [isFunded, setIsFunded] = useState(false);
  const [isSupplier, setIsSupplier] = useState(false);
  const [isCustodian, setIsCustodian] = useState(false);
  const [noteInfoData, setNoteInfoData] = useState([]);
  const [inputFldData, setInputFldData] = useState([]);


  useEffect(() => {
    // console.log(state.ggParam);
    //Critical Check

    handleSwitchCondition();
    getDetailById(state.ggParam);
    // BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);
    console.log(ggParam);
    BOM.getSpecDoc(ggParam?.ITEM_ID, setDocuments, userData);
  
  }, []);

  const handleSwitchCondition = () => {
    if (isCsd.toLowerCase() === "y") {
      setIsCsdCheck(true);
    }
    switch (companyTypeId) {
      case "CT2022012400002": // supplier
        setIsSupplier(true);
        // console.log(companyTypeId);
        break;
      case "CT2022012400000": // depository/custodian
        setIsCustodian(true);
        // console.log(companyTypeId);
        break;
    }
  };
  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      itemId: params.ITEM_ID,
      itemType: params.ITEM_TYPE,
      companyId,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetIntent, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {

        const objDetail = res.invoiceDetail;
        const invDetail = objDetail.INVOICE_DATA;
        const taxDetail = objDetail.TAX_DATA;

        setInvoiceDetail(invDetail);
        setValue("invoiceId", invDetail.INV_ID);
        setValue("intentId", params.INTENT_ID);
        setMaterialData(objDetail.MATERIAL_DATA);
        // setShippingData(objDetail.SHIP_DATA);
        // setBillingData(objDetail.BILL_DATA);

        //get document
        // getSpecDoc(state.ggParam);

        //disable Approve right side
        // if (invDetail.FLG_DEPOSITORY_NOTE.toLowerCase() === "y") {
        //   RAL.disableEnableRightAction(["CLEAR_RIGHT_BTN"]);
        //   setNoteInfoData([
        //     { name: appLabel.noteName, value: invDetail.NOTE_NAME },
        //     { name: appLabel.csdNumber, value: invDetail.CSD_NUMBER },
        //     { name: appLabel.noteCode, value: invDetail.NOTE_CODE },
        //   ]);
        // }

        switch (invDetail.FLG_DEPOSITORY_NOTE.toLowerCase()) {
          case "n":
            BOM.IsApproveOrReject("U", menuData, "Supplier Request");
            setNoteInfoData([
              { name: appLabel.noteName, value: invDetail.NOTE_NAME },
              { name: appLabel.csdNumber, value: invDetail.CSD_NUMBER },
              { name: appLabel.noteCode, value: invDetail.NOTE_CODE },
            ]);
            break;
          case "y":
            invDetail.FLG_SUPPLIER_APPROVAL.toLowerCase() === "n" &&
              RAL.disableEnableRightAction(["CLEAR_RIGHT_BTN"]);
            setNoteInfoData([
              { name: appLabel.noteName, value: invDetail.NOTE_NAME },
              { name: appLabel.csdNumber, value: invDetail.CSD_NUMBER },
              { name: appLabel.noteCode, value: invDetail.NOTE_CODE },
            ]);
            break;
          default:
            break;
        }

        // mergedDataArr
        setInputFldData([
          {
            name: appLabel.invoiceId,
            value: invDetail.INV_ID,
            fldType: "text",
            section: "general",
          },
          
          {
            name: appLabel.buyer,
            value: invDetail.BILL_TO_COMPANY,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.currency,
            value: invDetail.INV_CCY,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.supplier,
            value: invDetail.SUPPLIER_COMPANY,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.submissionDate,
            value: invDetail.SUBMISSION_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.invDueDate,
            value: invDetail.INVOICE_DUE_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.invDate,
            value: invDetail.INV_DATE,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.planedPymtDate,
            value: invDetail.PLANNED_PAYMENT_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.originalAmount,
            value: invDetail.ORIGINAL_AMOUNT,
            fldType: "amount",
            section: "payment",
          },
          {
            name: appLabel.finalAmount,
            value: invDetail.FINAL_AMOUNT,
            fldType: "amount",
            section: "general",
          },
          {
            name: appLabel.adjustment,
            value: invDetail.ADJUSTMENT,
            fldType: "amount",
            section: "payment",
          },
          {
            name: appLabel.discountAmount,
            value: invDetail.DISCOUNT_AMOUNT,
            fldType: "amount",
            section: "payment",
          },
          {
            name: appLabel.pymtOption,
            value: invDetail.PAYMENT_METHOD,
            fldType: "text",
            section: "general",
          },
        ]);
      }
    });
  };

  const getSpecDoc = (params) => {
    // console.log(params);
    const type = params.ITEM_TYPE.toLowerCase() === 'po' ? "purchaseorder" : "invoice"
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      id: params.ITEM_ID,
      type,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setDocuments(res.docListing);
      }
    });
  };

  const formSubmit = (formValues) => {
    // console.log("data", formValues);

    let body = {
      profileId,
      companyId,
      session,
      ...formValues,
    };

    // console.log(body);
    // return;
    /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(body, api.UpdateInvNoteInfo, "/manage-supplier-intent");
  };

  const onApprove = () => {
    let body = {
      profileId,
      itemId: state.ggParam.ITEM_ID,
      itemType: state.ggParam.ITEM_TYPE,
      intentId: state.ggParam.INTENT_ID,
      criteria: "A",
      session,
    };

    // console.log('approve: ', body);
    BOM.GlobalApproveRejectAction(
      body,
      api.ApproveRejectInvIntent,
      "manage-supplier-intent",
      state.ggParam.SUPPLIER,
      "Approve "
    );
  };

  const onReject = () => {
    let body = {
      profileId,
      itemId: state.ggParam.ITEM_ID,
      itemType: state.ggParam.ITEM_TYPE,
      intentId: state.ggParam.INTENT_ID,
      criteria: "R",
      session,
    };

    // console.log('reject: ', body);
    BOM.GlobalApproveRejectAction(
      body,
      api.ApproveRejectInvIntent,
      "manage-supplier-intent",
      state.ggParam.SUPPLIER,
      "Reject "
    );
  };

  return (
    <Main {...props} onApprove={onApprove} onReject={onReject} onSaveChange={handleSubmit(formSubmit)}>
      <form
        method="POST"
        autoComplete="off"
      >
        <ContentTop pageTitle={appLabel.intentInvoiceDetail} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{appLabel.invoiceInformation}</h5>
                  <div className="ibox-tools">
                    <Link
                      to="/manage-supplier-intent"
                      className="btn btn-primary btn-xs"
                    >
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                  <Col12BlockFacility topTitle={appLabel.generalInfo}>
                      {inputFldData.map((item, index) => (
                        <div key={index}>
                          {item.section === "general" &&
                            (item.fldType === "amount" ? (
                              <InputAmountFacility
                                inputLabel={item.name}
                                inputValue={item.value}
                              />
                            ) : (
                              <InputTextFacility
                                inputLabel={item.name}
                                inputValue={item.value}
                              />
                            ))}
                        </div>
                      ))}
                      <NoDataFac arrList={inputFldData} />
                    </Col12BlockFacility>
                    <Col12BlockFacility topTitle={appLabel.noteInformation}>
                      {invoiceDetail.FLG_DEPOSITORY_NOTE === "Y" &&
                      invoiceDetail.FLG_SUPPLIER_APPROVAL === "Y" ?(
                        <>
                          <div className="row form-group col-md-6">
                            <div className="col-md-4 text-left">
                              <label className="al-subtitle">
                                {appLabel.noteName}&nbsp;
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                type="text"
                                className="form-control input-sm"
                                placeholder={appLabel.noteName}
                                {...register("csdName", {
                                  required: "Field is required.",
                                  maxLength: {
                                    value: 60,
                                    message: "Maximum length is 60 characters",
                                  },
                                })}
                              />
                              <span className="asterisks">
                                {errors?.csdName?.message}
                              </span>
                            </div>
                          </div>
                          <div className={`row form-group col-md-6`}>
                            <div className="col-md-4 text-left">
                              <label className="al-subtitle">
                                {appLabel.csdNumber}&nbsp;
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                type="text"
                                className="form-control input-sm"
                                placeholder={appLabel.csdNumber}
                                {...register("csdNumber", {
                                  required: "Field is required.",
                                  maxLength: {
                                    value: 12,
                                    message: "Maximum length is 12 characters",
                                  },
                                })}
                              />
                              <span className="asterisks">
                                {errors?.csdNumber?.message}
                              </span>
                            </div>
                          </div>
                          <div className="row form-group col-md-6">
                            <div className="col-md-4 text-left">
                              <label className="al-subtitle">
                                {appLabel.noteCode}&nbsp;
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                type="text"
                                className="form-control input-sm"
                                placeholder={appLabel.noteCode}
                                {...register("csdCode", {
                                  required: "Field is required.",
                                })}
                              />
                              <span className="asterisks">
                                {errors?.csdCode?.message}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        noteInfoData.map((item, index)=>(
                          <InputTextFacility key={index} inputLabel={item.name} inputValue={item.value} />
                        ))
                       
                      )}
                    </Col12BlockFacility>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.otherInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <ul className="nav nav-tabs">
                        <li className="active">
                          <a data-toggle="tab" href="#materialInfo">
                            <i className="fa fa-tag text-navy" />
                            &nbsp;{appLabel.materialInfoShort}&nbsp;
                            <TooltipsFAke title={toolTip.materialInfo} />
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#documentInfo">
                           <i className="fa fa-file-pdf-o text-navy"  />
                            &nbsp;{appLabel.documentInfoShort}&nbsp;
                            <TooltipsFAke title={toolTip.documentInfo} />
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#funderBid">
                            <i className="fa fa-gavel text-navy" />
                            &nbsp;{appLabel.funderBid}&nbsp;
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="materialInfo">
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-12">
                              <div className="table-responsive">
                                {materials.length === 0 ? (
                                  <div className="alert alert-warning text-center">
                                    <a className="alert-link">
                                      {appLabel.noDataInTableMsg}
                                    </a>
                                  </div>
                                ) : (
                                  <table
                                    className="footable table table-stripped toggle-arrow-tiny"
                                    data-page-size="10"
                                    data-filter="#filter"
                                  >
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        {/* <th>{appLabel.manufacturer}</th> */}
                                        <th>{appLabel.description}</th>
                                        {/* <th>{appLabel.type}</th> */}
                                        <th>{appLabel.unitPrice}</th>
                                        <th>{appLabel.quantity}</th>
                                        {/* <th>{appLabel.currency}</th> */}
                                        {/* <th>{appLabel.category}</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {materials.map((item, index) => (
                                        <tr className="gradeX" key={index}>
                                          <td>{index + 1}</td>
                                          {/* <td>{item.MANUFACTURER}</td> */}
                                          <td>{item.MAT_DESC}</td>
                                          {/* <td>{item.MAT_TYPE}</td> */}
                                          <td>
                                            <NumberFormat
                                              value={item.UNIT_PRICE}
                                              displayType="text"
                                              thousandSeparator={true}
                                              prefix={item.PO_CCY + " "}
                                            />
                                          </td>
                                          <td>{item.QUANTITY}</td>
                                          {/* <td>{item.PO_CCY}</td> */}
                                          {/* <td>{item.MAT_CATEGORY}</td> */}
                                        </tr>
                                      ))}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td colSpan="8">
                                          <ul className="pagination pull-right"></ul>
                                        </td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="documentInfo">
                          <div className="row" style={{ marginTop: "30px" }}>
                            <div className="col-sm-12">
                              {documents.length === 0 ? (
                                <div className="alert alert-warning text-center">
                                  <a className="alert-link">
                                    {appLabel.noDocumentMsg}
                                  </a>
                                </div>
                              ) : (
                                <div>
                                  {documents.map((item, index) => (
                                    <div className="col-md-4" key={index}>
                                      <div className="file-box">
                                        <div className="file">
                                          <a
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              BOM.DownloadAction(
                                                item.DOC_OBJECT,
                                                item.DOC_ID +
                                                  " " +
                                                  item.DOC_CATEGORY.toLowerCase()
                                              )
                                            }
                                          >
                                            <span className="corner" />
                                            <div className="icon">
                                             <i className="fa fa-file-pdf-o text-navy"  />
                                            </div>
                                            <div className="file-name">
                                              {item.DOC_CATEGORY}
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="funderBid">
                          <div className="row" style={{ marginTop: "20px" }}>
                             <GetFunderBids itemId={ggParam?.ITEM_ID} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <RecordStatus {...recordData} />
        </div>
      </form>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplierIntentInvDetail);
