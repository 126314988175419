import React, { useEffect } from "react";
import { connect } from "react-redux";
import Main from "../../../layouts/Main";
import appLabel from "../../../../config/appLabel";
import { BOM } from "../../../../services/defined.action";
import RecordStatus from "../../../ui-setup/RecordStatus";
import { apis as api } from "../../../../services/api.action";
import { cnt } from "../../../../services/constant.action";
import RoleForm from "./RoleForm";

export const EditSubRole = (props) => {
  const { location, history, userData } = props;
  const { state } = location;
  const { profileId, session, companyId } = userData;


  useEffect(() => {
    // console.log(props);

    if (!state.ggParam) {
      history.push("/manage-role-menu");
      return;
    }
  }, []);

  const onSubmit = (formValues) => {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    //  /**MAKE ADJUSTMENT FOR SOME DATA*/
    formValues.roleName = BOM.Uwfirst(formValues.roleName);
    formValues.roleDescription = "0";

    let body = {
      profileId,
      session,
      entityId:companyId,
      roleId: state.ggParam.ROLE_ID,
      ...formValues,
    };

    // console.log(body);
    /** SEND REQ */
    BOM.SendReqAction(
      body,
      api.UpdateRole,
      "/manage-role-menu"
    );

  };
  return (
    <Main {...props}>
      <RoleForm
        onSubmit={onSubmit}
        {...{
          pageTitle: appLabel.editRole,
          action: "edit",
          roleObj: state.ggParam,
        }}
      />
      <RecordStatus {...BOM.NewRecordStatusdata()} />
    </Main>
  );
};


const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(EditSubRole);
