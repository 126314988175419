import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "./Dashboard.css";
import Main from "../../components/layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import { cnt } from "../../services/constant.action";
import { BOM, RAL } from "../../services/defined.action";
import NumberFormat from "react-number-format";
import { apis as api } from "../../services/api.action";
import { handleSkeletonFac } from "../../components/layouts/Facility";

export const Dashboard = (props) => {
  return (
    <Main {...props}>
      {/* <MainRender /> */}

      <div className="m-t" style={{ marginTop: "5%" }}>
        <PoRender {...props} />
        <InvoiceRender {...props} />
      </div>
    </Main>
  );
};

function MainRender() {
  return (
    <>
      {/* <ContentTop pageTitle='Dashboard'/> */}

      {/* <div className="row">
        <div className="containerPI">
          <iframe className="responsive-iframePI" title="FactorEx Dashboard-v1.00 - Dashboard" width={1024} height={612} src="https://app.powerbi.com/view?r=eyJrIjoiOTgzMDVmNWItOWJiOC00OWJkLTgyYjEtMTQ1MGIyMjY2MjE3IiwidCI6IjhmOGJhYWY2LWViNGMtNDllNS04YTFmLTBhZjdmZWFiOTgxYiIsImMiOjl9" frameBorder={0} allowFullScreen={true} />
        </div>
      </div> */}
      <div className="row" style={{ marginTop: "20%" }}>
        <div className="col-lg-12">
          <div className="ibox float-e-margins">
            <div className="ibox-content text-center p-md">
              <h2>
                <span className="text-info">The DASHBOARD - feature </span>
                is currently disabled.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function PoRender(props) {
  const { userData, menuData, location, history } = props;
  const { profileId, session, companyId } = userData;
  const globalVar = window.globalData;
  const dispatch = useDispatch();
  const [poDashData, setPoDashData] = useState([]);
  const [invoiceDashData, setInvoiceDashData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getListByCriteria();
  }, []);
  function getListByCriteria() {
    // console.log(params);

    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true);

    let body = {
      profileId,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetDashBoard, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.dashboardCount;

        // EXTRACT ONLY PO DATA
        const specPoData = menuData.filter(
          (item) => item.FUNCTION_ID === "POR000000000001"
        );

        // DECLARED VARIABLES
        let specSubPoData = [];
        let tempArr = [];

        // GET PO MENU FOR THE CURRENT USER.
        if (specPoData.length !== 0) {
          specSubPoData = specPoData[0]["SUB_MENU"];
        }

        // DISPATCH DATA IN TO ARRAY.
        specSubPoData.forEach((element) => {
          switch (element.FUNCTION_ID) {
            case "POR000000000004":
              tempArr.push({
                name: appLabel.availableItem,
                value: objDetail.poShared,
                icon: "fa fa-share",
                color: "navy-bg",
                itemType: "PO",
                url: "shared-order",
                position: 1,
              });
              break;
            case "POR000000000005":
              tempArr.push({
                name: appLabel.quotedItem,
                value: objDetail.poQuoted,
                icon: "fa fa-quote-left",
                color: "navy-bg",
                itemType: "PO",
                url: "financed-order",
                position: 2,
              });
              break;
            case "POR000000000006":
              tempArr.push({
                name: appLabel.fundedItem,
                value: objDetail.poFunded,
                icon: "fa fa-check-square-o",
                color: "navy-bg",
                itemType: "PO",
                url: "funded-order",
                position: 3,
              });
              break;

            case "POR000000000002":
              tempArr.push({
                name: appLabel.rejectedItem,
                value: objDetail.poRejected,
                icon: "fa fa-thumbs-down",
                color: "navy-bg",
                itemType: "PO",
                url: "manage-order",
                position: 4,
              });
              break;
            case "POR000000000003":
              tempArr.push({
                name: appLabel.awaitningItem,
                value: objDetail.awaitPoVerification,
                icon: "fa fa-smile-o",
                color: "navy-bg",
                itemType: "PO",
                url: "order-to-verify",
                position: 5,
              });

              break;
          }
        });

        /** ORDER ITM S BY POSITION */
        const myData = []
          .concat(tempArr)
          .sort((a, b) => (a.position > b.position ? 1 : -1));

        setPoDashData(myData);

        setIsLoading(false);
      }
    });
  }
  const onSelectTile = (objParam) => {
    // console.log(props);

    const { url } = objParam;

    history.push(`/${url}`);
  };
  return (
    <>
      <div className="animated fadeInRightFAKE">
        <div className="row">
          <div className="col-md-12">
            <button className="btn btn-outline btn-primary  dim " type="button" onClick={()=>BOM.ReloadComponent()}>
              <i className="fa fa-cart-plus" /> {appLabel.po}
            </button>
          </div>
        </div>
        <div className="row">
          {isLoading ? (
            handleSkeletonFac()
          ) : (
            <>
              {poDashData.length === 0 && (
                <div className="col-md-12" style={{ marginTop: "20%" }}>
                  <div className="alert alert-info text-center">
                    <a className="alert-link">{appLabel.noDataAvailableMsg}</a>
                  </div>
                </div>
              )}
              {poDashData.map((item, index) => (
                <div
                  className="col-xs-6 col-md-6 col-lg-3"
                  key={index}
                  onClick={() => onSelectTile(item)}
                >
                  <div className="ibox">
                    <div className="ibox-content dashCard">
                      <h5 className="m-b-md">
                        <i className="fa fa-cart-plus text-success" />{" "}
                        {item.itemType}
                      </h5>
                      <h3>
                        {" "}
                        <i className={`${item.icon} text-navy`} /> &nbsp;{" "}
                        {item.name}
                      </h3>
                      <span>{item.value}</span>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          {/* {poDashData.length === 0 && (
            <div className="col-md-12" style={{ marginTop: "20%" }}>
              <div className="alert alert-info text-center">
                <a className="alert-link">{appLabel.noDataAvailableMsg}</a>
              </div>
            </div>
          )}
          {isLoading && (
            <div className="col-md-12" style={{ marginTop: "10%" }}>
              <div className="alert alert-defau text-center">
                <i className="fa fa-spinner fa-spin fa-5x text-success" />
              </div>
            </div>
          )}
          {poDashData.map((item, index) => (
            <div
              className="col-xs-6 col-md-6 col-lg-3"
              key={index}
              onClick={() => onSelectTile(item)}
            >
              <div className="ibox">
                <div className="ibox-content dashCard">
                  <h5 className="m-b-md">
                    <i className="fa fa-cart-plus text-success" />{" "}
                    {item.itemType}
                  </h5>
                  <h3>
                    {" "}
                    <i className={`${item.icon} text-navy`} /> &nbsp;{" "}
                    {item.name}
                  </h3>
                  <span>{item.value}</span>
                </div>
              </div>
            </div>
          ))} */}
        </div>
      </div>
    </>
  );
}
function InvoiceRender(props) {
  const { userData, menuData, location, history } = props;
  const { profileId, session, companyId } = userData;
  const globalVar = window.globalData;
  const dispatch = useDispatch();
  const [invoiceDashData, setInvoiceDashData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getListByCriteria();
  }, []);

  function getListByCriteria() {
    // console.log(params);

    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true);

    let body = {
      profileId,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetDashBoard, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.dashboardCount;

        // EXTRACT ONLY PO DATA
        const specInvData = menuData.filter(
          (item) => item.FUNCTION_ID === "INV000000000001"
        );

        // DECLARED VARIABLES
        let specSubInvData = [];
        let tempArr = [];

        // GET PO MENU FOR THE CURRENT USER.

        if (specInvData.length !== 0) {
          specSubInvData = specInvData[0]["SUB_MENU"];
        }

        // DISPATCH DATA IN TO ARRAY.
        specSubInvData.forEach((element) => {
          switch (element.FUNCTION_ID) {
            case "INV000000000004":
              tempArr.push({
                name: appLabel.availableItem,
                value: objDetail.invoiceShared,
                icon: "fa fa-share",
                color: "yellow-bg",
                itemType: "Invoice",
                url: "shared-invoice",
                position: 1,
              });
              break;
            case "INV000000000005":
              tempArr.push({
                name: appLabel.quotedItem,
                value: objDetail.invQuoted,
                icon: "fa fa-quote-left",
                color: "yellow-bg",
                itemType: "Invoice",
                url: "financed-invoice",
                position: 2,
              });
              break;
            case "INV000000000006":
              tempArr.push({
                name: appLabel.fundedItem,
                value: objDetail.invoiceFunded,
                icon: "fa fa-check-square-o",
                color: "yellow-bg",
                itemType: "Invoice",
                url: "funded-invoice",
                position: 3,
              });
              break;
            case "INV000000000002":
              tempArr.push({
                name: appLabel.rejectedItem,
                value: objDetail.invRejected,
                icon: "fa fa-thumbs-down",
                color: "yellow-bg",
                itemType: "Invoice",
                url: "manage-invoice",
                position: 4,
              });

              break;
            case "INV000000000003":
              tempArr.push({
                name: appLabel.awaitningItem,
                value: objDetail.awaitInvVerification,
                icon: "fa fa-smile-o",
                color: "yellow-bg",
                itemType: "Invoice",
                url: "invoice-to-verify",
                position: 5,
              });
              break;
          }
        });

        /** ORDER ITM S BY POSITION */
        const myData = []
          .concat(tempArr)
          .sort((a, b) => (a.position > b.position ? 1 : -1));

        setInvoiceDashData(myData);
        setIsLoading(false);
      }
    });
  }
  const onSelectTile = (objParam) => {
    // console.log(props);

    const { url } = objParam;

    history.push(`/${url}`);
  };
  return (
    <>
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-md-12">
            <button className="btn btn-outline btn-primary  dim " type="button" onClick={()=>BOM.ReloadComponent()}>
              <i className="fa fa-money" /> {appLabel.invoice}
            </button>
          </div>
        </div>
        <div className="row">
          {isLoading ? (
            handleSkeletonFac()
          ) : (
            <>
              {isLoading && (
                <div className="col-md-12" style={{ marginTop: "10%" }}>
                  <div className="alert alert-defau text-center">
                    <i className="fa fa-spinner fa-spin fa-5x text-success" />
                  </div>
                </div>
              )}
              {invoiceDashData.map((item, index) => (
                <div
                  className="col-xs-6 col-md-6 col-lg-3"
                  key={index}
                  onClick={() => onSelectTile(item)}
                >
                  <div className="ibox">
                    <div className="ibox-content dashCard">
                      <h5 className="m-b-md">
                        <i className="fa fa-money text-success" />{" "}
                        {item.itemType}
                      </h5>
                      <h3>
                        {" "}
                        <i className={`${item.icon} text-navy`} /> &nbsp;{" "}
                        {item.name}
                      </h3>
                      <span>{item.value}</span>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
