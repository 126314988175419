import React, { useEffect, useState } from "react";
import { apis as api } from "../../services/api.action";
import { connect, useDispatch } from "react-redux";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../ui-setup/RecordStatus";
import appStatic from "../../config/appStaticData";
import NumberFormat from "react-number-format";
import {
  InputAmountFacility,
  Col12BlockFacility,
  InputTextFacility,
  NoDataFac,
  NavTabFac,
} from "../layouts/Facility";

export const InvoiceToVerifyDetail = (props) => {
  const { location, userData, configData, recordData, menuData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId, companyTypeId } = userData;
  const { isCsd } = configData;
  const { tabData } = appStatic;

  const dispatch = useDispatch();
  const [isCsdCheck, setIsCsdCheck] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [invoiceDetail, setInvoiceDetail] = useState({});

  const [orderDetail, setOrderDetail] = useState({});
  const [shipFromData, setShipFromData] = useState({});
  const [billFromData, setBillFromData] = useState({});
  const [shipToData, setShipToData] = useState({});
  const [billToData, setBillToData] = useState({});
  const [isPO, setIsPO] = useState(false);
  const [taxDetail, setTaxDetail] = useState({});
  const [fromCompanyDetail, setFromCompanyDetail] = useState({});
  const [toCompanyDetail, setToCompanyDetail] = useState({});

  const [contractDetail, setContractDetail] = useState({});
  const [financeDetail, setFinanceDetail] = useState({});
  const [financeHistoryData, setFinanceHistoryData] = useState([]);

  const [isSupplier, setIsSupplier] = useState(false);
  const [isCustodian, setIsCustodian] = useState(false);
  const [inputFldData, setInputFldData] = useState([]);

  useEffect(() => {
    // console.log(ggParam);
    handleSwitchCondition();

    getDetailById(ggParam);
    BOM.getSpecDoc(ggParam?.INV_ID, setDocuments, userData);

  }, []);

  const handleSwitchCondition = () => {
    if (isCsd.toLowerCase() === "y") {
      setIsCsdCheck(true);
    }
    switch (companyTypeId) {
      case "CT2022012400002": // supplier
        setIsSupplier(true);
        console.log(companyTypeId);
        break;
      case "CT2022012400000": // depository/custodian
        setIsCustodian(true);
        console.log(companyTypeId);
        break;
    }
  };

  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      invoiceId: params.INV_ID,
      companyId,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetInvoice, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.invoiceDetail;
        const invDetail = objDetail.INVOICE_DATA;
        const taxDetail = objDetail.TAX_DATA;

        setInvoiceDetail(invDetail);
        setTaxDetail(taxDetail);
        setContractDetail(objDetail.CONTRACT_DATA);
        setFinanceDetail(objDetail.BIDDING_DATA);
        setFinanceHistoryData(objDetail.FUND_HISTORY_DATA);

        getPo(objDetail.ORDERID);

        dispatch({
          type: cnt.RECORD_STATUS,
          payload: invDetail,
        });

        //get document
        // getSpecDoc(state.ggParam);

        if (
          invDetail.FLG_STATUS === "A" &&
          invDetail.INV_VERIFICATION_STATUS === "U"
        ) {
          //We passed U to have the two thumbs
          BOM.IsApproveOrReject("U", menuData, "Verify Invoice");
        }

        // mergedDataArr
        setInputFldData([
          {
            name: appLabel.invoiceId,
            value: invDetail.INV_ID,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.currency,
            value: invDetail.INV_CCY,
            fldType: "text",
            section: "general",
          },

          {
            name: appLabel.invAmount,
            value: invDetail.FINAL_AMOUNT,
            fldType: "amount",
            section: "general",
          },

          {
            name: appLabel.invRefId,
            value: invDetail.INV_LOM_CODE,
            fldType: "text",
            section: "general",
          },

          {
            name: appLabel.invIssueDate,
            value: invDetail.INVOICE_DUE_DATE,
            fldType: "text",
            section: "general",
          },

          {
            name: appLabel.invPymtDate,
            value: invDetail.PLANNED_PAYMENT_DATE,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.taxCode,
            value: taxDetail.TAX_CODE,
            fldType: "text",
            section: "tax",
          },
          {
            name: appLabel.rate,
            value: taxDetail.TAX_RATE,
            fldType: "text",
            section: "tax",
          },
          {
            name: appLabel.amount,
            value: taxDetail.TAX_AMOUNT,
            fldType: "amount",
            section: "tax",
          },
          {
            name: appLabel.excludeAmnt,
            value: taxDetail.EXCL_TAX_AMOUNT,
            fldType: "amount",
            section: "tax",
          },
          {
            name: appLabel.totalAmount,
            value: taxDetail.TOTAL_AMOUNT,
            fldType: "amount",
            section: "tax",
          },
          {
            name: appLabel.acceptedDate,
            value: invDetail.ACCEPTED_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.submissionDate,
            value: invDetail.SUBMISSION_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.invDate,
            value: invDetail.INV_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.originalAmount,
            value: invDetail.ORIGINAL_AMOUNT,
            fldType: "amount",
            section: "payment",
          },
          {
            name: appLabel.adjustment,
            value: invDetail.ADJUSTMENT,
            fldType: "amount",
            section: "payment",
          },
          {
            name: appLabel.discountAmount,
            value: invDetail.DISCOUNT_AMOUNT,
            fldType: "amount",
            section: "payment",
          },
          {
            name: appLabel.pymtOption,
            value: invDetail.PAYMENT_METHOD,
            fldType: "text",
            section: "payment",
          },
        ]);
      }
    });
  };
  const getSpecDoc = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      id: params.INV_ID,
      type: "invoice",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setDocuments(res.docListing);
      }
    });
  };

  const getCompanyById = (
    companyId,
    setShipParam,
    setBillParam,
    setCompanyDetail
  ) => {
    let body = {
      profileId,
      companyId,
      session,
    };

    /** FETCH */
    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let companyDetail = res.companyDetail;

        setShipParam(companyDetail.SHIP_DATA);
        setBillParam(companyDetail.BILL_DATA);
        setCompanyDetail(companyDetail);
      }
    });
  };

  const getPo = (idParam) => {
    let body = {
      profileId,
      orderId: idParam,
      criteria: "A",
      companyId,
      session,
    };
    BOM.LoadAlert(cnt.LOAD, "Processing");

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetPo, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let orderDetail = res.purchaseOrderDetail;
        setOrderDetail(orderDetail);

        getCompanyById(
          orderDetail.PO_FROM_COMPANYID,
          setShipFromData,
          setBillFromData,
          setFromCompanyDetail
        );
        getCompanyById(
          orderDetail.PO_TO_COMPANYID,
          setShipToData,
          setBillToData,
          setToCompanyDetail
        );
        setIsPO(true);
      }
    });
  };

  // const onConfirmVerification = (invoiceId) => {

  //   let bodyVerify = {
  //     profileId,
  //     invoiceId,
  //     criteria: "A",
  //     session,
  //   };

  //   // console.log(bodyVerify);
  //   BOM.GlobalApproveRejectAction(
  //     bodyVerify,
  //     api.VerifyInvoice,
  //     "invoice-to-verify",
  //     "Invoice ",
  //     "Verify "
  //   );
  // };

  const onApprove = () => {
    let body = {
      profileId,
      invoiceId: ggParam.INV_ID,
      criteria: "A",
      session,
    };

    // console.log(body);
    // return;
    BOM.GlobalApproveRejectAction(
      body,
      api.VerifyInvoice,
      "invoice-to-verify",
      ggParam.INV_ID,
      "Approve "
    );
  };

  const onReject = () => {
    let body = {
      profileId,
      invoiceId: ggParam.INV_ID,
      criteria: "R",
      session,
    };

    // console.log(body);
    // return;
    BOM.GlobalApproveRejectAction(
      body,
      api.VerifyInvoice,
      "invoice-to-verify",
      ggParam.INV_ID,
      "Reject "
    );
  };

  return (
    <Main {...props} onApprove={onApprove} onReject={onReject}>
      <ContentTop pageTitle={appLabel.invoiceDetail} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{appLabel.invoiceInformation}</h5>
                <div className="ibox-tools">
                  <Link
                    to="/invoice-to-verify"
                    className="btn btn-primary btn-xs"
                    style={{ marginRight: "8px" }}
                  >
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;
                    {appLabel.backBtn}
                  </Link>
                  {/* {invoiceDetail.FLG_STATUS === "A" &&
                    invoiceDetail.INV_VERIFICATION_STATUS === "U" && (
                      <button
                        type="button"
                        onClick={() =>
                          onConfirmVerification(invoiceDetail.INV_ID)
                        }
                        className="btn btn-primary btn-xs"
                      >
                        <i className="fa fa-check-circle" />
                        &nbsp;
                        {appLabel.confirmVerification}
                      </button>
                    )} */}
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                  <Col12BlockFacility topTitle={appLabel.generalInfo}>
                    {inputFldData.map((item, index) => (
                      <div key={index}>
                        {item.section === "general" &&
                          (item.fldType === "amount" ? (
                            <InputAmountFacility
                              inputLabel={item.name}
                              inputValue={item.value}
                            />
                          ) : (
                            <InputTextFacility
                              inputLabel={item.name}
                              inputValue={item.value}
                            />
                          ))}
                      </div>
                    ))}
                    <NoDataFac arrList={inputFldData} />
                  </Col12BlockFacility>

                  <Col12BlockFacility topTitle={appLabel.otherInfo}>
                    {/* <NavTabFac tabsList={tabData.invDetailList} /> */}
                    <ul className="nav nav-tabs">
                      <li className="active">
                        <a data-toggle="tab" href="#documentInfo">
                         <i className="fa fa-file-pdf-o" style={{color:'#d50000'}} />
                          &nbsp;{appLabel.documentInfo}
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#pOInfo">
                          <i className="fa fa-cart-plus text-navy" />
                          &nbsp;{appLabel.pOInfoShort}
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane active" id="documentInfo">
                        <div className="row" style={{ marginTop: "30px" }}>
                          <div className="col-sm-12">
                            {documents.length === 0 ? (
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDocumentMsg}
                                </a>
                              </div>
                            ) : (
                              <div>
                                {documents.map((item, index) => (
                                  <div className="col-md-4" key={index}>
                                    <div className="file-box">
                                      <div className="file">
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            BOM.DownloadAction(
                                              item.DOC_OBJECT,
                                              item.DOC_ID +
                                                " " +
                                                item.DOC_CATEGORY.toLowerCase()
                                            )
                                          }
                                        >
                                          <span className="corner" />
                                          <div className="icon">
                                           <i className="fa fa-file-pdf-o" style={{color:'#d50000'}} />
                                          </div>
                                          <div className="file-name">
                                            {item.DOC_CATEGORY}
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="pOInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          {Object.keys(invoiceDetail).length === 0 ? (
                            <div className="col-sm-12">
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDataInTableMsg}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-12">
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.poNo}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.ORDERID}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.from}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.FROM_COMPANY_NAME}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.to}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.TO_COMPANY_NAME}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.amount}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <NumberFormat
                                    className="form-control input-sm"
                                    value={orderDetail.PO_AMOUNT}
                                    displayType="text"
                                    disabled
                                    thousandSeparator={true}
                                    prefix={""}
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.currency}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.PO_CCY}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.pymtOption}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.PO_PAYMENT_OPTION}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col12BlockFacility>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RecordStatus {...recordData} />
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceToVerifyDetail);
