import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../../services/api.action";
import { connect, useDispatch } from "react-redux";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM, RAL } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import appStatic from "../../../config/appStaticData";
import { Link } from "react-router-dom";
import RecordStatus from "../../ui-setup/RecordStatus";
import NumberFormat from "react-number-format";
import {
  InputAmountFacility,
  Col12BlockFacility,
  InputTextFacility,
  NoDataFac,
  NavTabFac,
  AmntFormatFac,
  ColBlockFac,
  TableSimpleFac,
} from "../../layouts/Facility";


export const FeeDetail = (props) => {
  const { location, userData, configData, recordData, menuData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId, companyTypeId } = userData;
  const { isCsd } = configData;
  const { tableData } = appStatic;

  const dispatch = useDispatch();
  const [isCsdCheck, setIsCsdCheck] = useState(false);

  const [isSupplier, setIsSupplier] = useState(false);
  const [isCustodian, setIsCustodian] = useState(false);
  const [isSlab, setIsSlab] = useState(false);

  const [inputFldData, setInputFldData] = useState([]);
  const [feeSlabData, setFeeSlabData] = useState([]);

  useEffect(() => {
    // console.log(state.ggParam);
    handleSwitchCondition();
    getDetailById(ggParam);
  }, []);

  const handleSwitchCondition = () => {
    if (isCsd.toLowerCase() === "y") {
      setIsCsdCheck(true);
    }
    switch (companyTypeId) {
      case "CT2022012400002": // supplier
        setIsSupplier(true);
        // console.log(companyTypeId);
        break;
      case "CT2022012400000": // depository/custodian
        setIsCustodian(true);
        // console.log(companyTypeId);
        break;
    }
  };

  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      feeId: params.FEE_ID,
      ccy: "",
      session,
      companyTypeId,
      loanAmount:""
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetFees, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.feeDetail;
        setFeeSlabData(objDetail.SLAB_DATA);
        setIsSlab(objDetail.FLG_FEE_SLAB.toLowerCase() === 'y' ? true : false)
        dispatch({
          type: cnt.RECORD_STATUS,
          payload: objDetail,
        });

        // //handle right side
        BOM.IsApproveOrReject(objDetail.FLG_STATUS, menuData, "Charge Setup");

        // mergedDataArr
        setInputFldData([
          
          {
            name: appLabel.companyType,
            value: objDetail.COMPANY_TYPE_NAME,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.feeId,
            value: objDetail.FEE_ID,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.feeName,
            value: objDetail.FEE_NAME,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.currency,
            value: objDetail.FEE_CCY,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.feeType,
            value: objDetail.FEE_TYPE,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.feeAmount,
            value: objDetail.FEE_AMOUNT,
            fldType: "amount",
            section: "general",
          },
          {
            name: appLabel.feePercentage,
            value: objDetail.FEE_PERCENTAGE,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.feeOption,
            value: objDetail.FEE_OPTION,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.isFeeSlab,
            value: objDetail.FLG_FEE_SLAB.toLowerCase() === 'y' ? 'Yes':'No',
            fldType: "text",
            section: "general",
          },
        ]);
      }
    });
  };

  const onApprove = () => {
    let body = {
      profileId,
      feeId: ggParam.FEE_ID,
      criteria: "A",
      session,
    };

    // console.log(body);
    // return
    BOM.GlobalApproveRejectAction(
      body,
      api.ApproveRejectFee,
      "manage-fee",
      "Charge Setup",
      "Approve "
    );
  };
  const onReject = () => {
    let body = {
      profileId,
      feeId: ggParam.FEE_ID,
      criteria: "R",
      session,
    };

    // console.log(body);
    // return
    BOM.GlobalApproveRejectAction(
      body,
      api.ApproveRejectFee,
      "manage-fee",
      "Charge Setup",
      "Reject "
    );
  };

  return (
    <Main {...props} onApprove={onApprove} onReject={onReject}>
      <ContentTop pageTitle={appLabel.feeDetail} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{appLabel.feeInfo}</h5>
                <div className="ibox-tools">
                  <Link to="/manage-fee" className="btn btn-primary btn-xs">
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;
                    {appLabel.backBtn}
                  </Link>
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                  <Col12BlockFacility topTitle={appLabel.generalInfo}>
                    {inputFldData.map((item, index) => (
                      <div key={index}>
                        {item.section === "general" &&
                          (item.fldType === "amount" ? (
                            <InputAmountFacility
                              inputLabel={item.name}
                              inputValue={item.value}
                            />
                          ) : (
                            <InputTextFacility
                              inputLabel={item.name}
                              inputValue={item.value}
                            />
                          ))}
                      </div>
                    ))}
                    <NoDataFac arrList={inputFldData} />
                  </Col12BlockFacility>
                  <div className={`${isSlab ? "" : "hide"}`}>
                      <ColBlockFac topTitle={appLabel.slabInfo}>
                        <ColBlockFac>
                          {feeSlabData.length === 0 ? (
                            <div className="alert alert-warning text-center">
                              <a className="alert-link">
                                {appLabel.noDataInTableMsg}
                              </a>
                            </div>
                          ) : (
                            <TableSimpleFac thData={tableData.feeSlabList}>
                              {feeSlabData.map((item, index) => (
                                <tr className="gradeX" key={index}>
                                  <td>
                                    {index + 1} &nbsp;
                                  </td>
                                  <td>
                                    <AmntFormatFac
                                      inputValue={item.FEE_AMOUNT_MIN}
                                    />
                                  </td>
                                  <td>
                                    <AmntFormatFac
                                      inputValue={item.FEE_AMOUNT_MAX}
                                    />
                                  </td>
                                  <td>
                                    <AmntFormatFac inputValue={item.FEE_RATE} />
                                  </td>
                                </tr>
                              ))}
                            </TableSimpleFac>
                          )}
                        </ColBlockFac>
                      </ColBlockFac>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RecordStatus {...recordData} />
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FeeDetail);