/**
 * @author Gedeon NdundeCode
 * @date 05/12/2021
 */
import React, { useState, useEffect } from "react";
import useGeolocation from "react-hook-geolocation";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { apis as api } from "../../services/api.action";
import { cnt } from "../../services/constant.action";

import {
  SignInAction,
  SignOutAction,
} from "../../services/redux/actions/auth-act";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import Logo from "../../assets/img/logoLogin/fex_transparent.png";
import CardLogo from "../../assets/img/logoLogin/fxBg.jpg";
import Digicert from "../../assets/img/logoLogin/digicert.png";
import { BgImgFac } from "../layouts/Facility";
import history from "../../history";
import { SubmitBtnLoad } from "../layouts/Facility";
import PODashboard from "./auth-analytic-component/PODashboard";
import InvoiceDashboard from "./auth-analytic-component/InvoiceDashboard";
import { Box, Alert, AlertTitle, Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

import Header from "./auth-analytic-component/Header";
import SwitchModule from "./auth-analytic-component/SwitchModule";

const SignIn = (props) => {
  /** INITIALIZE VARIABLES */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { SignInAction, SignOutAction } = props;
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [switchValue, setSwitchValue] = useState(false);

  const onSwitchModule = () => {
    setSwitchValue(!switchValue);
  };

  // const [ip, setIP] = useState({});
  // const geolocation = useGeolocation();

  useEffect(() => {
    // console.log("this is props: ", props);+ ""
    // $('body').addClass('gray-bg');
    // getData();
    SignOutAction();
  }, []);

  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);

    // console.log("latitude: ", geolocation.latitude);
    // console.log("longitude: ", geolocation.longitude);
  };

  //creating function to load ip address from the API
  // const getData = async () => {
  //   const res = await axios.get("https://geolocation-db.com/json/");
  //   // console.log(res.data);
  //   setIP(res.data);
  // };

  /** SUBMISSION */
  const onSignIn = (formData) => {
    setIsLoading(true);

    const body = {
      userName: formData.userName,
      userPass: formData.userPass,
      sourceIp: "197.184.164.52", //ip.IPv4,
      browser: BOM.GetBrowserName(),
      host: window.location.host,
      osName: "windows",
      longitude: "-25.757", //ip.longitude + "",
      latitude: "28.1443", //ip.latitude + "",
      channel: "self service",
    };

    BOM.SessionLessReqAction(body, api.Authentication, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
        setIsLoading(false);
      } else {
        const { firstStatus, flg_terms, userParentRoleName, admin_flg_terms } =
          res.userData;
        //GET DYNAMIC MENU FOR CURRENT USER
        getRoleMenu(res.userData);

        dispatch({
          type: cnt.SIGN_IN,
          payload: res,
        });

        //check if the entity has accepted Ts and cs
        switch (userParentRoleName.toLowerCase()) {
          case "sad":
            reuseSwitchCase(admin_flg_terms, firstStatus);
            break;

          default:
            if (admin_flg_terms.toLowerCase() !== "n") {
              reuseSwitchCase(flg_terms, firstStatus);
            } else {
              BOM.AlertMsg(cnt.WARNING, appLabel.tsNotAcceptMsg);
              history.push("/sign-in");
              setIsLoading(false);
            }
            break;
        }
      }
    });
  };
  async function getRoleMenu(user) {
    // BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId: user.profileId,
      roleId: user.userRoleId,
      session: user.session,
    };

    BOM.FetchReqAction(body, api.GetRoleMenu, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.roleData.FUNCTIONS;
        dispatch({
          type: cnt.MENU_DATA,
          payload: objList,
        });
        // $("#side-menu").metisMenu();
      }
    });
  }

  function reuseSwitchCase(keyParam, firstStatus) {
    switch (keyParam.toLowerCase()) {
      case "n":
        history.push("/validate-terms");
        break;

      default:
        switch (firstStatus.toLowerCase()) {
          case "a":
            history.push("/first-time-login-password");
            break;

          default:
            setTimeout(() => {
              history.push("/dashboard");
            }, 1000);
            break;
        }
        break;
    }
  }

  const renderLoginForm = () => {
    return (
      <>
        <BgImgFac />
        <Box sx={{ flexGrow: 1, margin: "40px 0" }} m={2}>
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "15px",
              overflow: "auto",
              padding: "25px",
            }}
          >
            <div
              className="text-center"
              style={{
                height: "150px",
                backgroundColor: "#fff",
                borderRadius: "3px",
              }}
            >
              <img
                alt="image"
                style={{ width: "150px", marginTop: "50px" }}
                src={CardLogo}
              />
            </div>
            <form onSubmit={handleSubmit(onSignIn)} autoComplete="off">
              <div className="form-group">
                <label className="text-left">
                  {appLabel.username}{" "}
                  <small style={{ fontWeight: "normal" }}>
                    {appLabel.userNameExemple}
                  </small>
                </label>
                <input
                  type="text"
                  className={`form-control input-sm $ ${
                    errors.userName ? "outline-error" : ""
                  }`}
                  placeholder={appLabel.username}
                  {...register("userName", {
                    required: "Username is required!",
                    pattern: {
                      value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                      message: "Email must be a valid email address",
                    },
                  })}
                />
                <span className="asterisks">{errors?.userName?.message}</span>
              </div>
              <div className="form-group">
                <label className="text-left">{appLabel.password}</label>
                <input
                  type={passwordShown ? "text" : "password"}
                  className="form-control input-sm"
                  placeholder={appLabel.password}
                  {...register("userPass", {
                    required: "Password is required !",
                    minLength: {
                      value: 8,
                      message: "Minimum password length is 8 characters",
                    },
                  })}
                />
                <span className="p-viewer" onClick={() => togglePassword()}>
                  <i
                    className={passwordShown ? "fa fa-eye-slash" : "fa fa-eye"}
                  ></i>
                </span>
                <span className="asterisks">{errors?.userPass?.message}</span>
              </div>
              <div className="form-group text-center">
                <SubmitBtnLoad
                  isLoading={isLoading}
                  btnName={appLabel.btnLogin}
                  isFullWidth={true}
                  icon={"fa fa-sign-in"}
                />
              </div>
              <div className="form-group text-center">
                <Link
                  to="/forgot-password"
                  style={{ fontWeight: "bold", color: "gray" }}
                >
                  <small>{appLabel.forgotpass} ?</small>
                </Link>
              </div>
              <div className="form-group text-center">
                <label className="text-left">
                  <small>{appLabel.newTo}</small>
                </label>
                &nbsp;
                <Link
                  to="/registration"
                  style={{
                    fontWeight: "bold",
                    color: "rgb(204, 153, 61)",
                  }}
                >
                  <small>{appLabel.createAccount}</small>
                </Link>
              </div>
            </form>
          </Box>
        </Box>
      </>
    );
  };

  const Footer = () => {
    return (
      <div
        className="middle-box text-center text-white loginscreen animated fadeInDown"
        style={{ marginTop: 0, zIndex: 2 }}
      >
        <div className="col-md-12">
          {appLabel.poweredBy}{" "}
          <a
            href="https://openfactorgroup.com/"
            target="_blank"
            style={{ fontWeight: "900", fontSize: "16px", color: "#333333" }}
          >
            {"OpenFactor"}
          </a>
        </div>
        <div className="col-md-12 m-t">
          <img
            alt="image"
            style={{
              width: "125px",
              marginTop: "0px",
              paddingTop: "0px",
            }}
            src={Digicert}
          />
        </div>
      </div>
    );
  };
  return (
    <>
      <Box sx={{ flexGrow: 1 }} m={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={9}>
            <Grid item xs={12} sx={{ margin: "35px 20px" }}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Header title={"ANALYTICS"} subtitle={""} />

                <SwitchModule
                  onAction={onSwitchModule}
                  actionValue={switchValue}
                />
              </Box>
            </Grid>
            {switchValue ? (
              <InvoiceDashboard {...props} />
            ) : (
              <PODashboard isPo={true} {...props} />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {renderLoginForm()}
          </Grid>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default connect(null, { SignInAction, SignOutAction })(SignIn);
