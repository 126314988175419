import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "../Report.css";
import Main from "../../layouts/Main";
import { useForm } from "react-hook-form";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import NumberFormat from "react-number-format";
import { apis as api } from "../../../services/api.action";
import appStatic from "../../../config/appStaticData";
import appLabel from "../../../config/appLabel";
import { Tooltips } from "../../../components/layouts/Facility";

export const ContractReport = (props) => {
  const { toolTip } = appStatic;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const { userData } = props;
  const { profileId, session, companyId } = userData;

  const [isAuth, setIsAuth] = useState(false);
  const [isContractId, setIsContractId] = useState(false);
  const [isDateRange, setIsDateRange] = useState(false);
  const [contractData, setContractData] = useState([]);
  const [contractLabelData, setContractLabelData] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState({});

  useEffect(() => {
    setContractLabelData(appStatic.reporting.contractLabelData);
    requiredJqueryDOM();
  }, []);

  function requiredJqueryDOM() {
    let d = new Date();
    d.setMonth(d.getMonth() - 3);
    let lastThreeMonth = BOM.ReformatDate(d.toLocaleDateString());

    // console.log("lastThreeMonth ", lastThreeMonth);
    $(document).ready(function () {
      $("#startDate, .startDate, .startDate3").datepicker({
        todayBtn: "linked",
        keyboardNavigation: false,
        forceParse: false,
        calendarWeeks: true,
        autoclose: true,
        startDate: new Date(lastThreeMonth),
        endDate: new Date(BOM.CurrentDate()),
      });

      $("#endDate, .endDate, .endDate3").datepicker({
        todayBtn: "linked",
        keyboardNavigation: false,
        forceParse: false,
        calendarWeeks: true,
        autoclose: true,
        startDate: new Date(lastThreeMonth),
        endDate: new Date(BOM.CurrentDate()),
      });
    });
  }
  const onSelectType = (key) => {
    // console.log(key);
    // CLEAR ALL PREVIOUS TYPE
    setIsAuth(false);
    setIsContractId(false);
    setIsDateRange(false);

    setValue("contractId", "");
    setValue("contractType", "");
    setValue("criteria", "");
    $(".clearInput").val("");

    switch (key.toLowerCase()) {
      case "auth":
        setIsAuth(true);
        break;
      case "contractid":
        setIsContractId(true);
        break;
      case "date":
        setIsDateRange(true);
        break;
    }
  };

  const onSelectContractReport = (idParam) => {
    /** CLEAR PEVIOUS DATA */
    setContractData([]);
    setIsContractId(false);
    setIsDateRange(false);
    /** SOME LOGIC ON THE SELECTED CARD */
    let newArray = contractLabelData.map((item) =>
      item.id === idParam
        ? { ...item, isSelected: 1 }
        : { ...item, isSelected: 0 }
    );
    setContractLabelData(newArray);

    setSelectedLabel(newArray.find((item) => item.id === idParam));
  };
  const searchByStatus = (formValues) => {
    setContractData([]);
    let startDate = document.getElementById("startDate").value;
    let endDate = document.getElementById("endDate").value;

    if (!startDate && (isAuth)) {
      BOM.AlertMsg(cnt.WARNING, "Start date is required.");
      return;
    }

    if (!endDate && (isAuth)) {
      BOM.AlertMsg(cnt.WARNING, "End date is required.");
      return;
    }

    /** SEND PROPER CRITERIA */
    if (isAuth) {
      formValues.criteria = formValues.authCriteria;
    }

    formValues.startDate = startDate ? BOM.ReformatDate(startDate) : "";
    formValues.endDate = endDate ? BOM.ReformatDate(endDate) : "";
    let body = {
      profileId,
      session,
      ...{
        criteria: formValues.criteria,
        startDate: formValues.startDate,
        endDate: formValues.endDate,
        contractId: formValues.contractId,
        searchType: formValues.searchType,
        contractType: formValues.contractType
      },
    };

    // console.log(body);
    /** FETCH ROLE */
    BOM.LoadAlert(cnt.LOAD, "Searching...");
    BOM.FetchReqAction(body, api.GetContractReport, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setContractData(res.contractListing);
        BOM.DatatableUsage();
      }
    });
  };
  return (
    <Main {...props}>
      {/* <ContentTop pageTitle='Dashboard'/> */}
      <div className="row">
        { contractLabelData.map((item, index) => (
            <div
              className="col-md-6 col-lg-3"
              key={index}
              onClick={() => onSelectContractReport(item.id)}
            >
              <div className="ibox">
                <div
                  className={`ibox-content ${
                    item.isSelected ? "reportCardSelected" : "reportCard"
                  }`}
                >
                  <h5>{appLabel.report}</h5>
                  <h3>
                    {" "}
                    <i className={`${item.icon} text-navy`} /> &nbsp;{" "}
                    {item.name}
                  </h3>
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className="animated fadeInRight">
        <div
          className={`row ${
            selectedLabel.isSelected === 1 && selectedLabel.id === "1"
              ? ""
              : "hide"
          }`}
        >
          <form
            className="m-t"
            onSubmit={handleSubmit(searchByStatus)}
            autoComplete="off"
          >
            <div className="col-lg-12">
              <div className="ibox-title">
                <h5>{selectedLabel.name}</h5>
              </div>
              <div className="ibox-content m-b-sm border-bottom contentCard panel-primary">
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-search text-navy"></i>&nbsp;{" "}
                      {appLabel.searchCriteria}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label className="control-label">
                          {appLabel.type}&nbsp;<Tooltips title={toolTip.searchType} /><span className="asterisks">*</span>
                        </label>
                        <select
                          className="form-control input-sm"
                          {...register("searchType", {
                            required: "Field is required.",
                            onChange: (e) => onSelectType(e.target.value),
                          })}
                        >
                          <option value="">{appLabel.choose}</option>
                          {appStatic.contractStatusCriteria.map(
                            (item, index) => (
                              <option value={item.value} key={index}>
                                {item.name}
                              </option>
                            )
                          )}
                        </select>
                        <span className="asterisks">
                          {errors?.searchType?.message}
                        </span>
                      </div>
                    </div>
                    <div className={`col-sm-2 ${isAuth || isContractId ? "" : "hide"}`}>
                      <div className="form-group">
                        <label className="control-label">
                          {appLabel.contractType}{" "}<Tooltips title={toolTip.contractType} />
                          <span className="asterisks">*</span>
                        </label>
                        <select
                          className="form-control input-sm clearInput"
                          {...register("contractType", {
                            required: "Field is required.",
                          })}
                        >
                          <option value="">{appLabel.choose}</option>
                          {appStatic.contractTypeReport.map((item, index) => (
                            <option value={item.value} key={index}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <span className="asterisks">
                          {errors?.contractType?.message}
                        </span>
                      </div>
                    </div>
                    <div className={`col-sm-2 ${isAuth ? "" : "hide"}`}>
                      <div className="form-group">
                        <label className="control-label">
                          {appLabel.criteria}{" "}<Tooltips title={toolTip.searchCriteria} />
                          <span className="asterisks">*</span>
                        </label>
                        <select
                          className="form-control input-sm clearInput"
                          {...register("authCriteria", {
                            required: isAuth ? "Field is required." : false,
                          })}
                        >
                          <option value="">{appLabel.choose}</option>
                          {appStatic.flgStatusData.map((item, index) => (
                            <option value={item.value} key={index}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <span className="asterisks">
                          {errors?.authCriteria?.message}
                        </span>
                      </div>
                    </div>
                    <div className={`col-sm-3 ${isContractId ? "" : "hide"}`}>
                      <div className="form-group">
                        <label className="control-label">
                          {appLabel.contractId} <span className="asterisks">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control input-sm clearInput"
                          placeholder={appLabel.contractId}
                          {...register("contractId", {
                            required: isContractId ? "Field is required." : false,
                          })}
                        />
                        <span className="asterisks">
                          {errors?.contractId?.message}
                        </span>
                      </div>
                    </div>
                    <div
                      className={`col-sm-2 ${isAuth  ? "" : "hide"}`}
                    >
                      <div className="form-group">
                        <label className="control-label" htmlFor="startDate">
                          {appLabel.startDate}{" "}<Tooltips title={toolTip.startDate} />
                          <span className="asterisks">*</span>
                        </label>
                        <div className="input-group date">
                          <span className="input-group-addon">
                            <i className="fa fa-calendar" />
                          </span>
                          <input
                            id="startDate"
                            type="text"
                            className="form-control input-sm clearInput"
                          />

                          <span className="asterisks">
                            {errors?.startDate?.message}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`col-sm-2 ${isAuth? "" : "hide"}`}
                    >
                      <div className="form-group">
                        <label className="control-label" htmlFor="endDate">
                          {appLabel.endDate}{" "}<Tooltips title={toolTip.endDate} />
                          <span className="asterisks">*</span>
                        </label>
                        <div className="input-group date">
                          <span className="input-group-addon">
                            <i className="fa fa-calendar" />
                          </span>
                          <input
                            id="endDate"
                            type="text"
                            className="form-control input-sm clearInput"
                          />
                          <span className="asterisks">
                            {errors?.endDate?.message}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`col-sm-2 ${
                        isAuth || isContractId ? "" : "hide"
                      }`}
                    >
                      <div className="form-group">
                        <label className="control-label">&nbsp;</label>
                        <div className="input-group date">
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm"
                          >
                            <i className="fa fa-search" />
                            &nbsp;
                            {appLabel.search}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-list text-navy"></i>&nbsp;{" "}
                      {appLabel.result}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="table-responsive">
                      {contractData.length === 0 ? (
                        <div className="alert alert-warning text-center">
                          <a className="alert-link">
                            {appLabel.noDataInTableMsg}
                          </a>
                        </div>
                      ) : (
                        <div>
                          {" "}
                          <div className="table-responsive">
                            <table
                              id="myTable"
                              className="table table-striped table-bordered table-hover"
                            >
                              <thead>
                                <tr>
                                  <th>{appLabel.contractId}</th>
                                  <th>{appLabel.startDate}</th>
                                  <th>{appLabel.endDate}</th>
                                  <th>{appLabel.from}</th>
                                  <th>{appLabel.to}</th>
                                  <th>{appLabel.funder}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {contractData.map((contract, index) => (
                                  <tr className="gradeX" key={index}>
                                    <td>{contract.CONTRACT_ID}</td>
                                    <td>{contract.START_DATE}</td>
                                    <td>{contract.END_DATE}</td>
                                    <td>{contract.BUYER}</td>
                                    <td>{contract.SUPPLIER}</td>
                                    <td>{contract.FUNDER}</td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                              <tr>
                                  <th>{appLabel.contractId}</th>
                                  <th>{appLabel.startDate}</th>
                                  <th>{appLabel.endDate}</th>
                                  <th>{appLabel.from}</th>
                                  <th>{appLabel.to}</th>
                                  <th>{appLabel.funder}</th>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ContractReport);
