/**
 * @author Gedeon NdundeCode
 * @date 05/12/2021
 */
import React, { useState, useEffect } from "react";
import useGeolocation from "react-hook-geolocation";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { apis as api } from "../../services/api.action";
import { cnt } from "../../services/constant.action";

import {
  SignInAction,
  SignOutAction,
} from "../../services/redux/actions/auth-act";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import Logo from "../../assets/img/logoLogin/2022-10-FACTOReX-LOGO-DESIGN-3.png";
import GhanaMap from "../../assets/img/logoLogin/map-ghana-polygonal-mesh-line-map-flag-map-scaled.jpg";
import SupplyChain from "../../assets/img/logoLogin/2023-11-still-life-supply-chain-representation-scaled.jpg";
import { BgImgFac, HandleReqFldMsg, OutlineFldErr } from "../layouts/Facility";
import history from "../../history";
import AOS from "aos";
import Privacy from "../../other_files/terms/Privacy";
import appStatic from "../../config/appStaticData";

const SignIn = (props) => {
  /** INITIALIZE VARIABLES */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { SignInAction, SignOutAction } = props;
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [switchBlock, setSwitchBlock] = useState(false);
  const { privacyPolicyDocumentData } = appStatic;

  const onSwitchModule = (objParam) => {
    if (objParam?.isProtected) {
      history.push("/protected-privacy-document");
    } else {
      setSwitchBlock(!switchBlock);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      privacyPolicyDocumentData.forEach((element) =>
        element.isProtected ? (element.icon = "fa fa-lock text-navy") : element
      );
    }, 500);

    SignOutAction();
    AOS.init({
      easing: "ease-out-back",
      duration: 1000,
      // disable: 'mobile'
      disable: function () {
        var maxWidth = 1280;
        return window.innerWidth < maxWidth;
      },
    });
  }, []);

  window.onscroll = function () {
    // show or hide the back-top-top button
    var backToTo = document.querySelector(".fEx-scroll-top");
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 0
    ) {
      backToTo.style.display = "flex";
    } else {
      backToTo.style.display = "none";
    }
  };
  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  /** SUBMISSION */
  const onSignIn = (formData) => {
    setIsLoading(true);

    const body = {
      userName: formData.userName,
      userPass: formData.userPass,
      sourceIp: "197.184.164.52", //ip.IPv4,
      browser: BOM.GetBrowserName(),
      host: window.location.host,
      osName: "windows",
      longitude: "-25.757", //ip.longitude + "",
      latitude: "28.1443", //ip.latitude + "",
      channel: "self service",
    };

    BOM.SessionLessReqAction(body, api.Authentication, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        const { firstStatus, flg_terms, userParentRoleName, admin_flg_terms } =
          res.userData;
        //GET DYNAMIC MENU FOR CURRENT USER
        getRoleMenu(res.userData);

        dispatch({
          type: cnt.SIGN_IN,
          payload: res,
        });

        // handleFirstCheck(userParentRoleName, "n", flg_terms, firstStatus)
        handleFirstCheck(userParentRoleName, admin_flg_terms, flg_terms, firstStatus)
      }
    });
  };

  function handleFirstCheck(userParentRoleName, adminFlg, flg_terms, firstStatus) {
    // console.log("-------start handleFirstCheck()----");
    // console.log(`${userParentRoleName}, ${adminFlg}, ${flg_terms}, ${firstStatus}}`);

    switch (adminFlg.toLowerCase()) {
      case "n": // Admin has not agreed with Ts and Cs
        switch (userParentRoleName.toLowerCase()) {
          case "sad": // Send Admin where they can agree with Ts and Cs
            history.push("/validate-terms");
            break;
          default:
            BOM.AlertMsg(cnt.WARNING, appLabel.tsNotAcceptMsg);
            break;
        }
        break;
      case "a": // Admin has agreed with Ts and Cs Login user
        switch (userParentRoleName.toLowerCase()) {
          case "sad":
            handleSecondCheck(firstStatus); // Login Admin
            break;
          default:
            switch (flg_terms.toLowerCase()) {
              case "a":
                handleSecondCheck(firstStatus);
                break;
              default:
                history.push("/validate-terms"); // using same component but different content
                break;
            }
            break;
        }
        break;
      default:
        BOM.AlertMsg(cnt.WARNING, appLabel.tsNotAcceptMsg);
        break;
    }
  }

  function handleSecondCheck(firstStatus) {
    // console.log("-------start handleSecondCheck()----");

    switch (firstStatus.toLowerCase()) {
      case "a":
        history.push("/first-time-login-password");
        break;

      default:
        setTimeout(() => {
          history.push("/dashboard");
        }, 1000);
        break;
    }
  }

  async function getRoleMenu(user) {
    // BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId: user.profileId,
      roleId: user.userRoleId,
      session: user.session,
    };

    BOM.FetchReqAction(body, api.GetRoleMenu, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.roleData.FUNCTIONS;
        dispatch({
          type: cnt.MENU_DATA,
          payload: objList,
        });
        // $("#side-menu").metisMenu();
      }
    });
  }

  function reuseSwitchCase(keyParam, firstStatus) {
    switch (keyParam.toLowerCase()) {
      case "n":
        history.push("/validate-terms");
        break;

      default:
        switch (firstStatus.toLowerCase()) {
          case "a":
            history.push("/first-time-login-password");
            break;

          default:
            setTimeout(() => {
              history.push("/dashboard");
            }, 1000);
            break;
        }
        break;
    }
  }

  return (
    <>
      {/* New to Factorex */}
      <div className="factorex-register">
        <div className="fEx-container">
          <div className="fEx-row">
            <div className="fEx-col-6" data-aos="fade-up">
              <div className="factorex-img">
                <img
                  alt="factorex"
                  className="fEx-img"
                  width={615}
                  height={897}
                  src={GhanaMap}
                />
              </div>
            </div>
            <div className="fEx-col-6" data-aos="fade-up">
              <div className="factorex-from">
                <div className="fEx-inner-header">
                  LEADING SUPPLY CHAIN FINANCE MARKETPLACE
                </div>
                <div className="factorex-logo">
                  <img alt="logo" className="fEx-img" src={Logo} />
                  <span>Ghana</span>
                </div>
                <form onSubmit={handleSubmit(onSignIn)} autoComplete="off">
                  <div className="fEx-fild-row">
                    <label htmlFor="user">{appLabel.usernameOrEmail}</label>
                    <input
                      type="text"
                      className="fEx-from-input fEx-zero-input-focus"
                      placeholder={appLabel.usernameOrEmail}
                      {...register("userName", {
                        required: HandleReqFldMsg(appLabel.usernameOrEmail),
                        pattern: {
                          value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                          message: "Email must be a valid",
                        },
                      })}
                    />
                    <span className="asterisks-landing">
                      {errors?.userName?.message}
                    </span>
                  </div>
                  <div className="fEx-fild-row">
                    <label htmlFor="password">{appLabel.password}</label>
                    <input
                      type={passwordShown ? "text" : "password"}
                      className="fEx-from-input fEx-zero-input-focus"
                      placeholder={appLabel.password}
                      {...register("userPass", {
                        required: HandleReqFldMsg(appLabel.password),
                        minLength: {
                          value: 8,
                          message: "Minimum password length is 8 characters",
                        },
                      })}
                    />
                    <span className="p-viewer" onClick={() => togglePassword()}>
                      <i
                        className={
                          passwordShown ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                      ></i>
                    </span>
                    <span className="asterisks-landing">
                      {errors?.userPass?.message}
                    </span>
                  </div>
                  <div className="fEx-fild-row">
                    <label
                      className="fEx-check-box"
                      htmlFor="login-remember-me"
                    >
                      {/* <input
                        type="checkbox"
                        id="login-remember-me"
                        name="rememberme"
                        defaultValue="forever"
                        className="fEx-remember"
                      />
                      Remember Me */}
                    </label>
                  </div>
                  <div className="fEx-fild-row">
                    <button
                      type="submit"
                      disabled={isLoading}
                      className={`${
                        isLoading ? "fEx-on-click-btn-login" : "fEx-btn-login"
                      }`}
                    >
                      {isLoading ? (
                        <span>
                          <i className="fa fa-spinner fa-spin" /> &nbsp;{" "}
                          {appLabel.processing}
                        </span>
                      ) : (
                        appLabel.btnLogin
                      )}
                    </button>
                  </div>
                  <div className="fEx-fild-link">
                    <a
                      className="fEx-password"
                      onClick={() => history.push("/forgot-password")}
                    >
                      Lost your password?
                    </a>
                    <span>
                      New to Factorex?{" "}
                      <a
                        className="fEx-register"
                        onClick={() => history.push("/registration")}
                      >
                        Register Here{" "}
                      </a>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {switchBlock ? (
        <Privacy onSwitchModule={onSwitchModule} />
      ) : (
        <div className="fEx-innovative-platform">
          <div className="fEx-container">
            <div className="fEx-row">
              <div className="fEx-col-6">
                <div className="fEx-Innovative-img" data-aos="fade-up">
                  <img alt="Innovative" className="fEx-img" src={SupplyChain} />
                </div>
              </div>
              <div className="fEx-col-6">
                <div className="fEx-Innovative-info" data-aos="fade-down">
                  <p>
                    Secure, Robust and Innovative Platform for Buyers,
                    Suppliers, and Funders to authenticate and manage supply
                    chain transactions
                  </p>
                  <span className="fEx-divider" />
                  <ul className="fEx-icon-list-items">
                    {privacyPolicyDocumentData.map((item, index) => (
                      <li key={index}>
                        <a onClick={() => onSwitchModule(item)}>
                          <i className={item.icon} />
                          &nbsp;{item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <p>Alternatively, please feel free to contact us</p>
                  {/* <div className="fEx-btn-action">
                    <a href="#">
                      <span>Contact Us</span>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <a
        href="#"
        className="fEx-scroll-top"
        aria-label="Back to top"
        alt="back-to-top"
      >
        <i className="fa fa-arrow-up" />
      </a>
    </>
  );
};

export default connect(null, { SignInAction, SignOutAction })(SignIn);
