import React, { useEffect, Fragment, useState } from "react";
import { Button, ButtonToolbar, Modal } from "react-bootstrap";
import appLabel from "../../config/appLabel";

const OtpModal = (props) => {
  const { handleSave, handleClose, handleResend, show, children, title, saveTitle } = props;
  const showHideClassName = show ? "" : "hide";

  useEffect(() => {}, []);

  return (
    <div>
      <Modal.Dialog
        className={`animated flipInX modal-sm ${showHideClassName}`}
      >
        <Modal.Header>
          {/* <Modal.Title>{title}</Modal.Title> */}
          <div className="text-right">
            <Button
              className="btn btn-danger btn-xs"
              onClick={() => handleClose()}
            >
              {" "}
              <i className="fa fa-times" />
              &nbsp;
            </Button>
            &nbsp;
          </div>
          <div className="col-md-offset-2">
            <h3>
              {" "}
              &nbsp; <i className="fa fa-unlock text-navy" /> &nbsp;{" "}
              {appLabel.verifOtp}
            </h3>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="row">{children}</div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="btn btn-warning btn-xs"
            onClick={() => handleResend()}
          >
            <i className="fa fa-refresh" /> {appLabel.resendBtn}
          </Button>
          &nbsp;
          <Button
          type="submit"
            className="btn btn-primary btn-xs"
            onClick={() => handleSave()}
          >
            {" "}
            <i className="fa fa-arrow-circle-right" /> &nbsp;{" "}
            {appLabel.validateOtpBtn}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
};

export default OtpModal;
