import React, { useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../services/api.action";
import { connect } from "react-redux";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import history from "../../history";
import { cnt } from "../../services/constant.action";
import { BOM, RAL } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../ui-setup/RecordStatus";
import appStatic from "../../config/appStaticData";
import RightSide from "../ui-setup/RightSide";
import AppModal from "../layouts/AppModal";
import NumberFormat from "react-number-format";
import { Tooltips } from "../layouts/Facility";
import {
  AmountFacility,
  InputAmountFacility,
  InputTextFacility,
  NoDataFac,
  Col12BlockFacility,
  AmntFormatFac,
} from "../layouts/Facility";
import Notiflix from "notiflix";

const amtPybles = {
  amtPay1: 0,
  amtPay2: 0,
};
export const NewSharedOrder = (props) => {
  const { location, userData, configData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId, companyTypeId } = userData;
  const { isCsd } = configData;
  const { toolTip, notif } = appStatic;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const [investorId, setInvestorId] = useState("");
  const [docCategory, setDocCategory] = useState("");
  const [documents, setDocuments] = useState([]);
  const [selectedInvestors, setSelectedInvestors] = useState([]);
  const [investors, setInvestorData] = useState([]);
  const [autoFldData, setAutoFldData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [otherFee1Data, setOtherFee1Data] = useState([]);
  const [otherFee2Data, setOtherFee2Data] = useState([]);
  const [amountPayable1, setAmountPayable1] = useState(0);
  const [amountPayable2, setAmountPayable2] = useState(0);
  const [amountReceivable, setAmountReceivable] = useState(0);
  const [capitalRequired, setCapitalRequired] = useState(0);
  const [fundsAvailable, setFundsAvailable] = useState(undefined);
  const [logisticsOtherCosts, setLogisticsOtherCosts] = useState(undefined);
  const [supplierQuote, setSupplierQuote] = useState(undefined);
  const [isQuoteRqdFld, setIsQuoteRqdFld] = useState(true);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  const [isCalculated, setIsCalculated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isLogisticsRqdFld, setIsLogisticsRqdFld] = useState(true);
  const [isFundsRqdFld, setIsFundsRqdFld] = useState(true);
  const [isCapitalRqdFld, setIsCapitalRqdFld] = useState(true);
  const [isFullPayment, setIsFullPayment] = useState(false);
  const [isCsdCheck, setIsCsdCheck] = useState(false);
  const [tempFile, setTempFile] = useState(false);

  useEffect(() => {
    // console.log(ggParam);

    if (isCsd.toLowerCase() === "y") {
      setIsCsdCheck(true);
    }
    setUpOrder(ggParam);
    // onCalculate()
    setAmountPayable1(amountPayable1);
    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);
  }, []);

  function setUpOrder(param) {
    
    let body = {
      profileId,
      orderId: param.ORDERID,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.CheckPoFees, (err, res) => {
      if (err) {
        BOM.AlertGoBackMsg(cnt.WARNING, err);
      } else {

        // console.log(res);
        getCompany();
        setValue("orderId", param.ORDERID);
        setValue("fromCompany", param.FROM_COMPANY_NAME);
        setValue("toCompany", param.TO_COMPANY_NAME);
        setValue("amount", param.PO_AMOUNT);
        setValue("currency", param.PO_CCY);
      }
    });
  }
  function getCompany() {
    let body = {
      profileId,
      companyId: "",
      criteria: "A",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        /** GET COMPANIES BASED TYPE  SO FAR (FUNDER AND CUSTODIAN)*/
        let tempCo = res.companyListing.filter(
          (item) => item.COMPANY_TYPE_ID === "CT2022012400003"
          // || item.COMPANY_TYPE_ID === "CT2022012400000"
        );
        setInvestorData(tempCo);
        setCompanyData(res.companyListing);
      }
    });
  }
  // convert to base 64 file
  const handleFileRead = async (event, setState_param) => {
    const file = event.target.files[0];
    if (!file) {
      // if file is empty
      setState_param("");
      return;
    }

    if (file.size > 5242880) {
      BOM.AlertMsg(cnt.WARNING, "File size cannot exceed 5 MB");
      $(".documentInput").val("");
      return;
    }

    switch (file.type.toLowerCase()) {
      case "application/pdf":
        const base64 = await BOM.convertBase64(file);
        let strB64 = base64.split("base64,")[1]; //.replace(/^data:image\/[a-z]+;base64,/, "");
        setState_param(strB64);
        break;

      default:
        BOM.AlertMsg(cnt.WARNING, "Document must be a PDF.");
        $(".documentInput").val("");
        break;
    }

    // console.log(file.type);
  };

  const uploadFile = () => {
    // console.log(tempFile);

    const itemId = documents.find(
      (item, index) => item.docCategory === docCategory
    );

    if (itemId) {
      BOM.AlertMsg(cnt.WARNING, "No duplicate file allowed!");
      return;
    }

    if (!tempFile || !docCategory) {
      BOM.AlertMsg(cnt.WARNING, "Fields with * are required");
      return;
    }

    setDocuments([
      ...documents,
      {
        base64: tempFile,
        docType: "sharing",
        docCategory,
      },
    ]);
    $(".documentInput").val("");
  };
  const removeItem = (index, type) => {
    switch (type.toLowerCase()) {
      case "document":
        setDocuments([
          ...documents.slice(0, index),
          ...documents.slice(index + 1),
        ]);
        break;
      case "material":
        setSelectedInvestors([
          ...selectedInvestors.slice(0, index),
          ...selectedInvestors.slice(index + 1),
        ]);
        break;
    }
  };
  const showModal = () => {
    console.log("set show");
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  const onAddInvestor = () => {
    if (!investorId) {
      BOM.AlertMsg(cnt.WARNING, "Please choose an Investor.");
      return;
    }

    let investorObj = companyData.find(
      (item) => item.COMPANY_ID === investorId
    );

    setSelectedInvestors(
      BOM.GetUnique(
        [
          ...selectedInvestors,
          {
            id: investorId,
            investorObj,
          },
        ],
        "id"
      )
    );

    $(".investorInput").val("");
  };
  const onSelectFundingType = (key) => {
    setIsPrivate(false);
    setIsBlock(false);
    // console.log(key);
    switch (key.toLowerCase()) {
      case "private":
        setIsPrivate(true);
        setIsBlock(true);
        break;

      default:
        setSelectedInvestors([]);
        setIsPrivate(false);
        setIsBlock(true);
        break;
    }
  };
  const onCalculate = () => {
    if (
      logisticsOtherCosts === undefined ||
      supplierQuote === undefined ||
      fundsAvailable === undefined
    ) {
      BOM.AlertMsg(
        cnt.WARNING,
        `${appLabel.logisticsOtherCosts}, ${appLabel.supplierQuote}, and ${appLabel.fundsAvailable} fields are required.`
      );
      return;
    }
    //for loading and calculation screens
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setIsCalculated(true);
      RAL.disableEnableRightAction([cnt.RIGHT_SAVE_BTN]);
    }, 2000);

    let capitalRq = 0,
      arrangementFee = 0,
      surchargeObj = ggParam.SURCHARGE_DATA;
      

    capitalRq = logisticsOtherCosts + supplierQuote - fundsAvailable;

    if (capitalRq <= 0) {
        capitalRq = parseFloat(ggParam.PO_AMOUNT);
    }

    /** SURCHARGE CALCUL LOGIC */
    switch (Object.keys(surchargeObj).length === 0) { // check if the obj is empty
      case true:
            /** no further action needed */
        break;
    
      default:
          let chargeRate = parseFloat(surchargeObj.CHARGE_PERCENTAGE, 10) / 100;
          let maxChargeAmt = parseFloat(surchargeObj.CHARGE_LIMIT, 10);

          arrangementFee = capitalRq * chargeRate;

          if (arrangementFee > maxChargeAmt) {
              arrangementFee = maxChargeAmt;
          }
        break;
    }
    

    //prepare keys for the final request
    setValue("arrangementFee", arrangementFee);
    setValue("capitalRequired", capitalRq);
    setCapitalRequired(parseFloat(capitalRq, 10));

    //get fee based on requested amount
    getFeeList(capitalRq + "");

    // mergedDataArr
    setAutoFldData([
      {
        name: appLabel.capitalRequired,
        value: Number.isNaN(capitalRq) ? 0 : parseFloat(capitalRq, 10),
        fldType: "amount",
        section: "general",
        toolTip: toolTip.capitalRequired,
      },
      {
        name: appLabel.arrangementFees,
        value: arrangementFee,
        fldType: "amount",
        section: "general",
        toolTip: null,
      },
    ]);

    getAmountReceivable();
  };
  function getFeeList(loanAmount) {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      feeId: "",
      ccy: ggParam.PO_CCY,
      companyTypeId,
      loanAmount,
      criteria: "",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetFees, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.feeListing;

        setOtherFee1Data(
          objList.filter(
            (item) => item.FEE_OPTION.toLowerCase() === "other fee 1"
          )
        );
        setOtherFee2Data(
          objList.filter(
            (item) => item.FEE_OPTION.toLowerCase() === "other fee 2"
          )
        );
      }
    });
  }
  const checkIsCalculated = () => {
    setIsCalculated(false);
    setAmountPayable1(0);
    setAmountPayable2(0);
    RAL.disableEnableRightAction();
  };
  const onSelectOtherFee = (idParam, setParam, arrParam, keyFee) => {
    setParam(0);
    setAmountReceivable(amountReceivable);
    let tempCalc = 0;

    if (!idParam) {
      switch (keyFee) {
        case "fee1":
          setAmountReceivable(amtPybles.amtPay1 + amountReceivable);
          amtPybles.amtPay1 = 0;
          break;
        case "fee2":
          setAmountReceivable(amtPybles.amtPay2 + amountReceivable);
          amtPybles.amtPay2 = 0;
          break;
      }
      return;
    }

    /** AMOUNT PAYABLE  CALCULATION*/
    const feeSelected = arrParam.find((item) => item.FEE_ID === idParam);

    switch (feeSelected.FLG_FEE_SLAB.toLowerCase()) {
      case "n":
        switch (feeSelected.FEE_TYPE.toLowerCase()) {
          case "flat":
            tempCalc = parseFloat(feeSelected.FEE_AMOUNT, 10);
            setParam(tempCalc);
            break;
          case "percentage":
            const tempRate = parseFloat(feeSelected.FEE_PERCENTAGE, 10) / 100;
            tempCalc = capitalRequired * tempRate;
            setParam(tempCalc);
            break;
        }
        break;
      default:
        //regardless of fee type the following will be done

        if (feeSelected.SLAB_DATA.length === 0) {
          switch (feeSelected.FEE_TYPE.toLowerCase()) {
            case "flat":
              tempCalc = parseFloat(feeSelected.FEE_AMOUNT, 10);
              setParam(tempCalc);
              break;
            case "percentage":
              const tempRate = parseFloat(feeSelected.FEE_PERCENTAGE, 10) / 100;
              tempCalc = capitalRequired * tempRate; //+ capitalRequired;
              setParam(tempCalc);
              break;
          }
        } else {
          //We use rate in the slab data rather in percentage
          const { SLAB_DATA } = feeSelected;
          const tempRate = parseFloat(SLAB_DATA[0].FEE_RATE, 10) / 100;
          tempCalc = capitalRequired * tempRate; //+ capitalRequired;
          setParam(tempCalc);
        }
        break;
    }

    getAmountReceivable(tempCalc, keyFee);
  };
  function getAmountReceivable(amtParam = null, keyFee = null) {
    switch (keyFee) {
      case "fee1":
        amtPybles.amtPay1 = amtParam ? amtParam : 0;
        break;
      case "fee2":
        amtPybles.amtPay2 = amtParam ? amtParam : 0;
        break;

      default:
        amtPybles.amtPay1 = 0;
        amtPybles.amtPay2 = 0;
        break;
    }

    const { amtPay1, amtPay2 } = amtPybles;
    const arrgmtFee = parseFloat(getValues("arrangementFee"), 10);
    const captRq = parseFloat(getValues("capitalRequired"), 10);
    const amtReceivable = captRq - (arrgmtFee + amtPay1 + amtPay2);
    setAmountReceivable(amtReceivable);

    // console.log("capitalRequired: ", captRq);
    // console.log("arrgmtFee: ", arrgmtFee);
    // console.log("amtPay1: ", amtPay1);
    // console.log("amtPay2: ", amtPay2);
    // console.log("amtReceivable: ", amtReceivable );
    // console.log(amtPybles);
    // console.log(Object.keys(ggParam.SURCHARGE_DATA).length === 0);
  }
  const formSubmit = (formValues) => {
    if (amountReceivable < 0) {
      BOM.AlertMsg(cnt.WARNING, "Amount Receivable cannot be negative.");
      return;
    }

    //Validate NaN value
    if (BOM.ValidateNaNValue(formValues.interestRate, appLabel.interestRate))
      return;

    if (parseFloat(capitalRequired) > parseFloat(formValues.amount)) {
      BOM.AlertMsg(
        cnt.WARNING,
        appLabel.capitalRequired + " should no exceed the PO Amount."
      );
      return;
    }

    if (documents.length === 0) {
      BOM.AlertMsg(cnt.WARNING, "Document upload is required.");
      return;
    }

    if (isPrivate && selectedInvestors.length === 0) {
      BOM.AlertMsg(cnt.WARNING, "Investor is required.");
      return;
    }

    // extract Id
    let localInvestors = [];
    selectedInvestors.forEach((element) => {
      localInvestors.push({ id: element.id });
    });

    formValues.documents = documents;
    formValues.investors = localInvestors;
    formValues.capitalRequired = capitalRequired + "";
    formValues.fundsAvailable = fundsAvailable + "";
    formValues.logisticsOtherCosts = logisticsOtherCosts + "";
    formValues.supplierQuote = supplierQuote + "";
    formValues.amtPayable1 = amountPayable1 + "";
    formValues.amtPayable2 = amountPayable2 + "";
    formValues.arrangementFee = formValues.arrangementFee + "";
    formValues.amount = formValues.amount + "";

    let body = {
      profileId,
      session,
      ...formValues,
    };

    //check if custodian is selected

    let findCustodian = selectedInvestors.find(
      ({ investorObj }) => investorObj.COMPANY_TYPE_ID === "CT2022012400000"
    );
    // if (!findCustodian) {
    if (isPrivate) {
      Notiflix.Confirm.show(
        "Are you sure?",
        notif.notif1,
        "Proceed",
        "Cancel",
        function () {
          // Yes
          // console.log("proceed: ", findCustodian);
          // console.log(body);
          // return;
          /** SEND REQ */
          BOM.LoadAlert(cnt.LOAD, "Processing");
          BOM.SendReqAction(body, api.PoShare, "/manage-order");
        },
        function () {
          // No button
          // console.log("cancels: ", findCustodian);
        }
      );
    } else {
      // console.log(body);
      // return;
      /** SEND REQ */
      BOM.LoadAlert(cnt.LOAD, "Processing");
      BOM.SendReqAction(body, api.PoShare, "/manage-order");
    }
  };

  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <RightSide />
        <ContentTop pageTitle={appLabel.newRqFunding} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{appLabel.orderInfo}</h5>
                  <div className="ibox-tools">
                    <Link to="/manage-order" className="btn btn-primary btn-xs">
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.selectRequestType}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="col-sm-6 m-b-xs">
                        <div data-toggle="buttons" className="btn-group">
                          {appStatic.requestFundingType.map((item) => (
                            <label
                              onClick={() => {
                                onSelectFundingType(item.value);
                                setValue("shareType", item.value);
                              }}
                              className="btn btn-sm btn-white"
                              key={item.value}
                            >
                              {" "}
                              <input type="radio" />{" "}
                              <i
                                className={`fa fa-${
                                  item.value === "private" ? "user" : "users"
                                }`}
                              />{" "}
                              &nbsp; {item.name}{" "}
                            </label>
                          ))}
                        </div>
                      </div>
                      <div className={`col-sm-6 ${isBlock ? "" : "hide"}`}>
                        <a className="btn btn-primary btn-rounded btn-block">
                          <i
                            className={`text-warning fa fa-${
                              isPrivate ? "user" : "users"
                            }`}
                          />{" "}
                          &nbsp; {isPrivate ? "Private " : "Public "} Funding
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className={`row ${isBlock ? "" : "hide"}`}>
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.generalInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="col-sm-12">
                        <div className="row form-group col-md-6 ">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.orderId}&nbsp;
                              {/* <span className="asterisks">*</span> */}
                            </label>
                          </div>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control input-sm"
                              disabled
                              placeholder={appLabel.orderId}
                              {...register("orderId", {
                                required: "Field is required.",
                              })}
                            />
                            <span className="asterisks">
                              {errors?.orderId?.message}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="row form-group col-md-6 ">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.from}&nbsp;
                              {/* <span className="asterisks">*</span> */}
                            </label>
                          </div>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control input-sm"
                              {...register("fromCompany", {
                                required: "Field is required.",
                              })}
                              disabled
                            />
                            <span className="asterisks">
                              {errors?.fromCompany?.message}
                            </span>
                          </div>
                        </div>
                        <div className="row form-group col-md-6 ">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.to}&nbsp;
                              {/* <span className="asterisks">*</span> */}
                            </label>
                          </div>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control input-sm"
                              {...register("toCompany", {
                                required: "Field is required.",
                              })}
                              disabled
                            />
                            <span className="asterisks">
                              {errors?.toCompany?.message}
                            </span>
                          </div>
                        </div>
                        <div className="row form-group col-md-6">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.poAmount}&nbsp;
                              {/* <span className="asterisks">*</span> */}
                            </label>
                          </div>
                          <div className="col-md-7">
                            <NumberFormat
                              className="form-control input-sm"
                              value={getValues("amount")}
                              displayType="text"
                              disabled
                              thousandSeparator={true}
                              prefix={" "}
                            />
                            <span className="asterisks">
                              {errors?.amount?.message}
                            </span>
                          </div>
                        </div>
                        <div className="row form-group col-md-6 ">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.currency}&nbsp;
                              {/* <span className="asterisks">*</span> */}
                            </label>
                          </div>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control input-sm"
                              {...register("currency", {
                                required: "Field is required.",
                              })}
                              disabled
                            />
                            <span className="asterisks">
                              {errors?.currency?.message}
                            </span>
                          </div>
                        </div>
                        {/* <div className="row form-group col-md-6">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.capitalRequired}&nbsp;
                              <Tooltips title={toolTip.capitalRequired} />
                              <span className="asterisks">*</span>
                            </label>
                          </div>
                          <div className="col-md-7">
                            <AmountFacility
                              setIsRequiredField={setIsCapitalRqdFld}
                              setAmount={setCapitalRequired}
                              isRequiredField={isCapitalRqdFld}
                              callback={onCalculate}
                            />
                          </div>
                        </div> */}

                        <div className="row form-group col-md-6">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.supplierQuote}&nbsp;
                              <Tooltips title={toolTip.supplierQuote} />
                              <span className="asterisks">*</span>
                            </label>
                          </div>
                          <div className="col-md-7">
                            <AmountFacility
                              setIsRequiredField={setIsQuoteRqdFld}
                              setAmount={setSupplierQuote}
                              isRequiredField={isQuoteRqdFld}
                              callbackFunc={() => checkIsCalculated(false)}
                            />
                          </div>
                        </div>
                        <div className="row form-group col-md-6">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.duration}&nbsp;
                              <Tooltips title={toolTip.duration} />
                              <span className="asterisks">*</span>
                            </label>
                          </div>
                          <div className="col-md-7">
                            <input
                              type="number"
                              className="form-control input-sm"
                              placeholder={appLabel.duration}
                              {...register("tenor", {
                                required: "Field is required.",
                              })}
                            />
                            <span className="asterisks">
                              {errors?.tenor?.message}
                            </span>
                          </div>
                        </div>
                        <div className="row form-group col-md-6">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.logisticsOtherCosts}&nbsp;
                              <Tooltips title={toolTip.logisticsOtherCosts} />
                              <span className="asterisks">*</span>
                            </label>
                          </div>
                          <div className="col-md-7">
                            <AmountFacility
                              setIsRequiredField={setIsLogisticsRqdFld}
                              setAmount={setLogisticsOtherCosts}
                              isRequiredField={isLogisticsRqdFld}
                              callbackFunc={() => checkIsCalculated(false)}
                            />
                          </div>
                        </div>
                        <div className="row form-group col-md-6">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.interestRate}&nbsp;
                              <Tooltips title={toolTip.interestRate} />
                              <span className="asterisks">*</span>
                            </label>
                          </div>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control input-sm"
                              placeholder={appLabel.interestRate}
                              {...register("interestRate", {
                                required: "Field is required.",
                                min: {
                                  value: 1,
                                  message: "Rate cannot be less than 1%",
                                },
                                max: {
                                  value: 100,
                                  message: "Rate cannot be greater than 100%",
                                },
                              })}
                            />
                            <span className="asterisks">
                              {errors?.interestRate?.message}
                            </span>
                          </div>
                        </div>
                        <div className="row form-group col-md-6">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.fundsAvailable}&nbsp;
                              <Tooltips title={toolTip.fundsAvailable} />
                              <span className="asterisks">*</span>
                            </label>
                          </div>
                          <div className="col-md-7">
                            <AmountFacility
                              setIsRequiredField={setIsFundsRqdFld}
                              setAmount={setFundsAvailable}
                              isRequiredField={isFundsRqdFld}
                              callbackFunc={() => checkIsCalculated(false)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {!isCalculated && (
                      <Col12BlockFacility>
                        <div className="col-md-6">
                          <button
                            type="button"
                            onClick={() => onCalculate()}
                            className={`btn btn-xs ${
                              isLoading ? "btn-white" : "btn-success"
                            }`}
                          >
                            {/* <i className="fa fa-refresh" /> */}
                            <i
                              className={`fa ${
                                isLoading
                                  ? "fa-spinner fa-spin text-navy"
                                  : "fa-refresh"
                              }`}
                            />
                            &nbsp;{" "}
                            {isLoading
                              ? "Calculating..."
                              : appLabel.displayFeeBtn}
                          </button>
                        </div>
                      </Col12BlockFacility>
                    )}
                    {isCalculated && (
                      <Col12BlockFacility topTitle={appLabel.calculationInfo}>
                        <div className="row form-group col-md-6">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.otherFee1}&nbsp;
                            </label>
                          </div>
                          <div className="col-md-7">
                            <select
                              className="form-control input-sm"
                              onChange={(e) =>
                                onSelectOtherFee(
                                  e.target.value,
                                  setAmountPayable1,
                                  otherFee1Data,
                                  "fee1"
                                )
                              }
                            >
                              <option value="">{appLabel.choose}</option>
                              {otherFee1Data.map((item, index) => (
                                <option value={item.FEE_ID} key={index}>
                                  {item.FEE_NAME}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="row form-group col-md-6">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.otherFee2}&nbsp;
                            </label>
                          </div>
                          <div className="col-md-7">
                            <select
                              className="form-control input-sm"
                              onChange={(e) =>
                                onSelectOtherFee(
                                  e.target.value,
                                  setAmountPayable2,
                                  otherFee2Data,
                                  "fee2"
                                )
                              }
                            >
                              <option value="">{appLabel.choose}</option>
                              {otherFee2Data.map((item, index) => (
                                <option value={item.FEE_ID} key={index}>
                                  {item.FEE_NAME}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="row form-group col-md-6 ">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.amountPayable1}
                            </label>
                          </div>
                          <div className="col-md-7">
                            <NumberFormat
                              className="form-control input-sm"
                              value={amountPayable1}
                              displayType="text"
                              disabled
                              thousandSeparator={true}
                            />
                          </div>
                        </div>
                        <div className="row form-group col-md-6 ">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.amountPayable2}
                            </label>
                          </div>
                          <div className="col-md-7">
                            <NumberFormat
                              className="form-control input-sm"
                              value={amountPayable2}
                              displayType="text"
                              disabled
                              thousandSeparator={true}
                            />
                          </div>
                        </div>
                        {autoFldData.map((item, index) => (
                          <div key={index}>
                            {item.section === "general" &&
                              (item.fldType === "amount" ? (
                                <InputAmountFacility
                                  inputLabel={item.name}
                                  inputValue={item.value}
                                  toolTipLabel={item.toolTip}
                                />
                              ) : (
                                <InputTextFacility
                                  inputLabel={item.name}
                                  inputValue={item.value}
                                  toolTipLabel={item.toolTip}
                                />
                              ))}
                          </div>
                        ))}
                        <div className="row form-group col-md-6 ">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.amountReceivable}
                            </label>
                          </div>
                          <div className="col-md-7">
                            <NumberFormat
                              className="form-control input-sm"
                              value={amountReceivable}
                              displayType="text"
                              disabled
                              thousandSeparator={true}
                            />
                          </div>
                        </div>
                        {/* <NoDataFac arrList={autoFldData} /> */}
                      </Col12BlockFacility>
                    )}
                  </div>
                  <div className={`row ${isBlock ? "" : "hide"}`}>
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.otherInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <ul className="nav nav-tabs">
                        <li className={` ${isPrivate ? "active" : "hide"}`}>
                          <a
                            data-toggle="tab"
                            href="#investorInfo"
                            target="_self"
                          >
                            <i className="fa fa-tag text-navy" />
                            &nbsp;{appLabel.investorInfo}&nbsp;
                            <Tooltips title={toolTip.investorInfo} />
                          </a>
                        </li>
                        <li className={` ${isPrivate ? "" : "active"}`}>
                          <a
                            data-toggle="tab"
                            href="#documentInfo"
                            target="_self"
                          >
                            <i className="fa fa-file-pdf-o text-navy" />
                            &nbsp;{appLabel.documentInfo}
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div
                          className={` ${
                            isPrivate ? "tab-pane active" : "hide"
                          }`}
                          id="investorInfo"
                        >
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-12">
                              <div className="row">
                                <AppModal
                                  show={show}
                                  title={"Add Multiple"}
                                  handleClose={() => hideModal()}
                                  handleSave={() => onAddInvestor()}
                                >
                                  <div className=" form-group col-md-6">
                                    <label className="al-subtitle">
                                      {appLabel.investorName}&nbsp;
                                      <span className="asterisks">*</span>
                                    </label>
                                    <select
                                      className="form-control input-sm investorInput"
                                      onChange={(e) =>
                                        setInvestorId(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {investors.map((item, index) => (
                                        <option
                                          value={item.COMPANY_ID}
                                          key={index}
                                        >
                                          {item.COMPANY_NAME}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </AppModal>

                                {!show && (
                                  <div className="col-sm-2">
                                    <div className="title-action">
                                      <button
                                        type="button"
                                        title="Add more Materials"
                                        className="btn btn-primary btn-sm"
                                        onClick={() => showModal()}
                                      >
                                        <i className="fa fa-plus" />
                                      </button>
                                      <div
                                        className="hr-line"
                                        style={{ marginBottom: "10px" }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="table-responsive">
                                {selectedInvestors.length === 0 ? (
                                  <div className="alert alert-warning text-center">
                                    <a className="alert-link">
                                      {appLabel.noDataInTableMsg}
                                    </a>
                                  </div>
                                ) : (
                                  <div>
                                    <table
                                      className="footable table table-stripped toggle-arrow-tiny"
                                      data-page-size="10"
                                      data-filter="#filter"
                                    >
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>{appLabel.investorId}</th>
                                          <th>{appLabel.investorName}</th>
                                          <th>{appLabel.companyRegNo}</th>
                                          <th>{appLabel.companyType}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {selectedInvestors.map(
                                          (item, index) => (
                                            <tr className="gradeX" key={index}>
                                              <td>
                                                {index + 1}{" "}
                                                <i
                                                  style={{ cursor: "pointer" }}
                                                  className="fa fa-times-circle text-danger fa-2x"
                                                  onClick={() =>
                                                    removeItem(
                                                      index,
                                                      "material"
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>{item.id}</td>
                                              <td>
                                                {item.investorObj.COMPANY_NAME}
                                              </td>
                                              <td>
                                                {item.investorObj.COMPANY_REGNO}
                                              </td>
                                              <td>
                                                {
                                                  item.investorObj
                                                    .COMPANY_TYPE_NAME
                                                }
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td colSpan="5">
                                            <ul className="pagination pull-right"></ul>
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={` ${
                            isPrivate ? "tab-pane" : "tab-pane active"
                          }`}
                          id="documentInfo"
                        >
                          <div className="row" style={{ marginTop: "30px" }}>
                            <div className="col-sm-12">
                              <div className="col-md-6">
                                <div className="row form-group has-errorr">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle control-label">
                                      {appLabel.documentType}{" "}
                                      <Tooltips title={toolTip.documentType} />
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <select
                                      className="form-control input-sm documentInput"
                                      onChange={(e) =>
                                        setDocCategory(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {appStatic.documntTypesSharePo.map(
                                        (item, index) => (
                                          <option value={item.id} key={index}>
                                            {item.name}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="row form-group">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle ">
                                      {appLabel.documentUpload}{" "}
                                      <Tooltips
                                        title={toolTip.documentUploadPdf}
                                      />
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <input
                                      type="file"
                                      className="form-control input-sm documentInput"
                                      accept=".pdf"
                                      onChange={(e) =>
                                        handleFileRead(e, setTempFile)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row form-group">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle ">
                                      &nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <button
                                      type="button"
                                      onClick={() => uploadFile()}
                                      className="btn btn-primary btn-xs"
                                    >
                                      <i className="fa fa-upload" />
                                      &nbsp; Upload
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="col-sm-4 al-subtitle"></label>
                                  <div className="col-sm-8">
                                    {documents.map((item, index) => (
                                      <div className="i-checks" key={index}>
                                        <label className="control-label">
                                          <i
                                            style={{ cursor: "pointer" }}
                                            className="fa fa-times-circle text-danger fa-2x"
                                            onClick={() =>
                                              removeItem(index, "document")
                                            }
                                          />
                                          &nbsp; &nbsp;
                                          <i className="fa fa-file-pdf-o fa-2x text-info" />
                                          &nbsp; {item.docCategory}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <RecordStatus {...userDetail} /> */}
        </div>
      </form>
      <RecordStatus {...BOM.NewRecordStatusdata()} />
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  configData: state.auth.configData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewSharedOrder);
