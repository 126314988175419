import React, { useEffect, useState } from "react";
import { apis as api } from "../../services/api.action";
import { connect, useDispatch } from "react-redux";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../ui-setup/RecordStatus";
import appStatic from "../../config/appStaticData";
import NumberFormat from "react-number-format";
import { Col12BlockFacility } from "../layouts/Facility";
import { Parser as HtmlToReactParser } from "html-to-react";
import contractDb from "../configuration/termsCondition/contractDb.json";

//react pdf viewer imports
import { ReactPdfViewer } from "../layouts/Facility";

var htmlToReactParser = new HtmlToReactParser();

export const ContractDetail = (props) => {
  const { location, userData, configData, recordData, menuData } = props;
  const { state } = location;
  const { profileId, session, companyId } = userData;
  const { isCsd } = configData;
  const { privacyPolicyDocumentData } = appStatic;

  const dispatch = useDispatch();
  const [isCsdCheck, setIsCsdCheck] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [contractDetail, setContractDetail] = useState({});
  const [isOrder, setIsOrder] = useState(false);
  const [isInvoice, setIsInvoice] = useState(false);
  const [obgDetail, setObgDetail] = useState([]);
  const [file, setFile] = useState(privacyPolicyDocumentData[6].link);
  const [isShowDoc, setIsShowDoc] = useState(false);

  useEffect(() => {
    // console.log(state.ggParam);
    if (isCsd.toLowerCase() === "y") {
      setIsCsdCheck(true);
    }
    getDetailById(state.ggParam);
  }, []);

  const handleShowDocument = () => {
    setIsShowDoc(!isShowDoc);
  };
  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      contractId: params.CONTRACT_ID,
      companyId,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetContract, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let contractDetail = res.contractDetail;

        setContractDetail(contractDetail);
        // setObgDetail(contractDetail.CONTR_OBLIGATIONS)
        onChooseType(contractDetail.TRANS_TYPE);
        dispatch({
          type: cnt.RECORD_STATUS,
          payload: contractDetail,
        });

        //handle right side
        // BOM.IsApproveOrReject(contractDetail.FLG_STATUS, menuData, "Manage Contract");

        //get document
        getSpecDoc(state.ggParam);
      }
    });
  };
  const getSpecDoc = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      id: params.CONTRACT_ID,
      type: "contract",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setDocuments(res.docListing);
      }
    });
  };
  const onChooseType = (key) => {
    switch (key.toLowerCase()) {
      case "po":
        setIsOrder(true);
        setIsInvoice(false);
        break;
      case "inv":
        setIsInvoice(true);
        setIsOrder(false);
        break;
    }
  };

  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.contractDetail} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{appLabel.contractInformation}</h5>
                <div className="ibox-tools">
                  <Link
                    to="/manage-contract"
                    className="btn btn-primary btn-xs"
                  >
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;
                    {appLabel.backBtn}
                  </Link>
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.generalInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.type}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={contractDetail.TRANS_TYPE}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.contractId} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={contractDetail.CONTRACT_ID}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.from}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={contractDetail.BUYER_NAME}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.to}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={contractDetail.SUPPLIER_NAME}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.funder}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={contractDetail.FUNDER_NAME}
                          disabled
                        />
                      </div>
                    </div>

                    <div
                      className={`row form-group col-md-6 ${
                        isOrder ? "" : "hide"
                      }`}
                    >
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.poNo}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={contractDetail.PO_NUMBER}
                          disabled
                        />
                      </div>
                    </div>
                    <div
                      className={`row form-group col-md-6 ${
                        isInvoice ? "" : "hide"
                      }`}
                    >
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.invoiceId}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={contractDetail.INVOICE_ID}
                          disabled
                        />
                      </div>
                    </div>

                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.currency}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={contractDetail.CONTR_CCY}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.dateInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />

                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.fundingDate}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={contractDetail.TRANSACTION_DATE}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.maturityDate}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={contractDetail.MATURITY_DATE}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.feeInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.principalAmount}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={contractDetail.PRINCIPAL}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.arrangementFees}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={contractDetail.ARRANGEMENT_FEE}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>

                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.otherFees}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={contractDetail.OTHER_FEES}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.interestAmount}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={contractDetail.INTEREST_AMOUNT}
                          disabled
                        />
                      </div>
                    </div>
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.costOfFinance}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={contractDetail.COST_OF_FINANCE}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.totalRepayment}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={contractDetail.TOTAL_REPAYMENT}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                  </div>
                  <Col12BlockFacility topTitle={appLabel.contractAddendum}>
                    <div className="col-sm-12 text-center">
                      <a
                        className={` btn-xs m-b btn btn-${isShowDoc ? 'warning':'primary'}`}
                        onClick={() => setIsShowDoc(!isShowDoc)}
                      >
                        {`${isShowDoc ? 'Hide' :'View'} Contract Document`} &nbsp;
                        <i
                          className={`fa fa-long-arrow-${
                            isShowDoc ? "up" : "down"
                          }`}
                        />
                      </a>
                    </div>
                    <div className="col-md-10 col-md-offset-1">

                    {isShowDoc && <ReactPdfViewer fileParam={file} />}
                    </div>
                  </Col12BlockFacility>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RecordStatus {...recordData} />
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ContractDetail);
