import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Notiflix from "notiflix";
import Main from "../layouts/Main";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { apis as api } from "../../services/api.action";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import { Tooltips } from "../layouts/Facility";
import Select from "react-select";


const AuditTrail = (props) => {
  const { userData } = props;
  const { profileId, session, companyId } = userData;
  const globalVar = window.globalData;
  const dispatch = useDispatch();
  const { toolTip } = appStatic;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [usersData, setUsersData] = useState([]);
  const [audiUserData, setAudiUserData] = useState([]);
  requiredJqueryDOM()
  useEffect(() => {
    // console.log(props);
    getUser();
    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);
  }, []);

  function requiredJqueryDOM() {
    let d = new Date();
    d.setMonth(d.getMonth() - 1); // search only for 1 month
    let monthNumber =  BOM.ReformatDate(d.toLocaleDateString());

    // console.log("monthNumber ", monthNumber);
    $(document).ready(function () {

      $("#startDate").datepicker({
        todayBtn: "linked",
        keyboardNavigation: false,
        forceParse: false,
        calendarWeeks: true,
        autoclose: true,
        startDate: new Date(monthNumber),
        endDate: new Date(BOM.CurrentDate())
      });

      $("#endDate").datepicker({
        todayBtn: "linked",
        keyboardNavigation: false,
        forceParse: false,
        calendarWeeks: true,
        autoclose: true,
        startDate: new Date(monthNumber),
        endDate: new Date(BOM.CurrentDate())
      });    
    });
  }
  function getUser() {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      userId: "",
      criteria: "A",
      companyId,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetUser, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {

        let objList = res.userListing;
        let temp = [];

        objList.forEach((element) => {
          temp.push({
            value: element.USERID,
            label: element.USERNAME,
            ...element,
          });
        });

        setUsersData(temp);

      }
    });
  }

  const formSubmit = (formValues) => {

    setAudiUserData([])
    let startDate = document.getElementById("startDate").value;
    let endDate = document.getElementById("endDate").value;

    if (!startDate) {
      BOM.AlertMsg(cnt.WARNING, "Start date is required.");
      return;
    }

    if (!endDate) {
      BOM.AlertMsg(cnt.WARNING, "End date is required.");
      return;
    }

    formValues.startDate = BOM.ReformatDate(startDate);
    formValues.endDate = BOM.ReformatDate(endDate);
    let body = {
      profileId,
      session,
      ...formValues,
    };

    // console.log(body);
    /** FETCH ROLE */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.FetchReqAction(body, api.GetAudit, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        // Notiflix.Loading.remove();
        setAudiUserData(res.auditTrailListing);
        
        switch (true) {
          case res.auditTrailListing.length !== 0:
            BOM.DatatableUsage(res.auditTrailListing[0].PROFILE_ID + "_Audit_Trail");
            break;
        }
        
      }
    });
  };
  /** RENDER JXS DATA */
  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <ContentTop pageTitle={appLabel.auditTrail} />
        <div className="animated fadeInRight">
          <div className="ibox-content m-b-sm border-bottom contentCard panel-primary">
            {/* <div className="row">
              <div className="col-sm-4">
                <div className="form-group">
                  <label className="control-label">
                    <i className="fa fa-search fa-2x text-navy" /> &nbsp;{" "}
                    {appLabel.searchCriteria}
                  </label>
                </div>
              </div>
            </div> */}

            <div className="row">
              <div className="col-sm-12">
                <label className="al-heading">
                  <i className="fa fa-search text-navy"></i>&nbsp;{" "}
                  {appLabel.searchCriteria}
                </label>
                <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="control-label" htmlFor="amount">
                      {appLabel.userName}&nbsp;<span className="asterisks">*</span>
                    </label>
                    {/* <select
                      className="form-control input-sm"
                      {...register("userId", {
                        required: "Field is required.",
                      })}
                    >
                      <option value="">{appLabel.choose}</option>
                      {usersData.map((item, index) => (
                        <option value={item.USERID} key={index}>
                          {item.USERNAME}
                        </option>
                      ))}
                    </select> */}
                    <Select
                          className="basic-single"
                          classNamePrefix="select"
                          options={usersData}
                          onChange={(e)=>setValue("userId", e.USERID)}
                        />
                    <span className="asterisks">{errors?.userId?.message}</span>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="control-label" htmlFor="startDate">
                      {appLabel.startDate}&nbsp;<Tooltips title={toolTip.startDate} /><span className="asterisks">*</span>
                    </label>
                    <div className="input-group date">
                      <span className="input-group-addon">
                        <i className="fa fa-calendar" />
                      </span>
                      <input
                        id="startDate"
                        type="text"
                        className="form-control"
                      />
                      <span className="asterisks">
                        {errors?.startDate?.message}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="control-label" htmlFor="endDate">
                      {appLabel.endDate}&nbsp;<Tooltips title={toolTip.endDate} /><span className="asterisks">*</span>
                    </label>
                    <div className="input-group date">
                      <span className="input-group-addon">
                        <i className="fa fa-calendar" />
                      </span>
                      <input
                        id="endDate"
                        type="text"
                        className="form-control"
                      />
                      <span className="asterisks">
                        {errors?.endDate?.message}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="control-label">&nbsp;</label>
                    <div className="input-group date">
                      <button type="submit" className="btn btn-primary btn-sm">
                        <i className="fa fa-search" />
                        &nbsp;
                        {appLabel.search}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <label className="al-heading">
                  <i className="fa fa-list text-navy"></i>&nbsp;{" "}
                  {appLabel.result}
                </label>
                <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                <div className="table-responsive">
                  {audiUserData.length === 0 ? (
                    <div className="alert alert-warning text-center">
                      <a className="alert-link">{appLabel.noDataInTableMsg}</a>
                    </div>
                  ) : (
                   <div>
                     <div className="table-responsive">
                        
                     <table
                        id="myTable"
                        className="table table-striped table-bordered table-hover"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{appLabel.auditDate}</th>
                          <th>{appLabel.event}</th>
                          <th>{appLabel.actionPerformed}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {audiUserData.map((item, index) => (
                          <tr className="gradeX" key={index}>
                            <td>{index + 1}</td>
                            <td>{item.AUDIT_DATE}</td>
                            <td>{item.EVENT}</td>
                            <td>{item.ACTION_PERFORMED}</td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                      <tr>
                          <th>#</th>
                          <th>{appLabel.auditDate}</th>
                          <th>{appLabel.event}</th>
                          <th>{appLabel.actionPerformed}</th>
                        </tr>
                      </tfoot>
                    </table>
                     </div>
                   </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(AuditTrail);
