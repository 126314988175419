import React, { useEffect, useState } from "react";
import axios from "axios";
import Notiflix from "notiflix";
import { apis as api } from "../../../services/api.action";
import { connect, useDispatch } from "react-redux";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../../ui-setup/RecordStatus";
import appStatic from "../../../config/appStaticData";
import appConfig from "../../../config/appConfig.json";

import NumberFormat from "react-number-format";

export const LoanDetail = (props) => {
  const { location, userData, configData, recordData, menuData, authBuffer } =
    props;
  const { state } = location;
  const { profileId, session, companyId } = userData;
  const { isCsd } = configData;

  const dispatch = useDispatch();
  const [isCsdCheck, setIsCsdCheck] = useState(false);
  const [loanDetail, setLoanDetail] = useState({});
  const [loanSummary, setLoanSummary] = useState({});
  const [loanNarration, setLoanNarration] = useState({});
  const [loanTimeline, setLoanTimeline] = useState({});
  const [loanCurrency, setLoanCurrency] = useState({});
  const [loanRepaymentSchedule, setLoanRepaymentSchedule] = useState({});
  const [statusDetail, setStatusDetail] = useState({});
  const [dateArrToObj, setDateArrToObj] = useState("0000-00-00");

  useEffect(() => {
    // console.log(state.ggParam);
    if (isCsd.toLowerCase() === "y") {
      setIsCsdCheck(true);
    }
    getDetailById(state.ggParam);
  }, []);

  const getDetailById = (params) => {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      loanId: params.id + "",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetLoanPortfolioDetails, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const loanDetails = res.loanData;

        setLoanDetail(loanDetails);
        setLoanSummary(loanDetails.summary ? loanDetails.summary :{});
        setLoanNarration(loanDetails.loanNarration ? loanDetails.loanNarration : {});
        setLoanTimeline(loanDetails.timeline ? loanDetails.timeline : {});
        setLoanCurrency(loanDetails.currency ? loanDetails.currency : {});
        setLoanRepaymentSchedule(loanDetails.repaymentSchedule ? loanDetails.repaymentSchedule : {});
        setStatusDetail(loanDetails.status ? loanDetails.status : {});
      }
    });
  };
  const convertDateArrayToObj = (arrParam)=>{

    // console.log(arrParam);
    let stringDate, arrDate;
    arrDate = arrParam ? arrParam : [];
    let year = arrDate[0];
    let month = arrDate[1];
    let day = arrDate[2];

    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    stringDate = year + "-" + month + "-" + day
    return arrParam ? stringDate.toString() : "0000-00-00"
  }

  // return <div>Loan details....</div>
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.loanDetail} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{appLabel.loanInfo}</h5>
                <div className="ibox-tools">
                  <Link
                    to="/manage-loan-portfolio"
                    className="btn btn-primary btn-xs"
                  >
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;
                    {appLabel.backBtn}
                  </Link>
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.generalInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.loanId}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={loanDetail.accountNo}
                          disabled
                        />
                      </div>
                    </div>
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.status} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={statusDetail.value}
                          disabled
                        />
                      </div>
                    </div>
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.poNo} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={loanNarration.orderId}
                          disabled
                        />
                      </div>
                    </div>
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.from} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={loanNarration.buyer}
                          disabled
                        />
                      </div>
                    </div>
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.to} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={loanNarration.supplier}
                          disabled
                        />
                      </div>
                    </div>
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.funder} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={loanNarration.funder}
                          disabled
                        />
                      </div>
                    </div>
                    
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.currency} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={loanCurrency.code}
                          disabled
                        />
                      </div>
                    </div>
                    {/* <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.disbursedAmount} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={loanDetail.principalDisbursed}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div> */}
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.noOfRepayments} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={loanDetail.numberOfRepayments}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                  <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.feeInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.currentBalance}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={loanSummary.totalOutstanding}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.interestAmount} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={loanDetail.annualInterestRate}
                          disabled
                        />
                      </div>
                    </div>
                    
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.costOfFinance} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                      <NumberFormat
                          className="form-control input-sm"
                          value={loanNarration.costOfFinance}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.totalRepayment} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={loanNarration.totalAmount}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.dateInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.approvedOn} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          value={convertDateArrayToObj(loanTimeline.approvedOnDate)}                      
                          disabled
                        />
                      </div>
                    </div>
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.disbursementDate} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          value={convertDateArrayToObj(loanTimeline.actualDisbursementDate)}
                          disabled
                        />
                      </div>
                    </div>
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.valueDate} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                      <input
                          type="text"
                          className="form-control input-sm"
                          value={convertDateArrayToObj(loanNarration.paymentDate)}                      
                          disabled
                        />
                      </div>
                    </div>
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.maturityDate} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                      <input
                          type="text"
                          className="form-control input-sm"
                          value={convertDateArrayToObj(loanTimeline.expectedMaturityDate)}                  
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.otherPoFeesInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />

                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.principal} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={loanDetail.principal}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                    {/* <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.interest} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                      <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={loanDetail.totalInterestCharged}
                          disabled
                        />
                      </div>
                    </div> */}
                    {/* <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.arrangementFees} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={loanDetail.feeChargesCharged}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div> */}
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.otherFees} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={loanSummary.feeChargesCharged}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.outstanding} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={loanSummary.principalDisbursed}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.overdue} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={loanSummary.totalOverdue}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <hr />
                    <div className={`col-md-7`}></div>
                    <div className={`col-md-4`}>
                      <div className={`row form-group col-md-12`}>
                        <label className="al-subtitle">
                          {appLabel.totalAmount} &nbsp;
                        </label>
                        <NumberFormat
                          className="form-control input-sm"
                          value={loanSummary.totalExpectedRepayment}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
  authBuffer: state.auth.authBuffer,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LoanDetail);
