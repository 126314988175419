import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "./Report.css";
import Main from "../layouts/Main";
import { useForm } from "react-hook-form";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import NumberFormat from "react-number-format";
import { apis as api } from "../../services/api.action";
import appStatic from "../../config/appStaticData";
import appLabel from "../../config/appLabel";
import {
  TableFacility,
  Tooltips,
  AmntFormatFac,
  Col12BlockFacility,
} from "../layouts/Facility";

export const DownloadBidReport = (props) => {
  const { tableData, toolTip, reporting } = appStatic;
  const { bidLabelData } = reporting;
  const {
    register,
    handleSubmit,
    unregister,
    formState: { errors },
    setValue,
  } = useForm();

  const { userData } = props;
  const { profileId, session, companyId } = userData;
  const [companyData, setCompanyData] = useState([]);
  const [bidDownload, setBidDownload] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [labelData, setLabelData] = useState([]);
  const [isDateRange, setIsDateRange] = useState(false);
  const [isItemId, setIsItemId] = useState(false);
  const [itemTypeData, setItemTypeData] = useState([]);
  const [selectedItemObj, setSelectedItemObj] = useState({});
  requiredJqueryDOM();
  useEffect(() => {
    // getCompany();
    setLabelData(bidLabelData);
  }, []);
  function requiredJqueryDOM() {
    let d = new Date();
    d.setMonth(d.getMonth() - 3);
    let lastThreeMonth = BOM.ReformatDate(d.toLocaleDateString());

    // console.log("lastThreeMonth ", lastThreeMonth);
    $(document).ready(function () {
      $(".startDate").datepicker({
        todayBtn: "linked",
        keyboardNavigation: false,
        forceParse: false,
        calendarWeeks: true,
        autoclose: true,
        // startDate: new Date(lastThreeMonth),
        endDate: new Date(BOM.CurrentDate()),
      });

      $(".endDate").datepicker({
        todayBtn: "linked",
        keyboardNavigation: false,
        forceParse: false,
        calendarWeeks: true,
        autoclose: true,
        // startDate: new Date(lastThreeMonth),
        endDate: new Date(BOM.CurrentDate()),
      });
    });
  }
  const onSelectReport = (idParam) => {
    // console.log(idParam);
    unregister("");
    setItemTypeData([]);
    setLabelData([]);
    setSelectedItemObj({});
    let searchTypeLoc = [{ value: "date", name: "Date Range" }];
    // setIsLoading(true);

    /** SOME LOGIC ON THE SELECTED CARD */
    let newArray = bidLabelData.map((item) =>
      item.id === idParam
        ? { ...item, isSelected: 1 }
        : { ...item, isSelected: 0 }
    );
    setLabelData(newArray);

    // GET THE SELECTED OBJECT
    const selectedObj = newArray.find((item) => item.id === idParam);
    const { itemType } = selectedObj;

    setSelectedItemObj(selectedObj);
    // console.log(selectedObj);

    switch (itemType.toLowerCase()) {
      case "po":
        //add into array
        searchTypeLoc.push({ value: "po", name: "PO No" });
        setItemTypeData(searchTypeLoc);
        break;
      case "invoice":
        //add into array
        searchTypeLoc.push({ value: "invoice", name: "Invoice No" });
        setItemTypeData(searchTypeLoc);
        break;
    }
  };
  const onSelectType = (key) => {
    // console.log(key);
    // CLEAR ALL PREVIOUS TYPE
    setIsDateRange(false);
    setIsItemId(false);
    $(".clearInput").val("");

    switch (key.toLowerCase()) {
      case "date":
        setIsDateRange(true);

        //pre populate finale json
        setValue("criteria", "date");
        break;
      case "po":
      case "invoice":
        setIsItemId(true);

        //pre populate finale json
        setValue("criteria", "itemId");
        break;
      default:
        setIsDateRange(false);
        setIsItemId(false);
        $(".clearInput").val("");
        break;
    }
  };
  const searchBid = (formValues) => {
    // console.log(selectedItemObj);
    setBidDownload([]);
    let startDate = $(".startDate").val();
    let endDate = $(".endDate").val();

    if (!startDate && isDateRange) {
      BOM.AlertMsg(cnt.WARNING, "Start date is required.");
      return;
    }

    if (!endDate && isDateRange) {
      BOM.AlertMsg(cnt.WARNING, "End date is required.");
      return;
    }

    formValues.startDate = startDate ? BOM.ReformatDate(startDate) : "";
    formValues.endDate = endDate ? BOM.ReformatDate(endDate) : "";

    //re-initialise itemType base on parent click.
    formValues.itemType = selectedItemObj.itemType // get it from the parent tile.
    let body = {
      profileId,
      session,
      ...formValues,
    };

    // console.log(body);
    // return;
    /** FETCH ROLE */
    BOM.LoadAlert(cnt.LOAD, "Searching...");
    BOM.FetchReqAction(body, api.GetBiddingReport, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setBidDownload(res.bidListing);
        BOM.DatatableUsage();
      }
    });
  };

  return (
    <Main {...props}>
      <div className="row">
        {labelData.length === 0 && (
          <div className="col-md-12" style={{ marginTop: "20%" }}>
            <div className="alert alert-info text-center">
              <a className="alert-link">{appLabel.downloadEntityMsg}</a>
            </div>
          </div>
        )}
        <div className="col-md-12" style={{ marginTop: "20px" }}>
          {labelData.map((item, index) => (
            <div
              className="col-md-6 col-lg-6"
              key={index}
              onClick={() => onSelectReport(item.id)}
            >
              <div className="ibox">
                <div
                  className={`ibox-content ${
                    item.isSelected ? "reportCardSelected" : "reportCard"
                  }`}
                >
                  <h5>{appLabel.bidReport}</h5>
                  <h3>
                    {" "}
                    <i className={`${item.icon} text-navy`} /> &nbsp;{" "}
                    {item.name}
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </div>
        {isLoading && (
          <div className="col-md-12" style={{ marginTop: "10%" }}>
            <div className="alert alert-defau text-center">
              <i className="fa fa-spinner fa-spin fa-5x text-success" />
            </div>
          </div>
        )}
      </div>
      <div className="animated fadeInRight " style={{ marginTop: "20px" }}>
        {itemTypeData.length !== 0 && (
          <>
            <div className="row">
              <form className="m-t" autoComplete="off">
                <div className="col-lg-12">
                  <div className="ibox-title">
                    <h5>{selectedItemObj.name}</h5>
                  </div>
                  <div className="ibox-content m-b-sm border-bottom contentCard panel-primary">
                    <div className="row">
                      <div className="col-sm-12">
                        <label className="al-heading">
                          <i className="fa fa-search text-navy"></i>&nbsp;{" "}
                          {appLabel.searchCriteria}
                        </label>
                        <div
                          className="hr-line-dashed"
                          style={{ marginTop: 0 }}
                        />
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label className="control-label">
                              {appLabel.fundingType}&nbsp;{" "}
                              <span className="asterisks">*</span>
                            </label>
                            <select
                              className="form-control input-sm"
                              {...register("shareType", {
                                required: "Field is required.",
                              })}
                            >
                              <option value="">{appLabel.choose}</option>
                              {appStatic.requestFundingType.map(
                                (item, index) => (
                                  <option value={item.value} key={index}>
                                    {item.name}
                                  </option>
                                )
                              )}
                            </select>
                            <span className="asterisks">
                              {errors?.shareType?.message}
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label className="control-label">
                              {appLabel.type}&nbsp;
                              <Tooltips title={toolTip.searchType} />
                              <span className="asterisks">*</span>
                            </label>
                            <select
                              className="form-control input-sm"
                              {...register("itemType", {
                                required: "Field is required.",
                                onChange: (e) => onSelectType(e.target.value),
                              })}
                            >
                              <option value="">{appLabel.choose}</option>
                              {itemTypeData.map((item, index) => (
                                <option value={item.value} key={index}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            <span className="asterisks">
                              {errors?.itemType?.message}
                            </span>
                          </div>
                        </div>

                        <div className={`col-sm-3 ${isItemId ? "" : "hide"}`}>
                          <div className="form-group">
                            <label className="control-label">
                              {selectedItemObj.itemType}
                              {" No"}
                              <span className="asterisks">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control input-sm clearInput"
                              placeholder={`${selectedItemObj.itemType} No`}
                              {...register("itemId", {
                                required: isItemId
                                  ? "Field is required."
                                  : false,
                              })}
                            />
                            <span className="asterisks">
                              {errors?.itemId?.message}
                            </span>
                          </div>
                        </div>
                        <div
                          className={`col-sm-3 ${isDateRange ? "" : "hide"}`}
                        >
                          <div className="form-group">
                            <label
                              className="control-label"
                              htmlFor="startDate"
                            >
                              {appLabel.startDate}&nbsp;
                              <Tooltips title={toolTip.startDate} />
                              <span className="asterisks">*</span>
                            </label>
                            <div className="input-group date">
                              <span className="input-group-addon">
                                <i className="fa fa-calendar" />
                              </span>
                              <input
                                id="startDate"
                                type="text"
                                className="form-control input-sm clearInput startDate"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className={`col-sm-3 ${isDateRange ? "" : "hide"}`}
                        >
                          <div className="form-group">
                            <label className="control-label" htmlFor="endDate">
                              {appLabel.endDate}&nbsp;
                              <Tooltips title={toolTip.endDate} />
                              <span className="asterisks">*</span>
                            </label>
                            <div className="input-group date">
                              <span className="input-group-addon">
                                <i className="fa fa-calendar" />
                              </span>
                              <input
                                // id="endDate"
                                type="text"
                                className="form-control input-sm clearInput endDate"
                              />
                            </div>
                          </div>
                        </div>
                        <div className={`col-sm-2`}>
                          <div className="form-group">
                            <label className="control-label">&nbsp;</label>
                            <div className="input-group date">
                              <button
                                type="button"
                                onClick={handleSubmit(searchBid)}
                                className="btn btn-primary btn-sm"
                              >
                                <i className="fa fa-search" />
                                &nbsp;
                                {appLabel.search}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Col12BlockFacility topTitle={appLabel.result}>
                        {bidDownload.length === 0 ? (
                          <div className="alert alert-warning text-center">
                            <a className="alert-link">
                              {appLabel.noDataInTableMsg}
                            </a>
                          </div>
                        ) : (
                          <TableFacility thData={tableData.bidDownloadList}>
                            {bidDownload.map((item, index) => (
                              <tr className="gradeX" key={index}>
                                <td><i className="fa fa-gavel text-success" /></td>
                                <td>{item.FUNDER_NAME}</td>
                                <td>{item.ITEM_TYPE}</td>
                                <td>{item.ITEM_ID}</td>
                                <td>{item.NOTE_NAME}</td>
                                <td>{item.NOTE_NUMBER}</td>
                                <td>{item.NOTE_CODE}</td>
                                <td>
                                  <AmntFormatFac
                                    inputValue={item.REQUESTED_AMOUNT}
                                  />
                                </td>
                                <td>{item.FUNDER_RATE}</td>

                                <td>
                                  <AmntFormatFac
                                    inputValue={item.FUNDER_AMOUNT}
                                  />
                                </td>
                                <td>{item.ALLOTMENT_RATE}</td>
                                <td>
                                  <AmntFormatFac
                                    inputValue={item.ALLOTED_AMOUNT}
                                  />
                                </td>
                              </tr>
                            ))}
                          </TableFacility>
                        )}
                      </Col12BlockFacility>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
        {/* the following will be deleted after */}
        <div className="row">
          <form className="m-t" autoComplete="off">
            {companyData.length !== 0 && (
              <div className="col-lg-12">
                <div className="ibox float-e-margins">
                  <div className="ibox-content contentCard">
                    <TableFacility thData={tableData.downloadReport}>
                      {companyData.map((item, index) => (
                        <tr className="gradeX" key={index}>
                          <td>
                            <label className="myradio-button">
                              <input
                                type="radio"
                                name="radio"
                                // onClick={() => onSelectConfig(item)}
                              />
                              <span className="label-visible">
                                <span className="myfake-radiobutton"></span>
                              </span>
                            </label>
                          </td>
                          <td>{item.COMPANY_ID}</td>
                          <td>{item.COMPANY_NAME}</td>
                          <td>{item.COMPANY_REG_DATE}</td>
                          <td>{item.COMPANY_REGNO}</td>
                          <td>{item.COMPANY_TYPE_NAME}</td>
                        </tr>
                      ))}
                    </TableFacility>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(DownloadBidReport);
