import React, { useEffect, useState } from "react";
import { apis as api } from "../../services/api.action";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import { Link } from "react-router-dom";
import RecordStatus from "../ui-setup/RecordStatus";
import NumberFormat from "react-number-format";
import Notiflix from "notiflix";
import OtpModal from "../layouts/OtpModal";
import { TableSimpleFac, AmntFormatFac } from "../layouts/Facility";

export const ConsentedOrderDetail = (props) => {
  const { location, userData, configData, recordData, menuData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId, companyTypeId, userName } = userData;
  const { isCsd } = configData;
  const { tableData, notif } = appStatic;
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  const dispatch = useDispatch();
  const [isFullPayment, setIsFullPayment] = useState(false);
  const [isCsdCheck, setIsCsdCheck] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [orderDetail, setOrderDetail] = useState({});
  const [fundingDetail, setFundingDetail] = useState({});
  const [shippingData, setShippingData] = useState({});
  const [primaryData, setPrimaryData] = useState({});
  const [billingData, setBillingData] = useState({});
  const [sharedPODetail, setSharedPODetail] = useState({});
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [contractDetail, setContractDetail] = useState({});
  const [financeDetail, setFinanceDetail] = useState({});
  const [financeHistoryData, setFinanceHistoryData] = useState([]);
  const [isFunded, setIsFunded] = useState(false);
  const [surchargeDetail, setSurchargeDetail] = useState({});
  const [isSupplier, setIsSupplier] = useState(false);
  const [isFunder, setIsFunder] = useState(false);
  const [otp, setOtp] = useState("");
  const [show, setShow] = useState(false);
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  let tempDocument = [];

  useEffect(() => {
    // console.log(state.ggParam);
    handleSwitchCondition();
    setSharedPODetail(state.ggParam);
    getDetailById(state.ggParam);

    //CALL DOCUMENT BLOCK
    BOM.getSpecDoc(ggParam?.ORDERID, setDocuments, userData);

    // getSpecDoc(state?.ggParam?.ORDERID, "purchaseorder");
    // //GET 2nd DOCUMENT
    // setTimeout(() => {
    // getSpecDoc(state?.ggParam?.ORDERID, "sharing");
    // }, 1500);

  }, []);

  const handleSwitchCondition = () => {
    if (isCsd.toLowerCase() === "y") {
      setIsCsdCheck(true);
    }
    switch (companyTypeId) {
      case "CT2022012400002": // supplier
        setIsSupplier(true);
        break;
      case "CT2022012400003": // funder
        setIsFunder(true);
        break;
    }
  };
  const onChoosePaymentOption = (type) => {
    // console.log(type);
    switch (type.toLowerCase()) {
      case "instalment":
        setIsFullPayment(true);
        break;
      default:
        setIsFullPayment(false);
        break;
    }
  };
  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      orderId: params.ORDERID,
      companyId,
      session,
    };

    BOM.FetchReqAction(body, api.GetConsentedPo, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let orderDetail = res.purchaseOrderDetail;

        setOrderDetail(orderDetail);
        dispatch({
          type: cnt.RECORD_STATUS,
          payload: orderDetail,
        });

        onChoosePaymentOption(orderDetail.PO_PAYMENT_OPTION);

        setShippingData(orderDetail.SHIP_DATA);
        setBillingData(orderDetail.BILL_DATA);
        setFundingDetail(orderDetail.BIDDING_DATA);

        setInvoiceDetail(orderDetail.INVOICE_DATA);
        setContractDetail(orderDetail.CONTRACT_DATA);
        setFinanceDetail(orderDetail.BIDDING_DATA);
        setFinanceHistoryData(orderDetail.FUND_HISTORY_DATA);
        setSurchargeDetail(orderDetail.SURCHARGE_DATA);

        //handle right side
        BOM.IsApproveOrReject(
          orderDetail.BIDDING_DATA.FLG_STATUS,
          menuData,
          "Quoted PO"
        );

        //get document
        // getSpecDoc(state.ggParam);
      }
    });
  };
  const getSpecDocOLD = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      id: params.ORDERID,
      type: "purchaseorder",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setDocuments(res.docListing);
      }
    });
  };

  const getSpecDoc = async (id, type) => {
    let body = {
      profileId,
      id,
      type,
      session,
    };

    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        switch (type?.toLowerCase()) {
          case "purchaseorder":
            res.docListing.forEach((element) => {
              tempDocument.push(element);
            });
            break;

          default:
            res.docListing.forEach((element) => {
              tempDocument.push(element);
            });
            break;
        }

        setDocuments([...tempDocument]);
      }
    });
  };

  const showModal = () => {
    console.log("set show");
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };
  const onGenerateOtp = () => {
    $(".clearInput").val("");
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      // userName:"gedeon@openfactorgroup.com",
      userName,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GeneratePoToken, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        console.log(res);
        showModal();
      }
    });
  };

  const onResendOtp = () => {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      // userName:"gedeon@openfactorgroup.com",
      userName,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.ResendOtp, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        BOM.AlertMsg(cnt.SUCCESS, res.message);
        //display inner popup
        showModal();
        $(".clearInput").val("");
      }
    });
  };

  const onValidOtp = (orderDetail) => {
    setOtpErrorMsg("");

    if (!otp) {
      setOtpErrorMsg("OTP is required.");
    }
    if (otp.length < 6) {
      setOtpErrorMsg("Not less than 6 digits");
      return;
    }
    if (otp.length > 6) {
      setOtpErrorMsg("Not greater than 6 digits");
      return;
    }
    let body = {
      profileId,
      otp,
      session,
    };

    BOM.LoadAlert(cnt.LOAD, "Processing");
    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.OtpValidation, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        $(".clearInput").val("");
        // call to update
        onAppoveConsentStatusPo(orderDetail);
      }
    });
  };
  const onUpdateConsentStatusPo = (objParam) => {
    let body = {
      profileId,
      broadcastId: objParam.BROADCAST_ID,
      orderId: state.ggParam.ORDERID,
      session,
    };

    // confirm
    Notiflix.Confirm.show(
      "Confrim Funding",
      "Click Yes to Approve or No to Reject Funding.",
      "Yes",
      "No",
      function () {
        // Yes
        //generate OTP
        onGenerateOtp();
      },
      function () {
        // No button
        BOM.LoadAlert(cnt.LOAD, "Processing");
        body.criteria = "R";
        BOM.SendReqAction(body, api.ApproveRejectConsent, "financed-order");
        // console.log(body);
      }
    );
  };

  const onAppoveConsentStatusPo = (objParam) => {
    let body = {
      profileId,
      broadcastId: objParam.BROADCAST_ID,
      orderId: state.ggParam.ORDERID,
      session,
    };

    // console.log(body);

    BOM.LoadAlert(cnt.LOAD, "Processing");
    body.criteria = "A";
    BOM.SendReqAction(body, api.ApproveRejectConsent, "financed-order");
  };

  const onApprove = () => {
    let bodyApprove = {
      profileId,
      orderId: state.ggParam.ORDERID,
      criteria: "A",
      session,
    };

    // console.log(bodyApprove);
    BOM.GlobalApproveRejectAction(
      bodyApprove,
      api.ApproveRejectFundedPo,
      "financed-order",
      "Order",
      "Approve "
    );
  };
  const onReject = () => {
    let bodyDisable = {
      profileId,
      orderId: state.ggParam.ORDERID,
      criteria: "R",
      session,
    };

    // console.log(bodyDisable);
    BOM.GlobalApproveRejectAction(
      bodyDisable,
      api.ApproveRejectFundedPo,
      "financed-order",
      "PO",
      "Reject "
    );
  };

  return (
    <Main {...props} onApprove={onApprove} onReject={onReject}>
      <ContentTop pageTitle={appLabel.fundedPODetail} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{appLabel.orderInfo}</h5>
                <div className="ibox-tools">
                  <Link to="/funded-order" className="btn btn-primary btn-xs">
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;
                    {appLabel.backBtn}
                  </Link>
                  &nbsp;
                  {orderDetail.FLG_SUPPLIER_CONSENT === "U" &&
                    isSupplier &&
                    !show && (
                      <span>
                        <button
                          type="button"
                          className="btn btn-primary btn-xs"
                          onClick={() => onUpdateConsentStatusPo(orderDetail)}
                        >
                          <i className="fa fa-thumbs-up" />
                          &nbsp;
                          {appLabel.confirmFunding}
                        </button>
                      </span>
                    )}
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                  <OtpModal
                    show={show}
                    title={"OTP"}
                    handleClose={() => hideModal()}
                    handleResend={() => onResendOtp()}
                    handleSave={() => onValidOtp(orderDetail)}
                  >
                    <div className="row form-group col-md-12 ">
                      <label className="col-md-offset-4 al-subtitle">
                        {appLabel.enterOtp}
                        <span className="asterisks">*</span>
                      </label>
                      <label className="col-md-offset-1 al-subtitle">
                        <small>{userName}</small>
                      </label>
                      <input
                        type="number"
                        className="form-control input-sm clearInput"
                        placeholder="******"
                        onChange={(e) => setOtp(e.target.value)}
                      />
                      <span className="asterisks">{otpErrorMsg}</span>
                    </div>
                  </OtpModal>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.generalInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.poNo}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.ORDERID}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.from}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.FROM_COMPANY_NAME}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.to}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.TO_COMPANY_NAME}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.poAmount}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={orderDetail.PO_AMOUNT}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.currency}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.PO_CCY}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.pymtOption}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.PO_PAYMENT_OPTION}
                          disabled
                        />
                      </div>
                    </div>
                    <div
                      className={`row form-group col-md-6 ${
                        isFullPayment ? "" : "hide"
                      }`}
                    >
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.frequency}
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.PO_INSTALLMENT_FREQ}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.firstPayDue}{" "}
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.PO_FIRSTPAY_DUE}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.lastPayDue}{" "}
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.PO_LASTPAY_DUE}
                          disabled
                        />
                      </div>
                    </div>
                    <div
                      className={`row form-group col-md-6 ${
                        isCsdCheck ? "" : "hide"
                      }`}
                    >
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.csdNumber}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.CSD_NUMBER}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.addressInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="col-md-6">
                      <div className="well">
                        <address>
                          <h4>
                            {" "}
                            <i className="fa fa-building text-navy"></i>
                            &nbsp; {appLabel.shippingAddress}
                          </h4>{" "}
                          <br />
                          <i className="fa fa-circle text-navy"></i> &nbsp;{" "}
                          {shippingData.SHIPPING_ADDRESS_1},{" "}
                          {shippingData.SHIPPING_CITY_NAME}
                          <br />
                          {shippingData.SHIPPING_PROVINCE_NAME},{" "}
                          {shippingData.SHIPPING_COUNTRY_NAME}{" "}
                          {shippingData.SHIPPING_POST_CODE}
                          <br />
                          <span title="Phone">
                            <i className="fa fa-circle text-navy"></i> &nbsp;
                            Phone :
                          </span>{" "}
                          <strong>{primaryData.OFFICE_PHONE}</strong>
                        </address>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="well">
                        <address>
                          <h4>
                            {" "}
                            <i className="fa fa-building text-navy"></i>
                            &nbsp;{appLabel.billingAddress}
                          </h4>{" "}
                          <i className="fa fa-circle text-navy"></i>
                          &nbsp;{billingData.BILLING_ADDRESS_1},{" "}
                          {billingData.BILLING_CITY_NAME}
                          <br />
                          {billingData.BILLING_PROVINCE_NAME},{" "}
                          {billingData.BILLING_COUNTRY_NAME}{" "}
                          {billingData.BILLING_POST_CODE}
                          <br />
                          <span title="Phone">
                            <i className="fa fa-circle text-navy"></i> &nbsp;
                            Phone:
                          </span>{" "}
                          <strong>{primaryData.OFFICE_PHONE}</strong>
                        </address>
                      </div>
                    </div>
                  </div>
                </div>

                      {/* so far hidden for everybody */}
                <div className={` row hide`}>
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.orderFinancingInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.AmountToFinance}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={fundingDetail.LOAN_AMOUNT}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.interestRate}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="number"
                          className="form-control input-sm"
                          disabled
                          defaultValue={fundingDetail.INTEREST_RATE}
                        />
                      </div>
                    </div>
                    <div
                      className={`row form-group col-md-6 ${
                        isFunder ? "hide" : ""
                      }
                      `}
                    >
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.chargeRate}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="number"
                          className="form-control input-sm"
                          disabled
                          defaultValue={surchargeDetail.CHARGE_LIMIT}
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.duration}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="number"
                          className="form-control input-sm"
                          disabled
                          defaultValue={fundingDetail.MONTHS_TENOR}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.arrangementFees}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={fundingDetail.INSURANCE_FEE}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.otherFees}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={fundingDetail.PROCESSING_FEE}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                    {/* <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.trustAccount}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          disabled
                          defaultValue={fundingDetail.TRUST_ACCOUNT_NAME}
                        />
                      </div>
                    </div> */}
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.paymentDate}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          disabled
                          defaultValue={fundingDetail.PAYMENT_DATE}
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.costOfFinance}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={fundingDetail.COST_OF_FINANCE}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {/* {appLabel.installmentAmount}&nbsp; */}
                          {appLabel.interestAmount}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={fundingDetail.INSTALLMENT_AMOUNT}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.totalRepayment}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={fundingDetail.TOTAL_AMOUNT}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.otherInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <ul className="nav nav-tabs">
                      <li className="active">
                        <a
                          data-toggle="tab"
                          href="#documentInfo"
                          target="_self"
                        >
                         <i className="fa fa-file-pdf-o text-navy"  />
                          &nbsp;{appLabel.documentInfo}
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#invoiceInfo" target="_self">
                         <i className="fa fa-file-text text-navy"  />
                          &nbsp;{appLabel.invoiceInfoShort}
                        </a>
                      </li>
                      <li>
                        <a
                          data-toggle="tab"
                          href="#contractInfo"
                          target="_self"
                        >
                         <i className="fa fa-file-text text-navy"  />
                          &nbsp;{appLabel.contractInfoShort}
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#financeInfo" target="_self">
                          <i className="fa fa-money text-navy" />
                          &nbsp;{appLabel.financeInfoShort}
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane active" id="documentInfo">
                        <div className="row" style={{ marginTop: "30px" }}>
                          <div className="col-sm-12">
                            {documents.length === 0 ? (
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDocumentMsg}
                                </a>
                              </div>
                            ) : (
                              <div>
                                {documents.map((item, index) => (
                                  <div className="col-md-4" key={index}>
                                    <div className="file-box">
                                      <div className="file">
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            BOM.DownloadAction(
                                              item.DOC_OBJECT,
                                              item.DOC_ID +
                                                " " +
                                                item.DOC_CATEGORY.toLowerCase()
                                            )
                                          }
                                        >
                                          <span className="corner" />
                                          <div className="icon">
                                           <i className="fa fa-file-pdf-o" style={{color:'#d50000'}} />
                                          </div>
                                          <div className="file-name">
                                            {item.DOC_CATEGORY}
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="tab-pane" id="invoiceInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          {Object.keys(invoiceDetail).length === 0 ? (
                            <div className="col-sm-12">
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDataInTableMsg}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-12">
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.invoiceId}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={invoiceDetail.INV_ID}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.to}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={
                                      invoiceDetail.SUPPLIER_COMPANY
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.invAmount}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <NumberFormat
                                    className="form-control input-sm"
                                    value={invoiceDetail.ORIGINAL_AMOUNT}
                                    displayType="text"
                                    disabled
                                    thousandSeparator={true}
                                    prefix={" "}
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.currency}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={invoiceDetail.INV_CCY}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.startDate}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={
                                      invoiceDetail.CONTR_START_DATE
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="tab-pane" id="contractInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          {Object.keys(contractDetail).length === 0 ? (
                            <div className="col-sm-12">
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDataInTableMsg}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-12">
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.contractId}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={contractDetail.PO_CONTRACT_ID}
                                    disabled
                                  />
                                </div>
                              </div>

                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.type}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={contractDetail.TRANS_TYPE}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.value}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <NumberFormat
                                    className="form-control input-sm"
                                    value={contractDetail.CONTR_VALUE}
                                    displayType="text"
                                    disabled
                                    thousandSeparator={true}
                                    prefix={""}
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.currency}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={contractDetail.CONTR_CCY}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.startDate}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={
                                      contractDetail.CONTR_START_DATE
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="tab-pane" id="financeInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.history}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />

                                {financeHistoryData.length === 0 ? (
                                  <div className="alert alert-warning text-center">
                                    <a className="alert-link">
                                      {appLabel.noDataInTableMsg}
                                    </a>
                                  </div>
                                ) : (
                                  <TableSimpleFac
                                    thData={tableData.historyBidList}
                                  >
                                    {financeHistoryData.map((item, index) => (
                                      <tr className="gradeX" key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.ITEM_ID}</td>
                                        <td>{item.ITEM_TYPE}</td>
                                        <td>
                                          <AmntFormatFac
                                            inputValue={item.AMOUNT_REQUESTED}
                                            currency={item.CCY}
                                          />
                                        </td>
                                        <td>
                                          <AmntFormatFac
                                            inputValue={item.AMOUNT_FUNDED}
                                            currency={item.CCY}
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                  </TableSimpleFac>
                                )}
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RecordStatus {...recordData} />
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsentedOrderDetail);
