import React, { useEffect, useState } from "react";
import { apis as api } from "../../../services/api.action";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { cnt } from "../../../services/constant.action";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import RecordStatus from "../../ui-setup/RecordStatus";
import {BOM} from "../../../services/defined.action";
import appLabel from "../../../config/appLabel"

export const DetailRole = (props) => {
  const { location, userData, recordData, menuData } = props;
  const { state } = location;
  const {profileId, session} = userData;
  const dispatch = useDispatch();
  const [roleDetail, setRoleDetail] = useState({});
  
  useEffect(() => {

    getDetailById(state.ggParam);
    /** MAKE RIGHT ACTION BTN READY */

    $("#approveBtn").click(function () { 
      let bodyApprove = {
        profileId,
        roleId: state.ggParam.ROLE_ID,
        criteria: "A",
        session
      };
  
      // console.log(bodyApprove);
      BOM.GlobalApproveRejectAction(
        bodyApprove,
      api.ApproveRejectRole,
      "manage-role",
      state.ggParam.ROLE_DESCRIPTION,
      "Approve "
    );
    });

    $("#rejectBtn").click(function () { 
      let bodyDisable = {
        profileId,
        roleId: state.ggParam.ROLE_ID,
        criteria: "R",
        session
      };
  
      // console.log(bodyDisable);
      BOM.GlobalApproveRejectAction(
        bodyDisable,
      api.ApproveRejectRole,
      "manage-role",
      state.ggParam.ROLE_DESCRIPTION,
      "Reject "
    );
    });
  }, []);

  function getDetailById(params) {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      roleId: params.ROLE_ID,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetRole, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let roleDetail = res.roleDetail;
        setRoleDetail(roleDetail);
        dispatch({
          type: cnt.RECORD_STATUS,
          payload: roleDetail,
        });
        //handle right side
        BOM.IsApproveOrReject(roleDetail.FLG_STATUS, menuData, "Manage Role");
      }
    });
  }
  
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.roleDetail} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{appLabel.roleInformation}</h5>
                <div className="ibox-tools">
                  <Link to="/manage-role" className="btn btn-primary btn-xs">
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;{appLabel.backBtn}
                  </Link>
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle text-navy" />
                      &nbsp; {appLabel.generalInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="col-md-6">
                      <div className="row form-group">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">{appLabel.roleId}</label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={roleDetail.ROLE_ID}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">{appLabel.roleName}</label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={roleDetail.ROLE_NAME}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row form-group">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                          {appLabel.roleDescription}
                          </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={roleDetail.ROLE_DESCRIPTION}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RecordStatus {...recordData} />
      {/* <RecordStatus {...BOM.NewRecordStatusdata()} /> */}
      </div>
    </Main>
  );
};


const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  // recordData: state.staticData.recordStatus
  recordData: state.recordStatus
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(DetailRole);
