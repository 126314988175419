/**
 * @author Gedeon NdundeCode
 * @date 05/12/2021
 */
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { apis as api } from "../../services/api.action";
import { cnt } from "../../services/constant.action";

import {
  SignInAction,
  SignOutAction,
} from "../../services/redux/actions/auth-act";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import Logo from "../../assets/img/logoLogin/fxBg.jpg";
import { BgImgFac } from "../../components/layouts/Facility";
import { SubmitBtnLoad } from "../../components/layouts/Facility";
import appStatic from "../../config/appStaticData";

const ValidateTerms = (props) => {
  /** INITIALIZE VARIABLES */

  const { privacyPolicyDocumentData } = appStatic;
  const { history, userData, lockscreenData } = props;
  const { profileId, session, companyId, userParentRoleName } = userData;
  const { userName, fullName } = lockscreenData;
  const [isSadRole, setIsSadRole] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsSadRole(userParentRoleName.toLowerCase() === "sad" ? true : false);
  }, []);

  const onIAgree = () => {
    let body = {
      profileId,
      companyId,
      criteria: "A",
      session,
    };

    // console.log(body);
    // return
    /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(body, api.CreateTermsCondition, "/dashboard");
  };

  return (
    <React.Fragment>
      <BgImgFac />
      <div className="centeredContent">
        <div className="row">
          <div className="col-md-12">
            <header>
              <div
                className="middle-box loginscreen animated fadeInDown login-card"
                style={{ marginBottom: "0%" }}
              >
                <div className="text-center">
                  <img
                    alt="image"
                    style={{
                      width: "150px",
                      marginTop: "10px",
                      marginBottom: "25px",
                    }}
                    src={Logo}
                  />
                </div>

                {/* <div className="slogan text-center">
                  <h2>&nbsp;</h2>
                </div> */}
                <form
                  // className="m-t"
                  autoComplete="off"
                >
                  <div className="form-group">
                    <label className="text-left">
                      {/* <p style={{ fontWeight: "normal", fontSize: "14px" }}>
                        Hi, <strong>{fullName}</strong>. By clicking proceed,
                        you acknowledge that you have read and accepted the
                        <Link to="/sign-in" style={{ fontWeight: "bold" }}>
                          &nbsp;Terms and Conditions.
                        </Link>
                      </p> */}
                      <p style={{ fontWeight: "normal", fontSize: "14px" }}>
                        Hi, <strong>{fullName}</strong>. By clicking the{" "}
                        <i>'I agree </i>' button, you confirm that you have read
                        and understood the{" "}
                        {isSadRole ? (
                          <>
                            <a
                              onClick={() =>
                                history.push("/terms-detail", {
                                  fileParam: privacyPolicyDocumentData[1].link,
                                })
                              }
                              style={{ fontWeight: "bold" }}
                            >
                              &nbsp;Terms and Conditions
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              onClick={() =>
                                history.push("/terms-detail", {
                                  fileParam: privacyPolicyDocumentData[2].link,
                                })
                              }
                              style={{ fontWeight: "bold" }}
                            >
                              &nbsp;Privacy Policy
                            </a>
                          </>
                        )}
                        &nbsp; and
                        <a
                          onClick={() =>
                            history.push("/terms-detail", {
                              fileParam: privacyPolicyDocumentData[0].link,
                            })
                          }
                          style={{ fontWeight: "bold" }}
                        >
                          &nbsp;End User Licence Agreement
                        </a>
                      </p>
                    </label>
                  </div>
                  <div className="form-group text-center">
                    <button
                      type="button"
                      onClick={() => onIAgree()}
                      className="btn btn-primary block full-width"
                      style={{
                        marginTop: 30,
                        boxShadow: "0 10px 6px -6px #fff",
                      }}
                    >
                      {appLabel.agreeTsAndCs}
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger block full-width"
                      onClick={() => history.push("/sign-in")}
                      style={{
                        marginTop: 30,
                        boxShadow: "0 10px 6px -6px #fff",
                      }}
                    >
                      {appLabel.cancelBtn}
                    </button>
                  </div>
                  {/* <div className="form-group text-center">
                    <Link to="/sign-in" style={{ fontWeight: "bold" }}>
                      <i className="fa fa-chevron-circle-left" />{" "}
                      <small>{appLabel.goToLogin}</small>
                    </Link>
                  </div> */}
                </form>
              </div>
            </header>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  lockscreenData: state.auth.lockscreenData,
});

const mapDispatchToProps = { SignInAction, SignOutAction };

export default connect(mapStateToProps, mapDispatchToProps)(ValidateTerms);
