import React, { useEffect, useState } from "react";
import { apis as api } from "../../services/api.action";
import { useForm } from "react-hook-form";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { cnt } from "../../services/constant.action";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import RecordStatus from "../ui-setup/RecordStatus";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import RightSide from "../ui-setup/RightSide";

export const EditCompany = (props) => {
  const { location, userData, recordData, menuData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session } = userData;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const dispatch = useDispatch();
  const [companyDetail, setCompanyDetail] = useState({});
  const [primaryData, setPrimaryData] = useState({});
  const [shippingData, setShippingData] = useState({});
  const [billingData, setBillingData] = useState({});
  const [isFunder, setIsFunder] = useState(false);
  const [bankData, setBankData] = useState([]);
  const [isIndividual, setIsIndividual] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [provincePrimaryData, setProvincePrimaryData] = useState([]);
  const [provinceShippingData, setProvinceShippingData] = useState([]);
  const [provinceBillingData, setProvinceBillingData] = useState([]);
  const [cityPrimaryData, setCityPrimaryData] = useState([]);
  const [cityShippingData, setCityShippingData] = useState([]);
  const [cityBillingData, setCityBillingData] = useState([]);

  const [isShipAsPrim, setIsShipAsPrim] = useState(false);
  const [isBillAsPrim, setIsBillAsPrim] = useState(false);
  const [shipAsPrimData, setShipAsPrimData] = useState({});
  const [billAsPrim, setBillAsPrimData] = useState({});

  useEffect(() => {
    // console.log(props);
    getCountry();
    getDetailById(state.ggParam);
    BOM.getSpecDoc(ggParam?.COMPANY_ID, setDocuments, userData);
    /** MAKE RIGHT ACTION BTN READY */
    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);
  }, []);

  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      companyId: params.COMPANY_ID,
      session,
    };

    /** FETCH */
    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let companyDetail = res.companyDetail;

        setCompanyDetail(companyDetail);
        setPrimaryData(companyDetail.CONTACT_DATA);
        setShippingData(companyDetail.SHIP_DATA);
        setBillingData(companyDetail.BILL_DATA);
        setBankData(companyDetail.BANK_DATA);
        onChooseCompanyType(companyDetail);

        /** BIND SOME CURRENT VALUES */
        setValue("companyId", companyDetail.COMPANY_ID);
        setValue("email", companyDetail.COMPANY_EMAIL);
        setValue("website", companyDetail.COMPANY_WEBSITE);
        setValue("contactPerson", companyDetail.CONTACT_DATA.CONTACT_PERSON);
        setValue("officePhone", companyDetail.CONTACT_DATA.OFFICE_PHONE);
        setValue("contactEmail", companyDetail.CONTACT_DATA.CONTACT_EMAIL);
        setValue(
          "contactPerson2",
          companyDetail.CONTACT_DATA.OTHER_CONTACT_PERSON
        );
        setValue("officePhone2", companyDetail.CONTACT_DATA.OTHER_OFFICE_PHONE);
        setValue("contactEmail2", companyDetail.CONTACT_DATA.OTHER_EMAIL);
        setValue("userId", companyDetail.CONTACT_DATA.CONTACT_1_PROFILE_ID);
        setValue("userId2", companyDetail.CONTACT_DATA.CONTACT_2_PROFILE_ID);

        //primary address
        setValue("countryId", companyDetail.COUNTRY_ID);
        setValue("provinceId", companyDetail.PROVINCE_ID);
        setValue("cityId", companyDetail.CITY_ID);
        setValue("address1", companyDetail.COMPANY_ADDRESS_1);
        setValue("address2", companyDetail.COMPANY_ADDRESS_2);
        setValue("postCode", companyDetail.COMPANY_POSTCODE);

        //shipping address
        setValue("shipCountryId", companyDetail.SHIP_DATA.SHIPPING_COUNTRY_ID);
        setValue("shipProvinceId", companyDetail.SHIP_DATA.SHIPPING_PROVINCE_ID);
        setValue("shipCityId", companyDetail.SHIP_DATA.SHIPPING_CITY_ID);
        setValue("shipAddress1", companyDetail.SHIP_DATA.SHIPPING_ADDRESS_1);
        setValue("shipAddress2", companyDetail.SHIP_DATA.SHIPPING_ADDRESS_2);
        setValue("shipPostCode", companyDetail.SHIP_DATA.SHIPPING_POST_CODE);

        //billing address
        setValue("billCountryId", companyDetail.BILL_DATA.BILLING_COUNTRY_ID);
        setValue("billProvinceId", companyDetail.BILL_DATA.BILLING_PROVINCE_ID);
        setValue("billCityId", companyDetail.BILL_DATA.BILLING_CITY_ID);
        setValue("billAddress1", companyDetail.BILL_DATA.BILLING_ADDRESS_1);
        setValue("billAddress2", companyDetail.BILL_DATA.BILLING_ADDRESS_2);
        setValue("billPostCode", companyDetail.BILL_DATA.BILLING_POST_CODE);

        // getProvince(companyDetail.COUNTRY_ID);
        // getCity(companyDetail.PROVINCE_ID);

        getGlobalProvince(companyDetail.COUNTRY_ID, setProvincePrimaryData);
        getGlobalProvince(companyDetail.BILL_DATA.BILLING_COUNTRY_ID, setProvinceShippingData);
        getGlobalProvince(companyDetail.SHIP_DATA.SHIPPING_COUNTRY_ID, setProvinceBillingData);
        getGlobalCity(companyDetail.PROVINCE_ID, setCityPrimaryData);
        getGlobalCity(companyDetail.SHIP_DATA.SHIPPING_PROVINCE_ID, setCityShippingData);
        getGlobalCity(companyDetail.BILL_DATA.BILLING_PROVINCE_ID, setCityBillingData);

        
        dispatch({
          type: cnt.RECORD_STATUS,
          payload: companyDetail,
        });

        //get document
        // getSpecDoc(state.ggParam);
      }
    });
  };
  const getSpecDoc = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      id: params.COMPANY_ID,
      type: "company",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setDocuments(res.docListing);
      }
    });
  };
  const onChooseCompanyType = (obj) => {
    // console.log(obj);
    switch (obj.COMPANY_TYPE_ID) {
      case "CT2022012400003":
        setIsFunder(!isFunder);
        switch (obj.INVESTOR_TYPE.toLowerCase()) {
          case "individual":
            setIsIndividual(true);
            break;
          default:
            setIsIndividual(false);
            break;
        }
        break;
      default:
        setIsFunder(false);
        break;
    }
  };
  const getCountry = () => {
    let body = {
      // profileId,
      countryId: "",
      // session,
    };

    BOM.FetchReqAction(body, api.GetCountry, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setCountryData(res.countryListing);
      }
    });
  };
  const getProvince = (countryId, type) => {
    // console.log(countryId);
    if (!countryId) return;

    switch (type.toLowerCase()) {
      case "primary":
        getGlobalProvince(countryId, setProvincePrimaryData);
        break;
      case "shipping":
        getGlobalProvince(countryId, setProvinceShippingData);
        break;
      case "billing":
        getGlobalProvince(countryId, setProvinceBillingData);
        break;
    }
  };
  const getCity = (stateId, type) => {
    if (!stateId) return;
    // console.log(stateId);

    switch (type.toLowerCase()) {
      case "primary":
        const provinceObj = provincePrimaryData.find(
          (item, index) => item.ID === stateId
        );

        setValue("finsysProviceId", provinceObj.FINSYS_ID);
        // console.log(provinceObj);
        getGlobalCity(stateId, setCityPrimaryData);
        break;
      case "shipping":
        getGlobalCity(stateId, setCityShippingData);
        break;
      case "billing":
        getGlobalCity(stateId, setCityBillingData);
        break;
    }
  };
  function getGlobalProvince(countryId, setProvince) {
    // console.log(countryId);
    let body = {
      profileId,
      countryId,
      session,
    };
    BOM.FetchReqAction(body, api.GetCountry, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setProvince(res.provinceListing);
      }
    });
  }
  function getGlobalCity(stateId, setCity) {
    // console.log("wow", stateId);
    let body = {
      profileId,
      stateId,
      session,
    };

    BOM.FetchReqAction(body, api.GetCity, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        //
        setCity(res.cityListing);
      }
    });
  }
  const onSelectAddressAs = (key) => {
    if (
      !getValues("countryId") ||
      !getValues("provinceId") ||
      !getValues("cityId")
    ) {
      BOM.AlertMsg(
        cnt.WARNING,
        "Fields with * under Primary Address are required."
      );
      $("#shipping").prop("checked", false);
      $("#billing").prop("checked", false);
      setIsShipAsPrim(false);
      setIsBillAsPrim(false);
      return;
    }
    // console.log(isShipAsPrim);

    switch (key) {
      case "shipping":
        switch (!isShipAsPrim) {
          case true:
            // setShipAsPrimData({
            //   countryId: countryData.find(
            //     (item, index) => item.ID === getValues("countryId")
            //   )["COUNTRY_NAME"],
            //   provinceId: provincePrimaryData.find(
            //     (item, index) => item.ID === getValues("provinceId")
            //   )["PROVINCE_NAME"],
            //   cityId: cityPrimaryData.find(
            //     (item, index) => item.ID === getValues("cityId")
            //   )["CITY_NAME"],
            //   address1: getValues("address1"),
            //   address2: getValues("address2"),
            //   postCode: getValues("postCode"),
            // });

            setValue("shipCountryId", getValues("countryId"));
            setValue("shipProvinceId", getValues("provinceId"));
            setValue("shipCityId", getValues("cityId"));
            setValue("shipAddress1", getValues("address1"));
            setValue("shipAddress2", getValues("address2"));
            setValue("shipPostCode", getValues("postCode"));
            setIsShipAsPrim(!isShipAsPrim);
            break;

          default:
            setValue("shipCountryId", "");
            setValue("shipProvinceId", "");
            setValue("shipCityId", "");
            setValue("shipAddress1", "");
            setValue("shipAddress2", "");
            setValue("shipPostCode", "");
            setShipAsPrimData({});
            setIsShipAsPrim(!isShipAsPrim);
            break;
        }

        break;

      case "billing":
        switch (!isBillAsPrim) {
          case true:
            setValue("billCountryId", getValues("countryId"));
            setValue("billProvinceId", getValues("provinceId"));
            setValue("billCityId", getValues("cityId"));
            setValue("billAddress1", getValues("address1"));
            setValue("billAddress2", getValues("address2"));
            setValue("billPostCode", getValues("postCode"));
            setIsBillAsPrim(!isBillAsPrim);
            break;

          default:
            setValue("billCountryId", "");
            setValue("billProvinceId", "");
            setValue("billCityId", "");
            setValue("billAddress1", "");
            setValue("billAddress2", "");
            setValue("billPostCode", "");
            setIsBillAsPrim(!isBillAsPrim);
            break;
        }
        break;
    }
  };
  const formSubmit = (formValues) => {
    
    switch (formValues.companyTypeId) {
      case "CT2022012400003": // IF FUNDER TYPE
        formValues.companyName = formValues.investorName;
        break;
    }

    let body = {
      profileId,
      session,
      ...formValues,
    };

    // console.log(body);
    /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(body, api.UpdateCompany, "/manage-company");
  };
  // return <div/>
  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <RightSide />
        <ContentTop pageTitle={appLabel.editCompany} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{appLabel.companyInformation}</h5>
                  <div className="ibox-tools">
                    <Link
                      to="/manage-company"
                      className="btn btn-primary btn-xs"
                    >
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.generalInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className={`row form-group col-md-6`}>
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.companyType} &nbsp;
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={companyDetail.COMPANY_TYPE_NAME}
                            disabled
                          />
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6 ${
                          isFunder ? "hide" : ""
                        }
                      `}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.companyName}{" "}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={companyDetail.COMPANY_NAME}
                            disabled
                          />
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6 ${
                          isFunder ? "" : "hide"
                        }`}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.investorType}{" "}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={companyDetail.INVESTOR_TYPE}
                            disabled
                          />
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6 ${
                          isFunder ? "" : "hide"
                        }`}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.investorName}{" "}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={companyDetail.COMPANY_NAME}
                            disabled
                          />
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6 ${
                          isFunder && isIndividual ? "" : "hide"
                        }`}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.clientTitle}{" "}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={companyDetail.CLIENT_TITLE}
                            disabled
                          />
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6 ${
                          isFunder && isIndividual ? "" : "hide"
                        }`}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.clientType}{" "}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={companyDetail.CLIENT_TYPE}
                            disabled
                          />
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6 ${
                          isFunder && isIndividual ? "" : "hide"
                        }`}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.bvnRcNumber}{" "}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={companyDetail.BVN}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.companyRegNo}{" "}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={companyDetail.COMPANY_REG_DATE}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.vatId}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={companyDetail.COMPANY_VAT_ID}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.tradingName}{" "}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={companyDetail.COMPANY_TRADING_NAME}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.email}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.email}
                            {...register("email", {
                              required: "Field is required.",
                              pattern: {
                                value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                                message: "Email must be valid.",
                              },
                            })}
                          />
                          <span className="asterisks">
                            {errors?.email?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.companyTax}{" "}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={companyDetail.COMPANY_TAX}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.website}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.website}
                            {...register("website")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.otherInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <ul className="nav nav-tabs">
                        <li className="active">
                          <a
                            data-toggle="tab"
                            href="#contactInfo"
                            target="_self"
                          >
                            <i className="fa fa-users text-navy" />
                            &nbsp;{appLabel.contactInfo}
                          </a>
                        </li>
                        <li>
                          <a
                            data-toggle="tab"
                            href="#addressInfo"
                            target="_self"
                          >
                            <i className="fa fa-home text-navy" />
                            &nbsp;{appLabel.addressInfo}
                          </a>
                        </li>
                        <li>
                          <a
                            data-toggle="tab"
                            href="#statutoryInfo"
                            target="_self"
                          >
                            <i className="fa fa-tag text-navy" />
                            &nbsp;{appLabel.statutoryInfo}
                          </a>
                        </li>
                        <li>
                          <a
                            data-toggle="tab"
                            href="#documentInfo"
                            target="_self"
                          >
                           <i className="fa fa-file-pdf-o text-navy"  />
                            &nbsp;{appLabel.documentInfo}
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="contactInfo">
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.primaryContact}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.contactName}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={appLabel.contactName}
                                    {...register("contactPerson", {
                                      required: "Field is required.",
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.contactPerson?.message}
                                  </span>
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.officePhone}
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={appLabel.officePhone}
                                    {...register("officePhone")}
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle ">
                                    {appLabel.contactEmail}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    
                                    placeholder={appLabel.contactEmail}
                                    {...register("contactEmail", {
                                      required: "Field is required.",
                                      pattern: {
                                        value:
                                          /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                                        message: "Email must be valid.",
                                      },
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.contactEmail?.message}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.otherContact}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.contactName}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={appLabel.contactName}
                                    {...register("contactPerson2", {
                                      required: "Field is required.",
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.contactPerson2?.message}
                                  </span>
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.officePhone}
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={appLabel.officePhone}
                                    {...register("officePhone2")}
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle ">
                                    {appLabel.contactEmail}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={appLabel.contactEmail}
                                    
                                    {...register("contactEmail2", {
                                      required: "Field is required.",
                                      pattern: {
                                        value:
                                          /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                                        message: "Email must be valid.",
                                      },
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.contactEmail2?.message}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="addressInfo">
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.primaryAddress}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.country}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <select
                                    className="form-control input-sm"
                                    value={getValues("countryId")}
                                    {...register("countryId", {
                                      onChange: (e) =>
                                        getProvince(e.target.value, "primary"),
                                      required: "Field is required.",
                                    })}
                                  >
                                    <option value="">{appLabel.choose}</option>
                                    {countryData.map((item, index) => (
                                      <option value={item.ID} key={index}>
                                        {item.COUNTRY_NAME}
                                      </option>
                                    ))}
                                  </select>
                                  <span className="asterisks">
                                    {errors?.countryId?.message}
                                  </span>
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.province}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <select
                                    className="form-control input-sm"
                                    value={getValues("provinceId")}
                                    {...register("provinceId", {
                                      onChange: (e) =>
                                        getCity(e.target.value, "primary"),
                                      required: "",
                                    })}
                                  >
                                    <option value="">{appLabel.choose}</option>
                                    {provincePrimaryData.map((item, index) => (
                                      <option value={item.ID} key={index}>
                                        {item.PROVINCE_NAME}
                                      </option>
                                    ))}
                                  </select>
                                  <span className="asterisks">
                                    {errors?.provinceId?.message}
                                  </span>
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.city}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <select
                                    className="form-control input-sm"
                                    value={getValues("cityId")}
                                    {...register("cityId", {
                                      required: "Field is required.",
                                    })}
                                  >
                                    <option value="">{appLabel.choose}</option>
                                    {cityPrimaryData.map((item, index) => (
                                      <option value={item.ID} key={index}>
                                        {item.CITY_NAME}
                                      </option>
                                    ))}
                                  </select>
                                  <span className="asterisks">
                                    {errors?.cityId?.message}
                                  </span>
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.address} 1{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={`${appLabel.address} 1`}
                                    {...register("address1", {
                                      required: "Field is required.",
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.address1?.message}
                                  </span>
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.address} 2
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={`${appLabel.address} 2`}
                                    {...register("address2")}
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.postCode}
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={`${appLabel.postCode}`}
                                    {...register("postCode")}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.shippingAddress} &nbsp;{" "}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />
                              <div className="col-md-12">
                                <label className="al-subtitle">
                                  <i className="fa fa-circle text-navy" />{" "}
                                  &nbsp;
                                  {appLabel.sameAsPrimary}&nbsp;{" "}
                                  <input
                                    onClick={() => {
                                      setIsShipAsPrim(!isShipAsPrim);
                                      onSelectAddressAs("shipping");
                                    }}
                                    style={{ cursor: "pointer" }}
                                    type="checkbox"
                                    id="shipping"
                                  />
                                </label>
                                <div
                                  className="hr-line-dashed"
                                  style={{ marginTop: 0 }}
                                />
                                <div
                                  className={`alert alert-warning text-center ${
                                    isShipAsPrim ? "" : "hide"
                                  }`}
                                >
                                  <a className="alert-link">
                                    {appLabel.shipSameAsPrimaryAddress}
                                  </a>
                                </div>
                              </div>
                              <div className={`${!isShipAsPrim ? "" : "hide"}`}>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.country}{" "}
                                      <span className="asterisks">*</span>
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <select
                                      className="form-control input-sm"
                                      value={getValues("shipCountryId")}
                                      {...register("shipCountryId", {
                                        onChange: (e) =>
                                          getProvince(
                                            e.target.value,
                                            "shipping"
                                          ),
                                        required: "Field is required.",
                                      })}
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {countryData.map((item, index) => (
                                        <option value={item.ID} key={index}>
                                          {item.COUNTRY_NAME}
                                        </option>
                                      ))}
                                    </select>
                                    <span className="asterisks">
                                      {errors?.shipCountryId?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.province}{" "}
                                      <span className="asterisks">*</span>
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <select
                                      className="form-control input-sm"
                                      value={getValues("shipProvinceId")}
                                      {...register("shipProvinceId", {
                                        onChange: (e) =>
                                          getCity(e.target.value, "shipping"),
                                        required: "Field is required.",
                                      })}
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {provinceShippingData.map(
                                        (item, index) => (
                                          <option value={item.ID} key={index}>
                                            {item.PROVINCE_NAME}
                                          </option>
                                        )
                                      )}
                                    </select>
                                    <span className="asterisks">
                                      {errors?.shipProvinceId?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.city}{" "}
                                      <span className="asterisks">*</span>
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <select
                                      className="form-control input-sm"
                                      value={getValues("shipCityId")}
                                      {...register("shipCityId", {
                                        required: "Field is required.",
                                      })}
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {cityShippingData.map((item, index) => (
                                        <option value={item.ID} key={index}>
                                          {item.CITY_NAME}
                                        </option>
                                      ))}
                                    </select>
                                    <span className="asterisks">
                                      {errors?.shipCityId?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.address} 1{" "}
                                      <span className="asterisks">*</span>
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      placeholder={`${appLabel.address} 1`}
                                      {...register("shipAddress1", {
                                        required: "Field is required.",
                                      })}
                                    />
                                    <span className="asterisks">
                                      {errors?.shipAddress1?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.address} 2
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      placeholder={`${appLabel.address} 2`}
                                      {...register("shipAddress2")}
                                    />
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.postCode}
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      placeholder={`${appLabel.postCode}`}
                                      {...register("shipPostCode")}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.billingAddress}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />
                              <div className="col-md-12">
                                <label className="al-subtitle">
                                  <i className="fa fa-circle text-navy" />{" "}
                                  &nbsp;
                                  {appLabel.sameAsPrimary}&nbsp;{" "}
                                  <input
                                    onClick={() => {
                                      setIsBillAsPrim(!isBillAsPrim);
                                      onSelectAddressAs("billing");
                                    }}
                                    style={{ cursor: "pointer" }}
                                    type="checkbox"
                                    id="billing"
                                  />
                                </label>
                                <div
                                  className="hr-line-dashed"
                                  style={{ marginTop: 0 }}
                                />
                                <div
                                  className={`alert alert-warning text-center ${
                                    isBillAsPrim ? "" : "hide"
                                  }`}
                                >
                                  <a className="alert-link">
                                    {appLabel.billSameAsPrimaryAddress}
                                  </a>
                                </div>
                              </div>
                              <div className={`${!isBillAsPrim ? "" : "hide"}`}>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.country}{" "}
                                      <span className="asterisks">*</span>
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <select
                                      className="form-control input-sm"
                                      value={getValues("billCountryId")}
                                      {...register("billCountryId", {
                                        onChange: (e) =>
                                          getProvince(
                                            e.target.value,
                                            "billing"
                                          ),
                                        required: "Field is required.",
                                      })}
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {countryData.map((item, index) => (
                                        <option value={item.ID} key={index}>
                                          {item.COUNTRY_NAME}
                                        </option>
                                      ))}
                                    </select>
                                    <span className="asterisks">
                                      {errors?.billCountryId?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.province}{" "}
                                      <span className="asterisks">*</span>
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <select
                                      className="form-control input-sm"
                                      value={getValues("billProvinceId")}
                                      {...register("billProvinceId", {
                                        onChange: (e) =>
                                          getCity(e.target.value, "billing"),
                                        required: "Field is required.",
                                      })}
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {provinceBillingData.map(
                                        (item, index) => (
                                          <option value={item.ID} key={index}>
                                            {item.PROVINCE_NAME}
                                          </option>
                                        )
                                      )}
                                    </select>
                                    <span className="asterisks">
                                      {errors?.billProvinceId?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.city}{" "}
                                      <span className="asterisks">*</span>
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <select
                                      className="form-control input-sm"
                                      value={getValues("billCityId")}
                                      {...register("billCityId", {
                                        required: "Field is required.",
                                      })}
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {cityBillingData.map((item, index) => (
                                        <option value={item.ID} key={index}>
                                          {item.CITY_NAME}
                                        </option>
                                      ))}
                                    </select>
                                    <span className="asterisks">
                                      {errors?.billCityId?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.address} 1{" "}
                                      <span className="asterisks">*</span>
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      placeholder={`${appLabel.address} 1`}
                                      {...register("billAddress1", {
                                        required: "Field is required.",
                                      })}
                                    />
                                    <span className="asterisks">
                                      {errors?.billCityId?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.address} 2
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      ng-model="entity.address2"
                                      placeholder={`${appLabel.address} 2`}
                                      {...register("billAddress2")}
                                    />
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.postCode}
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      placeholder={`${appLabel.postCode}`}
                                      {...register("billPostCode")}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="statutoryInfo">
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.generalInfo}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />
                              <div
                                className={`row form-group col-md-6 ${
                                  isIndividual ? "hide" : ""
                                }`}
                              >
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.companyRegDate}{" "}
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={
                                      companyDetail.COMPANY_REG_DATE
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                              <div
                                className={`row form-group col-md-6 ${
                                  isIndividual ? "" : "hide"
                                }`}
                              >
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.dateOfBirth}{" "}
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={companyDetail.DOB}
                                    disabled
                                  />
                                </div>
                              </div>

                              <div
                                className={`row form-group col-md-6 ${
                                  isIndividual ? "" : "hide"
                                }`}
                              >
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.gender}{" "}
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={companyDetail.GENDER}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.businessStartDate}{" "}
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={
                                      companyDetail.BUSINESS_START_DATE
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.businessType}{" "}
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={companyDetail.BUSINESS_TYPE}
                                    disabled
                                  />
                                </div>
                              </div>
                              {/* <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.financeYear}{" "}
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={companyDetail.FINANCE_YEAR}
                                    disabled
                                  />
                                </div>
                              </div> */}
                            </div>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.bankInformation}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />

                              <div className="table-responsive">
                                {bankData.length === 0 ? (
                                  <div className="alert alert-warning text-center">
                                    <a className="alert-link">
                                      {appLabel.noDataInTableMsg}
                                    </a>
                                  </div>
                                ) : (
                                  <table
                                    className="footable table table-stripped toggle-arrow-tiny"
                                    data-page-size="10"
                                    data-filter="#filter"
                                  >
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>{appLabel.bankName}</th>
                                        <th>{appLabel.accountNumber}</th>
                                        <th>{appLabel.accountName}</th>
                                        <th>{appLabel.accountType}</th>
                                        <th>{appLabel.branchCode}</th>
                                        <th>{appLabel.nuban}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {bankData.map((bank, index) => (
                                        <tr className="gradeX" key={index}>
                                          <td>{index + 1}</td>
                                          <td>{bank.BANK_NAME}</td>
                                          <td>{bank.ACC_NUM}</td>
                                          <td>{bank.ACC_NAME}</td>
                                          <td>{bank.ACC_TYPE}</td>
                                          <td>{bank.BRANCH_CODE}</td>
                                          <td> {bank.NUBAN} </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td colSpan="7">
                                          <ul className="pagination pull-right"></ul>
                                        </td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="documentInfo">
                          <div className="row" style={{ marginTop: "30px" }}>
                            <div className="col-sm-12">
                              {documents.length === 0 ? (
                                <div className="alert alert-warning text-center">
                                  <a className="alert-link">
                                    {appLabel.noDocumentMsg}
                                  </a>
                                </div>
                              ) : (
                                <div>
                                  {documents.map((item, index) => (
                                    <div className="col-md-4" key={index}>
                                      <div className="file-box">
                                        <div className="file">
                                          <a
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              BOM.DownloadAction(
                                                item.DOC_OBJECT,
                                                item.DOC_ID +
                                                  " " +
                                                  item.DOC_CATEGORY.toLowerCase()
                                              )
                                            }
                                          >
                                            <span className="corner" />
                                            <div className="icon">
                                              <i className="fa fa-file-pdf-o" style={{color:'#d50000'}}/>
                                            </div>
                                            <div className="file-name">
                                              {item.DOC_CATEGORY}
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <RecordStatus {...userDetail} /> */}
        </div>
      </form>
      <RecordStatus {...recordData} />
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  // recordData: state.staticData.recordStatus
  recordData: state.recordStatus,
  menuData: state.staticData.menuData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditCompany);
