import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Main from "../../layouts/Main";
import { connect, useDispatch } from "react-redux";
import { apis as api } from "../../../services/api.action";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM, RAL } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import appStatic from "../../../config/appStaticData";
import { TableFacility, AmntFormatFac } from "../../layouts/Facility";
import RightSide from "../../ui-setup/RightSide";
import { HandleSkeletonTbleFac } from "../../layouts/Facility";

const ManageFunderBidDetail = (props) => {
  const { userData, menuData, location, history} = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId, companyTypeId } = userData;
  const globalVar = window.globalData;
  const { tableData } = appStatic;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const dispatch = useDispatch();
  const mainParams = ggParam;
  let bidActArr = []; // USELESS
  const initialAmount = parseFloat(mainParams.REQUESTED_AMOUNT);

  const [bidData, setBidData] = useState([]);
  const [reqAmnt, setReqAmnt] = useState(initialAmount);
  const [bidActivity, setBidActivity] = useState([]);
  const [isSelectedBid, setIsSelectedBid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState({});
  const [isCustodian, setIsCustodian] = useState(companyTypeId === 'CT2022012400000' ? true : false);

  const shareType = ggParam.shareType;
  const apiUrl =
    ggParam.ITEM_TYPE.toLowerCase() === "po"
      ? api.PreApprovePoBid
      : api.PreApproveInvBid;

  useEffect(() => {
    getBidList(mainParams);
    RAL.disableEnableRightAction();
  }, []);

  function getBidList(objParam) {
    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true);

    let body = {
      profileId,
      itemId: objParam.ITEM_ID,
      itemType: objParam.ITEM_TYPE,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetBid, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.bidDetails;
        setBidData(objList.public || objList.private);

        /** ORDER ITM S BY FUNDER RATE */
        // setBidData([...sortByRate(objList.public || objList.private)]);
        BOM.DatatableUsage();
        setIsLoading(false);
      }
    });
  }

  const onSelectItem = (objParam) => {
    let bidAmount = parseFloat(objParam.FUNDER_AMOUNT);
    let funderAmt = 0;
    let duplicateItem = {};
    let remainder = 0;
    let expressionStr = "";

    // Do Validate for duplicate
    duplicateItem = bidActivity.find(
      (item) => item.funderId === objParam.FUNDER_ID
    );

    if (duplicateItem) {
      // if found
      funderAmt = parseFloat(duplicateItem.bidAmount);
      setReqAmnt(reqAmnt + funderAmt);
      //progress bar call
      handleProgressBar(reqAmnt + funderAmt);
      setBidActivity(
        bidActivity.filter((item) => item.funderId !== objParam.FUNDER_ID)
      );
    } else {
      // if Not found

      if (reqAmnt >= bidAmount) {
        setReqAmnt(parseFloat(reqAmnt) - bidAmount);
        remainder = parseFloat(reqAmnt) - bidAmount;
        expressionStr = `${reqAmnt} - ${bidAmount}`;

        //progress bar call
        handleProgressBar(remainder);

        storeBidLogic(objParam, expressionStr, bidAmount, remainder);
      } else if (reqAmnt !== 0 && reqAmnt <= bidAmount) {
        let temp = bidAmount - reqAmnt;
        bidAmount -= temp;
        remainder = reqAmnt - reqAmnt;
        expressionStr = `${reqAmnt} - ${reqAmnt}`;
        setReqAmnt(remainder);
        //progress bar call
        handleProgressBar(remainder);
        storeBidLogic(objParam, expressionStr, bidAmount, remainder);
        return;
      } else {
        // console.log(objParam.FUNDER_ID);
        $("#idkey" + objParam.FUNDER_ID).prop("checked", false);
        BOM.AlertMsg(cnt.WARNING, appLabel.maximumRequestAmt);
      }
    }

    //progress bar call
    //  handleProgressBar(remainder)
  };

  function onSelectItem2(objParam) {
    console.log(objParam);
    let bidAmount = parseFloat(objParam.FUNDER_AMOUNT);
    let funderAmt = 0;
    let duplicateItem = {};
    let remainder = 0;
    let expressionStr = "";

    // Do Validate for duplicate
    duplicateItem = bidActivity.find(
      (item) => item.funderId === objParam.FUNDER_ID
    );

    if (duplicateItem) {
      // if found
      funderAmt = parseFloat(duplicateItem.bidAmount);
      setReqAmnt(reqAmnt + funderAmt);

      setBidActivity(
        bidActivity.filter((item) => item.funderId !== objParam.FUNDER_ID)
      );
    } else {
      // if Not found

      if (reqAmnt >= bidAmount) {
        setReqAmnt(parseFloat(reqAmnt) - bidAmount);
        remainder = parseFloat(reqAmnt) - bidAmount;
        expressionStr = `${reqAmnt} - ${bidAmount}`;

        storeBidLogic(objParam, expressionStr, bidAmount, remainder);
      } else if (reqAmnt !== 0 && reqAmnt <= bidAmount) {
        let temp = bidAmount - reqAmnt;
        bidAmount -= temp;
        remainder = reqAmnt - reqAmnt;
        expressionStr = `${reqAmnt} - ${reqAmnt}`;
        setReqAmnt(remainder);
        storeBidLogic(objParam, expressionStr, bidAmount, remainder);
        return;
      } else {
        // console.log(objParam.FUNDER_ID);
        $("#idkey" + objParam.FUNDER_ID).prop("checked", false);
        BOM.AlertMsg(cnt.WARNING, appLabel.maximumRequestAmt);
      }
    }
  }

  function storeBidLogic(objParam, expressionStr, bidAmount, remainder) {
    let tempObj = {};

    // NEW INTEREST FORMULA: Interest = Principal * Rate * Time
    //Interest = 200,000,000 * 3.8% * (3/12)
    //Total Repayment = Bid Amount + New Interest Amount

    const newInterest =
      bidAmount *
      (parseFloat(objParam.FUNDER_RATE) / 100) *
      (parseInt(objParam.TENOR) / 12);
    const newTotalRepayment = bidAmount + newInterest;

    tempObj = {
      funderId: objParam.FUNDER_ID,
      itemType: mainParams.ITEM_TYPE,
      expressionStr,
      dateTimeStr: BOM.DateTime(new Date()),
      remainderAmnt: <AmntFormatFac inputValue={remainder} />,
      bidAmount,
      transactionFee: <AmntFormatFac inputValue={objParam.ARRANGEMENT_FEE} />,
      amountPayable1: <AmntFormatFac inputValue={objParam.AMOUNT_PAYABLE_1} />,
      amountPayable2: <AmntFormatFac inputValue={objParam.AMOUNT_PAYABLE_2} />,
      rate: objParam.FUNDER_RATE,

      interestAmount: (
        // <AmntFormatFac inputValue={objParam.INTEREST_AMOUNT} />
        <AmntFormatFac inputValue={newInterest} />
      ),
      totalRepayment: (
        // <AmntFormatFac inputValue={objParam.TOTAL_REPAYMENT} />
        <AmntFormatFac inputValue={newTotalRepayment} />
      ),
      bidObj: { ...objParam },
    };

    setBidActivity([...bidActivity, tempObj]);
    setIsSelectedBid(true); //for row layout
  }
  function uncheckAll(arrList) {
    arrList.forEach((element) => {
      $("#idkey" + element.INTENT_ID).prop("checked", false);
      $("#idkey" + element.FUNDER_ID).prop("checked", false);
    });
  }

  function hadleEmptyField(keyParam, objParam) {
    const { HAS_BID, FUNDER_AMOUNT, FUNDER_RATE, INTEREST_AMOUNT } = objParam;
    const str = "N/A";
    switch (keyParam.toLowerCase()) {
      case "amount":
        return HAS_BID === "Y" ? (
          <AmntFormatFac inputValue={FUNDER_AMOUNT} />
        ) : (
          str
        );
      case "rate":
        return HAS_BID === "Y" ? FUNDER_RATE : str;
      case "interestamt":
        return HAS_BID === "Y" ? (
          <AmntFormatFac inputValue={INTEREST_AMOUNT} />
        ) : (
          str
        );
    }
  }
  const onRejectAll_OLD = () => {
    let investors = [];

    bidData.forEach((element) => {
      investors.push({
        investorId: element.FUNDER_ID,
        bidStatus: "R",
      });
    });

    let body = {
      profileId,
      intentId: mainParams.INTENT_ID,
      shareType,
      investors,
      session,
    };

    // console.log(body);
    // return;

    BOM.GlobalApproveRejectAction(
      body,
      api.PreApprovePoBid,
      "manage-funder-bid",
      "Bid ",
      "Reject All "
    );
  };
  const onRejectAll = () => {
    let body = {
      intentId: mainParams.INTENT_ID,
      itemType: mainParams.ITEM_TYPE,
    };

    // console.log(body);
    // return;

    BOM.GlobalApproveRejectAction(
      body,
      api.RejectBid,
      "manage-funder-bid",
      "Bid ",
      "Reject All "
    );
  };
  function sortByRate(objList) {
    /** ORDER ITM S BY FUNDER RATE */
    const myData = [].concat(objList).sort(
      (a, b) => {
        if (a.FUNDER_RATE === "" && b.FUNDER_RATE === "") {
          return -1;
        } else if (parseInt(a.FUNDER_RATE) > parseInt(b.FUNDER_RATE)) {
          return 1;
        }
      }
      // parseInt(a.FUNDER_RATE) < parseInt(b.FUNDER_RATE) ? 1 : -1
    );

    return myData;
  }

  function controlingOutstadingAmnt() {
    // console.log(reqAmnt);
    if (reqAmnt === 0) {
      setTimeout(() => {
        RAL.disableEnableRightAction([cnt.RIGHT_SAVE_BTN]);
      }, 500);
    } else {
      setTimeout(() => {
        RAL.disableEnableRightAction();
      }, 500);
    }
  }

  function onAutomaticBid() {
    const selectedItem = [
      {
        name: appLabel.status,
        value: ggParam.BIDDING_STATUS,
      },

      {
        name: appLabel.itemType,
        value: ggParam.ITEM_TYPE,
      },
    ];
    history.push("/auto-bid-preview", {
      ggParam: {
        // bidData: sortByRate(bidData).filter((item)=>item.FUNDER_ID !== "CO0000000000164"),
        bidData: sortByRate(bidData),
        selectedItem,
        bidDetail: ggParam,
        requestedAmount: initialAmount,
      },
    });
  }

  const handleProgressBar = (outStanding) => {
    // console.log(
    //   `outStanding: ${outStanding} | initialAmount: ${initialAmount}`
    // );

    if (outStanding === 0) {
      setProgress({
        text: `The bid was completed at 100%. The next step is to save the bid.`,
        value: "100%",
        color: "navy",
      });
    } else if (outStanding === initialAmount) {
      const value = outStanding - initialAmount;
      setProgress({
        text: `${value}%, please choose bids till the outstanding balance equals 0`,
        value: `0%`,
        color: "danger",
      });
    } else {
      const value = ((outStanding / initialAmount) * 100).toFixed(0);
      // console.log(value);
      setProgress({
        text: `${value}%, please choose bids till the outstanding balance equals 0`,
        value: `${value}%`,
        color: "danger",
      });
    }
  };
  const formSubmit = () => {
    let investors = [];

    bidActivity.forEach((element) => {
      investors.push({
        investorId: element.funderId,
        bidStatus: "A",
        depositoryFinalAmt: element.bidAmount,
        allotmentRate: 0,
        isAutoBid: "N",
      });
    });

    // console.log(bidActivity);
    if (investors.length === 0) {
      BOM.AlertMsg(cnt.WARNING, "Please choose at least one Investor.");
      return;
    }

    let body = {
      profileId,
      intentId: mainParams.INTENT_ID,
      shareType,
      investors,
      session,
    };

    // console.log(body);
    // return;

    BOM.GlobalApproveRejectAction(
      body,
      apiUrl,
      "manage-funder-bid",
      "Bidding ",
      "Confirm "
    );
  };

  /** RENDER JXS DATA */
  return (
    <Main {...props} onSaveChange={handleSubmit(formSubmit)}>
      <form
        method="POST"
        // id="NewItemForm"
        autoComplete="off"
        // onSubmit={handleSubmit(formSubmit)}
      >
        {/* <RightSide /> */}
        <ContentTop pageTitle={appLabel.bidByPo} />

        <div className="animated fadeInRightFAKE">
          <div className="row">
            <div className={`${isSelectedBid ? "col-sm-7" : "col-sm-12"}`}>
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>{appLabel.amountRequested}</h5>
                  <span
                    style={{ fontSize: "14px", backgroundColor: "#1ab394" }}
                    className="label label-warning"
                  >
                    <AmntFormatFac inputValue={initialAmount} />
                  </span>

                  <div className="ibox-tools">
                    {/* <Link
                      to="/manage-funder-bid"
                      className="btn btn-primary btn-xs"
                    >
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link> */}
                    <span style={{ marginLeft: "10px" }}>
                      <button
                        type="button"
                        className="btn btn-white btn-xs m-r"
                        style={{ color: "gray" }}
                        onClick={() => history.push("/manage-funder-bid")}
                      >
                        <i className="fa fa-arrow-circle-left" />
                        &nbsp;
                        {appLabel.backBtn}
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger btn-xs m-r"
                        onClick={() => onRejectAll()}
                      >
                        <i className="fa fa-thumbs-down" />
                        &nbsp;
                        {appLabel.rejectAll}
                      </button>
                      {isCustodian && (
                      <button
                      type="button"
                      className="btn btn-primary btn-xs"
                      onClick={() => onAutomaticBid()}
                    >
                      <i className="fa fa-gavel" />
                      &nbsp;
                      {appLabel.autoBid}
                    </button>
                      )}
                    </span>
                  </div>
                </div>

                <div className="ibox-content panel-primary contentCard">
                  <HandleSkeletonTbleFac
                    classParam={`${isLoading ? "" : "hide"}`}
                  />
                  <div className={` ${isLoading ? "hide" : ""}`}>
                    {bidData.length === 0 ? (
                      <div className="alert alert-warning text-center">
                        <a className="alert-link">
                          {appLabel.noDataInTableMsg}
                        </a>
                      </div>
                    ) : (
                      <TableFacility thData={tableData.bidDetail}>
                        {bidData.map((item, index) => (
                          <tr className="gradeX" key={index}>
                            <td>
                              <label
                                className={`${
                                  shareType.toLowerCase() === "private" &&
                                  "myradio-button"
                                }`}
                              >
                                {ggParam.shareType.toLowerCase() ===
                                  "private" &&
                                  (item.HAS_BID === "Y" ? (
                                    <>
                                      <input
                                        type="radio"
                                        name="radio"
                                        id={`idkey${item.INTENT_ID}`}
                                        onClick={() => onSelectItem(item)}
                                      />
                                      <span className="label-visible">
                                        <span className="myfake-radiobutton"></span>
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <input type="radio" disabled />
                                    </>
                                  ))}

                                {ggParam.shareType.toLowerCase() === "public" &&
                                  (item.HAS_BID === "Y" ? (
                                    <input
                                      type="checkbox"
                                      id={`idkey${item.FUNDER_ID}`}
                                      onClick={() => onSelectItem(item)}
                                      style={{ cursor: "pointer" }}
                                    />
                                  ) : (
                                    <input type="checkbox" disabled />
                                  ))}
                              </label>
                            </td>
                            {/* <td>{item.INTENT_ID}</td> */}
                            <td>{item.FUNDER_NAME}</td>
                            <td>{hadleEmptyField("rate", item)}</td>
                            <td>{hadleEmptyField("amount", item)}</td>
                            <td>{hadleEmptyField("interestamt", item)}</td>
                          </tr>
                        ))}
                      </TableFacility>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${
                isSelectedBid ? "col-sm-5 animated fadeInRightFAKE" : "hide"
              }`}
            >
              <div className="ibox">
                <div className="ibox-content contentCard">
                  <div className="row m-b-lg">
                    <div className="col-xs-6 col-sm-6 col-md-12">
                      <h2>{appLabel.outstandingBalance}</h2>

                      <strong style={{ fontSize: "36px" }}>
                        <AmntFormatFac inputValue={reqAmnt} />
                        {/* The following is helping to get the current outstanding */}
                        {controlingOutstadingAmnt()}
                      </strong>
                      {/* progress */}
                      <div className="progress progress-striped active m-b-sm">
                        <div
                          style={{ width: progress.value }}
                          className={`progress-bar progress-bar-${progress.color}`}
                        />
                      </div>
                      {progress.value === "100%" ? (
                        <small className="text-navy">
                          <b>{progress.text}</b>
                        </small>
                      ) : (
                        <small className="text-danger">
                          <b>{progress.text}</b>
                        </small>
                      )}
                      {/* end progress */}
                      {/* <h6
                        className={`${reqAmnt === 0 ? "hide" : ""}`}
                        style={{ color: "#e53935", fontWeight: "bolder" }}
                      >
                        <i>{appLabel.bidInfoWarning}</i>
                      </h6> */}
                    </div>
                  </div>
                  <div
                    className="client-detail"
                    style={{ overflowY: "scroll" }}
                  >
                    {bidActivity.length === 0
                      ? "Start bidding "
                      : appLabel.bidingActivity}

                    <div
                      id="vertical-timeline"
                      className="vertical-container dark-timeline"
                    >
                      {bidActivity.map((item, index) => (
                        <div key={index} className="vertical-timeline-block">
                          <div className="vertical-timeline-icon gray-bg">
                            <i className="fa fa-gavel text-success" />
                          </div>
                          <div className="vertical-timeline-content">
                            <div>
                              <span className="pull-right">
                                {item.itemType}
                              </span>
                              <i className="fa fa-dot-circle-o text-success" />
                              &nbsp;{appLabel.itemType}
                            </div>
                            <div>
                              <span className="pull-right">
                                {item.funderId}
                              </span>
                              <i className="fa fa-dot-circle-o text-success" />
                              &nbsp;{appLabel.funder}
                            </div>
                            <div>
                              <span className="pull-right">
                                {item.expressionStr}
                              </span>
                              <i className="fa fa-dot-circle-o text-success" />
                              &nbsp;{appLabel.calc}.
                            </div>
                            <div>
                              <span className="pull-right">
                                {item.remainderAmnt}
                              </span>
                              <i className="fa fa-dot-circle-o text-success" />
                              &nbsp;{appLabel.difference}
                            </div>
                            <div>
                              <span className="pull-right">
                                {/* {item.bidAmount} */}
                                <AmntFormatFac inputValue={item.bidAmount} />
                              </span>
                              <i className="fa fa-dot-circle-o text-success" />
                              &nbsp;{appLabel.bidAmount}
                            </div>
                            <div>
                              <span className="pull-right">
                                {item.transactionFee}
                              </span>
                              <i className="fa fa-dot-circle-o text-success" />
                              &nbsp;{appLabel.transactionFee}
                            </div>
                            {/* <div>
                            <span className="pull-right">
                              {item.amountPayable1}
                            </span>
                            <i className="fa fa-dot-circle-o text-success" />
                            &nbsp;{appLabel.amountPayable1}
                          </div>
                          <div>
                            <span className="pull-right">
                              {item.amountPayable2}
                            </span>
                            <i className="fa fa-dot-circle-o text-success" />
                            &nbsp;{appLabel.amountPayable2}
                          </div> */}

                            <div>
                              <span className="pull-right">{item.rate}</span>
                              <i className="fa fa-dot-circle-o text-success" />
                              &nbsp;{appLabel.rate}
                            </div>
                            <div>
                              <span className="pull-right">
                                {item.interestAmount}
                              </span>
                              <i className="fa fa-dot-circle-o text-success" />
                              &nbsp;{appLabel.interestAmount}
                            </div>
                            <div>
                              <span className="pull-right">
                                {item.totalRepayment}
                              </span>
                              <i className="fa fa-dot-circle-o text-success" />
                              &nbsp;{appLabel.totalRepayment}
                            </div>
                            <span className="vertical-date small text-muted m-t">
                              {item.dateTimeStr}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
});

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageFunderBidDetail);
