import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";

const SwitchModule = (props) => {
  const {onAction, actionValue}=props
  return (
    <Box
      sx={{
        backgroundColor: "#3da58a",
        color: "#ffffff",
        fontSize: "16px",
        fontWeight: "bold",
        padding: "10px 20px",
        borderRadius: "10px",
      }}
    >
      <FormGroup>
        <FormControlLabel
          value={`${actionValue ? "start":"end"}`}
          labelPlacement={`${actionValue ? "start":"end"}`}
          control={<Switch onChange={onAction} color="default"/>}
          label= {`${actionValue ? 'PO':'Invoice'}`}
        />
      </FormGroup>
    </Box>
  );
};

export default SwitchModule;
