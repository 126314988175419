import SidebarFooter from "./SidebarFooter"

const NavScreen = (props) => {
    const{Logo, children}=props

  return (
    <nav
      className="navbar-default navbar-static-side"
      role="navigation"
      style={{ overflow: "auto" }}
    >
      <div className="sidebar-collapse">
        <ul className="nav metismenu" id="side-menu">
          <li className="nav-header">
            <div
              className="dropdown profile-element"
            >
              <span>
                <img
                  alt="image"
                  style={{
                    width: "150px",
                    height: 85,
                    marginLeft: "8px",
                    marginTop: "20px",
                    borderRadius: "20px",
                  }}
                  src={Logo}
                />
              </span>
            </div>
            <div className="logo-element">FeX</div>
          </li>
          {children}
        </ul>
      </div>
      
      <SidebarFooter />
    </nav>
  )
}

export default NavScreen