/**
 * @author Gedeon NdundeCode
 * @date 05/12/2021
 */
import React, { useState, useEffect } from "react";
//  import useGeolocation from "react-hook-geolocation";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { apis as api } from "../../services/api.action";
import { BgImgFac } from "../layouts/Facility";

import { SignOutAction } from "../../services/redux/actions/auth-act";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import Logo from "../../assets/img/logoLogin/fxBg.jpg";
import axios from "axios";
import { SubmitBtnLoad } from "../layouts/Facility";
import GhanaMap from "../../assets/img/logoLogin/map-ghana-polygonal-mesh-line-map-flag-map-scaled.jpg";


const ForgotPassword = (props) => {
  /** INITIALIZE VARIABLES */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { history } = props;

  useEffect(() => {
    // console.log(props);
    SignOutAction();
  }, []);

  /** SUBMISSION */
  const onForgot = (formValues) => {
    const body = {
      ...formValues,
    };

    // console.log(body);
    /** SEND REQ */
    setIsLoading(true);
    BOM.FetchReqAction(body, api.ForgotPassword, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
        setIsLoading(false);
      } else {
        history.push("/sign-in");
      }
    });
  };

  return (
    <React.Fragment>
      <BgImgFac />
      <div className="centeredContent">
        <div className="row">
          <div className="col-md-12">
            <header>
              <div
                className="middle-box loginscreen animated fadeInDown login-card"
                style={{ marginTop: "16%", marginBottom: "0%" }}
              >
                {/* <div className="slogan text-center">
                  <h2><i className="fa fa-send text-navy "/>&nbsp; {appLabel.forgotpass}</h2>
                </div> */}
                <div className="text-center">
                  <img
                    alt="image"
                    style={{
                      width: "150px",
                      marginTop: "10px",
                      marginBottom: "25px",
                    }}
                    src={Logo}
                  />
                </div>
                <p>Please Fill out the form to forget your password.</p>
                <form
                  // className="m-t"
                  onSubmit={handleSubmit(onForgot)}
                  autoComplete="off"
                >
                  <div className="form-group">
                    <label className="text-left">{appLabel.email} </label>
                    <input
                      type="text"
                      className="form-control input-sm"
                      placeholder={appLabel.email}
                      {...register("userEmail", {
                        required: "Email is required!",
                        pattern: {
                          value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                          message: "Email must be a valid email address",
                        },
                      })}
                    />
                    <span className="asterisks">
                      {errors?.userEmail?.message}
                    </span>
                  </div>
                  <div className="form-group text-center">
                    {/* <button
                      type="submit"
                      className="btn btn-primary block full-width"
                      style={{
                        marginTop: 30,
                        boxShadow: "0 10px 6px -6px #fff",
                      }}
                    >
                      <i className="fa fa-arrow-circle-right" /> &nbsp;{" "}
                      {appLabel.proceedBtn}
                    </button> */}
                    <SubmitBtnLoad
                      isLoading={isLoading}
                      btnName={appLabel.proceedBtn}
                      isFullWidth={true}
                      icon={'fa fa-arrow-circle-right'}
                    />
                  </div>
                  <div className="form-group text-center">
                    <Link
                      to="/sign-in"
                      style={{ fontWeight: "bold", color: "rgb(204, 153, 61)" }}
                    >
                      <i className="fa fa-chevron-circle-left" />{" "}
                      <small>{appLabel.goToLogin}</small>
                    </Link>
                  </div>
                </form>
              </div>
            </header>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(null, { SignOutAction })(ForgotPassword);
