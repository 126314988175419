import appLabel from "../../config/appLabel";

const SidebarFooter = () => {
  return (
    <div
      className="profile-element"
      style={{
        marginTop: "50px",
        textAlign: "center",
        color: "azure",
        marginBottom: "20px",
      }}
    >
      <b>{appLabel.version}</b>
      <br />
      {appLabel.poweredBy}{" "}
      <a
        href="https://openfactorgroup.com/"
        target="_blank"
        style={{ fontWeight: "900", fontSize: "12px", color: "#D4AF37" }}
      >
        {"OpenFactor"}
      </a>
    </div>
  );
};

export default SidebarFooter;
