import React, { useEffect, useState } from "react";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import appLabel from "../../config/appLabel";
import { HandleSkeletonDetailFac } from "../layouts/Facility";

export default function PageNotFound(props) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Main {...props}>
      {/* <ContentTop pageTitle={appLabel.sharedInvoice} /> */}
      <div
        className="middle-box text-center animated fadeInDown"
        style={{ margiTop: "0 !important" }}
      >
        <h1>404</h1>
        <h3 className="font-bold">Page Not Found</h3>
        <div className="error-desc">
          Sorry, but the page you are looking for has note been found. Try
          checking the URL for error, then hit the refresh button on your
          browser or try found something else in our app.
        </div>
      </div>

      <HandleSkeletonDetailFac classParam={`${isLoading ? "" : "hide"}`} />
    </Main>
  );
}
