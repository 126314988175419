import SidebarMenuList from "./SidebarMenuList";
import NavScreen from "./NavScreen";
import { HandleSkeletonMenuFac } from "../layouts/Facility";

import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logoLogin/whiteLogo.jpg";
import appLabel from "../../config/appLabel";
import { BOM, RAL } from "../../services/defined.action";
import { cnt } from "../../services/constant.action";
import { apis as api } from "../../services/api.action";
import appStatic from "../../config/appStaticData";
import history from "../../history";

const LeftSidebar = (props) => {
    const {userData, menuData, location, currentMenuData } = props;
    const { profileId, session, userRoleId } = userData;
    const dispatch = useDispatch();
    const [roleMenuData, setRoleMenuData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activeLink, setActiveLink] = useState(location.pathname);
    const [activeLinkParent, setActiveLinkParent] = useState(currentMenuData.DESCRIPTION);

  useEffect(() => {
    getRoleMenu(userRoleId);
  }, []);

  function getRoleMenu(roleId) {
    setIsLoading(true);

    let body = {
      profileId,
      roleId,
      session,
    };

    BOM.FetchReqAction(body, api.GetRoleMenu, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setIsLoading(false);

        var roleMenu = [];
        roleMenu = res.roleData.FUNCTIONS;

        /** ORDER ITM S BY POSITION */
        const myData = []
          .concat(roleMenu)
          .sort((a, b) => (a.POSITION > b.POSITION ? 1 : -1));
        setRoleMenuData([...myData]);

        $("#side-menu").metisMenu();

        //UPDATE USER STORED MENU
        dispatch({
          type: cnt.MENU_DATA,
          payload: roleMenu,
        });

        //HANDLE CREATE RIGHT
        BOM.HandleCreateRight(appStatic.pathData, roleMenu, location.pathname);
      }
    });
  }

  const handleLinkClick = (subObj, parentObj) => {

    setActiveLink(subObj.URL);
    setActiveLinkParent(parentObj.DESCRIPTION)
    history.push(subObj.URL);
    dispatch({
      type: cnt.CURRENT_MENU,
      payload: parentObj,
    });
  };
  return (
    <NavScreen Logo={Logo}>
      {isLoading && <HandleSkeletonMenuFac />}
      <SidebarMenuList
        userMenu={roleMenuData}
        activeLinkParent={activeLinkParent}
        activeLinkSubmenu={activeLink}
        handleLinkClick={handleLinkClick}
      />
    </NavScreen>
  );
};

const mapStateToProps = (state) => ({
    userData: state.auth.userData,
    menuData: state.staticData.menuData,
    currentMenuData: state.staticData.currentMenu,
  });
  const mapDispatchToProps = {};
  export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);
