import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "./Report.css";
import Main from "../layouts/Main";
import { useForm } from "react-hook-form";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import NumberFormat from "react-number-format";
import { apis as api } from "../../services/api.action";
import appStatic from "../../config/appStaticData";
import appLabel from "../../config/appLabel";
import {
  TableFacility2,
  Tooltips,
  AmntFormatFac,
  Col12BlockFacility,
} from "../layouts/Facility";

export const SettlementReport = (props) => {
  const { tableData, toolTip, reporting } = appStatic;
  const { settlementLabelData } = reporting;
  const {
    register,
    handleSubmit,
    unregister,
    formState: { errors },
    setValue,
  } = useForm();

  const { userData } = props;
  const { profileId, session, companyId, companyTypeId } = userData;

  const [selectedItemObj, setSelectedItemObj] = useState({});
  const [selectedItemType, setSelectedItemType] = useState("Item ");
  const [companyData, setCompanyData] = useState([]);
  const [settlementDownload, setSettlementDownload] = useState([]);
  const [stlmtFunderData, setStlmtFunderData] = useState([]);
  const [stlmtSupplierData, setStlmtSupplierData] = useState([]);
  const [labelData, setLabelData] = useState([]);
  const [itemTypeData, setItemTypeData] = useState([]);
  const [isFunder, setIsFunder] = useState(false);
  const [isSupplier, setIsSupplier] = useState(false);
  const [isCustodian, setIsCustodian] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDateRange, setIsDateRange] = useState(false);
  const [isItemId, setIsItemId] = useState(false);

  requiredJqueryDOM();
  useEffect(() => {
    // getCompany();
    // setLabelData(settlementLabelData);
    handleSwitchCondition(settlementLabelData);
  }, []);
  function requiredJqueryDOM() {
    let d = new Date();
    d.setMonth(d.getMonth() - 3);
    let lastThreeMonth = BOM.ReformatDate(d.toLocaleDateString());

    // console.log("lastThreeMonth ", lastThreeMonth);
    $(document).ready(function () {
      $(".startDate").datepicker({
        todayBtn: "linked",
        keyboardNavigation: false,
        forceParse: false,
        calendarWeeks: true,
        autoclose: true,
        // startDate: new Date(lastThreeMonth),
        endDate: new Date(BOM.CurrentDate()),
      });

      $(".endDate").datepicker({
        todayBtn: "linked",
        keyboardNavigation: false,
        forceParse: false,
        calendarWeeks: true,
        autoclose: true,
        // startDate: new Date(lastThreeMonth),
        endDate: new Date(BOM.CurrentDate()),
      });
    });
  }
  const onSelectReport = (idParam) => {
    // console.log(idParam);
    unregister("");
    setItemTypeData([]);
    setLabelData([]);
    setSelectedItemObj({});
    setSettlementDownload([]);

    let searchTypeLoc = [
      { value: "date", name: "Date Range" },
      { value: "itemno", name: "Item No" },
    ];
    // setIsLoading(true);

    /** SOME LOGIC ON THE SELECTED CARD */
    let newArray = settlementLabelData.map((item) =>
      item.id === idParam
        ? { ...item, isSelected: 1 }
        : { ...item, isSelected: 0 }
    );

    /** DISPLAY CARD ACCORDING TO THE ENTITY*/
    handleSwitchCondition(newArray);

    // GET THE SELECTED OBJECT
    const selectedObj = newArray.find((item) => item.id === idParam);
    // const { itemType } = selectedObj;

    setSelectedItemObj(selectedObj);

    setItemTypeData(searchTypeLoc);
  };
  const onSelectType = (key) => {
    // console.log(key);
    // CLEAR ALL PREVIOUS TYPE
    setIsDateRange(false);
    setIsItemId(false);
    unregister("itemId");
    unregister("startDate");
    unregister("endDate");

    $(".clearInput").val("");

    switch (key.toLowerCase()) {
      case "date":
        setIsDateRange(true);
        break;
      case "itemno":
        setIsItemId(true);
        break;
      default:
        setIsDateRange(false);
        setIsItemId(false);
        $(".clearInput").val("");
        break;
    }
  };
  const onChooseItempType = (keyParam) => {
    setSelectedItemType(keyParam);
  };
  const handleSwitchCondition = (arrParam) => {
    const additionalData = [
      { name: appLabel.rate },
      { name: appLabel.taxAmount },
    ];

    switch (companyTypeId) {
      case "CT2022012400000": // depository/custodian
        setLabelData(arrParam);

        /** ADD ADDITIONAL KEY TO THE EXISTING ARRAY */
        const tempArr = [];
        // additionalData.forEach((element) => {
        //   tempArr.push(element);
        // });

        setStlmtSupplierData([
          ...tableData.stlmtSupplierList,
          ...BOM.GetUnique(tempArr, "name"),
        ]);
        setStlmtFunderData([
          ...tableData.stlmtFunderList,
          ...BOM.GetUnique(tempArr, "name"),
        ]);

        setIsCustodian(true);
        break;
      case "CT2022012400002": // Supplier
        setLabelData(
          arrParam.filter((item) => item.name.toLowerCase() === "supplier")
        );
        /** SEND DEFAULT ARRAY LIST */
        setStlmtSupplierData(tableData.stlmtSupplierList);
        setIsSupplier(true);
        break;
      case "CT2022012400003": // Funder
        setLabelData(
          arrParam.filter((item) => item.name.toLowerCase() === "funder")
        );

        /** SEND DEFAULT ARRAY LIST */
        setStlmtFunderData(tableData.stlmtFunderList);
        setIsFunder(true);
        break;
    }
  };
  const onSearch = (formValues) => {
    // console.log(selectedItemObj);
    setSettlementDownload([]);
    let startDate = $(".startDate").val();
    let endDate = $(".endDate").val();
    let reqUrl = "";

    // CALL URL BASED ENTITY TYPE SELECTED
    switch (selectedItemObj.name.toLowerCase()) {
      case "funder":
        reqUrl = api.GetFunderSettlementReport;
        break;
      case "supplier":
        reqUrl = api.GetSupplierSettlementReport;
        break;
    }
    if (!reqUrl) {
      BOM.AlertMsg(
        cnt.WARNING,
        "The request URL is undefined. Please get in touch with the developer support staff"
      );
      return;
    }

    if (!startDate && isDateRange) {
      BOM.AlertMsg(cnt.WARNING, "Start date is required.");
      return;
    }

    if (!endDate && isDateRange) {
      BOM.AlertMsg(cnt.WARNING, "End date is required.");
      return;
    }

    formValues.startDate = startDate ? BOM.ReformatDate(startDate) : "";
    formValues.endDate = endDate ? BOM.ReformatDate(endDate) : "";

    /** REMOVE KEYS THAT THE BACKEND DOES NOT NEED */
    delete formValues.criteria;

    let body = {
      profileId,
      session,
      ...formValues,
    };

    // console.log(body);
    // return;
    /** FETCH ROLE */
    BOM.LoadAlert(cnt.LOAD, "Searching...");
    BOM.FetchReqAction(body, reqUrl, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.settlementListing;
        setSettlementDownload(objList);
        BOM.DatatableWithNoPdfDownloadClass();
      }
    });
  };

  return (
    <Main {...props}>
      <div className="row">
        {labelData.length === 0 && (
          <div className="col-md-12" style={{ marginTop: "20%" }}>
            <div className="alert alert-info text-center">
              <a className="alert-link">{appLabel.downloadEntityMsg}</a>
            </div>
          </div>
        )}
        <div className="col-md-12" style={{ marginTop: "20px" }}>
          {labelData.map((item, index) => (
            <div
              className="col-md-6 col-lg-6"
              key={index}
              onClick={() => onSelectReport(item.id)}
            >
              <div className="ibox">
                <div
                  className={`ibox-content ${
                    item.isSelected ? "reportCardSelected" : "reportCard"
                  }`}
                >
                  <h5>{item.nameTwo}</h5>
                  <h3>
                    {" "}
                    <i className={`${item.icon} text-navy`} /> &nbsp;{" "}
                    {item.name}
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </div>
        {isLoading && (
          <div className="col-md-12" style={{ marginTop: "10%" }}>
            <div className="alert alert-defau text-center">
              <i className="fa fa-spinner fa-spin fa-5x text-success" />
            </div>
          </div>
        )}
      </div>
      <div className="animated fadeInRight " style={{ marginTop: "20px" }}>
        {itemTypeData.length !== 0 && (
          <>
            <div className="row">
              <form className="m-t" autoComplete="off">
                <div className="col-lg-12">
                  <div className="ibox-title">
                    <h5>{selectedItemObj.name}</h5>
                  </div>
                  <div className="ibox-content m-b-sm border-bottom contentCard panel-primary">
                    <div className="row">
                      <div className="col-sm-12">
                        <label className="al-heading">
                          <i className="fa fa-search text-navy"></i>&nbsp;{" "}
                          {appLabel.searchCriteria}
                        </label>
                        <div
                          className="hr-line-dashed"
                          style={{ marginTop: 0 }}
                        />
                        <div className="col-sm-2">
                          <div className="form-group">
                            <label className="control-label">
                              {appLabel.criteria}&nbsp;
                              <Tooltips title={toolTip.criteria} />
                              <span className="asterisks">*</span>
                            </label>
                            <select
                              className="form-control input-sm"
                              {...register("criteria", {
                                required: "Field is required.",
                                onChange: (e) => onSelectType(e.target.value),
                              })}
                            >
                              <option value="">{appLabel.choose}</option>
                              {itemTypeData.map((item, index) => (
                                <option value={item.value} key={index}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            <span className="asterisks">
                              {errors?.criteria?.message}
                            </span>
                          </div>
                        </div>

                        <div
                          className={`col-sm-2 ${
                            isDateRange || isItemId ? "" : "hide"
                          }`}
                        >
                          <div className="form-group">
                            <label className="control-label">
                              {appLabel.itemType}&nbsp;
                              <Tooltips title={toolTip.searchType} />
                              <span className="asterisks">*</span>
                            </label>
                            <select
                              className="form-control input-sm clearInput"
                              {...register("itemType", {
                                required: "Field is required.",
                                onChange: (e) =>
                                  onChooseItempType(e.target.value),
                              })}
                            >
                              <option value="">{appLabel.choose}</option>
                              {appStatic.itemType2.map((item, index) => (
                                <option value={item.value} key={index}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            <span className="asterisks">
                              {errors?.itemType?.message}
                            </span>
                          </div>
                        </div>
                        <div className={`col-sm-3 ${isItemId ? "" : "hide"}`}>
                          <div className="form-group">
                            <label className="control-label">
                              {`${selectedItemType} No`}
                              <span className="asterisks">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control input-sm clearInput"
                              placeholder={`${selectedItemType} No`}
                              {...register("itemId", {
                                required: isItemId
                                  ? "Field is required."
                                  : false,
                              })}
                            />
                            <span className="asterisks">
                              {errors?.itemId?.message}
                            </span>
                          </div>
                        </div>
                        <div
                          className={`col-sm-3 ${isDateRange ? "" : "hide"}`}
                        >
                          <div className="form-group">
                            <label
                              className="control-label"
                              htmlFor="startDate"
                            >
                              {appLabel.startDate}&nbsp;
                              <Tooltips title={toolTip.startDate} />
                              <span className="asterisks">*</span>
                            </label>
                            <div className="input-group date">
                              <span className="input-group-addon">
                                <i className="fa fa-calendar" />
                              </span>
                              <input
                                id="startDate"
                                type="text"
                                className="form-control input-sm clearInput startDate"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className={`col-sm-3 ${isDateRange ? "" : "hide"}`}
                        >
                          <div className="form-group">
                            <label className="control-label" htmlFor="endDate">
                              {appLabel.endDate}&nbsp;
                              <Tooltips title={toolTip.endDate} />
                              <span className="asterisks">*</span>
                            </label>
                            <div className="input-group date">
                              <span className="input-group-addon">
                                <i className="fa fa-calendar" />
                              </span>
                              <input
                                // id="endDate"
                                type="text"
                                className="form-control input-sm clearInput endDate"
                              />
                            </div>
                          </div>
                        </div>
                        <div className={`col-sm-2`}>
                          <div className="form-group">
                            <label className="control-label">&nbsp;</label>
                            <div className="input-group date">
                              <button
                                type="button"
                                onClick={handleSubmit(onSearch)}
                                className="btn btn-primary btn-sm"
                              >
                                <i className="fa fa-search" />
                                &nbsp;
                                {appLabel.search}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Col12BlockFacility topTitle={appLabel.result}>
                        {settlementDownload.length === 0 ? (
                          <div className="alert alert-warning text-center">
                            <a className="alert-link">
                              {appLabel.noDataInTableMsg}
                            </a>
                          </div>
                        ) : (
                          <div>
                            {selectedItemObj.name.toLowerCase() ===
                              "supplier" && (
                              <TableFacility2 thData={stlmtSupplierData}>
                                {/* SUPPLIER TABLE */}
                                {settlementDownload.map((item, index) => (
                                  <tr className="gradeX" key={index}>
                                    <td>
                                      <i className="fa fa-line-chart text-success" />
                                    </td>
                                    <td>{item.ITEM_NUMBER}</td>
                                    <td>{item.NOTE_NAME}</td>
                                    <td>{item.NOTE_NUMBER}</td>
                                    <td>{item.NOTE_CODE}</td>
                                    <td>{item.SUPPLIER}</td>
                                    <td>
                                      <AmntFormatFac
                                        inputValue={item.CAPITAL_REQUIRED}
                                      />
                                    </td>
                                    <td>{item.INTEREST_RATE}</td>
                                    <td>
                                      <AmntFormatFac
                                        inputValue={item.INTEREST_AMOUNT}
                                      />
                                    </td>
                                    <td>{item.TENOR}</td>
                                    <td>{item.VALUE_DATE}</td>
                                    <td>{item.MATURITY_DATE}</td>
                                    <td>
                                      <AmntFormatFac
                                        inputValue={item.ARRANGEMENT_FEE}
                                      />
                                    </td>
                                    <td>
                                      <AmntFormatFac
                                        inputValue={item.OTHER_FEE_1}
                                      />
                                    </td>
                                    <td>
                                      <AmntFormatFac
                                        inputValue={item.OTHER_FEE_2}
                                      />
                                    </td>
                                    <td>
                                      <AmntFormatFac
                                        inputValue={item.TOTAL_REPAYMENT}
                                      />
                                    </td>
                                    <td>
                                      <AmntFormatFac
                                        inputValue={item.TAX_AMOUNT}
                                      />
                                    </td>
                                    <td>{item.SUPPLIER_BANK_NAME}</td>
                                    <td>{item.SUPPLIER_ACC_NAME}</td>
                                    <td>{item.SUPPLIER_ACC_NUM}</td>
                                    {/* {isCustodian && (
                                      <>
                                        <td>
                                          <AmntFormatFac
                                            inputValue={item.TAX_RATE}
                                          />
                                        </td>
                                        <td>
                                          <AmntFormatFac
                                            inputValue={item.TAX_AMOUNT}
                                          />
                                        </td>
                                      </>
                                    )} */}
                                  </tr>
                                ))}
                              </TableFacility2>
                            )}
                            {selectedItemObj.name.toLowerCase() ===
                              "funder" && (
                              <TableFacility2 thData={stlmtFunderData}>
                                {/* FUNDER TABLE */}
                                {settlementDownload.map((item, index) => (
                                  <tr className="gradeX" key={index}>
                                    <td>
                                      <i className="fa fa-line-chart text-success" />
                                    </td>
                                    <td>{item.ITEM_NUMBER}</td>
                                    <td>{item.NOTE_NAME}</td>
                                    <td>{item.NOTE_NUMBER}</td>
                                    <td>{item.NOTE_CODE}</td>
                                    <td>{item.FUNDER}</td>
                                    <td>
                                      <AmntFormatFac
                                        inputValue={item.FUNDED_AMOUNT}
                                      />
                                    </td>
                                    <td>
                                      <AmntFormatFac
                                        inputValue={item.ALLOTED_AMOUNT}
                                      />
                                    </td>
                                    <td>{item.INTEREST_RATE}</td>
                                    <td>
                                      <AmntFormatFac
                                        inputValue={item.INTEREST_AMOUNT}
                                      />
                                    </td>
                                    <td>{item.TENOR}</td>
                                    <td>{item.VALUE_DATE}</td>
                                    <td>{item.MATURITY_DATE}</td>
                                    <td>
                                      <AmntFormatFac
                                        inputValue={item.TRANSACTION_FEE}
                                      />
                                    </td>
                                    <td>
                                      <AmntFormatFac
                                        inputValue={item.OTHER_FEE_1}
                                      />
                                    </td>
                                    <td>
                                      <AmntFormatFac
                                        inputValue={item.OTHER_FEE_2}
                                      />
                                    </td>
                                    <td>
                                      <AmntFormatFac
                                        inputValue={item.TOTAL_SETTLEMENT_AMOUNT}
                                      />
                                    </td>
                                    <td>
                                      <AmntFormatFac
                                        inputValue={item.TAX_AMOUNT}
                                      />
                                    </td>
                                    <td>{item.FUNDER_BANK_NAME}</td>
                                    <td>{item.FUNDER_ACC_NAME}</td>
                                    <td>{item.FUNDER_ACC_NUM}</td>

                                    {/* {isCustodian && (
                                      <>
                                        <td>
                                          <AmntFormatFac
                                            inputValue={item.TAX_RATE}
                                          />
                                        </td>
                                        <td>
                                          <AmntFormatFac
                                            inputValue={item.TAX_AMOUNT}
                                          />
                                        </td>
                                      </>
                                    )} */}
                                  </tr>
                                ))}
                              </TableFacility2>
                            )}
                          </div>
                        )}
                      </Col12BlockFacility>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SettlementReport);
