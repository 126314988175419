import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Main from "../../layouts/Main";
import { connect, useDispatch } from "react-redux";
import { apis as api } from "../../../services/api.action";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM, RAL } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import appStatic from "../../../config/appStaticData";
import { TableSimpleFac, AmntFormatFac } from "../../layouts/Facility";
import RightSide from "../../ui-setup/RightSide";
import { HandleSkeletonTbleFac } from "../../layouts/Facility";

const GetFunderBids = (props) => {
  const { userData, itemId } = props;
  const { profileId, session, companyId } = userData;
  const { tableData } = appStatic;
  const [bidData, setBidData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // console.log(props);
    getBidList(itemId);
  }, []);

  function getBidList(itemId) {
    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true);

    let body = {
      profileId,
      itemId,
      session,
    };

    BOM.FetchReqAction(body, api.GetIntentFunders, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.funderListing;
        setBidData(objList);
        // BOM.DatatableUsage();
        setIsLoading(false);
        $(".footable").footable();
      }
    });
  }
  return (
    <>
      <div className={`${bidData.length === 0 ? 'hide':'col-xs-12'}`}>
        <div className="row">
          <div className="col-lg-12">
            <dl className="dl-horizontal">
              <dt>{appLabel.bidMethod} :</dt>{" "}
              <dd>
                {bidData[0]?.IS_AUTO_BID?.toLowerCase() === "y" ? (
                  <span className="label label-primary">
                    {appLabel.automatic}
                  </span>
                ) : (
                  <span className="label label-info">{appLabel.manual}</span>
                )}
              </dd>
            </dl>
          </div>
        </div>
        <div className="hr-line-dashed" />
      </div>

      <div className="col-sm-12">
        <HandleSkeletonTbleFac classParam={`${isLoading ? "" : "hide"}`} />
        <div className={` ${isLoading ? "hide" : ""}`}>
          {bidData.length === 0 ? (
            <div className="alert alert-warning text-center">
              <a className="alert-link">{appLabel.noDataInTableMsg}</a>
            </div>
          ) : (
            <TableSimpleFac thData={tableData.bidByFunder}>
              {bidData.map((item, index) => (
                <tr className="gradeX" key={index}>
                  <td>{index + 1}</td>
                  <td>{item.FUNDER_NAME}</td>
                  <td>{item.FUNDER_RATE}</td>
                  <td>
                    <AmntFormatFac inputValue={item.FUNDER_AMOUNT} />
                  </td>
                  <td>
                    {
                      <i
                        className={`text-${
                          item?.IS_AUTO_BID?.toLowerCase() === "y"
                            ? "navy fa fa-check-circle"
                            : "info fa fa-check-circle"
                        }`}
                      />
                      
                    }
                    &nbsp;
                    {item.SUPPLIER_STATUS}
                  </td>
                </tr>
              ))}
            </TableSimpleFac>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});

export default connect(mapStateToProps, null)(GetFunderBids);
