import React, { useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../services/api.action";
import { connect } from "react-redux";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../ui-setup/RecordStatus";
import appStatic from "../../config/appStaticData";
import RightSide from "../ui-setup/RightSide";
import NumberFormat from "react-number-format";
import {
  AmountFacility,
  Tooltips,
  InputTextFacility,
  InputAmountFacility,
  Col12BlockFacility,
  NoDataFac,
} from "../layouts/Facility";

export const NewInvoice = (props) => {
  const { location, userData, configData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId } = userData;
  const { isCsd } = configData;
  const { toolTip } = appStatic;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const [documents, setDocuments] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [tempFile, setTempFile] = useState(false);
  const [docCategory, setDocCategory] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [orderDetail, setOrderDetail] = useState({});
  const [shipFromData, setShipFromData] = useState({});
  const [billFromData, setBillFromData] = useState({});
  const [shipToData, setShipToData] = useState({});
  const [billToData, setBillToData] = useState({});
  const [isPO, setIsPO] = useState(false);
  const [fromCompanyDetail, setFromCompanyDetail] = useState({});
  const [toCompanyDetail, setToCompanyDetail] = useState({});
  const [taxDetail, setTaxDetail] = useState({});
  const [amount, setAmount] = useState(undefined);
  const [isRequiredField, setIsRequiredField] = useState(true);
  const [inputFldData, setInputFldData] = useState([]);
  const currentDate = BOM.CurrentDate();

  useEffect(() => {
    // getOrder();
    // console.log(state);
    onChooseSpecState("po", ggParam.poId);
    setValue("orderId", ggParam.poId);
    getTax();
    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);
  }, []);

  function getOrder() {
    let body = {
      profileId,
      orderId: "",
      criteria: "A",
      companyId,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetPo, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let poInvoilessData = res.purchaseOrderListing.filter(
          (item) => item.PO_INVOICE_CODE === ""
        );
        setOrderData(poInvoilessData);
      }
    });
  }
  const handleFileRead = async (event, setState_param) => {
    const file = event.target.files[0];
    if (!file) {
      // if file is empty
      setState_param("");
      return;
    }

    if (file.size > 5242880) {
      BOM.AlertMsg(cnt.WARNING, "File size cannot exceed 5 MB");
      $(".documentInput").val("");
      return;
    }

    switch (file.type.toLowerCase()) {
      case "application/pdf":
        const base64 = await BOM.convertBase64(file);
        let strB64 = base64.split("base64,")[1]; //.replace(/^data:image\/[a-z]+;base64,/, "");
        setState_param(strB64);
        break;

      default:
        BOM.AlertMsg(cnt.WARNING, "Document must be a PDF.");
        $(".documentInput").val("");
        break;
    }

    // console.log(file.type);
  };
  const uploadFile = () => {
    // console.log(tempFile);

    const itemId = documents.find(
      (item, index) => item.docCategory === docCategory
    );

    if (itemId) {
      BOM.AlertMsg(cnt.WARNING, "No duplicate file allowed!");
      return;
    }

    if (!tempFile || !docCategory) {
      BOM.AlertMsg(cnt.WARNING, "Fields with * are required");
      return;
    }

    setDocuments([
      ...documents,
      {
        base64: tempFile,
        docType: "invoice",
        docCategory,
      },
    ]);
    $(".documentInput").val("");
  };
  const removeItem = (index, type) => {
    switch (type.toLowerCase()) {
      case "document":
        setDocuments([
          ...documents.slice(0, index),
          ...documents.slice(index + 1),
        ]);
        break;
      case "material":
        break;
    }
  };
  const onChooseSpecState = (key, idParam) => {
    if (!idParam) {
      setIsPO(false);
      return;
    }

    switch (key.toLowerCase()) {
      case "po":
        let body = {
          profileId,
          orderId: idParam,
          criteria: "A",
          companyId,
          session,
        };
        BOM.LoadAlert(cnt.LOAD, "Processing");

        /** FETCH ROLE */
        BOM.FetchReqAction(body, api.GetPo, (err, res) => {
          if (err) {
            BOM.AlertMsg(cnt.DANGER, err);
          } else {
            let orderDetail = res.purchaseOrderDetail;
            setOrderDetail(orderDetail);

            getCompanyById(
              orderDetail.PO_FROM_COMPANYID,
              setShipFromData,
              setBillFromData,
              setFromCompanyDetail
            );
            getCompanyById(
              orderDetail.PO_TO_COMPANYID,
              setShipToData,
              setBillToData,
              setToCompanyDetail
            );
            setIsPO(true);

            // mergedDataArr
            setInputFldData([
              {
                name: appLabel.poNo,
                value: orderDetail.ORDERID,
                fldType: "text",
                section: "general",
              },
              {
                name: appLabel.from,
                value: orderDetail.FROM_COMPANY_NAME,
                fldType: "text",
                section: "general",
              },
              {
                name: appLabel.to,
                value: orderDetail.TO_COMPANY_NAME,
                fldType: "text",
                section: "general",
              },

              {
                name: appLabel.poAmt,
                value: orderDetail.PO_AMOUNT,
                fldType: "amount",
                section: "general",
              },

              {
                name: appLabel.currency,
                value: orderDetail.PO_CCY,
                fldType: "text",
                section: "general",
              },

              {
                name: appLabel.pymtOption,
                value: orderDetail.PO_PAYMENT_OPTION,
                fldType: "text",
                section: "general",
              },
            ]);
          }
        });
        break;
      case "invoice":
        break;
    }
  };
  function getTax() {
    // BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      taxId: "",
      criteria: "A",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetTax, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setTaxData(res.taxListing);
      }
    });
  }

  const getCompanyById = (
    companyId,
    setShipParam,
    setBillParam,
    setCompanyDetail
  ) => {
    let body = {
      profileId,
      companyId,
      session,
    };

    /** FETCH */
    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let companyDetail = res.companyDetail;

        setShipParam(companyDetail.SHIP_DATA);
        setBillParam(companyDetail.BILL_DATA);
        setCompanyDetail(companyDetail);
      }
    });
  };

  const onChooseTax = (idParam) => {
    if (!idParam) {
      return;
    }

    let taxDetail = taxData.find((item) => item.TAX_ID === idParam);
    setTaxDetail(taxDetail);
  };
  const formSubmit = (formValues) => {
    if (!amount) {
      BOM.AlertMsg(cnt.WARNING, "All fields with * are required.");
      return;
    }

    if (parseFloat(orderDetail.PO_AMOUNT) < parseFloat(amount)) {
      BOM.AlertMsg(
        cnt.WARNING, "Invoice Amount should no exceed the PO Amount."
      );
      return;
    }

    if (documents.length === 0) {
      BOM.AlertMsg(cnt.WARNING, "Document upload is required.");
      return;
    }

    /** BUILD ORDER BODY */
    // some calculation

    const invoiceAmount = amount;
    let totaInvoice = parseFloat(invoiceAmount);

    formValues.documents = documents;
    formValues.taxCode = "";
    formValues.currency = orderDetail.PO_CCY;
    formValues.remitToCompanyId = "";
    formValues.billToCompanyId = fromCompanyDetail.COMPANY_ID;
    formValues.supplierCompanyId = toCompanyDetail.COMPANY_ID;
    formValues.customerCompanyId = "";
    formValues.shipFromCompanyId = "";
    formValues.shipToCompanyId = "";
    formValues.paymentMethod = orderDetail.PO_PAYMENT_OPTION;
    formValues.invDiscountCode = "";
    formValues.taxType = "";
    formValues.originalAmount = invoiceAmount + "";
    formValues.discountAmount = "0";
    formValues.adjustment = "0";
    formValues.finalAmount = totaInvoice.toString();
    formValues.taxRate = "0";
    formValues.taxableAmount = "0";
    formValues.taxAmount = "0";
    formValues.totalAmount = totaInvoice.toString();

    let body = {
      profileId,
      session,
      ...formValues,
    };

    // console.log(body);
    // return
    /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(body, api.CreateInvoice, "/manage-invoice");
  };

  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <RightSide />
        <ContentTop pageTitle={appLabel.newInvoice} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{appLabel.invoiceInformation}</h5>
                  <div className="ibox-tools">
                    <Link
                      to="/invoice-po-clean-list"
                      className="btn btn-primary btn-xs"
                    >
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <Col12BlockFacility topTitle={appLabel.generalInfo}>
                      {inputFldData.map((item, index) => (
                        <div key={index}>
                          {item.section === "general" &&
                            (item.fldType === "amount" ? (
                              <InputAmountFacility
                                inputLabel={item.name}
                                inputValue={item.value}
                              />
                            ) : (
                              <InputTextFacility
                                inputLabel={item.name}
                                inputValue={item.value}
                              />
                            ))}
                        </div>
                      ))}
                      <NoDataFac arrList={inputFldData} />
                    </Col12BlockFacility>
                    <Col12BlockFacility>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.amount}&nbsp;
                            <Tooltips title={toolTip.invPoAmount} />
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <AmountFacility
                            setIsRequiredField={setIsRequiredField}
                            setAmount={setAmount}
                            isRequiredField={isRequiredField}
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.invIssueDate}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="date"
                            className="form-control input-sm"
                            max={`${currentDate}`}
                            {...register("dueDate", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.dueDate?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.invPymtDate}&nbsp;
                            <Tooltips title={toolTip.fundingDate} />
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="date"
                            min={`${currentDate}`}
                            className="form-control input-sm"
                            {...register("plannedPaymentDate", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.plannedPaymentDate?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.invRefId}
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.invRefId}
                            {...register("lomCode", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.lomCode?.message}
                          </span>
                        </div>
                      </div>
                    </Col12BlockFacility>
                  </div>
                  {/* <div
                    className="row"
                    style={{ marginTop: "15px" }}
                  >
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.addressInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="col-md-3">
                        <div className="well">
                          <address>
                            <h4>
                              {" "}
                              <i className="fa fa-building text-navy"></i>
                              &nbsp; {appLabel.shipBuyerAddress}
                            </h4>{" "}
                            <i className="fa fa-circle text-navy"></i> &nbsp;{" "}
                            {shipFromData.SHIPPING_ADDRESS_1},{" "}
                            {shipFromData.SHIPPING_CITY_NAME}
                            <br />
                            {shipFromData.SHIPPING_PROVINCE_NAME},{" "}
                            {shipFromData.SHIPPING_COUNTRY_NAME}{" "}
                            {shipFromData.SHIPPING_POST_CODE}
                          </address>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="well">
                          <address>
                            <h4>
                              {" "}
                              <i className="fa fa-building text-navy"></i>
                              &nbsp;{appLabel.bilBuyerAddress}
                            </h4>{" "}
                            <i className="fa fa-circle text-navy"></i>
                            &nbsp;{billFromData.BILLING_ADDRESS_1},{" "}
                            {billFromData.BILLING_CITY_NAME}
                            <br />
                            {billFromData.BILLING_PROVINCE_NAME},{" "}
                            {billFromData.BILLING_COUNTRY_NAME}{" "}
                            {billFromData.BILLING_POST_CODE}
                          </address>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.otherInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <ul className="nav nav-tabs">
                        <li className="active">
                          <a data-toggle="tab" href="#documentInfo">
                           <i className="fa fa-file-pdf-o text-navy"  />
                            &nbsp;{appLabel.documentInfo}
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#addressInfo">
                            <i className="fa fa-home text-navy" />
                            &nbsp;{appLabel.address}
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="documentInfo">
                          <div className="row" style={{ marginTop: "30px" }}>
                            <div className="col-sm-12">
                              <p className="text-danger">
                                {appLabel.maxFileUploadMsg}
                              </p>
                            </div>
                            <div className="col-sm-12">
                              <div className="col-md-6">
                                <div className="row form-group has-errorr">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle control-label">
                                      {appLabel.documentType}{" "}
                                      <Tooltips title={toolTip.docType} />
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <select
                                      className="form-control input-sm documentInput"
                                      onChange={(e) =>
                                        setDocCategory(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {appStatic.invoiceType.map(
                                        (item, index) => (
                                          <option value={item.id} key={index}>
                                            {item.name}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="row form-group">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle ">
                                      {appLabel.documentUpload}{" "}
                                      <Tooltips title={toolTip.docUpload} />
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <input
                                      type="file"
                                      className="form-control input-sm documentInput"
                                      accept=".pdf"
                                      onChange={(e) =>
                                        handleFileRead(e, setTempFile)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row form-group">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle ">
                                      &nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <button
                                      type="button"
                                      onClick={() => uploadFile()}
                                      className="btn btn-primary btn-xs"
                                    >
                                      <i className="fa fa-upload" />
                                      &nbsp; Upload
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="col-sm-4 al-subtitle"></label>
                                  <div className="col-sm-8">
                                    {documents.map((item, index) => (
                                      <div className="i-checks" key={index}>
                                        <label className="control-label">
                                          <i
                                            style={{ cursor: "pointer" }}
                                            className="fa fa-times-circle text-danger fa-2x"
                                            onClick={() =>
                                              removeItem(index, "document")
                                            }
                                          />
                                          &nbsp; &nbsp;
                                          <i className="fa fa-file-pdf-o fa-2x" style={{color:'#d50000'}} />
                                          &nbsp; {item.docCategory}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="tab-pane" id="pOInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          {Object.keys(orderDetail).length === 0 ? (
                            <div className="col-sm-12">
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDataMsg}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-12">
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.poNo}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.ORDERID}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.from}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.FROM_COMPANY_NAME}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.to}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.TO_COMPANY_NAME}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.amount}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <NumberFormat
                                    className="form-control input-sm"
                                    value={orderDetail.PO_AMOUNT}
                                    displayType="text"
                                    disabled
                                    thousandSeparator={true}
                                    prefix={""}
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.currency}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.PO_CCY}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.pymtOption}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.PO_PAYMENT_OPTION}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        </div> */}
                        <div className="tab-pane" id="addressInfo">
                          <div className="row" style={{ marginTop: "30px" }}>
                            <Col12BlockFacility topTitle={null}>
                              <div className="col-md-3">
                                <div className="well">
                                  <address>
                                    <h4>
                                      {" "}
                                      <i className="fa fa-building text-navy"></i>
                                      &nbsp; {appLabel.shipBuyerAddress}
                                    </h4>{" "}
                                    <br />
                                    <i className="fa fa-circle text-navy"></i>{" "}
                                    &nbsp; {shipFromData.SHIPPING_ADDRESS_1},{" "}
                                    {shipFromData.SHIPPING_CITY_NAME}
                                    <br />
                                    {shipFromData.SHIPPING_PROVINCE_NAME},{" "}
                                    {shipFromData.SHIPPING_COUNTRY_NAME}{" "}
                                    {shipFromData.SHIPPING_POST_CODE}
                                  </address>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="well">
                                  <address>
                                    <h4>
                                      {" "}
                                      <i className="fa fa-building text-navy"></i>
                                      &nbsp;{appLabel.bilBuyerAddress}
                                    </h4>{" "}
                                    <i className="fa fa-circle text-navy"></i>
                                    &nbsp;{billFromData.BILLING_ADDRESS_1},{" "}
                                    {billFromData.BILLING_CITY_NAME}
                                    <br />
                                    {billFromData.BILLING_PROVINCE_NAME},{" "}
                                    {billFromData.BILLING_COUNTRY_NAME}{" "}
                                    {billFromData.BILLING_POST_CODE}
                                  </address>
                                </div>
                              </div>
                            </Col12BlockFacility>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <RecordStatus {...userDetail} /> */}
        </div>
      </form>
      <RecordStatus {...BOM.NewRecordStatusdata()} />
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  configData: state.auth.configData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewInvoice);
