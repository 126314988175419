/**
 * @author Gedeon NdundeCode
 * @date 01/02/2022
 */
import { apis as api } from "../../api.action";
import { BOM } from "../../defined.action";
import history from "../../../history";
import { cnt } from "../../constant.action";

export const getCountryActionFAKE = (body) => {
  // BOM.LoadAlert(cnt.LOAD, "Processing");

  return async (dispatch) => {
    BOM.FetchReqAction(body, api.GetCountry, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        dispatch({
          type: cnt.COUNTRY_DATA,
          payload: res.countryListing,
        });
      }
    });
  };
};

export const rightAction = (btnType) => {
  // console.log(btnType);
  return (dispatch) => {
    dispatch({
      type: btnType,
    });
  };
};

export const getCompanyAction = (body) => {
  return async (dispatch) => {
    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        console.log(res);
        const objList = res.companyListing;
        dispatch({
          type: cnt.COMPANY_LISTING,
          payload: objList,
        });
      }
    });
  };
};
