import React, { useEffect, useState } from "react";
import { apis as api } from "../../../services/api.action";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { cnt } from "../../../services/constant.action";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import history from "../../../history";
import RecordStatus from "../../ui-setup/RecordStatus";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import RightSide from "../../ui-setup/RightSide";

function EditUser(props) {
  const {
    register,
    handleSubmit,
    formState: { errors }, setValue, getValues
  } = useForm();
  const { location, userData } = props;
  const { state } = location;
  const dispatch = useDispatch();
  const { profileId, session, companyId } = userData;
  const [countryData, setCountryData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [subRoleData, setSubRoleData] = useState([]);
  const [passwordShown, setPasswordShown] = useState(false);
  const [userDetail, setUserDetail] = useState({});

  const [cityId, setCityId] = useState("");
  const [roleId, setRoleId] = useState("");

  useEffect(() => {
    // console.log(props);
    getDetailById(state.ggParam);
    getCountry();
    getRole();
    
    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);
  }, []);

  const getDetailById= (params)=> {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      companyId:params.COMPANY_ID,
      userId: params.USERID,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetUser, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let userDetail = res.userDetail;

        setUserDetail(userDetail);
        getProvince(userDetail.COUNTRY_ID)
        getCity(userDetail.PROVINCE_ID)
        getSubRole(userDetail.USER_PARENT_ROLE_ID);

        setValue("firstName", userDetail.USER_FIRSTNAME)
        setValue("lastName", userDetail.USER_LASTNAME)
        setValue("phoneNum", userDetail.USER_PHONENO)
        setValue("address", userDetail.ADDRESS)
        setValue("countryId", userDetail.COUNTRY_ID)
        setValue("provinceId", userDetail.PROVINCE_ID)
        setValue("cityId", userDetail.CITY_ID)
        setValue("roleId", userDetail.USER_ROLE_ID)
        setValue("parentRoleId", userDetail.USER_PARENT_ROLE_ID)
        setCityId(userDetail.CITY_ID)
        setRoleId(userDetail.USER_ROLE_ID);
        
        dispatch({
          type: cnt.RECORD_STATUS,
          payload: userDetail,
        });
      }
    });
  }

  const getCountry = () => {
    let body = {
      profileId,
      countryId: "",
      session,
    };

    BOM.FetchReqAction(body, api.GetCountry, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setCountryData(res.countryListing);
      }
    });
  };
  const getProvince = (countryId) => {
    // console.log(countryId);
    let body = {
      profileId,
      countryId,
      session,
    };
    BOM.FetchReqAction(body, api.GetCountry, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setProvinceData(res.provinceListing);
      }
    });
  };

  const getCity = (stateId) => {
    // console.log("wow", stateId);
    let body = {
      profileId,
      stateId,
      session,
    };

    BOM.FetchReqAction(body, api.GetCity, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        //
        setCityData(res.cityListing);
      }
    });
  };

  function getRole() {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      criteria: "A",
      roleId: "",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetRole, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setRoleData(res.roleListing);
      }
    });
  }
  const getSubRole = (parentId) => {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let getSubRoleBody = {
      profileId,
      entityId: companyId,
      parentId,
      session,
    };

    /** fetch (reqBody, url, svc, callback) */
    BOM.FetchReqAction(getSubRoleBody, api.GetSubRole, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setSubRoleData(res.roleListing);
        // BOM.DatatableUsage();
      }
    });
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  
  const formSubmit = (formValues) => {
    // console.log("data", formValues);
    BOM.LoadAlert(cnt.LOAD, "Processing");

    /**MAKE ADJUSTMENT FOR SOME DATA*/
    formValues.firstName = BOM.Uwfirst(formValues.firstName);
    formValues.lastName = BOM.Uwfirst(formValues.lastName);
    formValues.address = BOM.Uwfirst(formValues.address);
    formValues.lang = "ENG";
    formValues.userId = state.ggParam.USERID;

    let body = {
      profileId,
      session,
      ...formValues,
    };

    // console.log(body);
    /** SEND REQ */
    BOM.SendReqAction(
      body,
      api.UpdateUser,
      "/manage-user"
    );

  };
  // return (<><p>On friday</p></>)

  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit, BOM.onError)}
      >
        <RightSide />
        <ContentTop pageTitle={appLabel.editUser} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{appLabel.userInformation}</h5>
                  <div className="ibox-tools">
                    <Link to="/manage-user" className="btn btn-primary btn-xs">
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-sm-3">
                      {/*left col*/}
                      <div className="text-center">
                        <img
                          src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
                          className="avatar img-circle img-thumbnail"
                          alt="avatar"
                        />
                      </div>
                      <br />
                    </div>
                    {/*/col-3*/}
                    <div className="col-sm-9">
                      <ul className="nav nav-tabs">
                        <li className="active">
                          <a
                            data-toggle="tab"
                            href="#personalInfo"
                            target="_self"
                          >
                            <i className="fa fa-user text-navy" />
                            &nbsp;Personal
                          </a>
                        </li>
                        <li>
                          <a
                            data-toggle="tab"
                            href="#contactInfo"
                            target="_self"
                          >
                            <i className="fa fa-wechat text-navy" />
                            &nbsp;Contact
                          </a>
                        </li>
                        <li>
                          <a
                            data-toggle="tab"
                            href="#addressInfo"
                            target="_self"
                          >
                            <i className="fa fa-home text-navy" />
                            &nbsp;Address
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#roleInfo" target="_self">
                            <i className="fa fa-tag text-navy" />
                            &nbsp;Role
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="personalInfo">
                          <div className="row" style={{ marginTop: 20 }}>
                            <div className="form-group col-md-6">
                              <label className="al-subtitle">
                                {appLabel.firstName}&nbsp;
                                <span className="asterisks">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control input-sm"
                                {...register("firstName", {
                                  required: "First Name is required !",
                                  minLength: {
                                    value: 3,
                                    message:
                                      "Name should be greater than 3 characters",
                                  },
                                })}
                                placeholder={appLabel.firstName}
                              />
                              <span className="asterisks">
                                {errors?.firstName?.message}
                              </span>
                            </div>
                            <div className="form-group col-md-6">
                              <label className="al-subtitle">
                                {appLabel.lastName}&nbsp;
                                <span className="asterisks">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control input-sm"
                                {...register("lastName", {
                                  required: "Last Name is required !",
                                  minLength: {
                                    value: 3,
                                    message:
                                      "Name should be greater than 3 characters",
                                  },
                                })}
                                placeholder={appLabel.lastName}
                              />
                              <span className="asterisks">
                                {errors?.lastName?.message}
                              </span>
                            </div>
                            <div className="form-group col-md-6">
                              <label className="al-subtitle">
                                {appLabel.userName}
                              </label>
                              <input
                                type="email"
                                className="form-control input-sm"
                                defaultValue={userDetail.USERNAME}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="contactInfo">
                          <div className="row" style={{ marginTop: 20 }}>
                            <div className="form-group col-md-6">
                              <label className="al-subtitle">
                                {appLabel.userEmail}&nbsp;
                                <span className="asterisks">*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control input-sm"
                                defaultValue={userDetail.USERNAME}
                                disabled
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label className="al-subtitle">
                                {appLabel.userPhone}&nbsp;
                                <span className="asterisks">*</span>
                              </label>
                              <input
                                type="number"
                                min={0}
                                className="form-control input-sm"
                                {...register("phoneNum", {
                                  required: "Phone Number is required",
                                  minLength: {
                                    value: 10,
                                    message:
                                      "Phone Number should be greater than 9 digits",
                                  },
                                  maxLength: {
                                    value: 15,
                                    message:
                                      "Phone Number should be less than 16 digits",
                                  },
                                })}
                                placeholder={appLabel.userPhone}
                              />
                              <span className="asterisks">
                                {errors?.phoneNum?.message}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="addressInfo">
                          <div className="row" style={{ marginTop: 20 }}>
                            <div className="form-group col-md-6">
                              <label className="al-subtitle">
                                {appLabel.country}&nbsp;
                                <span className="asterisks">*</span>
                              </label>

                              <select
                                className="form-control input-sm"
                                value={getValues("countryId")}
                                {...register("countryId", {
                                  required: "Field is required.",
                                  onChange: (e) => getProvince(e.target.value),
                                })}
                              >
                                <option value="">{appLabel.choose}</option>
                                {countryData.map((item, index) => (
                                  <option value={item.ID} key={index}>
                                    {item.COUNTRY_NAME}
                                  </option>
                                ))}
                              </select>
                              <span className="asterisks">
                                {errors?.countryId?.message}
                              </span>
                            </div>
                            <div className="form-group col-md-6">
                              <label className="al-subtitle">
                                {appLabel.province}&nbsp;
                                <span className="asterisks">*</span>
                              </label>
                              <select
                                className="form-control input-sm"
                                value={getValues("provinceId")}
                                {...register("provinceId", {
                                  required: "Field is required.",
                                  onChange: (e) => getCity(e.target.value),
                                })}
                              >
                                <option value="">{appLabel.choose}</option>
                                {provinceData.map((item, index) => (
                                  <option value={item.ID} key={index}>
                                    {item.PROVINCE_NAME}
                                  </option>
                                ))}
                              </select>
                              <span className="asterisks">
                                {errors?.provinceId?.message}
                              </span>
                            </div>
                            <div className="form-group col-md-6">
                              <label className="al-subtitle">
                                {appLabel.city}&nbsp;
                                <span className="asterisks">*</span>
                              </label>
                              <select
                                className="form-control input-sm"
                                value={cityId}
                                {...register("cityId", {
                                  required: "Field is required.",
                                  onChange: (e)=>setCityId(e.target.value)
                                })}
                              >
                                <option value="">{appLabel.choose}</option>
                                {cityData.map((item, index) => (
                                  <option value={item.ID} key={index}>
                                    {item.CITY_NAME}
                                  </option>
                                ))}
                              </select>
                              <span className="asterisks">
                                {errors?.cityId?.message}
                              </span>
                            </div>
                            <div className="form-group col-md-6">
                              <label className="al-subtitle">
                                {appLabel.address}&nbsp;
                                <span className="asterisks">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control input-sm"

                                {...register("address", {
                                  required: "Field is required.",
                                  minLength: {
                                    value: 3,
                                    message:
                                      "Address should be greater than 3 characters",
                                  },
                                })}
                                placeholder={appLabel.address}
                              />
                              <span className="asterisks">
                                {errors?.address?.message}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="roleInfo">
                          <div className="row" style={{ marginTop: 20 }}>
                            <div className="form-group col-md-6">
                              <label className="al-subtitle">
                                {appLabel.parentRoleName}&nbsp;
                                <span className="asterisks">*</span>
                              </label>
                              <select
                                className="form-control input-sm"
                                value={getValues("parentRoleId")}                                
                                {...register("parentRoleId", {
                                  required: "Field is required.",
                                  onChange: (e) => getSubRole(e.target.value),
                                })}
                              >
                                <option value="">{appLabel.choose}</option>
                                {roleData.map((item, index) => (
                                  <option value={item.ROLE_ID} key={index}>
                                    {item.ROLE_DESCRIPTION}
                                  </option>
                                ))}
                              </select>
                              <span className="asterisks">
                                {errors?.parentRoleId?.message}
                              </span>
                            </div>
                            <div className="form-group col-md-6">
                              <label className="al-subtitle">
                                {appLabel.roleName}&nbsp;
                                <span className="asterisks">*</span>
                              </label>
                              <select
                                className="form-control input-sm"
                                value={roleId} 
                                {...register("roleId", {
                                    required: "Field is required.",
                                    onChange: (e)=>setRoleId(e.target.value)
                                })}
                              >
                                <option value="">{appLabel.choose}</option>
                                {subRoleData.map((item, index) => (
                                  <option value={item.ROLE_ID} key={index}>
                                    {item.ROLE_NAME}
                                  </option>
                                ))}
                              </select>
                              <span className="asterisks">
                                {errors?.roleId?.message}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*/tab-content*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <RecordStatus {...BOM.NewRecordStatusdata()} />
        </div>
      </form>
    </Main>
  );
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
