import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Main from "../../../layouts/Main";
import ContentTop from "../../../ui-setup/ContentTop";
import appLabel from "../../../../config/appLabel";
import { BOM } from "../../../../services/defined.action";
import RecordStatus from "../../../ui-setup/RecordStatus";
import { apis as api } from "../../../../services/api.action";
import { cnt } from "../../../../services/constant.action";
import Notiflix from "notiflix";

export const SubRoleDetail = (props) => {
  const { location, history, userData, recordData, menuData } = props;
  const { state } = location;
  const { profileId, session, companyId } = userData;
  const dispatch = useDispatch();
  const [roleMenuData, setRoleMenuData] = useState([]);
  const [mainNameposition, setMainNameposition] = useState("");
  const [mainName, setMainName] = useState("");
  const [mainId, setMainId] = useState("");

  const [subMenu2, setSubMenu2] = useState([]);
  const [parentName, setParentName] = useState("");
  const [parentId, setParentId] = useState("");

  const [readButton, setReadButton] = useState("");
  const [thirdLevel, setThirdLevel] = useState("");

  useEffect(() => {

    // console.log(props);
    if (!state.ggParam) {
      history.push("/manage-role-menu");
      return;
    }
    getRoleMenu(state.ggParam.ROLE_ID);
    // BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);

    setReadButton("Read More");
    /** MAKE RIGHT ACTION BTN READY */
    $("#approveBtn").click(function () { 
      let bodyApprove = {
        profileId,
        roleId: state.ggParam.ROLE_ID,
        criteria: "A",
        session
      };
  
      // console.log(bodyApprove);
      BOM.GlobalApproveRejectAction(
        bodyApprove,
      api.ApproveRejectRole,
      "manage-role-menu",
      state.ggParam.ROLE_DESCRIPTION,
      "Approve "
    );
    });

    $("#rejectBtn").click(function () { 
      let bodyDisable = {
        profileId,
        roleId: state.ggParam.ROLE_ID,
        criteria: "R",
        session
      };
  
      // console.log(bodyDisable);
      BOM.GlobalApproveRejectAction(
        bodyDisable,
      api.ApproveRejectRole,
      "manage-role-menu",
      state.ggParam.ROLE_DESCRIPTION,
      "Reject "
    );
    });

  }, []);

  function getRoleMenu(roleId){
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      roleId,
      session,
    };

    BOM.FetchReqAction(
      body,
      api.GetRoleMenu,
      (err, res) => {
        if (err) {
          BOM.AlertMsg(cnt.DANGER, err);
        } else {
          var roleMenu = [];
          roleMenu = res.roleData.FUNCTIONS;

          // console.log(res.roleData);

          setRoleMenuData(roleMenu);
          dispatch({
            type: cnt.RECORD_STATUS,
            payload: res.roleData,
          });

          if (roleMenu.length !== 0) {
            setMainNameposition(roleMenu[0].DESCRIPTION.toLowerCase());
            setMainName(roleMenu[0].DESCRIPTION);
            setMainId(roleMenu[0].FUNCTION_ID);
          }

          // /** LOOP MENU AND DELETE ALL PARENT MENU WITH NO SUBMENU */
          roleMenu.forEach((element) => {
            if (element.SUB_MENU.length === 0) {
              // localDeleteParentMenu(element.FUNCTION_ID)
            }
          });

          /** HANDLE APPROV REJECT BTN */
          BOM.IsApproveOrReject(state.ggParam.FLG_STATUS, menuData, "Role Config");
        }
      }
    );
  }
  const selectedFunction = (parentData) => {
    setSubMenu2(parentData.SUB_MENU);
    setParentName(parentData.DESCRIPTION);
    setParentId(parentData.FUNCTION_ID);

    $(document).ready(function () {
      $(".footable").footable();
    });
  };

  const viewThirdLevel = (param1, param2) => {
    console.log(param1);
    console.log(param2);
    if (param2 === true) {
      setReadButton(param1);
      setThirdLevel(param1);
    } else {
      setReadButton("");
      setThirdLevel("");
    }
  };

  const RemoveLogic = (
    functionId,
    functionType,
    paramFuncName
  ) =>{

    // console.log("remove");
    // confirm
    Notiflix.Confirm.show(
      "Remove " + paramFuncName + " Menu",
      "Are you sure?",
      "Yes",
      "No",
      function () {
        // Yes
        BOM.LoadAlert(cnt.LOAD, "Processing");
        let bodyRemove = {
          profileId,
          session,
          roleId:  state.ggParam.ROLE_ID,
          functionId,
          functionType,
        };
        // console.log("bodyRemove ", bodyRemove);

        /** SEND REQ */
        BOM.SendReqAction(
          bodyRemove,
          api.DeleteRoleMenuId,
          "manage-role-menu",
        );
      },
      function () {
        // No button
      }
    );

  };

  return (
    <Main {...props}>
        <ContentTop pageTitle={appLabel.roleMenuDetail} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-sm-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>
                    {state && state.ggParam.ROLE_NAME.length <= 25
                      ? state.ggParam.ROLE_NAME
                      : state.ggParam.ROLE_NAME + "..."}
                  </h5>
                  <div className="ibox-tools">
                    <Link
                      to="/manage-role-menu"
                      className="btn btn-primary btn-xs"
                    >
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                    {
                      roleMenuData.length === 0 ? (
                        (
                            <Link
                            to={{
                              pathname: "/new-subrole-menu",
                              state: {
                                ggParam: state.ggParam,
                              },
                            }}
                          >
                            <span className="btn btn-primary btn-xs">
                              <i className="fa fa-plus-circle" />
                              &nbsp;
                              {appLabel.newMenu}
                            </span>
                          </Link>
                        )
                      ):(
                          <Link
                          to={{
                            pathname: "/add-to-subrole-menu",
                            state: {
                              ggParam: state.ggParam,
                            },
                          }}
                        >
                          <span className="btn btn-warning btn-xs">
                            <i className="fa fa-plus-circle" />
                            &nbsp;
                            {appLabel.addToMenu}
                          </span>
                        </Link>
                      )
                    }
                    
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  {roleMenuData.length === 0 ? (
                    <div
                    style={{ marginTop: 35, fontWeight: 'bold'}}
                      className="alert alert-warning text-center"
                    >
                      {appLabel.yetToConfigure}
                    </div>
                  ) : (
                    <>
                      <ul className="nav nav-tabs">
                        {roleMenuData.map((item, index) => (
                          <li
                            className={`${
                              item.DESCRIPTION.toLowerCase() ===
                              mainNameposition
                                ? "active"
                                : ""
                            }`}
                            key={index}
                          >
                            <a
                              data-toggle="tab"
                              href="#tab-2"
                              target="_self"
                              onClick={() => selectedFunction(item)}
                            >
                              <i className={`fa fa-${item.ICON} text-navy`} />
                              &nbsp; {item.DESCRIPTION}
                            </a>
                          </li>
                        ))}
                      </ul>
                      <div className="tab-content">
                        <div id="tab-1" className="tab-pane active">
                          <div className="full-height-scroll">
                            <div className="table-responsive">
                              {roleMenuData.length === 0 ? (
                                <div
                                style={{ marginTop: 35, fontWeight: 'bold'}}
                                  className="alert alert-warning text-center"
                                >
                                  {appLabel.this_menu_has_no_sub_menu}
                                </div>
                              ) : (
                                <table className="table table-striped table-hover">
                                  <tbody>
                                    {roleMenuData[0].SUB_MENU && (
                                      <tr>
                                        <td>
                                          <a
                                            onClick={()=>RemoveLogic(mainId, 'parentKey', mainName)}
                                            title={`Remove ${mainName} on the list`}
                                          >
                                            <span className="btn btn-white btn-sm">
                                              <i className="fa fa-times-circle text-danger" />
                                              &nbsp; {mainName}
                                            </span>
                                          </a>
                                        </td>
                                      </tr>
                                    )}

                                    {roleMenuData[0].SUB_MENU.map(
                                      (submenu, index) => (
                                        <tr key={index}>
                                          <td>
                                            <a
                                              data-toggle="tab"
                                              className="client-link"
                                              title={`Remove ${submenu.DESCRIPTION} on the list`}
                                            >
                                              <i
                                                className="fa fa-times-circle text-danger"
                                                onClick={()=>RemoveLogic(submenu.FUNCTION_ID, 'submenuKey', submenu.DESCRIPTION)}
                                              />
                                              &nbsp; {submenu.DESCRIPTION}
                                            </a>
                                          </td>
                                          <td>
                                            {submenu.ACTION.create === "1" ? (
                                              <i className="fa fa-check text-success" />
                                            ) : (
                                              <i className="fa fa-minus text-warning" />
                                            )}
                                            &nbsp; {appLabel.create}
                                          </td>
                                          <td>
                                            {submenu.ACTION.view === "1" ? (
                                              <i className="fa fa-check text-success" />
                                            ) : (
                                              <i className="fa fa-minus text-warning" />
                                            )}
                                            &nbsp; {appLabel.view}
                                          </td>
                                          <td>
                                            {submenu.ACTION.edit === "1" ? (
                                              <i className="fa fa-check text-success" />
                                            ) : (
                                              <i className="fa fa-minus text-warning" />
                                            )}
                                            &nbsp; {appLabel.edit}
                                          </td>
                                          <td>
                                            {submenu.ACTION.approve === "1" ? (
                                              <i className="fa fa-check text-success" />
                                            ) : (
                                              <i className="fa fa-minus text-warning" />
                                            )}
                                            &nbsp; {appLabel.approve}
                                          </td>
                                          <td>
                                            {submenu.ACTION.reject === "1" ? (
                                              <i className="fa fa-check text-success" />
                                            ) : (
                                              <i className="fa fa-minus text-warning" />
                                            )}
                                            &nbsp; {appLabel.reject}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          </div>
                        </div>

                        <div id="tab-2" className="tab-pane">
                          <div className="full-height-scroll">
                            <div className="table-responsive">
                              {subMenu2.length === 0 ? (
                                <div
                                style={{ marginTop: 35, fontWeight: 'bold'}}
                                  className="alert alert-warning text-center"
                                >
                                  {appLabel.this_menu_has_no_sub_menu}
                                </div>
                              ) : (
                                <table className="table table-striped table-hover table-sm">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <a
                                          onClick={()=>RemoveLogic(parentId, 'parentKey', parentName)}
                                            title={`Remove ${parentName} on the list`}
                                        >
                                          <span className="btn btn-white btn-sm">
                                            <i className="fa fa-times-circle text-danger" />
                                            &nbsp; {parentName.toUpperCase()}
                                          </span>
                                        </a>
                                      </td>
                                    </tr>

                                    {subMenu2.map((submenu, index) => (
                                      <tr key={index}>
                                        <td>
                                        <a
                                              data-toggle="tab"
                                              className="client-link"
                                              title={`Remove ${submenu.DESCRIPTION} on the list`}
                                            >
                                              <i
                                                className="fa fa-times-circle text-danger"
                                                onClick={()=>RemoveLogic(submenu.FUNCTION_ID, 'submenuKey', submenu.DESCRIPTION)}
                                              />
                                              &nbsp; {submenu.DESCRIPTION}
                                            </a>
                                        </td>
                                        <td>
                                          {submenu.ACTION.create === "1" ? (
                                            <i className="fa fa-check text-success" />
                                          ) : (
                                            <i className="fa fa-minus text-warning" />
                                          )}
                                          &nbsp; {appLabel.create}
                                        </td>
                                        <td>
                                          {submenu.ACTION.view === "1" ? (
                                            <i className="fa fa-check text-success" />
                                          ) : (
                                            <i className="fa fa-minus text-warning" />
                                          )}
                                          &nbsp; {appLabel.view}
                                        </td>
                                        <td>
                                          {submenu.ACTION.edit === "1" ? (
                                            <i className="fa fa-check text-success" />
                                          ) : (
                                            <i className="fa fa-minus text-warning" />
                                          )}
                                          &nbsp; {appLabel.edit}
                                        </td>
                                        <td>
                                          {submenu.ACTION.approve === "1" ? (
                                            <i className="fa fa-check text-success" />
                                          ) : (
                                            <i className="fa fa-minus text-warning" />
                                          )}
                                          &nbsp; {appLabel.approve}
                                        </td>
                                        <td>
                                          {submenu.ACTION.reject === "1" ? (
                                            <i className="fa fa-check text-success" />
                                          ) : (
                                            <i className="fa fa-minus text-warning" />
                                          )}
                                          &nbsp; {appLabel.reject}
                                        </td>
                                        {/* {submenu.CHILD_SUB_MENU.length !==
                                          0 && (
                                          <td className="client-status">
                                            <label>
                                              <input
                                                type="checkbox"
                                                onClick={(e) =>
                                                  viewThirdLevel(
                                                    submenu.FUNCTION_ID,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                              &nbsp;
                                              <a
                                                onClick={(e) =>
                                                  viewThirdLevel(
                                                    submenu.FUNCTION_ID,
                                                    e.target.value
                                                  )
                                                }
                                                className={`${
                                                  thirdLevel !==
                                                  submenu.FUNCTION_ID
                                                    ? "text-success"
                                                    : "text-warning"
                                                }`}
                                              >
                                                {readButton ===
                                                submenu.FUNCTION_ID
                                                  ? appLabel.readLess
                                                  : appLabel.viewMore}

                                                   {readButton}
                                              </a>
                                            </label>
                                          </td>
                                        )} */}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <RecordStatus {...recordData} />
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  recordData: state.recordStatus
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SubRoleDetail);
