/**
 * @author Gedeon NdundeCode
 * @date 05/12/2021
 */
import React, { useState, useEffect } from "react";
import useGeolocation from 'react-hook-geolocation'
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { cnt } from "../../services/constant.action";
import {
  VerifyAuthAction,
  SignOutAction,
} from "../../services/redux/actions/auth-act";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";

import Logo from "../../assets/img/logoLogin/fxBg.jpg";
import Digicert from "../../assets/img/logoLogin/digicert.png";
import axios from "axios";

const VerifyAuth = (props) => {

  /** INITIALIZE VARIABLES */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { VerifyAuthAction, SignOutAction, match} = props;
  const {params} = match;
  const [passwordShown, setPasswordShown] = useState(false);
  const geolocation = useGeolocation()
  const dispatch = useDispatch();

  useEffect(() => {
    //clear the store
    SignOutAction();
    
    console.log(params);

    dispatch({
      type: cnt.VERIFY_ORDER_ID,
      payload: params.orderId,
    });
    // console.log("this is props: ", props)
  }, []);

  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };


  /** SUBMISSION */
  const onSignIn = (formData) => {

    const body = {

      userName: formData.userName,
      userPass: formData.userPass,
      sourceIp: "197.184.164.52", //ip.IPv4,
      browser: BOM.GetBrowserName(),
      host: window.location.host,
      osName: "windows",
      longitude: "-25.757", //ip.longitude + "",
      latitude: "28.1443", //ip.latitude + "",
      channel: "self service",

    }
    VerifyAuthAction(body);
  };

  return (
    <React.Fragment>
      <div className="centeredContent">
        <div className="row">
          <div className="col-md-12">
            <header>
              <div
                className="middle-box loginscreen animated fadeInDown login-card"
                style={{ marginBottom: "0%" }}
              >
                <div
                  className="text-center"
                  // style={{
                  //   height: "150px",
                  //   backgroundColor: "#fff",
                  //   borderRadius: "3px",
                  // }}
                >
                  <img
                    alt="image"
                    style={{ width: "200px", marginTop: "10px" }}
                    src={Logo}
                  />
                </div>

                <div className="slogan text-center">
                  <h2>Artis DealRoom</h2>
                </div>
                <form
                  className="m-t"
                  onSubmit={handleSubmit(onSignIn)}
                  autoComplete="off"
                >
                  <div className="form-group">
                    <label className="text-left">{appLabel.username} <small style={{fontWeight: 'normal'}}>{appLabel.userNameExemple}</small></label>
                    <input
                      type="text"
                      className={`form-control input-sm $ ${errors.userName ? 'outline-error' : ''}`}
                      placeholder={appLabel.username}
                      {...register("userName", {
                        required: "Username is required!",
                        pattern: {
                          value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                          message: "Email must be a valid email address",
                        },
                      })}
                    />
                    <span className="asterisks">{errors?.userName?.message}</span>
                  </div>
                  <div className="form-group">
                    <label className="text-left">{appLabel.password}</label>
                    <input
                      type={passwordShown ? 'text' : 'password'}
                      className="form-control input-sm"
                      placeholder={appLabel.password}
                      {...register("userPass", {
                        required: "Password is required !",
                        minLength: {
                          value: 5,
                          message: "Password too short !",
                        },
                      })}
                    />
                    <span className="p-viewer" onClick={()=>togglePassword()}>
                    <i
                     className={passwordShown ? 'fa fa-eye-slash' : 'fa fa-eye'}
                    ></i>
                  </span>
                  <span className="asterisks">{errors?.userPass?.message}</span>
                  </div>
                  <div className="form-group text-center">
                    <button
                      type="submit"
                      className="btn btn-primary block full-width"
                      style={{
                        marginTop: 30,
                        boxShadow: "0 10px 6px -6px #fff",
                      }}
                    >
                      <i className="fa fa-chevron-circle-right" /> &nbsp;{" "}
                      {appLabel.btnLogin}
                    </button>
                  </div>
                  <div className="form-group text-center">
                    <a
                      style={{ fontWeight: "bold", color: "#000" }}
                      data-toggle="modal"
                      data-target="#forgotPwd"
                    >
                      <small>{appLabel.forgotpass} ?</small>
                    </a>
                    {/* <Link to="/dashboard">dash</Link> */}
                  </div>
                </form>
              </div>
              <div
                className="middle-box text-center text-white loginscreen animated fadeInDown"
                style={{ marginTop: 0, zIndex: 2 }}
              >
                <div className="col-md-6">
                 {appLabel.poweredBy} <a  href="https://openfactorgroup.com/"  target="_blank" style={{fontWeight: '900', fontSize: '16px', color: 'inherit'}}>{appLabel.factorEx}</a>
                </div>
                <div className="col-md-6">
                  <img
                      alt="image"
                      style={{ width: "125px", marginTop: "0px", paddingTop: "0px"}}
                      src={Digicert}
                    />
                </div>
              </div>
            </header>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(null, { VerifyAuthAction, SignOutAction })(VerifyAuth);
