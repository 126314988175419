import { useEffect, useState } from "react";

//react pdf viewer
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

//project import
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import { BOM } from "../../services/defined.action";
import { cnt } from "../../services/constant.action";
import history from "../../history";


const Privacy = (props) => {
  const { isUserLogin, onSwitchModule } = props;
  const { privacyPolicyDocumentData } = appStatic;
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [file, setFile] = useState(privacyPolicyDocumentData[0].link);

  useEffect(()=>{
    if (isUserLogin) {
        privacyPolicyDocumentData.forEach(element => element.isProtected ? element.icon='fa fa-unlock text-navy' : element);
    }
  },[])

  const onViewDocument = (itemObj) => {
    if (itemObj?.isProtected && !isUserLogin) {
      BOM.AlertMsg(
        cnt.WARNING,
        "Please enter your login information to view the protected content"
      );
      onSwitchModule();
      history.push("/protected-privacy-document");
    } else {
      setFile(itemObj.link);
    }
  };

  return (
    <>
      <div className="fEx-innovative-platform">
        <div className="fEx-container-pdf">
          <div className="fEx-row">
            <>
              <div className="col-md-10">
                <div className="fEx-Innovative-img" data-aos="fade-up">
                  {/* file document here */}
                  <div
                    className="pdf-containerFAKE"
                    style={{
                      width: "100%",
                      height: "900px",
                      overflowY: "auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "10px",
                      boxShadow:
                        " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                  >
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                      <Viewer
                        fileUrl={file}
                        plugins={[defaultLayoutPluginInstance]}
                      />
                    </Worker>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="fEx-Innovative-info" data-aos="fade-down">
                  <span className="fEx-divider" />
                  <ul className="fEx-icon-list-items">
                    {privacyPolicyDocumentData.map((item, index) => (
                      <li key={index}>
                        <a onClick={() => onViewDocument(item)}>
                          <i className={item.icon} />
                          &nbsp;{item.name}
                        </a>
                      </li>
                    ))}
                  </ul>

                  {isUserLogin ? (
                    <a
                      className="btn btn-primary btn-rounded btn-block"
                      onClick={() => history.push("/dashboard")}
                    >
                      <i className="fa fa-long-arrow-right" /> &nbsp;{" "}
                      {appLabel.goToDashboard}
                    </a>
                  ) : (
                    <a
                      className="btn btn-danger btn-rounded btn-block"
                      onClick={() => onSwitchModule()}
                    >
                      <i className="fa fa-times-circle" /> &nbsp;{" "}
                      {appLabel.closeBtn}
                    </a>
                  )}
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
