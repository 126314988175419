import React, { useEffect, useState } from "react";
import Main from "../layouts/Main";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { apis as api } from "../../services/api.action";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM, RAL } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import NumberFormat from "react-number-format";
import { TableFacility, AmntFormatFac,HandleSkeletonTbleFac, } from "../layouts/Facility";

const ManageConsentedInvoice = (props) => {
  const { userData, menuData, location } = props;
  const { profileId, session, companyId } = userData;
  const globalVar = window.globalData;
  const { tableData } = appStatic;

  const dispatch = useDispatch();
  const [invoiceData, setInvoiceData] = useState([]);
  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // console.log(props);
    getListByCriteria();
  }, []);

  function getListByCriteria() {
    // console.log(params);
    setInvoiceData([]);

    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true)

    let body = {
      profileId,
      invoiceId: "",
      companyId,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetConsentedInvoice, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setInvoiceData(res.invoiceListing);
        BOM.DatatableUsage();
        setIsLoading(false)
      }
    });
  }
  const onSelectInv = (objParam) => {

    /** CHECK FOR RIGHT BTN PRIVILEGES */
    let path = appStatic.pathData.find(
      (item) => item.URL === location.pathname
    );
    if (path) {
      if (RAL.viewAction(menuData, path.DESCRIPTION)) {
        dispatch({
          type: cnt.RIGHT_DETAIL_BTN,
        });
      }
    }

    globalVar.viewLink = {
      path: "/funded-invoice-detail",
      obj: objParam,
    };
  };

  /** RENDER JXS DATA */
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.fundedInvoice} />
      <div className="animated fadeInRightFAKE">
        <div className="row">
          <form className="m-t" autoComplete="off">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>{appLabel.authorizedListing}</h5>
                </div>
                <div className="ibox-content panel-primary contentCard">
                <HandleSkeletonTbleFac
                    classParam={`${isLoading ? "" : "hide"}`}
                  />
                  <div className={` ${isLoading ? "hide" : ""}`}>
                  {invoiceData.length === 0 ? (
                    <div className="alert alert-warning text-center">
                      <a className="alert-link">{appLabel.noDataInTableMsg}</a>
                    </div>
                  ) : (
                      <TableFacility thData={tableData.invFundedList}>
                    {invoiceData.map((item, index) => (
                      <tr className="gradeX" key={index}>
                        <td>
                          <label className="myradio-button">
                            <input
                              type="radio"
                              name="radio"
                              onClick={() => onSelectInv(item)}
                            />
                            <span className="label-visible">
                              <span className="myfake-radiobutton"></span>
                            </span>
                          </label>
                        </td>
                        <td>{item.PAYMENT_DATE}</td>
                        <td>{item.FUNDING_ID}</td>
                        <td>{item.INV_ID}</td>
                        <td>
                        <AmntFormatFac inputValue={item.PO_AMOUNT} />                       
                        </td>
                      </tr>
                    ))}
                  </TableFacility>
                  )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
});

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageConsentedInvoice);
