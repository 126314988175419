import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../../services/api.action";
import { connect, useDispatch } from "react-redux";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../../ui-setup/RecordStatus";
import NumberFormat from "react-number-format";

export const InvoiceUploadDetail = (props) => {
  const { location, userData, configData, recordData, menuData } = props;
  const { state } = location;
  const { profileId, session, companyId } = userData;
  const { isCsd } = configData;

  const dispatch = useDispatch();
  const [isFullPayment, setIsFullPayment] = useState(false);
  const [isCsdCheck, setIsCsdCheck] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [orderUploadDetail, setOrderUploadDetail] = useState({});
  const [poData, setPoData] = useState([]);

  useEffect(() => {
    // console.log(state.ggParam);
    if (isCsd.toLowerCase() === "y") {
      setIsCsdCheck(true);
    }
    getDetailById(state.ggParam);
    /** MAKE RIGHT ACTION BTN READY */
    // $("#approveBtn").click(function () {
    //   let bodyApprove = {
    //     profileId,
    //     batchId: state.ggParam.BATCH_ID,
    //     uploadType: "invoice",
    //     criteria: "A",
    //     session,
    //   };

    //   // console.log(bodyApprove);
    //   BOM.GlobalApproveRejectAction(
    //     bodyApprove,
    //     api.ApproveRejectBatch,
    //     "invoice-upload",
    //     state.ggParam.BATCH_NAME,
    //     "Approve "
    //   );
    // });
    // $("#rejectBtn").click(function () {
    //   let bodyDisable = {
    //     profileId,
    //     batchId: state.ggParam.BATCH_ID,
    //     uploadType: "invoice",
    //     criteria: "R",
    //     session,
    //   };

    //   // console.log(bodyDisable);
    //   BOM.GlobalApproveRejectAction(
    //     bodyDisable,
    //     api.ApproveRejectBatch,
    //     "invoice-upload",
    //     state.ggParam.BATCH_NAME,
    //     "Reject "
    //   );
    // });
  }, []);

  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      batchId: params.BATCH_ID,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetBatch, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let orderUploadDetail = res.batchDetail;

        setOrderUploadDetail(orderUploadDetail);
        setPoData(res.itemData)
        dispatch({
          type: cnt.RECORD_STATUS,
          payload: orderUploadDetail,
        });

        //handle right side
        if (orderUploadDetail.FLG_STATUS.toLowerCase() !== 'a') {
            BOM.IsApproveOrReject(orderUploadDetail.FLG_STATUS, menuData, "Invoice Upload");
        }
      }
    });
  };

  const onApprove = () => {
    let bodyApprove = {
      profileId,
      batchId: state.ggParam.BATCH_ID,
      uploadType: "invoice",
      criteria: "A",
      session,
    };

    // console.log(bodyApprove);
    BOM.GlobalApproveRejectAction(
      bodyApprove,
      api.ApproveRejectBatch,
      "invoice-upload",
      state.ggParam.BATCH_NAME,
      "Approve "
    );
  }
const onReject = () => {
  let bodyDisable = {
    profileId,
    batchId: state.ggParam.BATCH_ID,
    uploadType: "invoice",
    criteria: "R",
    session,
  };

  // console.log(bodyDisable);
  BOM.GlobalApproveRejectAction(
    bodyDisable,
    api.ApproveRejectBatch,
    "invoice-upload",
    state.ggParam.BATCH_NAME,
    "Reject "
  );
}

return (
 <Main {...props} onApprove={onApprove} onReject={onReject}>
      <ContentTop pageTitle={appLabel.invoiceUploadDetail} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{appLabel.batchInfo}</h5>
                <div className="ibox-tools">
                  <Link to="/invoice-upload" className="btn btn-primary btn-xs">
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;
                    {appLabel.backBtn}
                  </Link>
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.generalInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.batchNumber}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={orderUploadDetail.BATCH_NUMBER}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.batchName}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={orderUploadDetail.BATCH_NAME}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.batchDescription}
                          </label>
                        </div>
                        <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control input-sm"
                              defaultValue={orderUploadDetail.BATCH_DESC}
                              disabled
                            />
                        </div>
                      </div>
                      
                    </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.otherInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <ul className="nav nav-tabs">
                      <li className="active">
                        <a
                          data-toggle="tab"
                          href="#invoiceInfo"
                          target="_self"
                        >
                          <i className="fa fa-tag text-navy" />
                          &nbsp;{appLabel.invoiceInformation}
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                    <div className="tab-pane active" id="invoiceInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          <div className="col-sm-12">
                            <div className="table-responsive">
                              {poData.length === 0 ? (
                                <div className="alert alert-warning text-center">
                                  <a className="alert-link">
                                    {appLabel.noDataInTableMsg}
                                  </a>
                                </div>
                              ) : (
                                <table
                                  className="footable table table-stripped toggle-arrow-tiny"
                                  data-page-size="10"
                                  data-filter="#filter"
                                >
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th>{appLabel.date}</th>
                                      <th>{appLabel.invoiceId}</th>
                                      <th>{appLabel.from}</th>
                                      <th>{appLabel.to}</th>
                                      <th>{appLabel.amount}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {poData.map((item, index) => (
                                      <tr className="gradeX" key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.ITEM_DATE}</td>
                                        <td>{item.ITEM_ID}</td>
                                        <td>{item.FROM_COMPANY_NAME}</td>
                                        <td>{item.TO_COMPANY_NAME}</td>
                                        <td>
                                          <NumberFormat
                                            value={item.AMOUNT}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix={item.CCY + " "}
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td colSpan="6">
                                        <ul className="pagination pull-right"></ul>
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RecordStatus {...recordData} />
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
  
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceUploadDetail);
