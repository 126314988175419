import React, { useEffect, useState } from "react";
import { apis as api } from "../../services/api.action";
import { connect, useDispatch } from "react-redux";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM, RAL } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../ui-setup/RecordStatus";
import appStatic from "../../config/appStaticData";
import NumberFormat from "react-number-format";
import {
  InputAmountFacility,
  Col12BlockFacility,
  InputTextFacility,
  NoDataFac,
  NavTabFac,
} from "../layouts/Facility";

export const InvoiceDetail = (props) => {
  const { location, userData, configData, recordData, menuData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId, companyTypeId } = userData;
  const { isCsd } = configData;
  const { tabData } = appStatic;

  const dispatch = useDispatch();
  const [isCsdCheck, setIsCsdCheck] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [invoiceDetail, setInvoiceDetail] = useState({});

  const [orderDetail, setOrderDetail] = useState({});
  const [shipFromData, setShipFromData] = useState({});
  const [billFromData, setBillFromData] = useState({});
  const [shipToData, setShipToData] = useState({});
  const [billToData, setBillToData] = useState({});
  const [isPO, setIsPO] = useState(false);
  const [taxDetail, setTaxDetail] = useState({});
  const [fromCompanyDetail, setFromCompanyDetail] = useState({});
  const [toCompanyDetail, setToCompanyDetail] = useState({});

  const [contractDetail, setContractDetail] = useState({});
  const [financeDetail, setFinanceDetail] = useState({});
  const [financeHistoryData, setFinanceHistoryData] = useState([]);

  const [isSupplier, setIsSupplier] = useState(false);
  const [isCustodian, setIsCustodian] = useState(false);
  const [inputFldData, setInputFldData] = useState([]);
  let tempDocument = [];

  useEffect(() => {
    // console.log(state.ggParam);
    handleSwitchCondition();
    getDetailById(state.ggParam);

     //CALL INVOICE DOCUMENT BLOCK
    BOM.getSpecDoc(ggParam?.INV_ID, setDocuments, userData);
     //GET 2nd DOCUMENT
    //  setTimeout(() => {
    //  getSpecDoc(state?.ggParam?.INV_ID, "sharing");
    //  }, 1500);
  }, []);

  const handleSwitchCondition = () => {
    if (isCsd.toLowerCase() === "y") {
      setIsCsdCheck(true);
    }
    switch (companyTypeId) {
      case "CT2022012400002": // supplier
        setIsSupplier(true);
        // console.log(companyTypeId);
        break;
      case "CT2022012400000": // depository/custodian
        setIsCustodian(true);
        // console.log(companyTypeId);
        break;
    }
  };

  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      invoiceId: params.INV_ID,
      companyId,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetInvoice, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.invoiceDetail;
        const invDetail = objDetail.INVOICE_DATA;
        const taxDetail = objDetail.TAX_DATA;

        setInvoiceDetail(invDetail);
        setTaxDetail(taxDetail);
        setContractDetail(objDetail.CONTRACT_DATA);
        // setFinanceDetail(objDetail.FUNDING_DATA);
        setFinanceDetail(objDetail.BIDDING_DATA);
        setFinanceHistoryData(objDetail.FUND_HISTORY_DATA);

        getPo(objDetail.ORDERID);

        dispatch({
          type: cnt.RECORD_STATUS,
          payload: invDetail,
        });

        //handle right side
        BOM.IsApproveOrReject(invDetail.FLG_STATUS, menuData, "Manage Invoice");

        //get document
        // getSpecDoc(state.ggParam);

        //disable Approve right side
        if (invDetail.FLG_STATUS.toLowerCase() === "r") {
          RAL.disableEnableRightAction(["CLEAR_RIGHT_BTN"]);
        }

        // mergedDataArr
        setInputFldData([
          {
            name: appLabel.invoiceId,
            value: invDetail.INV_ID,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.currency,
            value: invDetail.INV_CCY,
            fldType: "text",
            section: "general",
          },

          {
            name: appLabel.invAmount,
            value: invDetail.FINAL_AMOUNT,
            fldType: "amount",
            section: "general",
          },

          {
            name: appLabel.invRefId,
            value: invDetail.INV_LOM_CODE,
            fldType: "text",
            section: "general",
          },

          {
            name: appLabel.invIssueDate,
            value: invDetail.INVOICE_DUE_DATE,
            fldType: "text",
            section: "general",
          },

          {
            name: appLabel.invPymtDate,
            value: invDetail.PLANNED_PAYMENT_DATE,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.taxCode,
            value: taxDetail.TAX_CODE,
            fldType: "text",
            section: "tax",
          },
          {
            name: appLabel.rate,
            value: taxDetail.TAX_RATE,
            fldType: "text",
            section: "tax",
          },
          {
            name: appLabel.amount,
            value: taxDetail.TAX_AMOUNT,
            fldType: "amount",
            section: "tax",
          },
          {
            name: appLabel.excludeAmnt,
            value: taxDetail.EXCL_TAX_AMOUNT,
            fldType: "amount",
            section: "tax",
          },
          {
            name: appLabel.totalAmount,
            value: taxDetail.TOTAL_AMOUNT,
            fldType: "amount",
            section: "tax",
          },
          {
            name: appLabel.acceptedDate,
            value: invDetail.ACCEPTED_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.submissionDate,
            value: invDetail.SUBMISSION_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.invDate,
            value: invDetail.INV_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.originalAmount,
            value: invDetail.ORIGINAL_AMOUNT,
            fldType: "amount",
            section: "payment",
          },
          {
            name: appLabel.adjustment,
            value: invDetail.ADJUSTMENT,
            fldType: "amount",
            section: "payment",
          },
          {
            name: appLabel.discountAmount,
            value: invDetail.DISCOUNT_AMOUNT,
            fldType: "amount",
            section: "payment",
          },
          {
            name: appLabel.pymtOption,
            value: invDetail.PAYMENT_METHOD,
            fldType: "text",
            section: "payment",
          },
        ]);
      }
    });
  };
  const getSpecDoc = () => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      id: ggParam?.INV_ID,
      // type,//no need to send type anymore
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setDocuments(res.docListing);
      }
    });
  };
  const getSpecDocOLD = async (id, type) => {
    let body = {
      profileId,
      id,
      type,
      session,
    };

    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        switch (type?.toLowerCase()) {
          case "invoice":
            res.docListing.forEach((element) => {
              tempDocument.push(element);
            });
            break;

          default:
            res.docListing.forEach((element) => {
              tempDocument.push(element);
            });
            break;
        }

        setDocuments([...tempDocument]);
      }
    });
  };

  const getCompanyById = (
    companyId,
    setShipParam,
    setBillParam,
    setCompanyDetail
  ) => {
    let body = {
      profileId,
      companyId,
      session,
    };

    /** FETCH */
    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let companyDetail = res.companyDetail;

        setShipParam(companyDetail.SHIP_DATA);
        setBillParam(companyDetail.BILL_DATA);
        setCompanyDetail(companyDetail);
      }
    });
  };

  const getPo = (idParam) => {
    let body = {
      profileId,
      orderId: idParam,
      criteria: "A",
      companyId,
      session,
    };
    BOM.LoadAlert(cnt.LOAD, "Processing");

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetPo, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let orderDetail = res.purchaseOrderDetail;
        setOrderDetail(orderDetail);

        getCompanyById(
          orderDetail.PO_FROM_COMPANYID,
          setShipFromData,
          setBillFromData,
          setFromCompanyDetail
        );
        getCompanyById(
          orderDetail.PO_TO_COMPANYID,
          setShipToData,
          setBillToData,
          setToCompanyDetail
        );
        setIsPO(true);
      }
    });
  };

  const oniInitiateInvoice = () => {
    let body = {
      profileId,
      invoiceId: state.ggParam.INV_ID,
      session,
    };

    // console.log(body);
    BOM.GlobalApproveRejectAction(
      body,
      api.InitiateInvoice,
      "manage-invoice",
      "Verification",
      "Initiate "
    );
  };

  const onApprove = () => {
    let bodyApprove = {
      profileId,
      invoiceId: state.ggParam.INV_ID,
      criteria: "A",
      session,
    };

    // console.log(bodyApprove);
    BOM.GlobalApproveRejectAction(
      bodyApprove,
      api.ApproveRejectInvoice,
      "manage-invoice",
      "Invoice",
      "Approve "
    );
  };
  const onReject = () => {
    let bodyDisable = {
      profileId,
      invoiceId: state.ggParam.INV_ID,
      criteria: "R",
      session,
    };

    // console.log(bodyDisable);
    BOM.GlobalApproveRejectAction(
      bodyDisable,
      api.ApproveRejectInvoice,
      "manage-invoice",
      "Invoice",
      "Reject "
    );
  };

  return (
    <Main {...props} onApprove={onApprove} onReject={onReject}>
      <ContentTop pageTitle={appLabel.invoiceDetail} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{appLabel.invoiceInformation}</h5>
                <div className="ibox-tools">
                  <Link to="/manage-invoice" className="btn btn-primary btn-xs">
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;
                    {appLabel.backBtn}
                  </Link>
                  {/* <Link
                    to="/print-invoice-receipt"
                    className="btn btn-primary btn-xs"
                    target={"blank"}
                  >
                    <i className="fa fa-print" />
                    &nbsp; Print Invoice
                  </Link> */}
                  {invoiceDetail.FLG_STATUS === "A" &&
                    invoiceDetail.INV_VERIFICATION_STATUS === "A" &&
                    invoiceDetail.INV_COMPLETE_STATUS === "U" &&
                    invoiceDetail.FLG_SHARED === "N" &&
                    isSupplier && (
                      <span>
                        <Link
                          to={{
                            pathname: "/new-share-invoice",
                            state: {
                              ggParam: invoiceDetail,
                            },
                          }}
                          className="btn btn-primary btn-xs"
                        >
                          <i className="fa fa-share" />
                          &nbsp;
                          {appLabel.shareInvoice}
                        </Link>
                      </span>
                    )}

                  {invoiceDetail.FLG_STATUS === "A" &&
                    invoiceDetail.INV_VERIFICATION_STATUS === "U" &&
                    (isSupplier || isCustodian) && (
                      <span style={{ marginLeft: "10px" }}>
                        <button
                          type="button"
                          className="btn btn-primary btn-xs"
                          onClick={() => oniInitiateInvoice()}
                        >
                          <i className="fa fa-power-off" />
                          &nbsp;
                          {appLabel.initiateInvoice}
                        </button>
                      </span>
                    )}
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                  <Col12BlockFacility topTitle={appLabel.generalInfo}>
                    {inputFldData.map((item, index) => (
                      <div key={index}>
                        {item.section === "general" &&
                          (item.fldType === "amount" ? (
                            <InputAmountFacility
                              inputLabel={item.name}
                              inputValue={item.value}
                            />
                          ) : (
                            <InputTextFacility
                              inputLabel={item.name}
                              inputValue={item.value}
                            />
                          ))}
                      </div>
                    ))}
                    <NoDataFac arrList={inputFldData} />
                  </Col12BlockFacility>

                  <Col12BlockFacility topTitle={appLabel.otherInfo}>
                    <NavTabFac tabsList={tabData.invDetailList} />
                    <div className="tab-content">
                      <div className="tab-pane active" id="documentInfo">
                        <div className="row" style={{ marginTop: "30px" }}>
                          <div className="col-sm-12">
                            {documents.length === 0 ? (
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDocumentMsg}
                                </a>
                              </div>
                            ) : (
                              <div>
                                {documents.map((item, index) => (
                                  <div className="col-md-4" key={index}>
                                    <div className="file-box">
                                      <div className="file">
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            BOM.DownloadAction(
                                              item.DOC_OBJECT,
                                              item.DOC_ID +
                                                " " +
                                                item.DOC_CATEGORY.toLowerCase()
                                            )
                                          }
                                        >
                                          <span className="corner" />
                                          <div className="icon">
                                           <i className="fa fa-file-pdf-o" style={{color:'#d50000'}} />
                                          </div>
                                          <div className="file-name">
                                            {item.DOC_CATEGORY}
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="pOInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          {Object.keys(orderDetail).length === 0 ? (
                            <div className="col-sm-12">
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDataMsg}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-12">
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.poNo}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.ORDERID}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.from}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.FROM_COMPANY_NAME}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.to}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.TO_COMPANY_NAME}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.amount}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <NumberFormat
                                    className="form-control input-sm"
                                    value={orderDetail.PO_AMOUNT}
                                    displayType="text"
                                    disabled
                                    thousandSeparator={true}
                                    prefix={""}
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.currency}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.PO_CCY}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.pymtOption}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.PO_PAYMENT_OPTION}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="tab-pane" id="contractInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          {Object.keys(contractDetail).length === 0 ? (
                            <div className="col-sm-12">
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDataMsg}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-12">
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.contractId}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={contractDetail.PO_CONTRACT_ID}
                                    disabled
                                  />
                                </div>
                              </div>

                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.type}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={contractDetail.TRANS_TYPE}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.value}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <NumberFormat
                                    className="form-control input-sm"
                                    value={contractDetail.CONTR_VALUE}
                                    displayType="text"
                                    disabled
                                    thousandSeparator={true}
                                    prefix={""}
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.currency}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={contractDetail.CONTR_CCY}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.startDate}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={
                                      contractDetail.CONTR_START_DATE
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="tab-pane" id="financeInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          {Object.keys(financeDetail).length === 0 ? (
                            <div className="col-sm-12">
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDataMsg}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="col-sm-12">
                                <label className="al-heading">
                                  <i className="fa fa-circle-o text-navy"></i>
                                  &nbsp; {appLabel.generalInfo}
                                </label>
                                <div
                                  className="hr-line-dashed"
                                  style={{ marginTop: 0 }}
                                />
                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.fundingId}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      defaultValue={financeDetail.FUNDING_ID}
                                      disabled
                                    />
                                  </div>
                                </div>

                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.funder}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      defaultValue={financeDetail.INVESTOR_NAME}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.transactionDate}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      defaultValue={financeDetail.TRN_DATE}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.paymentDate}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      defaultValue={financeDetail.PAYMENT_DATE}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.totalAmount}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <NumberFormat
                                      className="form-control input-sm"
                                      value={financeDetail.TOTAL_AMOUNT}
                                      displayType="text"
                                      disabled
                                      thousandSeparator={true}
                                      prefix={""}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <label className="al-heading">
                                  <i className="fa fa-circle-o text-navy"></i>
                                  &nbsp; {appLabel.history}
                                </label>
                                <div
                                  className="hr-line-dashed"
                                  style={{ marginTop: 0 }}
                                />

                                <div className="table-responsive">
                                  {financeHistoryData.length === 0 ? (
                                    <div className="alert alert-warning text-center">
                                      <a className="alert-link">
                                        {appLabel.noDataInTableMsg}
                                      </a>
                                    </div>
                                  ) : (
                                    <table
                                      className="footable table table-stripped toggle-arrow-tiny"
                                      data-page-size="10"
                                      data-filter="#filter"
                                    >
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>{appLabel.itemId}</th>
                                          <th>{appLabel.funder}</th>
                                          <th>{appLabel.historyType}</th>
                                          <th>{appLabel.AmountToFinance}</th>
                                          <th>{appLabel.amountFunded}</th>
                                          {/* <th>{appLabel.status}</th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {financeHistoryData.map(
                                          (item, index) => (
                                            <tr className="gradeX" key={index}>
                                              <td>{index + 1}</td>
                                              <td>{item.ITEM_ID}</td>
                                              <td>{item.FUNDER}</td>
                                              <td>{item.ITEM_TYPE}</td>
                                              <td>
                                                <NumberFormat
                                                  value={item.AMOUNT_REQUESTED}
                                                  displayType="text"
                                                  disabled
                                                  thousandSeparator={true}
                                                  prefix={item.CCY + " "}
                                                />
                                              </td>
                                              <td>
                                                <NumberFormat
                                                  value={item.AMOUNT_FUNDED}
                                                  displayType="text"
                                                  disabled
                                                  thousandSeparator={true}
                                                  prefix={item.CCY + " "}
                                                />
                                              </td>
                                              {/* <td>
                                                <i
                                                  className={
                                                    item.FLG_STATUS.toLowerCase() ===
                                                    "f"
                                                      ? "fa fa-check text-navy"
                                                      : "fa fa-times text-danger"
                                                  }
                                                ></i>
                                                &nbsp;
                                                {item.FLG_STATUS.toLowerCase() ===
                                                "f"
                                                  ? appLabel.funded
                                                  : appLabel.notFunded}
                                              </td> */}
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td colSpan="8">
                                            <ul className="pagination pull-right"></ul>
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="tab-pane" id="addressInfo">
                        <div className="row" style={{ marginTop: "15px" }}>
                          <Col12BlockFacility topTitle={null}>
                            <div className="col-md-3">
                              <div className="well">
                                <address>
                                  <h4>
                                    {" "}
                                    <i className="fa fa-building text-navy"></i>
                                    &nbsp; {appLabel.shipBuyerAddress}
                                  </h4>{" "}
                                  <br />
                                  <i className="fa fa-circle text-navy"></i>{" "}
                                  &nbsp; {shipFromData.SHIPPING_ADDRESS_1},{" "}
                                  {shipFromData.SHIPPING_CITY_NAME}
                                  <br />
                                  {shipFromData.SHIPPING_PROVINCE_NAME},{" "}
                                  {shipFromData.SHIPPING_COUNTRY_NAME}{" "}
                                  {shipFromData.SHIPPING_POST_CODE}
                                </address>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="well">
                                <address>
                                  <h4>
                                    {" "}
                                    <i className="fa fa-building text-navy"></i>
                                    &nbsp;{appLabel.bilBuyerAddress}
                                  </h4>{" "}
                                  <i className="fa fa-circle text-navy"></i>
                                  &nbsp;{billFromData.BILLING_ADDRESS_1},{" "}
                                  {billFromData.BILLING_CITY_NAME}
                                  <br />
                                  {billFromData.BILLING_PROVINCE_NAME},{" "}
                                  {billFromData.BILLING_COUNTRY_NAME}{" "}
                                  {billFromData.BILLING_POST_CODE}
                                </address>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="well">
                                <address>
                                  <h4>
                                    {" "}
                                    <i className="fa fa-building text-navy"></i>
                                    &nbsp; {appLabel.shipSupplierAddress}
                                  </h4>{" "}
                                  <br />
                                  <i className="fa fa-circle text-navy"></i>{" "}
                                  &nbsp; {shipToData.SHIPPING_ADDRESS_1},{" "}
                                  {shipToData.SHIPPING_CITY_NAME}
                                  <br />
                                  {shipToData.SHIPPING_PROVINCE_NAME},{" "}
                                  {shipToData.SHIPPING_COUNTRY_NAME}{" "}
                                  {shipToData.SHIPPING_POST_CODE}
                                </address>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="well">
                                <address>
                                  <h4>
                                    {" "}
                                    <i className="fa fa-building text-navy"></i>
                                    &nbsp;{appLabel.bilSupplierAddress}
                                  </h4>{" "}
                                  <i className="fa fa-circle text-navy"></i>
                                  &nbsp;{billToData.BILLING_ADDRESS_1},{" "}
                                  {billToData.BILLING_CITY_NAME}
                                  <br />
                                  {billToData.BILLING_PROVINCE_NAME},{" "}
                                  {billToData.BILLING_COUNTRY_NAME}{" "}
                                  {billToData.BILLING_POST_CODE}
                                </address>
                              </div>
                            </div>
                          </Col12BlockFacility>
                        </div>
                      </div>
                    </div>
                  </Col12BlockFacility>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RecordStatus {...recordData} />
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetail);
