import React, { useEffect, useState } from "react";
import Main from "../layouts/Main";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { apis as api } from "../../services/api.action";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM, RAL } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import { HandleSkeletonTbleFac } from "../layouts/Facility";

const ManagePurchaseOrder = (props) => {
  const { userData, menuData, location } = props;
  const { profileId, session, companyId } = userData;
  const globalVar = window.globalData;
  const dispatch = useDispatch();
  const { handleFlgStatus, handleStatusIcon } = appStatic;

  const [poData, setPoData] = useState([]);
  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [tiles, setTiles] = useState([
    {
      title: appLabel.authorized,
      otherTitle: appLabel.authorizedListing,
      color: "primary",
      icon: "tag",
      criteria: "A",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.unauthorized,
      otherTitle: appLabel.unauthorizedListing,
      color: "warning",
      icon: "exclamation-triangle",
      criteria: "U",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.disabled,
      otherTitle: appLabel.disabledListing,
      color: "danger",
      icon: "close",
      criteria: "R",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
  ]);

  useEffect(() => {
    // console.log(props);
    getListByCriteria(tiles[0]); // get position 0 object
  }, []);

  function getListByCriteria(params) {
    // console.log(params);
    // INITIALIZE RIGHT ACTION MENU
    [cnt.CLEAR_RIGHT_BTN, cnt.RIGHT_ADD_BTN].map((item) => {
      dispatch({
        type: item,
      });
    });
    setPoData([]);

    setTitleLeft(params.otherTitle);
    setTitleRight(params.title);
    setColor(params.color);
    setIcon(params.icon);

    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true)

    let body = {
      profileId,
      orderId: "",
      criteria: params.criteria,
      companyId,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetPo, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setPoData(res.purchaseOrderListing);
        BOM.DatatableUsage();
        setIsLoading(false)
      }
    });
  }

  const onSelectPo = (order) => {
    // console.log(order);
    // ["RIGHT_DETAIL_BTN", "RIGHT_EDIT_BTN_FAKE"].map((item) => {
    //   dispatch({
    //     type: item,
    //   });
    // });
    /** CHECK FOR RIGHT BTN PRIVILEGES */
    let path = appStatic.pathData.find(
      (item) => item.URL === location.pathname
    );
    if (path) {
      if (RAL.viewAction(menuData, path.DESCRIPTION)) {
        dispatch({
          type: cnt.RIGHT_DETAIL_BTN,
        });
      }
    }

    globalVar.viewLink = {
      path: "/order-detail",
      obj: order,
    };

    // ONLY REJECTED/DISABLED DATA CAN BE EDITED
    if (order.FLG_STATUS.toLowerCase() === "r") {
      if (path) {
        if (RAL.editAction(menuData, path.DESCRIPTION)) {
          dispatch({
            type: cnt.RIGHT_EDIT_BTN,
          });
        }
      }
      globalVar.editLink = {
        path: "/edit-order",
        obj: order,
      };
    }
  };

  /** RENDER JXS DATA */
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.managePo} />
      <div className="animated fadeInRightFAKE">
        <div className="row">
          <form className="m-t" autoComplete="off">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>{titleLeft}</h5>
                  <div className="ibox-tools">
                    <a className="dropdown-toggle" data-toggle="dropdown">
                      <span className={`badge badge-${color}`}>
                        <i className={`fa fa-${icon}`}></i> &nbsp; {titleRight}{" "}
                        &nbsp;
                        <i className="fa fa-chevron-down"></i>
                      </span>
                    </a>
                    <ul className="dropdown-menu dropdown-user">
                      {tiles.map((tile, tileIndex) => {
                        if (tile.title !== titleRight) {
                          return (
                            <li key={tileIndex}>
                              <a onClick={() => tile.callback(tile)}>
                                {" "}
                                {tile.title}
                              </a>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </div>

                <div className="ibox-content panel-primary contentCard">
                <HandleSkeletonTbleFac
                    classParam={`${isLoading ? "" : "hide"}`}
                  />
                  <div className={` ${isLoading ? "hide" : ""}`}>
                  {poData.length === 0 ? (
                    <div className="alert alert-warning text-center">
                      <a className="alert-link">{appLabel.noDataInTableMsg}</a>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <div className="table-responsive">
                        <table
                          id="myTable"
                          className="table table-striped table-bordered table-hover"
                        >
                          <thead>
                            <tr>
                              <th></th>
                              <th>{appLabel.date}</th>
                              <th>{appLabel.poNo}</th>
                              <th>{appLabel.from}</th>
                              <th>{appLabel.to}</th>
                              <th>{appLabel.status}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {poData.map((order, index) => (
                              <tr className="gradeX" key={index}>
                                <td>
                                  <label className="myradio-button">
                                    <input
                                      type="radio"
                                      name="radio"
                                      onClick={() => onSelectPo(order)}
                                    />
                                    <span className="label-visible">
                                      <span className="myfake-radiobutton"></span>
                                    </span>
                                  </label>
                                </td>
                                <td>{order.PO_ORDER_DATE}</td>
                                <td>{order.ORDERID}</td>
                                <td>{order.BUYER}</td>
                                <td>{order.SUPPLIER}</td>
                                <td>{order.PO_VERIFICATION}</td>
                                {/* <td>
                                  {handleStatusIcon(
                                    order.PO_VERIFICATION
                                  )}
                                  &nbsp;
                                  {handleFlgStatus(
                                    order.PO_VERIFICATION
                                  )}
                                </td> */}
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th></th>
                              <th>{appLabel.date}</th>
                              <th>{appLabel.poNo}</th>
                              <th>{appLabel.from}</th>
                              <th>{appLabel.to}</th>
                              <th>{appLabel.status}</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
});

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagePurchaseOrder);
