import React, { useEffect, useState } from "react";
import Main from "../layouts/Main";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { apis as api } from "../../services/api.action";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM, RAL } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import NumberFormat from "react-number-format";
import { HandleSkeletonTbleFac } from "../layouts/Facility";

const ManageSharedOrder = (props) => {

  const { userData, menuData, location } = props;
  const { profileId, session, companyId, companyTypeId } = userData;
  const globalVar = window.globalData;
  const dispatch = useDispatch();
  const [poData, setPoData] = useState([]);
  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [isFunder, setIsFunder] = useState(false);
  const [isCustodian, setIsCustodian] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [tiles, setTiles] = useState([
    {
      title: appLabel.authorized,
      otherTitle: appLabel.authorizedListing,
      color: "primary",
      icon: "tag",
      criteria: "A",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.unauthorized,
      otherTitle: appLabel.unauthorizedListing,
      color: "warning",
      icon: "exclamation-triangle",
      criteria: "U",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.disabled,
      otherTitle: appLabel.disabledListing,
      color: "danger",
      icon: "close",
      criteria: "R",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
  ]);

  useEffect(() => {
    // console.log(props);
    handleSwitchCondition()
    getListByCriteria(tiles[0]); // get position 0 object
  }, []);

  const handleSwitchCondition = () => {
    switch (companyTypeId) {
      case "CT2022012400003": // funder
        setIsFunder(true);
        break;
        case "CT2022012400000": // custodian
        setIsCustodian(true);
        break;
      default: // other entity type
        setIsFunder(false);
        setIsCustodian(false)
        break;
    }
  };
  function getListByCriteria(params) {
    // console.log(params);
    setPoData([]);

    setTitleLeft(params.otherTitle);
    setTitleRight(params.title);
    setColor(params.color);
    setIcon(params.icon);

    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true)

    let body = {
      profileId,
      orderId: "",
      criteria: params.criteria,
      companyId,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetSharedPo, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setPoData(res.broadcastListing);
        BOM.DatatableUsage();
        setIsLoading(false)
      }
    });
  }

  const onSelectPo = (order) => {
    // ["RIGHT_DETAIL_BTN", "RIGHT_EDIT_BTN_FAKE"].map((item) => {
    //   dispatch({
    //     type: item,
    //   });
    // });

    /** CHECK FOR RIGHT BTN PRIVILEGES */
    let path = appStatic.pathData.find((item) => item.URL === location.pathname);
    if (path) {
      if (RAL.viewAction(menuData, path.DESCRIPTION)) {
        dispatch({
          type: cnt.RIGHT_DETAIL_BTN
        });
      }
    }

    globalVar.viewLink = {
      path: "/finance-order",
      obj: order,
    };

    globalVar.editLink = {
      path: "/edit-finance",
      obj: order,
    };
  };

  /** RENDER JXS DATA */
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.shareOrder} />
      <div className="animated fadeInRight">
        <div className="row">
          <form className="m-t" autoComplete="off">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>{titleLeft}</h5>
                  <div className="ibox-tools">
                  </div>
                </div>

                <div className="ibox-content panel-primary contentCard">
                <HandleSkeletonTbleFac
                    classParam={`${isLoading ? "" : "hide"}`}
                  />
                  <div className={` ${isLoading ? "hide" : ""}`}>
                  {poData.length === 0 ? (
                    <div className="alert alert-warning text-center">
                      <a className="alert-link">{appLabel.noDataInTableMsg}</a>
                    </div>
                  ) : (
                      <div className="table-responsive">
                        <table
                          id="myTable"
                          className="table table-striped table-bordered table-hover"
                        >
                          <thead>
                          <tr>
                              <th></th>
                              <th>{appLabel.date}</th>
                              <th>{appLabel.intentId}</th>
                              <th>{appLabel.poNo}</th>
                              <th className={`${isFunder ? "":"hide"}`} >{appLabel.funder}</th>
                              <th className={`${isCustodian ? "":"hide"}`} >{appLabel.to}</th>
                              <th>{appLabel.poAmount}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {poData.map((order, index) => (
                              <tr className="gradeX" key={index}>
                                <td>
                                  <label className="myradio-button">
                                    <input
                                      type="radio"
                                      name="radio"
                                      onClick={() =>
                                        onSelectPo(order)
                                      }
                                    />
                                    <span className="label-visible">
                                      <span className="myfake-radiobutton"></span>
                                    </span>
                                  </label>
                                </td>
                                <td>{order.BID_END_DATE}</td>
                                <td>{order.INTENT_ID}</td>
                                <td>{order.ORDERID}</td>
                                <td className={`${isFunder ? "":"hide"}`} >{order.COMPANY_SHARED_WITH}</td>
                                <td className={`${isCustodian ? "":"hide"}`}>{order.SUPPLIER_NAME}</td>
                                <td>
                                  <NumberFormat
                                    value={order.REQUESTED_AMOUNT}
                                    displayType="text"
                                    thousandSeparator={true}
                                    prefix={order.CCY + " "}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                           <tr>
                              <th></th>
                              <th>{appLabel.date}</th>
                              <th>{appLabel.intentId}</th>
                              <th>{appLabel.poNo}</th>
                              <th className={`${isFunder ? "":"hide"}`} >{appLabel.funder}</th>
                              <th className={`${isCustodian ? "":"hide"}`} >{appLabel.to}</th>
                              <th>{appLabel.poAmount}</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                  )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ManageSharedOrder);
