import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import ContentTop from "../../../ui-setup/ContentTop";
import appLabel from "../../../../config/appLabel";
import { BOM } from "../../../../services/defined.action";
import RightSide from "../../../ui-setup/RightSide";
import { Tooltips } from "../../../layouts/Facility";
import appStatic from "../../../../config/appStaticData";

export const RoleForm = (props) => {
  const { onSubmit, pageTitle, roleObj, action } = props;
  const { toolTip } = appStatic;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    // console.log(props);

    /** SET CURRENT VALUE */

    switch (action.toLowerCase()) {
      case "edit":
        return setValue("roleName", roleObj.ROLE_NAME);
      default:
        setValue("roleName", "");
        break;
    }

    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);
  }, []);

  const formSubmit = (formValues) => {
    onSubmit(formValues);
    // console.log(formValues);
  };

  return (
    <form
      method="POST"
      id="NewItemForm"
      autoComplete="off"
      onSubmit={handleSubmit(formSubmit)}
    >
      <RightSide />

      <ContentTop pageTitle={pageTitle} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{appLabel.roleInformation}</h5>
                <div className="ibox-tools">
                  <Link
                    to="/manage-role-menu"
                    className="btn btn-primary btn-xs"
                  >
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;
                    {appLabel.backBtn}
                  </Link>
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle text-navy" />
                      &nbsp;
                      {appLabel.generalInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="row form-group col-md-6">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.parentRoleName}
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control input-sm"
                          readOnly
                          defaultValue={roleObj && roleObj.ROLE_DESCRIPTION}
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.roleName}&nbsp;<Tooltips title={toolTip.roleName} />
                          <span className="asterisks">*</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control input-sm"
                          {...register("roleName", {
                            required: "Role Name is required",
                            minLength: {
                              value: 3,
                              message:
                                "Role Name should be greater than 3 characters",
                            },
                          })}
                          placeholder={appLabel.roleName}
                        />
                        <span className="asterisks">
                          {errors?.roleName?.message}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default RoleForm;
