import React, { useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../../services/api.action";
import { connect } from "react-redux";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../../ui-setup/RecordStatus";
import appStatic from "../../../config/appStaticData";
import RightSide from "../../ui-setup/RightSide";
import { AmountFacility } from "../../layouts/Facility";
import { Tooltips } from "../../layouts/Facility";

export const NewTax = (props) => {
  const { location, userData, configData } = props;
  const { state } = location;
  const { profileId, session, companyId } = userData;
  const { isCsd } = configData;
  const { toolTip } = appStatic;

  const [isRequiredField, setIsRequiredField] = useState(true);
  const [amount, setAmount] = useState(undefined);
  const currentDate = BOM.CurrentDate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    setValue("effectiveDate", currentDate)
    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);
  }, []);

  const formSubmit = (formValues) => {
    // console.log("data", formValues);

    //Validate NaN value
    if (BOM.ValidateNaNValue(formValues.rate, appLabel.rate)) return;

    // formValues.rate = parseFloat(formValues.rate);

    //We don't use it anymore but we leave it for the sake of backend not to fail
    formValues.amount = 0 + "";
    formValues.waiverAllow = "N";

    let body = {
      profileId,
      session,
      ...formValues,
    };

    // console.log(body);
    // return
    /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(body, api.CreateTax, "/manage-tax");
  };

  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <RightSide />
        <ContentTop pageTitle={appLabel.newTax} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{appLabel.taxInformation}</h5>
                  <div className="ibox-tools">
                    <Link to="/manage-tax" className="btn btn-primary btn-xs">
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.generalInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.category}&nbsp;
                            <Tooltips title={toolTip.category} />
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.category}
                            {...register("category", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.category?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.type}&nbsp;
                            <Tooltips title={toolTip.type} />
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          {/* <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.type}
                            {...register("type", {
                              required: "Field is required.",
                            })}
                          /> */}

                          <select
                            className="form-control input-sm"
                            {...register("type", {
                              required: "Field is required.",
                            })}
                          >
                            <option value="">{appLabel.choose}</option>
                            {appStatic.taxType.map((item, index) => (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </select>

                          <span className="asterisks">
                            {errors?.type?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.rate}&nbsp;
                            <Tooltips title={toolTip.rate} />
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.rate}
                            {...register("rate", {
                              required: "Field is required.",
                              min: {
                                value: 0.01,
                                message: "Rate cannot be less than 0.01%",
                              },
                              max: {
                                value: 100,
                                message: "Rate cannot be greater than 100%",
                              },
                            })}
                          />
                          <span className="asterisks">
                            {errors?.rate?.message}
                          </span>
                        </div>
                      </div>
                      {/* <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.amount}&nbsp;
                            <Tooltips title={toolTip.taxAmount} />
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <AmountFacility
                            setIsRequiredField={setIsRequiredField}
                            setAmount={setAmount}
                            isRequiredField={isRequiredField}
                          />
                        </div>
                      </div> */}
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.effectiveDate}&nbsp;
                            <Tooltips title={toolTip.effectiveDate} />
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="date"
                            className="form-control input-sm"
                            disabled
                            {...register("effectiveDate", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.effectiveDate?.message}
                          </span>
                        </div>
                      </div>
                      {/* <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.waiverAllow}&nbsp;
                            <Tooltips title={toolTip.waiverAllow} />
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm"
                            {...register("waiverAllow", {
                              required: "Field is required.",
                            })}
                          >
                            <option value="">{appLabel.choose}</option>
                            {appStatic.yesOrNoStatus.map((item, index) => (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <span className="asterisks">
                            {errors?.waiverAllow?.message}
                          </span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <RecordStatus {...userDetail} /> */}
        </div>
      </form>
      <RecordStatus {...BOM.NewRecordStatusdata()} />
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  configData: state.auth.configData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewTax);
