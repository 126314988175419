import React, { useEffect, useState } from "react";
import Main from "../layouts/Main";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { apis as api } from "../../services/api.action";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM, RAL } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import NumberFormat from "react-number-format";
import { HandleSkeletonTbleFac } from "../layouts/Facility";

const ManageSupplierIntent = (props) => {
  const { userData, menuData, location } = props;
  const { profileId, session, companyId } = userData;
  const globalVar = window.globalData;
  const dispatch = useDispatch();
  const [intentData, setIntentData] = useState([]);
  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tiles, setTiles] = useState([
    {
      title: appLabel.authorized,
      otherTitle: appLabel.authorizedListing,
      color: "primary",
      icon: "tag",
      criteria: "Y",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.unauthorized,
      otherTitle: appLabel.unauthorizedListing,
      color: "warning",
      icon: "exclamation-triangle",
      criteria: "N",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
  ]);

  useEffect(() => {
    // console.log(props);
    getListByCriteria(tiles[0]); // get position 0 object
  }, []);

  function getListByCriteria(params) {
    // console.log(params);
    // INITIALIZE RIGHT ACTION MENU
    // [cnt.CLEAR_RIGHT_BTN, cnt.RIGHT_ADD_BTN].map((item) => {
    //   dispatch({
    //     type: item,
    //   });
    // });
    setIntentData([]);

    setTitleLeft(params.otherTitle);
    setTitleRight(params.title);
    setColor(params.color);
    setIcon(params.icon);

    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true)

    let body = {
      profileId,
      criteria: params.criteria,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetIntent, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const intentData = res.supplierShareIntentListing;
        setIntentData(intentData);
        BOM.DatatableUsage();
        setIsLoading(false)
      }
    });
  }

  const onSelectIntent = (objParam) => {
    // console.log(objParam);
    if (!objParam) return;

    /** CHECK FOR RIGHT BTN PRIVILEGES */
    let path = appStatic.pathData.find(
      (item) => item.URL === location.pathname
    );
    if (path) {
      if (RAL.viewAction(menuData, path.DESCRIPTION)) {
        dispatch({
          type: cnt.RIGHT_DETAIL_BTN,
        });
      }
    }

    switch (objParam.ITEM_TYPE.toLowerCase()) {
      case "po":
        globalVar.viewLink = {
          path: "/supplier-intent-po-detail",
          obj: objParam,
        };
        break;
      case "inv":
        globalVar.viewLink = {
          path: "/supplier-intent-inv-detail",
          obj: objParam,
        };
        break;
    }
  };

  function hadleStatusIcon(key) {
    switch (key.toLowerCase()) {
      case "finalised":
        return <i className="fa fa fa-play fa-rotate-90 text-navy" />;
      case "new":
        return <i className="fa fa-play fa-rotate-270 text-warning" />;
        case "in progress":
        return <i className="fa fa-refresh text-success" />;
    }
  }

  /** RENDER JXS DATA */
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.manageSupplierIntent} />
      <div className="animated fadeInRightFAKE">
        <div className="row">
          <form className="m-t" autoComplete="off">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>{titleLeft}</h5>
                  <div className="ibox-tools">
                    <a className="dropdown-toggle" data-toggle="dropdown">
                      <span className={`badge badge-${color}`}>
                        <i className={`fa fa-${icon}`}></i> &nbsp; {titleRight}{" "}
                        &nbsp;
                        <i className="fa fa-chevron-down"></i>
                      </span>
                    </a>
                    <ul className="dropdown-menu dropdown-user">
                      {tiles.map((tile, tileIndex) => {
                        if (tile.title !== titleRight) {
                          return (
                            <li key={tileIndex}>
                              <a onClick={() => tile.callback(tile)}>
                                {" "}
                                {tile.title}
                              </a>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </div>

                <div className="ibox-content panel-primary contentCard">
                <HandleSkeletonTbleFac
                    classParam={`${isLoading ? "" : "hide"}`}
                  />
                  <div className={` ${isLoading ? "hide" : ""}`}>
                  {intentData.length === 0 ? (
                    <div className="alert alert-warning text-center">
                      <a className="alert-link">{appLabel.noDataInTableMsg}</a>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <div className="table-responsive">
                        <table
                          id="myTable"
                          className="table table-striped table-bordered table-hover"
                        >
                          <thead>
                            <tr>
                              <th></th>
                              <th>{appLabel.intentId}</th>
                              <th>{appLabel.itemType}</th>
                              <th>{appLabel.itemId}</th>
                              <th>{appLabel.to}</th>
                              <th>{appLabel.amountRequested}</th>
                              <th>{appLabel.status}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {intentData.map((item, index) => (
                              <tr className="gradeX" key={index}>
                                <td>
                                  <label className="myradio-button">
                                    <input
                                      type="radio"
                                      name="radio"
                                      onClick={() => onSelectIntent(item)}
                                    />
                                    <span className="label-visible">
                                      <span className="myfake-radiobutton"></span>
                                    </span>
                                  </label>
                                </td>
                                <td>{item.INTENT_ID}</td>
                                <td>{item.ITEM_TYPE}</td>
                                <td>{item.ITEM_ID}</td>
                                <td>{item.SUPPLIER}</td>
                                <td>
                                  <NumberFormat
                                    value={item.REQUESTED_AMOUNT}
                                    displayType="text"
                                    thousandSeparator={true}
                                    prefix={item.CCY + " "}
                                  />
                                </td>
                                <td>
                                  {hadleStatusIcon(item.INTENT_STATUS)}  {" "}
                                  {item.INTENT_STATUS}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th></th>
                              <th>{appLabel.intentId}</th>
                              <th>{appLabel.itemType}</th>
                              <th>{appLabel.itemId}</th>
                              <th>{appLabel.to}</th>
                              <th>{appLabel.amountRequested}</th>
                              <th>{appLabel.status}</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
});

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageSupplierIntent);
