import React, { useEffect, useState } from "react";
import { apis as api } from "../../services/api.action";
import { connect, useDispatch } from "react-redux";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../ui-setup/RecordStatus";
import appStatic from "../../config/appStaticData";
import NumberFormat from "react-number-format";
import OtpModal from "../layouts/OtpModal";
import {
  Tooltips,
  InputAmountFacility,
  Col12BlockFacility,
  InputTextFacility,
  NoDataFac,
  NavTabFac,
  AmountFacility,
} from "../layouts/Facility";
import Notiflix from "notiflix";

export const FinancedInvoiceDetail = (props) => {
  const { location, userData, configData, recordData, menuData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId, companyTypeId, userName } = userData;
  const { isCsd } = configData;

  const dispatch = useDispatch();
  const [isCsdCheck, setIsCsdCheck] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [invoiceDetail, setInvoiceDetail] = useState({});

  const [orderDetail, setOrderDetail] = useState({});
  const [shipFromData, setShipFromData] = useState({});
  const [billFromData, setBillFromData] = useState({});
  const [shipToData, setShipToData] = useState({});
  const [billToData, setBillToData] = useState({});
  const [isPO, setIsPO] = useState(false);
  const [fromCompanyDetail, setFromCompanyDetail] = useState({});
  const [toCompanyDetail, setToCompanyDetail] = useState({});

  const [contractDetail, setContractDetail] = useState({});
  const [financeDetail, setFinanceDetail] = useState({});
  const [discountDetail, setDiscountDetail] = useState({});
  const [financeHistoryData, setFinanceHistoryData] = useState([]);

  const [otp, setOtp] = useState("");
  const [show, setShow] = useState(false);
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const [inputFldData, setInputFldData] = useState([]);
  const [isSupplier, setIsSupplier] = useState(false);
  let tempDocument = [];

  useEffect(() => {
    // console.log(state.ggParam);
    if (isCsd.toLowerCase() === "y") {
      setIsCsdCheck(true);
    }
    getDetailById(state.ggParam);
    
    //CALL INVOICE DOCUMENT BLOCK
    BOM.getSpecDoc(ggParam?.INV_ID, setDocuments, userData);

  }, []);

  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      invoiceId: params.INV_ID,
      companyId,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetInvoice, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.invoiceDetail;
        const invDetail = objDetail.INVOICE_DATA;
        const biddingObj = objDetail.BIDDING_DATA;
        const taxDetail = objDetail.TAX_DATA;
        setInvoiceDetail(invDetail);
        setContractDetail(objDetail.CONTRACT_DATA);
        setFinanceDetail(objDetail.BIDDING_DATA);
        setFinanceHistoryData(objDetail.FUND_HISTORY_DATA);
        // setShareSupplierReq(objDetail.SUPPLIER_SHARE_REQ_DATA);

        getPo(objDetail.ORDERID);

        // dispatch({
        //   type: cnt.RECORD_STATUS,
        //   payload: invoiceDetail,
        // });

        //handle right side
        // BOM.IsApproveOrReject(discountDetail.FLG_STATUS, menuData, "Quoted Invoice");

        //get document
        // getSpecDoc(state.ggParam);

        // mergedDataArr
        setInputFldData([
          {
            name: appLabel.invoiceId,
            value: invDetail.INV_ID,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.currency,
            value: invDetail.INV_CCY,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.invAmount,
            value: taxDetail.TOTAL_AMOUNT,
            fldType: "amount",
            section: "n/a",
          },
          {
            name: appLabel.invDate,
            value: invDetail.INV_DATE,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.pymtOption,
            value: invDetail.PAYMENT_METHOD,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.investorInterest,
            value: biddingObj.FUNDER_RATE,
            fldType: "text",
            section: "finance",
          },
          {
            name: appLabel.tenor,
            value: biddingObj.TENOR,
            fldType: "text",
            section: "finance",
          },
          {
            name: appLabel.trustAccount,
            value: biddingObj.DPA,
            fldType: "text",
            section: "finance",
          },
          {
            name: appLabel.valueDate,
            value: biddingObj.VALUE_DATE,
            fldType: "text",
            section: "finance",
          },
          {
            name: appLabel.funderAmnt,
            value: biddingObj.FUNDER_AMOUNT,
            fldType: "amount",
            section: "finance",
          },
          {
            name: appLabel.amountPayable1,
            value: biddingObj.OTHER_FEES,
            fldType: "amount",
            section: "finance",
          },
          {
            name: appLabel.amountPayable2,
            value: biddingObj.OTHER_FEE_2,
            fldType: "amount",
            section: "finance",
          },
          {
            name: appLabel.totalRepayment,
            value: biddingObj.TOTAL_REPAYMENT,
            fldType: "amount",
            section: "finance",
          },

          {
            name: appLabel.transactionFee,
            value: biddingObj.ARRANGEMENT_FEES,
            fldType: "amount",
            section: "finance",
          },
          {
            name: appLabel.totalSettlementAmount,
            value: biddingObj.TOTAL_SETTLEMENT,
            fldType: "amount",
            section: "finance",
          },
        ]);
      }
    });
  };
  const getSpecDoc = () => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      id: ggParam?.INV_ID,
      // type,//no need to send type anymore
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setDocuments(res.docListing);
      }
    });
  };
  const getSpecDocOLD = async (id, type) => {
    let body = {
      profileId,
      id,
      type,
      session,
    };

    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        switch (type?.toLowerCase()) {
          case "invoice":
            res.docListing.forEach((element) => {
              tempDocument.push(element);
            });
            break;

          default:
            res.docListing.forEach((element) => {
              tempDocument.push(element);
            });
            break;
        }

        setDocuments([...tempDocument]);
      }
    });
  };
  const getCompanyById = (
    companyId,
    setShipParam,
    setBillParam,
    setCompanyDetail
  ) => {
    let body = {
      profileId,
      companyId,
      session,
    };

    /** FETCH */
    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let companyDetail = res.companyDetail;

        setShipParam(companyDetail.SHIP_DATA);
        setBillParam(companyDetail.BILL_DATA);
        setCompanyDetail(companyDetail);
      }
    });
  };
  const getPo = (idParam) => {
    let body = {
      profileId,
      orderId: idParam,
      criteria: "A",
      companyId,
      session,
    };
    BOM.LoadAlert(cnt.LOAD, "Processing");

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetPo, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let orderDetail = res.purchaseOrderDetail;
        setOrderDetail(orderDetail);

        getCompanyById(
          orderDetail.PO_FROM_COMPANYID,
          setShipFromData,
          setBillFromData,
          setFromCompanyDetail
        );
        getCompanyById(
          orderDetail.PO_TO_COMPANYID,
          setShipToData,
          setBillToData,
          setToCompanyDetail
        );
        setIsPO(true);
      }
    });
  };
  const showModal = () => {
    console.log("set show");
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };
  const onGenerateOtp = () => {
    $(".clearInput").val("");
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      // userName:"gedeon@openfactorgroup.com",
      userName,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GeneratePoToken, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        console.log(res);
        showModal();
      }
    });
  };
  const onResendOtp = () => {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      // userName:"gedeon@openfactorgroup.com",
      userName,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.ResendOtp, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        BOM.AlertMsg(cnt.SUCCESS, res.message);
        //display inner popup
        showModal();
        $(".clearInput").val("");
      }
    });
  };
  const onValidOtp = (orderDetail) => {
    setOtpErrorMsg("");

    if (!otp) {
      setOtpErrorMsg("OTP is required.");
    }
    if (otp.length < 6) {
      setOtpErrorMsg("Not less than 6 digits");
      return;
    }
    if (otp.length > 6) {
      setOtpErrorMsg("Not greater than 6 digits");
      return;
    }
    let body = {
      profileId,
      otp,
      session,
    };

    BOM.LoadAlert(cnt.LOAD, "Processing");
    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.OtpValidation, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        $(".clearInput").val("");
        // call to update
        onAppoveConsentStatusPo(orderDetail);
      }
    });
  };
  const onUpdateConsentStatusPo = (objParam) => {
    let body = {
      profileId,
      broadcastId: objParam.BROADCAST_ID,
      orderId: state.ggParam.ORDERID,
      session,
    };

    // confirm
    Notiflix.Confirm.show(
      "Confrim Funding",
      "Click Yes to Approve or No to Reject Funding.",
      "Yes",
      "No",
      function () {
        // Yes
        //generate OTP
        onGenerateOtp();
      },
      function () {
        // No button
        BOM.LoadAlert(cnt.LOAD, "Processing");
        body.criteria = "R";
        BOM.SendReqAction(body, api.ApproveRejectConsent, "financed-order");
        // console.log(body);
      }
    );
  };
  const onAppoveConsentStatusPo = (objParam) => {
    let body = {
      profileId,
      broadcastId: objParam.BROADCAST_ID,
      orderId: state.ggParam.ORDERID,
      session,
    };

    // console.log(body);

    BOM.LoadAlert(cnt.LOAD, "Processing");
    body.criteria = "A";
    BOM.SendReqAction(body, api.ApproveRejectConsent, "financed-order");
  };
  const onApprove = () => {
    let bodyApprove = {
      profileId,
      typeName: "invoice",
      typeId: state.ggParam.INV_ID,
      criteria: "A",
      session,
    };

    // console.log(bodyApprove);
    BOM.GlobalApproveRejectAction(
      bodyApprove,
      api.ApproveRejectDiscount,
      "financed-invoice",
      "Invoice",
      "Approve "
    );
  };
  const onReject = () => {
    let bodyDisable = {
      profileId,
      typeName: "invoice",
      typeId: state.ggParam.INV_ID,
      criteria: "R",
      session,
    };

    // console.log(bodyDisable);
    BOM.GlobalApproveRejectAction(
      bodyDisable,
      api.ApproveRejectDiscount,
      "financed-invoice",
      "Invoice",
      "Reject "
    );
  };

  return (
    <Main {...props} onApprove={onApprove} onReject={onReject}>
      <ContentTop pageTitle={appLabel.financedInvoiceDetail} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{appLabel.invoiceInformation}</h5>
                <div className="ibox-tools">
                  <Link
                    to="/financed-invoice"
                    className="btn btn-primary btn-xs"
                  >
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;
                    {appLabel.backBtn}
                  </Link>
                  &nbsp;
                  {orderDetail.FLG_SUPPLIER_CONSENT === "U" &&
                    isSupplier &&
                    !show && (
                      <span>
                        <button
                          type="button"
                          className="btn btn-primary btn-xs"
                          onClick={() => onUpdateConsentStatusPo(orderDetail)}
                        >
                          <i className="fa fa-thumbs-up" />
                          &nbsp;
                          {appLabel.confirmFunding}
                        </button>
                      </span>
                    )}
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                  <Col12BlockFacility topTitle={appLabel.generalInfo}>
                    {inputFldData.map((item, index) => (
                      <div key={index}>
                        {item.section === "general" &&
                          (item.fldType === "amount" ? (
                            <InputAmountFacility
                              inputLabel={item.name}
                              inputValue={item.value}
                            />
                          ) : (
                            <InputTextFacility
                              inputLabel={item.name}
                              inputValue={item.value}
                            />
                          ))}
                      </div>
                    ))}
                    <NoDataFac arrList={inputFldData} />
                  </Col12BlockFacility>
                  <Col12BlockFacility topTitle={appLabel.invoiceFinancingInfo}>
                    {inputFldData.map((item, index) => (
                      <div key={index}>
                        {item.section === "finance" &&
                          (item.fldType === "amount" ? (
                            <InputAmountFacility
                              inputLabel={item.name}
                              inputValue={item.value}
                            />
                          ) : (
                            <InputTextFacility
                              inputLabel={item.name}
                              inputValue={item.value}
                            />
                          ))}
                      </div>
                    ))}
                  </Col12BlockFacility>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.otherInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <ul className="nav nav-tabs">
                      <li className="active">
                        <a
                          data-toggle="tab"
                          href="#documentInfo"
                          target="_self"
                        >
                         <i className="fa fa-file-pdf-o text-navy"  />
                          &nbsp;{appLabel.documentInfo}
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#pOInfo" target="_self">
                          <i className="fa fa-cart-plus text-navy" />
                          &nbsp;{appLabel.pOInfoShort}
                        </a>
                      </li>
                      <li>
                        <a
                          data-toggle="tab"
                          href="#contractInfo"
                          target="_self"
                        >
                         <i className="fa fa-file-text text-navy"  />
                          &nbsp;{appLabel.contractInfoShort}
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#financeInfo" target="_self">
                          <i className="fa fa-money text-navy" />
                          &nbsp;{appLabel.financeInfoShort}
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane active" id="documentInfo">
                        <div className="row" style={{ marginTop: "30px" }}>
                          <div className="col-sm-12">
                            {documents.length === 0 ? (
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDocumentMsg}
                                </a>
                              </div>
                            ) : (
                              <div>
                                {documents.map((item, index) => (
                                  <div className="col-md-4" key={index}>
                                    <div className="file-box">
                                      <div className="file">
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            BOM.DownloadAction(
                                              item.DOC_OBJECT,
                                              item.DOC_ID +
                                                " " +
                                                item.DOC_CATEGORY.toLowerCase()
                                            )
                                          }
                                        >
                                          <span className="corner" />
                                          <div className="icon">
                                           <i className="fa fa-file-pdf-o" style={{color:'#d50000'}} />
                                          </div>
                                          <div className="file-name">
                                            {item.DOC_CATEGORY}
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="pOInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          {Object.keys(invoiceDetail).length === 0 ? (
                            <div className="col-sm-12">
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDataInTableMsg}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-12">
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.poNo}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.ORDERID}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.from}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.FROM_COMPANY_NAME}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.to}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.TO_COMPANY_NAME}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.amount}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <NumberFormat
                                    className="form-control input-sm"
                                    value={orderDetail.PO_AMOUNT}
                                    displayType="text"
                                    disabled
                                    thousandSeparator={true}
                                    prefix={""}
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.currency}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.PO_CCY}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.pymtOption}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.PO_PAYMENT_OPTION}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="tab-pane" id="contractInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          {Object.keys(contractDetail).length === 0 ? (
                            <div className="col-sm-12">
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDataInTableMsg}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-12">
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.contractId}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={contractDetail.PO_CONTRACT_ID}
                                    disabled
                                  />
                                </div>
                              </div>

                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.type}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={contractDetail.TRANS_TYPE}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.value}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <NumberFormat
                                    className="form-control input-sm"
                                    value={contractDetail.CONTR_VALUE}
                                    displayType="text"
                                    disabled
                                    thousandSeparator={true}
                                    prefix={""}
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.currency}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={contractDetail.CONTR_CCY}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.startDate}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={
                                      contractDetail.CONTR_START_DATE
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="tab-pane" id="financeInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          {Object.keys(financeDetail).length === 0 ? (
                            <div className="col-sm-12">
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDataInTableMsg}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="col-sm-12">
                                <label className="al-heading">
                                  <i className="fa fa-circle-o text-navy"></i>
                                  &nbsp; {appLabel.generalInfo}
                                </label>
                                <div
                                  className="hr-line-dashed"
                                  style={{ marginTop: 0 }}
                                />
                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.fundingId}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      defaultValue={financeDetail.FUNDING_ID}
                                      disabled
                                    />
                                  </div>
                                </div>

                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.funder}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      defaultValue={financeDetail.INVESTOR_NAME}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.transactionDate}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      defaultValue={financeDetail.TRN_DATE}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.paymentDate}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      defaultValue={financeDetail.PAYMENT_DATE}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.totalAmount}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <NumberFormat
                                      className="form-control input-sm"
                                      value={financeDetail.TOTAL_AMOUNT}
                                      displayType="text"
                                      disabled
                                      thousandSeparator={true}
                                      prefix={""}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <label className="al-heading">
                                  <i className="fa fa-circle-o text-navy"></i>
                                  &nbsp; {appLabel.discountInfo}
                                </label>
                                <div
                                  className="hr-line-dashed"
                                  style={{ marginTop: 0 }}
                                />
                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.discountId}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      defaultValue={
                                        discountDetail.INV_DISCOUNT_ID
                                      }
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.discountCode}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      defaultValue={
                                        discountDetail.DISCOUNT_CODE
                                      }
                                      disabled
                                    />
                                  </div>
                                </div>

                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.discountAmount}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <NumberFormat
                                      className="form-control input-sm"
                                      value={discountDetail.DISCOUNT_AMOUNT}
                                      displayType="text"
                                      disabled
                                      thousandSeparator={true}
                                      prefix={""}
                                    />
                                  </div>
                                </div>
                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.discounted}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <NumberFormat
                                      className="form-control input-sm"
                                      value={discountDetail.DISCOUNT_PCT}
                                      displayType="text"
                                      disabled
                                      thousandSeparator={true}
                                      prefix={""}
                                    />
                                  </div>
                                </div>
                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.netInvoiceAmount}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <NumberFormat
                                      className="form-control input-sm"
                                      value={discountDetail.NET_INVOICE_AMT}
                                      displayType="text"
                                      disabled
                                      thousandSeparator={true}
                                      prefix={""}
                                    />
                                  </div>
                                </div>
                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.finalAmount}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <NumberFormat
                                      className="form-control input-sm"
                                      value={discountDetail.FINAL_AMOUNT}
                                      displayType="text"
                                      disabled
                                      thousandSeparator={true}
                                      prefix={""}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <label className="al-heading">
                                  <i className="fa fa-circle-o text-navy"></i>
                                  &nbsp; {appLabel.history}
                                </label>
                                <div
                                  className="hr-line-dashed"
                                  style={{ marginTop: 0 }}
                                />

                                <div className="table-responsive">
                                  {financeHistoryData.length === 0 ? (
                                    <div className="alert alert-warning text-center">
                                      <a className="alert-link">
                                        {appLabel.noDataInTableMsg}
                                      </a>
                                    </div>
                                  ) : (
                                    <table
                                      className="footable table table-stripped toggle-arrow-tiny"
                                      data-page-size="10"
                                      data-filter="#filter"
                                    >
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>{appLabel.itemId}</th>
                                          <th>{appLabel.funder}</th>
                                          <th>{appLabel.historyType}</th>
                                          <th>{appLabel.AmountToFinance}</th>
                                          <th>{appLabel.amountFunded}</th>
                                          {/* <th>{appLabel.status}</th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {financeHistoryData.map(
                                          (item, index) => (
                                            <tr className="gradeX" key={index}>
                                              <td>{index + 1}</td>
                                              <td>{item.ITEM_ID}</td>
                                              <td>{item.FUNDER}</td>
                                              <td>{item.ITEM_TYPE}</td>
                                              <td>
                                                <NumberFormat
                                                  value={item.AMOUNT_REQUESTED}
                                                  displayType="text"
                                                  disabled
                                                  thousandSeparator={true}
                                                  prefix={item.CCY + " "}
                                                />
                                              </td>
                                              <td>
                                                <NumberFormat
                                                  value={item.AMOUNT_FUNDED}
                                                  displayType="text"
                                                  disabled
                                                  thousandSeparator={true}
                                                  prefix={item.CCY + " "}
                                                />
                                              </td>
                                              {/* <td>
                                                <i
                                                  className={
                                                    item.FLG_STATUS.toLowerCase() ===
                                                    "f"
                                                      ? "fa fa-check text-navy"
                                                      : "fa fa-times text-danger"
                                                  }
                                                ></i>
                                                &nbsp;
                                                {item.FLG_STATUS.toLowerCase() ===
                                                "f"
                                                  ? appLabel.funded
                                                  : appLabel.notFunded}
                                              </td> */}
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td colSpan="8">
                                            <ul className="pagination pull-right"></ul>
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <RecordStatus {...recordData} /> */}
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinancedInvoiceDetail);
