import React, { useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../../services/api.action";
import { connect } from "react-redux";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../../ui-setup/RecordStatus";
import appStatic from "../../../config/appStaticData";
import RightSide from "../../ui-setup/RightSide";

export const NewCurrency = (props) => {
  const { location, userData, configData } = props;
  const { state } = location;
  const { profileId, session, companyId } = userData;
  const { isCsd } = configData;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [currencyCodeData, setCurrencyCodeData] = useState([]);
  const [currencyCodeDetail, setCurrencyCodeDetail] = useState([]);
  const [isCountry, setIsCountry] = useState(false);

  useEffect(() => {
    getCurrencyCode();
    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);
  }, []);

  const getCurrencyCode = () => {
    let body = {
      profileId,
      currencyCodeId: "",
      session,
    };

    BOM.FetchReqAction(body, api.GetCurrencyCode, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setCurrencyCodeData(res.currencyCodeListing);
      }
    });
  };

  const getCurrencyCodeDetail = (currencyCodeId) => {

    setIsCountry(false);

    if (!currencyCodeId) return;

    let body = {
      profileId,
      currencyCodeId,
      session,
    };

    BOM.FetchReqAction(body, api.GetCurrencyCode, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let detail = res.currencyCodeDetail;

        setCurrencyCodeDetail(detail);
        setValue("country", detail.CCY_COUNTRY);
        setValue("code", detail.CCY_CODE);
        setValue("description", detail.CCY_DESC);
        setValue("isoCode", detail.CCY_ISO_CODE);
        setValue("currencyId", detail.CCY_ID);

        setIsCountry(true);
      }
    });
  };

  const formSubmit = (formValues) => {
    // validation

    switch (true) {
      case !formValues.code:
        return BOM.AlertMsg(cnt.WARNING, "Currency Code is required.");

      case !formValues.description:
        return BOM.AlertMsg(cnt.WARNING, "Currency Description is required.");

      case !formValues.isoCode:
        return BOM.AlertMsg(cnt.WARNING, "Currency ISO Code is required.");

      case !formValues.currencyId:
        return BOM.AlertMsg(cnt.WARNING, "Currency Id is required.");
    }

    let body = {
      profileId,
      session,
      ...formValues,
    };

    // console.log(body);
    /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(body, api.CreateCurrency, "/manage-currency");
  };

  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <RightSide />
        <ContentTop pageTitle={appLabel.newCurrency} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{appLabel.currencyInfo}</h5>
                  <div className="ibox-tools">
                    <Link
                      to="/manage-currency"
                      className="btn btn-primary btn-xs"
                    >
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-sm-12">
                      <div style={{ marginTop: "15px" }} />
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.country}{" "}
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm"
                            {...register("countryInputOnly", {
                              required: "Field is required.",
                              onChange: (e) =>
                                getCurrencyCodeDetail(e.target.value),
                            })}
                          >
                            <option value="">{appLabel.choose}</option>
                            {currencyCodeData.map((item, index) => (
                              <option value={item.CCY_ID} key={index}>
                                {item.CCY_COUNTRY}
                              </option>
                            ))}
                          </select>
                          <span className="asterisks">
                            {errors?.countryInputOnly?.message}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`col-sm-12 ${isCountry ? "" : "hide"}
                      `}
                    >
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.detailInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.currencyId}&nbsp;
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.currencyId}
                            defaultValue={currencyCodeDetail.CCY_ID}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.description}&nbsp;
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.description}
                            defaultValue={currencyCodeDetail.CCY_DESC}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.currencyCode}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.currencyCode}
                            defaultValue={currencyCodeDetail.CCY_CODE}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.isoCode}{" "}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.isoCode}
                            defaultValue={currencyCodeDetail.CCY_ISO_CODE}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <RecordStatus {...userDetail} /> */}
        </div>
      </form>
      <RecordStatus {...BOM.NewRecordStatusdata()} />
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  configData: state.auth.configData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewCurrency);
