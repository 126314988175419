/**
 * @author Gedeon NdundeCode
 * @date 05/12/2021
 */
 import React, { useState, useEffect } from "react";
//  import useGeolocation from "react-hook-geolocation";
import { apis as api } from "../../services/api.action";
 import { connect } from "react-redux";
 import { Link } from "react-router-dom";
 import { useForm } from "react-hook-form";
 
 import {SignOutAction} from "../../services/redux/actions/auth-act";
 import { cnt } from "../../services/constant.action";
 import { BOM } from "../../services/defined.action";
 import appLabel from "../../config/appLabel";
import { BgImgFac } from "../layouts/Facility";
import Logo from "../../assets/img/logoLogin/fxBg.jpg";
import { SubmitBtnLoad } from "../layouts/Facility";
 
 const ResetPassword = (props) => {
   /** INITIALIZE VARIABLES */
   const {
     register,
     handleSubmit,
     formState: { errors },
   } = useForm();
   const { SignOutAction, match} = props;
   const {params} = match;

   const [newPwdShown, setNewPwdShown] = useState(false);
  const [confirmPwdShown, setConfirmPwdShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { history } = props;

   useEffect(() => {
    //  console.log("this is props: ", props);
     SignOutAction();
   }, []);
 
   // Password toggle handler
  const togglePassword = (key) => {
    switch (key.toLowerCase()) {
      case "new":
        return setNewPwdShown(!newPwdShown);
      case "confirm":
        return setConfirmPwdShown(!confirmPwdShown);
    }
  };
 
  const formSubmit = (formValues) => {

    if (formValues.password !== formValues.confirm) {
      BOM.AlertMsg(cnt.WARNING, "New and Confirm Password do not match");
      return;
    }
    
    formValues.token = params.token
    let body = {
      ...formValues
    };

    // console.log(body);

    // /** SEND REQ */
    setIsLoading(true);
    BOM.FetchReqAction(body, api.ResetPassword, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
        setIsLoading(false);
      } else {
        history.push("/sign-in");
      }
    });
  };
 
   return (
     <React.Fragment>
       <BgImgFac/>
       <div className="centeredContent">
         <div className="row">
           <div className="col-md-12">
             <header>
               <div
                 className="middle-box loginscreen animated fadeInDown login-card"
                 style={{ marginBottom: "0%", marginTop: "16%"}}
               >
                  {/* <div className="slogan text-center">
                  <h2><i className="fa fa-lock text-navy"/> &nbsp; {appLabel.resetPwd}</h2>
                </div> */}
                <div className="text-center">
                  <img
                    alt="image"
                    style={{
                      width: "150px",
                      marginTop: "10px",
                      marginBottom: "25px",
                    }}
                    src={Logo}
                  />
                </div>
                <p>Please Fill out the form to reset your password.</p>
                 <form
                   // className="m-t"
                   onSubmit={handleSubmit(formSubmit)}
                   autoComplete="off"
                 >
                   <div className="form-group">
                     <label className="text-left">{appLabel.newPwd}</label>
                     <input
                       type={newPwdShown ? "text" : "password"}
                       className="form-control input-sm"
                       placeholder="************"
                       {...register("password", {
                        required: "Field is required.",
                        minLength: {
                          value: 8,
                          message: "Minimum password length is 8 characters",
                        },
                        pattern: {
                          value: /(?=.*?[A-Z])/ && /(?=.*?[0-9])/ && /(?=.*?[a-z])/ && /(?=.*?[#?!@$%^&*-])/,
                          message: "At least one Uppercase, Lowercase, Digit, Special Character"
                        },
                      })}
                     />
                     <span className="p-viewer" onClick={() => togglePassword("new")}>
                       <i
                         className={
                           newPwdShown ? "fa fa-eye-slash" : "fa fa-eye"
                         }
                       ></i>
                     </span>
                     <span className="asterisks">
                       {errors?.password?.message}
                     </span>
                   </div>
                   <div className="form-group">
                     <label className="text-left">{appLabel.confirmPwd}</label>
                     <input
                       type={confirmPwdShown ? "text" : "password"}
                       className="form-control input-sm"
                       placeholder="************"
                       {...register("confirm", {
                         required: "Password is required !",
                         minLength: {
                          value: 8,
                          message: "Minimum password length is 8 characters",
                        },
                       })}
                     />
                     <span className="p-viewer" onClick={() => togglePassword("confirm")}>
                       <i
                         className={
                           confirmPwdShown ? "fa fa-eye-slash" : "fa fa-eye"
                         }
                       ></i>
                     </span>
                     <span className="asterisks">
                       {errors?.confirm?.message}
                     </span>
                   </div>
                   <div className="form-group text-center">
                     {/* <button
                       type="submit"
                       className="btn btn-primary block full-width"
                       style={{
                         marginTop: 30,
                         boxShadow: "0 10px 6px -6px #fff",
                       }}
                     >
                       <i className="fa fa-arrow-circle-right" /> &nbsp;{" "}
                      {appLabel.proceedBtn}
                     </button> */}
                     <SubmitBtnLoad
                      isLoading={isLoading}
                      btnName={appLabel.unlockBtn}
                      isFullWidth={true}
                      icon={'fa fa-lock'}
                    />
                   </div>
                   <div className="form-group text-center">
                    <Link
                      to="/sign-in"
                      style={{ fontWeight: "bold", color: "rgb(204, 153, 61)" }}
                    >
                      <i className="fa fa-chevron-circle-left" />{" "}
                      <small>{appLabel.goToLogin}</small>
                    </Link>
                  </div>
                 </form>
               </div>
             </header>
           </div>
         </div>
       </div>
     </React.Fragment>
   );
 };
 
 export default connect(null, {SignOutAction })(ResetPassword);
 