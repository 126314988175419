import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import appLabel from "../../../config/appLabel";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import { apis as api } from "../../../services/api.action";
import { handleSignInSkeletonFac, AmntFormatFac } from "../../layouts/Facility";

//dash v2
import { Box, Alert, AlertTitle, Typography, Grid } from "@mui/material";
import PieChart from "./PieChart";
import {
  Share,
  CheckCircleOutline,
  ThumbDownOffAltOutlined,
  WatchLaterOutlined,
  FormatQuote,
} from "@mui/icons-material";
import StatBox from "./StatBox";
import ProgressCircle from "./ProgressCircle";
import { GiMoneyStack } from "react-icons/gi";
import { GiTakeMyMoney } from "react-icons/gi";

const DashboardLayout = (props) => {
  const {
    userData,
    menuData,
    location,
    history,

    dashboardData,
    totalFunded,
    isLoading,
    mockPieData,
    isPo,
    onSelectTile,
  } = props;

  useEffect(() => {
    // console.log(isPo);
  }, []);

  const kol = (
    <>
      {isLoading ? (
        handleSignInSkeletonFac()
      ) : dashboardData.length === 0 ? (
        <Box>
          <Alert severity="info" variant="outlined" sx={{ fontSize: "20px" }}>
            {appLabel.noDataAvailableMsg}
          </Alert>
        </Box>
      ) : (
        <Box
          display={"grid"}
          gridTemplateColumns={"repeat(12, 1fr)"}
          gridAutoRows={"140px"}
          gap={"20px"}
        >
          <Box
            gridColumn={"span 4"}
            gridRow={"span 2"}
            backgroundColor={"#ffffff"}
            overflow={"auto"}
            borderRadius={"15px"}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              borderBottom={`4px solid #4cceac`}
              color={"#e0e0e0"}
              p={"15px"}
            >
              <Typography color={"#333333"} variant="h5" fontWeight={"600"}>
                {`Recent ${isPo ? appLabel.poShort : appLabel.invoice}`}
              </Typography>
            </Box>
            {dashboardData.map((item, i) => (
              <Box
                key={`${i}`}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                borderBottom={`4px solid #4cceac`}
                p={"15px"}
              >
                <Box>
                  <Typography>{item.icon}</Typography>
                  <Typography color={"gray"} variant="h5" fontWeight={"600"}>
                    {`${item.itemType} ${item.name}`}
                  </Typography>
                </Box>
                <Box color={"141414"}>
                  <ProgressCircle
                    size="30"
                    progress={parseInt(item.value) / 100}
                  />
                </Box>
                <Box
                  backgroundColor={"#333333"}
                  color={"#ffffff"}
                  p={"5px 10px"}
                  borderRadius={"4px"}
                  width={"auto"}
                  alignItems={"center"}
                  fontWeight={"bold"}
                >
                  {item.value}
                </Box>
              </Box>
            ))}
          </Box>
          {/* ROW 2 */}
          <Box
            gridColumn={"span 4"}
            backgroundColor={"#ffffff"}
            boxShadow="3"
            gridRow={"span 2"}
            borderRadius={"15px"}
            // overflow={"auto"}
          >
            <Box
              mt={"20px"}
              p={"0 30px"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            ></Box>
            <Box height={"300px"} mt={"-20px"}>
              <PieChart isDashboard={true} dataChart={mockPieData} />
            </Box>
          </Box>
          <Box
            gridColumn={"span 4"}
            backgroundColor={"#ffffff"}
            gridRow={"span 2"}
            borderRadius={"15px"}
            p={"25px"}
            boxShadow="3"
            overflow={"auto"}
          >
            <Typography variant="h5" fontWeight={"600"} color={"#333333"}>
              {`Total ${
                isPo ? appLabel.poShort : appLabel.invoice
              } Funded Amount`}
            </Typography>
            {totalFunded.map((item, index) => (
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                mt={"25px"}
                key={index}
              >
                {/* <i className="fa fa-money fa-2x text-navy" /> */}
                <GiMoneyStack size={"3em"} color="#333333" />
                <Typography variant="h5" color={"#4cceac"} sx={{ mt: "15px" }}>
                  {/* <AmntFormatFac
                    inputValue={item.TOTAL_FUNDED}
                    currency={item.CCY}
                  /> */}
                  {`${item.CCY} ${BOM.formatNumber(
                    parseFloat(item.TOTAL_FUNDED)
                  )}`}
                </Typography>
                <Typography color={"#333333"} fontWeight="bold">
                  {appLabel.fundsGenerated}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );

  return (
<>
{isLoading ? (
        handleSignInSkeletonFac()
      ) : dashboardData.length === 0 ? (
        <Box>
          <Alert severity="info" variant="outlined" sx={{ fontSize: "20px" }}>
            {appLabel.noDataAvailableMsg}
          </Alert>
        </Box>
      ) : (
    <Grid container spacing={1} p={3} >
      <Grid
        item
        m={1}
        xs={12}
        md={4}
        sx={{
          backgroundColor: "#fff",
          borderRadius: "15px",
          height: "300px",
          overflow: "auto",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderBottom={`4px solid #4cceac`}
          color={"#e0e0e0"}
          p={"15px"}
        >
          <Typography color={"#333333"} variant="h5" fontWeight={"600"}>
            {`Recent ${isPo ? appLabel.poShort : appLabel.invoice}`}
          </Typography>
        </Box>
        {dashboardData.map((item, i) => (
          <Box
            key={`${i}`}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            borderBottom={`4px solid #4cceac`}
            p={"15px"}
          >
            <Box>
              <Typography>{item.icon}</Typography>
              <Typography color={"gray"} variant="h5" fontWeight={"600"}>
                {`${item.itemType} ${item.name}`}
              </Typography>
            </Box>
            <Box color={"141414"}>
              <ProgressCircle size="30" progress={parseInt(item.value) / 100} />
            </Box>
            <Box
              backgroundColor={"#333333"}
              color={"#ffffff"}
              p={"5px 10px"}
              borderRadius={"4px"}
              width={"auto"}
              alignItems={"center"}
              fontWeight={"bold"}
            >
              {item.value}
            </Box>
          </Box>
        ))}
      </Grid>

      <Grid
        item
        m={1}
        xs={12}
        md={4}
        sx={{
          backgroundColor: "#fff",
          borderRadius: "15px",
          overflow: "auto",
          height: "300px",
        }}
      >
        <Box
          mt={"20px"}
          p={"0 30px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        ></Box>
        <Box height={"300px"} mt={"-20px"}>
          <PieChart isDashboard={true} dataChart={mockPieData} />
        </Box>
      </Grid>

      <Grid
        item
        m={1}
        xs={12}
        md={3}
        sx={{
          backgroundColor: "#fff",
          borderRadius: "15px",
          height: "300px",
          overflow: "auto",
          padding: "25px",
        }}
      >
        <Typography variant="h5" fontWeight={"600"} color={"#333333"}>
          {`Total ${isPo ? appLabel.poShort : appLabel.invoice} Funded Amount`}
        </Typography>
        {totalFunded.map((item, index) => (
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            mt={"25px"}
            key={index}
          >
            {/* <i className="fa fa-money fa-2x text-navy" /> */}
            <GiMoneyStack size={"3em"} color="#333333" />
            <Typography variant="h5" color={"#4cceac"} sx={{ mt: "15px" }}>
              {/* <AmntFormatFac
                    inputValue={item.TOTAL_FUNDED}
                    currency={item.CCY}
                  /> */}
              {`${item.CCY} ${BOM.formatNumber(parseFloat(item.TOTAL_FUNDED))}`}
            </Typography>
            <Typography color={"#333333"} fontWeight="bold">
              {appLabel.fundsGenerated}
            </Typography>
          </Box>
        ))}
      </Grid>
    </Grid>
    )}
    </>
  );
};

export default DashboardLayout;
