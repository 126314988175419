import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "./Dashboard.css";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import NumberFormat from "react-number-format";
import Chart from "chart.js/auto";

const MonthsArr = appStatic.monthNames;

function getAppopriateMonth() {
  const currentMonth = new Date().getMonth() + 1;
  let finalArr = [];

  //get last two months inluding the current month, in total of 3
  for (let index = currentMonth - 3; index < currentMonth; index++) {
    const element = MonthsArr[index];

    // console.log(element);
    finalArr.push(element);
  }

  return finalArr;
}

const loopNb = (startIndex = 0, length) => {
  let returnArray = [];
  for (let index = 0; index < length; index++) {
    returnArray.push(index * startIndex);
  }

  console.log(returnArray);
  return returnArray;
};
export const Analytics = (props) => {
  const { userData } = props;
  const { profileId, session, companyId } = userData;
  const globalVar = window.globalData;
  const dispatch = useDispatch();

  // LINE CHART STRUCTURE
  const labels = getAppopriateMonth();
  const labels2 = getAppopriateMonth();
  const data = {
    // labels: labels,
    datasets: [
      {
        label: "Buyer",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
        // data: loopNb(2,7),
        // data: [0, 5, 10],
        data: [
          // { x: "January", y: 20 },
          // { x: "February", y: 10 },
          // { x: "March", y: 20 },
          // { x: "April", y: 10 },
          { x: "May", y: 2 },
          { x: "June", y: 5 },
          { x: "July", y: 10 },
        ],
      },
      {
        label: "Supplier",
        backgroundColor: "#000051",
        borderColor: "#000051",
        // data: loopNb(3, 7) //[0, 10, 5, 2, 20, 35],
        // data: [14, 18, 20],
        data: [
          { x: "May", y: 4 },
          { x: "June", y: 14 },
          { x: "July", y: 20 },
        ],
      },
      {
        label: "Funder",
        backgroundColor: "rgb(204, 153, 61)",
        borderColor: "rgb(204, 153, 61)",
        // data: loopNb(1, 7) // [0, 20, 40, 60, 80, 100],
        // data: [11, 13, 16],
        data: [
          { x: "May", y: 4 },
          { x: "June", y: 20 },
          { x: "July", y: 30 },
        ],
      },
    ],
  };
  const config = {
    type: "line",
    data: data,
    options: {
      scales: {
        x: {
          display: true,
          title: {
            display: true,
            text: "MONTH",
          },
        },
        y: {
          // defining min and max so hiding the dataset does not change scale range
          min: 0,
          max: 35,
        },
      },
    },
  };

  // BAR CHART STRUCTURE
  const data2 = {
    // labels: labels2,
    datasets: [
      {
        label: "NGN",
        // data: ['0.0bn', '0.2bn', '0.4bn', '0.6bn', '0.8bn', '1.0bn', '1.2bn', '1.4bn'],
        // data: [0.0, 0.2, 0.4, 0.6, 0.8, 1.0, 1.2, 1.4],
        // data: [
        //   { x: 10, y: 20 },
        //   { x: 15, y: null },
        //   { x: 20, y: 10 },
        // ],
        // data: [
        //   { x: "May", y: 4 },
        //   { x: "June", y: 20 },
        //   { x: "July", y: 30 },
        // ],
        data:[{x: 'Jan', net: 100, cogs: 50, gm: 50}, {x: 'Feb', net: 120, cogs: 55, gm: 75}],
        parsing: {
          yAxisKey: 'net'
      },
        backgroundColor: "rgba(255, 205, 86, 0.2)",
        borderColor: "rgb(255, 205, 86)",
        borderWidth: 1,
      },
      {
        label: "USD",
        // data: [0.05, 0.07, 0.08, 0.4, 0.5, 0.02],
        data: [
          { x: "May", y: 26 },
          { x: "June", y: 14 },
          { x: "July", y: 20 },
        ],
        backgroundColor: "rgb(35, 52, 106)",
        borderColor: "rgb(35, 52, 106)",
        borderWidth: 1,
      },
    ],
  };
  const config2 = {
    type: "bar",
    data: data2,
    options: {
      plugins: {
        title: {
          display: true,
          text: "CURRENCY",
        },
      },
      scales: {
        x: {
          display: true,
          title: {
            display: true,
            text: "MONTH",
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: "BN",
          },
        },
      },
    },
  };

  useEffect(() => {
    // console.log(loopNb(0, 5));
    console.log(getAppopriateMonth());
    new Chart(document.getElementById("lineChart"), config);
    new Chart(document.getElementById("barChart"), config2);
  }, []);

  return (
    <Main {...props}>
      {/* <ContentTop pageTitle='Dashboard'/> */}
      <div className="row">
        <div className="col-md-4 col-lg-4">
          <div className="ibox">
            <div className="ibox-content dashCard">
              <h5 className="m-b-md">{"Registered"}</h5>
              <h3>
                {" "}
                <i className="fa fa-share text-navy" /> &nbsp; {"Entities"}
              </h3>
              <span>{0}</span>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-4">
          <div className="ibox">
            <div className="ibox-content dashCard">
              <h5 className="m-b-md">{"Total"}</h5>
              <h3>
                <i className="fa fa-money text-navy" /> &nbsp;{"POs Processed"}
              </h3>
              <span>{0}</span>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-4">
          <div className="ibox">
            <div className="ibox-content dashCard">
              <h5 className="m-b-md">{"Total"}</h5>
              <h3>
                <i className="fa fa-money text-navy" /> &nbsp;
                {"Funding Requests"}
              </h3>
              <span>{0}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-6">
            <div className="ibox">
              <div className="ibox-title _border-radius-top">
                <h5>
                  {" "}
                  <i className="fa fa-dot-circle-o text-primary" />
                  &nbsp; No of Entities by Category
                </h5>
              </div>
              <div className="ibox-content _border-radius-bottom panel-primary">
                <canvas id="lineChart" width="0" height="300"></canvas>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ibox">
              <div className="ibox-title _border-radius-top">
                <h5>
                  {" "}
                  <i className="fa fa-dot-circle-o text-primary" />
                  &nbsp; Total Financing
                </h5>
              </div>
              <div className="ibox-content _border-radius-bottom panel-primary">
                <canvas id="barChart" width="0" height="300"></canvas>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4">
            <div className="ibox">
              <div className="ibox-title contentCard"><h5># of Entities by Category</h5></div>
              <div className="ibox-content contentCard panel-primary">
                <canvas id="myChart3" width="50" height="50"></canvas>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
