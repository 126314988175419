import React, { useEffect } from "react";
import appLabel from "../../config/appLabel";
const RecordStatus = (props) => {

  const {FLG_STATUS, MAKER_ID, MAKER_DATE, CHECKER_ID, CHECKER_DATE} = props

  useEffect(()=>{
    // console.log(props);
  },[])

  return (
    <React.Fragment>
      <div className="ibox">
        <div className="ibox-title">
          {/* <h5>New Service</h5> */}
          <div className="ibox-tools">
            <span className="badge badge-primary">
                {appLabel.recordStatus}
            </span>
          </div>
        </div>
        <div className="ibox-content panel-primary contentCard">
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label className="al-subtitle">
                {appLabel.authStatus}
                </label>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control pull-left input-sm"
                  value={FLG_STATUS.trim() === 'A' ? appLabel.authorized: appLabel.unauthorized}
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="al-subtitle">
                  {appLabel.makerId}
                </label>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control pull-left input-sm"
                  placeholder={MAKER_ID}
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label className="al-subtitle">
                  {appLabel.makerDate}
                </label>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control pull-left input-sm"
                  placeholder={MAKER_DATE}
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="al-subtitle">
                  {appLabel.checkerId}
                </label>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control pull-left input-sm"
                  placeholder={CHECKER_ID}
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label className="al-subtitle">
                  {appLabel.checkerDate}
                </label>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control pull-left input-sm"
                  placeholder={CHECKER_DATE}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RecordStatus;
