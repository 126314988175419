import React, { useEffect, useState } from "react";
import { apis as api } from "../../services/api.action";
import { useForm } from "react-hook-form";
import { connect, useDispatch } from "react-redux";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM, RAL } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import { Link } from "react-router-dom";
import RecordStatus from "../ui-setup/RecordStatus";
import NumberFormat from "react-number-format";
import {
  TooltipsFAke,
  Col12BlockFacility,
  InputTextFacility,
  TableFacility,
  ColBlockFac,
} from "../layouts/Facility";
import RightSide from "../ui-setup/RightSide";
import Notiflix from "notiflix";
import GetFunderBids from "./bid/GetFunderBids";

export const SupplierIntentPoDetail = (props) => {
  const { location, userData, configData, recordData, menuData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId, companyTypeId } = userData;
  const { isCsd } = configData;
  const { toolTip, tableData } = appStatic;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const [isFullPayment, setIsFullPayment] = useState(false);
  const [isCsdCheck, setIsCsdCheck] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [docsForView, setDocsForView] = useState([]);
  const [orderDetail, setOrderDetail] = useState({});
  const [materials, setMaterialData] = useState([]);
  const [shippingData, setShippingData] = useState({});
  const [billingData, setBillingData] = useState({});
  const [isSupplier, setIsSupplier] = useState(false);
  const [isCustodian, setIsCustodian] = useState(false);
  const [noteInfoData, setNoteInfoData] = useState([]);
  const [supplierSharedData, setSupplierSharedData] = useState({});
  const [docCategory, setDocCategory] = useState("");
  const [tempFile, setTempFile] = useState(false);
  const [bidData, setBidData] = useState([]);

  let tempDocument = [];

  useEffect(() => {
    handleSwitchCondition();
    getDetailById(ggParam);

    // getSpecDoc("po_invoice");
    BOM.getSpecDoc(ggParam?.ITEM_ID, setDocsForView, userData);

    // //GET 2nd DOCUMENT
    // setTimeout(() => {
    //   getSpecDoc("creditscore");
    // }, 1500);
  }, []);

  const handleSwitchCondition = () => {
    if (isCsd.toLowerCase() === "y") {
      setIsCsdCheck(true);
    }
    switch (companyTypeId) {
      case "CT2022012400002": // supplier
        setIsSupplier(true);
        break;
      case "CT2022012400000": // depository/custodian
        setIsCustodian(true);
        break;
    }
  };

  const onChoosePaymentOption = (type) => {
    switch (type.toLowerCase()) {
      case "instalment":
        setIsFullPayment(true);
        break;
      default:
        setIsFullPayment(false);
        break;
    }
  };
  const getDetailById = (params) => {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      itemId: params.ITEM_ID,
      itemType: params.ITEM_TYPE,
      companyId,
      session,
    };

    BOM.FetchReqAction(body, api.GetIntent, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let orderDetail = res.purchaseOrderDetail;
        setValue("orderId", orderDetail.ORDERID);
        setValue("intentId", params.INTENT_ID);
        setValue("csdName", orderDetail.NOTE_NAME);
        setValue("csdNumber", orderDetail.CSD_NUMBER);
        setValue("csdCode", orderDetail.NOTE_CODE);
        setOrderDetail(orderDetail);

        onChoosePaymentOption(orderDetail.PO_PAYMENT_OPTION);
        setMaterialData(orderDetail.MATERIAL_DATA);
        setShippingData(orderDetail.SHIP_DATA);
        setBillingData(orderDetail.BILL_DATA);

        setSupplierSharedData(orderDetail.SUPPLIER_SHARE_REQ_DATA);

        //get document
        // getSpecDoc(state.ggParam);
        switch (orderDetail.FLG_DEPOSITORY_NOTE.toLowerCase()) {
          case "n":
            BOM.IsApproveOrReject("U", menuData, "Supplier Request");
            setNoteInfoData([
              { name: appLabel.noteName, value: orderDetail.NOTE_NAME },
              { name: appLabel.csdNumber, value: orderDetail.CSD_NUMBER },
              { name: appLabel.noteCode, value: orderDetail.NOTE_CODE },
            ]);
            break;
          case "y":
            orderDetail.FLG_SUPPLIER_APPROVAL.toLowerCase() === "n" &&
              RAL.disableEnableRightAction(["CLEAR_RIGHT_BTN"]);
            setNoteInfoData([
              { name: appLabel.noteName, value: orderDetail.NOTE_NAME },
              { name: appLabel.csdNumber, value: orderDetail.CSD_NUMBER },
              { name: appLabel.noteCode, value: orderDetail.NOTE_CODE },
            ]);
            break;
          default:
            break;
        }
      }
    });
  };
  const handleFileRead = async (event, setState_param) => {
    const file = event.target.files[0];
    if (!file) {
      // if file is empty
      setState_param("");
      return;
    }

    if (file.size > 5242880) {
      BOM.AlertMsg(cnt.WARNING, "File size cannot exceed 5 MB");
      $(".documentInput").val("");
      return;
    }

    switch (file.type.toLowerCase()) {
      case "application/pdf":
        const base64 = await BOM.convertBase64(file);
        let strB64 = base64.split("base64,")[1]; //.replace(/^data:image\/[a-z]+;base64,/, "");
        setState_param(strB64);
        break;

      default:
        BOM.AlertMsg(cnt.WARNING, "Document must be a PDF.");
        $(".documentInput").val("");
        break;
    }

    // console.log(file.type);
  };

  const uploadFile = () => {
    // console.log(tempFile);

    const itemId = documents.find(
      (item, index) => item.docCategory === docCategory
    );

    if (itemId) {
      BOM.AlertMsg(cnt.WARNING, "No duplicate file allowed!");
      return;
    }

    if (!tempFile || !docCategory) {
      BOM.AlertMsg(cnt.WARNING, "Fields with * are required");
      return;
    }

    setDocuments([
      ...documents,
      {
        base64: tempFile,
        docType: "creditscore",
        docCategory,
      },
    ]);
    $(".documentInput").val("");
  };
  const removeItem = (index, type) => {
    switch (type.toLowerCase()) {
      case "document":
        setDocuments([
          ...documents.slice(0, index),
          ...documents.slice(index + 1),
        ]);
        break;
      case "material":
        setMaterialData([
          ...materials.slice(0, index),
          ...materials.slice(index + 1),
        ]);
        break;
    }
  };
  const getSpecDoc = () => {
    // const type =
    //   params.ITEM_TYPE.toLowerCase() === "po" ? "purchaseorder" : "invoice";
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      id: ggParam?.ITEM_ID,
      // type,//no need to send type anymore
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setDocsForView(res.docListing);
      }
    });
  };

  const getSpecDocOLD = async (typeParam) => {
    // console.log(ggParam?.ITEM_TYPE);
    let type = "";
    switch (typeParam?.toLowerCase()) {
      case "po_invoice":
        if (ggParam?.ITEM_TYPE?.toLowerCase() === "po") {
          type = "purchaseorder";
        } else {
          type = "invoice";
        }
        break;
      case "creditscore":
        type = typeParam;
        break;
      default:
        type = "na";
        break;
    }

    let body = {
      profileId,
      id: ggParam?.ITEM_ID,
      type,
      session,
    };

    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        // switch (type?.toLowerCase()) {
        //   case "purchaseorder":
        //     res.docListing.forEach((element) => {
        //       tempDocument.push(element);
        //     });
        //     break;

        //   default:
        //     res.docListing.forEach((element) => {
        //       tempDocument.push(element);
        //     });
        //     break;
        // }
        res.docListing.forEach((element) => {
          tempDocument.push(element);
        });
        setDocsForView([...tempDocument]);
      }
    });
  };
  const onApprove = () => {
    let body = {
      profileId,
      itemId: state.ggParam.ITEM_ID,
      itemType: state.ggParam.ITEM_TYPE,
      intentId: state.ggParam.INTENT_ID,
      criteria: "A",
      session,
    };

    // console.log('approve: ', body);
    BOM.GlobalApproveRejectAction(
      body,
      api.ApproveRejectPoIntent,
      "manage-supplier-intent",
      state.ggParam.SUPPLIER,
      "Approve "
    );
  };

  const onRejectOLD = () => {
    let body = {
      profileId,
      itemId: state.ggParam.ITEM_ID,
      itemType: state.ggParam.ITEM_TYPE,
      intentId: state.ggParam.INTENT_ID,
      criteria: "R",
      session,
    };

    // console.log('reject: ', body);
    BOM.GlobalApproveRejectAction(
      body,
      api.ApproveRejectPoIntent,
      "manage-supplier-intent",
      state.ggParam.SUPPLIER,
      "Reject "
    );
  };

  const approveReject = (key) => {
    console.log("key: ", key);

    //validation
    if (documents.length === 0) {
      BOM.AlertMsg(cnt.WARNING, "Document upload is required.");
      return;
    }

    let action = "";
    let label = state.ggParam.SUPPLIER;
    let criteria = "";

    switch (key?.toLowerCase()) {
      case "approve":
        action = "Approve ";
        criteria = "A";
        break;
      default:
        action = "Reject ";
        criteria = "R";
        break;
    }

    const body = {
      // profileId,
      docTypeId: state?.ggParam?.ITEM_ID,
      documents,
      session,
    };
    const body2 = {
      profileId,
      itemId: state.ggParam.ITEM_ID,
      itemType: state.ggParam.ITEM_TYPE,
      intentId: state.ggParam.INTENT_ID,
      criteria,
      session,
    };

    Notiflix.Confirm.show(
      "Are you sure?",
      action + label,
      "Yes",
      "No",
      function () {
        //activate loader
        BOM.LoadAlert(cnt.LOAD, "Processing");

        // Yes: send document to Item (PO,INV)
        BOM.FetchReqAction(body, api.CreateGenericDocument, (err, res) => {
          if (err) {
            BOM.AlertMsg(cnt.DANGER, err);
          } else {
            //if: success approve or reject Item (PO,INV)
            BOM.SendReqAction(
              body2,
              api.ApproveRejectPoIntent,
              "/manage-supplier-intent"
            );
          }
        });
      },
      function () {
        // No button
      }
    );

    /** FETCH ROLE */
  };

  const formSubmit = (formValues) => {
    let body = {
      profileId,
      companyId,
      session,
      ...formValues,
    };

    /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(body, api.UpdatePoNoteInfo, "/manage-supplier-intent");
  };

  return (
    <Main
      {...props}
      onApprove={() => approveReject("approve")}
      onReject={() => approveReject("reject")}
      onSaveChange={handleSubmit(formSubmit)}
    >
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <ContentTop pageTitle={appLabel.intentPoDetail} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{appLabel.orderInfo}</h5>
                  <div className="ibox-tools">
                    <Link
                      to="/manage-supplier-intent"
                      className="btn btn-primary btn-xs"
                    >
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.generalInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.poNo}&nbsp;
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={orderDetail.ORDERID}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.from}&nbsp;
                            <TooltipsFAke title={toolTip.from} />
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={orderDetail.FROM_COMPANY_NAME}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.poAmount}&nbsp;
                            <TooltipsFAke title={toolTip.poAmount} />
                          </label>
                        </div>
                        <div className="col-md-7">
                          <NumberFormat
                            className="form-control input-sm"
                            value={orderDetail.PO_AMOUNT}
                            displayType="text"
                            disabled
                            thousandSeparator={true}
                            prefix={""}
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.to}&nbsp;
                            <TooltipsFAke title={toolTip.to} />
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={orderDetail.TO_COMPANY_NAME}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.currency}&nbsp;
                            <TooltipsFAke title={toolTip.currency} />
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={orderDetail.PO_CCY}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.pymtOption}&nbsp;
                            <TooltipsFAke title={toolTip.pymtOption} />
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={orderDetail.PO_PAYMENT_OPTION}
                            disabled
                          />
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6 ${
                          isFullPayment ? "" : "hide"
                        }`}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.frequency}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={orderDetail.PO_INSTALLMENT_FREQ}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.firstPayDue}{" "}
                            <TooltipsFAke title={toolTip.firstPayDue} />
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={orderDetail.PO_FIRSTPAY_DUE}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.lastPayDue}{" "}
                            <TooltipsFAke title={toolTip.lastPayDue} />
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={orderDetail.PO_LASTPAY_DUE}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.lpoRefId}{" "}
                            <TooltipsFAke title={toolTip.lpoRefId} />
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={orderDetail.LPO_REFERENCE_ID}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <ColBlockFac topTitle={appLabel.orderFinancingInfo}>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.AmountToFinance}&nbsp;
                          </label>
                        </div>
                        <div className="col-md-7">
                          <NumberFormat
                            className="form-control input-sm"
                            value={supplierSharedData.REQUESTED_AMOUNT}
                            displayType="text"
                            disabled
                            thousandSeparator={true}
                            prefix={" "}
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.interestRate}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            placeholder={appLabel.interestRate}
                            className="form-control input-sm clearPerceInput"
                            disabled
                            defaultValue={supplierSharedData.INTEREST_RATE}
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.duration}&nbsp;
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.duration}
                            disabled
                            defaultValue={supplierSharedData.TENOR}
                          />
                        </div>
                      </div>
                    </ColBlockFac>

                    <Col12BlockFacility topTitle={appLabel.noteInformation}>
                      {orderDetail.FLG_DEPOSITORY_NOTE === "Y" &&
                      orderDetail.FLG_SUPPLIER_APPROVAL === "Y" ? (
                        <>
                          <div className="row form-group col-md-6">
                            <div className="col-md-4 text-left">
                              <label className="al-subtitle">
                                {appLabel.noteName}&nbsp;
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                type="text"
                                className="form-control input-sm"
                                placeholder={appLabel.noteName}
                                {...register("csdName", {
                                  required: "Field is required.",
                                  maxLength: {
                                    value: 60,
                                    message: "Maximum length is 60 characters",
                                  },
                                })}
                              />
                              <span className="asterisks">
                                {errors?.csdName?.message}
                              </span>
                            </div>
                          </div>
                          <div className={`row form-group col-md-6`}>
                            <div className="col-md-4 text-left">
                              <label className="al-subtitle">
                                {appLabel.csdNumber}&nbsp;
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                type="text"
                                className="form-control input-sm"
                                placeholder={appLabel.csdNumber}
                                {...register("csdNumber", {
                                  required: "Field is required.",
                                  // minLength: {
                                  //   value: 12,
                                  //   message: "Not less than 12 digits",
                                  // },
                                  maxLength: {
                                    value: 12,
                                    message: "Maximum length is 12 characters",
                                  },
                                })}
                              />
                              <span className="asterisks">
                                {errors?.csdNumber?.message}
                              </span>
                            </div>
                          </div>
                          <div className="row form-group col-md-6">
                            <div className="col-md-4 text-left">
                              <label className="al-subtitle">
                                {appLabel.noteCode}&nbsp;
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                type="text"
                                className="form-control input-sm"
                                placeholder={appLabel.noteCode}
                                {...register("csdCode", {
                                  required: "Field is required.",
                                  maxLength: {
                                    value: 16,
                                    message: "Maximum length is 16 characters",
                                  },
                                })}
                              />
                              <span className="asterisks">
                                {errors?.csdCode?.message}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        noteInfoData.map((item, index) => (
                          <InputTextFacility
                            key={index}
                            inputLabel={item.name}
                            inputValue={item.value}
                          />
                        ))
                      )}
                    </Col12BlockFacility>
                  </div>
                  <div className="row" style={{ marginTop: "15px" }}>
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.addressInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="col-md-6">
                        <div className="well">
                          <address>
                            <h4>
                              {" "}
                              <i className="fa fa-building text-navy"></i>
                              &nbsp; {appLabel.shippingAddress}
                            </h4>{" "}
                            <br />
                            <i className="fa fa-circle text-navy"></i> &nbsp;{" "}
                            {shippingData.SHIPPING_ADDRESS_1},{" "}
                            {shippingData.SHIPPING_CITY_NAME}
                            <br />
                            {shippingData.SHIPPING_PROVINCE_NAME},{" "}
                            {shippingData.SHIPPING_COUNTRY_NAME}{" "}
                            {shippingData.SHIPPING_POST_CODE}
                          </address>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="well">
                          <address>
                            <h4>
                              {" "}
                              <i className="fa fa-building text-navy"></i>
                              &nbsp;{appLabel.billingAddress}
                            </h4>{" "}
                            <i className="fa fa-circle text-navy"></i>
                            &nbsp;{billingData.BILLING_ADDRESS_1},{" "}
                            {billingData.BILLING_CITY_NAME}
                            <br />
                            {billingData.BILLING_PROVINCE_NAME},{" "}
                            {billingData.BILLING_COUNTRY_NAME}{" "}
                            {billingData.BILLING_POST_CODE}
                          </address>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.otherInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <ul className="nav nav-tabs">
                        <li className="active">
                          <a data-toggle="tab" href="#materialInfo">
                            <i className="fa fa-tag text-navy" />
                            &nbsp;{appLabel.materialInfoShort}&nbsp;
                            <TooltipsFAke title={toolTip.materialInfo} />
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#documentInfo">
                            <i className="fa fa-file-pdf-o text-navy" />
                            &nbsp;{appLabel.documentInfoShort}&nbsp;
                            <TooltipsFAke title={toolTip.documentInfo} />
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#funderBid">
                            <i className="fa fa-gavel text-navy" />
                            &nbsp;{appLabel.funderBid}&nbsp;
                            {/* <TooltipsFAke title={toolTip.funderBid} /> */}
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="materialInfo">
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-12">
                              <div className="table-responsive">
                                {materials.length === 0 ? (
                                  <div className="alert alert-warning text-center">
                                    <a className="alert-link">
                                      {appLabel.noDataInTableMsg}
                                    </a>
                                  </div>
                                ) : (
                                  <table
                                    className="footable table table-stripped toggle-arrow-tiny"
                                    data-page-size="10"
                                    data-filter="#filter"
                                  >
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        {/* <th>{appLabel.manufacturer}</th> */}
                                        <th>{appLabel.description}</th>
                                        {/* <th>{appLabel.type}</th> */}
                                        <th>{appLabel.unitPrice}</th>
                                        <th>{appLabel.quantity}</th>
                                        {/* <th>{appLabel.currency}</th> */}
                                        {/* <th>{appLabel.category}</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {materials.map((item, index) => (
                                        <tr className="gradeX" key={index}>
                                          <td>{index + 1}</td>
                                          {/* <td>{item.MANUFACTURER}</td> */}
                                          <td>{item.MAT_DESC}</td>
                                          {/* <td>{item.MAT_TYPE}</td> */}
                                          <td>
                                            <NumberFormat
                                              value={item.UNIT_PRICE}
                                              displayType="text"
                                              thousandSeparator={true}
                                              prefix={item.PO_CCY + " "}
                                            />
                                          </td>
                                          <td>{item.QUANTITY}</td>
                                          {/* <td>{item.PO_CCY}</td> */}
                                          {/* <td>{item.MAT_CATEGORY}</td> */}
                                        </tr>
                                      ))}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td colSpan="8">
                                          <ul className="pagination pull-right"></ul>
                                        </td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="documentInfo">
                          <div className="row" style={{ marginTop: "30px" }}>
                            <div className="col-sm-12">
                              {docsForView.length === 0 ? (
                                <div className="alert alert-warning text-center">
                                  <a className="alert-link">
                                    {appLabel.noDocumentMsg}
                                  </a>
                                </div>
                              ) : (
                                <div>
                                  {docsForView.map((item, index) => (
                                    <div className="col-md-4" key={index}>
                                      <div className="file-box">
                                        <div className="file">
                                          <a
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              BOM.DownloadAction(
                                                item.DOC_OBJECT,
                                                item.DOC_ID +
                                                  " " +
                                                  item.DOC_CATEGORY.toLowerCase()
                                              )
                                            }
                                          >
                                            <span className="corner" />
                                            <div className="icon">
                                              <i
                                                className="fa fa-file-pdf-o text-navy"
                                                style={{ color: "#d50000" }}
                                              />
                                            </div>
                                            <div className="file-name">
                                              {item.DOC_CATEGORY}
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                            {ggParam?.INTENT_STATUS?.toLowerCase() ===
                              "new" && (
                              <div className="col-sm-12">
                                New Document Upload
                                <div
                                  className="hr-line-dashed"
                                  style={{ marginTop: "0" }}
                                />
                                {documents.length == 0 ? (
                                  <div className="col-md-6">
                                    <div className="row form-group has-errorr">
                                      <div className="col-md-4 text-left">
                                        <label className="al-subtitle control-label">
                                          {appLabel.documentType}{" "}
                                        </label>
                                      </div>
                                      <div className="col-md-6">
                                        <select
                                          className="form-control input-sm documentInput"
                                          onChange={(e) =>
                                            setDocCategory(e.target.value)
                                          }
                                        >
                                          <option value="">
                                            {appLabel.choose}
                                          </option>
                                          {appStatic.docTypesCreditSharePo.map(
                                            (item, index) => (
                                              <option
                                                value={item.id}
                                                key={index}
                                              >
                                                {item.name}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="row form-group">
                                      <div className="col-md-4 text-left">
                                        <label className="al-subtitle ">
                                          {appLabel.documentUpload}{" "}
                                        </label>
                                      </div>
                                      <div className="col-md-6">
                                        <input
                                          type="file"
                                          className="form-control input-sm documentInput"
                                          accept=".pdf"
                                          onChange={(e) =>
                                            handleFileRead(e, setTempFile)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="row form-group">
                                      <div className="col-md-4 text-left">
                                        <label className="al-subtitle ">
                                          &nbsp;
                                        </label>
                                      </div>
                                      <div className="col-md-6">
                                        <button
                                          type="button"
                                          onClick={() => uploadFile()}
                                          className="btn btn-primary btn-xs"
                                        >
                                          <i className="fa fa-upload" />
                                          &nbsp; Upload
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label className="col-sm-4 al-subtitle"></label>
                                        <div className="col-sm-8">
                                          {documents.map((item, index) => (
                                            <div
                                              className="i-checks"
                                              key={index}
                                            >
                                              <label className="control-label">
                                                <i
                                                  style={{ cursor: "pointer" }}
                                                  className="fa fa-times-circle text-danger fa-2x"
                                                  onClick={() =>
                                                    removeItem(
                                                      index,
                                                      "document"
                                                    )
                                                  }
                                                />
                                                &nbsp; &nbsp;
                                                <i
                                                  className="fa fa-file-pdf-o fa-2x"
                                                  style={{ color: "#d50000" }}
                                                />
                                                &nbsp; {item.docCategory}
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="tab-pane" id="funderBid">
                          <div className="row" style={{ marginTop: "20px" }}>
                             <GetFunderBids itemId={ggParam?.ITEM_ID} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <RecordStatus {...recordData} />
        </div>
      </form>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplierIntentPoDetail);
