import React from 'react'
import { Box, IconButton, Typography, useTheme } from "@mui/material";

const Header = ({title, subtitle}) => {
    
  return (
    <Box mb={"30px"}>
        <Typography variant="h2" color={"#979797"} fontWeight="bold" sx={{mb:"5px"}}>
            {title}
        </Typography>
        <Typography variant="h5" color={"#70d8bd"}>
            {subtitle}
        </Typography>
    </Box>
  )
}

export default Header