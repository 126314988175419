import React, { useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../../services/api.action";
import { connect, useDispatch } from "react-redux";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../../ui-setup/RecordStatus";
import appStatic from "../../../config/appStaticData";
import RightSide from "../../ui-setup/RightSide";
import {
  InputTextFacility,
  Col12BlockFacility,
  AmountFacility,
} from "../../layouts/Facility";

export const AdHocDetail = (props) => {
  const { location, userData, recordData, configData } = props;
  const { state } = location;
  const { profileId, session, companyId } = userData;
  const { isCsd } = configData;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const dispatch = useDispatch();
  const [currencyData, setCurrencyData] = useState([]);
  const [configDetail, setConfigDetail] = useState({});
  const [taxData, setTaxData] = useState([]);
  const [taxDetail, setTaxDetail] = useState([]);
  const [isRequiredField, setIsRequiredField] = useState(true);
  const [amount, setAmount] = useState(undefined);
  const [inputDetailData, setInputDetailData] = useState([]);

  useEffect(() => {
    getDetailById(state.ggParam);
    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);
  }, []);

  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      configId: params.CONFIG_ID,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetConfig, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.configDetail;
        setConfigDetail(objDetail);
        setInputDetailData([
          { name: appLabel.configId, value: objDetail.CONFIG_ID },
          { name: appLabel.configName, value: objDetail.CONFIG_NAME },
          { name: appLabel.configValue, value: objDetail.CONFIG_VALUE },
        ]);

        dispatch({
          type: cnt.RECORD_STATUS,
          payload: objDetail,
        });
      }
    });
  };

  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.adHocDetail} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{appLabel.adHoc}</h5>
                <div className="ibox-tools">
                  <Link to="/manage-ad-hoc" className="btn btn-primary btn-xs">
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;
                    {appLabel.backBtn}
                  </Link>
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                  <Col12BlockFacility topTitle={appLabel.generalInfo}>
                    {inputDetailData.map((item, index) => (
                      <InputTextFacility
                        key={index}
                        inputLabel={item.name}
                        inputValue={item.value}
                      />
                    ))}
                  </Col12BlockFacility>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RecordStatus {...recordData} />
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdHocDetail);
