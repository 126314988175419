import React, { useEffect, useState } from "react";
import Main from "../../layouts/Main";
import { connect } from "react-redux";
import ContentTop from "../../ui-setup/ContentTop";
import appLabel from "../../../config/appLabel";
import PublicBidList from "./PublicBidList";
import PrivateBidList from "./PrivateBidList";
import RightSide from "../../ui-setup/RightSide";

const ManageFunderBid = (props) => {
  const { userData} = props;
  const {companyTypeId } = userData;
  const [isSupplier, setIsSupplier] = useState(false);
  const [isCustodian, setIsCustodian] = useState(false);

  useEffect(() => {
    handleSwitchCondition();
  }, []);

  const handleSwitchCondition = () => {
    switch (companyTypeId) {
      case "CT2022012400002": // supplier
        setIsSupplier(true);
        break;
      case "CT2022012400000": // depository/custodian
        setIsCustodian(true);
        break;
    }
  };

  /** RENDER JXS DATA */
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.manageBid} />
      {isCustodian && <PublicBidList {...props} />}
      {isSupplier && <PrivateBidList {...props} />}
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ManageFunderBid);
