import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Main from "../../components/layouts/Main";

//dash v2
import { Box, Alert, AlertTitle } from "@mui/material";
import Header from "./dashboard-component/Header";
import SwitchModule from "./dashboard-component/SwitchModule";
import PODashboard from "./dashboard-component/PODashboard";
import InvoiceDashboard from "./dashboard-component/InvoiceDashboard";
import DashboardLayout from "./dashboard-component/DashboardLayout";

export const Dashboard = (props) => {
  const [switchValue, setSwitchValue] = useState(false);

  const onSwitchModule = () => {
    setSwitchValue(!switchValue);
  };

  return (
    <Main {...props}>
      <Box m={"20px"}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Header title={"DASHBOARD"} subtitle={"Welcome to your dashboard"} />

          <SwitchModule onAction={onSwitchModule} actionValue={switchValue} />
        </Box>
      </Box>
      {/* {switchValue ? <InvoiceDashboard {...props} /> : <PODashboard {...props} />} */}
      {switchValue ? <InvoiceDashboard {...props} /> : <PODashboard isPo={true} {...props} />}
      {/* {switchValue ? <DashboardLayout isPo={false} {...props} /> : <PODashboard isPo={true} {...props} />} */}
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
