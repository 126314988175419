import React, { useState, useEffect } from "react";
import { apis as api } from "../../services/api.action";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import appStatic from "../../config/appStaticData";
import RightSide from "../ui-setup/RightSide";
import appLabel from "../../config/appLabel";

const ChangePassword = (props) => {
  /** INITIALIZE VARIABLES */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { auth, userData, history } = props;
  const { profileId, session, companyId } = userData;
  const [currentPwd, setCurrentPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [currentPwdShown, setCurrentPwdShown] = useState(false);
  const [newPwdShown, setNewPwdShown] = useState(false);
  const [confirmPwdShown, setConfirmPwdShown] = useState(false);

  useEffect(() => {
    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);
  }, []);


  // Password toggle handler
  const togglePassword = (key) => {
    switch (key.toLowerCase()) {
      case "current":
        return setCurrentPwdShown(!currentPwdShown);
      case "new":
        return setNewPwdShown(!newPwdShown);
      case "confirm":
        return setConfirmPwdShown(!confirmPwdShown);
    }
  };

  const formSubmit = (formValues) => {

    if (formValues.newPassword !== formValues.confirm) {
      BOM.AlertMsg(cnt.WARNING, "New and Confirm Password do not match");
      return;
    }

    let body = {
      profileId,
      userId:profileId,
      session,
      ...formValues,
    };
    // console.log(body);
    // return;
    /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(body, api.ChangePassword, "/dashboard");
  };

  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <RightSide />
        <ContentTop pageTitle={appLabel.changePwd} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{appLabel.changePwdInfo}</h5>
                  <div className="ibox-tools">
                  <Link
                    to="/dashboard"
                    className="btn btn-danger  btn-xs"
                    style={{color:'#fff'}}
                  >
                    <i className="fa fa-times" />
                    &nbsp;
                    {appLabel.cancelBtn}
                  </Link>
                </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="al-subtitle">
                          {appLabel.currentPwd}
                        </label>
                        <input
                          type={currentPwdShown ? "text" : "password"}
                          className="form-control m-b input-sm"
                          {...register("oldPassword", {
                            required: "Field is required.",
                            minLength: {
                              value: 8,
                              message: "Minimum password length is 8 characters",
                            },
                          })}
                          placeholder="************"
                        />
                        <span
                          className="p-change-viewer"
                          onClick={() => togglePassword("current")}
                        >
                          <i
                            className={
                              currentPwdShown ? "fa fa-eye-slash" : "fa fa-eye"
                            }
                          />
                        </span>
                        <span className="asterisks">
                          {errors?.oldPassword?.message}
                        </span>
                      </div>
                      <div className="form-group">
                        <label className="al-subtitle">{appLabel.newPwd}</label>
                        <input
                          type={newPwdShown ? "text" : "password"}
                          className="form-control m-b input-sm"
                          {...register("newPassword", {
                            required: "Field is required.",
                            minLength: {
                              value: 8,
                              message: "At least minumum 8 characters",
                            },
                            pattern: {
                              value: /(?=.*?[A-Z])/ && /(?=.*?[0-9])/ && /(?=.*?[a-z])/ && /(?=.*?[#?!@$%^&*-])/,
                              message: "At least one Uppercase, Lowercase, Digit, Special Character"
                            },
                          })}
                          placeholder="************"
                        />
                        <span
                          className="p-change-viewer"
                          onClick={() => togglePassword("new")}
                        >
                          <i
                            className={
                              newPwdShown ? "fa fa-eye-slash" : "fa fa-eye"
                            }
                          />
                        </span>
                        <span className="asterisks">
                          {errors?.newPassword?.message}
                        </span>
                      </div>
                      <div className="form-group">
                        <label className="al-subtitle">
                          {appLabel.confirmPwd}
                        </label>
                        <input
                          type={confirmPwdShown ? "text" : "password"}
                          className="form-control m-b input-sm"
                          placeholder="************"
                          {...register("confirm", {
                            required: "Field is required.",
                            minLength: {
                              value: 8,
                              message: "Minimum password length is 8 characters",
                            },
                          })}
                        />
                        <span
                          className="p-change-viewer"
                          onClick={() => togglePassword("confirm")}
                        >
                          <i
                            className={
                              confirmPwdShown ? "fa fa-eye-slash" : "fa fa-eye"
                            }
                          />
                        </span>
                        <span className="asterisks">
                          {errors?.confirm?.message}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="col-md-6 col-md-offset-4"
                        style={{ marginTop: "20%" }}
                      >
                        <i className="fa fa-lock text-navy fa-5x" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  configData: state.auth.configData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
