import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "./Report.css";
import Main from "../layouts/Main";
import { useForm } from "react-hook-form";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import NumberFormat from "react-number-format";
import { apis as api } from "../../services/api.action";
import appStatic from "../../config/appStaticData";
import appLabel from "../../config/appLabel";
import { TableFacility, Tooltips } from "../../components/layouts/Facility";

export const DownloadEntityTypeReport = (props) => {
  const { tableData, toolTip } = appStatic;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const { userData } = props;
  const { profileId, session, companyId } = userData;

  const [isAuth, setIsAuth] = useState(false);
  const [isContractId, setIsContractId] = useState(false);
  const [isDateRange, setIsDateRange] = useState(false);
  const [contractData, setContractData] = useState([]);
  const [contractLabelData, setContractLabelData] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState({});

  const [companyData, setCompanyData] = useState([]);
  const [entityToDownload, setEntityToDownload] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setContractLabelData(appStatic.reporting.contractLabelData);
    getCompany();
  }, []);

  const getCompany = () => {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      companyId: "",
      criteria: "A",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.companyListing;
        let tempArr = [];

        if (objList.length !== 0) {
          // EXTRACT ONLY FUNDERS AND SUPPLIERS
          const filterList = objList.filter(
            (item, index) =>
              item.COMPANY_TYPE_ID === "CT2022012400003" ||
              item.COMPANY_TYPE_ID === "CT2022012400002"
          );

          filterList.forEach((element) => {
            tempArr.push({
              id: element.COMPANY_TYPE_ID,
              name: element.COMPANY_TYPE_NAME,
              icon: "fa fa-download",
              isSelected: 0,
            });
          });
        }
        setEntityToDownload(BOM.GetUnique(tempArr, "id"));
      }
    });
  };
  const onSelectReport = (idParam) => {
    // console.log(idParam);
    setIsLoading(true);
    /** CLEAR PEVIOUS DATA */
    setCompanyData([]);

    /** SOME LOGIC ON THE SELECTED CARD */
    let newArray = entityToDownload.map((item) =>
      item.id === idParam
        ? { ...item, isSelected: 1 }
        : { ...item, isSelected: 0 }
    );
    setEntityToDownload(newArray);

    let body = {
      profileId,
      companyId: "",
      criteria: "A",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.companyListing;

        if (objList.length !== 0) {
          // EXTRACT ONLY FUNDERS AND SUPPLIERS
          const filterList = objList.filter(
            (item, index) => item.COMPANY_TYPE_ID === idParam
          );

          setIsLoading(false);
          setCompanyData(BOM.GetUnique(filterList, "COMPANY_ID"));
          BOM.DatatableUsage();
        }
        // setEntityToDownload(BOM.GetUnique(tempArr, "id"));
      }
    });
  };
  return (
    <Main {...props}>
      {/* <ContentTop pageTitle='Dashboard'/> */}
      <div className="row">
        {entityToDownload.length === 0 && (
          <div className="col-md-12" style={{ marginTop: "20%" }}>
            <div className="alert alert-info text-center">
              <a className="alert-link">{appLabel.downloadEntityMsg}</a>
            </div>
          </div>
        )}
        <div className="col-md-12" style={{ marginTop: "20px" }}>
          {entityToDownload.map((item, index) => (
            <div
              className="col-md-6 col-lg-6"
              key={index}
              onClick={() => onSelectReport(item.id)}
            >
              <div className="ibox">
                <div
                  className={`ibox-content ${
                    item.isSelected ? "reportCardSelected" : "reportCard"
                  }`}
                >
                  <h5>{appLabel.entityReport}</h5>
                  <h3>
                    {" "}
                    <i className={`${item.icon} text-navy`} /> &nbsp;{" "}
                    {item.name}
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </div>
        {isLoading && (
          <div className="col-md-12" style={{ marginTop: "10%" }}>
            <div className="alert alert-defau text-center">
              <i className="fa fa-spinner fa-spin fa-5x text-success" />
            </div>
          </div>
        )}
      </div>

      <div className="animated fadeInRight" style={{ marginTop: "20px" }}>
        <div className="row">
          <form className="m-t" autoComplete="off">
            {companyData.length !== 0 && (
              <div className="col-lg-12">
                <div className="ibox float-e-margins">
                  <div className="ibox-content contentCard">
                    <TableFacility thData={tableData.downloadReport}>
                      {companyData.map((item, index) => (
                        <tr className="gradeX" key={index}>
                          <td>
                            <i className="fa fa-bank text-success" />
                          </td>
                          <td>{item.COMPANY_NAME}</td>
                          <td>{item.COMPANY_REGNO}</td>
                          <td>{item.BUSINESS_TYPE}</td>
                          <td>{item.COMPANY_TYPE_NAME}</td>
                          <td>{item.COMPANY_REG_DATE}</td>
                          <td>{item.CONTACT_PERSON}</td>
                          <td>{item.OFFICE_PHONE}</td>
                          <td>{item.CONTACT_EMAIL}</td>
                          <td>{item.ADDRESS}</td>
                          <td>{item.CITY_NAME}</td>
                          <td>{item.COUNTRY_NAME}</td>
                        </tr>
                      ))}
                    </TableFacility>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadEntityTypeReport);
