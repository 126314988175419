import React, { useEffect, useState } from "react";
import { apis as api } from "../../../services/api.action";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { cnt } from "../../../services/constant.action";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import RecordStatus from "../../ui-setup/RecordStatus";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";

export const PropectCompanyDetail = (props) => {
  const { location, userData, recordData, menuData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session } = userData;

  const dispatch = useDispatch();
  const [companyDetail, setCompanyDetail] = useState({});
  const [primaryData, setPrimaryData] = useState({});
  const [shippingData, setShippingData] = useState({});
  const [billingData, setBillingData] = useState({});
  const [isFunder, setIsFunder] = useState(false);
  const [bankData, setBankData] = useState([]);
  const [isIndividual, setIsIndividual] = useState(false);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    // console.log(props);
    console.log(ggParam);
    getDetailById(state.ggParam);
  }, []);

  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      companyName: params.COMPANY_NAME,
      session,
    };

    /** FETCH */
    BOM.FetchReqAction(body, api.GetProspectCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let companyDetail = res.companyDetail;

        setCompanyDetail(companyDetail);
        setPrimaryData(companyDetail);
        setShippingData(companyDetail);
        setBillingData(companyDetail);
        // setBankData(companyDetail);
        onChooseCompanyType(companyDetail);

        dispatch({
          type: cnt.RECORD_STATUS,
          payload: companyDetail,
        });

        //handle right side
        BOM.IsApproveOrReject(companyDetail.FLG_STATUS, menuData, "Manage Company");

        //get document
        getSpecDoc(state.ggParam);
      }
    });
  };
  const getSpecDoc = (params) => {
    console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      id: params.COMPANY_ID,
      type: "company",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setDocuments(res.docListing);
      }
    });
  };
  const onChooseCompanyType = (obj) => {
    // console.log(obj);
    switch (obj.COMPANY_TYPE_ID) {
      case "CT2022012400003":
        setIsFunder(!isFunder);
        switch (true) {
          case (obj.INVESTOR_TYPE === "individual"):
            setIsIndividual(true);
            break;
          default:
            setIsIndividual(false);
            break;
        }
        break;
      default:
        setIsFunder(false);
        break;
    }
  };

  const onApprove = () => {
    let body = {
      profileId,
      companyName: state.ggParam.COMPANY_NAME,
      criteria: "A",
      session,
    };

    // console.log(body);
    BOM.GlobalApproveRejectAction(
      body,
      api.ApproveRejectProspectCompany,
      "manage-prospect-company",
      state.ggParam.COMPANY_NAME,
      "Approve "
    );
  }
  const onReject = () => {
    let body = {
      profileId,
      companyName: state.ggParam.COMPANY_NAME,
      criteria: "R",
      session,
    };

    // console.log(body);
    BOM.GlobalApproveRejectAction(
      body,
      api.ApproveRejectProspectCompany,
      "manage-prospect-company",
      state.ggParam.COMPANY_NAME,
      "Reject "
    );
  }
  
  // return <div/>
  return (
    <Main {...props} onApprove={onApprove} onReject={onReject}>
      <ContentTop pageTitle={appLabel.prospectCompanyDetail} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{appLabel.companyInformation}</h5>
                <div className="ibox-tools">
                  <Link to="/manage-prospect-company" className="btn btn-primary btn-xs">
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;
                    {appLabel.backBtn}
                  </Link>
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.generalInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className={`row form-group col-md-6`}>
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.companyType} &nbsp;
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={companyDetail.COMPANY_TYPE_NAME}
                          disabled
                        />
                      </div>
                    </div>
                    <div
                      className={`row form-group col-md-6 ${
                        isFunder ? "hide" : ""
                      }
                      `}
                    >
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.companyName}{" "}
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={companyDetail.COMPANY_NAME}
                          disabled
                        />
                      </div>
                    </div>
                    <div
                      className={`row form-group col-md-6 ${
                        isFunder ? "" : "hide"
                      }`}
                    >
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.investorType}{" "}
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={companyDetail.INVESTOR_TYPE}
                          disabled
                        />
                      </div>
                    </div>
                    <div
                      className={`row form-group col-md-6 ${
                        isFunder ? "" : "hide"
                      }`}
                    >
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.investorName}{" "}
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={companyDetail.COMPANY_NAME}
                          disabled
                        />
                      </div>
                    </div>
                    <div
                      className={`row form-group col-md-6 ${
                        isFunder && isIndividual ? "" : "hide"
                      }`}
                    >
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.clientTitle}{" "}
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={companyDetail.CLIENT_TITLE}
                          disabled
                        />
                      </div>
                    </div>
                    <div
                      className={`row form-group col-md-6 ${
                        isFunder && isIndividual ? "" : "hide"
                      }`}
                    >
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.clientType}{" "}
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={companyDetail.CLIENT_TYPE}
                          disabled
                        />
                      </div>
                    </div>
                    <div
                      className={`row form-group col-md-6 ${
                        isFunder && isIndividual ? "" : "hide"
                      }`}
                    >
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.bvnRcNumber}{" "}
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={companyDetail.BVN}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.coTaxRegNo}{" "}
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={companyDetail.ITEM_VALUE}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.tradingName}{" "}
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={companyDetail.COMPANY_TRADING_NAME}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.website}
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={companyDetail.COMPANY_WEBSITE}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.otherInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <ul className="nav nav-tabs">
                      <li className="active">
                        <a data-toggle="tab" href="#contactInfo" target="_self">
                          <i className="fa fa-users text-navy" />
                          &nbsp;{appLabel.contactInfo}
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#addressInfo" target="_self">
                          <i className="fa fa-home text-navy" />
                          &nbsp;{appLabel.addressInfo}
                        </a>
                      </li>
                      <li>
                        <a
                          data-toggle="tab"
                          href="#statutoryInfo"
                          target="_self"
                        >
                          <i className="fa fa-tag text-navy" />
                          &nbsp;{appLabel.statutoryInfo}
                        </a>
                      </li>
                      <li>
                        <a
                          data-toggle="tab"
                          href="#documentInfo"
                          target="_self"
                        >
                         <i className="fa fa-file-pdf-o text-navy" />
                          &nbsp;{appLabel.documentInfo}
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane active" id="contactInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          <div className="col-sm-12">
                            <label className="al-heading">
                              <i className="fa fa-circle-o text-navy"></i>
                              &nbsp; {appLabel.primaryContact}
                            </label>
                            <div
                              className="hr-line-dashed"
                              style={{ marginTop: 0 }}
                            />
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.contactName}{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={primaryData.CONTACT_PERSON}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.officePhone}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={primaryData.OFFICE_PHONE}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle ">
                                  {appLabel.contactEmail}{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={primaryData.CONTACT_EMAIL}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <label className="al-heading">
                              <i className="fa fa-circle-o text-navy"></i>
                              &nbsp; {appLabel.otherContact}
                            </label>
                            <div
                              className="hr-line-dashed"
                              style={{ marginTop: 0 }}
                            />

                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.contactName}{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={
                                    primaryData.OTHER_CONTACT_PERSON
                                  }
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.officePhone}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={primaryData.OTHER_OFFICE_PHONE}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle ">
                                  {appLabel.contactEmail}{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={primaryData.OTHER_EMAIL}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="addressInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          <div className="col-sm-12">
                            <label className="al-heading">
                              <i className="fa fa-circle-o text-navy"></i>
                              &nbsp; {appLabel.primaryAddress}
                            </label>
                            <div
                              className="hr-line-dashed"
                              style={{ marginTop: 0 }}
                            />
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.country}{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={companyDetail.COUNTRY_NAME}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.province}{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={companyDetail.PROVINCE_NAME}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.city}{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={companyDetail.CITY_NAME}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.address} 1{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={companyDetail.COMPANY_ADDRESS_1}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.address} 2
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={companyDetail.COMPANY_ADDRESS_2}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.postCode}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={companyDetail.COMPANY_POSTCODE}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <label className="al-heading">
                              <i className="fa fa-circle-o text-navy"></i>
                              &nbsp; {appLabel.shippingAddress}
                            </label>
                            <div
                              className="hr-line-dashed"
                              style={{ marginTop: 0 }}
                            />
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.country}{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={
                                    shippingData.SHIPPING_COUNTRY_NAME
                                  }
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.province}{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={
                                    shippingData.SHIPPING_PROVINCE_NAME
                                  }
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.city}{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={shippingData.SHIPPING_CITY_NAME}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.address} 1{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={shippingData.SHIPPING_ADDRESS_1}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.address} 2
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={shippingData.SHIPPING_ADDRESS_2}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.postCode}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={shippingData.SHIPPING_POST_CODE}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <label className="al-heading">
                              <i className="fa fa-circle-o text-navy"></i>
                              &nbsp; {appLabel.billingAddress}
                            </label>
                            <div
                              className="hr-line-dashed"
                              style={{ marginTop: 0 }}
                            />
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.country}{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={
                                    billingData.BILLING_COUNTRY_NAME
                                  }
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.province}{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={
                                    billingData.BILLING_PROVINCE_NAME
                                  }
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.city}{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={billingData.BILLING_CITY_NAME}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.address} 1{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={billingData.BILLING_ADDRESS_1}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.address} 2
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={billingData.BILLING_ADDRESS_2}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.postCode}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={billingData.BILLING_POST_CODE}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="statutoryInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          <div className="col-sm-12">
                            <label className="al-heading">
                              <i className="fa fa-circle-o text-navy"></i>
                              &nbsp; {appLabel.generalInfo}
                            </label>
                            <div
                              className="hr-line-dashed"
                              style={{ marginTop: 0 }}
                            />
                            <div
                              className={`row form-group col-md-6 ${
                                isIndividual ? "hide" : ""
                              }`}
                            >
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.companyRegDate}{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={companyDetail.COMPANY_REG_DATE}
                                  disabled
                                />
                              </div>
                            </div>
                            <div
                              className={`row form-group col-md-6 ${
                                isIndividual ? "" : "hide"
                              }`}
                            >
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.dateOfBirth}{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={companyDetail.DOB}
                                  disabled
                                />
                              </div>
                            </div>

                            <div
                              className={`row form-group col-md-6 ${
                                isIndividual ? "" : "hide"
                              }`}
                            >
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.gender}{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={companyDetail.GENDER}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.businessStartDate}{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={
                                    companyDetail.BUSINESS_START_DATE
                                  }
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.businessType}{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={companyDetail.BUSINESS_TYPE}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row form-group col-md-6">
                              <div className="col-md-4 text-left">
                                <label className="al-subtitle">
                                  {appLabel.financeYear}{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  defaultValue={companyDetail.FINANCE_YEAR}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <label className="al-heading">
                              <i className="fa fa-circle-o text-navy"></i>
                              &nbsp; {appLabel.bankInformation}
                            </label>
                            <div
                              className="hr-line-dashed"
                              style={{ marginTop: 0 }}
                            />

                            <div className="table-responsive">
                              {bankData.length === 0 ? (
                                <div className="alert alert-warning text-center">
                                  <a className="alert-link">
                                    {appLabel.noDataInTableMsg}
                                  </a>
                                </div>
                              ) : (
                                <table
                                  className="footable table table-stripped toggle-arrow-tiny"
                                  data-page-size="10"
                                  data-filter="#filter"
                                >
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>{appLabel.bankName}</th>
                                      <th>{appLabel.accountNumber}</th>
                                      <th>{appLabel.accountName}</th>
                                      <th>{appLabel.accountType}</th>
                                      <th>{appLabel.branchCode}</th>
                                      <th>{appLabel.nuban}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {bankData.map((bank, index) => (
                                      <tr className="gradeX" key={index}>
                                        <td>{index + 1}</td>
                                        <td>{bank.BANK_NAME}</td>
                                        <td>{bank.ACC_NUM}</td>
                                        <td>{bank.ACC_NAME}</td>
                                        <td>{bank.ACC_TYPE}</td>
                                        <td>{bank.BRANCH_CODE}</td>
                                        <td> {bank.NUBAN} </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td colSpan="7">
                                        <ul className="pagination pull-right"></ul>
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="documentInfo">
                        <div className="row" style={{ marginTop: "30px" }}>
                          <div className="col-sm-12">
                            {documents.length === 0 ? (
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDocumentMsg}
                                </a>
                              </div>
                            ) : (
                              <div>
                                {documents.map((item, index) => (
                                  <div className="col-md-4" key={index}>
                                    <div className="file-box">
                                      <div className="file">
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            BOM.DownloadAction(
                                              item.DOC_OBJECT,
                                              item.DOC_ID +
                                                " " +
                                                item.DOC_CATEGORY.toLowerCase()
                                            )
                                          }
                                        >
                                          <span className="corner" />
                                          <div className="icon">
                                           <i className="fa fa-file-pdf-o text-navy"  />
                                          </div>
                                          <div className="file-name">
                                            {item.DOC_CATEGORY}
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <RecordStatus {...userDetail} /> */}
      </div>

      <RecordStatus {...recordData} />
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  // recordData: state.staticData.recordStatus
  recordData: state.recordStatus,
  menuData: state.staticData.menuData
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PropectCompanyDetail);
