import React, { useEffect, Fragment, useState } from "react";
import { Button, ButtonToolbar, Modal } from "react-bootstrap";

const AppModal = (props) => {
  const { handleSave, handleClose, show, children, title, saveTitle } = props;
  const showHideClassName = show ? "" : "hide";

  useEffect(() => {}, []);

  return (
      <div>
        <Modal.Dialog className={showHideClassName}>
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
            <div className="text-right">
            <Button className="btn btn-danger btn-xs" onClick={() => handleClose()}>Done</Button>&nbsp;
            <Button className="btn btn-primary btn-xs" onClick={() => handleSave()}> <i className="fa fa-plus-circle"></i>&nbsp; Save</Button>
            </div>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
                {children}
            </div>
            </Modal.Body>

          <Modal.Footer>
             </Modal.Footer>
        </Modal.Dialog>
      </div>
  );
};

export default AppModal;
