/**
 * THIS COMPONENT AND SCREEN IS ONLY SEEN BY A CUSTODIAN / DEPOSITORY
 */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch } from "react-redux";
import { apis as api } from "../../services/api.action";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import history from "../../history";
import { cnt } from "../../services/constant.action";
import { BOM, RAL } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import { HandleSkeletonTbleFac } from "../layouts/Facility";
import { getCompanyAction } from "../../services/redux/actions/staticData-act";


export const ManageCompany = (props) => {
  const { userData, menuData, location, getCompanyAction } = props;
  const { profileId, session, companyTypeId } = userData;
  const globalVar = window.globalData;
  const dispatch = useDispatch();
  const [companyData, setCompanyData] = useState([]);
  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [isCustodian, setIsCustodian] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount]=useState(1)

  const [tiles, setTiles] = useState([
    {
      title: appLabel.authorized,
      otherTitle: appLabel.authorizedListing,
      color: "primary",
      icon: "tag",
      criteria: "A",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.unauthorized,
      otherTitle: appLabel.unauthorizedListing,
      color: "warning",
      icon: "exclamation-triangle",
      criteria: "U",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.disabled,
      otherTitle: appLabel.disabledListing,
      color: "danger",
      icon: "close",
      criteria: "R",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
  ]);

  useEffect(() => {
    console.log(props);
    handleSwitchCondition();
    getListByCriteria(tiles[0]); // get position 0 object
    // getPagiData()
  }, []);

  const handleSwitchCondition = () => {
    switch (companyTypeId) {
      case "CT2022012400000": // depository/custodian
        setIsCustodian(true);
        // console.log(companyTypeId);
        break;
    }
  };
  function getListByCriteria(params) {
    // console.log(params);
    setCompanyData([]);
    setTitleLeft(params.otherTitle);
    setTitleRight(params.title);
    setColor(params.color);
    setIcon(params.icon);

    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true);

    let body = {
      profileId,
      companyId: "",
      criteria: params.criteria,
      session,
    };

    // getCompanyAction(body)
    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.companyListing;
        setCompanyData(objList);
        BOM.DatatableUsage();
        setIsLoading(false);
        
        // dispatch({
        //   type: cnt.COMPANY_LISTING,
        //   payload:objList
        // });
      }
    });
  }

  const onSelectCompany = (company) => {
    /** CHECK FOR RIGHT BTN PRIVILEGES */
    let path = appStatic.pathData.find(
      (item) => item.URL === location.pathname
    );
    if (path) {
      if (RAL.viewAction(menuData, path.DESCRIPTION)) {
        dispatch({
          type: cnt.RIGHT_DETAIL_BTN,
        });
      }
    }

    if (path) {
      if (RAL.editAction(menuData, path.DESCRIPTION)) {
        dispatch({
          type: cnt.RIGHT_EDIT_BTN,
        });
      }
    }

    // ["RIGHT_DETAIL_BTN", "RIGHT_EDIT_BTN_FAKE"].map((item) => {
    //   dispatch({
    //     type: item,
    //   });
    // });

    globalVar.viewLink = {
      path: "/company-detail",
      obj: company,
    };

    globalVar.editLink = {
      path: "/edit-company",
      obj: company,
    };
  };

  const getPagiData= (body)=>{
    let tempPagi = [];
    let objList = [];

    if (count < 20) {
      setInterval(() => {
        setCount(count * 2)
        console.log(count);
      }, 100);
    }

      // BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      //   if (err) {
      //     BOM.AlertMsg(cnt.DANGER, err);
      //   } else {
      //     console.log(res);
      //     objList = res.companyListing;
      //     setCompanyData(objList);
      //   }
      // });
  }

  /** RENDER JXS DATA */
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.manageCompany} />
      <div className="animated fadeInRightFAKE">
        <div className="row">
          <form className="m-t" autoComplete="off">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>{titleLeft}</h5>
                  <div className="ibox-tools">
                    <a className="dropdown-toggle" data-toggle="dropdown">
                      <span className={`badge badge-${color}`}>
                        <i className={`fa fa-${icon}`}></i> &nbsp; {titleRight}{" "}
                        &nbsp;
                        <i className="fa fa-chevron-down"></i>
                      </span>
                    </a>
                    <ul className="dropdown-menu dropdown-user">
                      {tiles.map((tile, tileIndex) => {
                        if (tile.title !== titleRight) {
                          return (
                            <li key={tileIndex}>
                              <a onClick={() => tile.callback(tile)}>
                                {" "}
                                {tile.title}
                              </a>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </div>

                <div className="ibox-content panel-primary contentCard">
                  <HandleSkeletonTbleFac
                    classParam={`${isLoading ? "" : "hide"}`}
                  />
                  <div className={` ${isLoading ? "hide" : ""}`}>
                    {companyData.length === 0 ? (
                      <div className="alert alert-warning text-center">
                        <a className="alert-link">
                          {appLabel.noDataInTableMsg}
                        </a>
                      </div>
                    ) : (
                      <div>
                      <div className="table-responsive">
                        <table
                          id="myTable"
                          className="table table-striped table-bordered table-hover"
                        >
                          <thead>
                            <tr>
                              <th></th>
                              <th>{appLabel.companyId}</th>
                              <th>{appLabel.companyName}</th>
                              <th>{appLabel.companyType}</th>
                              <th>{appLabel.companyRegNo}</th>
                              {isCustodian && (
                                <th>{appLabel.companyRegDate}</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {companyData.map((company, index) => (
                              <tr className="gradeX" key={index}>
                                <td>
                                  <label className="myradio-button">
                                    <input
                                      type="radio"
                                      name="radio"
                                      onClick={() => onSelectCompany(company)}
                                    />
                                    <span className="label-visible">
                                      <span className="myfake-radiobutton"></span>
                                    </span>
                                  </label>
                                </td>
                                <td>{company.COMPANY_ID}</td>
                                <td>{company.COMPANY_NAME}</td>
                                <td>{company.COMPANY_TYPE_NAME}</td>
                                <td>{company.COMPANY_REGNO}</td>
                                {isCustodian && (
                                  <td>{company.COMPANY_REG_DATE}</td>
                                )}
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th></th>
                              <th>{appLabel.companyId}</th>
                              <th>{appLabel.companyName}</th>
                              <th>{appLabel.companyType}</th>
                              <th>{appLabel.companyRegNo}</th>
                              {isCustodian && (
                                <th>{appLabel.companyRegDate}</th>
                              )}
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  companyListPagi: state.paginationRed.companyList,
});

const mapDispatchToProps = {getCompanyAction};
export default connect(mapStateToProps, mapDispatchToProps)(ManageCompany);
