import React, { useEffect, useState } from "react";
import { apis as api } from "../../services/api.action";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM, RAL } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import { Link } from "react-router-dom";
import RecordStatus from "../ui-setup/RecordStatus";
import NumberFormat from "react-number-format";
import {
  InputAmountFacility,
  Tooltips,
  ColBlockFac,
} from "../layouts/Facility";
import RightSide from "../ui-setup/RightSide";

const amtPybles = {
  amtPay1: 0,
  amtPay2: 0,
};
export const FinanceOrder = (props) => {
  const { location, userData, configData, recordData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId, companyTypeId } = userData;
  const { isCsd, txnRelStlmt } = configData;
  const { toolTip } = appStatic;

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  const dispatch = useDispatch();
  const [isFullPayment, setIsFullPayment] = useState(false);
  const [isCsdCheck, setIsCsdCheck] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [orderDetail, setOrderDetail] = useState({});
  const [materials, setMaterialData] = useState([]);
  const [companyDetail, setCompanyDetail] = useState({});
  const [shippingData, setShippingData] = useState({});
  const [primaryData, setPrimaryData] = useState({});
  const [billingData, setBillingData] = useState({});
  const [trustAccountData, setTrustAccountData] = useState([]);
  const [isFunder, setIsFunder] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [sharedPODetail, setSharedPODetail] = useState({});
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [contractDetail, setContractDetail] = useState({});
  const [financeDetail, setFinanceDetail] = useState({});
  const [surchargeDetail, setSurchargeDetail] = useState({});
  const [financeHistoryData, setFinanceHistoryData] = useState([]);
  const [shareSupplierReq, setShareSupplierReq] = useState({});

  const [autoFldData, setAutoFldData] = useState([]);
  const [otherFee1Data, setOtherFee1Data] = useState([]);
  const [otherFee2Data, setOtherFee2Data] = useState([]);
  const [amountPayable1, setAmountPayable1] = useState(0);
  const [amountPayable2, setAmountPayable2] = useState(0);
  const [totalSettlement, setTotalSettlement] = useState(0);

  const [feeObj, setFeeObj] = useState({
    installmentAmount: 0,
    costOfFinance: 0,
    interestRate: 0,
    interestAmount: 0,
    tenorMonths: 0,
    transactionFee: 0,
    totalRepayment: 0,
    otherFee1: 0,
    otherFee2: 0,
  });

  const [valueDate, setValueDate] = useState(
    BOM.addDaysInDate(new Date(), txnRelStlmt)
  );
  let tempDocument = [];

  useEffect(() => {
    // console.log(ggParam);

    handleSwitchCondition();
    getDetailById(ggParam);
    checkForFundingType(ggParam);
    getTrustAccount();
    getFeeList(ggParam);
    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);

    //CALL DOCUMENT BLOCK
    BOM.getSpecDoc(ggParam?.ORDERID, setDocuments, userData);

    
    // getSpecDoc(state?.ggParam?.ORDERID, "purchaseorder");
    // //GET 2nd DOCUMENT
    // setTimeout(() => {
    // getSpecDoc(state?.ggParam?.ORDERID, "sharing");
    // }, 1500);
  }, []);

  const handleSwitchCondition = () => {
    switch (companyTypeId) {
      case "CT2022012400003": // funder
        setIsFunder(true);
        if (orderDetail.INTENT_ID && orderDetail.NOTE_CODE) {
          RAL.disableEnableRightAction();
        }
        break;
      default: // other entity type
        setIsFunder(false);
        RAL.disableEnableRightAction();
        break;
    }
  };
  const onChoosePaymentOption = (type) => {
    // console.log(type);
    switch (type.toLowerCase()) {
      case "instalment":
        setIsFullPayment(true);
        break;
      default:
        setIsFullPayment(false);
        break;
    }
  };
  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      orderId: params.ORDERID,
      companyId,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetPo, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let orderDetail = res.purchaseOrderDetail;

        setOrderDetail(orderDetail);

        dispatch({
          type: cnt.RECORD_STATUS,
          payload: orderDetail,
        });

        onChoosePaymentOption(orderDetail.PO_PAYMENT_OPTION);
        setMaterialData(orderDetail.MATERIAL_DATA);
        onChooseCompany(orderDetail.PO_FROM_COMPANYID);

        setInvoiceDetail(orderDetail.INVOICE_DATA);
        setContractDetail(orderDetail.CONTRACT_DATA);
        // setFinanceDetail(orderDetail.FUNDING_DATA); // changed to bidding
        setFinanceDetail(orderDetail.BIDDING_DATA);
        setSurchargeDetail(orderDetail.SURCHARGE_DATA);
        setFinanceHistoryData(orderDetail.FUND_HISTORY_DATA);

        setShareSupplierReq(orderDetail.SUPPLIER_SHARE_REQ_DATA);
        calculateTotals(orderDetail);

        //handle right side
        // BOM.IsApproveOrReject(orderDetail.FLG_STATUS);

        //get document
        // getSpecDoc(state.ggParam);
      }
    });
  };
  const getSpecDocOLD = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      id: params.ORDERID,
      type: "sharing",
      session,
    };

    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setDocuments(res.docListing);
      }
    });
  };
  const getSpecDoc = async (id, type) => {
    let body = {
      profileId,
      id,
      type,
      session,
    };

    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        switch (type?.toLowerCase()) {
          case "purchaseorder":
            res.docListing.forEach((element) => {
              tempDocument.push(element);
            });
            break;

          default:
            res.docListing.forEach((element) => {
              tempDocument.push(element);
            });
            break;
        }

        setDocuments([...tempDocument]);
      }
    });
  };
  const onChooseCompany = (companyId) => {
    // let specCo = companyData.find((item, index)=> item.COMPANY_ID === coId)
    if (!companyId) {
      setCompanyDetail({});
      return;
    }

    let body = {
      profileId,
      companyId,
      session,
    };

    /** FETCH */
    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let companyDetail = res.companyDetail;
        setCompanyDetail(companyDetail);
        setPrimaryData(companyDetail.CONTACT_DATA);
        setShippingData(companyDetail.SHIP_DATA);
        setBillingData(companyDetail.BILL_DATA);
      }
    });
  };
  function getTrustAccount() {
    let body = {
      profileId,
      accId: "",
      criteria: "A",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetTrustAccount, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setTrustAccountData(res.trustAccountListing);
      }
    });
  }
  const calculateTotals = (objParam) => {
    // setSharedPODetail(state.ggParam);
    // console.log(objParam);

    /** SURCHARGE CALCUL LOGIC */
    let surchargeObj = objParam.SURCHARGE_DATA,
      chargeRate = 0,
      maxChargeAmt = 0;

    if (Object.keys(surchargeObj).length !== 0) {
      chargeRate = parseFloat(surchargeObj.CHARGE_PERCENTAGE, 10) / 100;
      maxChargeAmt = parseFloat(surchargeObj.CHARGE_LIMIT, 10);
    }

    const { amtPay1, amtPay2 } = amtPybles;
    let shareReqObj = objParam.SUPPLIER_SHARE_REQ_DATA,
      tenorVal = parseInt(shareReqObj.TENOR),
      interestRateVal = parseFloat(shareReqObj.INTEREST_RATE, 10),
      funderAmount = parseFloat(getValues("investorLoanAmount"), 10),
      funderRate = parseFloat(getValues("investorIntrestRate"), 10) / 100,
      tenor = tenorVal / 12,
      interestAmount = funderAmount * funderRate * tenor,
      totalRepayment = funderAmount + interestAmount,
      transactionFee = funderAmount * chargeRate;

    if (transactionFee > maxChargeAmt) {
      transactionFee = maxChargeAmt;
    }

    let installmentAmount = funderRate * tenor * funderAmount;

    //Calculate TotalSettlement part 1
    setTotalSettlement(funderAmount + transactionFee + amtPay1 + amtPay2);

    //update feeObj for displaying purpose
    setFeeObj({
      installmentAmount: parseFloat(installmentAmount, 10).toFixed(2),
      interestRate: parseFloat(interestRateVal, 10).toFixed(2),
      interestAmount: parseFloat(interestAmount, 10).toFixed(2),
      tenorMonths: tenorVal,
      transactionFee: parseFloat(transactionFee, 10).toFixed(2),
      totalRepayment: parseFloat(totalRepayment, 10).toFixed(2),
    });
  };

  function checkForFundingType(objParam) {
    switch (objParam.IS_PRIVATE_FUNDING) {
      case "true":
        setIsPrivate(true);
        setValue("investorLoanAmount", parseFloat(objParam.REQUESTED_AMOUNT));
        break;
      default:
        break;
    }
  }
  function getFeeList(objParam) {
    // console.log(objParam);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      feeId: "",
      ccy: objParam.CCY,
      companyTypeId,
      loanAmount: objParam.REQUESTED_AMOUNT,
      criteria: "",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetFees, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.feeListing;

        setOtherFee1Data(
          objList.filter(
            (item) => item.FEE_OPTION.toLowerCase() === "other fee 1"
          )
        );
        setOtherFee2Data(
          objList.filter(
            (item) => item.FEE_OPTION.toLowerCase() === "other fee 2"
          )
        );
      }
    });
  }

  const onSelectOtherFee = (idParam, setParam, arrParam, keyFee) => {
    setParam(0);
    setTotalSettlement(totalSettlement);
    let tempCalc = 0;
    const bidAmount = parseFloat(getValues("investorLoanAmount"), 10);

    if (!idParam) {
      switch (keyFee) {
        case "fee1":
          setTotalSettlement(totalSettlement - amtPybles.amtPay1);
          amtPybles.amtPay1 = 0;
          break;
        case "fee2":
          setTotalSettlement(totalSettlement - amtPybles.amtPay2);
          amtPybles.amtPay2 = 0;
          break;
      }
      return;
    }

    /** AMOUNT PAYABLE  CALCULATION*/
    const feeSelected = arrParam.find((item) => item.FEE_ID === idParam);

    switch (feeSelected.FLG_FEE_SLAB.toLowerCase()) {
      case "n":
        switch (feeSelected.FEE_TYPE.toLowerCase()) {
          case "flat":
            tempCalc = parseFloat(feeSelected.FEE_AMOUNT, 10);
            setParam(tempCalc);
            break;
          case "percentage":
            const tempRate = parseFloat(feeSelected.FEE_PERCENTAGE, 10) / 100;
            if (!bidAmount) {
              BOM.AlertMsg(
                cnt.WARNING,
                appLabel.investorLoanAmnt + " is required."
              );
              $(".clearInputFee").val("");
              return;
            }
            tempCalc = bidAmount * tempRate;
            setParam(tempCalc);
            break;
        }
        break;
      default:
        //regardless of fee type the following will be done

        if (feeSelected.SLAB_DATA.length === 0) {
          switch (feeSelected.FEE_TYPE.toLowerCase()) {
            case "flat":
              tempCalc = parseFloat(feeSelected.FEE_AMOUNT, 10);
              setParam(tempCalc);
              break;
            case "percentage":
              const tempRate = parseFloat(feeSelected.FEE_PERCENTAGE, 10) / 100;
              if (!bidAmount) {
                BOM.AlertMsg(
                  cnt.WARNING,
                  appLabel.investorLoanAmnt + " is required."
                );
                $(".clearInputFee").val("");
                return;
              }
              tempCalc = bidAmount * tempRate;
              setParam(tempCalc);
              break;
          }
        } else {
          //We use rate in the slab data rather in percentage
          const { SLAB_DATA } = feeSelected;
          const tempRate = parseFloat(SLAB_DATA[0].FEE_RATE, 10) / 100;
          if (!bidAmount) {
            BOM.AlertMsg(
              cnt.WARNING,
              appLabel.investorLoanAmnt + " is required."
            );
            $(".clearInputFee").val("");
            return;
          }
          tempCalc = bidAmount * tempRate;
          setParam(tempCalc);
        }
        break;
    }

    calcTotalSettlement(tempCalc, keyFee);
  };
  function calcTotalSettlement(amtParam = null, keyFee = null) {
    switch (keyFee) {
      case "fee1":
        amtPybles.amtPay1 = amtParam ? amtParam : 0;
        break;
      case "fee2":
        amtPybles.amtPay2 = amtParam ? amtParam : 0;
        break;

      default:
        amtPybles.amtPay1 = 0;
        amtPybles.amtPay2 = 0;
        break;
    }

    const { amtPay1, amtPay2 } = amtPybles;
    const transactionFee = parseFloat(feeObj.transactionFee),
      bidAmount = parseFloat(getValues("investorLoanAmount")),
      totalSettlement = bidAmount + transactionFee + amtPay1 + amtPay2;

    //Calculate TotalSettlement part 2
    setTotalSettlement(totalSettlement);

    // console.log("transactionFee: ", transactionFee);
    // console.log("bidAmount: ", bidAmount);
    // console.log("amtPay1: ", amtPay1);
    // console.log("amtPay2: ", amtPay2);
    // console.log("totalSettlement: ", totalSettlement );
    // console.log(amtPybles);
  }
  const formSubmit = (formValues) => {
    // console.log("feeObj", feeObj);
    // console.log("data", formValues);

    if (totalSettlement < 0) {
      BOM.AlertMsg(
        cnt.WARNING,
        appLabel.totalSettlementAmount + " cannot be negative."
      );
      return;
    }

    if (
      parseFloat(formValues.investorLoanAmount) >
      parseFloat(ggParam.REQUESTED_AMOUNT)
    ) {
      BOM.AlertMsg(
        cnt.WARNING,
        appLabel.investorLoanAmnt + " should no exceed the Loan Amount."
      );
      return;
    }

    const otherFees = amountPayable1 + amountPayable2,
      costOfFinance = (
        formValues.investorLoanAmount +
        parseFloat(feeObj.transactionFee) +
        parseFloat(feeObj.installmentAmount) +
        otherFees
      ).toFixed(2);

    //merg objects
    formValues = { ...formValues, ...feeObj };

    //Validate NaN value
    if (
      BOM.ValidateNaNValue(
        formValues.investorIntrestRate,
        appLabel.investorInterest
      )
    )
      return;
    formValues.otherFee1 = amountPayable1 + "";
    formValues.otherFee2 = amountPayable2 + "";
    formValues.costOfFinance = costOfFinance + "";
    formValues.interestRate = formValues.interestRate + ""; // send it as a string to match the rule engine
    formValues.tenorMonths = formValues.tenorMonths + "";
    formValues.investorIntrestRate = formValues.investorIntrestRate + ""; // send it as a string to match the rule engine
    formValues.investorLoanAmount = formValues.investorLoanAmount + "";
    formValues.orderId = ggParam.ORDERID;
    formValues.loanAmount = ggParam.REQUESTED_AMOUNT;
    formValues.remitToCompanyId = orderDetail.PO_TO_COMPANYID;
    formValues.billToCompanyId = orderDetail.PO_FROM_COMPANYID;
    formValues.intentId = orderDetail.INTENT_ID;
    formValues.paymentDate = valueDate;
    formValues.investorId = companyId;
    formValues.trustAccountId="";//this was removed

    let body = {
      profileId,
      session,
      ...formValues,
    };

    // console.log(body);
    // return;

    /** SEND REQ */
    BOM.GlobalApproveRejectAction(
      body,
      api.FundPo,
      "shared-order",
      "Funding ",
      "Confirm "
    );
  };

  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <RightSide />
        <ContentTop
          pageTitle={
            isFunder ? appLabel.orderFinancing : appLabel.sharedOrderDetail
          }
        />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{appLabel.orderInfo}</h5>
                  <div className="ibox-tools">
                    <Link to="/shared-order" className="btn btn-primary btn-xs">
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.generalInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.poNo}&nbsp;
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={orderDetail.ORDERID}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.from}&nbsp;
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={orderDetail.FROM_COMPANY_NAME}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.to}&nbsp;
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={orderDetail.TO_COMPANY_NAME}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.poAmount}&nbsp;
                          </label>
                        </div>
                        <div className="col-md-7">
                          <NumberFormat
                            className="form-control input-sm"
                            value={orderDetail.PO_AMOUNT}
                            displayType="text"
                            disabled
                            thousandSeparator={true}
                            prefix={""}
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.currency}&nbsp;
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={orderDetail.PO_CCY}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.pymtOption}&nbsp;
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={orderDetail.PO_PAYMENT_OPTION}
                            disabled
                          />
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6 ${
                          isFullPayment ? "" : "hide"
                        }`}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.frequency}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={orderDetail.PO_INSTALLMENT_FREQ}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.firstPayDue}{" "}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={orderDetail.PO_FIRSTPAY_DUE}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.lastPayDue}{" "}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={orderDetail.PO_LASTPAY_DUE}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row" style={{ marginTop: "15px" }}>
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.addressInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="col-md-6">
                        <div className="well">
                          <address>
                            <h4>
                              {" "}
                              <i className="fa fa-building text-navy"></i>
                              &nbsp; {appLabel.shippingAddress}
                            </h4>{" "}
                            <br />
                            <i className="fa fa-circle text-navy"></i> &nbsp;{" "}
                            {shippingData.SHIPPING_ADDRESS_1},{" "}
                            {shippingData.SHIPPING_CITY_NAME}
                            <br />
                            {shippingData.SHIPPING_PROVINCE_NAME},{" "}
                            {shippingData.SHIPPING_COUNTRY_NAME}{" "}
                            {shippingData.SHIPPING_POST_CODE}
                            <br />
                            <span title="Phone">
                              <i className="fa fa-circle text-navy"></i> &nbsp;
                              Phone :
                            </span>{" "}
                            <strong>{primaryData.OFFICE_PHONE}</strong>
                          </address>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="well">
                          <address>
                            <h4>
                              {" "}
                              <i className="fa fa-building text-navy"></i>
                              &nbsp;{appLabel.billingAddress}
                            </h4>{" "}
                            <i className="fa fa-circle text-navy"></i>
                            &nbsp;{billingData.BILLING_ADDRESS_1},{" "}
                            {billingData.BILLING_CITY_NAME}
                            <br />
                            {billingData.BILLING_PROVINCE_NAME},{" "}
                            {billingData.BILLING_COUNTRY_NAME}{" "}
                            {billingData.BILLING_POST_CODE}
                            <br />
                            <span title="Phone">
                              <i className="fa fa-circle text-navy"></i> &nbsp;
                              Phone:
                            </span>{" "}
                            <strong>{primaryData.OFFICE_PHONE}</strong>
                          </address>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={`row ${isFunder ? "" : "hide"} `}>
                    <ColBlockFac topTitle={appLabel.orderFinancingInfo}>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.AmountToFinance}&nbsp;
                          </label>
                        </div>
                        <div className="col-md-7">
                          <NumberFormat
                            className="form-control input-sm"
                            value={ggParam.REQUESTED_AMOUNT}
                            displayType="text"
                            disabled
                            thousandSeparator={true}
                            prefix={" "}
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.interestRate}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            placeholder={appLabel.interestRate}
                            className="form-control input-sm clearPerceInput"
                            disabled
                            value={feeObj.interestRate}
                          />
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6 ${
                          isFunder ? "hide" : ""
                        }
                      `}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.chargeRate}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            placeholder={appLabel.chargeRate}
                            disabled
                            className="form-control input-sm clearPerceInput"
                            value={feeObj.chargeRate}
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.duration}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.duration}
                            disabled
                            value={feeObj.tenorMonths}
                          />
                        </div>
                      </div>
                    </ColBlockFac>
                    <ColBlockFac topTitle={appLabel.bidInfo}>
                      {/* <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.trustAccount}&nbsp;
                            <Tooltips title={toolTip.trustAccount} />
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm"
                            {...register("trustAccountId", {
                              required: "Field is required.",
                            })}
                          >
                            <option value="">{appLabel.choose}</option>
                            {trustAccountData.map((item, index) => (
                              <option value={item.TRUST_ACC_ID} key={index}>
                                {item.BANK_NAME}
                              </option>
                            ))}
                          </select>
                          <span className="asterisks">
                            {errors?.trustAccountId?.message}
                          </span>
                        </div>
                      </div> */}
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.paymentDate}&nbsp;
                            <Tooltips title={toolTip.paymentDate} />
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            value={valueDate}
                            disabled
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          isPrivate ? "hide" : "row form-group col-md-6 "
                        }`}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.investorLoanAmnt}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="number"
                            className="form-control input-sm"
                            placeholder={appLabel.investorLoanAmnt}
                            {...register("investorLoanAmount", {
                              onChange: () => calculateTotals(orderDetail),
                              required: "Field is required.",
                              setValueAs: (v) => parseInt(v),
                              valueAsNumber: true,
                              min: {
                                value: 1,
                                message:
                                  appLabel.investorLoanAmnt +
                                  " should not be less than 1.",
                              },
                              max: {
                                value: sharedPODetail.REQUESTED_AMOUNT,
                                message:
                                  appLabel.investorLoanAmnt +
                                  " should not exceed the initial amount.",
                              },
                            })}
                          />
                          <span className="asterisks">
                            {errors?.investorLoanAmount?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.investorInterest}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.investorInterest}
                            {...register("investorIntrestRate", {
                              onChange: () => calculateTotals(orderDetail),
                              required: "Field is required.",
                              setValueAs: (v) => parseInt(v),
                              valueAsNumber: true,
                              min: {
                                value: 1,
                                message: "Rate cannot be less than 1%",
                              },
                              max: {
                                value: 100,
                                message: "Rate cannot be greater than 100%",
                              },
                            })}
                          />
                          <span className="asterisks">
                            {errors?.investorIntrestRate?.message}
                          </span>
                        </div>
                      </div>
                      <InputAmountFacility
                        inputLabel={appLabel.interestAmount}
                        inputValue={feeObj.interestAmount}
                        toolTipLabel={toolTip.interestAmount}
                      />
                      <InputAmountFacility
                        inputLabel={appLabel.totalRepayment}
                        inputValue={feeObj.totalRepayment}
                        toolTipLabel={toolTip.totalRepayment}
                      />
                    </ColBlockFac>
                    <ColBlockFac topTitle={appLabel.fundingFeeInfo}>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.otherFee1}&nbsp;
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm clearInputFee"
                            onChange={(e) =>
                              onSelectOtherFee(
                                e.target.value,
                                setAmountPayable1,
                                otherFee1Data,
                                "fee1"
                              )
                            }
                          >
                            <option value="">{appLabel.choose}</option>
                            {otherFee1Data.map((item, index) => (
                              <option value={item.FEE_ID} key={index}>
                                {item.FEE_NAME}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.otherFee2}&nbsp;
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm clearInputFee"
                            onChange={(e) =>
                              onSelectOtherFee(
                                e.target.value,
                                setAmountPayable2,
                                otherFee2Data,
                                "fee2"
                              )
                            }
                          >
                            <option value="">{appLabel.choose}</option>
                            {otherFee2Data.map((item, index) => (
                              <option value={item.FEE_ID} key={index}>
                                {item.FEE_NAME}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.amountPayable1}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <NumberFormat
                            className="form-control input-sm"
                            value={amountPayable1}
                            displayType="text"
                            disabled
                            thousandSeparator={true}
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.amountPayable2}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <NumberFormat
                            className="form-control input-sm"
                            value={amountPayable2}
                            displayType="text"
                            disabled
                            thousandSeparator={true}
                          />
                        </div>
                      </div>
                      <InputAmountFacility
                        inputLabel={appLabel.transactionFee}
                        inputValue={feeObj.transactionFee}
                      />
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.totalSettlementAmount}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <NumberFormat
                            className="form-control input-sm"
                            value={totalSettlement}
                            displayType="text"
                            disabled
                            thousandSeparator={true}
                          />
                        </div>
                      </div>
                    </ColBlockFac>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.otherInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <ul className="nav nav-tabs">
                        <li className="active">
                          <a
                            data-toggle="tab"
                            href="#documentInfo"
                            target="_self"
                          >
                            <i className="fa fa-file-pdf-o text-navy" />
                            &nbsp;{appLabel.documentInfo}
                          </a>
                        </li>
                        <li>
                          <a
                            data-toggle="tab"
                            href="#invoiceInfo"
                            target="_self"
                          >
                            <i className="fa fa-file-text text-navy" />
                            &nbsp;{appLabel.invoiceInfoShort}
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="documentInfo">
                          <div className="row" style={{ marginTop: "30px" }}>
                            <div className="col-sm-12">
                              {documents.length === 0 ? (
                                <div className="alert alert-warning text-center">
                                  <a className="alert-link">
                                    {appLabel.noDocumentMsg}
                                  </a>
                                </div>
                              ) : (
                                <div>
                                  {documents.map((item, index) => (
                                    <div className="col-md-4" key={index}>
                                      <div className="file-box">
                                        <div className="file">
                                          <a
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              BOM.DownloadAction(
                                                item.DOC_OBJECT,
                                                item.DOC_ID +
                                                  " " +
                                                  item.DOC_CATEGORY.toLowerCase()
                                              )
                                            }
                                          >
                                            <span className="corner" />
                                            <div className="icon">
                                              <i
                                                className="fa fa-file-pdf-o"
                                                style={{ color: "#d50000" }}
                                              />
                                            </div>
                                            <div className="file-name">
                                              {item.DOC_CATEGORY}
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="invoiceInfo">
                          <div className="row" style={{ marginTop: "20px" }}>
                            {Object.keys(invoiceDetail).length === 0 ? (
                              <div className="col-sm-12">
                                <div className="alert alert-warning text-center">
                                  <a className="alert-link">
                                    {appLabel.noDataInTableMsg}
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <div className="col-sm-12">
                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.invoiceId}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      value={invoiceDetail.INV_ID}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.to}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      value={invoiceDetail.SUPPLIER_COMPANY}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className={`row form-group col-md-6`}>
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.invAmount}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <NumberFormat
                                      className="form-control input-sm"
                                      value={invoiceDetail.ORIGINAL_AMOUNT}
                                      displayType="text"
                                      disabled
                                      thousandSeparator={true}
                                      prefix={""}
                                    />
                                  </div>
                                </div>
                                <div className={`row form-group col-md-6`}>
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.currency}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      defaultValue={invoiceDetail.INV_CCY}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.startDate}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      defaultValue={
                                        invoiceDetail.CONTR_START_DATE
                                      }
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <RecordStatus {...recordData} /> */}
        </div>
      </form>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FinanceOrder);
