import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../../services/api.action";
import { connect, useDispatch } from "react-redux";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import appStatic from "../../../config/appStaticData";
import { Link } from "react-router-dom";
import RecordStatus from "../../ui-setup/RecordStatus";
import NumberFormat from "react-number-format";
import {
  InputAmountFacility,
  InputTextFacility,
  Col12BlockFacility,
} from "../../layouts/Facility";

export const TaxDetail = (props) => {
  const { location, userData, configData, recordData, menuData } = props;
  const { state } = location;
  const { profileId, session, companyId } = userData;
  const { isCsd } = configData;
  const { toolTip } = appStatic;

  const dispatch = useDispatch();
  const [taxDetail, setTaxDetail] = useState({});

  useEffect(() => {
    // console.log(state.ggParam);

    getDetailById(state.ggParam);
  }, []);

  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      taxId: params.TAX_ID,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetTax, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let taxDetail = res.taxDetail;

        setTaxDetail(taxDetail);
        dispatch({
          type: cnt.RECORD_STATUS,
          payload: taxDetail,
        });
        //handle right side
        BOM.IsApproveOrReject(taxDetail.FLG_STATUS, menuData, "Manage Tax");
      }
    });
  };

  const onApprove = () => {
    let bodyApprove = {
      profileId,
      taxId: state.ggParam.TAX_ID,
      criteria: "A",
      session,
    };

    // console.log(bodyApprove);
    BOM.GlobalApproveRejectAction(
      bodyApprove,
      api.ApproveRejectTax,
      "manage-tax",
      "Tax",
      "Approve "
    );
  };
  const onReject = () => {
    let bodyDisable = {
      profileId,
      taxId: state.ggParam.TAX_ID,
      criteria: "R",
      session,
    };

    // console.log(bodyDisable);
    BOM.GlobalApproveRejectAction(
      bodyDisable,
      api.ApproveRejectTax,
      "manage-tax",
      "Tax",
      "Reject "
    );
  };

  return (
    <Main {...props} onApprove={onApprove} onReject={onReject}>
      <ContentTop pageTitle={appLabel.taxDetail} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{appLabel.taxInformation}</h5>
                <div className="ibox-tools">
                  <Link to="/manage-tax" className="btn btn-primary btn-xs">
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;
                    {appLabel.backBtn}
                  </Link>
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                  <Col12BlockFacility topTitle={appLabel.generalInfo}>
                    <InputTextFacility
                      inputLabel={appLabel.category}
                      inputValue={taxDetail.TAX_CATEGORY}
                      toolTipLabel={toolTip.category}
                    />
                    <InputTextFacility
                      inputLabel={appLabel.type}
                      inputValue={taxDetail.TAX_TYPE}
                      toolTipLabel={toolTip.type}
                    />
                    <InputTextFacility
                      inputLabel={appLabel.rate}
                      inputValue={taxDetail.TAX_RATE}
                      toolTipLabel={toolTip.rate}
                    />
                    {/* <InputAmountFacility
                      inputLabel={appLabel.amount}
                      inputValue={taxDetail.TAX_AMOUNT}
                      toolTipLabel={toolTip.taxAmount}
                    /> */}
                    <InputTextFacility
                      inputLabel={appLabel.effectiveDate}
                      inputValue={taxDetail.EFFECTIVE_DATE}
                      toolTipLabel={toolTip.effectiveDate}
                    />
                    {/* <InputTextFacility
                      inputLabel={appLabel.waiverAllow}
                      inputValue={
                        taxDetail.WAIVER_ALLOW === "Y"
                          ? appLabel.yes
                          : appLabel.no
                      }
                      toolTipLabel={toolTip.waiverAllow}
                    /> */}
                  </Col12BlockFacility>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RecordStatus {...recordData} />
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TaxDetail);
