import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Main from "../../layouts/Main";
import { connect, useDispatch } from "react-redux";
import { apis as api } from "../../../services/api.action";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM, RAL } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import appStatic from "../../../config/appStaticData";
import { TableFacility, AmntFormatFac } from "../../layouts/Facility";
import RightSide from "../../ui-setup/RightSide";
import { HandleSkeletonTbleFac } from "../../layouts/Facility";
import ReactToPrint from "react-to-print";
import { Button } from "react-bootstrap";

const AutoBidPreview = (props) => {
  let componentRef = useRef();
  const { userData, location, history } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, profileFirstName, profileLastName } = userData;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const mainParams = ggParam;
  const bidDetail = ggParam?.bidDetail;
  const fullName = `${profileFirstName} ${profileLastName}`;
  const [bidData, setBidData] = useState([]);
  const [reqAmnt, setReqAmnt] = useState(
    parseFloat(bidDetail.REQUESTED_AMOUNT)
  );
  const [bidActivity, setBidActivity] = useState([]);
  const [isSelectedBid, setIsSelectedBid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bidInfo, setBidinfo] = useState({
    bidProcessed: 0,
    totalNoofAllotment: 0,
    totalOutstanding: reqAmnt,
    allotmentRate: 0,
    amountRequested: parseFloat(bidDetail.REQUESTED_AMOUNT),
  });
  const [progress, setProgress] = useState({});

  const shareType = bidDetail.shareType;
  const apiUrl =
    bidDetail.ITEM_TYPE.toLowerCase() === "po"
      ? api.PreApprovePoBid
      : api.PreApproveInvBid;

  useEffect(() => {
    onAutomaticBid();
    RAL.disableEnableRightAction();
  }, []);

  function storeBidLogic(objParam) {
    //distracture the objParam
    const { selectedItem, currentReqAmt, allotmentRate, isAutoBid, bidAmount } =
      objParam;
    let tempObj = {};

    const expressionStr = `${currentReqAmt.toFixed(2)} - ${bidAmount.toFixed(
      2
    )}`;
    const remainder = currentReqAmt - bidAmount;

    // NEW INTEREST FORMULA: Interest = Principal * Rate * Time
    //Interest = 200,000,000 * 3.8% * (3/12)
    //Total Repayment = Bid Amount + New Interest Amount

    const newInterest =
      bidAmount *
      (parseFloat(selectedItem.FUNDER_RATE) / 100) *
      (parseInt(selectedItem.TENOR) / 12);
    const newTotalRepayment = bidAmount + newInterest;

    tempObj = {
      funderId: selectedItem.FUNDER_ID,
      funderName: selectedItem.FUNDER_NAME,
      itemType: mainParams.ITEM_TYPE,
      expressionStr,
      dateTimeStr: BOM.DateTime(new Date()),
      remainderAmnt: remainder <= 0 ? 0 : remainder.toFixed(2),
      bidAmount: bidAmount.toFixed(2),
      transactionFee: parseFloat(selectedItem?.ARRANGEMENT_FEE).toFixed(2),
      amountPayable1: selectedItem.AMOUNT_PAYABLE_1,
      amountPayable2: selectedItem.AMOUNT_PAYABLE_2,
      rate: selectedItem.FUNDER_RATE,
      allotmentRate,
      interestAmount: newInterest.toFixed(2),
      totalRepayment: newTotalRepayment.toFixed(2),
      bidObj: { ...selectedItem },
    };

    return tempObj;
  }

  function sortByRate(objList) {
    /** ORDER ITM S BY FUNDER RATE */
    const myData = [].concat(objList).sort((a, b) => {
      if (a.FUNDER_RATE === "" && b.FUNDER_RATE === "") {
        return -1;
      } else if (parseFloat(a.FUNDER_RATE) > parseFloat(b.FUNDER_RATE)) {
        return 1;
      }
    });

    return myData;
  }

  function handleSaveBtn(outStanding) {
    if (outStanding === 0) {
      setTimeout(() => {
        RAL.disableEnableRightAction([cnt.RIGHT_SAVE_BTN]);
      }, 500);
    } else {
      setTimeout(() => {
        RAL.disableEnableRightAction();
      }, 500);
    }
  }

  function onAutomaticBid() {
    //initialization Variables
    const bidDetail = ggParam.bidDetail;

    //get only bids with HAS_BID set to Y
    const tempBidData = sortByRate(ggParam?.bidData).filter(
      (item) => item?.HAS_BID?.toLowerCase() === "y"
    );

    //temp variables
    let tempArr = [];
    let currentReqAmt = reqAmnt;

    // console.log("currentReqAmt: ", currentReqAmt);
    let rateType = "single";
    let bidAllotmentCount = 0;
    let allotmentRate = 0; // it is just a default value
    const TEMP_ALLOTMENT = [];
    const TEMP_OUSTANDING = [];

    //get best rate(s)
    // const bestRateObj = tempBidData[0];

    let size = 0;
    for (let index = size; index < tempBidData.length; index++) {
      const bestRateObj = tempBidData[index];

      // console.log(element);

      const bestRatesData = tempBidData.filter(
        (item) => item.FUNDER_RATE === bestRateObj.FUNDER_RATE
      );

      let bidAmount = bestRateObj?.FUNDER_AMOUNT
        ? parseFloat(bestRateObj.FUNDER_AMOUNT)
        : 0;

      switch (bestRatesData.length) {
        case 1: // Take the entire funder amount.
          // console.log("index case Single: ", index);

          if (currentReqAmt >= bidAmount) {
            //prepare parameters for sending
            const objParam = {
              selectedItem: bestRateObj,
              currentReqAmt,
              allotmentRate: 0,
              isAutoBid: "Y",
              bidAmount,
            };

            // send and store element for display purposes
            tempArr.push(storeBidLogic(objParam));

            //update the current or outstanding balance
            currentReqAmt -= bidAmount;
          } else if (currentReqAmt > 0) {
            //update bid amount take only the amount needed in funder amount
            let tempBid = bidAmount - (bidAmount - currentReqAmt);

            //prepare parameters for sending
            const objParam = {
              selectedItem: bestRateObj,
              currentReqAmt,
              allotmentRate: 0,
              isAutoBid: "Y",
              bidAmount: tempBid,
            };

            // send and store element for display purposes
            tempArr.push(storeBidLogic(objParam));

            //update the current or outstanding balance
            currentReqAmt -= currentReqAmt;

            //take the next bestRate in the list
            size++;
          }
          break;

        default:
          //Sum funder's amounts to get totalFunder
          const totalFunderAmt = BOM.calculateSumObj(
            bestRatesData,
            "FUNDER_AMOUNT"
          );
          //  + 10000000;

          /**
           * Save the Outstanding amounts
           * and
           * use the 1st amount as the constant value while comparing
           */
          TEMP_OUSTANDING.push(currentReqAmt);

          /**
           *  There is no allotment calculation
           * if OutStanding (TEMP_OUSTANDING[0] ) amount > total funder's amounts
           * Treat them as single rate bid
           */

          /**
           *  Single bid without allotment
           */
          // console.log(`Outs: ${currentReqAmt} | tot Funder: ${totalFunderAmt}`);

          if (TEMP_OUSTANDING[0] > totalFunderAmt) {
            if (currentReqAmt >= bidAmount) {
              //prepare parameters for sending
              const objParam = {
                selectedItem: bestRateObj,
                currentReqAmt,
                allotmentRate: 0,
                isAutoBid: "Y",
                bidAmount,
              };

              // send and store element for display purposes
              tempArr.push(storeBidLogic(objParam));

              //update the current or outstanding balance
              currentReqAmt -= bidAmount;
            } else if (currentReqAmt > 0) {
              //update bid amount take only the amount needed in funder amount
              let tempBid = bidAmount - (bidAmount - currentReqAmt);

              //prepare parameters for sending
              const objParam = {
                selectedItem: bestRateObj,
                currentReqAmt,
                allotmentRate: 0,
                isAutoBid: "Y",
                bidAmount: tempBid,
              };

              // send and store element for display purposes
              tempArr.push(storeBidLogic(objParam));

              //update the current or outstanding balance
              currentReqAmt -= currentReqAmt;

              //take the next bestRate in the list
              size++;
            }
          }
          //end single rate bid

          //Begin Allotment bid
          else {
            /**
             * calculate and save each allotment result in array
             */
            allotmentRate = parseFloat(
              (currentReqAmt / totalFunderAmt) * 100
              // .toFixed(2)
            );
            TEMP_ALLOTMENT.push(allotmentRate);

            // while (bidAllotmentCount < bestRatesData.length) {
            bestRatesData.forEach((element, index) => {
              if (bidAllotmentCount < bestRatesData.length) {
                //get each funder bid amount
                let bidAmount = element.FUNDER_AMOUNT
                  ? (parseFloat(element.FUNDER_AMOUNT) * allotmentRate) / 100
                  : 0;

                // console.log(
                //   `funder amount: ${element.FUNDER_AMOUNT} | allotmentRate: ${allotmentRate} | bidAmount: ${bidAmount}`
                // );

                // bidAmount = parseFloat(bidAmount.toFixed(0));
                // currentReqAmt = parseFloat(currentReqAmt.toFixed(0));

                // console.log(`currentReqAmt: ${currentReqAmt} | bidAmount: ${bidAmount}`);
                // if (currentReqAmt >= bidAmount) {
                //prepare parameters for sending
                const objParam = {
                  selectedItem: element,
                  currentReqAmt,
                  allotmentRate: TEMP_ALLOTMENT[0],
                  isAutoBid: "Y",
                  bidAmount, //:parseFloat(bidAmount.toFixed(0)),
                };

                // send and store element for display purposes
                tempArr.push(storeBidLogic(objParam));

                //update the current or outstanding balance
                currentReqAmt -= bidAmount;
                bidAllotmentCount++;
                // }
              }
            });
            // }
          }
          //end Allotment bid
          break;
      }
    }

    // console.log("tempAllotment: ", TEMP_ALLOTMENT);
    // console.log("tempOutstanding: ", TEMP_OUSTANDING);

    setBidActivity(tempArr);
    setBidinfo({
      ...bidInfo,
      bidProcessed: bidAllotmentCount,
      totalOutstanding: parseFloat(currentReqAmt.toFixed(2)),
    });
    handleSaveBtn(parseFloat(currentReqAmt.toFixed(2)));
    handleProgressBar(parseFloat(currentReqAmt.toFixed(2)))
  }
  const formSubmit = () => {
    let investors = [];

    bidActivity.forEach((element) => {
      investors.push({
        investorId: element.funderId,
        bidStatus: "A",
        depositoryFinalAmt: element.bidAmount,
        allotmentRate: element.allotmentRate,
        isAutoBid: "Y",
      });
    });

    // console.log(bidActivity);
    if (investors.length === 0) {
      BOM.AlertMsg(cnt.WARNING, "Please choose at least one Investor.");
      return;
    }

    let body = {
      profileId,
      intentId: bidDetail.INTENT_ID,
      shareType,
      investors,
      session,
    };

    // console.log(body);
    // return;

    BOM.GlobalApproveRejectAction(
      body,
      apiUrl,
      "manage-funder-bid",
      "Bidding ",
      "Confirm "
    );
  };

  const handleProgressBar = (outStanding) => {
    if (outStanding === 0) {
      setProgress({
        text: `The bid was completed at 100%. The next step is to save the bid.`,
        value: "100%",
        color: "navy",
      });
    }else{
      const value = ((outStanding/bidInfo.amountRequested) * 100).toFixed(0);
      setProgress({
        text: `The bid was completed at ${value}%`,
        value:`${value}%`,
        color: "danger",
      });
    }
  };

  return (
    <Main {...props} onSaveChange={handleSubmit(formSubmit)}>
      <form method="POST" autoComplete="off">
        <ContentTop pageTitle={appLabel.bidByPo} />

        <div className="animated fadeInRightFAKE">
          <div className="row">
            <div className="col-sm-12">
              <div className="ibox">
                <div className="ibox-content contentCard">
                  <div className="row m-b-lg">
                    <div className="col-xs-12">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="m-b-md">
                            <ReactToPrint
                              trigger={() => (
                                <button
                                  type="button"
                                  className="btn btn-primary btn-xs pull-right"
                                >
                                  <i className="fa fa-print" />
                                  &nbsp;{appLabel.printBtn}
                                </button>
                              )}
                              content={() => componentRef}
                            />
                            <span className="hide">
                              <ComponentToPrint
                                ref={(el) => (componentRef = el)}
                                objBid={ggParam}
                                ggParam={ggParam}
                                bidInfo={bidInfo}
                                bidDetail={bidDetail}
                                bidActivity={bidActivity}
                                fullName={fullName}
                              />
                            </span>
                            <button
                              type="button"
                              className="btn btn-white btn-xs m-r pull-right"
                              style={{ color: "gray" }}
                              onClick={() => history.goBack()}
                            >
                              <i className="fa fa-arrow-circle-left" />
                              &nbsp;
                              {appLabel.backBtn}
                            </button>

                            <h2>{appLabel.bidInfo}</h2>
                          </div>
                          <dl className="dl-horizontal">
                            <dt>{appLabel.status}:</dt>{" "}
                            <dd>
                              {bidDetail?.BIDDING_STATUS?.toLowerCase() ===
                              "open" ? (
                                <span className="label label-primary">
                                  {bidDetail.BIDDING_STATUS}
                                </span>
                              ) : (
                                <span className="label label-danger">
                                  {bidDetail.BIDDING_STATUS}
                                </span>
                              )}
                            </dd>
                          </dl>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-5">
                          <dl className="dl-horizontal">
                            <dt>{appLabel.itemType} :</dt>{" "}
                            <dd> {bidDetail.ITEM_TYPE}</dd>
                            <dt>{appLabel.itemId} :</dt>{" "}
                            <dd>{bidDetail.ITEM_ID}</dd>
                            <dt>{appLabel.amountRequested} :</dt>{" "}
                            <dd>
                              <a className="text-navy">
                                {" "}
                                <AmntFormatFac
                                  inputValue={bidInfo.amountRequested}
                                />
                              </a>{" "}
                            </dd>
                          </dl>
                        </div>
                        <div className="col-lg-7" id="cluster_info">
                          <dl className="dl-horizontal">
                            <dt>{appLabel.bidProcessed} :</dt>{" "}
                            <dd> {bidInfo.bidProcessed} </dd>
                            <dt>{appLabel.totalNoOfBids} :</dt>{" "}
                            <dd> {bidDetail.BID_COUNT} </dd>
                            {bidInfo?.totalOutstanding === 0 ? (
                              <>
                                <dt> {appLabel.totalOutstanding} :</dt>{" "}
                                <dd>
                                  <AmntFormatFac
                                    inputValue={bidInfo.totalOutstanding}
                                  />
                                </dd>
                              </>
                            ) : (
                              <>
                                <dt style={{ color: "red" }}>
                                  {" "}
                                  {appLabel.totalOutstanding} :
                                </dt>{" "}
                                <dd style={{ color: "red" }}>
                                  {" "}
                                  <AmntFormatFac
                                    inputValue={bidInfo.totalOutstanding}
                                  />{" "}
                                </dd>
                              </>
                            )}
                          </dl>
                        </div>
                      </div>
                      <div className="hr-line-dashed" />
                      <div className="row">
                        <div className="col-lg-5">
                          <dl className="dl-horizontal">
                            <dt>{appLabel.createdBy} :</dt> <dd>{fullName}</dd>
                            <dt>{appLabel.CreatedOn} :</dt>{" "}
                            <dd> {BOM.DateTime(new Date())} </dd>
                          </dl>
                        </div>

                        <div className="col-lg-5">
                          <dl className="dl-horizontal">
                            <dt>Progress:</dt>
                            <dd>
                              <div className="progress progress-striped active m-b-sm">
                                <div
                                  style={{ width: progress.value }}
                                  className={`progress-bar progress-bar-${progress.color}`}
                                />
                              </div>
                              {/* {
                                bidInfo.totalOutstanding == 0 &&
                              } */}
                              <small>
                               {progress.text}
                              </small>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="client-detail"
                    style={{ overflowY: "auto", height: "300px" }}
                  >
                    <div className="text-center p-xs">
                      <h3>
                        <i className="fa fa-spin" />
                        &nbsp;
                        {bidActivity.length === 0
                          ? "Start bidding "
                          : appLabel.bidingActivity}
                      </h3>
                    </div>

                    <div
                      id="vertical-timeline"
                      className="vertical-container dark-timeline"
                    >
                      {bidActivity.map((item, index) => (
                        <div key={index} className="vertical-timeline-block">
                          <div className="vertical-timeline-icon gray-bg ">
                            <i className="fa fa-gavel text-success" />
                          </div>
                          <div className="vertical-timeline-content sharp-border">
                            <div className="bid-border-bottom">
                              <span className="pull-right">
                                {item.funderName}
                              </span>
                              <i className="fa fa-dot-circle-o text-success" />
                              &nbsp;{appLabel.funder}
                            </div>
                            <div className="bid-border-bottom">
                              <span className="pull-right">
                                {item.expressionStr}
                              </span>
                              <i className="fa fa-dot-circle-o text-success" />
                              &nbsp;{appLabel.calc}.
                            </div>
                            <div className="bid-border-bottom">
                              <span className="pull-right">
                                <AmntFormatFac
                                  inputValue={item.remainderAmnt}
                                />
                              </span>
                              <i className="fa fa-dot-circle-o text-success" />
                              &nbsp;{appLabel.difference}
                            </div>
                            <div className="bid-border-bottom">
                              <span className="pull-right">
                                <AmntFormatFac inputValue={item.bidAmount} />
                              </span>
                              <i className="fa fa-dot-circle-o text-success" />
                              &nbsp;{appLabel.bidAmount}
                            </div>
                            <div className="bid-border-bottom">
                              <span className="pull-right">
                                <AmntFormatFac
                                  inputValue={item.transactionFee}
                                />
                              </span>
                              <i className="fa fa-dot-circle-o text-success" />
                              &nbsp;{appLabel.transactionFee}
                            </div>

                            <div className="bid-border-bottom">
                              <span className="pull-right">{item.rate}</span>
                              <i className="fa fa-dot-circle-o text-success" />
                              &nbsp;{appLabel.rate}
                            </div>
                            <div className="bid-border-bottom">
                              <span className="pull-right">
                                {item.allotmentRate}
                              </span>
                              <i className="fa fa-dot-circle-o text-success" />
                              &nbsp;{appLabel.allotmentRate}
                            </div>
                            <div className="bid-border-bottom">
                              <span className="pull-right">
                                <AmntFormatFac
                                  inputValue={item.interestAmount}
                                />
                              </span>
                              <i className="fa fa-dot-circle-o text-success" />
                              &nbsp;{appLabel.interestAmount}
                            </div>
                            <div className="bid-border-bottom">
                              <span className="pull-right">
                                <AmntFormatFac
                                  inputValue={item.totalRepayment}
                                />
                              </span>
                              <i className="fa fa-dot-circle-o text-success" />
                              &nbsp;{appLabel.totalRepayment}
                            </div>
                            <span className="vertical-date small text-muted m-t">
                              <i className="fa fa-clock-o text-navy" />
                              &nbsp;{item.dateTimeStr}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(AutoBidPreview);

const ComponentToPrint = React.forwardRef((props, ref) => {
  const { fullName, bidInfo, bidDetail, bidActivity } = props;

  return (
    <div ref={ref}>
      <div className="row">
        <div className="col-sm-12">
          <div className="ibox">
            <div className="ibox-content contentCard">
              <div className="row m-b-lg">
                <div className="col-lg-12">
                  {/* new block */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="m-b-md">
                        <h2>{appLabel.bidInfo}</h2>
                      </div>
                      <dl className="dl-horizontal">
                        <dt>{appLabel.status}:</dt>{" "}
                        <dd>
                          <span className="label label-primary">
                            {bidDetail.BIDDING_STATUS}
                          </span>
                        </dd>
                      </dl>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-4">
                      <dl className="dl-horizontal">
                        <dt>{appLabel.itemType} :</dt>{" "}
                        <dd> {bidDetail.ITEM_TYPE}</dd>
                        <dt>{appLabel.itemId} :</dt>{" "}
                        <dd>{bidDetail.ITEM_ID}</dd>
                        <dt>{appLabel.amountRequested} :</dt>{" "}
                        <dd>
                          <a href="#" className="text-navy">
                            {" "}
                            <AmntFormatFac
                              inputValue={bidInfo.amountRequested}
                            />
                          </a>{" "}
                        </dd>
                      </dl>
                    </div>
                    <div className="col-xs-4">
                      <dl className="dl-horizontal">
                        <dt>{appLabel.bidProcessed} :</dt>{" "}
                        <dd> {bidInfo.bidProcessed} </dd>
                        <dt>{appLabel.totalNoOfBids} :</dt>{" "}
                        <dd> {bidDetail.BID_COUNT} </dd>
                        {bidInfo?.totalOutstanding === 0 ? (
                          <>
                            <dt> {appLabel.totalOutstanding} :</dt>{" "}
                            <dd>
                              <AmntFormatFac
                                inputValue={bidInfo.totalOutstanding}
                              />
                            </dd>
                          </>
                        ) : (
                          <>
                            <dt style={{ color: "red" }}>
                              {" "}
                              {appLabel.totalOutstanding} :
                            </dt>{" "}
                            <dd style={{ color: "red" }}>
                              {" "}
                              <AmntFormatFac
                                inputValue={bidInfo.totalOutstanding}
                              />{" "}
                            </dd>
                          </>
                        )}
                      </dl>
                    </div>
                    <div className="col-xs-4">
                      <dl className="dl-horizontal">
                        <dt>{appLabel.createdBy} :</dt> <dd>{fullName}</dd>
                        <dt>{appLabel.CreatedOn} :</dt>{" "}
                        <dd> {BOM.DateTime(new Date())} </dd>
                      </dl>
                    </div>
                  </div>
                  {/* <div className="hr-line-dashed" /> */}
                  {/* end new block */}
                </div>
              </div>
              <div className="hr-line-dashed" />

              <div
                className="client-detail"
                // style={{ overflowY: "auto", height: "250px" }}
              >
                <div className="text-center p-xs">
                  <h3>
                    <i className="fa fa-spin" />
                    &nbsp;
                    {bidActivity.length === 0
                      ? "Start bidding "
                      : appLabel.bidingActivity}
                  </h3>
                </div>

                <div
                  id="vertical-timeline"
                  className="vertical-container dark-timeline"
                >
                  {bidActivity.map((item, index) => (
                    <div key={index} className="vertical-timeline-block">
                      <div className="vertical-timeline-icon gray-bg">
                        <i className="fa fa-gavel text-success" />
                      </div>
                      <div className="vertical-timeline-content sharp-border-print-bid">
                        <div className="bid-border-bottom">
                          <span className="pull-right">{item.funderName}</span>
                          <i className="fa fa-dot-circle-o text-success" />
                          &nbsp;{appLabel.funder}
                        </div>
                        <div className="bid-border-bottom">
                          <span className="pull-right">
                            {item.expressionStr}
                          </span>
                          <i className="fa fa-dot-circle-o text-success" />
                          &nbsp;{appLabel.calc}.
                        </div>
                        <div className="bid-border-bottom">
                          <span className="pull-right">
                            <AmntFormatFac inputValue={item.remainderAmnt} />
                          </span>
                          <i className="fa fa-dot-circle-o text-success" />
                          &nbsp;{appLabel.difference}
                        </div>
                        <div className="bid-border-bottom">
                          <span className="pull-right">
                            {/* {item.bidAmount} */}
                            <AmntFormatFac inputValue={item.bidAmount} />
                          </span>
                          <i className="fa fa-dot-circle-o text-success" />
                          &nbsp;{appLabel.bidAmount}
                        </div>
                        <div className="bid-border-bottom">
                          <span className="pull-right">
                            <AmntFormatFac inputValue={item.transactionFee} />
                          </span>
                          <i className="fa fa-dot-circle-o text-success" />
                          &nbsp;{appLabel.transactionFee}
                        </div>

                        <div className="bid-border-bottom">
                          <span className="pull-right">{item.rate}</span>
                          <i className="fa fa-dot-circle-o text-success" />
                          &nbsp;{appLabel.rate}
                        </div>
                        <div className="bid-border-bottom">
                          <span className="pull-right">
                            {item.allotmentRate}
                          </span>
                          <i className="fa fa-dot-circle-o text-success" />
                          &nbsp;{appLabel.allotmentRate}
                        </div>
                        <div className="bid-border-bottom">
                          <span className="pull-right">
                            <AmntFormatFac inputValue={item.interestAmount} />
                          </span>
                          <i className="fa fa-dot-circle-o text-success" />
                          &nbsp;{appLabel.interestAmount}
                        </div>
                        <div className="bid-border-bottom">
                          <span className="pull-right">
                            <AmntFormatFac inputValue={item.totalRepayment} />
                          </span>
                          <i className="fa fa-dot-circle-o text-success" />
                          &nbsp;{appLabel.totalRepayment}
                        </div>
                        <span className="vertical-date small text-muted m-t">
                          <i className="fa fa-clock-o text-navy" />
                          &nbsp;{item.dateTimeStr}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
