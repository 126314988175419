import React, { useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../../services/api.action";
import { connect } from "react-redux";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../../ui-setup/RecordStatus";
import appStatic from "../../../config/appStaticData";
import RightSide from "../../ui-setup/RightSide";
import { Tooltips } from "../../layouts/Facility";

export const NewInvoiceUpload = (props) => {
  const { location, userData, configData } = props;
  const { state } = location;
  const { profileId, session, companyId } = userData;
  const { isCsd } = configData;
  const { toolTip } = appStatic;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();

  const [tempFile, setTempFile] = useState(false);
  const [docCategory, setDocCategory] = useState("");
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    setValue("batchNumber", "BA" + BOM.GenerateTimestamp())
    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);
  }, []);


  // convert to base 64 file
  const handleFileRead = async (event, setState_param) => {
    const file = event.target.files[0];
    if (!file) {
      // if file is empty
      setState_param("");
      return;
    }

    if (file.size > 5242880) {
      BOM.AlertMsg(cnt.WARNING, "File size cannot exceed 5 MB");
        $(".documentInput").val("");
        return;
    }

    // switch (file.type.toLowerCase()) {
    //   case 'application/vnd.ms-excel':
    //   case 'application/vnd.msexcel':
    //   case 'application/tsv':
    //     const base64 = await BOM.convertBase64(file);
    //     let strB64 = base64.split("base64,")[1]; //.replace(/^data:image\/[a-z]+;base64,/, "");
    //     setState_param(strB64);
    //     break;
    
    //   default:
    //     BOM.AlertMsg(cnt.WARNING, "Document must be a CSV.");
    //     $(".documentInput").val("");
    //     break;
    // }
    
    // console.log(file.type);

    const base64 = await BOM.convertBase64(file);
    let strB64 = base64.split("base64,")[1]; //.replace(/^data:image\/[a-z]+;base64,/, "");
    setState_param(strB64);
    
  };

  const uploadFile = () => {

    // setValue()

    if (!tempFile) {
      BOM.AlertMsg(cnt.WARNING, "Fields with * are required");
      return;
    }

    setDocuments([
      ...documents,
      {
        sampleName: "Document",
        fileBase64: tempFile,
        uploadType: "invoice",
      },
    ]);
    $(".documentInput").val("");
  };
  const removeItem = (index, type) => {
    switch (type.toLowerCase()) {
      case "document":
        setDocuments([
          ...documents.slice(0, index),
          ...documents.slice(index + 1),
        ]);
        break;
    }
  };
  const formSubmit = (formValues) => {
    // console.log("data", formValues);

    if (documents.length === 0) {
      BOM.AlertMsg(cnt.WARNING, "Document upload is required.");
      return;
    }
   
    formValues.fileBase64 = documents[0].fileBase64;
    formValues.uploadType = documents[0].uploadType;

    let body = {
      profileId,
      session,
      ...formValues,
    };

    // console.log(body);
    // return
    /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(
      body,
      api.CreateBatch,
      "/invoice-upload"
    );
  };

  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <RightSide />
        <ContentTop pageTitle={appLabel.newInvoiceUpload} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{appLabel.invoiceUploadInfo}</h5>
                  <div className="ibox-tools">
                    <Link to="/invoice-upload" className="btn btn-primary btn-xs">
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.generalInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.batchNumber}&nbsp;<Tooltips title={toolTip.batchNumber} />
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            disabled
                            placeholder={appLabel.batchNumber}
                            {...register("batchNumber", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.batchNumber?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.batchName}&nbsp;<Tooltips title={toolTip.batchName} />
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.batchName}
                            {...register("batchName", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.batchName?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.batchDescription}&nbsp;<Tooltips title={toolTip.batchDescription} />
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.batchDescription}
                            {...register("batchDescription", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.batchDescription?.message}
                          </span>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.otherInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <ul className="nav nav-tabs">
                      <li className="active">
                          <a
                            data-toggle="tab"
                            href="#documentInfo"
                            target="_self"
                          >
                           <i className="fa fa-file-text text-navy"  />
                            &nbsp;{appLabel.documentInfo}
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="documentInfo">
                          <div className="row" style={{ marginTop: "30px" }}>
                          <div className="col-sm-12">
                          {/* <p className="text-danger">{appLabel.maxFileUploadMsgCSV}</p> */}
                          </div>
                            <div className="col-sm-12">
                              <div className="col-md-6">
                                <div className="row form-group">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle ">
                                      {appLabel.documentUpload}&nbsp;<Tooltips title={toolTip.documentUploadPdf} />
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <input
                                      type="file"
                                      className="form-control input-sm documentInput"
                                      // accept=".csv"
                                      onChange={(e) =>
                                        handleFileRead(e, setTempFile)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row form-group">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle ">
                                      &nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    {
                                      documents.length === 0 && (
                                        <button
                                          type="button"
                                          onClick={() => uploadFile()}
                                          className="btn btn-primary btn-xs"
                                        >
                                          <i className="fa fa-upload" />
                                          &nbsp; Upload
                                        </button>
                                      )
                                    }
                                    
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="col-sm-4 al-subtitle"></label>
                                  <div className="col-sm-8">
                                    {documents.map((item, index) => (
                                      <div className="i-checks" key={index}>
                                        <label className="control-label">
                                          <i
                                            style={{ cursor: "pointer" }}
                                            className="fa fa-times-circle text-danger fa-2x"
                                            onClick={() =>
                                              removeItem(index, "document")
                                            }
                                          />
                                          &nbsp; &nbsp;
                                          <i className="fa fa-file-text fa-2x text-info" />
                                          &nbsp; {item.sampleName}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <RecordStatus {...userDetail} /> */}
        </div>
      </form>
      <RecordStatus {...BOM.NewRecordStatusdata()} />
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  configData: state.auth.configData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewInvoiceUpload);