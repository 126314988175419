const appLabel = {
  companyInformation: "Company Information",
  contactInformation: "Contact Information",
  principalContactInformation: "Principal Contact Information",
  bankInformation: "Bank Information",
  investorInfo: "Investor Info",
  roleInformation: "Role Information",
  menuInformation: "Menu Information",
  generalInfo: "General Information",
  addressInfo: "Address Information",
  otherInfo: "Additional Information",
  contactInfo: "Contact Information",
  orderInfo: "PO Information",
  materialInfo: "Material Info",
  financeInformation: "Finance Information",
  batchInfo: "Batch Information",
  taxInformation: "Tax Information",
  trustAccountInformation: "Escrow Account Information",
  bankInformation: "Bank Information",
  coBankInfo: "Company Bank Information",
  currencyInfo: "Currency Information",
  surchargeInfo: "Fee Setup Information",
  contractInformation: "Contract Information",
  orderFinancingInfo: "PO Financing Information",
  invoiceFinancingInfo: "Invoice Financing Information",
  otherPoFeesInfo: "Other Fees Information",
  feeInfo: "Fee Information",
  dateInfo: "Date Information",
  otherFeesInfo: "PO Financed Information",
  invoiceInformation: "Invoice Information",
  userInformation: "User Information",
  paymentInformation: "Payment Information",
  discountInfo: "Discount Information",
  invoiceUploadInfo: "Invoice Upload Information",
  loanInfo: "Loan Information",
  invoiceDetail: "Invoice Detail",
  newShareInvoice: "New Share Invoice",
  shareInvoice: "Share Invoice",
  sharedInvoice: "Shared Invoice",
  invoiceUpload: "Invoice Upload",
  newInvoiceUpload: "New Invoice Upload",
  invoiceUploadDetail: "Invoice Upload Detail",
  invoiceTenorMonth: "Tenor(Month)",

  usernameOrEmail: "Username or Email Address",
  username: "Username",
  password: "Password",
  btnLogin: "LogIn",
  forgotpass: "Forgot Password",
  newTo: "New to FactoreX ? ",
  welcomeTo: "Welcome to ",
  artis: "Artis",
  createAccount: "Register Now",
  currentPwd: "Current Password",
  newPwd: "New Password",
  confirmPwd: "Confirm Password",
  resetPwd: "Reset Password",
  changePwd: "Change Password",
  changePwdInfo: "Change Password Information",
  proceedBtn: "Proceed",
  continueBtn: "Continue",
  confirmEmail: "Confirm Email",
  goToLogin: "Back to Login",
  goToDashboard: "Navigate to Dashboard",
  lockScreenMessage:
    "Your are in lock screen. You need to enter your password to go back to the app.",
  unlockBtn: "Unlock",
  lockscreen: "Lock Screen",
  logout: "Log out",
  unlockBtn: "Unlock",

  poCardTop: "PO",
  invoiceCardTop: "Invoice",
  shared: "Shared",
  poVerification: "Verification",

  manageCompany: "Manage Company",
  companyDetail: "Company Detail",
  editCompany: "Edit Company",
  companyId: "Company ID",
  companyName: "Company Name",
  companyTax: "Company TIN",
  companyRegNo: "Registration No",
  vatId: "VAT ID",
  website: "Website",
  tradingName: "Trading Name",
  taxId: "Tax ID",
  companyEmail: "Email",
  companyType: "Company Type",
  companyTypeId: "Company Type ID",
  companyTypeName: "Company Type Name",
  newCompany: "New Company",
  primaryContact: "Primary Contact",
  otherContact: "Secondary Contact",
  contactName: "Contact Name",
  officePhone: "Office Phone",
  contactRole: "Contact Role",
  contactEmail: "Contact Email",
  primaryAddress: "Primary Address",
  shippingAddress: "Shipping Address",
  billingAddress: "Billing Address",
  shipBuyerAddress: "Shipping ( Buyer )",
  bilBuyerAddress: "Billing ( Buyer )",
  shipSupplierAddress: "Shipping ( Supplier )",
  bilSupplierAddress: "Billing ( Supplier )",
  sameAsPrimaryAddress: "Same as Primary Address",
  companyRegDate: "Incorporation Date",
  businessStartDate: "Business Start Date",
  businessType: "Business Type",
  principalContact: "Principal Contact",
  phoneNumber: "Phone Number",
  gender: "Gender",
  female: "Female",
  male: "Male",
  bankName: "Bank Name",
  accountName: "Account Name",
  accountNumber: "Account No",
  branchCode: "Branch Code",
  accountType: "Account Type",
  investorName: "Investor Name",
  investorId: "Investor ID",
  investorType: "Investor Type",
  business: "Business",
  individual: "Individual",
  bvnRcNumber: "BVN",
  clientTitle: "client Title",
  clientType: "Client Type",
  contactAddress: "Contact Address",
  dateOfBirth: "Date of Birth",
  principalContact: "Principal Contact",
  investedAmount: "Invested Amount",
  clientBPID: "Client BPID",
  custodianBPID: "Custodian BPID",
  userNameExemple: "( example@domain.com )",
  financeYear: "Financial Year",
  flgStatusName: "Status",
  documentType: "Doc Type",
  documentUpload: "Doc Upload",
  documentInfo: "Document Info",
  manageRole: "Manage Role",
  roleDetail: "Role Detail",
  roleId: "Role ID",
  roleName: "Role Name",
  roleDescription: "Role Description",

  roleMenu: "Role Config",
  newSubrole: "New SubRole",
  newRole: "New Role",
  editRole: "Edit Role",
  parentRoleName: "Parent Role Name",
  // newMenu: "Entity Config Setup",
  newMenu: "Role Config Setup",
  addToMenu: "Role Config Setup",
  roleMenuDetail: "Role Config Detail",
  readLess: "Minimise",
  entityTypeMenuId: "Entity ID",
  entityTypeMenuName: "Entity Name",
  entityTypeMenuDesc: "Entity Description",

  manageUser: "Manage User",
  userDetail: "User Detail",
  userId: "User ID",
  userName: "Username",
  userPassword: "Password",
  userFullName: "Full Name",
  userPhone: "Phone Number",

  newUser: "New User Setup",
  editUser: "Edit User Setup",
  userEmail: "Email",
  email: "Email",
  nuban: "Nuban",
  firstName: "First Name",
  lastName: "Last Name",
  yetToConfigure: "Yet to configure.",
  this_menu_has_no_sub_menu: "This menu has no sub menu.",
  has_all_menu_configured: "All menus are configured.",
  You_are_about_to_add_all_config:
    "You are about to add all menus configuration.",
  entityType: "Entity Type",
  entityMenuDetail: "Entity Config Detail",
  create: "Create",
  view: "View",
  edit: "Edit",
  approve: "Approve",
  reject: "Reject",
  recordStatus: "Record Status",
  authStatus: "Auth Status",
  makerId: "Maker ID",
  makerDate: "Maker Date",
  checkerId: "Checker ID",
  checkerDate: "Checker Date",
  authorized: "Authorized",
  unauthorized: "Unauthorized",
  disabled: "Disabled",
  listing: "listing",
  authorizedListing: "Authorized Listing",
  unauthorizedListing: "Unauthorized Listing",
  disabledListing: "Disabled Listing",
  backBtn: "Back",

  statutoryInfo: "Statutory Data",

  country: "Country",
  province: "Province/State",
  city: "City/Town",
  address: "Address",
  address1: "Address 1",
  address2: "Address 2",
  postCode: "GPS Address",
  choose: "Select",
  noDataInTableMsg: "No data available in table",
  noDocumentMsg: "No document available.",
  entityMenu: "Entity Config",
  entityMenuListing: "Entity Listing",
  viewMore: "View More",

  newPoOrder: "New PO",
  poNo: "PO No",
  poAmt: "PO Amount",
  from: "Buyer",
  to: "Supplier",
  buyer: "Buyer",
  supplier: "Supplier",
  supplierName: "Supplier Name",
  poAmount: "Amount",
  currency: "Currency",
  pymtOption: "Payment Option",
  frequency: "Frequency",
  firstPayDue: "Issue Date",
  lastPayDue: "Expiry Date",
  verificationStatus: "Verif Status",
  date: "Date",

  description: "Description",
  type: "Type",
  unitPrice: "Unit Price",
  quantity: "Quantity",
  manufacturer: "Manufacturer",
  managePo: "Manage PO",
  managePoToVerify: "PO to Verify",
  manageInvoiceToVerify: "Invoice to Verify",
  csdNumber: "Note Number",
  noteNumber: "Note Number",
  noteName: "Note Name",
  noteCode: "Note Code",
  registrar: "Escrow",
  lpoRefId: "LPO Ref ID",
  category: "Category",
  lomCode: "LOM Code",
  orderDetail: "PO Detail",
  shareOrder: "Shared PO",
  sharePO: "Share PO",
  fundedPO: "Funded PO",
  fundedPODetail: "Funded PO Detail",
  fundedInvoice: "Funded Invoice",
  fundedInvoiceDetail: "Funded Invoice Detail",
  sharedOrderDetail: "Shared PO Detail",
  sharedInvoiceDetail: "Shared Invoice Detail",
  financedOrder: "Quoted PO",
  financedOrderDetail: "Quoted PO Detail",
  financedInvoice: "Quoted Invoice",
  financedInvoiceDetail: "Quoted Invoice Detail",

  verifyOrder: "Confirm Verification",
  confirmVerification: "Confirm Verification",
  confirmFunding: "Confirm Funding",
  verifiedOrder: "Verified PO",
  initiateOrder: "Verify PO",
  initiateInvoice: "Verify Invoice",
  newShareOrder: "New Share PO",
  capitalRequired: "Capital Required",
  fundsAvailable: "Equity Contribution",
  logisticsOtherCosts: "Logistics Costs",
  supplierQuote: "Procurement Cost",
  orderId: "PO No",
  manageBacth: "Batch Upload",

  batchId: "Batch ID",
  batchDescription: "Batch Description",
  batchNumber: "Batch Number",
  batchName: "Batch Name",
  batchDetail: "Batch Detail",
  newBatch: "New",
  newOrderBatchUpload: "New PO Upload",
  processedBatch: "Processed",
  fileLocation: "File Location",
  uploadFile: "Upload File",
  processingStatus: "Processing Status",

  rate: "Rate (%)",
  rateAmount: "Rate Amount",
  auditTrail: "Audit Trail",
  searchCriteria: "Select Search Criteria",
  startDate: "Start Date",
  endDate: "End Date",
  search: "Search",
  result: "Search Result",
  amount: "Amount",
  effectiveDate: "Effective Date",
  waiverAllow: "Waiver Allow",
  chargeName: "Fee Name",
  chargeId: "Fee ID",
  newTax: "New Tax",

  manageTax: "Manage Tax",
  taxId: "Tax ID",
  bankId: "Bank ID",
  taxDetail: "Tax Detail",
  bankDetail: "Bank Detail",
  coBankDetail: "Company Bank Detail",
  code: "code",
  currencyCode: "Currency code",
  isoCode: "ISO Code",
  manageCurrency: "Manage Currency",
  newCurrency: "New Currency",
  newTrustAccount: "New Escrow Account",
  newBank: "New Bank",
  newCoBank: "New Company Bank",
  bankCode: "Bank Code",

  currencyDetail: "Currency Detail",
  trustAccountDetail: "Escrow Account Detail",
  currencyId: "Currency ID",
  trustAccountId: "Escrow ID",
  manageSurchage: "Fee Setup",
  manageTrustAccount: "Escrow Account",
  manageBank: "Manage Bank",
  manageCoBank: "Manage Company Bank",

  newSurcharge: "New Fee Setup",
  editSurcharge: "Edit Fee Setup",
  chargeType: "Fee Type",
  chargeDetail: "Fee Setup Detail",

  newFee: "New Charge Setup",
  editFee: "Edit Charge Setup",
  feeDetail: "Charge Setup Detail",
  feeInfo: "Charge Setup Information",

  feeName: "Charge Name",
  feeType: "Charge Type",
  feeOption: "Charge Option",
  feePercentage: "Percentage",
  isFeeSlab: "Has Tiered Fees?",
  minAmount: "Min Amount",
  maxAmount: "Max Amount",
  feeAmount: "Charge Amount",
  feeId: "Charge Id",

  manageContract: "Manage Contract",
  newContract: "New Contract",
  contractDetail: "Contract Detail",
  contractType: "Contract Type",

  id: "ID",
  value: "Value",
  obligation: "Obligation",
  counterParty: "Counter Party",
  deliveryDate: "Delivery Date",
  status: "Status",

  auditDate: "Audit Date",
  event: "Event",
  actionPerformed: "Action Performed",

  invoiceId: "Invoice ID",
  invoiceNo: "Invoice Number",
  contractId: "Contract ID",
  broadcastId: "Broadcast ID",
  fundingId: "Funding ID",
  discountId: "Discount ID",
  funder: "Funder",
  discountedCard: "Discounted",

  orderFinancing: "PO Financing",
  invoiceFinancing: "Invoice Financing",

  AmountToFinance: "Loan Amount",
  interestRate: "Indicative Interest Rate (%PA)",
  indicativeInterestRate: "Indicative Interest Rate",
  _interestRate: "Interest Rate",
  chargeRate: "Transaction Rate (%)",
  duration: "Tenor(Month)",
  trustAccount: "Escrow Account",

  insuranceFee: "Insurance Fee",
  interestAmount: "Interest Amount",
  arrangementFees: "Arrangement Fees",
  arrangementFee: "Arrangement Fee",
  otherFees: "Other Fees",
  // paymentDate: "Payment Date",
  invPymtDate: "Payment Date",
  invIssueDate: "Issue Date",
  costOfFinance: "Cost of Finance",
  totalRepayment: "Total Repayment",
  installmentAmount: "Installment Amount",
  proceed: "Confirm funding",
  manageInvoice: "Manage Invoice",
  newInvoice: "New Invoice",
  invoiceDetail: "Invoice Detail",

  verified: "Verified",
  unVerified: "Unverified",
  version: "Version 1.0",
  poweredBy: "Powered by FACTOReX",
  poweredBy: "Powered by ",
  factorEx: "FACTOReX",

  materialInfoShort: "Material",
  documentInfoShort: "Document",
  invoiceInfoShort: "Invoice",
  pOInfoShort: "PO",
  contractInfoShort: "Contract",
  financeInfoShort: "Finance",
  itemId: "Item ID",

  originalAmount: "Original Amount",
  discountAmount: "Discount Amount",
  finalAmount: "Final Amount",
  history: "History",
  amountFunded: "Funding Offer",
  amountRequested: "Amount Requested",
  requestedAmount: "Requested Amount",
  historyType: "Type",
  paymentDate: "Value Date",
  transactionDate: "Transaction Date",
  totalAmount: "Total Amount",
  funded: "Funded",
  notFunded: "Not Funded",

  dueDate: "Due date",
  discountCode: "Discount Code",
  taxType: "Tax Type",
  taxCode: "Tax Code",
  adjustment: "Adjustment",
  taxRate: "Tax Rate",
  taxableAmount: "Tax Amount",
  taxAmount: "Tax Amount",
  buyerTax: "Buyer Tax",
  supplierTax: "Supplier Tax",
  yes: "Yes",
  no: "No",

  discounted: "Discounted (%)",
  discountedAmount: "Discounted Amount",
  netInvoiceAmount: "Net Amount",
  processingFee: "Processing Fee",
  adjustment: "Adjustment",
  remitTo: "Remit To",

  statusPending: "Pending",
  statusFunded: "Funded",
  statusNew: "New",

  pendingListing: "Pending Items",
  fundedListing: "Funded Items",
  newListing: "New Items",
  shipSameAsPrimaryAddress: "Shipping Address is the same as Primary Address",
  billSameAsPrimaryAddress: "Billing Address is the same as Primary Address",
  sameAsPrimary: "Same as Primary",

  reportPOByStatus: "PO by Status",
  reportPOShared: "Shared PO",
  reportPOFinanced: "Quoted PO",
  reportInvoices: "Invoices",
  reportSharedInvoices: "Shared Invoices",
  reportDiscountedInvoices: "Quoted Invoice",
  reportContracts: "Contracts",
  report: "Report",
  criteria: "Criteria",

  poNumber: "PO Number",
  poIssuer: "PO Issuer",
  poAmount: "PO Amount",
  poIssueDate: "PO Issue Date",
  poReceipient: "PO Receipient",
  poProposeFunder: "No of Funders",
  dateFunded: "Date Funded",
  fundingAmount: "Funding Amount",
  incomeAmount: "Income Amount",

  manageProspectCompany: "Manage Prospect",
  prospectCompanyDetail: "Prospect Company Detail",
  editProspectCompany: "Edit Prospect Company",

  itemType: "Item Type",
  itemValue: "Item Value",
  coTaxRegNo: "Company/Tax Reg No",

  companyInfo: "Company",
  contactInforma: "Contact",
  saveBtn: "Save",
  cancelBtn: "Cancel",
  resendBtn: "Resend",
  validateOtpBtn: "Validate",
  contactPersonDesign: "Designation ",
  customerType: "Customer Type",
  maxSurcharge: "Maximum Transaction",

  fullName: "Full Name",
  businessIdType: "Business ID Type",

  invoiceNumber: "Invoice Number",
  invoiceIssuer: "Invoice Issuer",
  invoiceAmount: "Invoice Amount",
  invoiceIssueDate: "Invoice Issue Date",
  paymentAmount: "Payment Amount",

  discountDate: "Funded Date",
  discountPercentage: "Discount %",
  maxFileUploadMsg: "*** Maximum file size should be 5 MB ( PDF )",
  maxFileUploadMsgCSV: "*** Maximum file size should be 5 MB ( TSV )",
  // maxFileUploadMsg: "*** Maximum file size should be less than 1 MB ( PDF )",
  // maxFileUploadMsgCSV: "*** Maximum file size should be less than 1 MB ( TSV )",


  funding: "Funding",
  maturityDate: "Maturity Date",
  vendor: "Vendor",
  lpoNumber: "LPO Number",
  totalAmountPayable: "Total Amount Payable",
  verifOtp: "OTP Verification",
  enterOtp: "Enter OTP sent to ",

  loanReport: "Manage Loan Report",
  loanId: "Loan ID",
  originalLoan: "Original Loan",
  loanBalance: "Loan Balance",
  loanDetail: "Loan Detail",
  outstandingBalance: "Outstanding Balance",
  approvedOn: "Approved on",
  disbursementDate: "Disbursement Date",
  valueDate: "Value Date",
  fundingDate: "Funding Date",
  disbursedAmount: "Disbursed Amount",
  noOfRepayments: "# of Repayments",
  principal: "Principal",
  interest: "Interest",
  total: "Total",
  original: "Original",
  paid: "Paid",
  outstanding: "Outstanding",
  overdue: "Overdue",
  fees: "Fees",
  principalAmount: "Principal Amount",

  // RQ V2
  intentId: "Intent Id",
  manageSupplierIntent: "Manage Supplier Request",
  requestFunding: "Request Funding",
  public: "Public",
  private: "Private",
  selectRequestType: "Select Funding Type",
  newRqFunding: "New Request Funding",
  noteInformation: "Note Information",
  intentPoDetail: "Intent PO Detail",
  intentInvoiceDetail: "Intent Invoice Detail",
  noteYetToConfigMsg: "Note Info is not available.",
  investorLoanAmnt: "Bid Amount",
  investorInterest: "Investor Interest Rate (%PA)",
  bidInfo: "Bid Information",
  maxBand: "Max Amount",
  manageAdHoc: "Manage Ad-Hoc",
  configId: "Config Id",
  configName: "Config Name",
  configValue: "Config Value",
  editAdHoc: "Edit Ad-Hoc",
  adHocDetail: "Ad-Hoc Detail",
  adHoc: "Ad-Hoc Information",
  downloadEntityMsg: "No data available to download",
  reportDownload: "Report Download",
  publicBiding: "Public Listing",
  privateBiding: "Pivate Listing",
  manageBid: "Manage Bid",
  bidByPo: "Bid Detail",
  bidCount: "Bid Count",
  funderName: "Funder Name",
  funderRate: "Funder Rate (%)",
  funderAmnt: "Funder Amount",
  poBidListing: "PO Bid Listing",
  invoiceBidListing: "Invoice Bid Listing",
  poBid: "PO Bid",
  invoiceBid: "Invoice Bid",
  bidingActivity: "Bidding Activity",
  selectSector: "Select Sector",
  bidding: "Bidding",
  depositoryId: "Escrow Id",
  depositoryName: "Escrow Name",
  depoName: "Dep.",
  confirmBid: "Confirm Bid",
  tenor: "Tenor(Month)",
  activePo: "Active PO",
  acceptedDate: "Accepted Date",
  submissionDate: "Submission Date",
  invDueDate: "Due Date",
  invDate: "Invoice Date",
  planedPymtDate: "Planed Payment Date",
  noDataMsg: "No data available",
  selectType: "Select Request Type",
  rejectAll: "Reject All",
  autoBid: "Auto Bid",
  invRefId: "Invoice Ref ID",
  invAmount: "Amount",
  fundingType: "Funding Type",
  noDataAvailableMsg: "No data available.",
  poTitle: "PO",
  invoiceTitle: "Invoice",
  availableItem: "Shared",
  quotedItem: "Quoted",
  fundedItem: "Funded",
  rejectedItem: "Rejected",
  awaitningItem: "Awaiting Verification",
  manageFee: "Charge Setup",
  calculationInfo: "Fee Information",
  displayFeeBtn: "View Fee",
  slabInfo: "Tiered Fees Information",
  modalDoneBtn: "Done",
  modalSaveBtn: "Save",
  otherFee1: "Other Fee 1",
  otherFee2: "Other Fee 2",
  amountPayable1: "Amount Payable 1",
  amountPayable2: "Amount Payable 2",
  amountReceivable: "Amount Receivable",
  fundingFeeInfo: "Funding Fee Information",
  totalSettlementAmount: "Total Settlement Amount",
  transactionFee: "Transaction Fee",
  difference: "Difference",
  calc: "Calc.",
  bidAmount: "Bid Amount",
  cumulativeRate: "Cumulative Rate",
  systemSlogan: "Artis DealRoom",
  settlementReport: "Settlement Report",
  bidReport: "Bid Report",
  entityReport: "Entity Report",
  settlementReport: "Settlement Report",
  fundedAmount: "Funded Amount",
  accept: "Accept",
  invoice: "Invoice",
  po: "Purchase Order",
  poShort: "PO",
  processing: "Processing..",
  coRegNo: "Company Registration No",
  previewBtn:"Preview",
  contractAddendum:"Contract Addendum",
  bidInfoWarning:"**Please choose bids till the outstanding balance equals 0.",
  maximumRequestAmt:'The desired amount has reached its maximum.',
  kycProcessVrfyMsg:'Please wait while the KYC verification procedure is completed...',
  unploadCompDocMsg:', however the document could not be uploaded. Please get in touch with the System Administrator.',
  // Ts & Cs
  // agreeTsAndCs: "I agree to Terms and Conditions",
  agreeTsAndCs: "I agree",
  termConfig: "Ts and Cs Config",
  tsNotAcceptMsg:"Sorry, your company's administrator has not yet accepted the platform usage T&C's. Please contact your administrator.",
  smthWentWrong:
    'We were unable to obtain timely feedback on your request. Please retry.',
    // 'If you still haven\'t received an answer. Please double-check your network or get in touch with our system administrator.',
  closeBtn:'Close',
  nextBtn:'Next',
  printBtn:'Print',
  paragraph:`<h3>Lorem Ipsum is simply</h3>
  dummy text of the printing and typesetting industry. <strong>Lorem Ipsum has been the industry's</strong> standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic
  typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with
  <br/>
  <br/>
  <ul>
      <li>Remaining essentially unchanged</li>
      <li>Make a type specimen book</li>
      <li>Unknown printer</li>
  </ul>`,
  fundsGenerated:"Funds Generated",
  totalFundedAmount:"Total PO Funded Amount",
  pOpieChart:"Purchase Order",
  totalNoOfBids:"Total No of bids",
  totalNoofAllotment:"Total No of allotment",
  totalOutstanding:"Total Outstanding",
  allotmentRate:"Allotment (%)",
  allotmentRate:"Allotment (%)",
  bidProcessed:"Bid(s) With Allotment",
  createdBy:'Created By',
  CreatedOn:'Created On',
  funderBid:"Bids by Funders",
  status: "Status",
  automatic: "Automatic",
  manual: "Manual",
  bidMethod: "Bidding Method",
  entityNotification: "Entity Notification",
  entityNotificationDetail: "Entity Notification Detail",
  userNotification: "User Notification",
  newEntityNotification: "New Entity Notification",
  notificationInformation: "Notification Information",
  notificationId: "Notification Id",
  notificationName: "Notification Name",
  notificationDescription: "Notification Description",
  listOfNotification: "List Of Notification",
  listOfUserNotification: "List Of User Notification",
  userNotificationSetup: "User Notification Setup",
  // userSetup: "Setup/Edit Notification",
  userSetup: "Config Notification",
  addBtn: "Add",
  supplierBankName: "Supplier Bank Name",
  supplierAccountName: "Supplier Account Name",
  supplierAccountNumber: "Supplier Account Number",
  funderBankName: "Funder Bank Name",
  funderAccountName: "Funder Account Name",
  funderAccountNumber: "Funder Account Number",
  noOfFunder: "No. of Funder",
  bidRate: "Bid Rate",
  allotedAmount: "Alloted Amount",
  addBtn: "Add",
  taxPayable: "Tax Payable",
  netSettlement: "Net Settlement",
  netSettlement: "Net Settlement",
  };
  

export default appLabel;
