/**
 * @author Gedeon NdundeCode
 * @date 08/12/2021
 */
import Auth, { apis as api, headerOptions as options } from "./api.action";
import Notiflix from "notiflix";
import { cnt } from "./constant.action";
import history from "../history";
import { store } from "./redux/store";
import {
  jaidsValidator,
  localTokenSession,
} from "../components/layouts/Helpers";
import appStatic from "../config/appStaticData";
import appLabel from "../config/appLabel";

// const currentUser = {
//   profileId: store.getState().auth.userData.profileId,
//   token: store.getState().auth.userToken //localTokenSession.token,
// };

/** BM (Block Of Methods) */
export const BOM = {
  FetchReqAction: async (body, apiUrl, callback) => {
    const headers = { ...options.headers, apisessionkey: body.session };
    // LoadAlert(cnt.LOAD, "Processing");

    // ADD CHANNEL IN EVERY REQUEST
    // body.profileId = currentUser.profileId;
    body.channel = "self service";

    try {
      const res = await Auth.post(apiUrl, body, { headers });

      const response = res.data.data;

      switch (response.status.toLowerCase()) {
        case "ok":
          BOM.LoadTimeout();
          $(".footable").footable();

          callback(null, response);
          break;
        case "forbidden":
          callback(response.message, null);
          history.push("/sign-in");
          break;
        case "bad request":
          callback(
            "A bad request has occured. Please get in touch with the developer support staff.",
            null
          );
          // BOM.AlertMsg(
          //   cnt.DANGER,
          //   "A bad request has occured. Please get in touch with the developer support staff."
          // );
          break;
        default:
          if (response.message.toLowerCase() === "invalid session") {
            history.push("/sign-in");
          }
          callback(response.message, null);
          break;
      }
    } catch (error) {
      console.log("error: ", error);
      callback(appLabel.smthWentWrong, null);
    }
  },
  SendReqAction: async (body, apiUrl, redirectUrl) => {
    const headers = { ...options.headers, apisessionkey: body.session };
    // LoadAlert(cnt.LOAD, "Processing");

    // ADD CHANNEL IN EVERY REQUEST
    // body.profileId = currentUser.profileId;
    body.channel = "self service";

    try {
      const res = await Auth.post(apiUrl, body, { headers });

      const response = res.data.data;

      // check for status length
      if (response.status.length > 15) {
        BOM.AlertMsg(cnt.DANGER, appLabel.smthWentWrong);
        return;
      }

      switch (response.status.toLowerCase()) {
        case "ok":
          BOM.AlertMsg(cnt.SUCCESS, response.message);
          history.push(redirectUrl);
          break;
        case "bad request":
          BOM.AlertMsg(
            cnt.DANGER,
            "A bad request has occured. Please get in touch with the developer support staff."
          );
          break;
        case "failed":
          BOM.AlertMsg(cnt.DANGER, response.message);
          break;
        default:
          BOM.AlertMsg(cnt.DANGER, response.message);
          break;
      }
    } catch (error) {
      console.log("error: ", error);
      BOM.AlertMsg(cnt.DANGER, appLabel.smthWentWrong);
    }
  },
  SendReqActWithReload: async (body, apiUrl) => {
    const headers = { ...options.headers, apisessionkey: body.session };

    // ADD CHANNEL IN EVERY REQUEST
    body.channel = "self service";
    try {
      const res = await Auth.post(apiUrl, body, { headers });

      const response = res.data.data;
      switch (response.status.toLowerCase()) {
        case "ok":
          // BOM.AlertMsg(cnt.SUCCESS, response.message);
          Notiflix.Report.success(cnt.SUCCESS, response.message, "OK", () => {
            BOM.ReloadComponent();
          });
          break;
        case "bad request":
          BOM.AlertMsg(
            cnt.DANGER,
            "A bad request has occured. Please get in touch with the developer support staff."
          );
          break;
        case "failed":
          BOM.AlertMsg(cnt.DANGER, response.message);
          break;
        default:
          BOM.AlertMsg(cnt.DANGER, response.message);
          break;
      }
    } catch (error) {
      console.log("error: ", error);
      BOM.AlertMsg(cnt.DANGER, appLabel.smthWentWrong);
    }
  },
  SendPOReqAction: async (body, apiUrl, body2, apiUrl2, redirectUrl) => {
    const headers = { ...options.headers, apisessionkey: body.session };

    body.channel = "self service";
    body2.channel = "self service";

    try {
      const res = await Auth.post(apiUrl, body, { headers });

      const response = res.data.data;
      switch (response.status.toLowerCase()) {
        case "ok":
          body2.orderId = response.orderId;

          const res2 = await Auth.post(apiUrl2, body2, { headers });
          const response2 = res2.data.data;
          switch (response2.status.toLowerCase()) {
            case "ok":
              BOM.AlertMsg(cnt.SUCCESS, response.message);
              history.push(redirectUrl);
              break;
            case "bad request":
              BOM.AlertMsg(
                cnt.DANGER,
                "A bad request has occured. Please get in touch with the developer support staff."
              );
              break;
            case "failed":
              BOM.AlertMsg(cnt.DANGER, response2.message);
              break;
            default:
              BOM.AlertMsg(cnt.DANGER, response2.message);
              break;
          }

          break;
        case "bad request":
          BOM.AlertMsg(
            cnt.DANGER,
            "A bad request has occured. Please get in touch with the developer support staff."
          );
          break;
        case "failed":
          BOM.AlertMsg(cnt.DANGER, response.message);
          break;
        default:
          BOM.AlertMsg(cnt.DANGER, response.message);
          break;
      }
    } catch (error) {
      console.log("error: ", error);
      BOM.AlertMsg(cnt.DANGER, appLabel.smthWentWrong);
    }
  },
  SendTwoReqAction: async (body, apiUrl, body2, apiUrl2, redirectUrl) => {
    const headers = { ...options.headers, apisessionkey: body.session };

    body.channel = "self service";
    body2.channel = "self service";

    try {
      const res = await Auth.post(apiUrl, body, { headers });

      const response = res.data.data;
      switch (response.status.toLowerCase()) {
        case "ok":
          body2.docTypeId = response.companyId;

          const res2 = await Auth.post(apiUrl2, body2, { headers });
          const response2 = res2.data.data;
          switch (response2.status.toLowerCase()) {
            case "ok":
              BOM.AlertMsg(cnt.SUCCESS, response.message);
              history.push(redirectUrl);
              break;
            case "bad request":
              BOM.AlertMsg(
                cnt.DANGER,
                "A bad request has occured. Please get in touch with the developer support staff."
              );
              break;
            default:
              // BOM.AlertMsg(cnt.DANGER, response2.message);
              BOM.AlertMsg(
                cnt.WARNING,
                response.message + appLabel.unploadCompDocMsg
              );
              history.push(redirectUrl);
              break;
          }

          break;
        case "bad request":
          BOM.AlertMsg(
            cnt.DANGER,
            "A bad request has occured. Please get in touch with the developer support staff."
          );
          break;
        case "failed":
          BOM.AlertMsg(cnt.DANGER, response.message);
          break;
        default:
          BOM.AlertMsg(cnt.DANGER, response.message);
          break;
      }
    } catch (error) {
      console.log("error: ", error);
      BOM.AlertMsg(cnt.DANGER, appLabel.smthWentWrong);
    }
  },
  SessionLessReqAction: async (body, apiUrl, callback) => {
    const headers = { ...options.headers };
    // LoadAlert(cnt.LOAD, "Processing");

    try {
      const res = await Auth.post(apiUrl, body, { headers });

      const response = res.data.data;

      switch (response.status.toLowerCase()) {
        case "ok":
          BOM.LoadTimeout();
          callback(null, response);
          break;
        case "forbidden":
          callback(response.message, null);
          history.push("/sign-in");
          break;
        case "bad request":
          // BOM.AlertMsg(
          //   cnt.DANGER,
          //   "A bad request has occured. Please get in touch with the developer support staff."
          // );
          callback(
            "A bad request has occured. Please get in touch with the developer support staff. wow",
            null
          );
          break;
        case "failedpasswordexpired":
          // console.log(response.token);
          BOM.AlertMsg(cnt.DANGER, response.message);
          history.push("/reset-password/" + response.token);
          break;
        default:
          callback(response.message, null);
          break;
      }
    } catch (error) {
      console.log("error: ", error);
      callback(appLabel.smthWentWrong, null);
    }
  },
  AlertMsg: (type, message) => {
    Notiflix.Loading.remove();
    try {
      switch (type) {
        case cnt.WARNING:
          return Notiflix.Report.warning("WARNING", message, "OK");
        case cnt.SUCCESS:
          return Notiflix.Report.success("SUCCESS", message, "OK");
        case cnt.DANGER:
          return Notiflix.Report.failure("ERROR", message, "OK");
        case cnt.INFO:
          return Notiflix.Report.info("INFO", message, "OK");
        case cnt.COPIED:
          return Notiflix.Report.success("COPIED", message, "OK");
        default:
          return Notiflix.Report.warning("WARNING", message, "OK");
      }
    } catch (error) {
      console.log("error wow: ", error);
    }
  },
  AlertGoBackMsg: (type, message) => {
    Notiflix.Loading.remove();
    try {
      switch (type) {
        case cnt.WARNING:
          return Notiflix.Report.warning("WARNING", message, "OK", () => {
            BOM.GoBack();
          });
        case cnt.SUCCESS:
          return Notiflix.Report.success("SUCCESS", message, "OK", () => {
            BOM.GoBack();
          });
        case cnt.DANGER:
          return Notiflix.Report.failure("ERROR", message, "OK", () => {
            BOM.GoBack();
          });
        case cnt.INFO:
          return Notiflix.Report.info("INFO", message, "OK", () => {
            BOM.GoBack();
          });
        case cnt.COPIED:
          return Notiflix.Report.success("COPIED", message, "OK", () => {
            BOM.GoBack();
          });
        default:
          return Notiflix.Report.warning("WARNING", message, "OK", () => {
            BOM.GoBack();
          });
      }
    } catch (error) {
      console.log("error wow: ", error);
    }
  },
  GoBack: () => {
    history.goBack();
  },
  LoadAlert: (type, message) => {
    try {
      switch (type.toLowerCase()) {
        case cnt.PROCESS:
          return Notiflix.Loading.dots(message + "...");
        case cnt.LOAD:
          return Notiflix.Loading.dots(message + "...");
        default:
          return Notiflix.Loading.dots(message + "...");
      }
    } catch (error) {
      console.log("error on loadingAlert func: ", error);
    }
  },
  ActivateRightBtnAction: (formName, rightBtn, submitMethod) => {
    setTimeout(() => {
      // let validate = new jaidsValidator();
      jaidsValidator().formValidationInit(formName, rightBtn, submitMethod);
    }, 500);
  },
  GetBrowserName: () => {
    if (
      (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) !== -1
    ) {
      return "Opera";
    } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
      return "Chrome";
    } else if (navigator.userAgent.indexOf("Safari") !== -1) {
      return "Safari";
    } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
      return "Firefox";
    } else if (
      navigator.userAgent.indexOf("MSIE") !== -1 ||
      !document.documentMode == true
    ) {
      return "Internet Explorer";
    } else {
      return "Not sure!";
    }
  },
  DatatableUsage: (title = "Record") => {
    $(document).ready(function () {
      setTimeout(function () {
        $("#myTable").DataTable({
          retrieve: true,
          // destroy: true,
          dom: '<"html5buttons"B>lTfgitp',
          buttons: [
            // {
            //   extend: "copy",
            // },
            {
              extend: "csv",
              title: BOM.GenerateTimestamp(),
            },
            // {
            //   extend: "excel",
            //   title: title + "_list",
            // },
            {
              extend: "pdf",
              title: BOM.GenerateTimestamp(),
            },

            {
              extend: "print",
              customize: function (win) {
                $(win.document.body).addClass("white-bg");
                $(win.document.body).css("font-size", "10px");

                $(win.document.body)
                  .find("table")
                  .addClass("compact")
                  .css("font-size", "inherit");
              },
              title: BOM.GenerateTimestamp(),
            },
          ],
        });
      }, 700);
    });
  },
  DatatableUsage2: (title = "Record") => {
    $(document).ready(function () {
      setTimeout(function () {
        $(".myTable").DataTable({
          retrieve: true,
          // destroy: true,
          dom: '<"html5buttons"B>lTfgitp',
          buttons: [
            // {
            //   extend: "copy",
            // },
            {
              extend: "csv",
            },
            {
              extend: "excel",
              title: title + "_list",
            },
            {
              extend: "pdf",
              title: title + "_list",
            },

            {
              extend: "print",
              customize: function (win) {
                $(win.document.body).addClass("white-bg");
                $(win.document.body).css("font-size", "10px");

                $(win.document.body)
                  .find("table")
                  .addClass("compact")
                  .css("font-size", "inherit");
              },
            },
          ],
        });
      }, 700);
    });
  },
  DatatableWithNoPdfDownload: (title = "Record") => {
    $(document).ready(function () {
      setTimeout(function () {
        $("#myTable").DataTable({
          retrieve: true,
          // destroy: true,
          dom: '<"html5buttons"B>lTfgitp',
          buttons: [
            {
              extend: "csv",
              title: BOM.GenerateTimestamp(),
            },
            {
              extend: "print",
              customize: function (win) {
                $(win.document.body).addClass("white-bg");
                $(win.document.body).css("font-size", "10px");

                $(win.document.body)
                  .find("table")
                  .addClass("compact")
                  .css("font-size", "inherit");
              },
              title: BOM.GenerateTimestamp(),
            },
          ],
        });
      }, 700);
    });
  },
  DatatableWithNoPdfDownloadClass: (title = "Record") => {
    $(document).ready(function () {
      setTimeout(function () {
        $(".myTable").DataTable({
          retrieve: true,
          // destroy: true,
          dom: '<"html5buttons"B>lTfgitp',
          buttons: [
            {
              extend: "csv",
              title: BOM.GenerateTimestamp(),
            },
            {
              extend: "print",
              customize: function (win) {
                $(win.document.body).addClass("white-bg");
                $(win.document.body).css("font-size", "10px");

                $(win.document.body)
                  .find("table")
                  .addClass("compact")
                  .css("font-size", "inherit");
              },
              title: BOM.GenerateTimestamp(),
            },
          ],
        });
      }, 700);
    });
  },
  GenerateTimestamp: () => {
    // get uniq time for a document sent;
    var now = new Date();
    var timestamp = now.getFullYear().toString();
    timestamp += (now.getMonth() + 1).toString();
    timestamp += now.getDate().toString() + "";
    timestamp += now.getHours().toString();
    timestamp += now.getMinutes().toString();
    timestamp += now.getSeconds().toString();
    timestamp += now.getMilliseconds().toString();
    return timestamp;
  },
  GetUnique: (arr, comp) => {
    // store the comparison  values in array
    const unique = arr
      .map((e) => e[comp])

      // store the indexes of the unique objects
      .map((e, i, final) => final.lastIndexOf(e) === i && i)

      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  },
  RmvAccentOnly: (str_param) => {
    // str_param.replace(/\s+/g, " ");
    let mystr = str_param.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return mystr; //.replace(/'/g, "A");
  },
  GetWindowData: (key, option = "") => {
    switch (key.toLowerCase()) {
      case "origin":
        return window.location.origin + option;
      case "host":
        return window.location.host;
      case "hostname":
        return window.location.hostname;
      case "href":
        return window.location.href;
      case "pathname":
        return window.location.pathname;
      case "port":
        return window.location.port;
      case "protocol":
        return window.location.protocol;
      default:
        return window.location.origin;
    }
  },
  Uwfirst: (str) => {
    return str.toLowerCase().replace(/^\w|\s\w/g, function (letter) {
      return letter.toUpperCase();
    });
  },
  IsApproveOrReject: (status = "exit", menuData = [], description = null) => {
    /** CHECK FOR APPROV/REJECT BTN PRIVILEGES */
    // let path = appStatic.pathData.find((item) => item.URL === keyPath);

    switch (status.toLowerCase().trim()) {
      case "a":
        if (RAL.rejectAction(menuData, description)) {
          [cnt.CLEAR_RIGHT_BTN, cnt.RIGHT_REJECT_BTN].map((item) => {
            store.dispatch({
              type: item,
            });
          });
        }

        break;

      case "r":
        if (RAL.approvAction(menuData, description)) {
          [cnt.CLEAR_RIGHT_BTN, cnt.RIGHT_APPROVE_BTN].map((item) => {
            store.dispatch({
              type: item,
            });
          });
        }

        break;
      case "u":
        if (RAL.approvAction(menuData, description)) {
          [
            cnt.CLEAR_RIGHT_BTN,
            cnt.RIGHT_REJECT_BTN,
            cnt.RIGHT_APPROVE_BTN,
          ].map((item) => {
            store.dispatch({
              type: item,
            });
          });
        }

        break;
      default:
        [cnt.CLEAR_RIGHT_BTN].map((item) => {
          store.dispatch({
            type: item,
          });
        });
        break;
    }
  },
  // IsApproveOrReject: (status = "exit") => {

  //   switch (status.toLowerCase().trim()) {
  //     case "a":
  //       [cnt.CLEAR_RIGHT_BTN, cnt.RIGHT_REJECT_BTN].map((item) => {
  //         store.dispatch({
  //           type: item,
  //         });
  //       });
  //       break;

  //     case "r":
  //       [cnt.CLEAR_RIGHT_BTN, cnt.RIGHT_APPROVE_BTN].map((item) => {
  //         store.dispatch({
  //           type: item,
  //         });
  //       });
  //       break;
  //     case "u":
  //       [cnt.CLEAR_RIGHT_BTN, cnt.RIGHT_REJECT_BTN, cnt.RIGHT_APPROVE_BTN].map(
  //         (item) => {
  //           store.dispatch({
  //             type: item,
  //           });
  //         }
  //       );
  //       break;
  //     default:
  //       [cnt.CLEAR_RIGHT_BTN].map((item) => {
  //         store.dispatch({
  //           type: item,
  //         });
  //       });
  //       break;
  //   }
  // },
  GlobalApproveRejectAction: (body, reqUrl, redirectUrl, label, action) => {
    // return async () => {

    // confirm
    Notiflix.Confirm.show(
      "Are you sure?",
      action + label,
      "Yes",
      "No",
      function () {
        // Yes
        BOM.LoadAlert(cnt.LOAD, "Processing");
        BOM.SendReqAction(body, reqUrl, redirectUrl);
      },
      function () {
        // No button
      }
    );
    // };
  },
  ConfirmAction: (params, callback) => {
    const { body, action, label, apiUrl } = params;
    const headers = { ...options.headers, apisessionkey: body.session };
    body.channel = "self service";

    const confirmMessage = action + label;

    // confirm
    Notiflix.Confirm.show(
      "Are you sure?",
      confirmMessage,
      "Yes",
      "No",
      async function () {
        // Yes
        if (params.isLoader)BOM.LoadAlert(cnt.LOAD, "Processing");


        try {
          const res = await Auth.post(apiUrl, body, { headers });

          const response = res.data.data;

          switch (response.status.toLowerCase()) {
            case "ok":
              BOM.LoadTimeout();
              callback(null, response);
              break;
            case "forbidden":
              callback(response.message, null);
              history.push("/sign-in");
              break;
            case "bad request":
              callback(
                "A bad request has occured. Please get in touch with the developer support staff.",
                null
              );
              break;
            default:
              if (response.message.toLowerCase() === "invalid session") {
                history.push("/sign-in");
              }
              callback(response.message, null);
              break;
          }
        } catch (error) {
          console.log("error: ", error);
          callback(appLabel.smthWentWrong, null);
        }
      },
      function () {
        // No button
      }
    );
    // };
  },
  ApproveRejectWithReload: (body, reqUrl, label, action) => {
    // return async () => {

    // confirm
    Notiflix.Confirm.show(
      action + label,
      "Are you sure?",
      "Yes",
      "No",
      function () {
        // Yes
        BOM.LoadAlert(cnt.LOAD, "Processing");
        BOM.SendReqActWithReload(body, reqUrl);
      },
      function () {
        // No button
      }
    );
    // };
  },
  LoadTimeout: () => {
    Notiflix.Loading.remove();
  },
  SystemTimeout: (param) => {
    return setTimeout(() => {
      BOM.LoadTimeout();
      console.log(`timeout occured on: ${param.toUpperCase()} request API`);
      Notiflix.Report.warning(
        "WARNING",
        "The server encountered something unexpected that didn't allow it to complete the request. We apologize.",
        "OK"
      );
    }, 60000);
  },
  CurrentDate: () => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (yyyy + "-" + mm + "-" + dd).toString();
  },
  NewRecordStatusdata: () => {
    return {
      FLG_STATUS: "U",
      MAKER_ID: "",
      MAKER_DATE: BOM.CurrentDate(),
      CHECKER_ID: "Not Applicable",
      CHECKER_DATE: "Not Applicable",
    };
  },
  handleFileRead: async (event, setState_param) => {
    const file = event.target.files[0];
    if (!file) {
      // if file is empty
      setState_param("");
      return;
    }

    const base64 = await BOM.convertBase64(file);
    let strB64 = base64.split("base64,")[1]; //.replace(/^data:image\/[a-z]+;base64,/, "");
    setState_param(strB64);
    console.log(strB64);
  },
  convertBase64: (file) => {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  },
  DownloadAction: (base64File, fileName) => {
    fileName = BOM.ReplaceSpace(fileName, "_");
    let fileType = BOM.DetectMimeType(base64File);

    const source = "data:" + fileType + ";base64," + base64File;

    const downloadLink = document.createElement("a");

    downloadLink.href = source;
    downloadLink.download = fileName.replace(/ /g, ""); // rename your file
    downloadLink.click();
  },
  DetectMimeType: (b64) => {
    let signatures = {
      JVBERi0: "application/pdf",
      iVBORw0KGgo: "image/png",
      "/9j/4AAQSkZJRgABAQ": "image/jpeg",
    };
    for (var s in signatures) {
      if (b64.indexOf(s) === 0) {
        return signatures[s];
      }
    }
  },
  ReplaceSpace: (str, character) => {
    return str.replace(/ /g, character);
  },
  ReformatDate: (dateParam) => {
    dateParam = new Date(dateParam);
    let dd = dateParam.getDate();
    let mm = dateParam.getMonth() + 1;
    let yyyy = dateParam.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (yyyy + "-" + mm + "-" + dd).toString();
  },
  addDaysInDate: (dateParam, param = 0) => {
    const aDay = 60 * 60 * 24 * 1000; // seconds * minutes * hours * milliseconds = 1 day
    dateParam = new Date(dateParam.getTime() + aDay * param);
    let dd = dateParam.getDate();
    let mm = dateParam.getMonth() + 1;
    let yyyy = dateParam.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (yyyy + "-" + mm + "-" + dd).toString();
  },
  DateTime: (myDate) => {
    // var myDate = new Date();

    let daysList = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let monthsList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Aug",
      "Oct",
      "Nov",
      "Dec",
    ];

    let date = myDate.getDate();
    let month = monthsList[myDate.getMonth() + 1];
    let year = myDate.getFullYear();
    let day = daysList[myDate.getDay()];

    // let today = `${date} ${month} ${year}, ${day}`;
    let today = `${date} ${month} ${year}`;

    let amOrPm;
    let twelveHours = function () {
      if (myDate.getHours() > 12) {
        amOrPm = "PM";
        let twentyFourHourTime = myDate.getHours();
        let conversion = twentyFourHourTime - 12;
        return `${conversion}`;
      } else {
        amOrPm = "AM";
        return `${myDate.getHours()}`;
      }
    };
    let hours = twelveHours();
    let minutes = myDate.getMinutes();
    let second = myDate.getSeconds().toString();

    let currentTime = `${hours}:${minutes}:${second} ${amOrPm.toLowerCase()}`;

    // console.log(today + " " + currentTime);

    return today + " - " + currentTime;
  },
  HandleCreateRight: (pathData, menuData, key) => {
    let path = pathData.find((item) => item.URL === key);
    // console.log(path);

    if (path) {
      if (RAL.createAction(menuData, path.DESCRIPTION)) {
        store.dispatch({
          type: cnt.RIGHT_ADD_BTN,
        });
      }
    }
  },
  SortArrayString: (arrayParam) => {
    /** ORDER ITM S BY POSITION */
    const myData = []
      .concat(arrayParam)
      .sort((a, b) => (a.POSITION > b.POSITION ? 1 : -1));

    return myData;
  },
  ReloadComponent: () => {
    window.location.reload(false);
  },
  ValidateNaNValue: (valueParam, labelParam) => {
    valueParam = parseFloat(valueParam);
    let isNaNVal = false;

    if (Number.isNaN(valueParam)) {
      BOM.AlertMsg(cnt.WARNING, labelParam + " is not a valid number.");
      isNaNVal = true;
    }

    if (isNaNVal) return 1;
    else return 0;
  },

  formatNumber: (num, precision = 2) => {
    const map = [
      { suffix: "T", threshold: 1e12 },
      { suffix: "B", threshold: 1e9 },
      { suffix: "M", threshold: 1e6 },
      { suffix: "K", threshold: 1e3 },
      { suffix: "", threshold: 1 },
    ];

    const found = map.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
      const formatted =
        (num / found.threshold).toFixed(precision) + found.suffix;
      return formatted;
    }

    return num;
  },

  calculateSumObj: (array, property) => {
    const total = array.reduce((accumulator, object) => {
      return accumulator + parseFloat(object[property]);
    }, 0);

    return parseFloat(total);
  },
  getSpecDoc: (id, setDocuments, userData) => {
    // console.log(`get doc from definded()...`, docType);

    // // BOM.LoadAlert(cnt.LOAD, "Processing");
    // const { userData } = store.getState().auth;

    /**
     * Restrict Display creditscore type only to custodian, funder, supplier
     */
    let docType = null;
    switch (userData?.companyTypeId) {
      case "CT2022012400000": // Depository/custodian
      case "CT2022012400003": // Funder
      case "CT2022012400002": // Supplier
        docType = null;
        break;
      default:
        docType = "creditscore";
        break;
    }

    let body = {
      profileId: userData.profileId,
      id,
      session: userData.session,
    };

    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res?.docListing?.filter(
          (item) => item.DOC_TYPE?.toLowerCase() != docType?.toLowerCase()
        );
        setDocuments(objList);
      }
    });
  },
  onError:(errors) => {
    // console.log(errors);
    BOM.AlertMsg(cnt.WARNING, "Please double-check the required fields for each tab");
  }
};

/** RPB (Right Privilege Button) */
export const RPB = (props) => {
  /** CALLING GLOBAL VARIABLE */
  const globalVar = window.globalData;

  store.dispatch({
    type: cnt.CLEAR_RIGHT_BTN,
  });

  switch (props.location.pathname.toLowerCase()) {
    case "/dashboard":
      // store.dispatch({
      //   type: cnt.RIGHT_DETAIL_BTN,
      // });
      // globalVar.viewLink = {
      //   path: "/manage-role",
      //   obj: {
      //     FLG_STATUS: "U",
      //     MAKER_ID: "jared@fakemail.com",
      //   },
      // };
      // let pathArray = ["RIGHT_DETAIL_BTN", "RIGHT_EDIT_BTN"];

      // pathArray.map((item) => {
      //   store.dispatch({
      //     type: item,
      //   });
      // });
      break;
    /** THE FOLLOWINGS WILL NOT HAVE CREATE BTN */
    case "/manage-role":
    // case "/manage-surcharge":
    case "/manage-entity-menu":
    case "/manage-currency":
    case "/order-to-verify":
    case "/invoice-to-verify":
    case "/manage-contract":
    case "/shared-order":
    case "/financed-order":
    case "/shared-invoice":
    case "/financed-invoice":
    case "/manage-role-menu":
    case "/supplier-shared-order":
    case "/manage-ad-hoc":
    case "/manage-funder-bid":
    case "/manage-prospect-company":
    case "/manage-supplier-intent":
    case "/funded-order":
    case "/funded-invoice":
      // case "/manage-company":
      setTimeout(() => {
        store.dispatch({
          type: cnt.CLEAR_RIGHT_BTN,
        });
      }, 1500);

      break;
    case "/role-detail":
      [cnt.RIGHT_APPROVE_BTN, cnt.RIGHT_REJECT_BTN].map((item) => {
        // if (item !== cnt.RIGHT_APPROVE_BTN) {
        store.dispatch({
          type: item,
        });
        // }
      });
      break;
    case "/manage-company":
      globalVar.addNewLink = "new-company";
      break;
    case "/manage-prospect-company":
      globalVar.addNewLink = "fakeUrl";
      break;
    case "/manage-user":
      globalVar.addNewLink = "new-user";
      break;
    case "/manage-order":
      globalVar.addNewLink = "new-order";
      break;
    case "/order-upload":
      globalVar.addNewLink = "new-order-upload";
      break;
    case "/manage-tax":
      globalVar.addNewLink = "new-tax";
      break;
    case "/manage-currency":
      globalVar.addNewLink = "new-currency";
      break;
    case "/manage-surcharge":
      globalVar.addNewLink = "new-surcharge";
      break;
    case "/manage-trust-account":
      globalVar.addNewLink = "new-trust-account";
      break;
    case "/manage-bank":
      globalVar.addNewLink = "new-bank";
      break;
    case "/manage-company-bank":
      globalVar.addNewLink = "new-company-bank";
      break;
    case "/manage-contract":
      globalVar.addNewLink = "new-contract";
      break;
    case "/manage-invoice":
      globalVar.addNewLink = "invoice-po-clean-list";
      break;
    case "/manage-fee":
      globalVar.addNewLink = "new-fee";
      break;
    case "/invoice-upload":
      globalVar.addNewLink = "new-invoice-upload";
      break;
    case "/manage-entity-notification":
      globalVar.addNewLink = "new-entity-notification";
      break;
    case "/manage-user-notification":
      globalVar.addNewLink = "create-user-notification";
      break;
    case "/new-company":
    case "/edit-company":
    case "/new-subrole":
    case "/edit-subrole":
    case "/new-subrole-menu":
    case "/add-to-subrole-menu":
    case "/new-entity-menu":
    case "/add-to-entity-menu":
    case "/new-user":
    case "/edit-user":
    case "/new-order":
    case "/new-order-upload":
    // case "/new-share-order":
    case "/new-tax":
    case "/new-currency":
    case "/edit-charge":
    case "/new-trust-account":
    case "/new-bank":
    case "/new-company-bank":
    case "/new-contract":
    case "/new-invoice":
    case "/new-share-invoice":
    case "/new-invoice-upload":
    case "/change-password":
    case "/edit-prospect-company":
    case "/supplier-intent-po-detail":
    case "/supplier-intent-inv-detail":
    case "/new-surcharge":
    case "/edit-ad-hoc":
    // case "/manage-funder-bid-detail":
    case "/finance-invoice":
    case "/finance-order":
    case "/new-fee":
    case "/edit-fee":
    case "/new-entity-notification":
    case "/create-user-notification":
      store.dispatch({
        type: cnt.RIGHT_SAVE_BTN,
      });
      break;
  }
};

/** RAL (Right Action Logic) */
export const RAL = {
  GetStoreMenu: async () => {
    let menu = [];
    setTimeout(() => {
      // console.log(store.getState().staticData.menuData);
      menu = store.getState().staticData.menuData;
    }, 500);

    return menu;
  },
  searchAction: function (menuData, submenuDescription) {
    return this.checkSearchAction(menuData, submenuDescription);
  },
  checkSearchAction: function (myArr, keySub) {
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.search === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.search === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      // console.log('Action create :', found);
      return true;
    } else {
      console.log("Action create :", found);
      return false;
    }
  },
  createAction: function (menuData, submenuDescription) {
    return this.checkCreateAction(menuData, submenuDescription);
  },
  checkCreateAction: function (myArr, keySub) {
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.create === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.create === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      // console.log('Action create :', found);
      return true;
    } else {
      console.log("Action create :", found);
      return false;
    }
  },
  approvAction: function (menuData, submenuDescription) {
    return this.checkApprovAction(menuData, submenuDescription);
  },
  checkApprovAction: function (myArr, keySub) {
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.approve === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.approve === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      // console.log('Action approve :', found);
      return true;
    } else {
      console.log("Action approve :", found);
      return false;
    }
  },
  rejectAction: function (menuData, submenuDescription) {
    return this.checkRejectAction(menuData, submenuDescription);
  },
  checkRejectAction: function (myArr, keySub) {
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.reject === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.reject === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      // console.log('Action reject :', found);
      return true;
    } else {
      console.log("Action reject :", found);
      return false;
    }
  },
  editAction: function (menuData, submenuDescription) {
    return this.checkEditAction(menuData, submenuDescription);
  },
  checkEditAction: function (myArr, keySub) {
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.edit === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.edit === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      // console.log('Action edit :', found);
      return true;
    } else {
      // console.log('Action edit :', found);
      return false;
    }
  },
  checkViewAction: function (myArr, keySub) {
    // console.log(myArr);
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.view === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.view === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      return true;
    } else {
      console.log("Action view :", found);
      return false;
    }
  },
  viewAction: function (menuData, submenuDescription) {
    return this.checkViewAction(menuData, submenuDescription);
  },
  checkDeleteAction: function (myArr, keySub) {
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.delete === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.delete === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      return true;
    } else {
      console.log("Action delete :", found);
      return false;
    }
  },
  deleteAction: function (menuData, submenuDescription) {
    return this.checkDeleteAction(menuData, submenuDescription);
  },
  disableEnableRightAction: function (arrParam = ["CLEAR_RIGHT_BTN"]) {
    // let pathArray = arrParam ? arrParam : ["CLEAR_RIGHT_BTN"];

    arrParam.map((item) => {
      store.dispatch({
        type: item,
      });
    });
  },
};

/** initialise notiflix pop up */
export const notifInitialization = () => {
  Notiflix.Confirm.init({
    className: "notiflix-confirm",
    width: "300px",
    zindex: 4003,
    position: "center",
    distance: "10px",
    backgroundColor: "#f8f8f8",
    borderRadius: "25px",
    backOverlay: true,
    backOverlayColor: "rgba(0,0,0,0.5)",
    rtl: false,
    fontFamily: "Quicksand",
    cssAnimation: true,
    cssAnimationDuration: 300,
    cssAnimationStyle: "fade",
    plainText: true,
    titleColor: "#1ab394",
    titleFontSize: "16px",
    titleMaxLength: 34,
    messageColor: "#1e1e1e",
    messageFontSize: "14px",
    messageMaxLength: 200,
    buttonsFontSize: "15px",
    buttonsMaxLength: 34,
    okButtonColor: "#f8f8f8",
    okButtonBackground: "#1ab394",
    cancelButtonColor: "#f8f8f8",
    cancelButtonBackground: "#a9a9a9",
  });
};
