import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import Main from "../../../layouts/Main";
import appLabel from "../../../../config/appLabel";
import { BOM, RAL } from "../../../../services/defined.action";
import RecordStatus from "../../../ui-setup/RecordStatus";
import { apis as api } from "../../../../services/api.action";
import { cnt } from "../../../../services/constant.action";
import RoleForm from "./RoleForm";
import appStatic from "../../../../config/appStaticData";
import { Link } from "react-router-dom";
import ContentTop from "../../../ui-setup/ContentTop";
import { Tooltips } from "../../../layouts/Facility";
import Select from "react-select";

export const NewSubRole = (props) => {
  const { location, history, userData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId } = userData;
  const { toolTip } = appStatic;

  const [subRoleData, setSubRoleData] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    // console.log(ggParam);
    getEntitySubRole();
  }, []);
  function getEntitySubRole() {
    /** FETCH MENU */
    let body = {
      profileId,
      parentRoleName: ggParam?.ROLE_DESCRIPTION,
      parentRoleId: ggParam?.ROLE_ID,
      session,
    };
    BOM.FetchReqAction(body, api.GetEntitySubRole, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let objList = res.entityRoleListing;
        let temp = [];

        switch (objList.length) {
          case 0:
            RAL.disableEnableRightAction()
            BOM.AlertMsg(cnt.WARNING, res.message);
            break;

          default:
            objList.forEach((element) => {
              temp.push({
                value: element.SUB_ROLE_NAME,
                label: element.SUB_ROLE_NAME,
                ...element,
              });
            });

            setSubRoleData(temp);
            break;
        }
      }
    });
  }
  const formSubmit = (formValues) => {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    if (!formValues.roleName) {
      BOM.AlertMsg(cnt.WARNING, `${appLabel.roleName} is required`);
      return;
    }
    /**MAKE ADJUSTMENT FOR SOME DATA*/
    formValues.roleName = BOM.Uwfirst(formValues?.roleName);
    formValues.roleDescription = "0";

    let body = {
      profileId,
      session,
      entityId: companyId,
      roleId: state.ggParam.ROLE_ID,
      ...formValues,
    };

    // console.log(body);
    // return;
    /** SEND REQ */
    BOM.SendReqAction(body, api.CreateSubRole, "/manage-role-menu");
  };
  return (
    <Main {...props} onSaveChange={handleSubmit(formSubmit)}>
      {/* <RoleForm
        onSubmit={onSubmit}
        {...{
          pageTitle: appLabel.newSubrole,
          action: "create",
          ggParam: state.ggParam,
        }}
      /> */}

      <form autoComplete="off">
        <ContentTop pageTitle={appLabel.newSubrole} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{appLabel.roleInformation}</h5>
                  <div className="ibox-tools">
                    <Link
                      to="/manage-role-menu"
                      className="btn btn-primary btn-xs"
                    >
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content content-card-v3 panel-primary">
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle text-navy" />
                        &nbsp;
                        {appLabel.generalInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.parentRoleName}
                          </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control input-sm"
                            readOnly
                            defaultValue={ggParam?.ROLE_DESCRIPTION}
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.roleName}&nbsp;
                            <Tooltips title={toolTip.roleName} />
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-6">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            options={subRoleData}
                            onChange={(e) =>
                              setValue("roleName", e.SUB_ROLE_NAME)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <RecordStatus {...BOM.NewRecordStatusdata()} />
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(NewSubRole);
