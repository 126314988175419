import React, { useEffect, useState } from "react";
import { apis as api } from "../../../services/api.action";
import { useForm } from "react-hook-form";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { cnt } from "../../../services/constant.action";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import RecordStatus from "../../ui-setup/RecordStatus";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import RightSide from "../../ui-setup/RightSide";
import appStatic from "../../../config/appStaticData";
import { Tooltips } from "../../layouts/Facility";

export const EditProspectCompany = (props) => {
  const { location, userData, recordData, menuData } = props;
  const { state } = location;
  const { profileId, session } = userData;
  const { toolTip } = appStatic;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const dispatch = useDispatch();
  const [companyDetail, setCompanyDetail] = useState({});
  const [primaryData, setPrimaryData] = useState({});
  const [shippingData, setShippingData] = useState({});
  const [billingData, setBillingData] = useState({});
  const [isFunder, setIsFunder] = useState(false);
  const [bankData, setBankData] = useState([]);
  const [isIndividual, setIsIndividual] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [provincePrimaryData, setProvincePrimaryData] = useState([]);
  const [provinceShippingData, setProvinceShippingData] = useState([]);
  const [provinceBillingData, setProvinceBillingData] = useState([]);
  const [cityPrimaryData, setCityPrimaryData] = useState([]);
  const [cityShippingData, setCityShippingData] = useState([]);
  const [cityBillingData, setCityBillingData] = useState([]);

  const [isShipAsPrim, setIsShipAsPrim] = useState(false);
  const [isBillAsPrim, setIsBillAsPrim] = useState(false);
  const [shipAsPrimData, setShipAsPrimData] = useState({});
  const [billAsPrim, setBillAsPrimData] = useState({});

  const [companyTypeData, setCompanyTypeData] = useState([]);
  const [isBusiness, setIsBusiness] = useState(false);
  const [docCategory, setDocCategory] = useState("");
  const [tempFile, setTempFile] = useState(false);
  const currentDate = BOM.CurrentDate();

  useEffect(() => {
    // console.log(props);
    // console.log(state.ggParam);
    getCountry();
    getDetailById(state.ggParam);
    getBank();
    /** MAKE RIGHT ACTION BTN READY */
    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);
  }, []);

  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      companyName: params.COMPANY_NAME,
      session,
    };

    /** FETCH */
    BOM.FetchReqAction(body, api.GetProspectCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let companyDetail = res.companyDetail;

        setCompanyDetail(companyDetail);
        onChooseCompanyType(companyDetail);

        /** BIND SOME CURRENT VALUES */
        setValue("companyName", companyDetail.COMPANY_NAME);
        setValue("companyTypeId", companyDetail.COMPANY_TYPE_ID);
        setValue("email", companyDetail.COMPANY_EMAIL);
        setValue("website", companyDetail.COMPANY_WEBSITE);
        setValue("contactPerson", companyDetail.CONTACT_PERSON);
        setValue("officePhone", companyDetail.OFFICE_PHONE);
        setValue("contactEmail", companyDetail.CONTACT_EMAIL);
        setValue("contactPerson2", companyDetail.OTHER_CONTACT_PERSON);
        setValue("officePhone2", companyDetail.OTHER_OFFICE_PHONE);
        setValue("contactEmail2", companyDetail.OTHER_EMAIL);

        /** ITEM TYPE RECEIVED LOGIC */

        switch (companyDetail.ITEM_TYPE.toLowerCase()) {
          case "company registration no":
            setValue("regno", companyDetail.ITEM_VALUE);
            break;
          case "company tax no":
            setValue("taxNum", companyDetail.ITEM_VALUE);
            break;
        }

        //primary address
        setValue("countryId", companyDetail.COUNTRY_ID);
        setValue("provinceId", companyDetail.PROVINCE_ID);
        setValue("cityId", companyDetail.CITY_ID);
        setValue("address1", companyDetail.COMPANY_ADDRESS_1);
        setValue("address2", companyDetail.COMPANY_ADDRESS_2);
        setValue("postCode", companyDetail.COMPANY_POSTCODE);

        getGlobalProvince(companyDetail.COUNTRY_ID, setProvincePrimaryData);
        getGlobalCity(companyDetail.PROVINCE_ID, setCityPrimaryData);

        dispatch({
          type: cnt.RECORD_STATUS,
          payload: companyDetail,
        });

        //get document
        getSpecDoc(state.ggParam);
      }
    });
  };
  const getSpecDoc = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      id: params.COMPANY_ID,
      type: "company",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setDocuments(res.docListing);
      }
    });
  };
  const onChooseCompanyType = (obj) => {
    // console.log(obj);
    switch (obj.COMPANY_TYPE_ID) {
      case "CT2022012400003":
        setIsFunder(!isFunder);
        switch (obj.INVESTOR_TYPE) {
          case "individual":
            setIsIndividual(true);
            break;
          default:
            setIsIndividual(false);
            break;
        }
        break;
      default:
        setIsFunder(false);
        break;
    }
  };
  const getCountry = () => {
    let body = {
      // profileId,
      countryId: "",
      // session,
    };

    BOM.FetchReqAction(body, api.GetCountry, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setCountryData(res.countryListing);
      }
    });
  };
  const getProvince = (countryId, type) => {
    // console.log(countryId);
    if (!countryId) return;

    switch (type.toLowerCase()) {
      case "primary":
        getGlobalProvince(countryId, setProvincePrimaryData);
        break;
      case "shipping":
        getGlobalProvince(countryId, setProvinceShippingData);
        break;
      case "billing":
        getGlobalProvince(countryId, setProvinceBillingData);
        break;
    }
  };
  const getCity = (stateId, type) => {
    if (!stateId) return;
    // console.log(stateId);

    switch (type.toLowerCase()) {
      case "primary":
        const provinceObj = provincePrimaryData.find(
          (item, index) => item.ID === stateId
        );

        setValue("finsysProviceId", provinceObj.FINSYS_ID);
        // console.log(provinceObj);
        getGlobalCity(stateId, setCityPrimaryData);
        break;
      case "shipping":
        getGlobalCity(stateId, setCityShippingData);
        break;
      case "billing":
        getGlobalCity(stateId, setCityBillingData);
        break;
    }
  };
  function getGlobalProvince(countryId, setProvince) {
    // console.log(countryId);
    let body = {
      profileId,
      countryId,
      session,
    };
    BOM.FetchReqAction(body, api.GetCountry, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setProvince(res.provinceListing);
      }
    });
  }
  function getGlobalCity(stateId, setCity) {
    // console.log("wow", stateId);
    let body = {
      profileId,
      stateId,
      session,
    };

    BOM.FetchReqAction(body, api.GetCity, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        //
        setCity(res.cityListing);
      }
    });
  }
  function getCompanyType() {
    /** FETCH MENU */
    let body = {
      profileId,
      criteria: "A",
      companyTypeId: "",
      session,
    };
    BOM.FetchReqAction(body, api.GetCompanyType, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        // get all entity type except custodian
        let tempCompany = res.companyTypeListing.filter(
          (item) => item.COMPANY_TYPE_ID !== "CT2022012400000"
        );
        setCompanyTypeData(tempCompany);
      }
    });
  }
  function getBank() {
    /** FETCH MENU */
    let body = {
      profileId,
      criteria: "A",
      bankId: "",
      session,
    };
    BOM.FetchReqAction(body, api.GetBank, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setBankData(res.bankListing);
      }
    });
  }
  const onInvestorType = (key) => {
    // console.log(key);
    switch (key.toLowerCase()) {
      case "business":
        setIsBusiness(true);
        setIsIndividual(false);
        break;
      default:
        setIsIndividual(true);
        setIsBusiness(false);
        break;
    }
  };
  const handleFileRead = async (event, setState_param) => {
    const file = event.target.files[0];
    if (!file) {
      // if file is empty
      setState_param("");
      return;
    }

    if (file.size > 5242880) {
      BOM.AlertMsg(cnt.WARNING, "File size cannot exceed 5 MB");
      $(".documentInput").val("");
      return;
    }

    switch (file.type.toLowerCase()) {
      case "application/pdf":
        const base64 = await BOM.convertBase64(file);
        let strB64 = base64.split("base64,")[1]; //.replace(/^data:image\/[a-z]+;base64,/, "");
        setState_param(strB64);
        break;

      default:
        BOM.AlertMsg(cnt.WARNING, "Document type must be PDF.");
        $(".documentInput").val("");
        break;
    }

    // console.log(file.type);
  };
  const uploadFile = () => {
    // console.log(tempFile);

    const itemId = documents.find(
      (item, index) => item.docCategory === docCategory
    );

    if (itemId) {
      BOM.AlertMsg(cnt.WARNING, "No duplicate file allowed!");
      return;
    }

    if (!tempFile || !docCategory) {
      BOM.AlertMsg(cnt.WARNING, "Document Type and upload are required");
      return;
    }

    setDocuments([
      ...documents,
      {
        base64: tempFile,
        docType: "company",
        docCategory,
      },
    ]);

    $(".documentInput").val("");
    setTempFile(false);
  };
  const removeItem = (index) => {
    setDocuments([...documents.slice(0, index), ...documents.slice(index + 1)]);
  };
  const onSelectAddressAs = (key) => {
    if (
      !getValues("countryId") ||
      !getValues("provinceId") ||
      !getValues("cityId")
    ) {
      BOM.AlertMsg(
        cnt.WARNING,
        "Fields with * under Primary Address are required."
      );
      $("#shipping").prop("checked", false);
      $("#billing").prop("checked", false);
      setIsShipAsPrim(false);
      setIsBillAsPrim(false);
      return;
    }
    // console.log(isShipAsPrim);

    switch (key) {
      case "shipping":
        switch (!isShipAsPrim) {
          case true:
            setValue("shipCountryId", getValues("countryId"));
            setValue("shipProvinceId", getValues("provinceId"));
            setValue("shipCityId", getValues("cityId"));
            setValue("shipAddress1", getValues("address1"));
            setValue("shipAddress2", getValues("address2"));
            setValue("shipPostCode", getValues("postCode"));
            setIsShipAsPrim(!isShipAsPrim);
            break;

          default:
            setValue("shipCountryId", "");
            setValue("shipProvinceId", "");
            setValue("shipCityId", "");
            setValue("shipAddress1", "");
            setValue("shipAddress2", "");
            setValue("shipPostCode", "");
            setShipAsPrimData({});
            setIsShipAsPrim(!isShipAsPrim);
            break;
        }

        break;

      case "billing":
        switch (!isBillAsPrim) {
          case true:
            setValue("billCountryId", getValues("countryId"));
            setValue("billProvinceId", getValues("provinceId"));
            setValue("billCityId", getValues("cityId"));
            setValue("billAddress1", getValues("address1"));
            setValue("billAddress2", getValues("address2"));
            setValue("billPostCode", getValues("postCode"));
            setIsBillAsPrim(!isBillAsPrim);
            break;

          default:
            setValue("billCountryId", "");
            setValue("billProvinceId", "");
            setValue("billCityId", "");
            setValue("billAddress1", "");
            setValue("billAddress2", "");
            setValue("billPostCode", "");
            setIsBillAsPrim(!isBillAsPrim);
            break;
        }
        break;
    }
  };

  const formSubmit = (formValues) => {
    // console.log("data", formValues);

    if (documents.length === 0) {
      BOM.AlertMsg(cnt.WARNING, "Document upload is required.");
      return;
    }

    formValues.roleId1 = "RL0000000000001"; // DEFAULT TO SAD FOR ALL COY TYPE.
    switch (formValues.companyTypeId) {
      case "CT2022012400003": // IF FUNDER TYPE
        // formValues.companyName = formValues.investorName;
        formValues.roleId2 = "RL0000000000003"; // DEFAULT TO LAD FOR ALL COY TYPE EXCEPT BUYER
        break;
      case "CT2022012400001": // IF BUYER TYPE
        formValues.roleId2 = "RL0000000000004"; // DEFAULT TO VRF BUYER ONLY
        break;
      default: // DEFAULT TO LAD FOR ALL COY TYPE EXCEPT BUYER
        formValues.roleId2 = "RL0000000000003";
        break;
    }
    // console.log("data", formValues);
    formValues.documents = documents;

    let body = {
      profileId,
      session,
      ...formValues,
    };

    // console.log(body);
    /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(body, api.UpdateProspectCompany, "/manage-prospect-company");
  };
  // return <div/>
  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <RightSide />
        <ContentTop pageTitle={appLabel.editProspectCompany} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{appLabel.companyInformation}</h5>
                  <div className="ibox-tools">
                    <Link
                      to="/manage-prospect-company"
                      className="btn btn-primary btn-xs"
                    >
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.generalInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className={`row form-group col-md-6`}>
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.companyType} &nbsp;
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={companyDetail.COMPANY_TYPE_NAME}
                            disabled
                          />
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6 ${
                          isFunder ? "hide" : ""
                        }
                      `}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.companyName}{" "}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={companyDetail.COMPANY_NAME}
                            disabled
                          />
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6 ${
                          isFunder ? "" : "hide"
                        }`}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.investorType}{" "}
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm"
                            {...register("investorType", {
                              onChange: (e) => onInvestorType(e.target.value),
                              required: isFunder ? "Field is required." : "",
                            })}
                          >
                            <option value="">{appLabel.choose}</option>
                            {appStatic.investorType.map((item, index) => (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <span className="asterisks">
                            {errors?.investorType?.message}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6 ${
                          isFunder ? "" : "hide"
                        }`}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.investorName}{" "}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            defaultValue={companyDetail.COMPANY_NAME}
                            disabled
                          />
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6
                       ${isFunder && isIndividual ? "" : "hide"}`}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.clientTitle}{" "}
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm"
                            {...register("clientTitle", {
                              required:
                                isFunder && isIndividual
                                  ? "Field is required."
                                  : "",
                            })}
                          >
                            <option disabled>{appLabel.choose}</option>
                            {appStatic.clientTitle.map((item, index) => (
                              <option value={item.value} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <span className="asterisks">
                            {errors?.clientTitle?.message}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6 
                      ${isFunder && isIndividual ? "" : "hide"}`}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.clientType}{" "}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm"
                            {...register("clientType", {
                              required:
                                isFunder && isIndividual
                                  ? "Field is required."
                                  : "",
                            })}
                          >
                            <option disabled>{appLabel.choose}</option>
                            {appStatic.clientType.map((item, index) => (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <span className="asterisks">
                            {errors?.clientType?.message}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6
                       ${isFunder && isIndividual ? "" : "hide"}`}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.bvnRcNumber}{" "}
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.bvnRcNumber}
                            {...register("bvn", {
                              required:
                                isFunder && isIndividual
                                  ? "Field is required."
                                  : "",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.bvn?.message}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6 ${
                          isIndividual ? "hide" : ""
                        }`}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.companyRegNo}{" "}
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.companyRegNo}
                            {...register("regno", {
                              required: isIndividual
                                ? false
                                : "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.regno?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.vatId}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.vatId}
                            {...register("vatId")}
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.tradingName}{" "}
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.tradingName}
                            {...register("tradingName", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.tradingName?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.companyTax}{" "}
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.companyTax}
                            {...register("taxNum", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.taxNum?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.email}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.email}
                            {...register("email", {
                              required: "Field is required.",
                              pattern: {
                                value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                                message: "Email must be valid.",
                              },
                            })}
                          />
                          <span className="asterisks">
                            {errors?.email?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.website}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.website}
                            {...register("website")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.otherInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <ul className="nav nav-tabs">
                        <li className="active">
                          <a
                            data-toggle="tab"
                            href="#contactInfo"
                            target="_self"
                          >
                            <i className="fa fa-users text-navy" />
                            &nbsp;{appLabel.contactInfo}
                          </a>
                        </li>
                        <li>
                          <a
                            data-toggle="tab"
                            href="#addressInfo"
                            target="_self"
                          >
                            <i className="fa fa-home text-navy" />
                            &nbsp;{appLabel.addressInfo}
                          </a>
                        </li>
                        <li>
                          <a
                            data-toggle="tab"
                            href="#statutoryInfo"
                            target="_self"
                          >
                            <i className="fa fa-tag text-navy" />
                            &nbsp;{appLabel.statutoryInfo}
                          </a>
                        </li>
                        <li>
                          <a
                            data-toggle="tab"
                            href="#documentInfo"
                            target="_self"
                          >
                           <i className="fa fa-file-pdf-o text-navy"/>
                            &nbsp;{appLabel.documentInfo}
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="contactInfo">
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.primaryContact}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.contactName}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={appLabel.contactName}
                                    {...register("contactPerson", {
                                      required: "Field is required.",
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.contactPerson?.message}
                                  </span>
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.officePhone}
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={appLabel.officePhone}
                                    {...register("officePhone")}
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle ">
                                    {appLabel.contactEmail}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={appLabel.contactEmail}
                                    {...register("contactEmail", {
                                      required: "Field is required.",
                                      pattern: {
                                        value:
                                          /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                                        message: "Email must be valid.",
                                      },
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.contactEmail?.message}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.otherContact}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.contactName}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={appLabel.contactName}
                                    {...register("contactPerson2", {
                                      required: "Field is required.",
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.contactPerson2?.message}
                                  </span>
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.officePhone}
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={appLabel.officePhone}
                                    {...register("officePhone2")}
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle ">
                                    {appLabel.contactEmail}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={appLabel.contactEmail}
                                    {...register("contactEmail2", {
                                      required: "Field is required.",
                                      pattern: {
                                        value:
                                          /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                                        message: "Email must be valid.",
                                      },
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.contactEmail2?.message}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="addressInfo">
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.primaryAddress}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.country}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <select
                                    className="form-control input-sm"
                                    value={getValues("countryId")}
                                    {...register("countryId", {
                                      onChange: (e) =>
                                        getProvince(e.target.value, "primary"),
                                      required: "Field is required.",
                                    })}
                                  >
                                    <option value="">{appLabel.choose}</option>
                                    {countryData.map((item, index) => (
                                      <option value={item.ID} key={index}>
                                        {item.COUNTRY_NAME}
                                      </option>
                                    ))}
                                  </select>
                                  <span className="asterisks">
                                    {errors?.countryId?.message}
                                  </span>
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.province}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <select
                                    className="form-control input-sm"
                                    value={getValues("provinceId")}
                                    {...register("provinceId", {
                                      onChange: (e) =>
                                        getCity(e.target.value, "primary"),
                                      required: "",
                                    })}
                                  >
                                    <option value="">{appLabel.choose}</option>
                                    {provincePrimaryData.map((item, index) => (
                                      <option value={item.ID} key={index}>
                                        {item.PROVINCE_NAME}
                                      </option>
                                    ))}
                                  </select>
                                  <span className="asterisks">
                                    {errors?.provinceId?.message}
                                  </span>
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.city}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <select
                                    className="form-control input-sm"
                                    value={getValues("cityId")}
                                    {...register("cityId", {
                                      required: "Field is required.",
                                    })}
                                  >
                                    <option value="">{appLabel.choose}</option>
                                    {cityPrimaryData.map((item, index) => (
                                      <option value={item.ID} key={index}>
                                        {item.CITY_NAME}
                                      </option>
                                    ))}
                                  </select>
                                  <span className="asterisks">
                                    {errors?.cityId?.message}
                                  </span>
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.address} 1{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={`${appLabel.address} 1`}
                                    {...register("address1", {
                                      required: "Field is required.",
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.address1?.message}
                                  </span>
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.address} 2
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={`${appLabel.address} 2`}
                                    {...register("address2")}
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.postCode}
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={`${appLabel.postCode}`}
                                    {...register("postCode")}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.shippingAddress} &nbsp;{" "}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />
                              <div className="col-md-12">
                                <label className="al-subtitle">
                                  <i className="fa fa-circle text-navy" />{" "}
                                  &nbsp;
                                  {appLabel.sameAsPrimary}&nbsp;{" "}
                                  <input
                                    onClick={() => {
                                      setIsShipAsPrim(!isShipAsPrim);
                                      onSelectAddressAs("shipping");
                                    }}
                                    style={{ cursor: "pointer" }}
                                    type="checkbox"
                                    id="shipping"
                                  />
                                </label>
                                <div
                                  className="hr-line-dashed"
                                  style={{ marginTop: 0 }}
                                />
                                <div
                                  className={`alert alert-warning text-center ${
                                    isShipAsPrim ? "" : "hide"
                                  }`}
                                >
                                  <a className="alert-link">
                                    {appLabel.shipSameAsPrimaryAddress}
                                  </a>
                                </div>
                              </div>
                              <div className={`${!isShipAsPrim ? "" : "hide"}`}>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.country}{" "}
                                      <span className="asterisks">*</span>
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <select
                                      className="form-control input-sm"
                                      value={getValues("shipCountryId")}
                                      {...register("shipCountryId", {
                                        onChange: (e) =>
                                          getProvince(
                                            e.target.value,
                                            "shipping"
                                          ),
                                        required: "Field is required.",
                                      })}
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {countryData.map((item, index) => (
                                        <option value={item.ID} key={index}>
                                          {item.COUNTRY_NAME}
                                        </option>
                                      ))}
                                    </select>
                                    <span className="asterisks">
                                      {errors?.shipCountryId?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.province}{" "}
                                      <span className="asterisks">*</span>
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <select
                                      className="form-control input-sm"
                                      value={getValues("shipProvinceId")}
                                      {...register("shipProvinceId", {
                                        onChange: (e) =>
                                          getCity(e.target.value, "shipping"),
                                        required: "Field is required.",
                                      })}
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {provinceShippingData.map(
                                        (item, index) => (
                                          <option value={item.ID} key={index}>
                                            {item.PROVINCE_NAME}
                                          </option>
                                        )
                                      )}
                                    </select>
                                    <span className="asterisks">
                                      {errors?.shipProvinceId?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.city}{" "}
                                      <span className="asterisks">*</span>
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <select
                                      className="form-control input-sm"
                                      value={getValues("shipCityId")}
                                      {...register("shipCityId", {
                                        required: "Field is required.",
                                      })}
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {cityShippingData.map((item, index) => (
                                        <option value={item.ID} key={index}>
                                          {item.CITY_NAME}
                                        </option>
                                      ))}
                                    </select>
                                    <span className="asterisks">
                                      {errors?.shipCityId?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.address} 1{" "}
                                      <span className="asterisks">*</span>
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      placeholder={`${appLabel.address} 1`}
                                      {...register("shipAddress1", {
                                        required: "Field is required.",
                                      })}
                                    />
                                    <span className="asterisks">
                                      {errors?.shipAddress1?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.address} 2
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      placeholder={`${appLabel.address} 2`}
                                      {...register("shipAddress2")}
                                    />
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.postCode}
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      placeholder={`${appLabel.postCode}`}
                                      {...register("shipPostCode")}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.billingAddress}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />
                              <div className="col-md-12">
                                <label className="al-subtitle">
                                  <i className="fa fa-circle text-navy" />{" "}
                                  &nbsp;
                                  {appLabel.sameAsPrimary}&nbsp;{" "}
                                  <input
                                    onClick={() => {
                                      setIsBillAsPrim(!isBillAsPrim);
                                      onSelectAddressAs("billing");
                                    }}
                                    style={{ cursor: "pointer" }}
                                    type="checkbox"
                                    id="billing"
                                  />
                                </label>
                                <div
                                  className="hr-line-dashed"
                                  style={{ marginTop: 0 }}
                                />
                                <div
                                  className={`alert alert-warning text-center ${
                                    isBillAsPrim ? "" : "hide"
                                  }`}
                                >
                                  <a className="alert-link">
                                    {appLabel.billSameAsPrimaryAddress}
                                  </a>
                                </div>
                              </div>
                              <div className={`${!isBillAsPrim ? "" : "hide"}`}>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.country}{" "}
                                      <span className="asterisks">*</span>
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <select
                                      className="form-control input-sm"
                                      value={getValues("billCountryId")}
                                      {...register("billCountryId", {
                                        onChange: (e) =>
                                          getProvince(
                                            e.target.value,
                                            "billing"
                                          ),
                                        required: "Field is required.",
                                      })}
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {countryData.map((item, index) => (
                                        <option value={item.ID} key={index}>
                                          {item.COUNTRY_NAME}
                                        </option>
                                      ))}
                                    </select>
                                    <span className="asterisks">
                                      {errors?.billCountryId?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.province}{" "}
                                      <span className="asterisks">*</span>
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <select
                                      className="form-control input-sm"
                                      value={getValues("billProvinceId")}
                                      {...register("billProvinceId", {
                                        onChange: (e) =>
                                          getCity(e.target.value, "billing"),
                                        required: "Field is required.",
                                      })}
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {provinceBillingData.map(
                                        (item, index) => (
                                          <option value={item.ID} key={index}>
                                            {item.PROVINCE_NAME}
                                          </option>
                                        )
                                      )}
                                    </select>
                                    <span className="asterisks">
                                      {errors?.billProvinceId?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.city}{" "}
                                      <span className="asterisks">*</span>
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <select
                                      className="form-control input-sm"
                                      value={getValues("billCityId")}
                                      {...register("billCityId", {
                                        required: "Field is required.",
                                      })}
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {cityBillingData.map((item, index) => (
                                        <option value={item.ID} key={index}>
                                          {item.CITY_NAME}
                                        </option>
                                      ))}
                                    </select>
                                    <span className="asterisks">
                                      {errors?.billCityId?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.address} 1{" "}
                                      <span className="asterisks">*</span>
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      placeholder={`${appLabel.address} 1`}
                                      {...register("billAddress1", {
                                        required: "Field is required.",
                                      })}
                                    />
                                    <span className="asterisks">
                                      {errors?.billCityId?.message}
                                    </span>
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.address} 2
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      ng-model="entity.address2"
                                      placeholder={`${appLabel.address} 2`}
                                      {...register("billAddress2")}
                                    />
                                  </div>
                                </div>
                                <div className="row form-group col-md-6">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.postCode}
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      placeholder={`${appLabel.postCode}`}
                                      {...register("billPostCode")}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="statutoryInfo">
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.generalInfo}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />
                              <div
                                className={`row form-group col-md-6 ${
                                  isIndividual ? "hide" : ""
                                }`}
                              >
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.companyRegDate}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="date"
                                    className="form-control input-sm"
                                    max={`${currentDate}`}
                                    {...register("regDate", {
                                      required: isIndividual
                                        ? false
                                        : "Field is required.",
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.regDate?.message}
                                  </span>
                                </div>
                              </div>
                              <div
                                className={`row form-group col-md-6 ${
                                  isIndividual ? "" : "hide"
                                }`}
                              >
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.dateOfBirth}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="date"
                                    className="form-control input-sm"
                                    {...register("dob", {
                                      required: isIndividual
                                        ? "Field is required."
                                        : false,
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.dob?.message}
                                  </span>
                                </div>
                              </div>

                              <div
                                className={`row form-group col-md-6 ${
                                  isIndividual ? "" : "hide"
                                }`}
                              >
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.gender}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <select
                                    className="form-control input-sm"
                                    {...register("gender", {
                                      required: isIndividual
                                        ? "Field is required."
                                        : false,
                                    })}
                                  >
                                    <option value="">{appLabel.choose}</option>
                                    {appStatic.gender.map((item, index) => (
                                      <option value={item.id} key={index}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                  <span className="asterisks">
                                    {errors?.gender?.message}
                                  </span>
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.businessStartDate}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="date"
                                    className="form-control input-sm"
                                    max={`${currentDate}`}
                                    {...register("businessStartDate", {
                                      required: "Field is required.",
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.businessStartDate?.message}
                                  </span>
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.businessType}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <select
                                    className="form-control input-sm"
                                    {...register("businessType", {
                                      required: "Field is required.",
                                    })}
                                  >
                                    <option value=""> Choose</option>
                                    {appStatic.businessType.map(
                                      (item, index) => (
                                        <option value={item.value} key={index}>
                                          {item.name}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  <span className="asterisks">
                                    {errors?.businessType?.message}
                                  </span>
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.financeYear}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <select
                                    className="form-control input-sm"
                                    {...register("financialYear", {
                                      required: "Field is required.",
                                    })}
                                  >
                                    <option value=""> Choose</option>
                                    {appStatic.financeYears.map(
                                      (item, index) => (
                                        <option value={item.value} key={index}>
                                          {item.name}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  <span className="asterisks">
                                    {errors?.financialYear?.message}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.bankInformation}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />

                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.bankName}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <select
                                    className="form-control input-sm"
                                    {...register("bankId", {
                                      required: "Field is required.",
                                    })}
                                  >
                                    <option value="">{appLabel.choose}</option>
                                    {bankData.map((item, index) => (
                                      <option value={item.BANK_ID} key={index}>
                                        {item.BANK_NAME}
                                      </option>
                                    ))}
                                  </select>
                                  <span className="asterisks">
                                    {errors?.bankId?.message}
                                  </span>
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.accountNumber}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={appLabel.accountNumber}
                                    {...register("accountNumber", {
                                      required: "Field is required.",
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.accountNumber?.message}
                                  </span>
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.accountName}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={appLabel.accountName}
                                    {...register("accountName", {
                                      required: "Field is required.",
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.accountName?.message}
                                  </span>
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle ">
                                    {appLabel.branchCode}{" "}
                                    {/* <span className="asterisks">*</span> */}
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    placeholder={appLabel.branchCode}
                                    {...register("branchCode")}
                                  />
                                  {/* <span className="asterisks">
                                    {errors?.branchCode?.message}
                                  </span> */}
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.accountType}{" "}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <select
                                    className="form-control input-sm"
                                    {...register("accountType", {
                                      required: "Field is required.",
                                    })}
                                  >
                                    <option value="">{appLabel.choose}</option>
                                    {appStatic.bankAccountType.map(
                                      (item, index) => (
                                        <option value={item.id} key={index}>
                                          {item.name}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  <span className="asterisks">
                                    {errors?.accountType?.message}
                                  </span>
                                </div>
                              </div>
                              {/* <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.nuban}
                                    <span className="asterisks">*</span>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="number"
                                    className="form-control input-sm"
                                    placeholder={appLabel.nuban}
                                    {...register("nuban", {
                                      required: "Field is required.",
                                      minLength: {
                                        value: 10,
                                        message: "Not less than 10 digits",
                                      },
                                      maxLength: {
                                        value: 10,
                                        message: "Not greater than 10 digits",
                                      },
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.nuban?.message}
                                  </span>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="documentInfo">
                          <div className="row" style={{ marginTop: "30px" }}>
                          <div className="col-sm-12">
                              <p className="text-danger">
                                {appLabel.maxFileUploadMsg}
                              </p>
                            </div>
                            <div className="col-sm-12">
                              <div className="col-md-6">
                                <div className="row form-group has-errorr">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle control-label">
                                      {appLabel.documentType}{" "}<Tooltips title={toolTip.documentType} />
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <select
                                      className="form-control input-sm documentInput"
                                      onChange={(e) =>
                                        setDocCategory(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {appStatic.documntTypes.map(
                                        (item, index) => (
                                          <option value={item.id} key={index}>
                                            {item.name}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="row form-group">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle ">
                                      {appLabel.documentUpload}{" "}<Tooltips title={toolTip.documentUploadPdf} />
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <input
                                      type="file"
                                      className="form-control input-sm documentInput"
                                      accept=".pdf"
                                      onChange={(e) =>
                                        handleFileRead(e, setTempFile)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row form-group">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle ">
                                      &nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <button
                                      type="button"
                                      onClick={() => uploadFile()}
                                      className="btn btn-primary btn-xs"
                                    >
                                      <i className="fa fa-upload" />
                                      &nbsp; Upload
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="col-sm-4 al-subtitle"></label>
                                  <div className="col-sm-8">
                                    {documents.map((item, index) => (
                                      <div className="i-checks" key={index}>
                                        <label className="control-label">
                                          <i
                                            style={{ cursor: "pointer" }}
                                            className="fa fa-times-circle text-danger fa-2x"
                                            onClick={() => removeItem(index)}
                                          />
                                          &nbsp; &nbsp;
                                          <i className="fa fa-file-pdf-o fa-2x" style={{color:'#d50000'}} />
                                          &nbsp; {item.docCategory}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <RecordStatus {...userDetail} /> */}
        </div>
      </form>
      <RecordStatus {...recordData} />
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  // recordData: state.staticData.recordStatus
  recordData: state.recordStatus,
  menuData: state.staticData.menuData,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProspectCompany);
