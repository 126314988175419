import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import appLabel from "../../../config/appLabel";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import NumberFormat from "react-number-format";
import { apis as api } from "../../../services/api.action";
import { handleSkeletonFac } from "../../layouts/Facility";

//dash v2
import { Box, Alert, AlertTitle, Typography } from "@mui/material";
import PieChart from "./PieChart";
import {
  Share,
  CheckCircleOutline,
  ThumbDownOffAltOutlined,
  WatchLaterOutlined,
  FormatQuote,
} from "@mui/icons-material";
import StatBox from "./StatBox";
import ProgressCircle from "./ProgressCircle";
import DashboardLayout from "./DashboardLayout";

const InvoiceDashboard = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const [invoiceDashData, setInvoiceDashData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mockPieData, setMockPieData] = useState([]);
  const [totalFundedInv, setTotalFundedInv] = useState([]);

  useEffect(() => {
    getListByCriteria();
  }, []);
  function getListByCriteria() {
    setIsLoading(true);

    let body = {
      // profileId,
      // session,
    };

    BOM.FetchReqAction(body, api.GetGenericDashBoard, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.dashboardCount;

        let tempArr = [
          {
            name: appLabel.availableItem,
            value: objDetail.invoiceShared,
            icon: <Share sx={{ color: "#333333", fontSize: "20px" }} />,
            itemType: "Invoice",
            url: "shared-invoice",
            position: 1,
            gridColumn: "span 2",
          },
          {
            name: appLabel.quotedItem,
            value: objDetail.invQuoted,
            icon: <FormatQuote sx={{ color: "#333333", fontSize: "20px" }} />,
            itemType: "Invoice",
            url: "financed-invoice",
            position: 2,
            gridColumn: "span 2",
          },
          {
            name: appLabel.fundedItem,
            value: objDetail.invoiceFunded,
            icon: (
              <CheckCircleOutline sx={{ color: "#333333", fontSize: "20px" }} />
            ),
            itemType: "Invoice",
            url: "funded-invoice",
            position: 3,
            gridColumn: "span 2",
          },
          {
            name: appLabel.rejectedItem,
            value: objDetail.invRejected,
            icon: (
              <ThumbDownOffAltOutlined
                sx={{ color: "#333333", fontSize: "20px" }}
              />
            ),
            itemType: "Invoice",
            url: "manage-invoice",
            position: 4,
            gridColumn: "span 2",
          },
          {
            name: appLabel.awaitningItem,
            value: objDetail.awaitInvVerification,
            icon: (
              <WatchLaterOutlined sx={{ color: "#333333", fontSize: "20px" }} />
            ),
            itemType: "Invoice",
            url: "invoice-to-verify",
            position: 5,
            gridColumn: "span 3",
          },
        ];

        let tempChart = [
          {
            id: appLabel.availableItem,
            label: appLabel.availableItem,
            value: objDetail.invoiceShared,
            color: "hsl(104, 70%, 50%)",
            position: 1,
          },
          {
            id: appLabel.quotedItem,
            label: appLabel.quotedItem,
            value: objDetail.invQuoted,
            color: "hsl(162, 70%, 50%)",
            position: 2,
          },
          {
            id: appLabel.fundedItem,
            label: appLabel.fundedItem,
            value: objDetail.invoiceFunded,
            color: "hsl(291, 70%, 50%)",
            position: 3,
            gridColumn: "span 2",
          },
          {
            id: appLabel.rejectedItem,
            label: appLabel.rejectedItem,
            value: objDetail.invRejected,
            color: "hsl(229, 70%, 50%)",
            position: 4,
          },
          {
            id: appLabel.awaitningItem,
            label: appLabel.awaitningItem,
            value: objDetail.awaitInvVerification,
            color: "hsl(344, 70%, 50%)",
            position: 5,
          },
        ];

        /** INVOICE ITM S BY POSITION */
        const myData = []
          .concat(tempArr)
          .sort((a, b) => (a.position > b.position ? 1 : -1));

        setInvoiceDashData(myData);

        const myDataChart = []
          .concat(tempChart)
          .sort((a, b) => (a.position > b.position ? 1 : -1));

        // pie mock data
        setMockPieData(myDataChart);

        setIsLoading(false);
        setTotalFundedInv(objDetail.invTotalFunded);
      }
    });
  }
  const onSelectTile = (objParam) => {
    const { url } = objParam;

    history.push(`/${url}`);
  };
  return (
    <DashboardLayout
      dashboardData={invoiceDashData}
      isLoading={isLoading}
      mockPieData={mockPieData}
      totalFunded={totalFundedInv}
      isPo={false}
      onSelectTile={onSelectTile}
    />
  );
};

export default InvoiceDashboard;
