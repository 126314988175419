import React, { useEffect, useState } from "react";
import Main from "../../layouts/Main";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { apis as api } from "../../../services/api.action";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM, RAL } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import appStatic from "../../../config/appStaticData";
import { TableFacility, IboxTitleFacility, HandleSkeletonTbleFac } from "../../layouts/Facility";

const ManageAdHoc = (props) => {
  const { userData, menuData, location } = props;
  const { profileId, session, companyId } = userData;
  const globalVar = window.globalData;
  const dispatch = useDispatch();
  const { tableData } = appStatic;

  const [configData, setConfigData] = useState([]);
  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [tiles, setTiles] = useState([
    {
      title: appLabel.authorized,
      otherTitle: appLabel.authorizedListing,
      color: "primary",
      icon: "tag",
      criteria: "A",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.unauthorized,
      otherTitle: appLabel.unauthorizedListing,
      color: "warning",
      icon: "exclamation-triangle",
      criteria: "U",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.disabled,
      otherTitle: appLabel.disabledListing,
      color: "danger",
      icon: "close",
      criteria: "R",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
  ]);

  useEffect(() => {
    // console.log(props);
    getListByCriteria(tiles[0]); // get position 0 object
  }, []);

  function getListByCriteria(params) {
    // console.log(params);
    setConfigData([]);
    setTitleLeft(params.otherTitle);
    setTitleRight(params.title);
    setColor(params.color);
    setIcon(params.icon);

    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true)

    let body = {
      profileId,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetConfig, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.configListing;
        setConfigData(objList);
        BOM.DatatableUsage();
        setIsLoading(false)
      }
    });
  }

  const onSelectConfig = (objParam) => {
    // ["RIGHT_DETAIL_BTN", "RIGHT_EDIT_BTN_FAKE"].map((item) => {
    //   dispatch({
    //     type: item,
    //   });
    // });

    /** CHECK FOR RIGHT BTN PRIVILEGES */
    let path = appStatic.pathData.find(
      (item) => item.URL === location.pathname
    );
    if (path) {
      if (RAL.viewAction(menuData, path.DESCRIPTION)) {
        dispatch({
          type: cnt.RIGHT_DETAIL_BTN,
        });
      }
    }

    // if (RAL.editAction(menuData, path.DESCRIPTION)) {
    dispatch({
      type: cnt.RIGHT_EDIT_BTN,
    });
    // }

    globalVar.viewLink = {
      path: "/ad-hoc-detail",
      obj: objParam,
    };

    globalVar.editLink = {
      path: "/edit-ad-hoc",
      obj: objParam,
    };
  };

  /** RENDER JXS DATA */
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.manageAdHoc} />
      <div className="animated fadeInRightFAKE">
        <div className="row">
          <form className="m-t" autoComplete="off">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                {/* <IboxTitleFacility
                  titleLeft={titleLeft}
                  color={color}
                  icon={icon}
                  titleRight={titleRight}
                  tiles={tiles}
                /> */}
                <div className="ibox-content panel-primary contentCard">
                <HandleSkeletonTbleFac
                    classParam={`${isLoading ? "" : "hide"}`}
                  />
                  <div className={` ${isLoading ? "hide" : ""}`}>
                  {configData.length === 0 ? (
                    <div className="alert alert-warning text-center">
                      <a className="alert-link">{appLabel.noDataInTableMsg}</a>
                    </div>
                  ) : (
                    <TableFacility thData={tableData.adHoc}>
                      {configData.map((item, index) => (
                        <tr className="gradeX" key={index}>
                          <td>
                            <label className="myradio-button">
                              <input
                                type="radio"
                                name="radio"
                                onClick={() => onSelectConfig(item)}
                              />
                              <span className="label-visible">
                                <span className="myfake-radiobutton"></span>
                              </span>
                            </label>
                          </td>
                          <td>{item.CONFIG_ID}</td>
                          <td>{item.CONFIG_NAME}</td>
                          <td>{item.CONFIG_VALUE}</td>
                        </tr>
                      ))}
                    </TableFacility>
                  )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ManageAdHoc);
