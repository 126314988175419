import React, { useEffect, useState, useReducer } from "react";
import { apis as api } from "../../services/api.action";
import { connect, useDispatch } from "react-redux";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM, RAL } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import { Link } from "react-router-dom";
import RecordStatus from "../ui-setup/RecordStatus";
import NumberFormat from "react-number-format";
import {
  TooltipsFAke,
  Col12BlockFacility,
  InputTextFacility,
  TableSimpleFac,
  AmntFormatFac,
  ColBlockFac,
  getDocumentFac,
} from "../layouts/Facility";
import axios from "axios";
import Auth, { headerOptions as options } from "../../services/api.action";



export const OrderDetail = (props) => {
  const { location, userData, configData, recordData, menuData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId, companyTypeId } = userData;
  const { isCsd } = configData;
  const { toolTip, tableData } = appStatic;

  const dispatch = useDispatch();
  const [isFullPayment, setIsFullPayment] = useState(false);
  const [isCsdCheck, setIsCsdCheck] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [orderDetail, setOrderDetail] = useState({});
  const [materials, setMaterialData] = useState([]);
  const [shippingData, setShippingData] = useState({});
  const [primaryData, setPrimaryData] = useState({});
  const [billingData, setBillingData] = useState({});

  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [contractDetail, setContractDetail] = useState({});
  const [financeDetail, setFinanceDetail] = useState({});
  const [financeHistoryData, setFinanceHistoryData] = useState([]);
  const [isFunded, setIsFunded] = useState(false);
  const [isSupplier, setIsSupplier] = useState(false);
  const [isCustodian, setIsCustodian] = useState(false);
  const [noteInfoData, setNoteInfoData] = useState([]);
  let tempDocument = [];

  useEffect(() => {
    BOM.getSpecDoc(ggParam?.ORDERID, setDocuments, userData);

    handleSwitchCondition();
    getDetailById(state.ggParam);

    //   //GET 2nd DOCUMENT
    // setTimeout(() => {
    //   getSpecDoc(state?.ggParam?.ORDERID, "sharing");
    // }, 1500);
  }, []);

  const handleSwitchCondition = () => {
    if (isCsd.toLowerCase() === "y") {
      setIsCsdCheck(true);
    }
    switch (companyTypeId) {
      case "CT2022012400002": // supplier
        setIsSupplier(true);
        break;
      case "CT2022012400000": // depository/custodian
        setIsCustodian(true);
        break;
    }
  };

  const onChoosePaymentOption = (type) => {
  
    switch (type.toLowerCase()) {
      case "instalment":
        setIsFullPayment(true);
        break;
      default:
        setIsFullPayment(false);
        break;
    }
  };
  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      orderId: params.ORDERID,
      companyId,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetPo, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let orderDetail = res.purchaseOrderDetail;
        setOrderDetail(orderDetail);
        dispatch({
          type: cnt.RECORD_STATUS,
          payload: orderDetail,
        });

        onChoosePaymentOption(orderDetail.PO_PAYMENT_OPTION);
        setMaterialData(orderDetail.MATERIAL_DATA);
        setShippingData(orderDetail.SHIP_DATA);
        setBillingData(orderDetail.BILL_DATA);

        setInvoiceDetail(orderDetail.INVOICE_DATA);
        setContractDetail(orderDetail.CONTRACT_DATA);
        // setFinanceDetail(orderDetail.FUNDING_DATA);
        setFinanceDetail(orderDetail.BIDDING_DATA);
        setFinanceHistoryData(orderDetail.FUND_HISTORY_DATA);

        BOM.IsApproveOrReject(orderDetail.FLG_STATUS, menuData, "Manage PO");

        //GET DOCUMENT
        // getSpecDoc(state.ggParam);

        // getDocumentFac(profileId, session, params.ORDERID, [
        //   "purchaseorder, sharing",
        // ]);

        //disable Approve right side
        if (orderDetail.FLG_STATUS.toLowerCase() === "r") {
          RAL.disableEnableRightAction(["CLEAR_RIGHT_BTN"]);
        }

        //MAKE NOTE INFO
        setNoteInfoData([
          { name: appLabel.noteName, value: orderDetail.NOTE_NAME },
          { name: appLabel.csdNumber, value: orderDetail.CSD_NUMBER },
          { name: appLabel.noteCode, value: orderDetail.NOTE_CODE },
        ]);
      }
    });
  };

  const getSpecDoc = () => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      id: ggParam?.ORDERID,
      session,
    };

    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setDocuments(res.docListing);
      }
    });
  };

  const getSpecDocOLD = async (id, type ) => {
    

    let body = {
      profileId,
      id,
      type,
      session,
    };

    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        switch (type?.toLowerCase()) {
          case "purchaseorder":
            res.docListing.forEach((element) => {
              tempDocument.push(element);
            });
            break;

          default:
            res.docListing.forEach((element) => {
              tempDocument.push(element);
            });
            break;
        }

        setDocuments([...tempDocument]);
      }
    });
  };

  const oniInitiateOrder = () => {
    let bodyVerify = {
      profileId,
      orderId: state.ggParam.ORDERID,
      session,
    };

    // console.log(bodyVerify);
    BOM.GlobalApproveRejectAction(
      bodyVerify,
      api.InitiatePo,
      "manage-order",
      "Verification",
      "Initiate "
    );
  };

  const onApprove = () => {
    let body = {
      profileId,
      orderId: ggParam.ORDERID,
      criteria: "A",
      session,
    };

    // console.log('approve: ', body);
    BOM.GlobalApproveRejectAction(
      body,
      api.ApproveRejectPo,
      "manage-order",
      ggParam.ORDERID,
      "Approve "
    );
  };

  const onReject = () => {
    let body = {
      profileId,
      orderId: ggParam.ORDERID,
      criteria: "R",
      session,
    };

    // console.log('reject: ', body);
    BOM.GlobalApproveRejectAction(
      body,
      api.ApproveRejectPo,
      "manage-order",
      ggParam.ORDERID,
      "Reject "
    );
  };

  return (
    <Main {...props} onApprove={onApprove} onReject={onReject}>
      <ContentTop pageTitle={appLabel.orderDetail} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{appLabel.orderInfo}</h5>
                <div className="ibox-tools">
                  <Link to="/manage-order" className="btn btn-primary btn-xs">
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;
                    {appLabel.backBtn}
                  </Link>
                  &nbsp;
                  {orderDetail.FLG_STATUS === "A" &&
                    orderDetail.PO_VERIFICATION_STATUS === "A" &&
                    orderDetail.PO_COMPLETE_STATUS === "U" &&
                    orderDetail.FLG_SHARED === "N" &&
                    orderDetail.FLG_PO_EXPIRY === "N" &&
                    isSupplier && (
                      <span>
                        <Link
                          to={{
                            pathname: "/new-share-order",
                            state: {
                              ggParam: orderDetail,
                            },
                          }}
                          className="btn btn-primary btn-xs"
                        >
                          <i className="fa fa-share" />
                          &nbsp;
                          {/* {appLabel.sharePO} */}
                          {appLabel.requestFunding}
                        </Link>
                      </span>
                    )}
                  {orderDetail.FLG_STATUS === "A" &&
                    orderDetail.PO_VERIFICATION_STATUS === "U" &&
                    (isSupplier || isCustodian) && (
                      <span>
                        <button
                          type="button"
                          className="btn btn-primary btn-xs"
                          onClick={() => oniInitiateOrder()}
                        >
                          <i className="fa fa-power-off" />
                          &nbsp;
                          {appLabel.initiateOrder}
                        </button>
                      </span>
                    )}
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.generalInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.poNo}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.ORDERID}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.from}&nbsp;
                          <TooltipsFAke title={toolTip.from} />
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.FROM_COMPANY_NAME}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="row form-group col-md-6">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.poAmount}&nbsp;
                          <TooltipsFAke title={toolTip.poAmount} />
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={orderDetail.PO_AMOUNT}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.to}&nbsp;
                          <TooltipsFAke title={toolTip.to} />
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.TO_COMPANY_NAME}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.currency}&nbsp;
                          <TooltipsFAke title={toolTip.currency} />
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.PO_CCY}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.pymtOption}&nbsp;
                          <TooltipsFAke title={toolTip.pymtOption} />
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.PO_PAYMENT_OPTION}
                          disabled
                        />
                      </div>
                    </div>
                    <div
                      className={`row form-group col-md-6 ${
                        isFullPayment ? "" : "hide"
                      }`}
                    >
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.frequency}
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.PO_INSTALLMENT_FREQ}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.firstPayDue}{" "}
                          <TooltipsFAke title={toolTip.firstPayDue} />
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.PO_FIRSTPAY_DUE}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.lastPayDue}{" "}
                          <TooltipsFAke title={toolTip.lastPayDue} />
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.PO_LASTPAY_DUE}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.registrar}{" "}
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.REGISTRAR}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.lpoRefId}{" "}
                          <TooltipsFAke title={toolTip.lpoRefId} />
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.LPO_REFERENCE_ID}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <Col12BlockFacility topTitle={appLabel.noteInformation}>
                    {orderDetail.FLG_DEPOSITORY_NOTE === "Y" ? (
                      noteInfoData.map((item, index) => (
                        <InputTextFacility
                          key={index}
                          inputLabel={item.name}
                          inputValue={item.value}
                        />
                      ))
                    ) : (
                      <div className="alert alert-warning text-center">
                        <a className="alert-link">
                          {appLabel.noteYetToConfigMsg}
                        </a>
                      </div>
                    )}
                  </Col12BlockFacility>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.addressInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="col-md-6">
                      <div className="well">
                        <address>
                          <h4>
                            {" "}
                            <i className="fa fa-building text-navy"></i>
                            &nbsp; {appLabel.shippingAddress}
                          </h4>{" "}
                          <br />
                          <i className="fa fa-circle text-navy"></i> &nbsp;{" "}
                          {shippingData.SHIPPING_ADDRESS_1},{" "}
                          {shippingData.SHIPPING_CITY_NAME}
                          <br />
                          {shippingData.SHIPPING_PROVINCE_NAME},{" "}
                          {shippingData.SHIPPING_COUNTRY_NAME}{" "}
                          {shippingData.SHIPPING_POST_CODE}
                        </address>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="well">
                        <address>
                          <h4>
                            {" "}
                            <i className="fa fa-building text-navy"></i>
                            &nbsp;{appLabel.billingAddress}
                          </h4>{" "}
                          <i className="fa fa-circle text-navy"></i>
                          &nbsp;{billingData.BILLING_ADDRESS_1},{" "}
                          {billingData.BILLING_CITY_NAME}
                          <br />
                          {billingData.BILLING_PROVINCE_NAME},{" "}
                          {billingData.BILLING_COUNTRY_NAME}{" "}
                          {billingData.BILLING_POST_CODE}
                        </address>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.otherInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <ul className="nav nav-tabs">
                      <li className="active">
                        <a
                          data-toggle="tab"
                          href="#materialInfo"
                          target="_self"
                        >
                          <i className="fa fa-tag text-navy" />
                          &nbsp;{appLabel.materialInfoShort}&nbsp;
                          <TooltipsFAke title={toolTip.materialInfo} />
                        </a>
                      </li>
                      <li>
                        <a
                          data-toggle="tab"
                          href="#documentInfo"
                          target="_self"
                        >
                          <i className="fa fa-file-pdf-o text-navy" />
                          &nbsp;{appLabel.documentInfoShort}&nbsp;
                          <TooltipsFAke title={toolTip.documentInfo} />
                        </a>
                      </li>

                      <li>
                        <a data-toggle="tab" href="#invoiceInfo" target="_self">
                          <i className="fa fa-file-text text-navy" />
                          &nbsp;{appLabel.invoiceInfoShort}
                        </a>
                      </li>
                      <li>
                        <a
                          data-toggle="tab"
                          href="#contractInfo"
                          target="_self"
                        >
                          <i className="fa fa-file-text text-navy" />
                          &nbsp;{appLabel.contractInfoShort}
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#financeInfo" target="_self">
                          <i className="fa fa-money text-navy" />
                          &nbsp;{appLabel.financeInfoShort}
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane active" id="materialInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          <div className="col-sm-12">
                            <div className="table-responsive">
                              {materials.length === 0 ? (
                                <div className="alert alert-warning text-center">
                                  <a className="alert-link">
                                    {appLabel.noDataInTableMsg}
                                  </a>
                                </div>
                              ) : (
                                <table
                                  className="footable table table-stripped toggle-arrow-tiny"
                                  data-page-size="10"
                                  data-filter="#filter"
                                >
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      {/* <th>{appLabel.manufacturer}</th> */}
                                      <th>{appLabel.description}</th>
                                      <th>{appLabel.unitPrice}</th>
                                      <th>{appLabel.quantity}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {materials.map((item, index) => (
                                      <tr className="gradeX" key={index}>
                                        <td>{index + 1}</td>
                                        {/* <td>{item.MANUFACTURER}</td> */}
                                        <td>{item.MAT_DESC}</td>
                                        <td>
                                          <NumberFormat
                                            value={item.UNIT_PRICE}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix={item.PO_CCY + " "}
                                          />
                                        </td>
                                        <td>{item.QUANTITY}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td colSpan="8">
                                        <ul className="pagination pull-right"></ul>
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="documentInfo">
                        <div className="row" style={{ marginTop: "30px" }}>
                          <div className="col-sm-12">
                            {documents.length === 0 ? (
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDocumentMsg}
                                </a>
                              </div>
                            ) : (
                              <div>
                                {documents.map((item, index) => (
                                  <div className="col-md-4" key={index}>
                                    <div className="file-box">
                                      <div className="file">
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            BOM.DownloadAction(
                                              item.DOC_OBJECT,
                                              item.DOC_ID +
                                                " " +
                                                item.DOC_CATEGORY.toLowerCase()
                                            )
                                          }
                                        >
                                          <span className="corner" />
                                          <div className="icon">
                                            <i
                                              className="fa fa-file-pdf-o"
                                              style={{ color: "#d50000" }}
                                            />
                                          </div>
                                          <div className="file-name">
                                            {item.DOC_CATEGORY}
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="invoiceInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          {Object.keys(invoiceDetail).length === 0 ? (
                            <div className="col-sm-12">
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDataInTableMsg}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-12">
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.invoiceId}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={invoiceDetail.INV_ID}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.to}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={
                                      invoiceDetail.SUPPLIER_COMPANY
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.invAmount}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <NumberFormat
                                    className="form-control input-sm"
                                    value={invoiceDetail.ORIGINAL_AMOUNT}
                                    displayType="text"
                                    disabled
                                    thousandSeparator={true}
                                    prefix={""}
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.currency}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={invoiceDetail.INV_CCY}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.startDate}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={
                                      invoiceDetail.CONTR_START_DATE
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="tab-pane" id="contractInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          {Object.keys(contractDetail).length === 0 ? (
                            <div className="col-sm-12">
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDataInTableMsg}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-12">
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.contractId}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={contractDetail.PO_CONTRACT_ID}
                                    disabled
                                  />
                                </div>
                              </div>

                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.type}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={contractDetail.TRANS_TYPE}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.value}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <NumberFormat
                                    className="form-control input-sm"
                                    value={contractDetail.CONTR_VALUE}
                                    displayType="text"
                                    disabled
                                    thousandSeparator={true}
                                    prefix={""}
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.currency}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={contractDetail.CONTR_CCY}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.startDate}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={
                                      contractDetail.CONTR_START_DATE
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="tab-pane" id="financeInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          <ColBlockFac topTitle={appLabel.history}>
                            {financeHistoryData.length === 0 ? (
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDataInTableMsg}
                                </a>
                              </div>
                            ) : (
                              <TableSimpleFac thData={tableData.historyBidList}>
                                {financeHistoryData.map((item, index) => (
                                  <tr className="gradeX" key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.ITEM_ID}</td>
                                    <td>{item.ITEM_TYPE}</td>
                                    <td>
                                      <AmntFormatFac
                                        inputValue={item.AMOUNT_REQUESTED}
                                        currency={item.CCY}
                                      />
                                    </td>
                                    <td>
                                      <AmntFormatFac
                                        inputValue={item.AMOUNT_FUNDED}
                                        currency={item.CCY}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </TableSimpleFac>
                            )}
                          </ColBlockFac>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RecordStatus {...recordData} />
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
