import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const ContentTop = ({pageTitle}) => {

  useEffect(()=>{
  }, [])
  return (
    <div>
      <div className="row wrapper border-bottom white-bg page-heading" style={{marginBottom: '20px'}}>
        <div className="col-lg-10">
          <h2>{pageTitle}</h2>
          <ol className="breadcrumb">
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="active">
              <strong>{pageTitle}</strong>
            </li>
          </ol>
        </div>
        <div className="col-lg-2"></div>
      </div>
    </div>
  );
};

export default ContentTop
