import React, { useEffect, useState } from "react";
import { apis as api } from "../../../services/api.action";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { cnt } from "../../../services/constant.action";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import history from "../../../history";
import RecordStatus from "../../ui-setup/RecordStatus";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";

export const DetailUser = (props) => {
  const { location, userData, recordData, menuData} = props;
  const { state } = location;
  const { profileId, session, companyId } = userData;
  const dispatch = useDispatch();
  const [userDetail, setUserDetail] = useState({});

  useEffect(() => {
    // console.log(state.ggParam);
    getDetailById(state.ggParam);
  }, []);

  const getDetailById= (params)=> {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      companyId:params.COMPANY_ID,
      userId: params.USERID,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetUser, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let userDetail = res.userDetail;

        setUserDetail(userDetail);
        dispatch({
          type: cnt.RECORD_STATUS,
          payload: userDetail,
        });
        // getCountry();
        // getProvince(userDetail.COUNTRY_ID)
        // getCity(userDetail.CITY_ID)

        //handle right side
        BOM.IsApproveOrReject(userDetail.FLG_STATUS, menuData, "User Setup");
      }
    });
  }

  const onApprove = () => {
    let bodyApprove = {
      profileId,
      userId: state.ggParam.USERID,
      criteria: "A",
      session,
    };

    // console.log(bodyApprove);
    BOM.GlobalApproveRejectAction(
      bodyApprove,
      api.ApproveRejectUser,
      "manage-user",
      state.ggParam.USER_FIRSTNAME,
      "Approve "
    );
  }
  const onReject = () => {
    let bodyDisable = {
      profileId,
      userId: state.ggParam.USERID,
      criteria: "R",
      session,
    };

    // console.log(bodyDisable);
    BOM.GlobalApproveRejectAction(
      bodyDisable,
      api.ApproveRejectUser,
      "manage-user",
      state.ggParam.USER_FIRSTNAME,
      "Reject "
    );
  }
  
  return (
   <Main {...props} onApprove={onApprove} onReject={onReject}>
      <ContentTop pageTitle={appLabel.userDetail} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{appLabel.userInformation}</h5>
                <div className="ibox-tools">
                  <Link to="/manage-user" className="btn btn-primary btn-xs">
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;
                    {appLabel.backBtn}
                  </Link>
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                  <div className="col-sm-3">
                    <div className="text-center">
                      <img
                        src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
                        className="avatar img-circle img-thumbnail"
                        alt="avatar"
                      />
                    </div>
                    <br />
                  </div>
                  <div className="col-sm-9">
                    <ul className="nav nav-tabs">
                      <li className="active">
                        <a
                          data-toggle="tab"
                          href="#personalInfo"
                          target="_self"
                        >
                          <i className="fa fa-user text-navy" />
                          &nbsp;Personal
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#contactInfo" target="_self">
                          <i className="fa fa-wechat text-navy" />
                          &nbsp;Contact
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#addressInfo" target="_self">
                          <i className="fa fa-home text-navy" />
                          &nbsp;Address
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#roleInfo" target="_self">
                          <i className="fa fa-tag text-navy" />
                          &nbsp;Role
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane active" id="personalInfo">
                        <div className="row" style={{ marginTop: 20 }}>
                          <div className="col-sm-12">
                            <label className="al-heading">
                              <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                              {appLabel.generalInfo}
                            </label>
                            <div
                              className="hr-line-dashed"
                              style={{ marginTop: 0 }}
                            />
                            <div className="form-group col-md-6">
                              <label className="al-subtitle">
                                {appLabel.userId}
                              </label>
                              <input
                                type="text"
                                className="form-control input-sm"
                                defaultValue={userDetail.USERID}
                                disabled
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label className="al-subtitle">
                                {appLabel.firstName}
                              </label>
                              <input
                                type="text"
                                className="form-control input-sm"
                                defaultValue={userDetail.USER_FIRSTNAME}
                                disabled
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label className="al-subtitle">
                                {appLabel.lastName}
                              </label>
                              <input
                                type="text"
                                className="form-control input-sm"
                                defaultValue={userDetail.USER_LASTNAME}
                                disabled
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label className="al-subtitle">
                                {appLabel.userName}
                              </label>
                              <input
                                type="email"
                                className="form-control input-sm"
                                defaultValue={userDetail.USERNAME}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <label className="al-heading">
                              <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                              {appLabel.companyInformation}
                            </label>
                            <div
                              className="hr-line-dashed"
                              style={{ marginTop: 0 }}
                            />
                            <div className="form-group col-md-6">
                              <label className="al-subtitle">
                                {appLabel.companyType}
                              </label>
                              <input
                                type="text"
                                className="form-control input-sm"
                                defaultValue={userDetail.COMPANY_TYPE_NAME}
                                disabled
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label className="al-subtitle">
                                {appLabel.companyName}
                              </label>
                              <input
                                type="text"
                                className="form-control input-sm"
                                defaultValue={userDetail.COMPANY_NAME}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="contactInfo">
                        <div className="row" style={{ marginTop: 20 }}>
                          <div className="form-group col-md-6">
                            <label className="al-subtitle">
                              {appLabel.userEmail}
                            </label>
                            <input
                              type="email"
                              className="form-control input-sm"
                              defaultValue={userDetail.USER_EMAIL}
                              disabled
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label className="al-subtitle">
                              {appLabel.userPhone}
                            </label>
                            <input
                              type="text"
                              className="form-control input-sm"
                              defaultValue={userDetail.USER_PHONENO}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="addressInfo">
                        <div className="row" style={{ marginTop: 20 }}>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="al-subtitle">
                                {appLabel.country}
                              </label>
                              <input
                              type="email"
                              className="form-control input-sm"
                              defaultValue={userDetail.COUNTRY_NAME}
                              disabled
                            />
                            </div>
                            <div className="form-group">
                              <label className="al-subtitle">
                                {appLabel.province}
                              </label>
                              <input
                              type="email"
                              className="form-control input-sm"
                              defaultValue={userDetail.PROVINCE_NAME}
                              disabled
                            />
                            </div>
                          </div>
                          <div
                            className="col-md-6"
                            ng-if="userDetail.provinceId"
                          >
                            <div className="form-group">
                              <label className="al-subtitle">
                                {appLabel.city}
                              </label>
                              <input
                              type="email"
                              className="form-control input-sm"
                              defaultValue={userDetail.CITY_NAME}
                              disabled
                            />
                            </div>
                            <div className="form-group">
                              <label className="al-subtitle">
                                {appLabel.address}
                              </label>
                              <input
                                type="text"
                                className="form-control input-sm"
                                defaultValue={userDetail.ADDRESS}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="roleInfo">
                        <div className="row" style={{ marginTop: 20 }}>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="al-subtitle">
                                {appLabel.parentRoleName}
                              </label>
                              <input
                                type="text"
                                className="form-control input-sm"
                                defaultValue={userDetail.USER_PARENT_ROLE_NAME}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="al-subtitle">
                                {appLabel.roleName}
                              </label>
                              <input
                                type="text"
                                className="form-control input-sm"
                                defaultValue={userDetail.USER_ROLE_NAME}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*/tab-content*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        <RecordStatus {...recordData} />
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  recordData: state.recordStatus
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DetailUser);
