import React from "react";
import { Navigate, Route, Switch } from "react-router-dom";

// -------------- PAGES IMPORTS --------------

/** AUTH */
import SignIn from "../components/auth/SignIn";
import ChangePassword from "../components/auth/ChangePassword";
import ForgotPassword from "../components/auth/ForgotPassword";
import ResetPassword from "../components/auth/ResetPassword";
import Registration from "../components/auth/Registration";
import LockScreen from "../components/auth/LockScreen";
import FirstTimeLoginPassword from "../components/auth/FirstTimeLoginPassword";
import ValidateTerms from "../other_files/terms/ValidateTerms";
import TermsDetail from "../other_files/terms/TermsDetail";


import Dashboard from "../components/dashboard/Dashboard";
import Analytics from "../components/dashboard/Analytics";
import DashboardVersion2 from "../components/dashboard-version-2/Dashboard";

/** COMPANY */
import ManageCompany from "../components/company/ManageCompany";
import NewCompany from "../components/company/NewCompany";
import EditCompany from "../components/company/EditCompany";
/** SECURITY */
import ManageRole from "../components/security/role/ManageRole";
import DetailRole from "../components/security/role/DetailRole";
import ManageUser from "../components/security/user/ManageUser";
import DetailUser from "../components/security/user/DetailUser";
import ManageRoleMenu from "../components/security/roleMenu/ManageRoleMenu";
import SubRoleDetail from "../components/security/roleMenu/subrole/SubRoleDetail";
import NewSubRole from "../components/security/roleMenu/subrole/NewSubRole";
import EditSubRole from "../components/security/roleMenu/subrole/EditSubRole";
import ManageEntityMenu from "../components/security/entityMenu/ManageEntityMenu";
import EntityMenuDetail from "../components/security/entityMenu/EntityMenuDetail";
import NewMenu from "../components/security/roleMenu/menu/NewMenu";
import AddToMenu from "../components/security/roleMenu/menu/AddToMenu";
import NewEntityMenu from "../components/security/entityMenu/NewEntityMenu";
import AddToEntityMenu from "../components/security/entityMenu/AddToEntityMenu";
import NewUser from "../components/security/user/NewUser";
import CompanyDetail from "../components/company/CompanyDetail";
import ManagePurchaseOrder from "../components/purchaseOrder/ManagePurchaseOrder";
import ManageOrderToVerify from "../components/purchaseOrder/ManageOrderToVerify";
import NewOrder from "../components/purchaseOrder/NewOrder";
import OrderDetail from "../components/purchaseOrder/OrderDetail";
import AppModal from "../components/layouts/AppModal";
import NewSharedOrder from "../components/purchaseOrder/NewSharedOrder";
import OrderUpload from "../components/batchUpload/purchaseOrder/OrderUpload";
import NewOrderUpload from "../components/batchUpload/purchaseOrder/NewOrderUpload";
import OrderUploadDetail from "../components/batchUpload/purchaseOrder/OrderUploadDetail";
import InvoiceUpload from "../components/batchUpload/invoice/InvoiceUpload";
import NewInvoiceUpload from "../components/batchUpload/invoice/NewInvoiceUpload";
import InvoiceUploadDetail from "../components/batchUpload/invoice/InvoiceUploadDetail";
import VerifiedOrder from "../components/purchaseOrder/VerifiedOrder";
import OrderToVerifyDetail from "../components/purchaseOrder/OrderToVerifyDetail";
import ManageConsentedOrder from "../components/purchaseOrder/ManageConsentedOrder";
import ConsentedOrderDetail from "../components/purchaseOrder/ConsentedOrderDetail";
import VerifyAuth from "../components/auth/VerifyAuth";
import ManageTax from "../components/configuration/tax/ManageTax";
import NewTax from "../components/configuration/tax/NewTax";
import TaxDetail from "../components/configuration/tax/TaxDetail";
import ManageCurrency from "../components/configuration/currency/ManageCurrency";
import NewCurrency from "../components/configuration/currency/NewCurrency";
import CurrencyDetail from "../components/configuration/currency/CurrencyDetail";
import ManageSurcharge from "../components/configuration/surchage/ManageSurcharge";
import NewSurcharge from "../components/configuration/surchage/NewSurcharge";
import SurchargeDetail from "../components/configuration/surchage/SurchargeDetail";
import ManageTrustAccount from "../components/configuration/trustAccount/ManageTrustAccount";
import NewTrustAccount from "../components/configuration/trustAccount/NewTrustAccount";
import TrustAccountDetail from "../components/configuration/trustAccount/TrustAccountDetail";
import ManageBank from "../components/configuration/bank/ManageBank";
import NewBank from "../components/configuration/bank/NewBank";
import BankDetail from "../components/configuration/bank/BankDetail";
import ManageCoBank from "../components/configuration/companyBank/ManageCoBank";
import NewCoBank from "../components/configuration/companyBank/NewCoBank";
import CoBankDetail from "../components/configuration/companyBank/CoBankDetail";
import EditSurcharge from "../components/configuration/surchage/EditSurcharge";
import ManageAdHoc from "../components/configuration/adHoc/ManageAdHoc";
import EditAdHoc from "../components/configuration/adHoc/EditAdHoc";
import AdHocDetail from "../components/configuration/adHoc/AdHocDetail";
import ManageFee from "../components/configuration/fee/ManageFee";
import NewFee from "../components/configuration/fee/NewFee";
import FeeDetail from "../components/configuration/fee/FeeDetail";
import EditFee from "../components/configuration/fee/EditFee";
import ManageSharedOrder from "../components/purchaseOrder/ManageSharedOrder";
import AuditTrail from "../components/reporting/AuditTrail";
import ManageContract from "../components/contract/ManageContract";
import NewContract from "../components/contract/NewContract";
import ContractDetail from "../components/contract/ContractDetail";
import ManageInvoice from "../components/invoice/ManageInvoice";
import ManageInvoiceToVerify from "../components/invoice/ManageInvoiceToVerify";
import FinanceOrder from "../components/purchaseOrder/FinanceOrder";
import ManageFinancedOrder from "../components/purchaseOrder/ManageFinancedOrder";
import FinancedOrderDetail from "../components/purchaseOrder/FinancedOrderDetail";
import EditUser from "../components/security/user/EditUser";
import NewInvoice from "../components/invoice/NewInvoice";
import InvoiceDetail from "../components/invoice/InvoiceDetail";
import PrintInvoiceReceipt from "../components/invoice/printInvoice/PrintInvoiceReceipt";
import NewShareInvoice from "../components/invoice/NewShareInvoice";
import ManageSharedInvoice from "../components/invoice/ManageSharedInvoice";
import FinanceInvoice from "../components/invoice/FinanceInvoice";
import PageNotFound from "../components/ui-setup/PageNotFound";
import ManageFinancedInvoice from "../components/invoice/ManageFinancedInvoice";
import FinancedInvoiceDetail from "../components/invoice/FinancedInvoiceDetail";
import InvoiceToVerifyDetail from "../components/invoice/InvoiceToVerifyDetail";
import ManageConsentedInvoice from "../components/invoice/ManageConsentedInvoice";
import ConsentedInvoiceDetail from "../components/invoice/ConsentedInvoiceDetail";

import POReport from "../components/reporting/po/POReport";
import InvoiceReport from "../components/reporting/invoice/InvoiceReport";
import ContractReport from "../components/reporting/contract/ContractReport";
import ManageLoanReport from "../components/reporting/loan/ManageLoanReport";
import DownloadEntityTypeReport from "../components/reporting/DownloadEntityTypeReport";
import DownloadBidReport from "../components/reporting/DownloadBidReport";
import SettlementReport from "../components/reporting/SettlementReport";

import LoanDetail from "../components/reporting/loan/LoanDetail";
import ManageProsCompany from "../components/company/prospectCompany/ManageProsCompany";
import PropectCompanyDetail from "../components/company/prospectCompany/PropectCompanyDetail";
import EditProspectCompany from "../components/company/prospectCompany/EditProspectCompany";
import ManageSupplierIntent from "../components/otherCompoents/ManageSupplierIntent";
import SupplierIntentPoDetail from "../components/otherCompoents/SupplierIntentPoDetail";
import SupplierIntentInvDetail from "../components/otherCompoents/SupplierIntentInvDetail";
import ManageFunderBid from "../components/otherCompoents/bid/ManageFunderBid";
import ManageFunderBidDetail from "../components/otherCompoents/bid/ManageFunderBidDetail";
import PoCleanList from "../components/invoice/PoCleanList";
import TermsCondition from "../components/configuration/termsCondition/TermsCondition";
import AutoBidPreview from "../components/otherCompoents/bid/AutoBidPreview";

import EntityNotification from "../components/configuration/notification/entity/EntityNotification";
import NewEntityNotification from "../components/configuration/notification/entity/NewEntityNotification";
import EntityNotificationDetail  from "../components/configuration/notification/entity/EntityNotificationDetail";
import UserNotification from "../components/configuration/notification/UserNotification";
import ManageUserNotification from "../components/configuration/notification/user/ManageUserNotification";
import CreateUserNotification from "../components/configuration/notification/user/CreateUserNotification";
import SignInWithAnalytic from "../components/auth/SignInWithAnalytic";
import Privacy from "../other_files/terms/Privacy";
import ProtectedPrivacy from "../other_files/terms/ProtectedPrivacy";

import RouteGuard from "../components/layouts/RouteGuard";


export const RoutedContent = () => {
  return (
    <Switch>
      {/* <Navigate from="/" to="landing" exact /> */}
      {/* ROOT PATH */}
        <Route path="/" component={SignIn} exact />
        <RouteGuard path="/modal" component={AppModal} exact />

        <RouteGuard path="/dashboard" component={DashboardVersion2} exact />
        <RouteGuard path="/report-analytics" component={Analytics} exact />
        {/* <RouteGuard path="/dashboard-version-2" component={DashboardVersion2} exact /> */}
      {/* COMPANY */}
        <RouteGuard path="/manage-company" component={ManageCompany} exact />
        <RouteGuard path="/company-detail" component={CompanyDetail} exact/>
        <RouteGuard path="/new-company" component={NewCompany} exact />
        <RouteGuard path="/edit-company" component={EditCompany} exact />
        <RouteGuard path="/manage-prospect-company" component={ManageProsCompany} exact />
        <RouteGuard path="/prospect-company-detail" component={PropectCompanyDetail} exact/>
        <RouteGuard path="/edit-prospect-company" component={EditProspectCompany} exact />


      {/* SECURITY */}

      {/* ROLE */}
        <RouteGuard path="/manage-role" component={ManageRole} exact />
        <RouteGuard path="/role-detail" component={DetailRole} exact />

      {/* USER */}
        <RouteGuard path="/manage-user" component={ManageUser} exact />
        <RouteGuard path="/user-detail" component={DetailUser} exact />
        <RouteGuard path="/new-user" component={NewUser} exact />
        <RouteGuard path="/edit-user" component={EditUser} exact />

      {/* ROLE MENU */}
        <RouteGuard path="/manage-role-menu" component={ManageRoleMenu} exact />
        <RouteGuard path="/subrole-detail" component={SubRoleDetail} exact />
        <RouteGuard path="/new-subrole" component={NewSubRole} exact />
        <RouteGuard path="/edit-subrole" component={EditSubRole} exact />

      {/* ENTITY  */}
        <RouteGuard path="/manage-entity-menu" component={ManageEntityMenu} exact />
        <RouteGuard path="/entity-menu-detail" component={EntityMenuDetail} exact />

      {/* MENU */}
        <RouteGuard path="/new-subrole-menu" component={NewMenu} exact />
        <RouteGuard path="/add-to-subrole-menu" component={AddToMenu} exact />
        <RouteGuard path="/new-entity-menu" component={NewEntityMenu} exact />
        <RouteGuard path="/add-to-entity-menu" component={AddToEntityMenu} exact />

      {/* PURCHASE ORDER */}
        <RouteGuard path="/manage-order" component={ManagePurchaseOrder} exact />
        <RouteGuard path="/new-order" component={NewOrder} exact />
        <RouteGuard path="/order-detail" component={OrderDetail} exact />
        <RouteGuard path="/new-share-order" component={NewSharedOrder} exact />
        <RouteGuard path="/verify-order" component={VerifiedOrder} exact />
        <RouteGuard path="/verify-auth/:orderId" component={VerifyAuth} exact />
        <RouteGuard path="/order-to-verify-detail" component={OrderToVerifyDetail} exact />
        <RouteGuard path="/shared-order" component={ManageSharedOrder} exact />
        <RouteGuard path="/finance-order" component={FinanceOrder} exact />
        <RouteGuard path="/financed-order" component={ManageFinancedOrder} exact />
        <RouteGuard path="/financed-order-detail" component={FinancedOrderDetail} exact />
        <RouteGuard path="/order-to-verify" component={ManageOrderToVerify} exact />
        <RouteGuard path="/funded-order" component={ManageConsentedOrder} exact />
        <RouteGuard path="/funded-order-detail" component={ConsentedOrderDetail} exact />

       {/* BATCH UPLOAD */}
        <RouteGuard path="/order-upload" component={OrderUpload} exact />
        <RouteGuard path="/new-order-upload" component={NewOrderUpload} exact />
        <RouteGuard path="/order-upload-detail" component={OrderUploadDetail} exact />
        
        <RouteGuard path="/invoice-upload" component={InvoiceUpload} exact />
        <RouteGuard path="/new-invoice-upload" component={NewInvoiceUpload} exact />
        <RouteGuard path="/invoice-upload-detail" component={InvoiceUploadDetail} exact />

        {/* CONFIGURATION */}
        <RouteGuard path="/manage-tax" component={ManageTax} exact />
        <RouteGuard path="/new-tax" component={NewTax} exact />
        <RouteGuard path="/tax-detail" component={TaxDetail} exact />

        <RouteGuard path="/manage-currency" component={ManageCurrency} exact />
        <RouteGuard path="/new-currency" component={NewCurrency} exact />
        <RouteGuard path="/currency-detail" component={CurrencyDetail} exact />

        <RouteGuard path="/manage-surcharge" component={ManageSurcharge} exact />
        <RouteGuard path="/new-surcharge" component={NewSurcharge} exact />
        <RouteGuard path="/charge-detail" component={SurchargeDetail} exact />
        <RouteGuard path="/edit-charge" component={EditSurcharge} exact />

        <RouteGuard path="/manage-trust-account" component={ManageTrustAccount} exact />
        <RouteGuard path="/new-trust-account" component={NewTrustAccount} exact />
        <RouteGuard path="/trust-account-detail" component={TrustAccountDetail} exact />

        <RouteGuard path="/manage-bank" component={ManageBank} exact />
        <RouteGuard path="/new-bank" component={NewBank} exact />
        <RouteGuard path="/bank-detail" component={BankDetail} exact />

        <RouteGuard path="/manage-company-bank" component={ManageCoBank} exact />
        <RouteGuard path="/new-company-bank" component={NewCoBank} exact />
        <RouteGuard path="/company-bank-detail" component={CoBankDetail} exact />

        <RouteGuard path="/manage-ad-hoc" component={ManageAdHoc} exact />
        <RouteGuard path="/edit-ad-hoc" component={EditAdHoc} exact />
        <RouteGuard path="/ad-hoc-detail" component={AdHocDetail} exact />

        <RouteGuard path="/manage-fee" component={ManageFee} exact />
        <RouteGuard path="/new-fee" component={NewFee} exact />
        <RouteGuard path="/fee-detail" component={FeeDetail} exact />
        <RouteGuard path="/edit-fee" component={EditFee} exact />
        <RouteGuard path="/manage-terms-and-conditions" component={TermsCondition} exact />

        <RouteGuard path="/manage-entity-notification" component={EntityNotification} exact />
        <RouteGuard path="/new-entity-notification" component={NewEntityNotification} exact />
        <RouteGuard path="/entity-notification-detail" component={EntityNotificationDetail} exact />
        <RouteGuard path="/manage-user-notification" component={ManageUserNotification} exact />
        <RouteGuard path="/create-user-notification" component={CreateUserNotification} exact />
        

        {/* REPORTING */}
        <RouteGuard path="/audit-trail" component={AuditTrail} exact />
        <RouteGuard path="/po-report" component={POReport} exact />
        <RouteGuard path="/invoice-report" component={InvoiceReport} exact />
        <RouteGuard path="/contract-report" component={ContractReport} exact />
        <RouteGuard path="/manage-loan-portfolio" component={ManageLoanReport} exact />
        <RouteGuard path="/loan-detail" component={LoanDetail} exact />
        <RouteGuard path="/download-entity-report" component={DownloadEntityTypeReport} exact />
        <RouteGuard path="/download-bid-report" component={DownloadBidReport} exact />
        <RouteGuard path="/settlement-report" component={SettlementReport} exact />


        {/* CONTRACT */}
        <RouteGuard path="/manage-contract" component={ManageContract} exact />
        <RouteGuard path="/new-contract" component={NewContract} exact />
        <RouteGuard path="/contract-detail" component={ContractDetail} exact />

        {/* INVOICE */}
        <RouteGuard path="/manage-invoice" component={ManageInvoice} exact />
        <RouteGuard path="/new-invoice" component={NewInvoice} exact />
        <RouteGuard path="/invoice-detail" component={InvoiceDetail} exact />
        <RouteGuard path="/print-invoice-receipt" component={PrintInvoiceReceipt} exact />
        <RouteGuard path="/new-share-invoice" component={NewShareInvoice} exact />
        <RouteGuard path="/shared-invoice" component={ManageSharedInvoice} exact />
        <RouteGuard path="/finance-invoice" component={FinanceInvoice} exact />
        <RouteGuard path="/financed-invoice" component={ManageFinancedInvoice} exact />
        <RouteGuard path="/financed-invoice-detail" component={FinancedInvoiceDetail} exact />
        <RouteGuard path="/invoice-to-verify" component={ManageInvoiceToVerify} exact />
        <RouteGuard path="/invoice-to-verify-detail" component={InvoiceToVerifyDetail} exact />
        <RouteGuard path="/invoice-po-clean-list" component={PoCleanList} exact />
        <RouteGuard path="/funded-invoice" component={ManageConsentedInvoice} exact />
        <RouteGuard path="/funded-invoice-detail" component={ConsentedInvoiceDetail} exact />

        {/* INTENT */}
        <RouteGuard path="/manage-supplier-intent" component={ManageSupplierIntent} exact />
        <RouteGuard path="/supplier-intent-po-detail" component={SupplierIntentPoDetail} exact />
        <RouteGuard path="/supplier-intent-inv-detail" component={SupplierIntentInvDetail} exact />

        {/* BID */}
        <RouteGuard path="/manage-funder-bid" component={ManageFunderBid} exact />
        <RouteGuard path="/manage-funder-bid-detail" component={ManageFunderBidDetail} exact />
        <RouteGuard path="/auto-bid-preview" component={AutoBidPreview} exact />

        {/* TERM AND PRIVACY DOCUMENTS */}
        <Route path="/privacy-document" component={Privacy} exact />
        <Route path="/protected-privacy-document" component={ProtectedPrivacy} exact />

      

     {/* AUTH */}
      <Route path="/sign-in" component={SignIn} exact />
      <Route path="/change-password" component={ChangePassword} exact />
      <Route path="/forgot-password" component={ForgotPassword} exact />
      <Route path="/reset-password/:token" component={ResetPassword} exact />
      <Route path="/registration" component={Registration} exact />
      <Route path="/lockscreen" component={LockScreen} exact />
      <Route path="/validate-terms" component={ValidateTerms} exact />
      <Route path="/terms-detail" component={TermsDetail} exact />
      <Route path="/first-time-login-password" component={FirstTimeLoginPassword} exact />
      
      {/* NOT FOUND */}
      <RouteGuard path="*" component={PageNotFound} />
    </Switch>
  );
};
