import React, { useEffect } from "react";
// import Sidebar from "../ui-setup/Sidebar";
import { connect } from "react-redux";
import TopHeader from "../ui-setup/TopHeader";
import RightSide from "../ui-setup/RightSide";
import Footer from "../ui-setup/Footer";
import { correctHeight, detectBody } from "./Helpers";
import { RPB, BOM } from "../../services/defined.action";
import ContentTop from "../ui-setup/ContentTop";
import {cnt} from "../../services/constant.action"
import { useIdleTimer } from "react-idle-timer";

import history from "../../history";
import Sidebar from "../leftSidebar";



const Main = (props) => {
  const { isSignedIn,  userData, location, children, onApprove,
    onReject, onSaveChange } = props;

  // start idle logic
  const handleOnIdle = (event) => {
    // history.push("/sign-in");

    history.push("/lockscreen");
    
    console.log("last active", getLastActiveTime());
    // BOM.AlertMsg(
    //   cnt.WARNING,
    //   "Session has expired due to inactivity. Please login again"
    // );
  };

  const handleOnActive = (event) => {
    // console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
  };

  const handleOnAction = (event) => {
    // console.log("user did something", event);
  };
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 250,
  });

  // end idle logic

  useEffect(() => {
    
    // Run correctHeight function on load and resize window event
    $(window).bind("load resize", function () {
      correctHeight();
      detectBody();
    });

    // Correct height of wrapper after metisMenu animation.
    $(".metismenu a").click(() => {
      setTimeout(() => {
        correctHeight();
      }, 300);
    });
    // console.log(props);
    RPB(props);
    window.scrollTo(0,0)
    $("select, input").removeClass("input-sm");
    $("select, input").addClass("input-md, input-radius")
  }, []);

  return (
    <div id="wrapper">
      <Sidebar location={location} />

      <div id="page-wrapper" className="gray-bg sidebar-content">
        <TopHeader />

        <RightSide onApprove={onApprove} onReject= {onReject} onSaveChange = {onSaveChange} />
        <div className="wrapper wrapper-content">
           {children}
        </div>

        {/* <Footer /> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {

  // console.log(state);
  return { 
    isSignedIn: state.auth.isSignedIn,
    userData: state.auth.userData,
   };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
