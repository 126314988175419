import React, { useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../services/api.action";
import { connect } from "react-redux";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../ui-setup/RecordStatus";
import appStatic from "../../config/appStaticData";
import RightSide from "../ui-setup/RightSide";
import NumberFormat from "react-number-format";

export const NewContract = (props) => {
  const { location, userData, configData } = props;
  const { state } = location;
  const { profileId, session, companyId } = userData;
  const { isCsd } = configData;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm();

  const [documents, setDocuments] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [tempFile, setTempFile] = useState(false);
  const [docCategory, setDocCategory] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [contractTypeId, setContractTypeId] = useState("");
  const [isOrder, setIsOrder] = useState(false);
  const [isInvoice, setIsInvoice] = useState(false);
  const [obgDetail, setObgDetail] = useState({
    funder: "N/A",
    buyer: "N/A",
    supplier: "N/A",
  });

  useEffect(() => {
    getCurrency();
    getOrder();
    getInvoice();
    setValue("currency", "NGN");
    setValue("contractValue", 0);
    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);
  }, []);

  function getCurrency() {
    /** FETCH MENU */
    let body = {
      profileId,
      criteria: "A",
      currencyId: "",
      session,
    };
    BOM.FetchReqAction(body, api.GetCurrency, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setCurrencyData(res.currencyListing);
      }
    });
  }

  function getOrder() {
    let body = {
      profileId,
      orderId: "",
      criteria: "A",
      companyId,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetPo, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setOrderData(res.purchaseOrderListing);
      }
    });
  }
  function getInvoice() {
    let body = {
      profileId,
      invoiceId: "",
      criteria: "A",
      companyId,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetInvoice, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setInvoiceData(res.invoiceListing);
      }
    });
  }
 
  const handleFileRead = async (event, setState_param) => {
    const file = event.target.files[0];
    if (!file) {
      // if file is empty
      setState_param("");
      return;
    }

    if (file.size > 5242880) {
      BOM.AlertMsg(cnt.WARNING, "File size cannot exceed 5 MB");
        $(".documentInput").val("");
        return;
    }

    switch (file.type.toLowerCase()) {
      case 'application/pdf':
        const base64 = await BOM.convertBase64(file);
        let strB64 = base64.split("base64,")[1]; //.replace(/^data:image\/[a-z]+;base64,/, "");
        setState_param(strB64);
        break;
    
      default:
        BOM.AlertMsg(cnt.WARNING, "Document must be a PDF.");
        $(".documentInput").val("");
        break;
    }
    
    // console.log(file.type);
    
  };

  const uploadFile = () => {
    // console.log(tempFile);

    const itemId = documents.find(
      (item, index) => item.docCategory === docCategory
    );

    if (itemId) {
      BOM.AlertMsg(cnt.WARNING, "No duplicate file allowed!");
      return;
    }

    if (!tempFile || !docCategory) {
      BOM.AlertMsg(cnt.WARNING, "Fields with * are required");
      return;
    }

    setDocuments([
      ...documents,
      {
        base64: tempFile,
        docType: "contract",
        docCategory,
      },
    ]);
    $(".documentInput").val("");
  };
  const removeItem = (index, type) => {
    switch (type.toLowerCase()) {
      case "document":
        setDocuments([
          ...documents.slice(0, index),
          ...documents.slice(index + 1),
        ]);
        break;
      case "material":
        break;
    }
  };
  const onChooseType = (key) => {
    setValue("currency", "NGN");
      setValue("contractValue", 0);

    $(".clearInput").val("");
    switch (key.toLowerCase()) {
      case "po":
        setIsOrder(true);
        setIsInvoice(false);
        break;
      case "invoice":
        setIsInvoice(true);
        setIsOrder(false);
        break;
    }
  };
  const onChooseSpecState = (key, idParam) => {
    setContractTypeId(idParam)
    if (!idParam){
      setValue("currency", "NGN");
      setValue("contractValue", 0);
      setIsOrder(false);
      setIsInvoice(false);
      return;
    }
    
    switch (key.toLowerCase()) {
      case "po":
        let order = orderData.find((item) => item.ORDERID === idParam);
        setValue("currency", order.PO_CCY);
        setValue("contractValue", order.PO_AMOUNT);

        let body = {
          profileId,
          orderId: idParam,
          criteria: "A",
          companyId,
          session,
        };

        /** FETCH ROLE */
        BOM.FetchReqAction(body, api.GetPo, (err, res) => {
          if (err) {
            BOM.AlertMsg(cnt.DANGER, err);
          } else {
            let orderDetail = res.purchaseOrderDetail
            setObgDetail({
              funder: '',//orderDetail.FROM_COMPANY_NAME,
              buyer: orderDetail.FROM_COMPANY_NAME,
              supplier: orderDetail.TO_COMPANY_NAME,
            })
          }
        });
        break;
      case "invoice":
        let invoice = invoiceData.find((item) => item.INV_ID === idParam);
        setValue("currency", invoice.CCY);
        setValue("contractValue", invoice.FINAL_AMOUNT);

        /** GET DETAIL FROM ID */
        let body2 = {
          profileId,
          invoiceId: idParam,
          criteria: "A",
          companyId,
          session,
        };

        /** FETCH ROLE */
        BOM.FetchReqAction(body2, api.GetInvoice, (err, res) => {
          if (err) {
            BOM.AlertMsg(cnt.DANGER, err);
          } else {
            let ivoiceDetail = res.invoiceDetail
            setObgDetail({
              funder: "N/A",
              buyer: "N/A",
              supplier: "N/A",
            })
          }
        });
        break;
    }
  };
  const formSubmit = (formValues) => {

    if (documents.length === 0) {
      BOM.AlertMsg(cnt.WARNING, "Document upload is required.");
      return;
    }

  if (!contractTypeId) {
    BOM.AlertMsg(cnt.WARNING, "Contract Type Id is missing. Please contact dev team.");
    return;
    }

    formValues.obligations = [
      {
          "obligation": "Financing",
          "counterParty": obgDetail.funder,
          "role": "Funder",
          "deliveryDate": document.getElementById("deliveryDate1").value,
          "status": document.getElementById("status1").value
      },
      {
          "obligation": "Payment",
          "counterParty": obgDetail.buyer,
          "role": "Buyer",
          "deliveryDate": document.getElementById("deliveryDate2").value,
          "status": document.getElementById("status2").value
      },
      {
          "obligation": "Delivery",
          "counterParty":obgDetail.supplier,
          "role": "Supplier",
          "deliveryDate": document.getElementById("deliveryDate3").value,
          "status": document.getElementById("status3").value
      }
  ]

    /** BUILD ORDER BODY */
    formValues.documents = documents;
    formValues.contractTypeId = contractTypeId;
    let body = {
      profileId,
      companyId,
      session,
      ...formValues,
    };

    console.log(body);

    /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(body, api.CreateContract, "/manage-contract");
  };

  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <RightSide />
        <ContentTop pageTitle={appLabel.newContract} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{appLabel.contractInformation}</h5>
                  <div className="ibox-tools">
                    <Link
                      to="/manage-contract"
                      className="btn btn-primary btn-xs"
                    >
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.generalInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.type}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm"
                            {...register("contractType", {
                              onChange: (e) => onChooseType(e.target.value),
                              required: "Field is required.",
                            })}
                          >
                            <option value="">{appLabel.choose}</option>
                            {appStatic.contractType.map((item, index) => (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <span className="asterisks">
                            {errors?.contractType?.message}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6 ${
                          isOrder ? "" : "hide"
                        }`}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.poNo}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm clearInput"
                            {...register("orderId", {
                              onChange: (e) =>
                                onChooseSpecState("po", e.target.value),
                              required: isOrder ? "Field is required." : false,
                            })}
                          >
                            <option value="">{appLabel.choose}</option>
                            {orderData.map((item, index) => (
                              <option value={item.ORDERID} key={index}>
                                {item.ORDERID}
                              </option>
                            ))}
                          </select>
                          <span className="asterisks">
                            {errors?.orderId?.message}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6 ${
                          isInvoice ? "" : "hide"
                        }`}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.invoiceId}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm clearInput"
                            {...register("invoiceId", {
                              onChange: (e) =>
                                onChooseSpecState("invoice", e.target.value),
                              required: isInvoice ? "Field is required.": false,
                            })}
                          >
                            <option value="">{appLabel.choose}</option>
                            {invoiceData.map((item, index) => (
                              <option value={item.INV_ID} key={index}>
                                {item.INV_ID}
                              </option>
                            ))}
                          </select>
                          <span className="asterisks">
                            {errors?.invoiceId?.message}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6 ${
                          isOrder || isInvoice ? "" : "hide"
                        }`}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.value}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <NumberFormat
                            className="form-control input-sm"
                            value ={getValues("contractValue")}
                            displayType="text"
                            disabled
                            thousandSeparator={true}
                            prefix={" "}
                          />
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6 ${
                          isOrder || isInvoice ? "" : "hide"
                        }`}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.currency}&nbsp;
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            disabled
                            {...register("currency", {
                              required: "Field is required.",
                            })}
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.startDate}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="date"
                            className="form-control input-sm"
                            placeholder={appLabel.startDate}
                            {...register("startDate", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.startDate?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.endDate}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="date"
                            className="form-control input-sm"
                            placeholder={appLabel.startDate}
                            {...register("endDate", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.endDate?.message}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.otherInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <ul className="nav nav-tabs">
                        <li className="active">
                          <a
                            data-toggle="tab"
                            href="#obligation"
                            target="_self"
                          >
                            <i className="fa fa-tag text-navy" />
                            &nbsp;{appLabel.obligation}
                          </a>
                        </li>
                        <li>
                          <a
                            data-toggle="tab"
                            href="#documentInfo"
                            target="_self"
                          >
                            <i className="fa fa-tag text-navy" />
                            &nbsp;{appLabel.documentInfo}
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="obligation">
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-12">
                              <div className="table-responsive">
                                <table
                                  className="footable table table-stripped toggle-arrow-tiny"
                                  data-page-size="10"
                                  data-filter="#filter"
                                >
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>{appLabel.obligation}</th>
                                      <th>{appLabel.counterParty}</th>
                                      <th>{appLabel.roleName}</th>
                                      <th>{appLabel.deliveryDate}</th>
                                      <th>{appLabel.status}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>1</td>
                                      <td>Financing</td>
                                      <td>{obgDetail.funder}</td>
                                      <td>Funder</td>
                                      <td>
                                        <input
                                          type="date"
                                          className="form-control input-sm"
                                          id="deliveryDate1"
                                        />
                                      </td>
                                      <td>
                                        <select className="form-control input-sm" id="status1">
                                          <option value="">
                                            {appLabel.choose}
                                          </option>
                                          {appStatic.obligationStatus.map(
                                            (item, index) => (
                                              <option
                                                value={item.id}
                                                key={index}
                                              >
                                                {item.name}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </td>
                                    </tr>
                                    
                                    <tr>
                                      <td>3</td>
                                      <td>Delivery</td>
                                      <td>{obgDetail.supplier}</td>
                                      <td>Supplier</td>
                                      <td>
                                        <input
                                          type="date"
                                          className="form-control input-sm"
                                          id="deliveryDate3"
                                        />
                                      </td>
                                      <td>
                                        <select className="form-control input-sm" id="status3">
                                          <option value="">
                                            {appLabel.choose}
                                          </option>
                                          {appStatic.obligationStatus.map(
                                            (item, index) => (
                                              <option
                                                value={item.id}
                                                key={index}
                                              >
                                                {item.name}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>2</td>
                                      <td>Payment</td>
                                      <td>{obgDetail.buyer}</td>
                                      <td>Buyer</td>
                                      <td>
                                        <input
                                          type="date"
                                          className="form-control input-sm"
                                          id="deliveryDate2"
                                        />
                                      </td>
                                      <td>
                                        <select className="form-control input-sm" id="status2">
                                          <option value="">
                                            {appLabel.choose}
                                          </option>
                                          {appStatic.obligationStatus.map(
                                            (item, index) => (
                                              <option
                                                value={item.id}
                                                key={index}
                                              >
                                                {item.name}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td colSpan="8">
                                        <ul className="pagination pull-right"></ul>
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="documentInfo">
                          <div className="row" style={{ marginTop: "30px" }}>
                          <div className="col-sm-12">
                          <p className="text-danger">{appLabel.maxFileUploadMsg}</p>
                          </div>
                            <div className="col-sm-12">
                              <div className="col-md-6">
                                <div className="row form-group has-errorr">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle control-label">
                                      {appLabel.documentType}{" "}
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <select
                                      className="form-control input-sm documentInput"
                                      onChange={(e) =>
                                        setDocCategory(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {appStatic.documntTypesPo.map(
                                        (item, index) => (
                                          <option value={item.id} key={index}>
                                            {item.name}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="row form-group">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle ">
                                      {appLabel.documentUpload}{" "}
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <input
                                      type="file"
                                      className="form-control input-sm documentInput"
                                      accept=".pdf"
                                      onChange={(e) =>
                                        handleFileRead(e, setTempFile)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row form-group">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle ">
                                      &nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <button
                                      type="button"
                                      onClick={() => uploadFile()}
                                      className="btn btn-primary btn-xs"
                                    >
                                      <i className="fa fa-upload" />
                                      &nbsp; Upload
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="col-sm-4 al-subtitle"></label>
                                  <div className="col-sm-8">
                                    {documents.map((item, index) => (
                                      <div className="i-checks" key={index}>
                                        <label className="control-label">
                                          <i
                                            style={{ cursor: "pointer" }}
                                            className="fa fa-times-circle text-danger fa-2x"
                                            onClick={() =>
                                              removeItem(index, "document")
                                            }
                                          />
                                          &nbsp; &nbsp;
                                          <i className="fa fa-file-pdf-o fa-2x" style={{color:'#d50000'}} />
                                          &nbsp; {item.docCategory}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <RecordStatus {...userDetail} /> */}
        </div>
      </form>
      <RecordStatus {...BOM.NewRecordStatusdata()} />
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  configData: state.auth.configData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewContract);
