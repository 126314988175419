import React, { useEffect, useState } from "react";
import Main from "../../layouts/Main";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { apis as api } from "../../../services/api.action";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM, RAL } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import appStatic from "../../../config/appStaticData";
import { HandleSkeletonTbleFac } from "../../layouts/Facility";
import ReactQuill , { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Parser as HtmlToReactParser } from "html-to-react";
import termsDb from './termsDb.json'

var htmlToReactParser = new HtmlToReactParser();

const TermsCondition = (props) => {
  const { userData, menuData, location } = props;
  const { profileId, session, companyId } = userData;
  const globalVar = window.globalData;
  const dispatch = useDispatch();

  const [tsAndCsDetail, setTsAndCsDetail] = useState(appLabel.paragraph)
  const [isPreview, setIsPreview] = useState(false);
  // const [code, setCode] = useState(localStorage.getItem('tsandcs'));
  const [code, setCode] = useState(termsDb.data);
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      // [{ size: [] }],
      [{ font: [] }],
      // [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: ["red", "#785412", "#1ab394"] }],
      [{ background: ["red", "#785412", "#1ab394"] }]
    ]
  };
  useEffect(() => {
  }, []);

  const handleProcedureContentChange = (content, delta, source, editor) => {
        // setCode(content);

        localStorage.setItem('tsandcs', content)
    
      //  console.log("content: ", content);
      //  console.log("delta: ", delta);
      //  console.log("source: ", source);
      //  console.log("editor: ", editor);
      //  console.log("editor: ", editor.getContents());

  };
  const handleSaveToPC = (jsonData,filename) => {
    const fileData = JSON.stringify(jsonData);
    const blob = new Blob([fileData], {type: "text/plain"});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    // link.download = `${filename}.json`;
    link.href = url;
    link.click();
  }

  /** RENDER JXS DATA */
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.termConfig} />
      <div className="animated fadeInRightFAKE">
        <div className={`row ${isPreview ? 'hide': ''}`}>
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5></h5>
                <button
                  id="edit"
                  className="btn btn-primary btn-xs m-l-sm m-r"
                  onClick={() => setIsPreview(!isPreview)}
                  type="button"
                >
                  {appLabel.previewBtn}
                </button>
              </div>
              <div className="ibox-content no-padding contentCard">
                <div className="click2edit wrapper p-md">
                  <ReactQuill theme="snow" value={code}
                  modules={modules}
                    onChange={handleProcedureContentChange} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`row ${isPreview ? '': 'hide'}`}>
          <div className="col-lg-10 col-lg-offset-1">
            <div className="ibox float-e-margins">
            <div className="ibox-title">
                <h5></h5>
                <button
                  className="btn btn-primary btn-xs m-l-sm m-r"
                  onClick={() => setIsPreview(!isPreview)}
                  type="button"
                >
                  {appLabel.edit}
                </button>
              </div>
              <div
                className="ibox-content contentCard"
              >
                {
                  htmlToReactParser.parse(code)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(TermsCondition);
