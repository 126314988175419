import React, { useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../../../services/api.action";
import { connect } from "react-redux";
import Main from "../../../layouts/Main";
import ContentTop from "../../../ui-setup/ContentTop";
import { cnt } from "../../../../services/constant.action";
import { BOM } from "../../../../services/defined.action";
import appLabel from "../../../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../../../ui-setup/RecordStatus";
import appStatic from "../../../../config/appStaticData";
import RightSide from "../../../ui-setup/RightSide";
import { Tooltips } from "../../../layouts/Facility";

export const NewEntityNotification = (props) => {
  const { location, userData, configData } = props;
  const { state } = location;
  const { profileId, session, companyId } = userData;
  const { isCsd } = configData;
  const { toolTip } = appStatic;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [bankData, setBankData] = useState([]);
  const [companyTypeData, setCompanyTypeData] = useState([]);

  useEffect(() => {
    getCompanyType();
    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);
  }, []);

  function getCompanyType() {
    /** FETCH MENU */
    let body = {
      profileId,
      criteria: "A",
      companyTypeId: "",
      session,
    };
    BOM.FetchReqAction(body, api.GetCompanyType, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setCompanyTypeData(res.companyTypeListing);
      }
    });
  }
  const formSubmit = (formValues) => {
    // console.log("data", formValues);

    let body = {
      profileId,
      session,
      ...formValues,
    };

    // console.log(body);
    // return;
    /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(
      body,
      api.CreateEntityNotification,
      "/manage-entity-notification"
    );
  };

  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <RightSide />
        <ContentTop pageTitle={appLabel.newEntityNotification} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{appLabel.notificationInformation}</h5>
                  <div className="ibox-tools">
                    <Link
                      to="/manage-entity-notification"
                      className="btn btn-primary btn-xs"
                    >
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.generalInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.entityType} &nbsp;
                            <Tooltips title={toolTip.companyType} />
                            <span className="asterisks"> *</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm"
                            {...register("entityTypeId", {
                              required: "Field is required.",
                            })}
                          >
                            <option value="">{appLabel.choose}</option>
                            {companyTypeData.map((item, index) => (
                              <option value={item.COMPANY_TYPE_ID} key={index}>
                                {item.COMPANY_TYPE_NAME}
                              </option>
                            ))}
                          </select>
                          <span className="asterisks">
                            {errors?.entityTypeId?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.notificationName}{" "}
                            {/* <Tooltips title={toolTip.configBankName} /> */}
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.notificationName}
                            {...register("notificationName", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.notificationName?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.description}{" "}
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <textarea
                            style={{
                              width: "100%",
                              height: 80,
                              padding: 5,
                            }}
                            className="form-control"
                            placeholder="description"
                            {...register("description", {
                              required: "Field is required.",
                              minLength: {
                                value: 1,
                                message: "Minimum length is 1 character",
                              },
                              maxLength: {
                                value: 200,
                                message: "Maximum length is 200 characters",
                              },
                            })}
                          />
                          {/* <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.description}
                            {...register("description", {
                              required: "Field is required.",
                            })}
                          /> */}
                          <span className="asterisks">
                            {errors?.description?.message}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <RecordStatus {...userDetail} /> */}
        </div>
      </form>
      <RecordStatus {...BOM.NewRecordStatusdata()} />
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  configData: state.auth.configData,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewEntityNotification);
