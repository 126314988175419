import React, { useEffect, useState } from "react";
import { apis as api } from "../../services/api.action";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM, RAL } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../ui-setup/RecordStatus";
import appStatic from "../../config/appStaticData";
import NumberFormat from "react-number-format";
import AppModal from "../layouts/AppModal";
import RightSide from "../ui-setup/RightSide";
import {
  Tooltips,
  InputAmountFacility,
  Col12BlockFacility,
  InputTextFacility,
  NoDataFac,
  NavTabFac,
  AmountFacility,
} from "../layouts/Facility";
import Notiflix from "notiflix";

const amtPybles = {
  amtPay1: 0,
  amtPay2: 0,
};
export const NewShareInvoice = (props) => {
  const { location, userData, configData, recordData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId, companyTypeId } = userData;
  const { isCsd } = configData;
  const { toolTip, tabData, notif } = appStatic;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm();

  const dispatch = useDispatch();
  const [isCsdCheck, setIsCsdCheck] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [isOrder, setIsOrder] = useState(false);
  const [isInvoice, setIsInvoice] = useState(false);
  const [obgDetail, setObgDetail] = useState([]);

  const [orderDetail, setOrderDetail] = useState({});
  const [shipFromData, setShipFromData] = useState({});
  const [billFromData, setBillFromData] = useState({});
  const [shipToData, setShipToData] = useState({});
  const [billToData, setBillToData] = useState({});
  const [isPO, setIsPO] = useState(false);

  const [fromCompanyDetail, setFromCompanyDetail] = useState({});
  const [toCompanyDetail, setToCompanyDetail] = useState({});
  const [show, setShow] = useState(false);
  const [investorId, setInvestorId] = useState("");
  const [investors, setInvestorData] = useState([]);
  const [selectedInvestors, setSelectedInvestors] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [autoFldData, setAutoFldData] = useState([]);
  const [inputFldData, setInputFldData] = useState([]);
  const [otherFee1Data, setOtherFee1Data] = useState([]);
  const [otherFee2Data, setOtherFee2Data] = useState([]);
  const [amountPayable1, setAmountPayable1] = useState(0);
  const [amountPayable2, setAmountPayable2] = useState(0);
  const [amountReceivable, setAmountReceivable] = useState(0);

  const [capitalRequired, setCapitalRequired] = useState(undefined);
  const [isCapitalRqdFld, setIsCapitalRqdFld] = useState(true);
  const [fundsAvailable, setFundsAvailable] = useState(undefined);
  const [isFundsRqdFld, setIsFundsRqdFld] = useState(true);
  const [logisticsOtherCosts, setLogisticsOtherCosts] = useState(undefined);
  const [isLogisticsRqdFld, setIsLogisticsRqdFld] = useState(true);
  const [supplierQuote, setSupplierQuote] = useState(undefined);
  const [isQuoteRqdFld, setIsQuoteRqdFld] = useState(true);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isBlock, setIsBlock] = useState(false);

  const [isCalculated, setIsCalculated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // console.log(state.ggParam);
    if (isCsd.toLowerCase() === "y") {
      setIsCsdCheck(true);
    }
    setUpOrder(ggParam);
    BOM.getSpecDoc(ggParam?.INV_ID, setDocuments, userData);

  }, []);

  function setUpOrder(param) {
    
    let body = {
      profileId,
      invoiceId: param.INV_ID,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.CheckInvoiceFees, (err, res) => {
      if (err) {
        BOM.AlertGoBackMsg(cnt.WARNING, err);
      } else {

        // console.log(res);
        getCompany();
        getDetailById(param);

        /** UPDATE VALUES */
        setValue("invoiceId", param.INV_ID);
        // setValue("invoiceAmount", invDetail.FINAL_AMOUNT);
        setValue("currency", param.INV_CCY);
        setValue("fromCompany", param.SUPPLIER_COMPANY);
        setValue("toCompany", param.BILL_TO_COMPANY);
      }
    });
  }
  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      invoiceId: params.INV_ID,
      companyId,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetInvoice, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.invoiceDetail;
        const invDetail = objDetail.INVOICE_DATA;
        const taxDetail = objDetail.TAX_DATA;

        setInvoiceDetail(objDetail);
        // /** UPDATE VALUES */
        // setValue("invoiceId", invDetail.INV_ID);
        // // setValue("invoiceAmount", invDetail.FINAL_AMOUNT);
        // setValue("currency", invDetail.INV_CCY);
        // setValue("fromCompany", invDetail.SUPPLIER_COMPANY);
        // setValue("toCompany", invDetail.BILL_TO_COMPANY);

        getPo(objDetail.ORDERID);

        dispatch({
          type: cnt.RECORD_STATUS,
          payload: invDetail,
        });

        //get document
        // getSpecDoc(state.ggParam);

        // mergedDataArr
        setInputFldData([
          {
            name: appLabel.invoiceId,
            value: invDetail.INV_ID,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.currency,
            value: invDetail.INV_CCY,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.taxCode,
            value: taxDetail.TAX_CODE,
            fldType: "text",
            section: "tax",
          },
          {
            name: appLabel.rate,
            value: taxDetail.TAX_RATE,
            fldType: "text",
            section: "n/a",
          },
          {
            name: appLabel.amount,
            value: taxDetail.TAX_AMOUNT,
            fldType: "amount",
            section: "n/a",
          },
          {
            name: appLabel.excludeAmnt,
            value: taxDetail.EXCL_TAX_AMOUNT,
            fldType: "amount",
            section: "tax",
          },
          {
            name: appLabel.totalAmount,
            value: taxDetail.TOTAL_AMOUNT,
            fldType: "amount",
            section: "n/a",
          },
          {
            name: appLabel.acceptedDate,
            value: invDetail.ACCEPTED_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.submissionDate,
            value: invDetail.SUBMISSION_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.invDueDate,
            value: invDetail.INVOICE_DUE_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.invDate,
            value: invDetail.INV_DATE,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.planedPymtDate,
            value: invDetail.PLANNED_PAYMENT_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.invAmount,
            value: invDetail.ORIGINAL_AMOUNT,
            fldType: "amount",
            section: "general",
          },
          {
            name: appLabel.finalAmount,
            value: invDetail.FINAL_AMOUNT,
            fldType: "amount",
            section: "payment",
          },
          {
            name: appLabel.adjustment,
            value: invDetail.ADJUSTMENT,
            fldType: "amount",
            section: "payment",
          },
          {
            name: appLabel.discountAmount,
            value: invDetail.DISCOUNT_AMOUNT,
            fldType: "amount",
            section: "payment",
          },
          {
            name: appLabel.pymtOption,
            value: invDetail.PAYMENT_METHOD,
            fldType: "text",
            section: "general",
          },
        ]);
      }
    });
  };
  const getSpecDoc = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      id: params.INV_ID,
      type: "invoice",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setDocuments(res.docListing);
      }
    });
  };

  const getCompanyById = (
    companyId,
    setShipParam,
    setBillParam,
    setCompanyDetail
  ) => {
    let body = {
      profileId,
      companyId,
      session,
    };

    /** FETCH */
    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let companyDetail = res.companyDetail;

        setShipParam(companyDetail.SHIP_DATA);
        setBillParam(companyDetail.BILL_DATA);
        setCompanyDetail(companyDetail);
      }
    });
  };

  const getPo = (idParam) => {
    let body = {
      profileId,
      orderId: idParam,
      criteria: "A",
      companyId,
      session,
    };
    BOM.LoadAlert(cnt.LOAD, "Processing");

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetPo, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let orderDetail = res.purchaseOrderDetail;
        setOrderDetail(orderDetail);

        getCompanyById(
          orderDetail.PO_FROM_COMPANYID,
          setShipFromData,
          setBillFromData,
          setFromCompanyDetail
        );
        getCompanyById(
          orderDetail.PO_TO_COMPANYID,
          setShipToData,
          setBillToData,
          setToCompanyDetail
        );
        setIsPO(true);
      }
    });
  };

  const hideModal = () => {
    setShow(false);
  };

  const onAddInvestor = () => {
    if (!investorId) {
      BOM.AlertMsg(cnt.WARNING, "Please choose an Investor.");
      return;
    }

    const itemId = selectedInvestors.find(
      (item, index) => item.investorId === investorId
    );

    if (itemId) {
      BOM.AlertMsg(cnt.WARNING, "No duplicate Investor allowed.");
      return;
    }

    let investorObj = companyData.find(
      (item) => item.COMPANY_ID === investorId
    );
    setSelectedInvestors(BOM.GetUnique([
      ...selectedInvestors,
      {
        id: investorId,
        investorObj,
      },
    ], "id"));

    $(".investorInput").val("");
    // console.log(selectedInvestors);
  };

  const showModal = () => {
    // console.log("set show");
    setShow(true);
  };

  const removeItem = (index, type) => {
    switch (type.toLowerCase()) {
      case "document":
        setDocuments([
          ...documents.slice(0, index),
          ...documents.slice(index + 1),
        ]);
        break;
      case "investor":
        setSelectedInvestors([
          ...selectedInvestors.slice(0, index),
          ...selectedInvestors.slice(index + 1),
        ]);
        break;
    }
  };
  function getCompany() {
    let body = {
      profileId,
      companyId: "",
      criteria: "A",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        /** GET COMPANIES BASED TYPE */
        let tempCo = res.companyListing.filter(
          (item) => item.COMPANY_TYPE_ID === "CT2022012400003"
        );
        setInvestorData(tempCo);
        setCompanyData(res.companyListing);
      }
    });
  }
  const onSelectFundingType = (key) => {
    setIsPrivate(false);
    setIsBlock(false);
    // console.log(key);
    switch (key.toLowerCase()) {
      case "private":
        setIsPrivate(true);
        setIsBlock(true);
        break;

      default:
        setSelectedInvestors([]);
        setIsPrivate(false);
        setIsBlock(true);
        break;
    }
  };
  /** NEW FUNCTIONS */
  const onCalculate = () => {
    // console.log(invoiceDetail);
    // console.log(ggParam);

    if (!capitalRequired) {
      BOM.AlertMsg(
        cnt.WARNING,
        "Capital Required is required."
      );
      return;
    }
    //for loading and calculation screens
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setIsCalculated(true);
      RAL.disableEnableRightAction([cnt.RIGHT_SAVE_BTN]);
    }, 2000);

    let capitalRq = capitalRequired,
      arrangementFee = 0,
      surchargeObj = invoiceDetail.SURCHARGE_DATA;

      if (capitalRq <= 0) {
          capitalRq = parseFloat(ggParam.ORIGINAL_AMOUNT)
        }


    /** SURCHARGE CALCUL LOGIC */
    switch (Object.keys(surchargeObj).length === 0) { // check if the obj is empty
      case true:
            /** no further action needed */
        break;
    
      default:
          let chargeRate = parseFloat(surchargeObj.CHARGE_PERCENTAGE, 10) / 100;
          let maxChargeAmt = parseFloat(surchargeObj.CHARGE_LIMIT, 10);

          arrangementFee = capitalRq * chargeRate;

          if (arrangementFee > maxChargeAmt) {
              arrangementFee = maxChargeAmt;
          }
        break;
    }

    //prepare keys for the final request
    setValue("arrangementFee", arrangementFee);
    setValue("capitalRequired", capitalRq);
    // setCapitalRequired(parseFloat(capitalRq, 10));

    //get fee based on requested amount
    getFeeList(capitalRq + "");

    // mergedDataArr
    setAutoFldData([
      // {
      //   name: appLabel.capitalRequired,
      //   value: Number.isNaN(capitalRq) ? 0 : parseFloat(capitalRq, 10),
      //   fldType: "amount",
      //   section: "general",
      //   toolTip: toolTip.capitalRequired,
      // },
      {
        name: appLabel.arrangementFees,
        value: arrangementFee,
        fldType: "amount",
        section: "general",
        toolTip: null,
      },
    ]);
    
    getAmountReceivable()
  };
  function getFeeList(loanAmount) {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      feeId: "",
      ccy: ggParam.INV_CCY,
      companyTypeId,
      loanAmount,
      criteria: "",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetFees, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.feeListing;

        setOtherFee1Data(objList.filter((item) => item.FEE_OPTION.toLowerCase() === "other fee 1"));
        setOtherFee2Data(objList.filter((item) => item.FEE_OPTION.toLowerCase() === "other fee 2"));
      
      }
    });
  }
  const checkIsCalculated = () => {
    setIsCalculated(false);
    setAmountPayable1(0);
    setAmountPayable2(0);
    RAL.disableEnableRightAction();
  };
  const onSelectOtherFee = (idParam, setParam, arrParam, keyFee) => {
    setParam(0);
    setAmountReceivable(amountReceivable)
    let tempCalc = 0;

    if (!idParam) {
      switch (keyFee) {
        case "fee1":
          setAmountReceivable(amtPybles.amtPay1 + amountReceivable)
          amtPybles.amtPay1 =0;
          break;
        case "fee2":
          setAmountReceivable(amtPybles.amtPay2 + amountReceivable)
          amtPybles.amtPay2 =0;
          break;
      }
      return;
    }

    /** AMOUNT PAYABLE  CALCULATION*/
    const feeSelected = arrParam.find((item) => item.FEE_ID === idParam);

    switch (feeSelected.FLG_FEE_SLAB.toLowerCase()) {
      case "n":
        switch (feeSelected.FEE_TYPE.toLowerCase()) {
          case "flat":
            tempCalc = parseFloat(feeSelected.FEE_AMOUNT, 10);
            setParam(tempCalc);
            break;
          case "percentage":
            const tempRate = parseFloat(feeSelected.FEE_PERCENTAGE, 10) / 100;
            tempCalc = capitalRequired * tempRate;
            setParam(tempCalc);
            break;
        }
        break;
      default:
        //regardless of fee type the following will be done

        if (feeSelected.SLAB_DATA.length === 0) {
          switch (feeSelected.FEE_TYPE.toLowerCase()) {
            case "flat":
              tempCalc = parseFloat(feeSelected.FEE_AMOUNT, 10);
              setParam(tempCalc);
              break;
            case "percentage":
              const tempRate = parseFloat(feeSelected.FEE_PERCENTAGE, 10) / 100;
              tempCalc = capitalRequired * tempRate; //+ capitalRequired;
              setParam(tempCalc);
              break;
          }
        } else {
          //We use rate in the slab data rather in percentage
          const { SLAB_DATA } = feeSelected;
          const tempRate = parseFloat(SLAB_DATA[0].FEE_RATE, 10) / 100;
          tempCalc = capitalRequired * tempRate ; //+ capitalRequired;
          setParam(tempCalc);
        }
        break;
    }

    getAmountReceivable(tempCalc, keyFee);
  };
  function getAmountReceivable(amtParam=null, keyFee=null) {
  

    switch (keyFee) {
      case "fee1":
        amtPybles.amtPay1 = amtParam ? amtParam : 0;
        break;
      case "fee2":
        amtPybles.amtPay2 = amtParam ? amtParam : 0;
        break;

      default:
        amtPybles.amtPay1 =0;
        amtPybles.amtPay2 =0;
        break
    }


    const {amtPay1, amtPay2} = amtPybles; 
    const arrgmtFee = parseFloat(getValues("arrangementFee"), 10);
    const captRq = parseFloat(getValues("capitalRequired"), 10);
    const amtReceivable = captRq - (arrgmtFee + amtPay1 + amtPay2 )
    setAmountReceivable(amtReceivable)

   
    // console.log("capitalRequired: ", captRq);
    // console.log("arrgmtFee: ", arrgmtFee);
    // console.log("amtPay1: ", amtPay1);
    // console.log("amtPay2: ", amtPay2);
    // console.log("amtReceivable: ", amtReceivable );
    // console.log(amtPybles);
  }
  const formSubmit = (formValues) => {
    // if (documents.length === 0) {
    //   BOM.AlertMsg(cnt.WARNING, "Document upload is required.");
    //   return;
    // }

    if (amountReceivable < 0) {
      BOM.AlertMsg(cnt.WARNING, "Amount Receivable cannot be negative.");
      return;
    }

    if (parseFloat(capitalRequired) > parseFloat(ggParam.ORIGINAL_AMOUNT)) {
      BOM.AlertMsg(
        cnt.WARNING,
        appLabel.capitalRequired + " should no exceed the Invoice Amount."
      );
      return;
    }

    //Validate NaN value
    if(BOM.ValidateNaNValue(formValues.interestRate, appLabel.interestRate))return;
    
    if (isPrivate && selectedInvestors.length === 0) {
      BOM.AlertMsg(cnt.WARNING, "Investor is required.");
      return;
    }

    // extract Id
    let localInvestors = [];
    selectedInvestors.forEach((element) => {
      localInvestors.push({ id: element.id });
    });

    formValues.investors = localInvestors;
    formValues.capitalRequired = capitalRequired + "";
    formValues.amtPayable1 = amountPayable1 + "";
    formValues.amtPayable2 = amountPayable2 + "";
    formValues.arrangementFee = formValues.arrangementFee + "";
    formValues.tenor = parseFloat(formValues.tenor);
    
    let body = {
      profileId,
      session,
      ...formValues,
    };

    if (isPrivate) {
      Notiflix.Confirm.show(
        "Are you sure?",
        notif.notif1,
        "Proceed",
        "Cancel",
        function () {
          // Yes
          // console.log(body);
          // return
          /** SEND REQ */
          BOM.LoadAlert(cnt.LOAD, "Processing");
          BOM.SendReqAction(body, api.ShareInvoice, "/manage-invoice");
        },
        function () {
          // No button
          // console.log("cancels: ", findCustodian);
        }
      );
    } else {
      // console.log(body);
      // return
      /** SEND REQ */
      BOM.LoadAlert(cnt.LOAD, "Processing");
      BOM.SendReqAction(body, api.ShareInvoice, "/manage-invoice");
    }
  };
  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <RightSide />
        <ContentTop pageTitle={appLabel.newShareInvoice} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{appLabel.invoiceInformation}</h5>
                  <div className="ibox-tools">
                    <Link
                      to="/manage-invoice"
                      className="btn btn-primary btn-xs"
                    >
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.selectType}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="col-sm-6 m-b-xs">
                        <div data-toggle="buttons" className="btn-group">
                          {appStatic.requestFundingType.map((item) => (
                            <label
                              onClick={() => {
                                onSelectFundingType(item.value);
                                setValue("shareType", item.value);
                              }}
                              className="btn btn-sm btn-white"
                              key={item.value}
                            >
                              {" "}
                              <input type="radio" />{" "}
                              <i
                                className={`fa fa-${
                                  item.value === "private" ? "user" : "users"
                                }`}
                              />{" "}
                              &nbsp; {item.name}{" "}
                            </label>
                          ))}
                        </div>
                      </div>
                      <div className={`col-sm-6 ${isBlock ? "" : "hide"}`}>
                        <a className="btn btn-primary btn-rounded btn-block">
                          <i
                            className={`text-warning fa fa-${
                              isPrivate ? "user" : "users"
                            }`}
                          />{" "}
                          &nbsp; {isPrivate ? "Private " : "Public "} Funding
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className={`row ${isBlock ? "" : "hide"}`}>
                    <Col12BlockFacility topTitle={appLabel.generalInfo}>
                      {inputFldData.map((item, index) => (
                        <div key={index}>
                          {item.section === "general" &&
                            (item.fldType === "amount" ? (
                              <InputAmountFacility
                                inputLabel={item.name}
                                inputValue={item.value}
                              />
                            ) : (
                              <InputTextFacility
                                inputLabel={item.name}
                                inputValue={item.value}
                              />
                            ))}
                        </div>
                      ))}
                      <NoDataFac arrList={inputFldData} />
                    </Col12BlockFacility>
                    <div className="col-sm-12">
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.capitalRequired}&nbsp;
                            <Tooltips title={toolTip.capitalRequired} />
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <AmountFacility
                            setIsRequiredField={setIsCapitalRqdFld}
                            setAmount={setCapitalRequired}
                            isRequiredField={isCapitalRqdFld}
                            callbackFunc={() => checkIsCalculated(false)}
                          />
                        </div>
                      </div>
                      
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.interestRate}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.interestRate}
                            {...register("interestRate", {
                              required: "Field is required.",
                              min: {
                                value: 1,
                                message: "Rate cannot be less than 1%",
                              },
                              max: {
                                value: 100,
                                message: "Rate cannot be greater than 100%",
                              },
                            })}
                          />
                          <span className="asterisks">
                            {errors?.interestRate?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.duration}&nbsp;
                            <Tooltips title={toolTip.tenor} />
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="number"
                            className="form-control input-sm"
                            placeholder={appLabel.duration}
                            {...register("tenor", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.tenor?.message}
                          </span>
                        </div>
                      </div>
                    </div>
                    
                    {!isCalculated && (
                      <Col12BlockFacility>
                        <div className="col-md-6">
                          <button
                            type="button"
                            onClick={() => onCalculate()}
                            className={`btn btn-xs ${
                              isLoading ? "btn-white" : "btn-success"
                            }`}
                          >
                            {/* <i className="fa fa-refresh" /> */}
                            <i
                              className={`fa ${
                                isLoading
                                  ? "fa-spinner fa-spin text-navy"
                                  : "fa-refresh"
                              }`}
                            />
                            &nbsp;{" "}
                            {isLoading
                              ? "Calculating..."
                              : appLabel.displayFeeBtn}
                          </button>
                        </div>
                      </Col12BlockFacility>
                    )}
                    {isCalculated && (
                      <Col12BlockFacility topTitle={appLabel.calculationInfo}>
                        <div className="row form-group col-md-6">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.otherFee1}&nbsp;
                            </label>
                          </div>
                          <div className="col-md-7">
                            <select
                              className="form-control input-sm"
                              onChange={(e) =>
                                onSelectOtherFee(
                                  e.target.value,
                                  setAmountPayable1,
                                  otherFee1Data,
                                  "fee1"
                                )
                              }
                            >
                              <option value="">{appLabel.choose}</option>
                              {otherFee1Data.map((item, index) => (
                                <option value={item.FEE_ID} key={index}>
                                  {item.FEE_NAME}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="row form-group col-md-6">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.otherFee2}&nbsp;
                            </label>
                          </div>
                          <div className="col-md-7">
                            <select
                              className="form-control input-sm"
                              onChange={(e) =>
                                onSelectOtherFee(
                                  e.target.value,
                                  setAmountPayable2,
                                  otherFee2Data,
                                  "fee2"
                                )
                              }
                            >
                              <option value="">{appLabel.choose}</option>
                              {otherFee2Data.map((item, index) => (
                                <option value={item.FEE_ID} key={index}>
                                  {item.FEE_NAME}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="row form-group col-md-6 ">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.amountPayable1}
                            </label>
                          </div>
                          <div className="col-md-7">
                            <NumberFormat
                              className="form-control input-sm"
                              value={amountPayable1}
                              displayType="text"
                              disabled
                              thousandSeparator={true}
                            />
                          </div>
                        </div>
                        <div className="row form-group col-md-6 ">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.amountPayable2}
                            </label>
                          </div>
                          <div className="col-md-7">
                            <NumberFormat
                              className="form-control input-sm"
                              value={amountPayable2}
                              displayType="text"
                              disabled
                              thousandSeparator={true}
                            />
                          </div>
                        </div>
                        {autoFldData.map((item, index) => (
                          <div key={index}>
                            {item.section === "general" &&
                              (item.fldType === "amount" ? (
                                <InputAmountFacility
                                  inputLabel={item.name}
                                  inputValue={item.value}
                                  toolTipLabel={item.toolTip}
                                />
                              ) : (
                                <InputTextFacility
                                  inputLabel={item.name}
                                  inputValue={item.value}
                                  toolTipLabel={item.toolTip}
                                />
                              ))}
                          </div>
                        ))}
                        <div className="row form-group col-md-6 ">
                          <div className="col-md-4 text-left">
                            <label className="al-subtitle">
                              {appLabel.amountReceivable}
                            </label>
                          </div>
                          <div className="col-md-7">
                            <NumberFormat
                              className="form-control input-sm"
                              value={amountReceivable}
                              displayType="text"
                              disabled
                              thousandSeparator={true}
                            />
                          </div>
                        </div>
                      </Col12BlockFacility>
                    )}
                  </div>
                  <div className={`row ${isBlock ? "" : "hide"}`}>
                    <Col12BlockFacility topTitle={appLabel.otherInfo}>
                      {/* <NavTabFac tabsList={tabData.invShareList} /> */}
                      <ul className="nav nav-tabs">
                        <li className={` ${isPrivate ? "active" : "hide"}`}>
                          <a
                            data-toggle="tab"
                            href="#investorInfo"
                            target="_self"
                          >
                            <i className="fa fa-tag text-navy" />
                            &nbsp;{appLabel.investorInfo}&nbsp;
                            <Tooltips title={toolTip.investorInfo} />
                          </a>
                        </li>
                        <li className={` ${isPrivate ? "" : "active"}`}>
                          <a
                            data-toggle="tab"
                            href="#addressInfo"
                            target="_self"
                          >
                            <i className="fa fa-home text-navy" />
                            &nbsp;{appLabel.address}
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div
                          className={` ${
                            isPrivate ? "tab-pane active" : "hide"
                          }`}
                          id="investorInfo"
                        >
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-12">
                              <div className="row">
                                <AppModal
                                  show={show}
                                  title={"Add Multiple"}
                                  handleClose={() => hideModal()}
                                  handleSave={() => onAddInvestor()}
                                >
                                  <div className=" form-group col-md-6">
                                    <label className="al-subtitle">
                                      {appLabel.investorName}&nbsp;
                                      <span className="asterisks">*</span>
                                    </label>
                                    <select
                                      className="form-control input-sm investorInput"
                                      onChange={(e) =>
                                        setInvestorId(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {investors.map((item, index) => (
                                        <option
                                          value={item.COMPANY_ID}
                                          key={index}
                                        >
                                          {item.COMPANY_NAME}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </AppModal>

                                {!show && (
                                  <div className="col-sm-2">
                                    <div className="title-action">
                                      <button
                                        type="button"
                                        title="Add more Materials"
                                        className="btn btn-primary btn-sm"
                                        onClick={() => showModal()}
                                      >
                                        <i className="fa fa-plus" />
                                      </button>
                                      <div
                                        className="hr-line"
                                        style={{ marginBottom: "10px" }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="table-responsive">
                                {selectedInvestors.length === 0 ? (
                                  <div className="alert alert-warning text-center">
                                    <a className="alert-link">
                                      {appLabel.noDataInTableMsg}
                                    </a>
                                  </div>
                                ) : (
                                  <div>
                                    <table
                                      className="footable table table-stripped toggle-arrow-tiny"
                                      data-page-size="10"
                                      data-filter="#filter"
                                    >
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>{appLabel.investorId}</th>
                                          <th>{appLabel.investorName}</th>
                                          <th>{appLabel.companyRegNo}</th>
                                          <th>{appLabel.companyType}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {selectedInvestors.map(
                                          (item, index) => (
                                            <tr className="gradeX" key={index}>
                                              <td>
                                                {index + 1}{" "}
                                                <i
                                                  style={{ cursor: "pointer" }}
                                                  className="fa fa-times-circle text-danger fa-2x"
                                                  onClick={() =>
                                                    removeItem(
                                                      index,
                                                      "investor"
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>{item.id}</td>
                                              <td>
                                                {item.investorObj.COMPANY_NAME}
                                              </td>
                                              <td>
                                                {item.investorObj.COMPANY_REGNO}
                                              </td>
                                              <td>
                                                {
                                                  item.investorObj
                                                    .COMPANY_TYPE_NAME
                                                }
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td colSpan="5">
                                            <ul className="pagination pull-right"></ul>
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={` ${
                            isPrivate ? "tab-pane" : "tab-pane active"
                          }`}
                          id="addressInfo"
                        >
                          <div className="row" style={{ marginTop: "15px" }}>
                            <Col12BlockFacility topTitle={null}>
                              <div className="col-md-3">
                                <div className="well">
                                  <address>
                                    <h4>
                                      {" "}
                                      <i className="fa fa-building text-navy"></i>
                                      &nbsp; {appLabel.shipBuyerAddress}
                                    </h4>{" "}
                                    <br />
                                    <i className="fa fa-circle text-navy"></i>{" "}
                                    &nbsp; {shipFromData.SHIPPING_ADDRESS_1},{" "}
                                    {shipFromData.SHIPPING_CITY_NAME}
                                    <br />
                                    {shipFromData.SHIPPING_PROVINCE_NAME},{" "}
                                    {shipFromData.SHIPPING_COUNTRY_NAME}{" "}
                                    {shipFromData.SHIPPING_POST_CODE}
                                  </address>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="well">
                                  <address>
                                    <h4>
                                      {" "}
                                      <i className="fa fa-building text-navy"></i>
                                      &nbsp;{appLabel.bilBuyerAddress}
                                    </h4>{" "}
                                    <i className="fa fa-circle text-navy"></i>
                                    &nbsp;{billFromData.BILLING_ADDRESS_1},{" "}
                                    {billFromData.BILLING_CITY_NAME}
                                    <br />
                                    {billFromData.BILLING_PROVINCE_NAME},{" "}
                                    {billFromData.BILLING_COUNTRY_NAME}{" "}
                                    {billFromData.BILLING_POST_CODE}
                                  </address>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="well">
                                  <address>
                                    <h4>
                                      {" "}
                                      <i className="fa fa-building text-navy"></i>
                                      &nbsp; {appLabel.shipSupplierAddress}
                                    </h4>{" "}
                                    <br />
                                    <i className="fa fa-circle text-navy"></i>{" "}
                                    &nbsp; {shipToData.SHIPPING_ADDRESS_1},{" "}
                                    {shipToData.SHIPPING_CITY_NAME}
                                    <br />
                                    {shipToData.SHIPPING_PROVINCE_NAME},{" "}
                                    {shipToData.SHIPPING_COUNTRY_NAME}{" "}
                                    {shipToData.SHIPPING_POST_CODE}
                                  </address>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="well">
                                  <address>
                                    <h4>
                                      {" "}
                                      <i className="fa fa-building text-navy"></i>
                                      &nbsp;{appLabel.bilSupplierAddress}
                                    </h4>{" "}
                                    <i className="fa fa-circle text-navy"></i>
                                    &nbsp;{billToData.BILLING_ADDRESS_1},{" "}
                                    {billToData.BILLING_CITY_NAME}
                                    <br />
                                    {billToData.BILLING_PROVINCE_NAME},{" "}
                                    {billToData.BILLING_COUNTRY_NAME}{" "}
                                    {billToData.BILLING_POST_CODE}
                                  </address>
                                </div>
                              </div>
                            </Col12BlockFacility>
                          </div>
                        </div>
                      </div>
                    </Col12BlockFacility>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <RecordStatus {...recordData} />
        </div>
      </form>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewShareInvoice);
