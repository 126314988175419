import React, { useEffect, useState } from "react";
import { apis as api } from "../../services/api.action";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import { Link } from "react-router-dom";
import RecordStatus from "../ui-setup/RecordStatus";
import NumberFormat from "react-number-format";
import Notiflix from "notiflix";
import OtpModal from "../layouts/OtpModal";
import {
  TableSimpleFac, AmntFormatFac,
  Tooltips,
  InputAmountFacility,
  Col12BlockFacility,
  InputTextFacility,
  NoDataFac,
  NavTabFac,
  AmountFacility,
} from "../layouts/Facility";


export const ConsentedInvoiceDetail = (props) => {
  const { location, userData, configData, recordData, menuData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId, companyTypeId, userName } = userData;
  const { isCsd } = configData;
  const { tableData, notif } = appStatic;
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  const dispatch = useDispatch();
  const [isFullPayment, setIsFullPayment] = useState(false);
  const [isCsdCheck, setIsCsdCheck] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [orderDetail, setOrderDetail] = useState({});
  const [fundingDetail, setFundingDetail] = useState({});
  const [shippingData, setShippingData] = useState({});
  const [primaryData, setPrimaryData] = useState({});
  const [billingData, setBillingData] = useState({});
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [contractDetail, setContractDetail] = useState({});
  const [financeDetail, setFinanceDetail] = useState({});
  const [financeHistoryData, setFinanceHistoryData] = useState([]);
  const [isFunded, setIsFunded] = useState(false);
  const [surchargeDetail, setSurchargeDetail] = useState({});
  const [isSupplier, setIsSupplier] = useState(false);
  const [isFunder, setIsFunder] = useState(false);
  const [otp, setOtp] = useState("");
  const [show, setShow] = useState(false);
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const [inputFldData, setInputFldData] = useState([]);
  let tempDocument = [];

  useEffect(() => {
    // console.log(ggParam);
    handleSwitchCondition();
    getDetailById(ggParam);

    //CALL INVOICE DOCUMENT BLOCK
    BOM.getSpecDoc(ggParam?.INV_ID, setDocuments, userData);
    // //GET 2nd DOCUMENT
    // setTimeout(() => {
    // getSpecDoc(state?.ggParam?.INV_ID, "sharing");
    // }, 1500);
  }, []);

  const handleSwitchCondition = () => {
    if (isCsd.toLowerCase() === "y") {
      setIsCsdCheck(true);
    }
    switch (companyTypeId) {
      case "CT2022012400002": // supplier
        setIsSupplier(true);
        break;
      case "CT2022012400003": // funder
        setIsFunder(true);
        break;
    }
  };
  const getPo = (idParam) => {
    let body = {
      profileId,
      orderId: idParam,
      criteria: "A",
      companyId,
      session,
    };
    BOM.LoadAlert(cnt.LOAD, "Processing");

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetPo, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let orderDetail = res.purchaseOrderDetail;
        setOrderDetail(orderDetail);
      }
    });
  };
  const onChoosePaymentOption = (type) => {
    // console.log(type);
    switch (type.toLowerCase()) {
      case "instalment":
        setIsFullPayment(true);
        break;
      default:
        setIsFullPayment(false);
        break;
    }
  };
  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      invoiceId: params.INV_ID,
      companyId,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetConsentedInvoice, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.invoiceDetail;
        const invDetail = objDetail.INVOICE_DATA;
        const taxDetail = objDetail.TAX_DATA;
        setInvoiceDetail(invDetail);
        setContractDetail(objDetail.CONTRACT_DATA);
        setFinanceDetail(objDetail.BIDDING_DATA);
        setFinanceHistoryData(objDetail.FUND_HISTORY_DATA);

        dispatch({
          type: cnt.RECORD_STATUS,
          payload: invDetail,
        });

        //call additional function
        // getSpecDoc(objDetail);
        getPo(objDetail.ORDERID);

        // mergedDataArr
        setInputFldData([
          {
            name: appLabel.invoiceId,
            value: invDetail.INV_ID,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.currency,
            value: invDetail.INV_CCY,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.taxCode,
            value: taxDetail.TAX_CODE,
            fldType: "text",
            section: "tax",
          },
          {
            name: appLabel.rate,
            value: taxDetail.TAX_RATE,
            fldType: "text",
            section: "n/a",
          },
          {
            name: appLabel.taxAmount,
            value: taxDetail.TAX_AMOUNT,
            fldType: "amount",
            section: "n/a",
          },
          {
            name: appLabel.excludeAmnt,
            value: taxDetail.EXCL_TAX_AMOUNT,
            fldType: "amount",
            section: "tax",
          },
          {
            name: appLabel.invAmount,
            value: taxDetail.TOTAL_AMOUNT,
            fldType: "amount",
            section: "general",
          },
          {
            name: appLabel.acceptedDate,
            value: invDetail.ACCEPTED_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.submissionDate,
            value: invDetail.SUBMISSION_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.invDueDate,
            value: invDetail.INVOICE_DUE_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.invDate,
            value: invDetail.INV_DATE,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.planedPymtDate,
            value: invDetail.PLANNED_PAYMENT_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.originalAmount,
            value: invDetail.ORIGINAL_AMOUNT,
            fldType: "amount",
            section: "payment",
          },
          {
            name: appLabel.finalAmount,
            value: invDetail.FINAL_AMOUNT,
            fldType: "amount",
            section: "n/a",
          },
          {
            name: appLabel.adjustment,
            value: invDetail.ADJUSTMENT,
            fldType: "amount",
            section: "payment",
          },
          {
            name: appLabel.discountAmount,
            value: invDetail.DISCOUNT_AMOUNT,
            fldType: "amount",
            section: "payment",
          },
          {
            name: appLabel.pymtOption,
            value: invDetail.PAYMENT_METHOD,
            fldType: "text",
            section: "general",
          },
        ]);
      }
    });
  };
  const getSpecDocOLD = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      id: params.ORDERID,
      type: "purchaseorder",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setDocuments(res.docListing);
      }
    });
  };
  const getSpecDoc = async (id, type) => {
    let body = {
      profileId,
      id,
      type,
      session,
    };

    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        switch (type?.toLowerCase()) {
          case "invoice":
            res.docListing.forEach((element) => {
              tempDocument.push(element);
            });
            break;

          default:
            res.docListing.forEach((element) => {
              tempDocument.push(element);
            });
            break;
        }

        setDocuments([...tempDocument]);
      }
    });
  };

  const showModal = () => {
    // console.log("set show");
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };
  const onGenerateOtp = () => {
    $(".clearInput").val("");
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      // userName:"gedeon@openfactorgroup.com",
      userName,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GeneratePoToken, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        console.log(res);
        showModal();
      }
    });
  };

  const onResendOtp = () => {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      // userName:"gedeon@openfactorgroup.com",
      userName,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.ResendOtp, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        BOM.AlertMsg(cnt.SUCCESS, res.message);
        //display inner popup
        showModal();
        $(".clearInput").val("");
      }
    });
  };

  const onValidOtp = (orderDetail) => {
    setOtpErrorMsg("");

    if (!otp) {
      setOtpErrorMsg("OTP is required.");
    }
    if (otp.length < 6) {
      setOtpErrorMsg("Not less than 6 digits");
      return;
    }
    if (otp.length > 6) {
      setOtpErrorMsg("Not greater than 6 digits");
      return;
    }
    let body = {
      profileId,
      otp,
      session,
    };

    BOM.LoadAlert(cnt.LOAD, "Processing");
    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.OtpValidation, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        $(".clearInput").val("");
        // call to update
        onAppoveConsentStatusPo(orderDetail);
      }
    });
  };
  const onUpdateConsentStatusPo = (objParam) => {
    let body = {
      profileId,
      broadcastId: objParam.BROADCAST_ID,
      orderId: state.ggParam.ORDERID,
      session,
    };

    // confirm
    Notiflix.Confirm.show(
      "Confrim Funding",
      "Click Yes to Approve or No to Reject Funding.",
      "Yes",
      "No",
      function () {
        // Yes
        //generate OTP
        onGenerateOtp();
      },
      function () {
        // No button
        BOM.LoadAlert(cnt.LOAD, "Processing");
        body.criteria = "R";
        BOM.SendReqAction(body, api.ApproveRejectConsent, "financed-order");
        // console.log(body);
      }
    );
  };

  const onAppoveConsentStatusPo = (objParam) => {
    let body = {
      profileId,
      broadcastId: objParam.BROADCAST_ID,
      orderId: state.ggParam.ORDERID,
      session,
    };

    // console.log(body);

    BOM.LoadAlert(cnt.LOAD, "Processing");
    body.criteria = "A";
    BOM.SendReqAction(body, api.ApproveRejectConsent, "financed-order");
  };

  const onApprove = () => {
    let bodyApprove = {
      profileId,
      orderId: state.ggParam.ORDERID,
      criteria: "A",
      session,
    };

    // console.log(bodyApprove);
    BOM.GlobalApproveRejectAction(
      bodyApprove,
      api.ApproveRejectFundedPo,
      "financed-order",
      "Order",
      "Approve "
    );
  };
  const onReject = () => {
    let bodyDisable = {
      profileId,
      orderId: state.ggParam.ORDERID,
      criteria: "R",
      session,
    };

    // console.log(bodyDisable);
    BOM.GlobalApproveRejectAction(
      bodyDisable,
      api.ApproveRejectFundedPo,
      "financed-order",
      "PO",
      "Reject "
    );
  };

  return (
    <Main {...props} onApprove={onApprove} onReject={onReject}>
      <ContentTop pageTitle={appLabel.fundedInvoiceDetail} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{appLabel.invoiceInformation}</h5>
                <div className="ibox-tools">
                  <Link to="/funded-invoice" className="btn btn-primary btn-xs">
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;
                    {appLabel.backBtn}
                  </Link>
                  &nbsp;
                  {orderDetail.FLG_SUPPLIER_CONSENT === "U" &&
                    isSupplier &&
                    !show && (
                      <span>
                        <button
                          type="button"
                          className="btn btn-primary btn-xs"
                          onClick={() => onUpdateConsentStatusPo(orderDetail)}
                        >
                          <i className="fa fa-thumbs-up" />
                          &nbsp;
                          {appLabel.confirmFunding}
                        </button>
                      </span>
                    )}
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                  <OtpModal
                    show={show}
                    title={"OTP"}
                    handleClose={() => hideModal()}
                    handleResend={() => onResendOtp()}
                    handleSave={() => onValidOtp(orderDetail)}
                  >
                    <div className="row form-group col-md-12 ">
                      <label className="col-md-offset-4 al-subtitle">
                        {appLabel.enterOtp}
                        <span className="asterisks">*</span>
                      </label>
                      <label className="col-md-offset-1 al-subtitle">
                        <small>{userName}</small>
                      </label>
                      <input
                        type="number"
                        className="form-control input-sm clearInput"
                        placeholder="******"
                        onChange={(e) => setOtp(e.target.value)}
                      />
                      <span className="asterisks">{otpErrorMsg}</span>
                    </div>
                  </OtpModal>
                </div>
                <div className="row">
                  <Col12BlockFacility topTitle={appLabel.generalInfo}>
                    {inputFldData.map((item, index) => (
                      <div key={index}>
                        {item.section === "general" &&
                          (item.fldType === "amount" ? (
                            <InputAmountFacility
                              inputLabel={item.name}
                              inputValue={item.value}
                            />
                          ) : (
                            <InputTextFacility
                              inputLabel={item.name}
                              inputValue={item.value}
                            />
                          ))}
                      </div>
                    ))}
                    <NoDataFac arrList={inputFldData} />
                  </Col12BlockFacility>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.otherInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <ul className="nav nav-tabs">
                      <li className="active">
                        <a
                          data-toggle="tab"
                          href="#documentInfo"
                          target="_self"
                        >
                         <i className="fa fa-file-pdf-o text-navy"  />
                          &nbsp;{appLabel.documentInfo}
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#pOInfo" target="_self">
                         <i className="fa fa-cart-plus text-navy"  />
                          &nbsp;{appLabel.pOInfoShort}
                        </a>
                      </li>
                      <li>
                        <a
                          data-toggle="tab"
                          href="#contractInfo"
                          target="_self"
                        >
                         <i className="fa fa-file-text text-navy"  />
                          &nbsp;{appLabel.contractInfoShort}
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#financeInfo" target="_self">
                          <i className="fa fa-money text-navy" />
                          &nbsp;{appLabel.financeInfoShort}
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane active" id="documentInfo">
                        <div className="row" style={{ marginTop: "30px" }}>
                          <div className="col-sm-12">
                            {documents.length === 0 ? (
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDocumentMsg}
                                </a>
                              </div>
                            ) : (
                              <div>
                                {documents.map((item, index) => (
                                  <div className="col-md-4" key={index}>
                                    <div className="file-box">
                                      <div className="file">
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            BOM.DownloadAction(
                                              item.DOC_OBJECT,
                                              item.DOC_ID +
                                                " " +
                                                item.DOC_CATEGORY.toLowerCase()
                                            )
                                          }
                                        >
                                          <span className="corner" />
                                          <div className="icon">
                                           <i className="fa fa-file-pdf-o" style={{color:'#d50000'}} />
                                          </div>
                                          <div className="file-name">
                                            {item.DOC_CATEGORY}
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <div className="tab-pane" id="invoiceInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          {Object.keys(invoiceDetail).length === 0 ? (
                            <div className="col-sm-12">
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDataInTableMsg}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-12">
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.invoiceId}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={invoiceDetail.INV_ID}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.to}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={
                                      invoiceDetail.SUPPLIER_COMPANY
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.originalAmount}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <NumberFormat
                                    className="form-control input-sm"
                                    value={invoiceDetail.ORIGINAL_AMOUNT}
                                    displayType="text"
                                    disabled
                                    thousandSeparator={true}
                                    prefix={" "}
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.finalAmount}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <NumberFormat
                                    className="form-control input-sm"
                                    value={invoiceDetail.FINAL_AMOUNT}
                                    displayType="text"
                                    disabled
                                    thousandSeparator={true}
                                    prefix={""}
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.currency}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={invoiceDetail.INV_CCY}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.startDate}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={
                                      invoiceDetail.CONTR_START_DATE
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div> */}
                      <div className="tab-pane" id="pOInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          {Object.keys(orderDetail).length === 0 ? (
                            <div className="col-sm-12">
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDataMsg}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-12">
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.poNo}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.ORDERID}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.from}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.FROM_COMPANY_NAME}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.to}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.TO_COMPANY_NAME}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.amount}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <NumberFormat
                                    className="form-control input-sm"
                                    value={orderDetail.PO_AMOUNT}
                                    displayType="text"
                                    disabled
                                    thousandSeparator={true}
                                    prefix={""}
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.currency}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.PO_CCY}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.pymtOption}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.PO_PAYMENT_OPTION}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="tab-pane" id="contractInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          {Object.keys(contractDetail).length === 0 ? (
                            <div className="col-sm-12">
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDataInTableMsg}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-12">
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.contractId}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={contractDetail.PO_CONTRACT_ID}
                                    disabled
                                  />
                                </div>
                              </div>

                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.type}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={contractDetail.TRANS_TYPE}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.value}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <NumberFormat
                                    className="form-control input-sm"
                                    value={contractDetail.CONTR_VALUE}
                                    displayType="text"
                                    disabled
                                    thousandSeparator={true}
                                    prefix={""}
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.currency}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={contractDetail.CONTR_CCY}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.startDate}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={
                                      contractDetail.CONTR_START_DATE
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="tab-pane" id="financeInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.history}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />

                              <div className="table-responsive">
                                {financeHistoryData.length === 0 ? (
                                  <div className="alert alert-warning text-center">
                                    <a className="alert-link">
                                      {appLabel.noDataInTableMsg}
                                    </a>
                                  </div>
                                ) : (
                                  <TableSimpleFac
                                    thData={tableData.historyBidList}
                                  >
                                    {financeHistoryData.map((item, index) => (
                                      <tr className="gradeX" key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.ITEM_ID}</td>
                                        <td>{item.ITEM_TYPE}</td>
                                        <td>
                                          <AmntFormatFac
                                            inputValue={item.AMOUNT_REQUESTED}
                                            currency={item.CCY}
                                          />
                                        </td>
                                        <td>
                                          <AmntFormatFac
                                            inputValue={item.AMOUNT_FUNDED}
                                            currency={item.CCY}
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                  </TableSimpleFac>
                                )}
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RecordStatus {...recordData} />
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsentedInvoiceDetail);
