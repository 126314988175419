/**
 * @author Gedeon NdundeCode
 * @date 05/12/2021
 */
import React, { useState, useEffect } from "react";
import useGeolocation from "react-hook-geolocation";
import { apis as api } from "../../services/api.action";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import { SignOutAction } from "../../services/redux/actions/auth-act";
import { cnt } from "../../services/constant.action";
import { BOM, RAL } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import Bg from "../../assets/img/logoLogin/bg3.jpg";
import { BgImgFac } from "../layouts/Facility";
import { SubmitBtnLoad, OnClickBtnLoad, Tooltips } from "../layouts/Facility";
import PatternFormat from "react-number-format";

const Registration = (props) => {
  /** INITIALIZE VARIABLES */

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    getValues: getValues2,
    setValue: setValue2,
  } = useForm();

  const { SignOutAction, match } = props;
  const { toolTip } = appStatic;

  const [isItemType, setIsItemType] = useState(false);
  const [itemIdLabel, setItemIdLabel] = useState("");
  // const [countryData, setCountryData] = useState([]);
  // const [provincePrimaryData, setProvincePrimaryData] = useState([]);
  // const [cityPrimaryData, setCityPrimaryData] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  // const [isEditableFld, setIsEditableFld] = useState(false);

  const { history } = props;

  // NEWWWWW
  const [countryData, setCountryData] = useState([]);
  const [provincePrimaryData, setProvincePrimaryData] = useState([]);
  const [provinceShippingData, setProvinceShippingData] = useState([]);
  const [provinceBillingData, setProvinceBillingData] = useState([]);
  const [cityPrimaryData, setCityPrimaryData] = useState([]);
  const [cityShippingData, setCityShippingData] = useState([]);
  const [cityBillingData, setCityBillingData] = useState([]);
  const [isFunder, setIsFunder] = useState(false);
  const [companyTypeData, setCompanyTypeData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [isBusiness, setIsBusiness] = useState(false);
  const [isIndividual, setIsIndividual] = useState(false);
  const [tempFile, setTempFile] = useState(false);
  const [docCategory, setDocCategory] = useState("");
  const [documents, setDocuments] = useState([]);

  const [isShipAsPrim, setIsShipAsPrim] = useState(false);
  const [isBillAsPrim, setIsBillAsPrim] = useState(false);
  const [shipAsPrimData, setShipAsPrimData] = useState({});
  const [billAsPrim, setBillAsPrimData] = useState({});
  const currentDate = BOM.CurrentDate();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditableFld, setIsEditableFld] = useState(false);

  const [verifyKycInput, setVerifyKycInput] = useState({});

  useEffect(() => {
    getCountry();
    // getCompanyType();
    getBank();
    SignOutAction();
    $("select").removeClass("input-md");
  }, []);

  const getCountry = () => {
    let body = {
      countryId: "",
    };

    BOM.FetchReqAction(body, api.GetCountry, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setCountryData(res.countryListing);
      }
    });
  };
  const getProvince = (countryId, type) => {
    // console.log(countryId);
    if (!countryId) return;

    switch (type.toLowerCase()) {
      case "primary":
        getGlobalProvince(countryId, setProvincePrimaryData);
        break;
      case "shipping":
        getGlobalProvince(countryId, setProvinceShippingData);
        break;
      case "billing":
        getGlobalProvince(countryId, setProvinceBillingData);
        break;
    }
  };
  const getCity = (stateId, type) => {
    if (!stateId) return;
    // console.log(stateId);

    switch (type.toLowerCase()) {
      case "primary":
        const provinceObj = provincePrimaryData.find(
          (item, index) => item.ID === stateId
        );

        setValue2("finsysProviceId", provinceObj.FINSYS_ID);
        // console.log(provinceObj);
        getGlobalCity(stateId, setCityPrimaryData);
        break;
      case "shipping":
        getGlobalCity(stateId, setCityShippingData);
        break;
      case "billing":
        getGlobalCity(stateId, setCityBillingData);
        break;
    }
  };
  const onChooseCompanyType = (type) => {
    // console.log(type);
    switch (type) {
      case "CT2022012400003":
        setIsFunder(!isFunder);
        break;
      default:
        setIsFunder(false);
        break;
    }
  };
  function getGlobalProvince(countryId, setProvince) {
    // console.log(countryId);
    let body = {
      countryId,
    };
    BOM.FetchReqAction(body, api.GetCountry, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setProvince(res.provinceListing);
      }
    });
  }
  function getGlobalCity(stateId, setCity) {
    // console.log("wow", stateId);
    let body = {
      stateId,
    };

    BOM.FetchReqAction(body, api.GetCity, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        //
        setCity(res.cityListing);
      }
    });
  }
  function getCompanyType() {
    /** FETCH MENU */
    let body = {
      // profileId,
      // criteria: "A",
      // companyTypeId: "",
      // session,
    };
    BOM.FetchReqAction(body, api.GetCompanyType, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        // get all entity type except custodian
        let tempCompany = res.companyTypeListing.filter(
          (item) => item.COMPANY_TYPE_ID !== "CT2022012400000"
        );
        setCompanyTypeData(tempCompany);
      }
    });
  }
  function getBank() {
    /** FETCH MENU */
    let body = {};
    BOM.FetchReqAction(body, api.GetProspectBank, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setBankData(res.bankListing);
      }
    });
  }
  const onInvestorType = (key) => {
    // console.log(key);
    switch (key.toLowerCase()) {
      case "business":
        setIsBusiness(true);
        setIsIndividual(false);
        break;
      default:
        setIsIndividual(true);
        setIsBusiness(false);
        break;
    }
  };
  const handleFileRead = async (event, setState_param) => {
    const file = event.target.files[0];
    if (!file) {
      setState_param("");
      return;
    }

    if (file.size > 5242880) {
      BOM.AlertMsg(cnt.WARNING, "File size cannot exceed 5 MB");
      $(".documentInput").val("");
      return;
    }

    switch (file.type.toLowerCase()) {
      case "application/pdf":
        const base64 = await BOM.convertBase64(file);
        let strB64 = base64.split("base64,")[1]; //.replace(/^data:image\/[a-z]+;base64,/, "");
        setState_param(strB64);
        break;

      default:
        BOM.AlertMsg(cnt.WARNING, "Document type must be PDF.");
        $(".documentInput").val("");
        break;
    }

    // console.log(file.type);
  };
  const uploadFile = () => {
    // console.log(tempFile);

    const itemId = documents.find(
      (item, index) => item.docCategory === docCategory
    );

    if (itemId) {
      BOM.AlertMsg(cnt.WARNING, "No duplicate file allowed!");
      return;
    }

    if (!tempFile || !docCategory) {
      BOM.AlertMsg(cnt.WARNING, "Document Type and upload are required");
      return;
    }

    setDocuments([
      ...documents,
      {
        base64: tempFile,
        docType: "company",
        docCategory,
      },
    ]);

    $(".documentInput").val("");
    setTempFile(false);
  };
  const removeItem = (index) => {
    setDocuments([...documents.slice(0, index), ...documents.slice(index + 1)]);
  };
  const onSelectAddressAs = (key) => {
    if (
      !getValues2("countryId") ||
      !getValues2("provinceId") ||
      !getValues2("cityId")
    ) {
      BOM.AlertMsg(
        cnt.WARNING,
        "Fields with * under Primary Address are required."
      );
      $("#shipping").prop("checked", false);
      $("#billing").prop("checked", false);
      setIsShipAsPrim(false);
      setIsBillAsPrim(false);
      return;
    }
    // console.log(isShipAsPrim);

    switch (key) {
      case "shipping":
        switch (!isShipAsPrim) {
          case true:
            setValue2("shipCountryId", getValues2("countryId"));
            setValue2("shipProvinceId", getValues2("provinceId"));
            setValue2("shipCityId", getValues2("cityId"));
            setValue2("shipAddress1", getValues2("address1"));
            setValue2("shipAddress2", getValues2("address2"));
            setValue2("shipPostCode", getValues2("postCode"));
            setIsShipAsPrim(!isShipAsPrim);
            break;

          default:
            setValue2("shipCountryId", "");
            setValue2("shipProvinceId", "");
            setValue2("shipCityId", "");
            setValue2("shipAddress1", "");
            setValue2("shipAddress2", "");
            setValue2("shipPostCode", "");
            setShipAsPrimData({});
            setIsShipAsPrim(!isShipAsPrim);
            break;
        }

        break;

      case "billing":
        switch (!isBillAsPrim) {
          case true:
            setValue2("billCountryId", getValues2("countryId"));
            setValue2("billProvinceId", getValues2("provinceId"));
            setValue2("billCityId", getValues2("cityId"));
            setValue2("billAddress1", getValues2("address1"));
            setValue2("billAddress2", getValues2("address2"));
            setValue2("billPostCode", getValues2("postCode"));
            setIsBillAsPrim(!isBillAsPrim);
            break;

          default:
            setValue2("billCountryId", "");
            setValue2("billProvinceId", "");
            setValue2("billCityId", "");
            setValue2("billAddress1", "");
            setValue2("billAddress2", "");
            setValue2("billPostCode", "");
            setIsBillAsPrim(!isBillAsPrim);
            break;
        }
        break;
    }
  };

  const onVerifyKyc = (formValues) => {
    formValues.investorType = appStatic.companyTypeData.find(
      (item) => item.id === formValues.companyTypeId
    )["name"];
    console.log(formValues);
    // return

    if (formValues.contactEmail === formValues.contactEmail2) {
      BOM.AlertMsg(
        cnt.WARNING,
        "The primary and secondary contact emails must not be the same."
      );
      return;
    }

   //company TIN validation
   if (formValues?.taxNumValidateValue?.length !== 12) {
    BOM.AlertMsg(cnt.WARNING, `The company TIN should have 13 characters, 12 numbers, and a - character, as seen below. XXXXXXXX-XXXX
    `);
    return;
  }else{
      delete formValues?.taxNumValidateValue;
  }

    switch (formValues.companyTypeId) {
      case "CT2022012400003": // IF FUNDER TYPE
        formValues.companyName = formValues.investorName;
        break;
      case "CT2022012400001": // IF BUYER TYPE
        break;
      default: // DEFAULT TO LAD FOR ALL COY TYPE EXCEPT BUYER
        break;
    }

    setIsLoading(true);
    // setIsEditableFld(false);
    RAL.disableEnableRightAction();

    let body = {
      // profileId,
      module: "company",
      ...formValues,
      // session,
    };

    // console.log(body);
    // setIsLoading(false);
    // setIsEditableFld(true);
    // RAL.disableEnableRightAction([cnt.RIGHT_SAVE_BTN]);
    // return;

    BOM.FetchReqAction(body, api.ProspectKycVerification, (err, res) => {
      if (err) {
        setIsLoading(false);
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.kycDetails;

        switch (objDetail.code.toLowerCase()) {
          case "partial verification":
            BOM.AlertMsg(cnt.WARNING, objDetail.message);
            break;
          default:
            BOM.AlertMsg(cnt.SUCCESS, objDetail.message);
            break;
        }

        setIsLoading(false);
        setIsEditableFld(true);
        formValues.companyId = objDetail.companyId;
        setVerifyKycInput(formValues);
        RAL.disableEnableRightAction([cnt.RIGHT_SAVE_BTN]);
      }
    });
  };
  const validator = (data) => {
    // this function has been called each time to validate fields in real time
  };

  const formSubmit = (formValues) => {
    // console.log(verifyKycInput);

    //merg objects
    formValues = { ...formValues, ...verifyKycInput };

    if (documents.length === 0) {
      BOM.AlertMsg(cnt.WARNING, "Document upload is required.");
      return;
    }

    formValues.roleId1 = "RL0000000000001"; // DEFAULT TO SAD FOR ALL COY TYPE.
    switch (formValues.companyTypeId) {
      case "CT2022012400003": // IF FUNDER TYPE
        // formValues.companyName = formValues.investorName;
        formValues.roleId2 = "RL0000000000003"; // DEFAULT TO LAD FOR ALL COY TYPE EXCEPT BUYER
        break;
      case "CT2022012400001": // IF BUYER TYPE
        formValues.roleId2 = "RL0000000000004"; // DEFAULT TO VRF BUYER ONLY
        break;
      default: // DEFAULT TO LAD FOR ALL COY TYPE EXCEPT BUYER
        formValues.roleId2 = "RL0000000000003";
        break;
    }

    // console.log("data", formValues);
    formValues.documents = []; //documents;
    formValues.financialYear = "";

    let body = {
      // profileId,
      // session,
      ...formValues,
    };

    // // console.log(body);
    let body2 = {
      // profileId,
      documents,
    };

    // console.log(body);
    // return;
    /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendTwoReqAction(
      body,
      api.CreateProspectCompany,
      body2,
      api.CreateProspectGenericDocument,
      "/sign-in"
    );
  };

  // const onVerifyKyc = () => {
  //   setIsLoading(true);
  //   setIsEditableFld(false);

  //   RAL.disableEnableRightAction();

  //   setTimeout(() => {
  //     setIsLoading(false);
  //     setIsEditableFld(true);
  //     RAL.disableEnableRightAction([cnt.RIGHT_SAVE_BTN]);
  //   }, 500);
  // };

  return (
    <React.Fragment>
      <BgImgFac />

      <div className="">
        <div className="row">
          <div className="col-md-12">
            <header>
              <div
                className="animated fadeInDown login-card"
                style={{
                  maxWidth: "800px",
                  zIndex: "100",
                  margin: "0 auto",
                  //  paddingTop: "40px",
                  marginBottom: "8%",
                  marginTop: "8%",
                }}
              >
                <div className="slogan text-center">
                  <h2>
                    <i className="fa fa-circle-o-notch text-navy" />{" "}
                    <small>{appLabel.welcomeTo}</small>&nbsp;{appLabel.factorEx}
                  </h2>
                </div>
                <p>Fill out the form to get started.</p>
                <form
                  // className="m-t"
                  onSubmit={handleSubmit2(formSubmit, BOM.onError)}
                  autoComplete="off"
                  onBlur={handleSubmit2(validator)}
                >
                  <div className="row">
                    <div className="col-sm-12">
                      <ul className="nav nav-tabs">
                        <li className="active">
                          <a data-toggle="tab" href="#companyInfo">
                            <i className="fa fa-building text-navy" />
                            &nbsp;{appLabel.companyInfo}
                          </a>
                        </li>
                        {/* <li className={`${isEditableFld ? "" : "hide"}`}>
                          <a data-toggle="tab" href="#contactInfo">
                            <i className="fa fa-users text-navy" />
                            &nbsp;{appLabel.contactInforma}
                          </a>
                        </li> */}
                        <li className={`${isEditableFld ? "" : "hide"}`}>
                          <a data-toggle="tab" href="#addressInfo">
                            <i className="fa fa-home text-navy" />
                            &nbsp;{appLabel.address}
                          </a>
                        </li>
                        <li className={`${isEditableFld ? "" : "hide"}`}>
                          <a data-toggle="tab" href="#statutoryInfo">
                            <i className="fa fa-tag text-navy" />
                            &nbsp;{appLabel.statutoryInfo}
                          </a>
                        </li>
                        <li className={`${isEditableFld ? "" : "hide"}`}>
                          <a data-toggle="tab" href="#documentInfo">
                            <i className="fa fa-file-pdf-o text-navy" />
                            &nbsp;{appLabel.documentInfoShort}
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="companyInfo">
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.generalInfo}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />
                              <div className="form-group col-md-4 ">
                                <label className="al-subtitle">
                                  {appLabel.companyType} &nbsp;
                                  <Tooltips title={toolTip.companyType} />
                                  <span className="asterisks"> *</span>
                                </label>
                                <select
                                  className="form-control input-md"
                                  disabled={isEditableFld}
                                  name="companyTypeId"
                                  {...register("companyTypeId", {
                                    onChange: (e) =>
                                      onChooseCompanyType(e.target.value),
                                    required: "Field is required.",
                                  })}
                                >
                                  <option value="">{appLabel.choose}</option>
                                  {appStatic.companyTypeData.map(
                                    (item, index) => (
                                      <option value={item.id} key={index}>
                                        {item.name}
                                      </option>
                                    )
                                  )}
                                </select>
                                <span className="asterisks">
                                  {errors?.companyTypeId?.message}
                                </span>
                              </div>
                              <div
                                className={`form-group col-md-4 ${
                                  isFunder ? "hide" : ""
                                }
                      `}
                              >
                                <label className="al-subtitle">
                                  {appLabel.companyName}{" "}
                                  <span className="asterisks">* </span>
                                  <Tooltips title={"company name"} />
                                </label>
                                <input
                                  disabled={isEditableFld}
                                  type="text"
                                  className="form-control input-md"
                                  placeholder={appLabel.companyName}
                                  {...register("companyName", {
                                    required: !isFunder
                                      ? "Field is required."
                                      : "",
                                    minLength: {
                                      value: 3,
                                      message:
                                        "Company Name should be greater than 3 characters",
                                    },
                                    maxLength: {
                                      value: 60,
                                      message:
                                        "Company Name shouldn't be greater than 60 characters",
                                    },
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.companyName?.message}
                                </span>
                              </div>
                              <div
                                className={`form-group col-md-4 ${
                                  isFunder ? "" : "hide"
                                }`}
                              >
                                <label className="al-subtitle">
                                  {appLabel.investorType}{" "}
                                  <span className="asterisks">*</span>
                                </label>
                                <select
                                  disabled={isEditableFld}
                                  className="form-control input-md"
                                  {...register("investorType", {
                                    onChange: (e) =>
                                      onInvestorType(e.target.value),
                                    required: isFunder
                                      ? "Field is required."
                                      : "",
                                  })}
                                >
                                  <option value="">{appLabel.choose}</option>
                                  {appStatic.investorType.map((item, index) => (
                                    <option value={item.id} key={index}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                                <span className="asterisks">
                                  {errors?.investorType?.message}
                                </span>
                              </div>
                              <div
                                className={`form-group col-md-4 ${
                                  isFunder ? "" : "hide"
                                }`}
                              >
                                <label className="al-subtitle">
                                  {appLabel.investorName}{" "}
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  disabled={isEditableFld}
                                  type="text"
                                  className="form-control input-md"
                                  placeholder={appLabel.investorName}
                                  {...register("investorName", {
                                    required: isFunder
                                      ? "Field is required."
                                      : "",
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.investorName?.message}
                                </span>
                              </div>
                              <div
                                className={`form-group col-md-4
                       ${isFunder && isIndividual ? "" : "hide"}`}
                              >
                                <label className="al-subtitle">
                                  {appLabel.clientTitle}{" "}
                                  <span className="asterisks">*</span>
                                </label>
                                <select
                                  disabled={isEditableFld}
                                  className="form-control input-md"
                                  {...register("clientTitle", {
                                    required:
                                      isFunder && isIndividual
                                        ? "Field is required."
                                        : "",
                                  })}
                                >
                                  <option disabled>{appLabel.choose}</option>
                                  {appStatic.clientTitle.map((item, index) => (
                                    <option value={item.value} key={index}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                                <span className="asterisks">
                                  {errors?.clientTitle?.message}
                                </span>
                              </div>
                              <div
                                className={`form-group col-md-4 
                      ${isFunder && isIndividual ? "" : "hide"}`}
                              >
                                <label className="al-subtitle">
                                  {appLabel.clientType}{" "}
                                </label>
                                <select
                                  disabled={isEditableFld}
                                  className="form-control input-md"
                                  {...register("clientType", {
                                    required:
                                      isFunder && isIndividual
                                        ? "Field is required."
                                        : "",
                                  })}
                                >
                                  <option disabled>{appLabel.choose}</option>
                                  {appStatic.clientType.map((item, index) => (
                                    <option value={item.id} key={index}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                                <span className="asterisks">
                                  {errors?.clientType?.message}
                                </span>
                              </div>
                              <div
                                className={`form-group col-md-4
                       ${isFunder && isIndividual ? "" : "hide"}`}
                              >
                                <label className="al-subtitle">
                                  {appLabel.bvnRcNumber}{" "}
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  disabled={isEditableFld}
                                  type="number"
                                  className="form-control input-md"
                                  placeholder={appLabel.bvnRcNumber}
                                  {...register("bvn", {
                                    required:
                                      isFunder && isIndividual
                                        ? "Field is required."
                                        : "",
                                    minLength: {
                                      value: 11,
                                      message: "Not less than 11 digits",
                                    },
                                    maxLength: {
                                      value: 11,
                                      message: "Not greater than 11 digits",
                                    },
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.bvn?.message}
                                </span>
                              </div>
                              <div
                                className={`form-group col-md-4 ${
                                  isIndividual ? "hide" : ""
                                }`}
                              >
                                <label className="al-subtitle">
                                  {appLabel.companyRegNo}{" "}
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  disabled={isEditableFld}
                                  type="text"
                                  className="form-control input-md"
                                  placeholder={appLabel.companyRegNo}
                                  {...register("regno", {
                                    required: isIndividual
                                      ? false
                                      : "Field is required.",
                                    minLength: {
                                      value: 8,
                                      message: "Not less than 8 digits",
                                    },
                                    maxLength: {
                                      value: 10,
                                      message: "Not greater than 10 digits",
                                    },
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.regno?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-4">
                                <label className="al-subtitle">
                                  {appLabel.vatId} &nbsp;
                                  <Tooltips title={toolTip.vatId} />
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  disabled={isEditableFld}
                                  type="number"
                                  className="form-control input-md"
                                  placeholder={appLabel.vatId}
                                  {...register("vatId", {
                                    required: "Field is required.",
                                    minLength: {
                                      value: 1,
                                      message: "Not less than 1 character",
                                    },
                                    maxLength: {
                                      value: 40,
                                      message: "Not greater than 40 characters",
                                    },
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.vatId?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-4">
                                <label className="al-subtitle">
                                  {appLabel.tradingName}{" "}
                                  <Tooltips title={toolTip.tradingName} />
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  disabled={isEditableFld}
                                  type="text"
                                  className="form-control input-md"
                                  placeholder={appLabel.tradingName}
                                  {...register("tradingName", {
                                    required: "Field is required.",
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.tradingName?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-4">
                                <label className="al-subtitle">
                                  {appLabel.companyTax}{" "}
                                  <Tooltips title={toolTip.taxId} />
                                  <span className="asterisks">*</span>
                                </label>
                                {/* <input
                                  disabled={isEditableFld}
                                  type="text"
                                  className="form-control input-md"
                                  placeholder={appLabel.companyTax}
                                  {...register("taxNum", {
                                    required: "Field is required.",
                                    minLength: {
                                      value: 13,
                                      message: "Not less than 13 digits",
                                    },
                                    maxLength: {
                                      value: 13,
                                      message: "Not greater than 13 digits",
                                    },
                                  })}
                                /> */}
                                <PatternFormat
                                  format="########-####"
                                  className="form-control input-md"
                                  {...register("taxNum", {
                                    required: "Field is required.",
                                    minLength: {
                                      value: 13,
                                      message: "Not less than 13 digits",
                                    },
                                    maxLength: {
                                      value: 13,
                                      message: "Not greater than 13 digits",
                                    },
                                  })}
                                  onValueChange={(values) => {
                                    setValue("taxNum",values.formattedValue);
                                    setValue("taxNumValidateValue",values.value);
                                    // console.log("taxid pattern: ", values);
                                  }}
                                  // valueIsNumericString={true}
                                />
                                <span className="asterisks">
                                  {errors?.taxNum?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-4">
                                <label className="al-subtitle ">
                                  {appLabel.companyEmail}{" "}
                                  <Tooltips title={toolTip.companyEmail} />
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  disabled={isEditableFld}
                                  type="text"
                                  className="form-control input-md"
                                  placeholder={appLabel.email}
                                  {...register("email", {
                                    required: "Field is required.",
                                    pattern: {
                                      value:
                                        /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                                      message: "Email must be valid.",
                                    },
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.email?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-4">
                                <label className="al-subtitle">
                                  {appLabel.website}&nbsp;
                                  <Tooltips title={toolTip.website} />
                                  {/* <span className="asterisks">*</span> */}
                                </label>
                                <input
                                  disabled={isEditableFld}
                                  type="text"
                                  className="form-control input-md"
                                  placeholder={appLabel.website}
                                  {...register("website", {
                                    required: false, //"Field is required.",
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.website?.message}
                                </span>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.primaryContact} &nbsp;{" "}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />
                              <div className="form-group col-md-4">
                                <label className="al-subtitle">
                                  {appLabel.contactName}{" "}
                                  <Tooltips title={toolTip.contactName} />
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  disabled={isEditableFld}
                                  type="text"
                                  className="form-control input-md"
                                  placeholder={appLabel.contactName}
                                  {...register("contactPerson", {
                                    required: "Field is required.",
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.contactPerson?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-4">
                                <label className="al-subtitle">
                                  {appLabel.officePhone}&nbsp;
                                  <Tooltips title={toolTip.officePhone} />
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  disabled={isEditableFld}
                                  type="number"
                                  className="form-control input-md"
                                  placeholder={appLabel.officePhone}
                                  {...register("officePhone", {
                                    required: "Field is required.",
                                    minLength: {
                                      value: 10,
                                      message: "Not less than 10 digits",
                                    },
                                    maxLength: {
                                      value: 15,
                                      message: "Not greater than 15 digits",
                                    },
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.officePhone?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-4">
                                <label className="al-subtitle ">
                                  {appLabel.contactEmail}{" "}
                                  <Tooltips title={toolTip.contactEmail} />
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  disabled={isEditableFld}
                                  type="text"
                                  className="form-control input-md"
                                  placeholder={appLabel.contactEmail}
                                  {...register("contactEmail", {
                                    required: "Field is required.",
                                    pattern: {
                                      value:
                                        /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                                      message: "Email must be valid.",
                                    },
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.contactEmail?.message}
                                </span>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.otherContact}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />

                              <div className="form-group col-md-4">
                                <label className="al-subtitle">
                                  {appLabel.contactName}{" "}
                                  <Tooltips title={toolTip.otherContactName} />
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  disabled={isEditableFld}
                                  type="text"
                                  className="form-control input-md"
                                  placeholder={appLabel.contactName}
                                  {...register("contactPerson2", {
                                    required: "Field is required.",
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.contactPerson2?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-4">
                                <label className="al-subtitle">
                                  {appLabel.officePhone}
                                  <Tooltips title={toolTip.otherOfficePhone} />
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  disabled={isEditableFld}
                                  type="number"
                                  className="form-control input-md"
                                  placeholder={appLabel.officePhone}
                                  {...register("officePhone2", {
                                    required: "Field is required.",
                                    minLength: {
                                      value: 10,
                                      message: "Not less than 10 digits",
                                    },
                                    maxLength: {
                                      value: 15,
                                      message: "Not greater than 15 digits",
                                    },
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.officePhone2?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-4">
                                <label className="al-subtitle ">
                                  {appLabel.contactEmail}{" "}
                                  <Tooltips title={toolTip.otherContactEmail} />
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  disabled={isEditableFld}
                                  type="text"
                                  className="form-control input-md"
                                  placeholder={appLabel.contactEmail}
                                  {...register("contactEmail2", {
                                    required: "Field is required.",
                                    pattern: {
                                      value:
                                        /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                                      message: "Email must be valid.",
                                    },
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.contactEmail2?.message}
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* <div className={`row ${isEditableFld ? "hide" : ""}`}>
                            <div className="col-sm-12">
                              <div>
                                <hr />
                                <div className="form-group">
                                  <div className="col-sm-6">
                                    <OnClickBtnLoad
                                      isLoading={isLoading}
                                      btnName={appLabel.nextBtn}
                                      isFullWidth={false}
                                      icon={"fa fa-arrow-circle-right"}
                                      callbackFn={onVerifyKyc}
                                      // callbackFn={handleSubmit(onVerifyKyc)}
                                      processMsg={appLabel.kycProcessVrfyMsg}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        {/* <div className="tab-pane" id="contactInfo">
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.primaryContact} &nbsp;{" "}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />
                              <div className="form-group col-md-4">
                                
                                  <label className="al-subtitle">
                                    {appLabel.contactName}{" "}
                                    <Tooltips title={toolTip.contactName} />
                                    <span className="asterisks">*</span>
                                  </label>
                                  <input
                                    disabled={isEditableFld}
                                    type="text"
                                    className="form-control input-md"
                                    placeholder={appLabel.contactName}
                                    {...register("contactPerson", {
                                      required: "Field is required.",
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.contactPerson?.message}
                                  </span>
                              </div>
                              <div className="form-group col-md-4">
                                  <label className="al-subtitle">
                                    {appLabel.officePhone}&nbsp;
                                    <Tooltips title={toolTip.officePhone} />
                                    <span className="asterisks">*</span>
                                  </label>
                                  <input
                                    disabled={isEditableFld}
                                    type="number"
                                    className="form-control input-md"
                                    placeholder={appLabel.officePhone}
                                    {...register("officePhone", {
                                      required: "Field is required.",
                                      minLength: {
                                        value: 10,
                                        message: "Not less than 10 digits",
                                      },
                                      maxLength: {
                                        value: 15,
                                        message: "Not greater than 15 digits",
                                      },
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.officePhone?.message}
                                  </span>
                              </div>
                              <div className="form-group col-md-4">
                                  <label className="al-subtitle ">
                                    {appLabel.contactEmail}{" "}
                                    <Tooltips title={toolTip.contactEmail} />
                                    <span className="asterisks">*</span>
                                  </label>
                                  <input
                                    disabled={isEditableFld}
                                    type="text"
                                    className="form-control input-md"
                                    placeholder={appLabel.contactEmail}
                                    {...register("contactEmail", {
                                      required: "Field is required.",
                                      pattern: {
                                        value:
                                          /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                                        message: "Email must be valid.",
                                      },
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.contactEmail?.message}
                                  </span>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.otherContact}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />

                              <div className="form-group col-md-4">
                                  <label className="al-subtitle">
                                    {appLabel.contactName}{" "}
                                    <Tooltips
                                      title={toolTip.otherContactName}
                                    />
                                    <span className="asterisks">*</span>
                                  </label>
                                  <input
                                    disabled={isEditableFld}
                                    type="text"
                                    className="form-control input-md"
                                    placeholder={appLabel.contactName}
                                    {...register("contactPerson2", {
                                      required: "Field is required.",
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.contactPerson2?.message}
                                  </span>
                              </div>
                              <div className="form-group col-md-4">
                                  <label className="al-subtitle">
                                    {appLabel.officePhone}
                                    <Tooltips
                                      title={toolTip.otherOfficePhone}
                                    />
                                    <span className="asterisks">*</span>
                                  </label>
                                  <input
                                    disabled={isEditableFld}
                                    type="number"
                                    className="form-control input-md"
                                    placeholder={appLabel.officePhone}
                                    {...register("officePhone2", {
                                      required: "Field is required.",
                                      minLength: {
                                        value: 10,
                                        message: "Not less than 10 digits",
                                      },
                                      maxLength: {
                                        value: 15,
                                        message: "Not greater than 15 digits",
                                      },
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.officePhone2?.message}
                                  </span>
                              </div>
                              <div className="form-group col-md-4">
                                  <label className="al-subtitle ">
                                    {appLabel.contactEmail}{" "}
                                    <Tooltips
                                      title={toolTip.otherContactEmail}
                                    />
                                    <span className="asterisks">*</span>
                                  </label>
                                  <input
                                    disabled={isEditableFld}
                                    type="text"
                                    className="form-control input-md"
                                    placeholder={appLabel.contactEmail}
                                    {...register("contactEmail2", {
                                      required: "Field is required.",
                                      pattern: {
                                        value:
                                          /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                                        message: "Email must be valid.",
                                      },
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors?.contactEmail2?.message}
                                  </span>
                              </div>
                            </div>
                          </div>
                          <div className={`row ${isEditableFld ? "hide" : ""}`}>
                            <div className="col-sm-12">
                              <div>
                                <hr />
                                <div className="form-group">
                                  <div className="col-sm-6">
                                    <OnClickBtnLoad
                                      isLoading={isLoading}
                                      btnName={appLabel.nextBtn}
                                      isFullWidth={false}
                                      icon={"fa fa-arrow-circle-right"}
                                      // callbackFn={onVerifyKyc}
                                      callbackFn={handleSubmit(onVerifyKyc)}
                                      processMsg={appLabel.kycProcessVrfyMsg}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="tab-pane" id="addressInfo">
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.primaryAddress}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />
                              <div className="form-group col-md-4">
                                <label className="al-subtitle">
                                  {appLabel.country}{" "}
                                  <Tooltips title={toolTip.country} />
                                  <span className="asterisks">*</span>
                                </label>
                                <select
                                  className="form-control input-md"
                                  {...register2("countryId", {
                                    onChange: (e) =>
                                      getProvince(e.target.value, "primary"),
                                    required: "Field is required.",
                                  })}
                                >
                                  <option value="">{appLabel.choose}</option>
                                  {countryData.map((item, index) => (
                                    <option value={item.ID} key={index}>
                                      {item.COUNTRY_NAME}
                                    </option>
                                  ))}
                                </select>
                                <span className="asterisks">
                                  {errors2?.countryId?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-4">
                                <label className="al-subtitle">
                                  {appLabel.province}{" "}
                                  <Tooltips title={toolTip.province} />
                                  <span className="asterisks">*</span>
                                </label>
                                <select
                                  className="form-control input-md"
                                  {...register2("provinceId", {
                                    onChange: (e) =>
                                      getCity(e.target.value, "primary"),
                                    required: "",
                                  })}
                                >
                                  <option value="">{appLabel.choose}</option>
                                  {provincePrimaryData.map((item, index) => (
                                    <option value={item.ID} key={index}>
                                      {item.PROVINCE_NAME}
                                    </option>
                                  ))}
                                </select>
                                <span className="asterisks">
                                  {errors2?.provinceId?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-4">
                                <label className="al-subtitle">
                                  {appLabel.city}{" "}
                                  <Tooltips title={toolTip.city} />
                                  <span className="asterisks">*</span>
                                </label>
                                <select
                                  className="form-control input-md"
                                  {...register2("cityId", {
                                    required: "Field is required.",
                                  })}
                                >
                                  <option value="">{appLabel.choose}</option>
                                  {cityPrimaryData.map((item, index) => (
                                    <option value={item.ID} key={index}>
                                      {item.CITY_NAME}
                                    </option>
                                  ))}
                                </select>
                                <span className="asterisks">
                                  {errors2?.cityId?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-4">
                                <label className="al-subtitle">
                                  {appLabel.address1}{" "}
                                  <Tooltips title={toolTip.address1} />
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-md"
                                  placeholder={`${appLabel.address} 1`}
                                  {...register2("address1", {
                                    required: "Field is required.",
                                  })}
                                />
                                <span className="asterisks">
                                  {errors2?.address1?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-4">
                                <label className="al-subtitle">
                                  {appLabel.address2}&nbsp;
                                  <Tooltips title={toolTip.address2} />
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-md"
                                  placeholder={`${appLabel.address} 2`}
                                  {...register2("address2")}
                                />
                              </div>
                              <div className="form-group col-md-4">
                                <label className="al-subtitle">
                                  {appLabel.postCode}&nbsp;
                                  <Tooltips title={toolTip.postCode} />
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-md"
                                  placeholder={`${appLabel.postCode} e.g GA-123-4567`}
                                  {...register2("postCode", {
                                    required: "Field is required.",
                                  })}
                                />
                                <span className="asterisks">
                                  {errors2?.postCode?.message}
                                </span>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.shippingAddress} &nbsp;{" "}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />
                              <div className="col-md-12">
                                <label className="al-subtitle">
                                  <i className="fa fa-circle text-navy" />{" "}
                                  &nbsp;
                                  {appLabel.sameAsPrimary}&nbsp;{" "}
                                  <input
                                    onClick={() => {
                                      setIsShipAsPrim(!isShipAsPrim);
                                      onSelectAddressAs("shipping");
                                    }}
                                    style={{ cursor: "pointer" }}
                                    type="checkbox"
                                    id="shipping"
                                  />
                                </label>
                                <div
                                  className="hr-line-dashed"
                                  style={{ marginTop: 0 }}
                                />
                                <div
                                  className={`alert alert-warning text-center ${
                                    isShipAsPrim ? "" : "hide"
                                  }`}
                                >
                                  <a className="alert-link">
                                    {appLabel.shipSameAsPrimaryAddress}
                                  </a>
                                </div>
                              </div>
                              <div className={`${!isShipAsPrim ? "" : "hide"}`}>
                                <div className="form-group col-md-4">
                                  <label className="al-subtitle">
                                    {appLabel.country}{" "}
                                    <Tooltips title={toolTip.country} />
                                    <span className="asterisks">*</span>
                                  </label>
                                  <select
                                    className="form-control input-md"
                                    {...register2("shipCountryId", {
                                      onChange: (e) =>
                                        getProvince(e.target.value, "shipping"),
                                      required: "Field is required.",
                                    })}
                                  >
                                    <option value="">{appLabel.choose}</option>
                                    {countryData.map((item, index) => (
                                      <option value={item.ID} key={index}>
                                        {item.COUNTRY_NAME}
                                      </option>
                                    ))}
                                  </select>
                                  <span className="asterisks">
                                    {errors2?.shipCountryId?.message}
                                  </span>
                                </div>
                                <div className="form-group col-md-4">
                                  <label className="al-subtitle">
                                    {appLabel.province}{" "}
                                    <Tooltips title={toolTip.province} />
                                    <span className="asterisks">*</span>
                                  </label>
                                  <select
                                    className="form-control input-md"
                                    {...register2("shipProvinceId", {
                                      onChange: (e) =>
                                        getCity(e.target.value, "shipping"),
                                      required: "Field is required.",
                                    })}
                                  >
                                    <option value="">{appLabel.choose}</option>
                                    {provinceShippingData.map((item, index) => (
                                      <option value={item.ID} key={index}>
                                        {item.PROVINCE_NAME}
                                      </option>
                                    ))}
                                  </select>
                                  <span className="asterisks">
                                    {errors2?.shipProvinceId?.message}
                                  </span>
                                </div>
                                <div className="form-group col-md-4">
                                  <label className="al-subtitle">
                                    {appLabel.city}{" "}
                                    <Tooltips title={toolTip.city} />
                                    <span className="asterisks">*</span>
                                  </label>
                                  <select
                                    className="form-control input-md"
                                    {...register2("shipCityId", {
                                      required: "Field is required.",
                                    })}
                                  >
                                    <option value="">{appLabel.choose}</option>
                                    {cityShippingData.map((item, index) => (
                                      <option value={item.ID} key={index}>
                                        {item.CITY_NAME}
                                      </option>
                                    ))}
                                  </select>
                                  <span className="asterisks">
                                    {errors2?.shipCityId?.message}
                                  </span>
                                </div>
                                <div className="form-group col-md-4">
                                  <label className="al-subtitle">
                                    {appLabel.address1}{" "}
                                    <Tooltips title={toolTip.address1} />
                                    <span className="asterisks">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control input-md"
                                    placeholder={`${appLabel.address} 1`}
                                    {...register2("shipAddress1", {
                                      required: "Field is required.",
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors2?.shipAddress1?.message}
                                  </span>
                                </div>
                                <div className="form-group col-md-4">
                                  <label className="al-subtitle">
                                    {appLabel.address2}{" "}
                                    <Tooltips title={toolTip.address2} />
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control input-md"
                                    placeholder={`${appLabel.address} 2`}
                                    {...register2("shipAddress2")}
                                  />
                                </div>
                                <div className="form-group col-md-4">
                                  <label className="al-subtitle">
                                    {appLabel.postCode}{" "}
                                    <Tooltips title={toolTip.postCode} />
                                    <span className="asterisks">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control input-md"
                                    placeholder={`${appLabel.postCode}`}
                                    {...register2("shipPostCode", {
                                      required: "Field is required.",
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors2?.shipPostCode?.message}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.billingAddress}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />
                              <div className="col-md-12">
                                <label className="al-subtitle">
                                  <i className="fa fa-circle text-navy" />{" "}
                                  &nbsp;
                                  {appLabel.sameAsPrimary}&nbsp;{" "}
                                  <input
                                    onClick={() => {
                                      setIsBillAsPrim(!isBillAsPrim);
                                      onSelectAddressAs("billing");
                                    }}
                                    style={{ cursor: "pointer" }}
                                    type="checkbox"
                                    id="billing"
                                  />
                                </label>
                                <div
                                  className="hr-line-dashed"
                                  style={{ marginTop: 0 }}
                                />
                                <div
                                  className={`alert alert-warning text-center ${
                                    isBillAsPrim ? "" : "hide"
                                  }`}
                                >
                                  <a className="alert-link">
                                    {appLabel.billSameAsPrimaryAddress}
                                  </a>
                                </div>
                              </div>
                              <div className={`${!isBillAsPrim ? "" : "hide"}`}>
                                <div className="form-group col-md-4">
                                  <label className="al-subtitle">
                                    {appLabel.country}{" "}
                                    <Tooltips title={toolTip.country} />
                                    <span className="asterisks">*</span>
                                  </label>
                                  <select
                                    className="form-control input-md"
                                    {...register2("billCountryId", {
                                      onChange: (e) =>
                                        getProvince(e.target.value, "billing"),
                                      required: "Field is required.",
                                    })}
                                  >
                                    <option value="">{appLabel.choose}</option>
                                    {countryData.map((item, index) => (
                                      <option value={item.ID} key={index}>
                                        {item.COUNTRY_NAME}
                                      </option>
                                    ))}
                                  </select>
                                  <span className="asterisks">
                                    {errors2?.billCountryId?.message}
                                  </span>
                                </div>
                                <div className="form-group col-md-4">
                                  <label className="al-subtitle">
                                    {appLabel.province}{" "}
                                    <Tooltips title={toolTip.province} />
                                    <span className="asterisks">*</span>
                                  </label>
                                  <select
                                    className="form-control input-md"
                                    {...register2("billProvinceId", {
                                      onChange: (e) =>
                                        getCity(e.target.value, "billing"),
                                      required: "Field is required.",
                                    })}
                                  >
                                    <option value="">{appLabel.choose}</option>
                                    {provinceBillingData.map((item, index) => (
                                      <option value={item.ID} key={index}>
                                        {item.PROVINCE_NAME}
                                      </option>
                                    ))}
                                  </select>
                                  <span className="asterisks">
                                    {errors2?.billProvinceId?.message}
                                  </span>
                                </div>
                                <div className="form-group col-md-4">
                                  <label className="al-subtitle">
                                    {appLabel.city}{" "}
                                    <Tooltips title={toolTip.city} />
                                    <span className="asterisks">*</span>
                                  </label>
                                  <select
                                    className="form-control input-md"
                                    {...register2("billCityId", {
                                      required: "Field is required.",
                                    })}
                                  >
                                    <option value="">{appLabel.choose}</option>
                                    {cityBillingData.map((item, index) => (
                                      <option value={item.ID} key={index}>
                                        {item.CITY_NAME}
                                      </option>
                                    ))}
                                  </select>
                                  <span className="asterisks">
                                    {errors2?.billCityId?.message}
                                  </span>
                                </div>
                                <div className="form-group col-md-4">
                                  <label className="al-subtitle">
                                    {appLabel.address1}{" "}
                                    <Tooltips title={toolTip.address1} />
                                    <span className="asterisks">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control input-md"
                                    placeholder={`${appLabel.address1}`}
                                    {...register2("billAddress1", {
                                      required: "Field is required.",
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors2?.billCityId?.message}
                                  </span>
                                </div>
                                <div className="form-group col-md-4">
                                  <label className="al-subtitle">
                                    {appLabel.address2}{" "}
                                    <Tooltips title={toolTip.address2} />
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control input-md"
                                    ng-model="entity.address2"
                                    placeholder={`${appLabel.address2}`}
                                    {...register2("billAddress2")}
                                  />
                                </div>
                                <div className="form-group col-md-4">
                                  <label className="al-subtitle">
                                    {appLabel.postCode}{" "}
                                    <Tooltips title={toolTip.postCode} />
                                    <span className="asterisks">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control input-md"
                                    placeholder={`${appLabel.postCode}`}
                                    {...register2("billPostCode", {
                                      required: "Field is required.",
                                    })}
                                  />
                                  <span className="asterisks">
                                    {errors2?.billPostCode?.message}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="statutoryInfo">
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.generalInfo}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />
                              <div
                                className={`form-group col-md-4 ${
                                  isIndividual ? "hide" : ""
                                }`}
                              >
                                <label className="al-subtitle">
                                  {appLabel.companyRegDate}{" "}
                                  <Tooltips title={toolTip.companyRegDate} />
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  type="date"
                                  className="form-control input-md"
                                  max={`${currentDate}`}
                                  {...register2("regDate", {
                                    required: isIndividual
                                      ? false
                                      : "Field is required.",
                                  })}
                                />
                                <span className="asterisks">
                                  {errors2?.regDate?.message}
                                </span>
                              </div>
                              <div
                                className={`form-group col-md-4 ${
                                  isIndividual ? "" : "hide"
                                }`}
                              >
                                <label className="al-subtitle">
                                  {appLabel.dateOfBirth}{" "}
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  type="date"
                                  className="form-control input-md"
                                  {...register2("dob", {
                                    required: isIndividual
                                      ? "Field is required."
                                      : false,
                                  })}
                                />
                                <span className="asterisks">
                                  {errors2?.dob?.message}
                                </span>
                              </div>

                              <div
                                className={`form-group col-md-4 ${
                                  isIndividual ? "" : "hide"
                                }`}
                              >
                                <label className="al-subtitle">
                                  {appLabel.gender}{" "}
                                  <span className="asterisks">*</span>
                                </label>
                                <select
                                  className="form-control input-md"
                                  {...register2("gender", {
                                    required: isIndividual
                                      ? "Field is required."
                                      : false,
                                  })}
                                >
                                  <option value="">{appLabel.choose}</option>
                                  {appStatic.gender.map((item, index) => (
                                    <option value={item.id} key={index}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                                <span className="asterisks">
                                  {errors2?.gender?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-4">
                                <label className="al-subtitle">
                                  {appLabel.businessStartDate}{" "}
                                  <Tooltips title={toolTip.businessStartDate} />
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  type="date"
                                  className="form-control input-md"
                                  max={`${currentDate}`}
                                  {...register2("businessStartDate", {
                                    required: "Field is required.",
                                  })}
                                />
                                <span className="asterisks">
                                  {errors2?.businessStartDate?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-4">
                                <label className="al-subtitle">
                                  {appLabel.businessType}{" "}
                                  <span className="asterisks">*</span>
                                </label>
                                <select
                                  className="form-control input-md"
                                  {...register2("businessType", {
                                    required: "Field is required.",
                                  })}
                                >
                                  <option value=""> Choose</option>
                                  {appStatic.businessType.map((item, index) => (
                                    <option value={item.value} key={index}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                                <span className="asterisks">
                                  {errors2?.businessType?.message}
                                </span>
                              </div>
                              {/* <div className="form-group col-md-4">
                                  <label className="al-subtitle">
                                    {appLabel.financeYear}{" "}
                                    <Tooltips title={toolTip.financeYear} />
                                    <span className="asterisks">*</span>
                                  </label>
                                  <select
                                    className="form-control input-md"
                                    {...register2("financialYear", {
                                      required: "Field is required.",
                                    })}
                                  >
                                    <option value=""> Choose</option>
                                    {appStatic.financeYears.map(
                                      (item, index) => (
                                        <option value={item.value} key={index}>
                                          {item.name}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  <span className="asterisks">
                                    {errors2?.financialYear?.message}
                                  </span>
                              </div> */}
                            </div>
                            <div className="col-sm-12">
                              <label className="al-heading">
                                <i className="fa fa-circle-o text-navy"></i>
                                &nbsp; {appLabel.bankInformation}
                              </label>
                              <div
                                className="hr-line-dashed"
                                style={{ marginTop: 0 }}
                              />

                              <div className="form-group col-md-4">
                                <label className="al-subtitle">
                                  {appLabel.bankName}{" "}
                                  <Tooltips title={toolTip.bankName} />
                                  <span className="asterisks">*</span>
                                </label>
                                <select
                                  className="form-control input-md"
                                  {...register2("bankId", {
                                    required: "Field is required.",
                                  })}
                                >
                                  <option value="">{appLabel.choose}</option>
                                  {bankData.map((item, index) => (
                                    <option value={item.BANK_ID} key={index}>
                                      {item.BANK_NAME}
                                    </option>
                                  ))}
                                </select>
                                <span className="asterisks">
                                  {errors2?.bankId?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-4">
                                <label className="al-subtitle">
                                  {appLabel.accountNumber}{" "}
                                  <Tooltips title={toolTip.accountNumber} />
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  type="number"
                                  className="form-control input-md"
                                  placeholder={appLabel.accountNumber}
                                  {...register2("accountNumber", {
                                    required: "Field is required.",
                                    minLength: {
                                      value: 13,
                                      message: "Not less than 13 digits",
                                    },
                                    maxLength: {
                                      value: 13,
                                      message: "Not greater than 13 digits",
                                    },
                                  })}
                                />
                                <span className="asterisks">
                                  {errors2?.accountNumber?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-4">
                                <label className="al-subtitle">
                                  {appLabel.accountName}{" "}
                                  <Tooltips title={toolTip.accountName} />
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-md"
                                  placeholder={appLabel.accountName}
                                  {...register2("accountName", {
                                    required: "Field is required.",
                                  })}
                                />
                                <span className="asterisks">
                                  {errors2?.accountName?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-4">
                                <label className="al-subtitle ">
                                  {appLabel.branchCode}{" "}
                                  <Tooltips title={toolTip.branchCode} />
                                  {/* <span className="asterisks">*</span> */}
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-md"
                                  placeholder={appLabel.branchCode}
                                  {...register2("branchCode")}
                                />
                                {/* <span className="asterisks">
                                    {errors2?.branchCode?.message}
                                  </span> */}
                              </div>
                              <div className="form-group col-md-4">
                                <label className="al-subtitle">
                                  {appLabel.accountType}{" "}
                                  <Tooltips title={toolTip.accountType} />
                                  <span className="asterisks">*</span>
                                </label>
                                <select
                                  className="form-control input-md"
                                  {...register2("accountType", {
                                    required: "Field is required.",
                                  })}
                                >
                                  <option value="">{appLabel.choose}</option>
                                  {appStatic.bankAccountType.map(
                                    (item, index) => (
                                      <option value={item.id} key={index}>
                                        {item.name}
                                      </option>
                                    )
                                  )}
                                </select>
                                <span className="asterisks">
                                  {errors2?.accountType?.message}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="documentInfo">
                          <div className="row" style={{ marginTop: "30px" }}>
                            <div className="col-sm-12">
                              <p className="text-danger">
                                {appLabel.maxFileUploadMsg}
                              </p>
                            </div>
                            <div className="col-sm-12">
                              <div className="col-md-6">
                                <div className="row form-group has-errorr">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle control-label">
                                      {appLabel.documentType}{" "}
                                      <Tooltips title={toolTip.documentType} />
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <select
                                      className="form-control input-md documentInput"
                                      onChange={(e) =>
                                        setDocCategory(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        {appLabel.choose}
                                      </option>
                                      {appStatic.documntTypes.map(
                                        (item, index) => (
                                          <option value={item.id} key={index}>
                                            {item.name}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="row form-group">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle ">
                                      {appLabel.documentUpload}{" "}
                                      <Tooltips
                                        title={toolTip.documentUploadPdf}
                                      />
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <input
                                      type="file"
                                      className="form-control input-md documentInput"
                                      accept=".pdf"
                                      onChange={(e) =>
                                        handleFileRead(e, setTempFile)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row form-group">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle ">
                                      &nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <button
                                      type="button"
                                      onClick={() => uploadFile()}
                                      className="btn btn-primary btn-xs"
                                    >
                                      <i className="fa fa-upload" />
                                      &nbsp; Upload
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="col-sm-4 al-subtitle"></label>
                                  <div className="col-sm-8">
                                    {documents.map((item, index) => (
                                      <div className="i-checks" key={index}>
                                        <label className="control-label">
                                          <i
                                            style={{ cursor: "pointer" }}
                                            className="fa fa-times-circle text-danger fa-2x"
                                            onClick={() => removeItem(index)}
                                          />
                                          &nbsp; &nbsp;
                                          <i
                                            className="fa fa-file-pdf-o fa-2x"
                                            style={{ color: "#d50000" }}
                                          />
                                          &nbsp; {item.docCategory}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div>
                        <hr />
                        <div className="form-group">
                          <div className="col-sm-6">
                            <Link
                              to="/sign-in"
                              className="btn btn-white btn-sm m-r"
                            >
                              <i className="fa fa-arrow-circle-left" />
                              &nbsp;
                              {appLabel.backBtn}
                            </Link>
                            {/* <button
                              className="btn btn-primary btn-sm "
                              type="submit"
                            >
                              <i className="fa fa-arrow-circle-right" /> &nbsp;{" "}
                              {appLabel.saveBtn}
                            </button> */}
                            <span className={`${isEditableFld ? "hide" : ""}`}>
                              <OnClickBtnLoad
                                isLoading={isLoading}
                                btnName={appLabel.nextBtn}
                                isFullWidth={false}
                                icon={"fa fa-arrow-circle-right"}
                                // callbackFn={onVerifyKyc}
                                callbackFn={handleSubmit(onVerifyKyc, BOM.onError)}
                                processMsg={appLabel.kycProcessVrfyMsg}
                              />
                            </span>
                            <span className={`${isEditableFld ? "" : "hide"}`}>
                              <SubmitBtnLoad
                                isLoading={isLoading}
                                btnName={appLabel.saveBtn}
                                isFullWidth={false}
                                icon={"fa fa-arrow-circle-right"}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </header>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(null, { SignOutAction })(Registration);
