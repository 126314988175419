import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import appLabel from "../../../config/appLabel";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import NumberFormat from "react-number-format";
import { apis as api } from "../../../services/api.action";
import { handleSkeletonFac } from "../../../components/layouts/Facility";

//dash v2
import { Box, Alert, AlertTitle, Typography } from "@mui/material";
import PieChart from "./PieChart";
import {
  Share,
  CheckCircleOutline,
  ThumbDownOffAltOutlined,
  WatchLaterOutlined,
  FormatQuote,
} from "@mui/icons-material";
import StatBox from "./StatBox";
import ProgressCircle from "./ProgressCircle";
import DashboardLayout from "./DashboardLayout";

const InvoiceDashboard = (props) => {
  const { userData, menuData, location, history } = props;
  const { profileId, session, companyId } = userData;
  const dispatch = useDispatch();
  const [invoiceDashData, setInvoiceDashData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mockPieData, setMockPieData] = useState([]);
  const [totalFundedInv, setTotalFundedInv] = useState([]);

  useEffect(() => {
    getListByCriteria();
  }, []);
  function getListByCriteria() {
    setIsLoading(true);

    let body = {
      profileId,
      session,
    };

    BOM.FetchReqAction(body, api.GetDashBoard, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.dashboardCount;

        // EXTRACT ONLY PO DATA
        const specInvData = menuData.filter(
          (item) => item.FUNCTION_ID === "INV000000000001"
        );

        // DECLARED VARIABLES
        let specSubInvData = [];
        let tempArr = [];
        let tempChart = [];

        // GET PO MENU FOR THE CURRENT USER.

        if (specInvData.length !== 0) {
          specSubInvData = specInvData[0]["SUB_MENU"];
        }

        // DISPATCH DATA IN TO ARRAY.
        specSubInvData.forEach((element) => {
          switch (element.FUNCTION_ID) {
            case "INV000000000004":
              tempArr.push({
                name: appLabel.availableItem,
                value: objDetail.invoiceShared,
                icon: <Share sx={{ color: "#3da58a", fontSize: "20px" }} />,
                itemType: "Invoice",
                url: "shared-invoice",
                position: 1,
               gridColumn: 2,
              });
              tempChart.push({
                id: appLabel.availableItem,
                label: appLabel.availableItem,
                value: objDetail.invoiceShared,
                color: "hsl(104, 70%, 50%)",
                position: 1,
              });
              break;
            case "INV000000000005":
              tempArr.push({
                name: appLabel.quotedItem,
                value: objDetail.invQuoted,
                icon: (
                  <FormatQuote sx={{ color: "#3da58a", fontSize: "20px" }} />
                ),
                itemType: "Invoice",
                url: "financed-invoice",
                position: 2,
               gridColumn: 2,
              });
              tempChart.push({
                id: appLabel.quotedItem,
                label: appLabel.quotedItem,
                value: objDetail.invQuoted,
                color: "hsl(162, 70%, 50%)",
                position: 2,
              });
              break;
            case "INV000000000006":
              tempArr.push({
                name: appLabel.fundedItem,
                value: objDetail.invoiceFunded,
                icon: (
                  <CheckCircleOutline
                    sx={{ color: "#3da58a", fontSize: "20px" }}
                  />
                ),
                itemType: "Invoice",
                url: "funded-invoice",
                position: 3,
               gridColumn: 2,
              });
              tempChart.push({
                id: appLabel.fundedItem,
                label: appLabel.fundedItem,
                value: objDetail.invoiceFunded,
                color: "hsl(291, 70%, 50%)",
                position: 3,
               gridColumn: 2,
              });

              break;
            case "INV000000000002":
              tempArr.push({
                name: appLabel.rejectedItem,
                value: objDetail.invRejected,
                icon: (
                  <ThumbDownOffAltOutlined
                    sx={{ color: "#3da58a", fontSize: "20px" }}
                  />
                ),
                itemType: "Invoice",
                url: "manage-invoice",
                position: 4,
               gridColumn: 2,
              });
              tempChart.push({
                id: appLabel.rejectedItem,
                label: appLabel.rejectedItem,
                value: objDetail.invRejected,
                color: "hsl(229, 70%, 50%)",
                position: 4,
              });

              break;
            case "INV000000000003":
              tempArr.push({
                name: appLabel.awaitningItem,
                value: objDetail.awaitInvVerification,
                icon: (
                  <WatchLaterOutlined
                    sx={{ color: "#3da58a", fontSize: "20px" }}
                  />
                ),
                itemType: "Invoice",
                url: "invoice-to-verify",
                position: 5,
                gridColumn: 3,
              });

              tempChart.push({
                id: appLabel.awaitningItem,
                label: appLabel.awaitningItem,
                value: objDetail.awaitInvVerification,
                color: "hsl(344, 70%, 50%)",
                position: 5,
              });
              break;
          }
        });

        /** INVOICE ITM S BY POSITION */
        const myData = []
          .concat(tempArr)
          .sort((a, b) => (a.position > b.position ? 1 : -1));

        setInvoiceDashData(myData);

        const myDataChart = []
          .concat(tempChart)
          .sort((a, b) => (a.position > b.position ? 1 : -1));

        // pie mock data
        setMockPieData(myDataChart);

        setIsLoading(false);
        setTotalFundedInv(objDetail.invTotalFunded);
      }
    });
  }
  const onSelectTile = (objParam) => {
    const { url } = objParam;

    history.push(`/${url}`);
  };
  return (
    <DashboardLayout
      dashboardData={invoiceDashData}
      isLoading={isLoading}
      mockPieData={mockPieData}
      totalFunded={totalFundedInv}
      isPo={false}
      onSelectTile={onSelectTile}
    />
  );
};

export default InvoiceDashboard;
