import React, { useEffect, useState } from "react";
import { apis as api } from "../../services/api.action";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM, RAL } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../ui-setup/RecordStatus";
import appStatic from "../../config/appStaticData";
import NumberFormat from "react-number-format";
import {
  Tooltips,
  InputAmountFacility,
  Col12BlockFacility,
  InputTextFacility,
  NoDataFac,
  NavTabFac,
  ColBlockFac,
} from "../layouts/Facility";
import RightSide from "../ui-setup/RightSide";

const amtPybles = {
  amtPay1: 0,
  amtPay2: 0,
};
export const FinanceInvoice = (props) => {
  const { location, userData, configData, recordData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId, companyTypeId } = userData;
  const { isCsd, txnRelStlmt } = configData;
  const { toolTip, tabData } = appStatic;

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  const dispatch = useDispatch();
  const [isCsdCheck, setIsCsdCheck] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [fromCompanyDetail, setFromCompanyDetail] = useState({});
  const [toCompanyDetail, setToCompanyDetail] = useState({});
  const [orderDetail, setOrderDetail] = useState({});
  const [shipFromData, setShipFromData] = useState({});
  const [billFromData, setBillFromData] = useState({});
  const [shipToData, setShipToData] = useState({});
  const [billToData, setBillToData] = useState({});
  const [isPO, setIsPO] = useState(false);
  const [taxDetail, setTaxDetail] = useState({});

  const [contractDetail, setContractDetail] = useState({});
  const [financeDetail, setFinanceDetail] = useState({});
  const [financeHistoryData, setFinanceHistoryData] = useState([]);
  const [inputFldData, setInputFldData] = useState([]);

  const [isFunder, setIsFunder] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [sharedPODetail, setSharedPODetail] = useState({});
  const [trustAccountData, setTrustAccountData] = useState([]);
  const [shareSupplierReq, setShareSupplierReq] = useState({});

  const [discountAmt, setDiscountAmt] = useState(0);
  const [netInvoiceAmt, setNetInvoiceAmt] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [discountedDetail, setDiscountedDetail] = useState({
    adjustment: 0,
    processingFee: 0,
  });
  const [valueDate, setValueDate] = useState(
    BOM.addDaysInDate(new Date(), txnRelStlmt)
  );

  const [autoFldData, setAutoFldData] = useState([]);
  const [otherFee1Data, setOtherFee1Data] = useState([]);
  const [otherFee2Data, setOtherFee2Data] = useState([]);
  const [amountPayable1, setAmountPayable1] = useState(0);
  const [amountPayable2, setAmountPayable2] = useState(0);
  const [totalSettlement, setTotalSettlement] = useState(0);
  const [feeObj, setFeeObj] = useState({
    installmentAmount: 0,
    costOfFinance: 0,
    interestRate: 0,
    interestAmount: 0,
    tenorMonths: 0,
    transactionFee: 0,
    totalRepayment: 0,
    otherFee1: 0,
    otherFee2: 0,
  });
  let tempDocument = [];

  useEffect(() => {
    // console.log(ggParam);
    handleSwitchCondition();
    getTrustAccount();
    checkForFundingType(ggParam)
    getDetailById(ggParam);
    getFeeList(ggParam);
    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);

    //CALL INVOICE DOCUMENT BLOCK
    BOM.getSpecDoc(ggParam?.INV_ID, setDocuments, userData);

    // getSpecDoc(state?.ggParam?.INV_ID, "invoice");
    // //GET 2nd DOCUMENT
    // setTimeout(() => {
    // getSpecDoc(state?.ggParam?.INV_ID, "sharing");
    // }, 1500);
    
  }, []);
  const handleSwitchCondition = () => {

    switch (companyTypeId) {
      case "CT2022012400003": // funder
        setIsFunder(true);
        break;
      default: // other entity type
        setIsFunder(false);
        RAL.disableEnableRightAction()
        break;
    }
  }
  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      invoiceId: params.INV_ID,
      companyId,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetInvoice, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.invoiceDetail;
        const invDetail = objDetail.INVOICE_DATA;
        const taxDetail = objDetail.TAX_DATA;

        setValue("invoiceId", invDetail.INV_ID);
        setInvoiceDetail(objDetail);
        setTaxDetail(taxDetail);
        setContractDetail(objDetail.CONTRACT_DATA);
        setFinanceDetail(objDetail.BIDDING_DATA);
        setFinanceHistoryData(objDetail.FUND_HISTORY_DATA);
        setShareSupplierReq(objDetail.SUPPLIER_SHARE_REQ_DATA);
        calculateTotals(objDetail);

        getPo(objDetail.ORDERID);

        dispatch({
          type: cnt.RECORD_STATUS,
          payload: invDetail,
        });

        //get document
        // getSpecDoc(state.ggParam);

        // mergedDataArr
        setInputFldData([
          {
            name: appLabel.invoiceId,
            value: invDetail.INV_ID,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.currency,
            value: invDetail.INV_CCY,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.taxCode,
            value: taxDetail.TAX_CODE,
            fldType: "text",
            section: "tax",
          },
          {
            name: appLabel.rate,
            value: taxDetail.TAX_RATE,
            fldType: "text",
            section: "tax",
          },
          {
            name: appLabel.amount,
            value: taxDetail.TAX_AMOUNT,
            fldType: "amount",
            section: "tax",
          },
          {
            name: appLabel.excludeAmnt,
            value: taxDetail.EXCL_TAX_AMOUNT,
            fldType: "amount",
            section: "tax",
          },
          {
            name: appLabel.invAmount,
            value: taxDetail.TOTAL_AMOUNT,
            fldType: "amount",
            section: "n/a",
          },
          {
            name: appLabel.acceptedDate,
            value: invDetail.ACCEPTED_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.submissionDate,
            value: invDetail.SUBMISSION_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.invDueDate,
            value: invDetail.INVOICE_DUE_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.invDate,
            value: invDetail.INV_DATE,
            fldType: "text",
            section: "general",
          },
          {
            name: appLabel.planedPymtDate,
            value: invDetail.PLANNED_PAYMENT_DATE,
            fldType: "text",
            section: "date",
          },
          {
            name: appLabel.originalAmount,
            value: invDetail.ORIGINAL_AMOUNT,
            fldType: "amount",
            section: "payment",
          },
          {
            name: appLabel.finalAmount,
            value: invDetail.FINAL_AMOUNT,
            fldType: "amount",
            section: "n/a",
          },
          {
            name: appLabel.adjustment,
            value: invDetail.ADJUSTMENT,
            fldType: "amount",
            section: "payment",
          },
          {
            name: appLabel.discountAmount,
            value: invDetail.DISCOUNT_AMOUNT,
            fldType: "amount",
            section: "payment",
          },
          {
            name: appLabel.pymtOption,
            value: invDetail.PAYMENT_METHOD,
            fldType: "text",
            section: "general",
          },
        ]);
      }
    });
  };
  const getSpecDoc = () => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      id: ggParam?.INV_ID,
      // type,//no need to send type anymore
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setDocuments(res.docListing);
      }
    });
  };
  const getSpecDocOLD = async (id, type) => {
    let body = {
      profileId,
      id,
      type,
      session,
    };

    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        switch (type?.toLowerCase()) {
          case "invoice":
            res.docListing.forEach((element) => {
              tempDocument.push(element);
            });
            break;

          default:
            res.docListing.forEach((element) => {
              tempDocument.push(element);
            });
            break;
        }

        setDocuments([...tempDocument]);
      }
    });
  };

  function getTrustAccount() {
    let body = {
      profileId,
      accId: "",
      criteria: "A",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetTrustAccount, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setTrustAccountData(res.trustAccountListing);
      }
    });
  }
  const getCompanyById = (
    companyId,
    setShipParam,
    setBillParam,
    setCompanyDetail
  ) => {
    let body = {
      profileId,
      companyId,
      session,
    };

    /** FETCH */
    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let companyDetail = res.companyDetail;

        setShipParam(companyDetail.SHIP_DATA);
        setBillParam(companyDetail.BILL_DATA);
        setCompanyDetail(companyDetail);
      }
    });
  };
  const getPo = (idParam) => {
    let body = {
      profileId,
      orderId: idParam,
      criteria: "A",
      companyId,
      session,
    };
    BOM.LoadAlert(cnt.LOAD, "Processing");

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetPo, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let orderDetail = res.purchaseOrderDetail;
        setOrderDetail(orderDetail);
        // calculateTotals(invoiceDetail);

        getCompanyById(
          orderDetail.PO_FROM_COMPANYID,
          setShipFromData,
          setBillFromData,
          setFromCompanyDetail
        );
        getCompanyById(
          orderDetail.PO_TO_COMPANYID,
          setShipToData,
          setBillToData,
          setToCompanyDetail
        );
        setIsPO(true);
      }
    });
  };
  const calculateTotals = (objParam) => {
    // setSharedPODetail(state.ggParam);
    // console.log(objParam);

    /** SURCHARGE CALCUL LOGIC */
    let surchargeObj = objParam.SURCHARGE_DATA,
        chargeRate = 0,
        maxChargeAmt = 0;

    if (Object.keys(surchargeObj).length !== 0) {
        chargeRate = parseFloat(surchargeObj.CHARGE_PERCENTAGE, 10) / 100;
        maxChargeAmt = parseFloat(surchargeObj.CHARGE_LIMIT, 10);
      }


    const { amtPay1, amtPay2 } = amtPybles;
    let  shareReqObj = objParam.SUPPLIER_SHARE_REQ_DATA,
          tenorVal = parseInt(shareReqObj.TENOR),
          interestRateVal = parseFloat(shareReqObj.INTEREST_RATE, 10),
          funderAmount = parseFloat(getValues("investorLoanAmount"), 10),
          funderRate = parseFloat(getValues("investorIntrestRate"), 10) / 100,
          tenor = tenorVal / 12,
          interestAmount = funderAmount * funderRate * tenor,
          totalRepayment = funderAmount + interestAmount,
          transactionFee = funderAmount * chargeRate;

    if (transactionFee > maxChargeAmt) {
      transactionFee = maxChargeAmt;
    }

    let installmentAmount = funderRate * tenor * funderAmount;

    //Calculate TotalSettlement part 1
    setTotalSettlement(funderAmount + transactionFee + amtPay1 + amtPay2);

    //update feeObj for displaying purpose
    setFeeObj({
      installmentAmount: parseFloat(installmentAmount, 10).toFixed(2),
      interestRate: parseFloat(interestRateVal, 10).toFixed(2),
      interestAmount: parseFloat(interestAmount, 10).toFixed(2),
      tenorMonths: tenorVal,
      transactionFee: parseFloat(transactionFee, 10).toFixed(2),
      totalRepayment: parseFloat(totalRepayment, 10).toFixed(2),
    });
  };
  function checkForFundingType(objParam) {
    switch (objParam.IS_PRIVATE_FUNDING) {
      case "true":
        setIsPrivate(true);
        setValue("investorLoanAmount", parseFloat(objParam.REQUESTED_AMOUNT));
        break;
      default:
        break;
    }
  }
  function getFeeList(objParam) {
    // console.log(objParam);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      feeId: "",
      ccy: objParam.CCY,
      companyTypeId,
      loanAmount: objParam.REQUESTED_AMOUNT,
      criteria: "",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetFees, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.feeListing;

        setOtherFee1Data(
          objList.filter(
            (item) => item.FEE_OPTION.toLowerCase() === "other fee 1"
          )
        );
        setOtherFee2Data(
          objList.filter(
            (item) => item.FEE_OPTION.toLowerCase() === "other fee 2"
          )
        );
      }
    });
  }
  const onSelectOtherFee = (idParam, setParam, arrParam, keyFee) => {
    setParam(0);
    setTotalSettlement(totalSettlement);
    let tempCalc = 0;
    const bidAmount = parseFloat(getValues("investorLoanAmount"), 10);

    if (!idParam) {
      switch (keyFee) {
        case "fee1":
          setTotalSettlement(totalSettlement - amtPybles.amtPay1);
          amtPybles.amtPay1 = 0;
          break;
        case "fee2":
          setTotalSettlement(totalSettlement - amtPybles.amtPay2);
          amtPybles.amtPay2 = 0;
          break;
      }
      return;
    }

    /** AMOUNT PAYABLE  CALCULATION*/
    const feeSelected = arrParam.find((item) => item.FEE_ID === idParam);

    switch (feeSelected.FLG_FEE_SLAB.toLowerCase()) {
      case "n":
        switch (feeSelected.FEE_TYPE.toLowerCase()) {
          case "flat":
            tempCalc = parseFloat(feeSelected.FEE_AMOUNT, 10);
            setParam(tempCalc);
            break;
          case "percentage":
            const tempRate = parseFloat(feeSelected.FEE_PERCENTAGE, 10) / 100;
            if (!bidAmount) {
              BOM.AlertMsg(
                cnt.WARNING,
                appLabel.investorLoanAmnt + " is required."
              );
              $(".clearInputFee").val("");
              return;
            }
            tempCalc = bidAmount * tempRate;
            setParam(tempCalc);
            break;
        }
        break;
      default:
        //regardless of fee type the following will be done

        if (feeSelected.SLAB_DATA.length === 0) {
          switch (feeSelected.FEE_TYPE.toLowerCase()) {
            case "flat":
              tempCalc = parseFloat(feeSelected.FEE_AMOUNT, 10);
              setParam(tempCalc);
              break;
            case "percentage":
              const tempRate = parseFloat(feeSelected.FEE_PERCENTAGE, 10) / 100;
              if (!bidAmount) {
                BOM.AlertMsg(
                  cnt.WARNING,
                  appLabel.investorLoanAmnt + " is required."
                );
                $(".clearInputFee").val("");
                return;
              }
              tempCalc = bidAmount * tempRate;
              setParam(tempCalc);
              break;
          }
        } else {
          //We use rate in the slab data rather in percentage
          const { SLAB_DATA } = feeSelected;
          const tempRate = parseFloat(SLAB_DATA[0].FEE_RATE, 10) / 100;
          if (!bidAmount) {
            BOM.AlertMsg(
              cnt.WARNING,
              appLabel.investorLoanAmnt + " is required."
            );
            $(".clearInputFee").val("");
            return;
          }
          tempCalc = bidAmount * tempRate;
          setParam(tempCalc);
        }
        break;
    }

    calcTotalSettlement(tempCalc, keyFee);
  };
  function calcTotalSettlement(amtParam = null, keyFee = null) {
    switch (keyFee) {
      case "fee1":
        amtPybles.amtPay1 = amtParam ? amtParam : 0;
        break;
      case "fee2":
        amtPybles.amtPay2 = amtParam ? amtParam : 0;
        break;

      default:
        amtPybles.amtPay1 = 0;
        amtPybles.amtPay2 = 0;
        break;
    }

    const { amtPay1, amtPay2 } = amtPybles;
    const transactionFee = parseFloat(feeObj.transactionFee),
      bidAmount = parseFloat(getValues("investorLoanAmount")),
      totalSettlement = bidAmount + transactionFee + amtPay1 + amtPay2;

    //Calculate TotalSettlement part 2
    setTotalSettlement(totalSettlement);
  }
  const formSubmit = (formValues) => {
    
    if (totalSettlement < 0) {
      BOM.AlertMsg(cnt.WARNING,  appLabel.totalSettlementAmount + " cannot be negative.");
      return;
    }

    if (parseFloat(formValues.investorLoanAmount ) > parseFloat(ggParam.REQUESTED_AMOUNT)) {
      BOM.AlertMsg(
        cnt.WARNING,
        appLabel.investorLoanAmnt + " should no exceed the Loan Amount."
      );
      return;
    }

    const otherFees = amountPayable1 + amountPayable2,
      costOfFinance = (
        formValues.investorLoanAmount +
        parseFloat(feeObj.transactionFee) +
        parseFloat(feeObj.installmentAmount) +
        otherFees
      ).toFixed(2);

    //merg objects
    formValues = { ...formValues, ...feeObj };

    //  Validate NaN value
    if(BOM.ValidateNaNValue(formValues.investorIntrestRate, appLabel.investorInterest))return;

    formValues.otherFee1 = amountPayable1 + "";
    formValues.otherFee2 = amountPayable2 + "";
    formValues.costOfFinance = costOfFinance + "";
    formValues.interestRate = formValues.interestRate + ""; // send it as a string to match the rule engine
    formValues.tenorMonths = formValues.tenorMonths + "";
    formValues.investorIntrestRate = formValues.investorIntrestRate + ""; // send it as a string to match the rule engine
    formValues.investorLoanAmount = formValues.investorLoanAmount + "";
    formValues.orderId = orderDetail.ORDERID;
    formValues.investorId = companyId;
    formValues.loanAmount = ggParam.REQUESTED_AMOUNT;
    formValues.remitToCompanyId = orderDetail.PO_TO_COMPANYID;
    formValues.billToCompanyId = orderDetail.PO_FROM_COMPANYID;
    formValues.paymentDate = valueDate;
    formValues.intentId = ggParam.INTENT_ID;

    let body = {
      profileId,
      session,
      ...formValues,
    };

    // console.log(body);
    // return
    /** SEND REQ */
    BOM.GlobalApproveRejectAction(
      body,
      api.FundInvoice,
      "shared-invoice",
      "Funding ",
      "Confirm ",
    );
  };

  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <RightSide />
      <ContentTop
        pageTitle={
          isFunder ? appLabel.invoiceFinancing : appLabel.sharedInvoiceDetail
        }
      />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{appLabel.invoiceInformation}</h5>
                <div className="ibox-tools">
                  <Link to="/shared-invoice" className="btn btn-primary btn-xs">
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;
                    {appLabel.backBtn}
                  </Link>
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                  <Col12BlockFacility topTitle={appLabel.generalInfo}>
                    {inputFldData.map((item, index) => (
                      <div key={index}>
                        {item.section === "general" &&
                          (item.fldType === "amount" ? (
                            <InputAmountFacility
                              inputLabel={item.name}
                              inputValue={item.value}
                            />
                          ) : (
                            <InputTextFacility
                              inputLabel={item.name}
                              inputValue={item.value}
                            />
                          ))}
                      </div>
                    ))}
                    <NoDataFac arrList={inputFldData} />
                  </Col12BlockFacility>

                <div className={`${isFunder ? "" : "hide"} `}>
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.invoiceFinancingInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.AmountToFinance}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={ggParam.REQUESTED_AMOUNT}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={" "}
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.interestRate}&nbsp;
                          <span className="asterisks">*</span>
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          placeholder={appLabel.interestRate}
                          className="form-control input-sm clearPerceInput"
                          disabled
                          value={feeObj.interestRate}
                        />
                      </div>
                    </div>
                    <div
                      className={`row form-group col-md-6 ${
                        isFunder ? "hide" : ""
                      }
                      `}
                    >
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.chargeRate}&nbsp;
                          <span className="asterisks">*</span>
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          placeholder={appLabel.chargeRate}
                          disabled
                          className="form-control input-sm clearPerceInput"
                          value={feeObj.chargeRate}
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.duration}&nbsp;
                          <span className="asterisks">*</span>
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          placeholder={appLabel.duration}
                          disabled
                          value={feeObj.tenorMonths}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.bidInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />

                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.trustAccount}&nbsp;
                          <Tooltips title={toolTip.trustAccount} />
                          <span className="asterisks">*</span>
                        </label>
                      </div>
                      <div className="col-md-7">
                        <select
                          className="form-control input-sm"
                          {...register("trustAccountId", {
                            required: "Field is required.",
                          })}
                        >
                          <option value="">{appLabel.choose}</option>
                          {trustAccountData.map((item, index) => (
                            <option value={item.TRUST_ACC_ID} key={index}>
                              {item.BANK_NAME}
                            </option>
                          ))}
                        </select>
                        <span className="asterisks">
                          {errors?.trustAccountId?.message}
                        </span>
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.paymentDate}&nbsp;
                          <Tooltips title={toolTip.paymentDate} />
                          <span className="asterisks">*</span>
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          value={valueDate}
                          disabled
                        />
                      </div>
                    </div>
                    <div className={`${isPrivate ? "hide":"row form-group col-md-6 "}`} >
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.investorLoanAmnt}&nbsp;
                          <span className="asterisks">*</span>
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="number"
                          className="form-control input-sm"
                          placeholder={appLabel.investorLoanAmnt}
                          {...register("investorLoanAmount", {
                            onChange: () => calculateTotals(invoiceDetail),
                            required: "Field is required.",
                            setValueAs: (v) => parseInt(v),
                            valueAsNumber: true,
                            min: {
                              value: 1,
                              message:
                                appLabel.investorLoanAmnt +
                                " should not be less than 1.",
                            },
                            max: {
                              value: sharedPODetail.REQUESTED_AMOUNT,
                              message:
                                appLabel.investorLoanAmnt +
                                " should not exceed the initial amount.",
                            },
                          })}
                        />
                        <span className="asterisks">
                          {errors?.investorLoanAmount?.message}
                        </span>
                      </div>
                    </div>
                    
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.investorInterest}&nbsp;
                          <span className="asterisks">*</span>
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          placeholder={appLabel.investorInterest}
                          {...register("investorIntrestRate", {
                            onChange: () => calculateTotals(invoiceDetail),
                            required: "Field is required.",
                            setValueAs: (v) => parseInt(v),
                            valueAsNumber: true,
                            min: {
                              value: 1,
                              message: "Rate cannot be less than 1%",
                            },
                            max: {
                              value: 100,
                              message: "Rate cannot be greater than 100%",
                            },
                          })}
                        />
                        <span className="asterisks">
                          {errors?.investorIntrestRate?.message}
                        </span>
                      </div>
                    </div>
                  </div>
                  
                  {/* <Col12BlockFacility topTitle={appLabel.otherPoFeesInfo}>
                      <InputAmountFacility
                        inputLabel={appLabel.otherFees}
                        inputValue={feeObj.otherFee}
                        toolTipLabel={toolTip.otherFees}
                      />
                      <InputAmountFacility
                        inputLabel={appLabel.interestAmount}
                       inputValue={feeObj.installmentAmount}
                        toolTipLabel={toolTip.interestAmount}
                      />
                      <InputAmountFacility
                        inputLabel={appLabel.costOfFinance}
                        inputValue={feeObj.costOfFinance}
                        toolTipLabel={toolTip.costOfFinance}
                      />
                      <InputAmountFacility
                        inputLabel={appLabel.arrangementFees}
                        inputValue={feeObj.insuranceFee}
                        toolTipLabel={toolTip.arrangementFees}
                      />
                      <InputAmountFacility
                        inputLabel={appLabel.totalRepayment}
                        inputValue={feeObj.totalAmount}
                        toolTipLabel={toolTip.totalRepayment}
                      />
                    </Col12BlockFacility> */}
                    
                    <ColBlockFac topTitle={appLabel.fundingFeeInfo}>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.otherFee1}&nbsp;
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm clearInputFee"
                            onChange={(e) =>
                              onSelectOtherFee(
                                e.target.value,
                                setAmountPayable1,
                                otherFee1Data,
                                "fee1"
                              )
                            }
                          >
                            <option value="">{appLabel.choose}</option>
                            {otherFee1Data.map((item, index) => (
                              <option value={item.FEE_ID} key={index}>
                                {item.FEE_NAME}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.otherFee2}&nbsp;
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm clearInputFee"
                            onChange={(e) =>
                              onSelectOtherFee(
                                e.target.value,
                                setAmountPayable2,
                                otherFee2Data,
                                "fee2"
                              )
                            }
                          >
                            <option value="">{appLabel.choose}</option>
                            {otherFee2Data.map((item, index) => (
                              <option value={item.FEE_ID} key={index}>
                                {item.FEE_NAME}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.amountPayable1}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <NumberFormat
                            className="form-control input-sm"
                            value={amountPayable1}
                            displayType="text"
                            disabled
                            thousandSeparator={true}
                          />
                        </div>
                      </div>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.amountPayable2}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <NumberFormat
                            className="form-control input-sm"
                            value={amountPayable2}
                            displayType="text"
                            disabled
                            thousandSeparator={true}
                          />
                        </div>
                      </div>
                      <InputAmountFacility
                        inputLabel={appLabel.transactionFee}
                        inputValue={feeObj.transactionFee}
                      />
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.totalSettlementAmount}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <NumberFormat
                            className="form-control input-sm"
                            value={totalSettlement}
                            displayType="text"
                            disabled
                            thousandSeparator={true}
                          />
                        </div>
                      </div>
                    </ColBlockFac>
                </div>
                
                  <Col12BlockFacility topTitle={appLabel.otherInfo}>
                    <NavTabFac tabsList={tabData.invDetailList} />
                    <div className="tab-content">
                      <div className="tab-pane active" id="documentInfo">
                        <div className="row" style={{ marginTop: "30px" }}>
                          <div className="col-sm-12">
                            {documents.length === 0 ? (
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDocumentMsg}
                                </a>
                              </div>
                            ) : (
                              <div>
                                {documents.map((item, index) => (
                                  <div className="col-md-4" key={index}>
                                    <div className="file-box">
                                      <div className="file">
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            BOM.DownloadAction(
                                              item.DOC_OBJECT,
                                              item.DOC_ID +
                                                " " +
                                                item.DOC_CATEGORY.toLowerCase()
                                            )
                                          }
                                        >
                                          <span className="corner" />
                                          <div className="icon">
                                           <i className="fa fa-file-pdf-o" style={{color:'#d50000'}} />
                                          </div>
                                          <div className="file-name">
                                            {item.DOC_CATEGORY}
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="pOInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          {Object.keys(orderDetail).length === 0 ? (
                            <div className="col-sm-12">
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDataMsg}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-12">
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.poNo}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.ORDERID}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.from}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.FROM_COMPANY_NAME}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.to}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.TO_COMPANY_NAME}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.amount}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <NumberFormat
                                    className="form-control input-sm"
                                    value={orderDetail.PO_AMOUNT}
                                    displayType="text"
                                    disabled
                                    thousandSeparator={true}
                                    prefix={""}
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.currency}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.PO_CCY}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.pymtOption}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={orderDetail.PO_PAYMENT_OPTION}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="tab-pane" id="contractInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          {Object.keys(contractDetail).length === 0 ? (
                            <div className="col-sm-12">
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDataMsg}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-12">
                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.contractId}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={contractDetail.PO_CONTRACT_ID}
                                    disabled
                                  />
                                </div>
                              </div>

                              <div className="row form-group col-md-6 ">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.type}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={contractDetail.TRANS_TYPE}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.value}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <NumberFormat
                                    className="form-control input-sm"
                                    value={contractDetail.CONTR_VALUE}
                                    displayType="text"
                                    disabled
                                    thousandSeparator={true}
                                    prefix={""}
                                  />
                                </div>
                              </div>
                              <div className={`row form-group col-md-6`}>
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.currency}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={contractDetail.CONTR_CCY}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="row form-group col-md-6">
                                <div className="col-md-4 text-left">
                                  <label className="al-subtitle">
                                    {appLabel.startDate}&nbsp;
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    className="form-control input-sm"
                                    defaultValue={
                                      contractDetail.CONTR_START_DATE
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="tab-pane" id="financeInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          {Object.keys(financeDetail).length === 0 ? (
                            <div className="col-sm-12">
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDataMsg}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="col-sm-12">
                                <label className="al-heading">
                                  <i className="fa fa-circle-o text-navy"></i>
                                  &nbsp; {appLabel.generalInfo}
                                </label>
                                <div
                                  className="hr-line-dashed"
                                  style={{ marginTop: 0 }}
                                />
                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.fundingId}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      defaultValue={financeDetail.FUNDING_ID}
                                      disabled
                                    />
                                  </div>
                                </div>

                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.funder}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      defaultValue={financeDetail.INVESTOR_NAME}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.transactionDate}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      defaultValue={financeDetail.TRN_DATE}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.paymentDate}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      defaultValue={financeDetail.PAYMENT_DATE}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="row form-group col-md-6 ">
                                  <div className="col-md-4 text-left">
                                    <label className="al-subtitle">
                                      {appLabel.totalAmount}&nbsp;
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <NumberFormat
                                      className="form-control input-sm"
                                      value={financeDetail.TOTAL_AMOUNT}
                                      displayType="text"
                                      disabled
                                      thousandSeparator={true}
                                      prefix={""}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <label className="al-heading">
                                  <i className="fa fa-circle-o text-navy"></i>
                                  &nbsp; {appLabel.history}
                                </label>
                                <div
                                  className="hr-line-dashed"
                                  style={{ marginTop: 0 }}
                                />

                                <div className="table-responsive">
                                  {financeHistoryData.length === 0 ? (
                                    <div className="alert alert-warning text-center">
                                      <a className="alert-link">
                                        {appLabel.noDataInTableMsg}
                                      </a>
                                    </div>
                                  ) : (
                                    <table
                                      className="footable table table-stripped toggle-arrow-tiny"
                                      data-page-size="10"
                                      data-filter="#filter"
                                    >
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>{appLabel.itemId}</th>
                                          <th>{appLabel.funder}</th>
                                          <th>{appLabel.historyType}</th>
                                          <th>{appLabel.AmountToFinance}</th>
                                          <th>{appLabel.amountFunded}</th>
                                          {/* <th>{appLabel.status}</th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {financeHistoryData.map(
                                          (item, index) => (
                                            <tr className="gradeX" key={index}>
                                              <td>{index + 1}</td>
                                              <td>{item.ITEM_ID}</td>
                                              <td>{item.FUNDER}</td>
                                              <td>{item.ITEM_TYPE}</td>
                                              <td>
                                                <NumberFormat
                                                  value={item.AMOUNT_REQUESTED}
                                                  displayType="text"
                                                  disabled
                                                  thousandSeparator={true}
                                                  prefix={item.CCY + " "}
                                                />
                                              </td>
                                              <td>
                                                <NumberFormat
                                                  value={item.AMOUNT_FUNDED}
                                                  displayType="text"
                                                  disabled
                                                  thousandSeparator={true}
                                                  prefix={item.CCY + " "}
                                                />
                                              </td>
                                              {/* <td>
                                                <i
                                                  className={
                                                    item.FLG_STATUS.toLowerCase() ===
                                                    "f"
                                                      ? "fa fa-check text-navy"
                                                      : "fa fa-times text-danger"
                                                  }
                                                ></i>
                                                &nbsp;
                                                {item.FLG_STATUS.toLowerCase() ===
                                                "f"
                                                  ? appLabel.funded
                                                  : appLabel.notFunded}
                                              </td> */}
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td colSpan="8">
                                            <ul className="pagination pull-right"></ul>
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="tab-pane" id="addressInfo">
                        <div className="row" style={{ marginTop: "15px" }}>
                          <Col12BlockFacility topTitle={null}>
                            <div className="col-md-3">
                              <div className="well">
                                <address>
                                  <h4>
                                    {" "}
                                    <i className="fa fa-building text-navy"></i>
                                    &nbsp; {appLabel.shipBuyerAddress}
                                  </h4>{" "}
                                  <br />
                                  <i className="fa fa-circle text-navy"></i>{" "}
                                  &nbsp; {shipFromData.SHIPPING_ADDRESS_1},{" "}
                                  {shipFromData.SHIPPING_CITY_NAME}
                                  <br />
                                  {shipFromData.SHIPPING_PROVINCE_NAME},{" "}
                                  {shipFromData.SHIPPING_COUNTRY_NAME}{" "}
                                  {shipFromData.SHIPPING_POST_CODE}
                                </address>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="well">
                                <address>
                                  <h4>
                                    {" "}
                                    <i className="fa fa-building text-navy"></i>
                                    &nbsp;{appLabel.bilBuyerAddress}
                                  </h4>{" "}
                                  <i className="fa fa-circle text-navy"></i>
                                  &nbsp;{billFromData.BILLING_ADDRESS_1},{" "}
                                  {billFromData.BILLING_CITY_NAME}
                                  <br />
                                  {billFromData.BILLING_PROVINCE_NAME},{" "}
                                  {billFromData.BILLING_COUNTRY_NAME}{" "}
                                  {billFromData.BILLING_POST_CODE}
                                </address>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="well">
                                <address>
                                  <h4>
                                    {" "}
                                    <i className="fa fa-building text-navy"></i>
                                    &nbsp; {appLabel.shipSupplierAddress}
                                  </h4>{" "}
                                  <br />
                                  <i className="fa fa-circle text-navy"></i>{" "}
                                  &nbsp; {shipToData.SHIPPING_ADDRESS_1},{" "}
                                  {shipToData.SHIPPING_CITY_NAME}
                                  <br />
                                  {shipToData.SHIPPING_PROVINCE_NAME},{" "}
                                  {shipToData.SHIPPING_COUNTRY_NAME}{" "}
                                  {shipToData.SHIPPING_POST_CODE}
                                </address>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="well">
                                <address>
                                  <h4>
                                    {" "}
                                    <i className="fa fa-building text-navy"></i>
                                    &nbsp;{appLabel.bilSupplierAddress}
                                  </h4>{" "}
                                  <i className="fa fa-circle text-navy"></i>
                                  &nbsp;{billToData.BILLING_ADDRESS_1},{" "}
                                  {billToData.BILLING_CITY_NAME}
                                  <br />
                                  {billToData.BILLING_PROVINCE_NAME},{" "}
                                  {billToData.BILLING_COUNTRY_NAME}{" "}
                                  {billToData.BILLING_POST_CODE}
                                </address>
                              </div>
                            </div>
                          </Col12BlockFacility>
                        </div>
                      </div>
                    </div>
                  </Col12BlockFacility>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <RecordStatus {...recordData} /> */}
      </div>
      </form>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FinanceInvoice);
