import { Link } from "react-router-dom/cjs/react-router-dom";

const SidebarMenuList = (props) => {
  const { userMenu, activeLinkParent, activeLinkSubmenu, handleLinkClick } =
    props;

  return userMenu.map((menu, index) => (
    <li
      className={`${activeLinkParent === menu.DESCRIPTION ? "active" : ""}`}
      key={index}
    >
      {menu.SUB_MENU.length === 0 ? (
        <Link to={menu.URL} onClick={() => handleLinkClick({}, menu)}>
          <i
            className={`fa fa-${menu.ICON} text-navy ${
              activeLinkParent === menu.DESCRIPTION ? "side-menu-icon" : ""
            }`}
          ></i>{" "}
          <span className="nav-label">{menu.DESCRIPTION}</span>
        </Link>
      ) : (
        <>
          <a>
            <i
              className={`fa fa-${menu.ICON} text-navy ${
                activeLinkParent === menu.DESCRIPTION ? "side-menu-icon" : ""
              } `}
            ></i>{" "}
            <span className="nav-label">{menu.DESCRIPTION}</span>
            <span className="fa arrow" />
          </a>
          <ul className="nav nav-second-level collapse">
            {menu.SUB_MENU.map((submenu, subIndex) => {
              return (
                <li
                  className={`${
                    activeLinkSubmenu === submenu.URL ? "active" : ""
                  }`}
                  key={subIndex}
                >
                  {submenu.DESCRIPTION === "Fund Portfolio" ? (
                    <a href={submenu.URL} target={"_blank"}>
                      {submenu.DESCRIPTION}
                    </a>
                  ) : (
                    <a
                      onClick={() => handleLinkClick(submenu, menu)}
                      className={` ${
                        activeLinkSubmenu === submenu.URL
                          ? "active-submenu-text"
                          : ""
                      }`}
                    >
                      {submenu.DESCRIPTION}
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
        </>
      )}
    </li>
  ));
};

export default SidebarMenuList;
