import React, { useEffect, useState } from "react";

//project imports
import appLabel from "../../config/appLabel";
import history from "../../history";
import { BgImgFac } from "../../components/layouts/Facility";
import { connect } from "react-redux";
import appStatic from "../../config/appStaticData";

import { Parser as HtmlToReactParser } from "html-to-react";
import termsSadDb from "../../components/configuration/termsCondition/termsSadDb.json";
import termsDb from "../../components/configuration/termsCondition/termsDb.json";

//react pdf viewer import
import { ReactPdfViewer } from "../../components/layouts/Facility";

var htmlToReactParser = new HtmlToReactParser();

const TermsDetailOLD = (props) => {
  const { userData } = props;
  const { userParentRoleName } = userData;
  const [code, setCode] = useState(null);

  // Object.keys(surchargeObj).length !== 0

  useEffect(() => {
    // console.log(userData);
    switch (userParentRoleName.toLowerCase()) {
      case "sad":
        setCode(termsSadDb.data);
        break;
      default:
        setCode(termsDb.data);
        break;
    }
  }, []);

  return (
    <div>
      <BgImgFac />
      <div className="wrapper wrapper-content  animated fadeInRight article ">
        <div className="row">
          <div className="col-lg-10 col-lg-offset-1">
            <div className="ibox">
              <div
                className="ibox-content contentCard"
                style={{ marginTop: "70px" }}
              >
                <div className="pull-right">
                  <button
                    className="btn btn-white btn-xs"
                    type="button"
                    onClick={() => history.goBack()}
                  >
                    <i className="fa fa-times text-danger" />
                    &nbsp;{appLabel.closeBtn}
                  </button>
                </div>
                <div className="text-center article-title">
                  <span className="text-muted">
                    Last update : <i className="fa fa-clock-o" /> 11th Jan 2023
                  </span>
                  <h1>Terms and Conditions</h1>
                </div>
                {/* {
                  htmlToReactParser.parse(localStorage.getItem('tsandcs'))
                  
                } */}
                {code
                  ? htmlToReactParser.parse(code)
                  : appLabel.noDataAvailableMsg}
                <hr />
                {/* <div className="pull-right">
                  <button
                    className="btn btn-white btn-xs"
                    type="button"
                    onClick={() => history.goBack()}
                  >
                    <i className="fa fa-times text-danger" />
                    &nbsp;{appLabel.closeBtn}
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TermsDetail = (props) => {
  const { location, userData } = props;
  const { state } = location;
  const { userParentRoleName } = userData;
  const { privacyPolicyDocumentData } = appStatic;

  const [code, setCode] = useState(null);
  const [file, setFile] = useState(state.fileParam);

  // Object.keys(surchargeObj).length !== 0

  useEffect(() => {
    // console.log(props);
    switch (userParentRoleName.toLowerCase()) {
      case "sad":
        setCode(termsSadDb.data);
        break;
      default:
        setCode(termsDb.data);
        break;
    }
  }, []);

  return (
    <div>
      <BgImgFac />
      <div className="wrapper wrapper-content  animated fadeInRight article ">
        <div className="row">
          <div className="col-lg-10 col-lg-offset-1">
            <div className="ibox">
              <div className="ibox-conten" style={{ marginTop: "70px" }}>
                <div className="pull-right m-b">
                  <button
                    className="btn btn-white btn-sm"
                    type="button"
                    onClick={() => history.goBack()}
                  >
                    <i className="fa fa-times text-danger" />
                    &nbsp;{appLabel.closeBtn}
                  </button>
                </div>
                {/* <div className="text-center article-title">
                  <h1>Terms and Conditions</h1>
                </div> */}

                <ReactPdfViewer fileParam={file} />
                <hr />

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(TermsDetail);
