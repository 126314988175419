import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../../services/api.action";
import { connect, useDispatch } from "react-redux";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../../ui-setup/RecordStatus";
import appStatic from "../../../config/appStaticData";
import RightSide from "../../ui-setup/RightSide";
import {
  InputTextFacility,
  Col12BlockFacility,
  AmountFacility,
  AmntFormatFac,
  ColBlockFac,
  AppModalFac,
  TableSimpleFac,
} from "../../layouts/Facility";

export const EditFee = (props) => {
  const { location, userData, configData, recordData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId, companyTypeId } = userData;
  const { isCsd } = configData;
  const { toolTip, chargeType, yesOrNoStatus, otherFeeData, tableData } =
    appStatic;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const {
    register: register2,
    unregister: unregister2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    setValue: setValue2,
  } = useForm();

  const dispatch = useDispatch();
  const [currencyData, setCurrencyData] = useState([]);
  const [isRequiredField, setIsRequiredField] = useState(true);
  const [isFlatOption, setIsFlatOption] = useState(false);
  const [isPercOpt, setIsPercOpt] = useState(false);
  const [isSlab, setIsSlab] = useState(false);

  const [show, setShow] = useState(false);
  const [feeAmount, setFeeAmount] = useState(undefined);
  const [minFeeAmount, setMinFeeAmount] = useState(undefined);
  const [maxFeeAmount, setMaxFeeAmount] = useState(undefined);
  const [addFeeSlab, setAddFeeSlab] = useState([]);
  const [companyTypeData, setCompanyTypeData] = useState([]);

  useEffect(() => {
    getCurrency();
    getCompanyType();
    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);
  }, []);
  function getCompanyType() {
    /** FETCH MENU */
    let body = {
      profileId,
      criteria: "A",
      companyTypeId: "",
      session,
    };
    BOM.FetchReqAction(body, api.GetCompanyType, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        // get all entity type except custodian
        let tempCompany = res.companyTypeListing.filter(
          (item) => item.COMPANY_TYPE_ID !== "CT2022012400000"
        );
        setCompanyTypeData(tempCompany);
      }
    });
  }
  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      feeId: params.FEE_ID,
      ccy: "",
      session,
      companyTypeId,
      loanAmount: "",
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetFees, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.feeDetail;
        let tempSlab = [];
        dispatch({
          type: cnt.RECORD_STATUS,
          payload: objDetail,
        });

        setValue("feeId", objDetail.FEE_ID);
        setValue("feeName", objDetail.FEE_NAME);
        setValue("feeType", objDetail.FEE_TYPE);
        setValue("feeAmount", objDetail.FEE_AMOUNT);
        setValue("feePercentage", objDetail.FEE_PERCENTAGE);
        setValue("feeOption", objDetail.FEE_OPTION);
        setValue("isFeeSlab", objDetail.FLG_FEE_SLAB);
        setValue("feeCcy", objDetail.FEE_CCY);
        setValue("companyTypeId", objDetail.COMPANY_TYPE_ID);

        //slab array logic
        objDetail.SLAB_DATA.forEach((element) => {
          tempSlab.push({
            minFeeAmount: parseFloat(element.FEE_AMOUNT_MIN, 10),
            maxFeeAmount: parseFloat(element.FEE_AMOUNT_MAX, 10),
            feeRate: element.FEE_RATE,
          });
        });
        setAddFeeSlab(tempSlab);

        onChangeType(objDetail.FEE_TYPE);
        onFeeSlab(objDetail.FLG_FEE_SLAB);
      }
    });
  };
  function getCurrency() {
    /** FETCH MENU */
    let body = {
      profileId,
      criteria: "A",
      currencyId: "",
      session,
    };
    BOM.FetchReqAction(body, api.GetCurrency, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setCurrencyData(res.currencyListing);
        getDetailById(ggParam);
      }
    });
  }
  const onChangeType = (key) => {
    setIsFlatOption(false);
    setIsPercOpt(false);
    // $(".clearType").val("");

    switch (key.toLowerCase()) {
      case "flat":
        setIsFlatOption(true);
        break;
      case "percentage":
        setIsPercOpt(true);
        break;
    }
  };
  const onFeeSlab = (key) => {
    setIsSlab(false);
    // $(".clearSlab").val("");

    switch (key.toLowerCase()) {
      case "y":
        setIsSlab(true);
        break;
      case "n":
        setIsSlab(false);
        break;
    }
  };

  const onAddAmtSlab = (formValues) => {
    // VALIDATE MIN AND MAXIMUM
    const tempFound = addFeeSlab.find(
      (item, index) => item.maxFeeAmount === minFeeAmount
    );

    if (tempFound) {
      BOM.AlertMsg(
        cnt.WARNING,
        "The minimum and maximum fees must be different."
      );
      return;
    }

    if (minFeeAmount === maxFeeAmount) {
      BOM.AlertMsg(
        cnt.WARNING,
        "The minimum and maximum fees must be different."
      );
      return;
    }

    if (minFeeAmount > maxFeeAmount) {
      BOM.AlertMsg(
        cnt.WARNING,
        "The minimum fee cannot be greater than maximum fee."
      );
      return;
    }
    const feeRate = formValues.feeRate;
    setAddFeeSlab([
      ...addFeeSlab,
      {
        minFeeAmount: minFeeAmount,
        maxFeeAmount: maxFeeAmount,
        feeRate: feeRate,
      },
    ]);

    console.log(addFeeSlab);

    // $('.clearInputClass').val('')
  };
  const removeItem = (index) => {
    setAddFeeSlab([
      ...addFeeSlab.slice(0, index),
      ...addFeeSlab.slice(index + 1),
    ]);
  };
  const formSubmit = (formValues) => {
    // console.log("data", formValues);
    // if (!isSlab) {
    //   formValues.minFeeAmount = 0 +"";
    //   formValues.maxFeeAmount = 0 +"";
    //   formValues.feeRate = 0 +"";
    // }

    if (isFlatOption) {
      formValues.feePercentage = 0 + "";
    } else {
      formValues.feeAmount = 0 + "";
    }

    /** COVERT SLAB DATA VALUES INTO STRINGS */
    let tempFeeSlab = [];
    addFeeSlab.forEach((element) => {
      tempFeeSlab.push({
        minFeeAmount: element.minFeeAmount + "",
        maxFeeAmount: element.maxFeeAmount + "",
        feeRate: element.feeRate + "",
      });
    });

    formValues.feeSlab = isSlab ? tempFeeSlab : [];

    let body = {
      profileId,
      companyTypeId,
      session,
      ...formValues,
    };

    // console.log(body);
    // return;
    /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(body, api.UpdateFee, "/manage-fee");
  };

  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <RightSide />
        <ContentTop pageTitle={appLabel.editFee} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{appLabel.feeInfo}</h5>
                  <div className="ibox-tools">
                    <Link to="/manage-fee" className="btn btn-primary btn-xs">
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <Col12BlockFacility topTitle={appLabel.generalInfo}>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.companyType} &nbsp;
                            <span className="asterisks"> *</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm"
                            name="companyTypeId"
                            {...register("companyTypeId", {
                              required: "Field is required.",
                            })}
                          >
                            <option value="">{appLabel.choose}</option>
                            {companyTypeData.map((item, index) => (
                              <option value={item.COMPANY_TYPE_ID} key={index}>
                                {item.COMPANY_TYPE_NAME}
                              </option>
                            ))}
                          </select>
                          <span className="asterisks">
                            {errors?.companyTypeId?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.feeName}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.feeName}
                            {...register("feeName", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.feeName?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6 ">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.currency}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm"
                            {...register("feeCcy", {
                              required: "Field is required.",
                            })}
                          >
                            <option value="">{appLabel.choose}</option>
                            {currencyData.map((item, index) => (
                              <option value={item.CCY_CODE} key={index}>
                                {item.CCY_CODE}
                              </option>
                            ))}
                          </select>
                          <span className="asterisks">
                            {errors?.feeCcy?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.feeType}{" "}
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm"
                            {...register("feeType", {
                              required: "Field is required.",
                              onChange: (e) => onChangeType(e.target.value),
                            })}
                          >
                            <option value="">{appLabel.choose}</option>
                            {chargeType.map((item, index) => (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <span className="asterisks">
                            {errors?.feeType?.message}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`row form-group col-md-6 ${
                          isFlatOption ? "" : "hide"
                        }`}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.feeAmount}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm clearType"
                            placeholder={appLabel.feeAmount}
                            {...register("feeAmount", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.feeAmount?.message}
                          </span>
                        </div>
                      </div>

                      <div
                        className={`row form-group col-md-6 ${
                          isPercOpt ? "" : "hide"
                        }`}
                      >
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.feePercentage}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm clearType"
                            placeholder={appLabel.feePercentage}
                            {...register("feePercentage", {
                              required: isPercOpt
                                ? "Field is required."
                                : false,
                            })}
                          />
                          <span className="asterisks">
                            {errors?.feePercentage?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.feeOption}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm"
                            {...register("feeOption", {
                              required: "Field is required.",
                            })}
                          >
                            <option value="">{appLabel.choose}</option>
                            {otherFeeData.map((item, index) => (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <span className="asterisks">
                            {errors?.feeOption?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.isFeeSlab}{" "}
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className="form-control input-sm"
                            {...register("isFeeSlab", {
                              required: "Field is required.",
                              onChange: (e) => onFeeSlab(e.target.value),
                            })}
                          >
                            <option value="">{appLabel.choose}</option>
                            {yesOrNoStatus.map((item, index) => (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <span className="asterisks">
                            {errors?.isFeeSlab?.message}
                          </span>
                        </div>
                      </div>
                    </Col12BlockFacility>

                    <div className={`${isSlab ? "" : "hide"}`}>
                      <ColBlockFac>
                        <AppModalFac
                          show={show}
                          title={"Add Multiple"}
                          handleClose={() => setShow(false)}
                          handleSave={handleSubmit2(onAddAmtSlab)}
                        >
                          <div className={`${isSlab ? "" : "hide"}`}>
                            <div className=" form-group col-sm-12 col-md-6">
                              <label className="al-subtitle">
                                {appLabel.minAmount}&nbsp;
                                <span className="asterisks">*</span>
                              </label>

                              <AmountFacility
                                setIsRequiredField={setIsRequiredField}
                                setAmount={setMinFeeAmount}
                                isRequiredField={isRequiredField}
                              />
                            </div>
                            <div className=" form-group col-sm-12 col-md-6">
                              <label className="al-subtitle">
                                {appLabel.maxAmount}&nbsp;
                                <span className="asterisks">*</span>
                              </label>

                              <AmountFacility
                                setIsRequiredField={setIsRequiredField}
                                setAmount={setMaxFeeAmount}
                                isRequiredField={isRequiredField}
                              />
                            </div>

                            <div className=" form-group col-sm-12 col-md-6">
                              <label className="al-subtitle">
                                {appLabel.rate}&nbsp;
                                <span className="asterisks">*</span>
                              </label>

                              <input
                                type="text"
                                className="form-control input-sm clearInputClass"
                                placeholder={appLabel.rate}
                                {...register2("feeRate", {
                                  required: isSlab
                                    ? "Field is required."
                                    : false,
                                })}
                              />
                              <span className="asterisks">
                                {errors2?.feeRate?.message}
                              </span>
                            </div>
                          </div>
                        </AppModalFac>
                      </ColBlockFac>
                      <ColBlockFac topTitle={appLabel.slabInfo}>
                        {!show && (
                          <div className="col-sm-2">
                            <div className="title-action">
                              <button
                                type="button"
                                title="Add more Materials"
                                className="btn btn-primary btn-sm"
                                onClick={() => setShow(!show)}
                              >
                                <i className="fa fa-plus" />
                              </button>
                              <div
                                className="hr-line"
                                style={{ marginBottom: "10px" }}
                              />
                            </div>
                          </div>
                        )}
                        <ColBlockFac>
                          {addFeeSlab.length === 0 ? (
                            <div className="alert alert-warning text-center">
                              <a className="alert-link">
                                {appLabel.noDataInTableMsg}
                              </a>
                            </div>
                          ) : (
                            <TableSimpleFac thData={tableData.feeSlabList}>
                              {addFeeSlab.map((item, index) => (
                                <tr className="gradeX" key={index}>
                                  <td>
                                    {index + 1} &nbsp;
                                    <i
                                      style={{ cursor: "pointer" }}
                                      className="fa fa-times-circle text-danger"
                                      onClick={() =>
                                        removeItem(index, "material")
                                      }
                                    />
                                  </td>
                                  <td>
                                    <AmntFormatFac
                                      inputValue={item.minFeeAmount}
                                    />
                                  </td>
                                  <td>
                                    <AmntFormatFac
                                      inputValue={item.maxFeeAmount}
                                    />
                                  </td>
                                  <td>
                                    <AmntFormatFac inputValue={item.feeRate} />
                                  </td>
                                </tr>
                              ))}
                            </TableSimpleFac>
                          )}
                        </ColBlockFac>
                      </ColBlockFac>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <RecordStatus {...userDetail} /> */}
        </div>
      </form>
      <RecordStatus {...recordData} />
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  recordData: state.recordStatus,
  configData: state.auth.configData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditFee);
