import React, { useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../../services/api.action";
import { connect, useDispatch } from "react-redux";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../../ui-setup/RecordStatus";
import appStatic from "../../../config/appStaticData";
import RightSide from "../../ui-setup/RightSide";
import {
  InputTextFacility,
  Col12BlockFacility,
  AmountFacility,
} from "../../layouts/Facility";

export const EditAdHoc = (props) => {
  const { location, userData, recordData, configData } = props;
  const { state } = location;
  const { profileId, session, companyId } = userData;
  const { isCsd } = configData;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const dispatch = useDispatch();
  const [currencyData, setCurrencyData] = useState([]);
  const [configDetail, setConfigDetail] = useState({});
  const [taxData, setTaxData] = useState([]);
  const [taxDetail, setTaxDetail] = useState([]);
  const [isRequiredField, setIsRequiredField] = useState(true);
  const [amount, setAmount] = useState(undefined);

  useEffect(() => {
    getDetailById(state.ggParam);
    BOM.ActivateRightBtnAction("NewItemForm", "saveBtn", handleSubmit);
  }, []);

  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      configId:params.CONFIG_ID,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetConfig, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.configDetail;
        setConfigDetail(objDetail);
        setValue("configName", objDetail.CONFIG_NAME);
        setValue("configValue", objDetail.CONFIG_VALUE);
        setValue("configId", objDetail.CONFIG_ID);

            dispatch({
          type: cnt.RECORD_STATUS,
          payload: objDetail,
        });
      }
    });
  };
  const formSubmit = (formValues) => {
    // console.log("data", formValues);

    let body = {
      profileId,
      session,
      ...formValues,
    };

    // console.log(body);
    /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(body, api.UpdateConfig, "/manage-ad-hoc");
  };

  return (
    <Main {...props}>
      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <RightSide />
        <ContentTop pageTitle={appLabel.editAdHoc} />
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>{appLabel.adHoc}</h5>
                  <div className="ibox-tools">
                    <Link
                      to="/manage-ad-hoc"
                      className="btn btn-primary btn-xs"
                    >
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                        {appLabel.generalInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.configName}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.configName}
                            {...register("configName", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.configName?.message}
                          </span>
                        </div>
                      </div>
                      <div className="row form-group col-md-6">
                        <div className="col-md-4 text-left">
                          <label className="al-subtitle">
                            {appLabel.configValue}&nbsp;
                            <span className="asterisks">*</span>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder={appLabel.configValue}
                            {...register("configValue", {
                              required: "Field is required.",
                            })}
                          />
                          <span className="asterisks">
                            {errors?.configValue?.message}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <RecordStatus {...recordData} />
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditAdHoc);
