import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../../../services/api.action";
import { connect, useDispatch } from "react-redux";
import Main from "../../../layouts/Main";
import ContentTop from "../../../ui-setup/ContentTop";
import { cnt } from "../../../../services/constant.action";
import { BOM } from "../../../../services/defined.action";
import appLabel from "../../../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../../../ui-setup/RecordStatus";
import { HandleSkeletonDetailFac } from "../../../layouts/Facility";

export const EntityNotificationDetail = (props) => {
  const { location, userData, configData, recordData, menuData } = props;
  const { state } = location;
  const { profileId, session, companyId } = userData;
  const { isCsd } = configData;

  const dispatch = useDispatch();
  const [notificationDetail, setNotificationDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // console.log(state.ggParam);

    getDetailById(state.ggParam);
  }, []);

  const getDetailById = (params) => {
    // console.log(params);

    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true);

    let body = {
      profileId,
      notificationId: params.NOTIF_ID,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetEntityNotification, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let notificationDetail = res.companyNotifDetail;

        setNotificationDetail(notificationDetail);
        dispatch({
          type: cnt.RECORD_STATUS,
          payload: notificationDetail,
        });
        //handle right side
        BOM.IsApproveOrReject(
          notificationDetail.FLG_STATUS,
          menuData,
          "Entity Notification"
        );
        setIsLoading(false);
      }
    });
  };

  const onApprove = () => {
    let bodyApprove = {
      profileId,
      notificationId: state?.ggParam?.NOTIF_ID,
      criteria: "A",
      session,
    };

    // console.log(bodyApprove);
    BOM.GlobalApproveRejectAction(
      bodyApprove,
      api.ApproveRejectEntityNotification,
      "manage-entity-notification",
      state?.ggParam?.NOTIF_NAME,
      "Approve "
    );
  };
  const onReject = () => {
    let bodyDisable = {
      profileId,
      notificationId: state?.ggParam?.NOTIF_ID,
      criteria: "R",
      session,
    };

    // console.log(bodyDisable);
    BOM.GlobalApproveRejectAction(
      bodyDisable,
      api.ApproveRejectEntityNotification,
      "manage-entity-notification",
      state?.ggParam?.NOTIF_NAME,
      "Reject "
    );
  };

  return (
    <Main {...props} onApprove={onApprove} onReject={onReject}>
      <ContentTop pageTitle={appLabel.entityNotificationDetail} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <HandleSkeletonDetailFac
              classParam={`${isLoading ? "" : "hide"}`}
            />
            <div className={`${isLoading ? "hide" : "ibox"}`}>
              <div className="ibox-title">
                <h5>{appLabel.notificationInformation}</h5>
                <div className="ibox-tools">
                  <Link
                    to="/manage-entity-notification"
                    className="btn btn-primary btn-xs"
                  >
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;
                    {appLabel.backBtn}
                  </Link>
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.generalInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="row form-group col-md-6">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.notificationId}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={notificationDetail.NOTIF_ID}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.notificationName}{" "}
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={notificationDetail.NOTIF_NAME}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.description}
                        </label>
                      </div>
                      <div className="col-md-7">
                        <textarea
                         disabled
                          style={{
                            width: "100%",
                            height: 80,
                            padding: 5,
                          }}
                          defaultValue={notificationDetail.NOTIF_DESCRIPTION}
                          className="form-control"
                          placeholder="description"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isLoading && <RecordStatus {...recordData} />}
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntityNotificationDetail);
