import React, { useEffect, useState } from "react";
import { apis as api } from "../../services/api.action";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import RecordStatus from "../ui-setup/RecordStatus";
import NumberFormat from "react-number-format";
import history from "../../history";
import { Tooltips } from "../layouts/Facility";

export const OrderToVerifyDetail = (props) => {
  const {
    location,
    userData,
    configData,
    verifyOrderId,
    recordData,
    menuData,
  } = props;
  const { state } = location;
  const { ggParam } = state;
  const { profileId, session, companyId } = userData;
  const { isCsd } = configData;
  const { toolTip } = appStatic;

  const dispatch = useDispatch();
  const [isFullPayment, setIsFullPayment] = useState(false);
  const [isCsdCheck, setIsCsdCheck] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [orderDetail, setOrderDetail] = useState({});
  const [materials, setMaterialData] = useState([]);

  useEffect(() => {
    // console.log(ggParam);
    if (isCsd.toLowerCase() === "y") {
      setIsCsdCheck(true);
    }
    getDetailById(ggParam);
    BOM.getSpecDoc(ggParam?.ORDERID, setDocuments, userData);
  }, []);

  const onChoosePaymentOption = (type) => {
    // console.log(type);
    switch (type.toLowerCase()) {
      case "instalment":
        setIsFullPayment(true);
        break;
      default:
        setIsFullPayment(false);
        break;
    }
  };
  const getDetailById = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      orderId: params.ORDERID,
      companyId,
      session,
    };

    // /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetVerifiedPo, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let orderDetail = res.purchaseOrderDetail;

        setOrderDetail(orderDetail);
        dispatch({
          type: cnt.RECORD_STATUS,
          payload: orderDetail,
        });

        onChoosePaymentOption(orderDetail.PO_PAYMENT_OPTION);
        setMaterialData(orderDetail.MATERIAL_DATA);

        if (
          orderDetail.FLG_STATUS === "A" &&
          orderDetail.PO_VERIFICATION_STATUS === "U"
        ) {
          //We passed U to have the two thumbs
          BOM.IsApproveOrReject("U", menuData, "Verify PO");
        }

        //get document
        // getSpecDoc(orderDetail);
      }
    });
  };

  const getSpecDoc = (params) => {
    // console.log(params);

    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      id: params.ORDERID,
      type: "purchaseorder",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetDocument, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setDocuments(res.docListing);
      }
    });
  };

  // const onVerifyOrder = (orderId) => {

  //   let bodyVerify = {
  //     profileId,
  //     orderId,
  //     criteria: "A",
  //     session,
  //   };

  //   // console.log(bodyVerify);
  //   BOM.GlobalApproveRejectAction(
  //     bodyVerify,
  //     api.VerifyPo,
  //     "order-to-verify",
  //     "PO " + orderId,
  //     "Verify "
  //   );
  // }

  const onApprove = () => {
    let body = {
      profileId,
      orderId: ggParam.ORDERID,
      criteria: "A",
      session,
    };

    // console.log(body);
    // return;
    BOM.GlobalApproveRejectAction(
      body,
      api.VerifyPo,
      "order-to-verify",
      ggParam.ORDERID,
      "Approve "
    );
  };

  const onReject = () => {
    let body = {
      profileId,
      orderId: ggParam.ORDERID,
      criteria: "R",
      session,
    };

    // console.log(body);
    // return;
    BOM.GlobalApproveRejectAction(
      body,
      api.VerifyPo,
      "order-to-verify",
      ggParam.ORDERID,
      "Reject "
    );
  };

  return (
    <Main {...props} onApprove={onApprove} onReject={onReject}>
      <ContentTop pageTitle={appLabel.orderDetail} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>{appLabel.orderInfo}</h5>
                <div className="ibox-tools">
                  <Link
                    to="/order-to-verify"
                    className="btn btn-primary btn-xs"
                  >
                    <i className="fa fa-arrow-circle-left" />
                    &nbsp;
                    {appLabel.backBtn}
                  </Link>
                  {/* {orderDetail.FLG_STATUS === "A" &&
                    orderDetail.PO_VERIFICATION_STATUS === "U" && (
                      <button
                        type="button"
                        onClick={() => onVerifyOrder(orderDetail.ORDERID)}
                        className="btn btn-primary btn-xs"
                      >
                        <i className="fa fa-check-circle" />
                        &nbsp;
                        {appLabel.verifyOrder}&nbsp;
                        <Tooltips title={toolTip.verifyOrder} />
                      </button>
                    )} */}
                </div>
              </div>
              <div className="ibox-content contentCard panel-primary">
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.generalInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.poNo}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.ORDERID}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.from}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.FROM_COMPANY_NAME}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.to}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.TO_COMPANY_NAME}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.poAmount}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <NumberFormat
                          className="form-control input-sm"
                          value={orderDetail.PO_AMOUNT}
                          displayType="text"
                          disabled
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.currency}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.PO_CCY}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6 ">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.pymtOption}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.PO_PAYMENT_OPTION}
                          disabled
                        />
                      </div>
                    </div>
                    <div
                      className={`row form-group col-md-6 ${
                        isFullPayment ? "" : "hide"
                      }`}
                    >
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.frequency}
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.PO_INSTALLMENT_FREQ}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.firstPayDue}{" "}
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.PO_FIRSTPAY_DUE}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group col-md-6">
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.lastPayDue}{" "}
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.PO_LASTPAY_DUE}
                          disabled
                        />
                      </div>
                    </div>
                    <div
                      className={`row form-group col-md-6 ${
                        isCsdCheck ? "" : "hide"
                      }`}
                    >
                      <div className="col-md-4 text-left">
                        <label className="al-subtitle">
                          {appLabel.csdNumber}&nbsp;
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control input-sm"
                          defaultValue={orderDetail.CSD_NUMBER}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label className="al-heading">
                      <i className="fa fa-circle-o text-navy"></i>&nbsp;{" "}
                      {appLabel.otherInfo}
                    </label>
                    <div className="hr-line-dashed" style={{ marginTop: 0 }} />
                    <ul className="nav nav-tabs">
                      <li className="active">
                        <a
                          data-toggle="tab"
                          href="#materialInfo"
                          target="_self"
                        >
                          <i className="fa fa-tag text-navy" />
                          &nbsp;{appLabel.materialInfo}
                        </a>
                      </li>
                      <li>
                        <a
                          data-toggle="tab"
                          href="#documentInfo"
                          target="_self"
                        >
                         <i className="fa fa-file-pdf-o text-navy"  />
                          &nbsp;{appLabel.documentInfo}
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane active" id="materialInfo">
                        <div className="row" style={{ marginTop: "20px" }}>
                          <div className="col-sm-12">
                            <div className="table-responsive">
                              {materials.length === 0 ? (
                                <div className="alert alert-warning text-center">
                                  <a className="alert-link">
                                    {appLabel.noDataInTableMsg}
                                  </a>
                                </div>
                              ) : (
                                <table
                                  className="footable table table-stripped toggle-arrow-tiny"
                                  data-page-size="10"
                                  data-filter="#filter"
                                >
                                  <thead>
                                  <tr>
                                      <th>#</th>
                                      <th>{appLabel.manufacturer}</th>
                                      <th>{appLabel.description}</th>
                                      <th>{appLabel.unitPrice}</th>
                                      <th>{appLabel.quantity}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {materials.map((item, index) => (
                                      <tr className="gradeX" key={index}>
                                      <td>{index + 1}</td>
                                      <td>{item.MANUFACTURER}</td>
                                      <td>{item.MAT_DESC}</td>
                                      <td>
                                        <NumberFormat
                                          value={item.UNIT_PRICE}
                                          displayType="text"
                                          thousandSeparator={true}
                                          prefix={item.PO_CCY + " "}
                                        />
                                      </td>
                                      <td>{item.QUANTITY}</td>
                                    </tr>
                                    ))}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td colSpan="8">
                                        <ul className="pagination pull-right"></ul>
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="documentInfo">
                        <div className="row" style={{ marginTop: "30px" }}>
                          <div className="col-sm-12">
                            {documents.length === 0 ? (
                              <div className="alert alert-warning text-center">
                                <a className="alert-link">
                                  {appLabel.noDocumentMsg}
                                </a>
                              </div>
                            ) : (
                              <div>
                                {documents.map((item, index) => (
                                  <div className="col-md-4" key={index}>
                                    <div className="file-box">
                                      <div className="file">
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            BOM.DownloadAction(
                                              item.DOC_OBJECT,
                                              item.DOC_ID +
                                                " " +
                                                item.DOC_CATEGORY.toLowerCase()
                                            )
                                          }
                                        >
                                          <span className="corner" />
                                          <div className="icon">
                                           <i className="fa fa-file-pdf-o" style={{color:'#d50000'}} />
                                          </div>
                                          <div className="file-name">
                                            {item.DOC_CATEGORY}
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RecordStatus {...recordData} />
      </div>
      {/* <RecordStatus {...BOM.NewRecordStatusdata()} /> */}
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  configData: state.auth.configData,
  verifyOrderId: state.staticData.verifyOrderId,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderToVerifyDetail);
