/**
 * @author Gedeon NdundeCode
 * @date 05/12/2021
 */
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { apis as api } from "../../services/api.action";
import { cnt } from "../../services/constant.action";

import {
  SignInAction,
  SignOutAction,
} from "../../services/redux/actions/auth-act";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import Logo from "../../assets/img/logoLogin/fxBg.jpg";
import { BgImgFac } from "../layouts/Facility";
import { SubmitBtnLoad } from "../layouts/Facility";

const LockScreen = (props) => {
  /** INITIALIZE VARIABLES */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { history, SignInAction, SignOutAction, lockscreenData } = props;
  const { userName, fullName } = lockscreenData;
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const storeData = [
    cnt.LOCKSCREEN,
    cnt.CLEAR_RIGHT_BTN,
    cnt.CLEAR_STATIC_DATA,
  ];

  useEffect(() => {
    storeData.map((item) => {
      dispatch({
        type: item,
      });
    });
  }, []);

  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  /** SUBMISSION */
  const onSignIn = (formData) => {
    setIsLoading(true);

    const body = {
      userName,
      userPass: formData.userPass,
      sourceIp: "197.184.164.52", //ip.IPv4,
      browser: BOM.GetBrowserName(),
      host: window.location.host,
      osName: "windows",
      longitude: "-25.757", //ip.longitude + "",
      latitude: "28.1443", //ip.latitude + "",
      channel: "self service",
    };

    // console.log(body);
    // return;
    //Send request
    // SignInAction(body);

    BOM.SessionLessReqAction(body, api.Authentication, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
        setIsLoading(false);
      } else {
        getRoleMenu(res.userData); //GET DYNAMIC MENU FOR CURRENT USER

        dispatch({
          type: cnt.SIGN_IN,
          payload: res,
        });

        // decide which screen to go after login
        switch (res.userData.firstStatus.toLowerCase()) {
          case "a":
            history.push("/first-time-login-password");
            break;

          default:
            setTimeout(() => {
              history.push("/dashboard");
            }, 500);
            break;
        }
      }
    });
  };
  async function getRoleMenu(user) {
    // BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId: user.profileId,
      roleId: user.userRoleId,
      session: user.session,
    };

    BOM.FetchReqAction(body, api.GetRoleMenu, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.roleData.FUNCTIONS;
        dispatch({
          type: cnt.MENU_DATA,
          payload: objList,
        });
        // $("#side-menu").metisMenu();
      }
    });
  }

  return (
    <React.Fragment>
      <BgImgFac />
      <div className="centeredContent">
        <div className="row">
          <div className="col-md-12">
            <header>
              <div
                className="middle-box loginscreen animated fadeInDown login-card"
                style={{ marginBottom: "0%" }}
              >
                <div className="text-center">
                  <img
                    alt="image"
                    style={{
                      width: "150px",
                      marginTop: "10px",
                      marginBottom: "25px",
                    }}
                    src={Logo}
                  />
                </div>

                {/* <div className="slogan text-center">
                  <h2>&nbsp;</h2>
                </div> */}
                <form
                  // className="m-t"
                  onSubmit={handleSubmit(onSignIn)}
                  autoComplete="off"
                >
                  <div className="form-group">
                    <label className="text-left">
                      <p style={{ fontWeight: "normal", fontSize: "14px" }}>
                        Hi, <strong>{fullName}</strong>. You are currently on
                        the lock screen; please enter your FeX password to
                        proceed.
                      </p>
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="text-left">{appLabel.password}</label>
                    <input
                      type={passwordShown ? "text" : "password"}
                      className="form-control input-sm"
                      placeholder="********"
                      {...register("userPass", {
                        required: "Field is required.",
                        minLength: {
                          value: 8,
                          message: "Minimum password length is 8 characters",
                        },
                      })}
                    />
                    <span className="p-viewer" onClick={() => togglePassword()}>
                      <i
                        className={
                          passwordShown ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                      ></i>
                    </span>
                    <span className="asterisks">
                      {errors?.userPass?.message}
                    </span>
                  </div>
                  <div className="form-group text-center">
                    {/* <button
                      type="submit"
                      className="btn btn-primary block full-width"
                      style={{
                        marginTop: 30,
                        boxShadow: "0 10px 6px -6px #fff",
                      }}
                    >
                      <i className="fa fa-unlock" /> &nbsp;{" "}
                      {appLabel.unlockBtn}
                    </button> */}
                    <SubmitBtnLoad
                      isLoading={isLoading}
                      btnName={appLabel.unlockBtn}
                      isFullWidth={true}
                      icon={"fa fa-unlock"}
                    />
                  </div>
                  <div className="form-group text-center">
                    <Link to="/sign-in" style={{ fontWeight: "bold" }}>
                      <i className="fa fa-chevron-circle-left" />{" "}
                      <small>{appLabel.goToLogin}</small>
                    </Link>
                  </div>
                </form>
              </div>
            </header>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  lockscreenData: state.auth.lockscreenData,
});

const mapDispatchToProps = { SignInAction, SignOutAction };

export default connect(mapStateToProps, mapDispatchToProps)(LockScreen);
