import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { apis as api } from "../../../services/api.action";
import { connect, useDispatch } from "react-redux";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import { Link } from "react-router-dom";
import RecordStatus from "../../ui-setup/RecordStatus";
import { HandleSkeletonDetailFac, ColBlockFac } from "../../layouts/Facility";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const testNotification = [
  { value: "Currency", label: "Currency" },
  { value: "World", label: "World" },
  { value: "Bank", label: "Bank" },
  { value: "Drink", label: "Drink" },
  { value: "Food", label: "Food" },
  { value: "Limitation", label: "Limitation" },
  { value: "Luck", label: "Luck" },
];

export const UserNotification = (props) => {
  const { location, userData, configData, recordData, menuData, history } =
    props;
  const { state } = location;
  const { profileId, session, companyId } = userData;
  const { isCsd } = configData;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const dispatch = useDispatch();
  const [parentRoleData, setParentRoleData] = useState([]);
  const [subRoleData, setSubRoleData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedParentRole, setSelectedParentRole] = useState(null);
  const [selectedSubrole, setSelectedSubrole] = useState(null);
  const [selectedNotif, setSelectedNotif] = useState(null);
  const [userNotifData, setUserNotifData] = useState([]);
  const [isSetup, setIsSetup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isClearable, setIsClearable] = useState(true);
  const [userNotifListing, setUserNotifListing] = useState([]);

  useEffect(() => {
    getUserNotification();
    getRolesByEntity();
    getNotification();
  }, []);

  function getUserNotification() {
    /** FETCH MENU */
    let body = {
      profileId,
      session,
    };
    BOM.FetchReqAction(body, api.GetUserNotification, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let objList = res.notifListing;

        let temp = [];

        objList.forEach((element, index) => {
          temp.push({
            userId: element?.PROFILE_ID,
            username: element?.USERNAME,
            parentRoleName: element?.PARENT_ROLE_NAME,
            roleName: element?.SUB_ROLE_NAME,
            notificationData: element?.NOTIF_DATA.map((item) => {
              return {
                notifId: item.NOTIF_ID,
                notifName: item.NOTIF_NAME,
              };
            }),
          });
        });
        // console.log(temp);
        setUserNotifData(temp);
      }
    });
  }

  function getUser() {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      userId: "",
      criteria: "A",
      companyId,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetUser, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let objList = res.userListing;
        let temp = [];

        objList.forEach((element) => {
          temp.push({
            value: element.USERID,
            label: element.USERNAME,
            ...element,
          });
        });

        setUsersData(temp);
      }
    });
  }
  function getRolesByEntity() {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      subRoleId: "",
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetRolesByEntity, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let objList = res.roleListing;
        let temp = [];

        objList.forEach((element) => {
          temp.push({
            value: element.PARENT_ROLE_ID,
            label: element.PARENT_ROLE_NAME,
            ...element,
          });
        });

        setParentRoleData(temp);
      }
    });
  }
  function getUserRolesByEntity(subRoleId) {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      subRoleId,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetRolesByEntity, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let objList = res.userListing;
        let temp = [];

        objList.forEach((element) => {
          temp.push({
            value: element.PROFILE_ID,
            label: element.USERNAME,
            ...element,
          });
        });

        setUsersData(temp);
      }
    });
  }
  function getNotification() {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      criteria: "A",
      session,
    };

    BOM.FetchReqAction(body, api.GetEntityNotification, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let objList = res.companyNotifListing;
        let temp = [];

        objList.forEach((element) => {
          temp.push({
            value: element.NOTIF_ID,
            label: element.NOTIF_NAME,
            ...element,
          });
        });

        setNotificationData(temp);
      }
    });
  }
  const onChangeItem = (param, key) => {
    switch (key?.toLowerCase()) {
      case "username":
        setSelectedUser(param);
        break;
      case "notification":
        console.log(param);
        setSelectedNotif(param);
        break;
      case "parent-role":
        let temp = [];
        param?.SUB_ROLE_DATA.forEach((element) => {
          temp.push({
            value: element.SUB_ROLE_ID,
            label: element.SUB_ROLE_NAME,
            ...element,
          });
        });
        setSubRoleData(temp);
        setSelectedParentRole(param);
        break;
      case "subrole":
        getUserRolesByEntity(param.SUB_ROLE_ID);
        setSelectedSubrole(param);
        break;
      default:
        break;
    }
  };

  const onAddLink = () => {
    console.log(selectedUser);
    // console.log(userNotifData);

    let temp = [];

    if (!selectedParentRole) {
      BOM.AlertMsg(cnt.WARNING, "Please select a Parent Role");
      return;
    }
    if (!selectedSubrole) {
      BOM.AlertMsg(cnt.WARNING, "Please select a Subrole");
      return;
    }
    if (!selectedUser) {
      BOM.AlertMsg(cnt.WARNING, "Please select a user");
      return;
    }

    if (selectedNotif.length === 0) {
      BOM.AlertMsg(cnt.WARNING, "Please select a Notification");
      return;
    }

    const currentItem = userNotifData.find(
      (item) => item.userId === selectedUser?.PROFILE_ID
    );
    if (currentItem) {
      setUserNotifData(
        userNotifData.filter((item) =>
          item.userId === selectedUser?.PROFILE_ID
            ? // item?.notificationData?.push({
              //     notifId: selectedNotif?.value,
              //     notifName: selectedNotif?.label,
              //   })

              selectedNotif.map((sub) => {
                item?.notificationData?.push({
                  notifId: sub?.value,
                  notifName: sub?.label,
                });
              })
            : { ...item }
        )
      );
    } else {
      // temp.push({
      //   notifId: selectedNotif?.value,
      //   notifName: selectedNotif?.label,
      // });

      selectedNotif.forEach((element) => {
        temp.push({
          notifId: element?.value,
          notifName: element?.label,
        });
      });

      setUserNotifData([
        ...userNotifData,
        {
          userId: selectedUser?.PROFILE_ID,
          username: selectedUser?.USERNAME,
          parentRoleName: selectedParentRole.PARENT_ROLE_NAME,
          roleName: selectedSubrole.SUB_ROLE_NAME,
          notificationData: temp,
        },
      ]);

      console.log(userNotifData);
    }

    // setSubRoleData([]);
    // setUsersData([]);
    // setNotificationData([]);
  };

  const removeItem = (index, type) => {
    switch (type.toLowerCase()) {
      case "user-notif":
        setUserNotifData([
          ...userNotifData.slice(0, index),
          ...userNotifData.slice(index + 1),
        ]);
        break;
      case "notif": // not in used so far
        setUserNotifData(
          userNotifData.filter((item) =>
            item.userId === selectedUser?.PROFILE_ID
              ? item?.notificationData?.filter((subItem, subIndex) => subIndex)
              : { ...item }
          )
        );
        break;
    }
  };

  const formSubmit = () => {
    // console.log("data", formValues);
    if (userNotifData.length === 0) {
      BOM.AlertMsg(cnt.WARNING, "Please configure user notification.");
      return;
    }
    let body = {
      profileId,
      userNotifData,
      session,
    };

    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(body, api.CreateUserNotification, "/manage-bank");
  };

  return (
    <Main {...props} onSaveChange={formSubmit}>
      <ContentTop pageTitle={appLabel.userNotification} />
      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <HandleSkeletonDetailFac
              classParam={`${isLoading ? "" : "hide"}`}
            />
            <div className={`${isLoading ? "hide" : "ibox"}`}>
              <div className="ibox-title">
                <h5>{appLabel.notificationInformation}</h5>
                <div className="ibox-tools">
                  <button
                    type="button"
                    className={`btn-xs m-r btn btn-${
                      isSetup ? "danger" : "primary"
                    }`}
                    onClick={() => setIsSetup(!isSetup)}
                  >
                    <i
                      className={`fa fa-${
                        isSetup ? "times" : "arrow-circle-right"
                      }`}
                    />
                    &nbsp;
                    {isSetup ? appLabel.cancelBtn : appLabel.userSetup}
                  </button>
                </div>
              </div>
              <div className="ibox-content content-card-v2 panel-primary">
                <div className="row">
                  {userNotifData.length === 0 && !isSetup ? (
                    <div
                      style={{ marginTop: "20px" }}
                      className="alert alert-success text-center"
                    >
                      {appLabel.noDataMsg}
                      <br />

                      <a
                        className="alert-link"
                        onClick={() => setIsSetup(!isSetup)}
                      >
                        <i className="fa fa-plus-circle fa-5x" />
                        <br />
                        {`Add New User Notification`}
                      </a>
                    </div>
                  ) : (
                    <>
                      <ColBlockFac topTitle={appLabel.userNotificationSetup}>
                        <div className="form-group col-md-2">
                          <label className="al-subtitle">
                            {appLabel.parentRoleName} &nbsp;
                            <span className="asterisks"> *</span>
                          </label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            options={parentRoleData}
                            onChange={(e) => onChangeItem(e, "parent-role")}
                          />
                          {/* <span className="asterisks">
                            {errors?.userId?.message}
                          </span> */}
                        </div>
                        <div className="form-group col-md-3">
                          <label className="al-subtitle">
                            {appLabel.roleName} &nbsp;
                            <span className="asterisks"> *</span>
                          </label>
                          <Select
                            className="basic-single "
                            classNamePrefix="select"
                            options={subRoleData}
                            // isClearable={isClearable}
                            onChange={(e) => onChangeItem(e, "subrole")}
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label className="al-subtitle">
                            {appLabel.userName} &nbsp;
                            <span className="asterisks"> *</span>
                          </label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            options={usersData}
                            onChange={(e) => onChangeItem(e, "username")}
                          />
                          <span className="asterisks">
                            {errors?.userId?.message}
                          </span>
                        </div>
                        <div className="form-group col-md-3">
                          <label className="al-subtitle">
                            {appLabel.notificationName}{" "}
                            <span className="asterisks">*</span>
                          </label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            options={
                              // testNotification
                              notificationData
                            }
                            // onChange={(e) => onChangeItem(e, "notification")}
                            onChange={(e) => onChangeItem(e, "notification")}
                            isMulti
                          />
                          <span className="asterisks">
                            {errors?.userId?.message}
                          </span>
                        </div>
                        <div className="form-group pull-right">
                          <div className="m-t-xs btn-group">
                            <a
                              className="btn btn-sm btn-primary"
                              onClick={() => onAddLink()}
                            >
                              <i className="fa fa-plus-circle" />{" "}
                              {appLabel.addBtn}{" "}
                            </a>
                            <a className="btn btn-sm btn-white">
                              <i className="fa fa-times-circle" />{" "}
                              {appLabel.closeBtn}
                            </a>
                          </div>
                        </div>
                      </ColBlockFac>
                      <ColBlockFac topTitle={appLabel.listOfUserNotification}>
                        {userNotifData.map((item, index) => (
                          <div className="col-sm-3" key={index}>
                            <div className="ibox notification-card">
                              <div className="ibox-content">
                                {/* <h5>Profile {index + 1}</h5> */}
                                <h5
                                  className="text-center"
                                  style={{ cursor: "pointer" }}
                                >
                                  <i className="fa fa-user text-navy m-r" />
                                  <i
                                    className="fa fa-times-circle text-danger"
                                    onClick={() =>
                                      removeItem(index, "user-notif")
                                    }
                                  />
                                </h5>
                                <h5>&nbsp;{item.username}</h5>
                                <h6 className="text-center">{`${item.roleName} ( ${item.parentRoleName} )`}</h6>
                                <table className="table table-stripped small m-t-md">
                                  <tbody>
                                    {BOM.GetUnique(
                                      item?.notificationData,
                                      "notifId"
                                    ).map((item2, sub) => (
                                      <tr key={sub}>
                                        <td style={{ cursor: "pointer" }}>
                                          <i className="fa fa-circle text-navy" />
                                          {/* <i className="fa fa-times-circle text-danger"/> */}
                                        </td>
                                        <td>{item2.notifName}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ))}
                      </ColBlockFac>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* { !isLoading && <RecordStatus {...recordData} />} */}
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
  configData: state.auth.configData,
  recordData: state.recordStatus,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserNotification);
