import React, { useEffect, useState } from "react";
import Main from "../layouts/Main";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { apis as api } from "../../services/api.action";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import NumberFormat from "react-number-format";
import { TableFacility, AmntFormatFac, HandleSkeletonTbleFac } from "../layouts/Facility";

const ManageSharedInvoice = (props) => {
  const { userData } = props;
  const { profileId, session, companyId, companyTypeId } = userData;
  const globalVar = window.globalData;
  const { tableData } = appStatic;

  const dispatch = useDispatch();
  const [invoiceData, setInvoiceData] = useState([]);
  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [isFunder, setIsFunder] = useState(false);
  const [isCustodian, setIsCustodian] = useState(false);
  const [thValue, setThValue] = useState(tableData.invSharedList);
  const [isLoading, setIsLoading] = useState(false);

  const [tiles, setTiles] = useState([
    {
      title: appLabel.authorized,
      otherTitle: appLabel.authorizedListing,
      color: "primary",
      icon: "tag",
      criteria: "A",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.unauthorized,
      otherTitle: appLabel.unauthorizedListing,
      color: "warning",
      icon: "exclamation-triangle",
      criteria: "U",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.disabled,
      otherTitle: appLabel.disabledListing,
      color: "danger",
      icon: "close",
      criteria: "R",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
  ]);

  useEffect(() => {
    // console.log(props);
    handleSwitchCondition();
    getListByCriteria(tiles[0]); // get position 0 object
  }, []);
  const handleSwitchCondition = () => {
    switch (companyTypeId) {
      case "CT2022012400003": // funder
        setThValue([...thValue, { name: appLabel.funder, id: "4" }]);
        setIsFunder(true);
        break;
      case "CT2022012400000": // custodian
        setThValue([...thValue, { name: appLabel.to, id: "4" }]);
        setIsCustodian(true);
        break;
      default: // other entity type
        setIsFunder(false);
        setIsCustodian(false);
        break;
    }
  };
  function getListByCriteria(params) {
    // console.log(params);
    setInvoiceData([]);

    setTitleLeft(params.otherTitle);
    setTitleRight(params.title);
    setColor(params.color);
    setIcon(params.icon);

    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true)

    let body = {
      profileId,
      invoiceId: "",
      companyId,
      session,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetSharedInvoice, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setInvoiceData(res.invoiceListing);
        BOM.DatatableUsage();
        setIsLoading(false)
      }
    });
  }
  const onSelectContract = (invoice) => {
    ["RIGHT_DETAIL_BTN", "RIGHT_EDIT_BTN_FAKE"].map((item) => {
      dispatch({
        type: item,
      });
    });

    globalVar.viewLink = {
      path: "/finance-invoice",
      obj: invoice,
    };

    globalVar.editLink = {
      path: "/edit-invoice",
      obj: invoice,
    };
  };

  /** RENDER JXS DATA */
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.sharedInvoice} />
      <div className="animated fadeInRightFAKE">
        <div className="row">
          <form className="m-t" autoComplete="off">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>{titleLeft}</h5>
                  <div className="ibox-tools"></div>
                </div>

                <div className="ibox-content panel-primary contentCard">
                <HandleSkeletonTbleFac
                    classParam={`${isLoading ? "" : "hide"}`}
                  />
                  <div className={` ${isLoading ? "hide" : ""}`}>
                  {invoiceData.length === 0 ? (
                    <div className="alert alert-warning text-center">
                      <a className="alert-link">{appLabel.noDataInTableMsg}</a>
                    </div>
                  ) : (
                    <TableFacility
                      thData={thValue.sort((a, b) => (a.id > b.id ? 1 : -1))}
                    >
                      {invoiceData.map((invoice, index) => (
                        <tr className="gradeX" key={index}>
                          <td>
                            <label className="myradio-button">
                              <input
                                type="radio"
                                name="radio"
                                onClick={() => onSelectContract(invoice)}
                              />
                              <span className="label-visible">
                                <span className="myfake-radiobutton"></span>
                              </span>
                            </label>
                          </td>
                          <td>{invoice.BID_END_DATE}</td>
                          <td>{invoice.INTENT_ID}</td>
                          <td>{invoice.INV_ID}</td>
                          {(isFunder || isCustodian) && (
                            <td>
                              {`
                            ${isFunder ? invoice.COMPANY_SHARED_WITH:''}
                            ${isCustodian ? invoice.SUPPLIER_NAME:''}
                          `}
                            </td>
                          )}
                          <td>
                            <AmntFormatFac
                              inputValue={invoice.REQUESTED_AMOUNT}
                              currency={invoice.CCY}
                            />
                          </td>
                        </tr>
                      ))}
                    </TableFacility>
                  )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageSharedInvoice);
